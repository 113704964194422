import SigningInfoPagesLayout from 'components/layouts/SigningInfoPagesLayout';
import useResetSigningContext from 'hooks/signing/useResetSigningContext';
import { Trans } from 'react-i18next';
import classes from './InfoPages.module.less';

/**
 * WrongIdentityForSigning component is page component, which represents the wrong identity page for signing processes
 * @returns {JSX.Element} wrong identity page for signing processes
 * @component
 */
const WrongIdentityForSigning = () => {
  const { resetSigningContext, resetSigningTheme } = useResetSigningContext();

  resetSigningContext();
  setTimeout(() => resetSigningTheme(), 5000);

  return (
    <SigningInfoPagesLayout contentAreaClassName={classes.contentArea}>
      <div className={classes.headingWrapper}>
        <h1 className={classes.infoPageHeading}>
          <Trans i18nKey="signing.infoPages.wrongIdentityForSigning.heading" components={{ br: <br /> }} />
        </h1>
      </div>
    </SigningInfoPagesLayout>
  );
};

export default WrongIdentityForSigning;
