import { gql } from '@apollo/client';
import { adminStatusFragment } from 'graphql/fragments';

const moveStatusMutation = gql`
  mutation moveStatusMutation($_id: ID!, $offset: Int, $order: Int) {
    moveStatus(_id: $_id, offset: $offset, order: $order) {
      ...adminStatusFragment
    }
  }
  ${adminStatusFragment}
`;

export default moveStatusMutation;
