import { gql } from '@apollo/client';
import apollo from 'graphql/apollo';
import { adminItemFragment } from 'graphql/fragments';

const categoryItemsFragment = gql`
  fragment categoryItemsFragment on Category {
    items {
      ...adminItemFragment
    }
  }
  ${adminItemFragment}
`;

export default function moveCategoryItem({ _id, items }) {
  apollo.writeFragment({
    id: `Category___${_id}`,
    fragment: categoryItemsFragment,
    fragmentName: 'categoryItemsFragment',
    data: { items },
  });
}
