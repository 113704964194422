import { EuroCircleOutlined } from '@ant-design/icons';
import { Col, Form, Row } from 'antd';
import { RoundPriceIdFormikInput } from 'components/admin/itemModal/components';
import Card from 'components/common/Card';
import equal from 'fast-deep-equal/es6/react';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FormItem from 'components/common/FormItem';
import { Radio } from 'formik-antd';
import { ShowDigits } from 'constants/shoppingCart';

const showDigitsOptions = ({ t }) =>
  Object.keys(ShowDigits).map((key) => ({ _id: key, label: t(`common.showDigits.${key}`) }));

const ShowDigitsFormikInput = (props) => {
  const { t } = useTranslation();
  const options = useMemo(() => showDigitsOptions({ t }), [t]);
  return (
    <FormItem
      name="showDigits"
      label={t('admin.Setting.ShoppingCartPreferences.pricingSetting.showDigits.title')}
      {...props}
    >
      <Radio.Group name="showDigits">
        {options?.map(({ _id, label }) => (
          <Radio name="showDigits" key={_id} value={_id}>
            {label}
          </Radio>
        ))}
      </Radio.Group>
    </FormItem>
  );
};

const PricingSetting = () => {
  const { t } = useTranslation();
  return (
    <Card icon={<EuroCircleOutlined />} title={t('admin.Setting.ShoppingCartPreferences.pricingSetting.title')}>
      <Form layout="vertical">
        <Row>
          <Col sm={24} md={12}>
            <RoundPriceIdFormikInput isSelectInput={false} />
          </Col>
          <Col sm={24} md={12}>
            <ShowDigitsFormikInput />
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
export default memo(PricingSetting, equal);
