import { useEffect, useState } from 'react';
import concatClassNames from 'utils/classNames';
import classes from './StickyBarOnScrollPane.module.less';

/**
 * StickyBarOnScrollPane component to stick a bar on a scroll pane to stick bar on a fixed position on a scrollable pane
 * @param {Object} inputParameters - Input parameters of component
 * @param {JSX.Element[]} inputParameters.children - Children components of the component
 * @param {Element} inputParameters.scrollPane - Scroll pane element, on which the bar should be sticked
 * @param {String} inputParameters.className - Classname for the sticky bar
 * @returns {JSX.Element} sticky bar on a scroll pane
 * @component
 */
const StickyBarOnScrollPane = ({ children, scrollPane, className }) => {
  const [transform, setTransform] = useState({ scrollTop: 0, scrollLeft: 0 });
  useEffect(() => {
    const scrollEventHandler = () => {
      const { scrollTop, scrollLeft } = scrollPane;
      setTransform({ scrollTop, scrollLeft });
    };
    scrollPane.addEventListener('scroll', scrollEventHandler);
    return () => scrollPane.removeEventListener('scroll', scrollEventHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      className={concatClassNames(classes.StickyBarOnScrollPane, className)}
      style={{ transform: `translateY(${transform.scrollTop}px) translateX(${transform.scrollLeft}px)` }}
    >
      {children}
    </div>
  );
};

export default StickyBarOnScrollPane;
