import AlreadySigned from 'pages/signing/AlreadySigned';
import DocumentGenerationFailed from 'pages/signing/DocumentGenerationFailed';
import SigningPage from 'pages/signing/Signing';
// import SigningTest from 'pages/signing/SigningTest';
import SigningUnvalid from 'pages/signing/SigningUnvalid';
import StartSigning from 'pages/signing/StartSigning';
import SuccessfullySigned from 'pages/signing/SuccessfullySigned';
import WaitForDocuments from 'pages/signing/WaitForDocuments';
import WaitForDocumentsTimeout from 'pages/signing/WaitForDocumentsTimeout';
import WrongIdentityForSigning from 'pages/signing/WrongIdentityForSigning';
import { Route } from 'react-router-dom';
import routePaths from './route-paths';

/**
 * Array of routes for signing pages, where each object contains a path and the related page component
 */
export const signingRoutes = [
  {
    path: routePaths.signing,
    component: <SigningPage />,
  },
  {
    path: routePaths.signingStart,
    component: <StartSigning />,
  },
  {
    path: routePaths.signingUnvalid,
    component: <SigningUnvalid />,
  },
  {
    path: routePaths.signingAlreadySigned,
    component: <AlreadySigned />,
  },
  {
    path: routePaths.signingSuccessfullySigned,
    component: <SuccessfullySigned />,
  },
  {
    path: routePaths.signingDocumentsPreparation,
    component: <WaitForDocuments />,
  },
  {
    path: routePaths.signingDocumentsPreparationTimeout,
    component: <WaitForDocumentsTimeout />,
  },
  {
    path: routePaths.signingDocumentsPreparationFailed,
    component: <DocumentGenerationFailed />,
  },
  {
    path: routePaths.signingWrongIdentity,
    component: <WrongIdentityForSigning />,
  },
];

export const signingRoutesRouterComponents = signingRoutes.map((route) => {
  return <Route path={route.path} element={route.component} key={route.path} />;
});
