import Card from 'components/common/Card';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { GrDocumentConfig } from 'react-icons/gr';
import { useFormikField } from 'hooks/common/useFormikField';
import { QuestionInput } from './ShoppingCartSettingsCard/QuestionInput';
import { HelpTextInput } from './ShoppingCartSettingsCard/HelpTextInput';
import { DefaultOption } from './ShoppingCartSettingsCard/DefaultOption';

const ShoppingCartSettingsCard = () => {
  const { t } = useTranslation();

  const { value: monthlyPaymentMode } = useFormikField('monthlyPaymentMode');
  if (monthlyPaymentMode !== 'optional') return null;

  return (
    <Card
      icon={<GrDocumentConfig />}
      title={t('admin.Setting.MonthlyPaymentPreferences.shoppingCartSettings.cardLabel')}
      style={{ padding: 0 }}
    >
      <Form layout="vertical">
        <QuestionInput t={t} />
        <HelpTextInput t={t} />
        <DefaultOption t={t} />
      </Form>
    </Card>
  );
};

export default ShoppingCartSettingsCard;
