import gql from 'graphql-tag';
import { userComparingDocumentTemplatesFragment } from 'graphql/fragments';

const userShoppingCartComparingDocumentTemplatesQuery = gql`
  query userShoppingCartComparingDocumentTemplatesQuery($_id: ID!) {
    userShoppingCart(_id: $_id) {
      documentTemplates {
        initializationConfigDate
        ...userComparingDocumentTemplatesFragment
      }
    }
  }
  ${userComparingDocumentTemplatesFragment}
`;

export default userShoppingCartComparingDocumentTemplatesQuery;
