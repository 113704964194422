import { useFormikContext } from 'formik';
import { get } from 'lodash';
import { useFunctionToRefCB } from 'memo';

export const useFormikField = (name) => {
  const formik = useFormikContext();
  const onChange = useFunctionToRefCB((v) => {
    formik.setFieldValueAndTouched(name, v);
  });
  const value = get(formik.values, name);
  return { value, onChange, submitForm: formik.submitForm };
};
