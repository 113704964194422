import { useEffect, useState } from 'react';
import { Col, Row } from 'antd';

import { useMutation } from '@apollo/client';
import copyToClipboard from 'utils/copyToClipboard';
import { getSigneeSigningPasswordMutation, getSigneeSigningLinkMutation } from 'graphql/mutations';

import Button from '../Button';
import { formatDateToAvailableTime } from '../utils';
import classes from './SigneeManagement.module.less';

/**
 * PasswordAndLinkManagement component for signee management in digital signature state card in shopping cart view
 * @param {Object} inputParameter - Input parameter
 * @param {Function} inputParameter.translation - I18n translation function
 * @param {Object} inputParameter.signee - Informations about the signee
 * @param {String} inputParameter.shoppingCartId - Database id of related shopping cart object
 * @param {React.Ref} inputParameter.resetReference - Reference to set reset function on for resetting the show of password and link
 * @param {Object} inputParameter.signingLinkInfos - Informations about the signees signing link
 * @param {Boolean} inputParameter.signingLinkInfos.usePassword - Is password for the signing process required
 * @param {Date} inputParameter.signingLinkInfos.expirationDate - Expiration date of the signing link
 * @returns {JSX.Element} PasswordAndLinkManagement component for signee management
 * @Component
 */
const PasswordAndLinkManagement = ({ translation, signee, shoppingCartId, resetReference, signingLinkInfos }) => {
  const [password, setPassword] = useState();
  const [link, setLink] = useState();
  const [getSigneeSigningLink] = useMutation(getSigneeSigningLinkMutation, {
    variables: {
      shoppingCartId,
      signeeEmail: signee.email,
      signeeRelatedObjectId: signee.id,
    },
  });
  const [getSigneeSigningPassword] = useMutation(getSigneeSigningPasswordMutation, {
    variables: {
      shoppingCartId,
      signeeEmail: signee.email,
      signeeRelatedObjectId: signee.id,
    },
  });

  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    resetReference.current = {
      reset: () => {
        setLink(undefined);
        setPassword(undefined);
      },
    };
  }, [resetReference]);

  const showPasswordRow = signingLinkInfos ? signingLinkInfos.usePassword : false;
  const placeholderPassword = ' ';
  const placeholderLink = signingLinkInfos
    ? formatDateToAvailableTime(new Date(signingLinkInfos.expirationDate), translation)
    : '';

  const onCopyPassword = () => {
    copyToClipboard(password, translation('viewer.ShoppingCartView.DigitalSignature.signeeManagement.copiedPassword'));
  };
  const onShowPassword = () => {
    getSigneeSigningPassword().then((data) => {
      setPassword(data.data.getSigneeSigningPassword);
      copyToClipboard(
        data.data.getSigneeSigningPassword,
        translation('viewer.ShoppingCartView.DigitalSignature.signeeManagement.copiedPassword'),
      );
    });
  };
  const onCopyLink = () => {
    copyToClipboard(link, translation('viewer.ShoppingCartView.DigitalSignature.signeeManagement.copiedLink'));
  };
  const onShowLink = () => {
    getSigneeSigningLink().then((data) => {
      setLink(data.data.getSigneeSigningLink);
      copyToClipboard(
        data.data.getSigneeSigningLink,
        translation('viewer.ShoppingCartView.DigitalSignature.signeeManagement.copiedLink'),
      );
    });
  };

  const passwordCopyButton = (
    <Button onClick={onCopyPassword}>
      {translation('viewer.ShoppingCartView.DigitalSignature.signeeManagement.copyPassword')}
    </Button>
  );
  const passwordShowButton = (
    <Button onClick={onShowPassword}>
      {translation('viewer.ShoppingCartView.DigitalSignature.signeeManagement.showPassword')}
    </Button>
  );
  const linkCopyButton = (
    <Button onClick={onCopyLink}>
      {translation('viewer.ShoppingCartView.DigitalSignature.signeeManagement.copyLink')}
    </Button>
  );
  const linkShowButton = (
    <Button onClick={onShowLink}>
      {translation('viewer.ShoppingCartView.DigitalSignature.signeeManagement.showLink')}
    </Button>
  );
  const passwordRowContent = password ? (
    <p>{password}</p>
  ) : (
    <p className={classes.placeholder}>{placeholderPassword}</p>
  );
  const contentColumn = [
    showPasswordRow ? passwordRowContent : undefined,
    link ? <p>{link}</p> : <p className={classes.placeholder}>{placeholderLink}</p>,
  ].filter(Boolean);
  const passwordButton = password ? passwordCopyButton : passwordShowButton;
  const buttonColumn = [showPasswordRow ? passwordButton : undefined, link ? linkCopyButton : linkShowButton].filter(
    Boolean,
  );
  return (
    <Row className={classes.signeeInfoRow}>
      <Col className={classes.labels} span={2}>
        {showPasswordRow && <p>{translation('viewer.ShoppingCartView.DigitalSignature.signeeManagement.password')}</p>}
        <p>{translation('viewer.ShoppingCartView.DigitalSignature.signeeManagement.link')}</p>
      </Col>
      <Col className={classes.inputs} span={17}>
        {contentColumn}
      </Col>
      <Col className={classes.signeeInfoRowButtons} span={4}>
        {buttonColumn}
      </Col>
    </Row>
  );
};

export default PasswordAndLinkManagement;
