import { useMemo } from 'react';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { useCachedQuery } from 'graphql/utils';
import userUsersInTenantQuery from 'graphql/queries/user/userUsersInTenantQuery';

const useUsersInTenantList = () => {
  const { data, loading } = useCachedQuery(userUsersInTenantQuery, {
    fetchPolicy: 'cache-and-network',
  });
  const users = useMemo(() => grabFirstGQLDataResult(data), [data]);
  return { data: users, loading };
};

export default useUsersInTenantList;
