import gql from 'graphql-tag';

const userDocumentTemplateMultiSelectQuery = gql`
  query userDocumentTemplateMultiSelectQuery($isLibrary: Boolean) {
    documentTemplates(isLibrary: $isLibrary) {
      _id
      name
      customizableBlocks {
        _id
        name
        props
        descriptor {
          _id
          name
          displayName
          form
        }
      }
      isSignable
    }
  }
`;

export default userDocumentTemplateMultiSelectQuery;
