import { GrDrag } from 'react-icons/gr';
import equal from 'fast-deep-equal/es6/react';
import { memo } from 'react';
import classes from './MoveButton.module.less';

export const DragButton = ({ ...buttonProps }) => {
  return (
    <div style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', left: -2 }}>
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'move',
            height: '32px',
          }}
        >
          <GrDrag {...buttonProps} type="primary" className={classes.drag} style={{ cursor: 'move' }} />
        </div>
      </div>
    </div>
  );
};

export default memo(DragButton, equal);
