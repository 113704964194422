import { gql } from '@apollo/client';
import { userCompanyFragment } from 'graphql/fragments';

const importCompaniesFromCSVMutation = gql`
  mutation importCompaniesFromCSVMutation($companies: [UpdateCompanyInput]!) {
    importCompaniesFromCSV(companies: $companies) {
      companies {
        ...userCompanyFragment
      }
      numberUpdate
      numberInsert
    }
  }
  ${userCompanyFragment}
`;

export default importCompaniesFromCSVMutation;
