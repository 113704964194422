import apollo from 'graphql/apollo';
import { importCompaniesFromCSVMutation } from 'graphql/mutations';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { userCompanyListQuery } from 'graphql/queries';

const importCompaniesFromCSV = async (companies) =>
  apollo.mutate({
    mutation: importCompaniesFromCSVMutation,
    variables: {
      companies,
    },
    update: (cache, { data }) => {
      const result = grabFirstGQLDataResult(data);
      const cachedData = cache.readQuery({ query: userCompanyListQuery });
      cache.writeQuery({
        query: userCompanyListQuery,
        data: {
          companies: [...result.companies, ...cachedData.companies],
        },
      });
    },
  });

export default importCompaniesFromCSV;
