import { FaSignature } from 'react-icons/fa';
import { Sans30px400, Sans30px600 } from './Text/Text';
import c from './SignButton.module.less';
import { Br100 } from './Text/Br';

const SignButton = () => {
  1;

  return (
    <div className={c.signButtonContainer}>
      <Sans30px600>Please sign all documents</Sans30px600>
      <Br100 />
      <div className={c.signButton}>
        <FaSignature />
        <Sans30px400>Click here to sign</Sans30px400>
      </div>
    </div>
  );
};
export default SignButton;
