import equal from 'fast-deep-equal/es6/react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Input from 'components/common/Input';
import ShoppingCartFormItem from '../components/ShoppingCartFormItem';

/**
 * Input for the project name
 * @returns {JSX.Element} let's the user enter the project name
 * @component
 */
const ProjectNameFormikInput = () => {
  const { t } = useTranslation();
  return (
    <ShoppingCartFormItem name="name" label={t('user.ShoppingCart.inputs.name.label')}>
      <Input name="name" debounceTime={500} />
    </ShoppingCartFormItem>
  );
};
export default memo(ProjectNameFormikInput, equal);
