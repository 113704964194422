import { memo, useMemo } from 'react';
import { Document, Pages, OnLoadingComponent, OnError } from '../Document';
import PdfViewerViewPane from './PdfViewPane';
import classes from './PdfView.module.less';

/**
 * PdfView component for pdf viewer, which shows the view pane and the document itsself
 * @param {Object} inputParameters - Input parameters for component
 * @param {Uint8Array} inputParameters.data - Binary data of pdf or null (Data as Uint8Array)
 * @param {Function} inputParameters.openFullscreenMode - Function called, if fullscreen mode button was pressed
 * @param {React.Ref} inputParameters.documentControlRef - Reference for getting access to scale and navigation functions of the document
 * @param {Boolean} inputParameters.widthControlled - Is pdf drawer in width controlled mode
 * @param {Function} inputParameters.onDocumentLoadSuccess - Function called, when document has been successful loaded (gets object with pageNumber)
 * @param {Function} inputParameters.onItemClick - Function called, when an item (link, toc item) was clicked with informations about the clicked item as parameter
 * @param {Object} inputParameters.documentInfo - Informations about loading/loaded document
 * @param {String} inputParameters.documentInfo.documentTemplateName - Name of document template for the document
 * @param {Number} inputParameters.numPages - Number of pages of the document or null
 * @param {Object} inputParameters.error - Error object from loading or null/undefined
 * @param {String} inputParameters.error.message - Error message
 * @param {String|Number} inputParameters.error.status - Error status (like 404 or status message like Network error)
 * @returns {JSX.Element} pdf view component for pdf viewer, which shows the loaded document or loading screen or load error
 * @component
 */
const PdfView = ({
  data,
  openFullscreenMode,
  documentControlRef,
  widthControlled,
  onDocumentLoadSuccess,
  onItemClick,
  documentInfo,
  numPages,
  error,
}) => {
  const LoadingOrErrorComponent = useMemo(() => (error ? <OnError /> : <OnLoadingComponent />), [error]);

  return (
    <PdfViewerViewPane documentControlRef={documentControlRef} widthControlled={widthControlled}>
      {data
        ? ({ availableHeight, pageHeight, pdfWrapperRef, updateScrollLeft }) => (
            <Document
              data={data}
              onDocumentLoadSuccess={onDocumentLoadSuccess}
              onItemClick={onItemClick}
              documentInfo={documentInfo}
            >
              {numPages ? (
                <Pages
                  numberOfPages={numPages}
                  heightForPage={pageHeight}
                  heightForList={availableHeight}
                  pdfViewPaneRef={{ current: pdfWrapperRef }}
                  documentControlRef={documentControlRef}
                  listClassName={classes.List}
                  onOpenFullscreenViewer={openFullscreenMode}
                  onUpdateScrollLeft={updateScrollLeft}
                />
              ) : null}
            </Document>
          )
        : () => LoadingOrErrorComponent}
    </PdfViewerViewPane>
  );
};

const MemorizedPdfView = memo(PdfView);

export default MemorizedPdfView;
