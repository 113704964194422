import { useNavigate } from 'react-router';
import routePaths from 'router/route-paths';
import useSigningShoppingCart from './useSigningShoppingCart';

const useDocumentsAreGenerated = (hasDocumentGenerationTriggered) => {
  const navigate = useNavigate();
  const { shoppingCart } = useSigningShoppingCart();

  if (hasDocumentGenerationTriggered) {
    navigate(routePaths.signingDocumentsPreparation);
    return;
  }

  const { pdfs } = shoppingCart || { pdfs: [] };
  const hasUnfinishedPdfs = pdfs.find((document) => document.isGenerating);

  if (hasUnfinishedPdfs) navigate(routePaths.signingDocumentsPreparation);
};

export default useDocumentsAreGenerated;
