import { gql } from '@apollo/client';
import { userShoppingCartFragment } from 'graphql/fragments';

const userShoppingCartQuery = gql`
  query userShoppingCartQuery($_id: ID!) {
    userShoppingCart(_id: $_id) {
      ...userShoppingCartFragment
    }
  }
  ${userShoppingCartFragment}
`;

export default userShoppingCartQuery;
