import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import equal from 'fast-deep-equal/es6/react';
import { deleteStatusWithConfirmation, updateStatus, moveStatus } from 'graphql/methods';
import i18n from 'i18n';
import { TableMemo } from 'memo';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import inputConfirmModal from 'utils/inputConfirmModal';
import toast from 'utils/toast';
import statusTypes from 'constants/statusTypes';
import { moveStatusCache } from 'graphql/cache';
import { DragHandle, useDragTableComponents, useSortEnd } from 'components/admin/drag/DragTable';
import StatusForm, { statusValidationSchema } from './StatusForm';

const editStatusModal = (status) =>
  inputConfirmModal({
    okText: i18n.t('admin.ManageShoppingCartStatus.updateStatusModal.ok'),
    cancelText: i18n.t('admin.ManageShoppingCartStatus.updateStatusModal.cancel'),
    formContent: () => <StatusForm />,
    fields: [],
    headerText: i18n.t('admin.ManageShoppingCartStatus.updateStatusModal.title'),
    onSubmit: (props) =>
      updateStatus({ _id: status._id, prevType: statusTypes.shoppingCart, modifier: props }).then(toast.successDefault),
    value: { name: status.name || '', type: statusTypes.shoppingCart },
    validationSchema: statusValidationSchema,
    errorResolver: { Duplicated: ['name', i18n.t('common.duplicatedErrorMessage', { name: 'Name' })] },
    width: '600px',
  });

const columns = ({ t }) => [
  {
    className: 'drag-visible',
    width: 10,
    render: () => <DragHandle />,
    key: 'sort',
  },
  {
    className: 'col-12 table-text',
    title: t('admin.ManageShoppingCartStatus.columns.name'),
    dataIndex: 'name',
  },
  {
    key: 'actions',
    className: 'action-column-2',
    render: (status) => {
      return (
        <>
          <Button
            type="primary"
            className="ant-btn-default"
            ghost
            icon={<EditOutlined />}
            onClick={() => editStatusModal(status)}
          />{' '}
          <Button
            type="danger"
            className="ant-btn-default"
            ghost
            icon={<DeleteOutlined />}
            onClick={() =>
              deleteStatusWithConfirmation({ _id: status._id, type: status.type, isDefault: status.isDefault })
            }
          />
        </>
      );
    },
  },
];

function StatusList({ statuses }) {
  const { t } = useTranslation();

  const moveInCache = useCallback((newData) => moveStatusCache({ statuses: newData }), []);

  const { onSortEnd } = useSortEnd({
    dataSource: statuses,
    moveInCache,
    moveMutation: moveStatus,
    refetchQueryProps: { type: statuses?.[0].type },
  });

  const { DraggableContainer, DraggableBodyRow } = useDragTableComponents({ dataSource: statuses, onSortEnd });

  return (
    <TableMemo
      rowKey="_id"
      dataSource={statuses}
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      }}
      columns={columns({ t })}
      pagination={false}
    />
  );
}

export default memo(StatusList, equal);
