import apollo from 'graphql/apollo';
import { resetCustomSignatureImageMutation } from 'graphql/mutations';
import { adminUsersInTenantQuery } from 'graphql/queries';

const resetCustomSignatureImage = (userId) => {
  apollo.mutate({
    mutation: resetCustomSignatureImageMutation,
    variables: { userId },
    refetchQueries: [{ query: adminUsersInTenantQuery }],
  });
};

export default resetCustomSignatureImage;
