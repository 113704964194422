const REGEXP_PATTERN_TO_GET_SUBDOMAINS = '((.+)\\.)?[^>]+\\.[^>]+';
const DMARC_HOSTNAME_PREFIX = '_dmarc';

/**
 * Creates hostname for dmarc record
 * @param {String} domainName - Domain name
 * @returns {String} generated dmarc hostname
 */
export const createDmarcHostname = (domainName) => {
  if (!domainName) return '';
  if (domainName === '') return '';
  const regexp = new RegExp(REGEXP_PATTERN_TO_GET_SUBDOMAINS);
  const regexpResult = regexp.exec(domainName);
  if (!regexpResult) return '';
  const subdomains = regexpResult[2];
  if (!subdomains) return DMARC_HOSTNAME_PREFIX;
  return `${DMARC_HOSTNAME_PREFIX}.${subdomains}`;
};
