import { Radio as RadioFormik } from 'formik-antd';
import { Tooltip, Row } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import classes from './Radio.module.less';

export const Radio = ({ tooltip, value, name, children, props }) => {
  return (
    <Row className={classes.radio}>
      <span>
        <RadioFormik name={name} value={value} className={classes.radioWithLabel} {...props}>
          {children}
        </RadioFormik>
      </span>
      {tooltip ? (
        <span>
          <Tooltip title={tooltip}>
            <InfoCircleOutlined className={classes.tooltipIcon} />
          </Tooltip>
        </span>
      ) : null}
    </Row>
  );
};
