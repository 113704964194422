import { forwardRef, useMemo } from 'react';
import { Form } from 'formik-antd';
import { AlertFromFormik } from 'components/common/ErrorComponent';
import SaveChangesButton from 'components/common/SaveChangesButton';
import I18nFormik from 'components/common/I18nFormik';
import { toastGraphQLError } from 'utils/helpers';
import toast from 'utils/toast';
import { useTranslation } from 'react-i18next';
import { find, groupBy, forEach } from 'lodash';
import { uploadFile } from 'graphql/methods';
import ConfigurationSubtitleWithFields from 'components/admin/DocumentTemplate/Block/Configuration/ConfigurationSubtitleWithFields';
import BlockController from './BlockController';

const FormItems = ({ setPreviewBlockDescriptor, form: _form, visibleFields, blockNumber }) => {
  const { t } = useTranslation();
  const form = useMemo(() => {
    const filterForm = _form;
    let filteredForm = filterForm;
    if (visibleFields) {
      filteredForm = filterForm
        .map((formItem) =>
          visibleFields.includes(formItem.name)
            ? {
                ...formItem,
                displayName: blockNumber || t(`admin.DocumentTemplateConfigurationPage.blocks.${formItem.name}`),
              }
            : null,
        )
        .filter(Boolean);
    }
    return groupBy(filteredForm, (e) => e?.subtitle || '');
  }, [_form, visibleFields, t, blockNumber]);

  return Object.entries(form).map(([subtitle, fields]) => {
    return (
      <ConfigurationSubtitleWithFields
        subtitle={subtitle}
        fields={fields}
        setPreviewBlockDescriptor={setPreviewBlockDescriptor}
      />
    );
  });
};

function DocumentLayoutBlockForm(
  {
    form,
    initialValues,
    getDocumentTemplate,
    blockId,
    visibleFields,
    blockNumber,
    setPreviewBlockDescriptor,
    autosaveChanges,
    onSubmit,
  },
  ref,
) {
  const mergedInitialValues = useMemo(() => ({ ...initialValues }), [initialValues]);
  return (
    <I18nFormik
      ref={ref}
      initialValues={mergedInitialValues}
      onSubmit={async (_values) => {
        const values = {};
        const promises = [];
        forEach(_values, (value, key) => {
          const type = find(form, { name: key })?.type;
          if (type === 'photo') {
            promises.push(
              uploadFile({
                name: `name_${new Date().toISOString()}`,
                parentId: blockId,
                parentType: 'blocks',
                size: value.size,
                file: value,
              }),
            );
          } else {
            values[key] = value;
          }
        });
        await Promise.all(promises);
        if (typeof onSubmit === 'function') onSubmit({ blockId, values });
        else {
          const blocks = BlockController.updateBlockForm({
            blockId,
            blocks: getDocumentTemplate().blocks,
            formValues: values,
          });
          BlockController.persistBlocks({ _id: getDocumentTemplate()._id, blocks })
            .then(autosaveChanges || toast.successDefault)
            .catch(toastGraphQLError);
        }
      }}
      // TODO: do we need validation?
      // validationSchema={generateValidationSchema(form)}
      enableReinitialize
    >
      <Form layout="vertical">
        <AlertFromFormik />
        <FormItems
          visibleFields={visibleFields}
          blockNumber={blockNumber}
          form={form}
          setPreviewBlockDescriptor={setPreviewBlockDescriptor}
        />
        <SaveChangesButton autosaveEnabled={autosaveChanges} initialValues={mergedInitialValues} />
      </Form>
    </I18nFormik>
  );
}

export default forwardRef(DocumentLayoutBlockForm);
