import { gql } from '@apollo/client';

const primaryColorsAppQuery = gql`
  query primaryColorsAppQuery {
    onboarding {
      theme {
        primaryColor
        color
        titleFontFamily
        fontFamily
      }
    }
  }
`;
export default primaryColorsAppQuery;
