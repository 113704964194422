import { Drawer } from 'antd';
import { useState } from 'react';
import PdfDrawerHeader from './DrawerHeader';
import PdfDrawerFooter from './DrawerFooter';
import { PdfViewer } from './PdfViewer';
import { useFetchFileAsUint8Array, useWidthControlledMode } from './hooks';
import classes from './PdfDrawer.module.less';

/**
 * PdfDrawer component to show pdf documents in a drawer
 * @param {Object} inputParameters - Input parameter for the component
 * @param {Object[]} inputParameters.documents - Collection of documents, which should be accessable over the drawer
 * @param {String} inputParameters.documents.shoppingCartId - Id of shopping cart to which the document belongs
 * @param {String} inputParameters.documents.documentTemplateId - Id of related document template
 * @param {String} inputParameters.documents.templateName - Name of related document template, used for document name
 * @param {Number} inputParameters.indexOfCurrentDocument - Index of the document, which should be initial opened
 * @param {Function} inputParameters.onCloseDrawer - Function, which should be called to close the drawer
 * @returns {JSX.Element} Drawer component with a documents carousel, a viewer with a fullscreen mode and buttons to download and print current document
 * @component
 */
const PdfDrawer = ({ indexOfCurrentDocument, onCloseDrawer, documents }) => {
  const [indexOfShownDocument, setIndexOfShownDocument] = useState(indexOfCurrentDocument);
  const widthControlled = useWidthControlledMode();

  const url = `/files/shopping-cart?id=${documents[indexOfShownDocument].shoppingCartId}&t=${documents[indexOfShownDocument].documentTemplateId}`;
  const fileType = 'application/pdf';

  const { data, error } = useFetchFileAsUint8Array(url, fileType);

  const documentInfo = {
    shoppingCartId: documents[indexOfShownDocument].shoppingCartId,
    documentTemplateId: documents[indexOfShownDocument].documentTemplateId,
    documentTemplateName: documents[indexOfShownDocument].templateName,
  };

  const nextDocument = () => {
    setIndexOfShownDocument((currentIndex) => (currentIndex < documents.length - 1 ? currentIndex + 1 : 0));
  };

  const prevDocument = () => {
    setIndexOfShownDocument((currentIndex) => (currentIndex === 0 ? documents.length - 1 : currentIndex - 1));
  };

  return (
    <Drawer
      onClose={onCloseDrawer}
      closable
      className={widthControlled ? classes.PdfDrawerWidthControlled : classes.PdfDrawer}
      open
      visible
      placement="left"
      title={
        <PdfDrawerHeader
          title={documents[indexOfShownDocument].templateName}
          onNextDocument={nextDocument}
          onPreviousDocument={prevDocument}
          enableButtons
        />
      }
      footer={
        <PdfDrawerFooter
          url={url}
          fileType={fileType}
          fileName={documents[indexOfShownDocument].name}
          data={data}
          onClose={onCloseDrawer}
        />
      }
    >
      <PdfViewer data={data} documentInfo={documentInfo} widthControlled error={error} />
    </Drawer>
  );
};

export default PdfDrawer;
