import { useMemo } from 'react';
import usePreferences from 'hooks/user/usePreferences';
import { getMessageFromGraphQLError } from 'utils/helpers';
import { updateTenantSettings } from 'graphql/methods';
import I18nFormik from 'components/common/I18nFormik';
import SaveChangesButton from 'components/common/SaveChangesButton';
import { Alert, Skeleton } from 'antd';

const MonthlyPaymentPreferencesForm = ({ children }) => {
  const { preferences, loading, error } = usePreferences({ fetchPolicy: 'cache-and-network' });

  const skeletons = [...new Array(2)].map((_, i) => i + 1);

  const initialValues = useMemo(() => {
    return {
      monthlyPaymentMode: preferences?.monthlyPaymentPreferences?.monthlyPaymentMode || 'optional',
      monthlyPaymentTitle: preferences?.monthlyPaymentPreferences?.monthlyPaymentTitle || '',
      roundPriceId: preferences?.monthlyPaymentPreferences?.rounding || 'noRound',
      shoppingCartSettings: {
        questionText: preferences?.monthlyPaymentPreferences?.shoppingCartSettings?.questionText || '',
        helpText: preferences?.monthlyPaymentPreferences?.shoppingCartSettings?.helpText || '',
        defaultOption: preferences?.monthlyPaymentPreferences?.shoppingCartSettings?.defaultOption || 'decideLater',
      },
      signatureSettings: {
        title: preferences?.monthlyPaymentPreferences?.signatureSettings?.title || '',
        introductionText: preferences?.monthlyPaymentPreferences?.signatureSettings?.introductionText || '',
        optionPayMonthlyPaymentText:
          preferences?.monthlyPaymentPreferences?.signatureSettings?.optionPayMonthlyPaymentText || '',
        optionPaySeparatelyText:
          preferences?.monthlyPaymentPreferences?.signatureSettings?.optionPaySeparatelyText || '',
      },
    };
  }, [
    preferences?.monthlyPaymentPreferences?.monthlyPaymentMode,
    preferences?.monthlyPaymentPreferences?.monthlyPaymentTitle,
    preferences?.monthlyPaymentPreferences?.rounding,
    preferences?.monthlyPaymentPreferences?.shoppingCartSettings?.questionText,
    preferences?.monthlyPaymentPreferences?.shoppingCartSettings?.helpText,
    preferences?.monthlyPaymentPreferences?.shoppingCartSettings?.defaultOption,
    preferences?.monthlyPaymentPreferences?.signatureSettings?.title,
    preferences?.monthlyPaymentPreferences?.signatureSettings?.introductionText,
    preferences?.monthlyPaymentPreferences?.signatureSettings?.optionPayMonthlyPaymentText,
    preferences?.monthlyPaymentPreferences?.signatureSettings?.optionPaySeparatelyText,
  ]);

  return (
    <>
      {error ? <Alert type="error" message={getMessageFromGraphQLError(error)} /> : null}
      {!preferences && loading ? skeletons.map((k) => <Skeleton title loading active key={k} />) : null}
      {preferences ? (
        <I18nFormik
          initialValues={initialValues}
          onSubmit={async ({
            monthlyPaymentMode,
            monthlyPaymentTitle,
            roundPriceId,
            shoppingCartSettings,
            signatureSettings,
          }) => {
            return updateTenantSettings({
              settings: {
                monthlyPaymentPreferences: {
                  monthlyPaymentMode,
                  monthlyPaymentTitle,
                  rounding: roundPriceId,
                  shoppingCartSettings,
                  signatureSettings,
                },
              },
            });
          }}
          enableReinitialize
        >
          {children}
          <SaveChangesButton initialValues={initialValues} />
        </I18nFormik>
      ) : null}
    </>
  );
};

export default MonthlyPaymentPreferencesForm;
