import UserMultiSelect from 'components/common/UserMultiSelect';
import classes from './DefaultTenantSigneesSelect.module.less';

const DefaultTenantSigneesSelect = ({ t }) => {
  const specialOptions = [
    {
      label: t('admin.Setting.DigitalSignaturePreferences.generalSettings.defaultTenantSignees.specialOption'),
      value: 'CURRENT_USER',
    },
  ];

  return (
    <UserMultiSelect
      fieldName="defaultTenantSignees"
      label={t('admin.Setting.DigitalSignaturePreferences.generalSettings.defaultTenantSignees.label')}
      tooltip={t('admin.Setting.DigitalSignaturePreferences.generalSettings.defaultTenantSignees.tooltip')}
      specialOptions={specialOptions}
      className={classes.select}
      placeholder={t('admin.Setting.DigitalSignaturePreferences.generalSettings.defaultTenantSignees.placeholder')}
    />
  );
};

export default DefaultTenantSigneesSelect;
