import { gql } from '@apollo/client';
import { adminContactFragment } from 'graphql/fragments';

const updateContactMutation = gql`
  mutation updateContactMutation($_id: ID!, $modifier: UpdateContactInput) {
    updateContact(_id: $_id, modifier: $modifier) {
      ...adminContactFragment
    }
  }
  ${adminContactFragment}
`;

export default updateContactMutation;
