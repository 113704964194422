import { gql } from '@apollo/client';

const moveDiscountMutation = gql`
  mutation moveDiscountMutation($_id: ID!, $offset: Int, $order: Int) {
    moveDiscount(_id: $_id, offset: $offset, order: $order) {
      _id
      order
    }
  }
`;

export default moveDiscountMutation;
