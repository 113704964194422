import { useState } from 'react';
import { Button, Space, Row, Col } from 'antd';
import useDownloadFile from 'hooks/signing/useDownloadFile';
import { useTranslation } from 'react-i18next';
import { FaRegFilePdf } from 'react-icons/fa';
import { PdfDrawer } from 'components/common/PdfDrawer';
import classes from './DocumentEntry.module.less';

/**
 * DocumentEntry component is a document entry for the documents list of the documents card of the signing process
 * @param {Object} inputParameters - Input parameters of the component
 * @param {Object} inputParameters.document - Document object
 * @param {String} inputParameters.shoppingCartId - Id of the shopping cart object
 * @returns {JSX.Element} shows a row with a specified document; shows template name button to open doc and button to download doc
 * @component
 */

const DocumentEntry = ({ document, shoppingCartId, documents }) => {
  const { t: translation } = useTranslation();
  const [showDrawer, setShowDrawer] = useState(false);

  const { templateName: name, documentTemplateId: templateId, name: fileName } = document;
  const url = `/files/shopping-cart?id=${shoppingCartId}&t=${templateId}`;
  const fileType = 'application/pdf';
  const { downloadFile } = useDownloadFile(url, fileType, fileName);

  const showPdf = () => {
    setShowDrawer(true);
  };

  const indexOfElement = documents.indexOf(document);

  return (
    <>
      <Row className={classes.documentEntry} justify="space-between" align="middle">
        <Col className={classes.nameColumn}>
          <button className={classes.documentTitleClickableWrapper} type="button" onClick={showPdf}>
            <div className={classes.iconContainer}>
              <FaRegFilePdf size="16" className={classes.icon} />
            </div>
            <h3>{name}</h3>
          </button>
        </Col>
        <Col>
          <Space>
            <Button className={classes.button} onClick={showPdf}>
              {translation('signing.viewDocumentsAndSignStep.documentsCard.showButton')}
            </Button>
            <Button className={classes.button} onClick={() => downloadFile()}>
              {translation('signing.viewDocumentsAndSignStep.documentsCard.downloadButton')}
            </Button>
          </Space>
        </Col>
      </Row>
      {showDrawer ? (
        <PdfDrawer
          indexOfCurrentDocument={indexOfElement}
          onCloseDrawer={() => setShowDrawer(false)}
          documents={documents}
        />
      ) : null}
    </>
  );
};

export default DocumentEntry;
