const usePrintFile = (url, fileType) => {
  const printFile = (fileArrayBuffer) => {
    const getDataPromise = new Promise((resolve) => {
      if (!fileArrayBuffer) {
        fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': fileType,
          },
        })
          .then((response) => response.blob())
          .then((blob) => {
            resolve([blob]);
          });
      } else {
        resolve([fileArrayBuffer]);
      }
    });
    getDataPromise.then((binaryData) => {
      const convertedBlob = new Blob(binaryData, { type: fileType });
      let blobUrl = window.URL.createObjectURL(convertedBlob);
      const objFra = document.createElement('iframe');
      objFra.className = 'hiddenAbsolute';
      objFra.src = blobUrl;
      objFra.name = 'print_frame';
      document.body.appendChild(objFra);
      objFra.contentWindow.focus();
      objFra.contentWindow.print();
      blobUrl = URL.revokeObjectURL(convertedBlob);
    });
  };
  return { printFile };
};

export default usePrintFile;
