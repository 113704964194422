import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import AcceptInvitationForm from 'components/verify/AcceptInvitationForm';
import { memo } from 'react';
import equal from 'fast-deep-equal/es6/react';

const AcceptInvitation = () => {
  const { t } = useTranslation();
  return (
    <Card title={t('verify.AcceptInvitation.title')}>
      <AcceptInvitationForm />
    </Card>
  );
};

export default memo(AcceptInvitation, equal);
