import { gql } from '@apollo/client';
import { adminDocumentTemplateListFragment } from 'graphql/fragments';

const adminDocumentTemplateListQuery = gql`
  query adminDocumentTemplateListQuery($isLibrary: Boolean) {
    documentTemplates(isLibrary: $isLibrary) {
      ...adminDocumentTemplateListFragment
    }
  }
  ${adminDocumentTemplateListFragment}
`;

export default adminDocumentTemplateListQuery;
