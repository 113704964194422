import { gql } from '@apollo/client';
import { adminStaticItemFragment } from 'graphql/fragments';

const updateStaticItemMutation = gql`
  mutation updateStaticItemMutation($_id: ID!, $modifier: StaticItemInput!) {
    updateCategoryStaticItem(_id: $_id, modifier: $modifier) {
      ...adminStaticItemFragment
    }
  }
  ${adminStaticItemFragment}
`;

export default updateStaticItemMutation;
