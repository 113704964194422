import { useFormikContext } from 'formik';
import InputNumber from 'components/common/InputNumber';
import { Input } from 'formik-antd';

/*
  VariableValueInput is a component that renders an Input or InputNumber component
  depending on the type property set in the variable-form.
*/
export default function VariableValueInput({ ...props }) {
  const { values } = useFormikContext();
  const isTypeText = values.type === 'text';
  return isTypeText ? <Input {...props} /> : <InputNumber min={0} max={Infinity} {...props} />;
}
