const Line = ({ data, fill }) => {
  const path = data.reduce((prev, curr, index) => {
    return `${prev}L${index},${100 - curr.value}`;
  }, 'M0,100');
  return <path d={`${path}L${data.length - 1},100`} shapeRendering="crispEdges" fill={fill} />;
};

export const CPUSvg = (props) => {
  const { data } = props;
  return (
    <svg
      style={{ height: 200, width: 100, transform: 'scale(2)', transformOrigin: 'top left' }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <Line data={data.map(({ date, allLoad }) => ({ date, value: allLoad }))} fill="#008200" />
      <Line data={data.map(({ date, systemLoad }) => ({ date, value: systemLoad }))} fill="#BA0000" />
      <line x1={0} y1={0} x2={data.length - 0.5} y2={0} stroke="black" />
      <line
        x1={0}
        y1={100}
        x2={data.length - 0.5}
        y2={100}
        stroke="black"
        shapeRendering="crispEdges"
        strokeWidth="0.5px"
      />
      <line x1={0} y1={0} x2={0} y2={100} stroke="black" shapeRendering="crispEdges" />
      <line
        x1={data.length - 0.5}
        y1={0}
        x2={data.length - 0.5}
        y2={100}
        stroke="black"
        shapeRendering="crispEdges"
        strokeWidth="0.5px"
      />
    </svg>
  );
};

export const CPUSvgs = ({ data }) => {
  const perCore = [];
  (data || []).forEach((e) => {
    const { date, data: perCoreData } = e;
    perCoreData.forEach((core, index) => {
      const [allLoad, systemLoad] = core;
      perCore[index] = perCore[index] || [];
      perCore[index].push({ allLoad, systemLoad, date });
    });
  });
  return (
    <div>
      {perCore.map((e, index) => {
        // eslint-disable-next-line react/no-array-index-key
        return <CPUSvg key={index} data={e} />;
      })}
    </div>
  );
};
