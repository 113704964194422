import apollo from 'graphql/apollo';
import { resetThemeMutation } from 'graphql/mutations';
import { adminMyThemeQuery } from 'graphql/queries';

const resetTheme = async () => {
  return apollo.mutate({
    mutation: resetThemeMutation,
    refetchQueries: [{ query: adminMyThemeQuery }],
  });
};

export default resetTheme;
