import { FaCheck } from 'react-icons/fa';
import classNames from 'classnames';
import cc from './Done.module.less';
import SignDocumentsLayout from '../../../components/layouts/SignDocumentsLayout';
import c from './Hello.module.less';
import { Sans43px600 } from '../Text/Text';

const Done = () => {
  return (
    <SignDocumentsLayout hideProgress className={c.layout}>
      <div className={classNames(c.helloContainer, cc.doneContainer)}>
        <div className={cc.check}>
          <FaCheck />
        </div>
        <Sans43px600 textStyle={{ color: 'unset' }}>
          Well done!
          <br />
          <br />
          All documents have been signed.
          <br />
          This route is closed.
        </Sans43px600>
      </div>
    </SignDocumentsLayout>
  );
};
export default Done;
