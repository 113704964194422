import { Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import classes from './DrawerHeader.module.less';

/**
 * PdfDrawerHeader component for the PdfDrawer with a carusal file select
 * @param {Object} inputParameters - Input parameters of component
 * @param {String} inputParameters.title - Title of the current selection
 * @param {Function} inputParameters.onNextDocument - Function, which is called on press on next button
 * @param {Function} inputParameters.onPreviousDocument - Function, which is called on press on previous button
 * @param {Boolean} inputParameters.enableButtons - Should next and previous button be shown or only the title
 * @returns {JSX.Element} Component shows a title and a previous and next button to switch between documents
 * @component
 */
const PdfDrawerHeader = ({ title, onNextDocument, onPreviousDocument, enableButtons }) => {
  if (!enableButtons) return <span className={classes.title}>{title}</span>;
  return (
    <>
      {onPreviousDocument ? <Button icon={<LeftOutlined />} type="text" onClick={onPreviousDocument} /> : null}
      <span className={classes.title}>{title}</span>
      {onNextDocument ? <Button icon={<RightOutlined />} type="text" onClick={onNextDocument} /> : null}
    </>
  );
};

export default PdfDrawerHeader;
