import { adminDocumentTemplateBlocksByIdQuery } from 'graphql/queries';
import { useCachedQuery } from 'graphql/utils';
import { grabFirstGQLDataResult } from 'utils/helpers';

export const useDocumentTemplateBlocks = (documentTemplateId) => {
  const { data } = useCachedQuery(adminDocumentTemplateBlocksByIdQuery, {
    variables: {
      documentTemplateId,
    },
    fetchPolicy: 'cache-only',
  });
  const blocks = grabFirstGQLDataResult(data)?.blocks;
  return { blocks };
};

export default useDocumentTemplateBlocks;
