import { Layout, Button, Divider, Popover } from 'antd';
import { Link } from 'react-router-dom';
import routePaths from 'router/route-paths';
import cn from 'classnames';
import { AuditOutlined, LogoutOutlined, PlusOutlined } from '@ant-design/icons';
import { Trans, useTranslation } from 'react-i18next';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import useLogout from 'hooks/auth/useLogout';
import MediaQuery from 'react-responsive';
import { memo, useCallback, useMemo, useState } from 'react';
import { MdMenu } from 'react-icons/md';
import HideByRoute from 'components/common/HideByRoute';
import equal from 'fast-deep-equal/es6/react';
import roles from 'constants/roles';
import CurrentUserDropdown, { fullNameFromUser, UserAvatar } from './CurrentUserDropdown';
import s from './AppHeader.module.less';

const { Header } = Layout;
function AvatarCollapseMenu({ onHide }) {
  const [me] = useCurrentUser();
  const { i18n } = useTranslation();
  return (
    <div className={s.avatarInfo}>
      <div className="ant-dropdown-link" aria-hidden="true" onClick={onHide}>
        <UserAvatar user={me} />
      </div>
      <span>
        <Trans i18nKey="CurrentUserDropdown.loggedInAs" name={fullNameFromUser(me)}>
          Logged in as <b>{{ name: fullNameFromUser(me) }}</b>
        </Trans>
      </span>
      <span>
        <Trans i18nKey="CurrentUserDropdown.role" role={me.role}>
          Role: <b>{{ role: me.role }}</b>
        </Trans>
      </span>
      {me?.locale !== 'de' ? (
        <div
          aria-hidden="true"
          onClick={() => {
            i18n.changeLanguage(i18n.language === 'en' ? 'de' : 'en');
          }}
        >
          <Trans i18nKey="CurrentUserDropdown.locale">
            Locale: <b>{i18n.language}</b>
          </Trans>
        </div>
      ) : null}
    </div>
  );
}

const Content = ({ onHide }) => {
  const logout = useLogout();
  const { t } = useTranslation();
  const [me] = useCurrentUser();
  const isViewer = useMemo(() => me?.role === roles.VIEWER, [me]);
  return (
    <div className={s.collapseMenu} aria-hidden="true">
      <AvatarCollapseMenu onHide={onHide} />
      <Divider />
      {isViewer || (
        <HideByRoute path={routePaths.shoppingCart}>
          <Link to={routePaths.shoppingCart} onClick={onHide}>
            <div>
              <PlusOutlined />
              {t('ShoppingCartEntriesPage.createShoppingCart')}
            </div>
          </Link>
        </HideByRoute>
      )}
      <Link to={routePaths.accountSetting}>
        <div>
          <AuditOutlined />
          {t('NavBar.accountSetting.title')}
        </div>
      </Link>
      <div className={s.logout} aria-hidden="true" onClick={logout}>
        <LogoutOutlined />
        {t('CurrentUserDropdown.logout')}
      </div>
    </div>
  );
};
function AppHeader() {
  const [visible, setVisible] = useState(false);
  const hide = useCallback(() => {
    setVisible(false);
  }, []);
  return (
    <Header className={cn(s.headerContainer, 'xs-pl-0 xs-pr-0')}>
      <div className={cn('container', s.header)}>
        <MediaQuery maxWidth={530}>
          <div className={s.headerRight}>
            <Popover
              placement="bottomRight"
              content={<Content onHide={hide} />}
              trigger="click"
              visible={visible}
              onVisibleChange={(value) => setVisible(value)}
            >
              <Button>
                <MdMenu size={25} />
              </Button>
            </Popover>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={531}>
          <RightMenu />
        </MediaQuery>
      </div>
    </Header>
  );
}

function RightMenu() {
  const { t } = useTranslation();

  const [me] = useCurrentUser();
  const isViewer = useMemo(() => me?.role === roles.VIEWER, [me]);
  return (
    <div className={s.headerRight}>
      {isViewer || (
        <HideByRoute path={routePaths.shoppingCart}>
          <Link to={routePaths.shoppingCart}>
            <Button icon={<PlusOutlined />} type="primary">
              {t('ShoppingCartEntriesPage.createShoppingCart')}
            </Button>
          </Link>
        </HideByRoute>
      )}
      <CurrentUserDropdown />
    </div>
  );
}

export default memo(AppHeader, equal);
