import { setGraphqlErrors } from 'components/common/ErrorComponent';
import apollo from 'graphql/apollo';
import { getUploadFileLinkMutation } from 'graphql/mutations';
import i18n from 'i18n';

const getUploadFileLink = ({ name, parentId, parentType, size }) =>
  apollo
    .mutate({ mutation: getUploadFileLinkMutation, variables: { name, parentId, parentType, size } })
    .catch((error) => {
      throw setGraphqlErrors({
        error,
        errorResolver: { Duplicated: ['name', i18n.t('admin.addCategoryModal.duplicatedErrorMessage')] },
      });
    });
export default getUploadFileLink;
