import { gql } from '@apollo/client';
import { userShoppingCartViewFragment } from 'graphql/fragments';

const userShoppingCartViewQuery = gql`
  query userShoppingCartViewQuery($_id: ID!) {
    userShoppingCart(_id: $_id) {
      ...userShoppingCartViewFragment
    }
  }
  ${userShoppingCartViewFragment}
`;

export default userShoppingCartViewQuery;
