import EmailTemplateSelect from 'components/common/EmailTemplateSelect';

const RemindSigneeAboutDigitalSignatureProcessTemplateSelect = ({ t }) => {
  return (
    <EmailTemplateSelect
      fieldName="remindSigneeAboutDigitalSignatureProcessTemplate"
      label={t(
        'admin.Setting.DigitalSignaturePreferences.automateSignatureProcess.remindSigneeAboutDigitalSignatureProcessTemplate.label',
      )}
      placeholder={t(
        'admin.Setting.DigitalSignaturePreferences.automateSignatureProcess.remindSigneeAboutDigitalSignatureProcessTemplate.placeholder',
      )}
      tooltip={t(
        'admin.Setting.DigitalSignaturePreferences.automateSignatureProcess.remindSigneeAboutDigitalSignatureProcessTemplate.tooltip',
      )}
    />
  );
};

export default RemindSigneeAboutDigitalSignatureProcessTemplateSelect;
