import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import s from './Footer.module.css';

const { Footer } = Layout;

export default function DefaultFooter({ className }) {
  const { t } = useTranslation();

  return (
    <Footer className={cn(className, s.footer)}>
      {t('FooterComponent.copyright', { year: new Date().getFullYear() })}
    </Footer>
  );
}
