import { gql } from '@apollo/client';
import { currentUserFragment } from 'graphql/fragments';

const currentUserQuery = gql`
  query me {
    me {
      ...currentUserFragment
    }
  }
  ${currentUserFragment}
`;
export default currentUserQuery;
