import { useTranslation } from 'react-i18next';
import { Button, Card, Steps, Alert } from 'antd';
import { Form, SubmitButton } from 'formik-antd';
import { useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { useYourLicense } from 'graphql/hooks';
import { BulbOutlined, FileExcelOutlined } from '@ant-design/icons';
import { find } from 'lodash';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import Step4 from './components/Step4';
import companyFields from './constants/companyFields';
import classes from './ImportCompaniesForm.module.less';

const { Step } = Steps;

const alerts = (t) => [
  {
    step: 0,
    message: t('admin.Setting.ImportCompanies.alert.step1.message'),
    description: (
      <a href={t('admin.Setting.ImportCompanies.alert.step1.linkDescription')} target="_blank" rel="noreferrer">
        {t('admin.Setting.ImportCompanies.alert.step1.description')} <FileExcelOutlined />
      </a>
    ),
  },
  {
    step: 2,
    message: t('admin.Setting.ImportCompanies.alert.step3.message'),
    description: () => t('admin.Setting.ImportCompanies.alert.step3.description'),
  },
];

const CartActions = ({ currentStep, stepsProps, next, prev }) => {
  const { t } = useTranslation();
  return (
    <div className={classes.stepActions}>
      <div className={classes.actionButtonsGroupe}>
        {currentStep > 0 ? (
          <Button style={{ marginRight: '8px' }} onClick={prev}>
            {t('admin.Setting.ImportCompanies.navigationButtons.previous')}
          </Button>
        ) : null}
        {currentStep < 4 - 1 ? (
          <Button type="primary" disabled={!stepsProps[currentStep + 1].allow} onClick={next}>
            {t('admin.Setting.ImportCompanies.navigationButtons.next')}
          </Button>
        ) : (
          <SubmitButton disabled={!stepsProps[currentStep + 1].allow}>
            {t('admin.Setting.ImportCompanies.navigationButtons.import')}
          </SubmitButton>
        )}
      </div>
    </div>
  );
};

const ImportCompaniesForm = ({ setCompaniesData, companiesData, selectedRowKeys, setSelectedRowKeys }) => {
  const { t } = useTranslation();
  const [fields, setFields] = useState([]);
  const [currentStep, setCurrent] = useState(0);
  const alert = useMemo(() => find(alerts(t), { step: currentStep }), [currentStep, t]);
  const [numberCompaniesInsert, setNumberCompaniesInsert] = useState(0);
  const { data: license } = useYourLicense();
  const { isValid } = useFormikContext();
  const stepsProps = [
    { allow: true, skip: false },
    { allow: fields.length, skip: false },
    { allow: isValid, skip: false },
    { allow: isValid, skip: false },
    {
      allow:
        selectedRowKeys?.length &&
        // eslint-disable-next-line no-unsafe-optional-chaining
        !(license?.nrOfCompaniesLimit - license?.nrOfCompaniesCreated < numberCompaniesInsert),
    },
  ];
  const next = () => {
    let newStep = currentStep + 1;
    while (newStep < stepsProps.length - 1 && stepsProps[newStep].skip) newStep += 1;
    setCurrent(newStep);
  };
  const prev = () => {
    let newStep = currentStep - 1;
    while (newStep > 0 && stepsProps[newStep].skip) newStep -= 1;
    setCurrent(newStep);
  };
  return (
    <Form layout="vertical">
      {alert ? (
        <Alert
          icon={<BulbOutlined />}
          showIcon
          closable
          className="alert-info"
          message={alert.message}
          description={alert.description}
          type="info"
        />
      ) : null}
      <Card
        title={
          <Steps current={currentStep}>
            <Step title={t('admin.Setting.ImportCompanies.step1')} />
            <Step title={t('admin.Setting.ImportCompanies.step2')} />
            <Step title={t('admin.Setting.ImportCompanies.step3')} />
            <Step title={t('admin.Setting.ImportCompanies.step4')} />
          </Steps>
        }
        actions={[<CartActions currentStep={currentStep} stepsProps={stepsProps} next={next} prev={prev} />]}
      >
        <Step1
          companyFields={companyFields}
          currentStep={currentStep}
          setCompaniesData={setCompaniesData}
          setFieldsFromCsv={setFields}
        />
        <Step2 companyFields={companyFields} fields={fields} currentStep={currentStep} />
        <Step3 currentStep={currentStep} />
        <Step4
          companiesData={companiesData}
          companyFields={companyFields}
          currentStep={currentStep}
          license={license}
          numberCompaniesInsert={numberCompaniesInsert}
          selectedRowKeys={selectedRowKeys}
          setNumberCompaniesInsert={setNumberCompaniesInsert}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      </Card>
    </Form>
  );
};

export default ImportCompaniesForm;
