import { useMemo } from 'react';

export const usePanelProps = ({ panelProps, recordId, onOpenCollapse, extraPanelProps }) => {
  return useMemo(() => {
    const props = { ...panelProps };
    if (panelProps?.extra) {
      const Extra = panelProps.extra;
      props.extra = <Extra _id={recordId} onOpen={onOpenCollapse} {...extraPanelProps} />;
    }
    return props;
  }, [extraPanelProps, onOpenCollapse, panelProps, recordId]);
};

export const useCollapseProps = ({ collapseProps, record }) => {
  return useMemo(() => {
    const props = { ...collapseProps };

    if (collapseProps?.defaultActiveKey) props.defaultActiveKey = collapseProps.defaultActiveKey(record);

    return props;
  }, [collapseProps, record]);
};

export const usePanelTitle = ({ title, record }) => {
  return useMemo(() => {
    if (typeof title === 'function') {
      return title(record);
    }
    return title;
  }, [record, title]);
};
