import { gql } from '@apollo/client';

const createShoppingCartRevisionMutation = gql`
  mutation createShoppingCartRevisionMutation($prevCartId: ID!, $shoppingCart: ShoppingCartInput!) {
    createShoppingCartRevision(prevCartId: $prevCartId, shoppingCart: $shoppingCart) {
      _id
      digitalSignatureState
      signatureData {
        companySignees {
          email
          firstName
          lastName
          id
        }
        tenantSignees {
          email
          firstName
          lastName
          id
        }
        signedCompanySignees {
          signeeEmail
          signeeName
          relatedContactObject
          signDate
          signIP
        }
        signedTenantSignees {
          signeeEmail
          signeeName
          relatedTenantObject
          signDate
          signIP
        }
      }
    }
  }
`;

export default createShoppingCartRevisionMutation;
