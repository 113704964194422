import { gql } from '@apollo/client';

const signeeSignMutation = gql`
  mutation signeeSignMutation(
    $identityCheck: Boolean!
    $checkDocumentsSeen: Boolean!
    $checkNoticeOfCancellation: Boolean
  ) {
    sign(
      identityCheck: $identityCheck
      checkDocumentsSeen: $checkDocumentsSeen
      checkNoticeOfCancellation: $checkNoticeOfCancellation
    ) {
      success
      signaturePng
    }
  }
`;

export default signeeSignMutation;
