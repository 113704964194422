import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useOnboardingContext } from '../OnboardingContext';
import c from '../Onboarding.module.less';

export default function WizardNavigationButtons({ isSubmitting, onNext, onPrev }) {
  const { steps, currentIndex, setCurrentIndex, isSubmitting: isFinalSubmitting } = useOnboardingContext();

  const { t } = useTranslation();
  const prev = () => {
    if (typeof onPrev === 'function') onPrev();
    setCurrentIndex(currentIndex - 1);
  };

  return (
    <div className={c.stepsAction}>
      {currentIndex > 0 ? (
        <Button style={{ margin: '0 8px' }} onClick={prev}>
          {t('onboarding.navigationButtons.previous')}
        </Button>
      ) : null}
      <Button
        htmlType="submit"
        onClick={onNext}
        type="primary"
        disabled={isSubmitting || isFinalSubmitting}
        loading={isSubmitting || isFinalSubmitting}
      >
        {currentIndex === steps.length - 1
          ? t('onboarding.navigationButtons.done')
          : t('onboarding.navigationButtons.next')}
      </Button>
    </div>
  );
}
