import { gql } from '@apollo/client';

const isSepaMandateStepDoneQuery = gql`
  query isSepaMandateStepDone {
    isSepaMandateStepDone {
      isDone
    }
  }
`;

export default isSepaMandateStepDoneQuery;
