import { useTranslation } from 'react-i18next';
import { FaFile } from 'react-icons/fa';
import Card from 'components/common/Card';
import { useMemo } from 'react';
import { useParams } from 'react-router';
import { CreationMode } from 'constants/shoppingCart';
import DocumentTypesList from './DocumentTypesList';
import { PDFTypeFormikSelect } from './ShoppingCartPdfTypesSelect';

/**
 * Displays a card containing a form to select a PDF type and a list of document types. (Needs a document template for shopping cart context)
 * @component
 * @param {Object} props - Props passed to the component.
 * @param {Object} props.buttonState - State of the button.
 * @param {boolean} props.isSubmitting - Indicates if the form is currently being submitted.
 * @param {React.Ref} props.currentSubmitTypeRef - A reference to the current submit type.
 * @returns {JSX.Element} shows pdf types
 */
const PdfTypes = ({ buttonState, isSubmitting, currentSubmitTypeRef }) => {
  const { t } = useTranslation();
  const params = useParams();
  const isAllowHistoricalDocumentTemplates = useMemo(() => params.mode === CreationMode.INDEPENDENT, [params.mode]);

  return (
    <Card bordered title={t('user.ShoppingCart.DocumentTypes.label')} icon={<FaFile />}>
      <PDFTypeFormikSelect isAllowHistoricalDocumentTemplates={isAllowHistoricalDocumentTemplates} />
      <br />
      <DocumentTypesList
        buttonState={buttonState}
        isSubmitting={isSubmitting}
        currentSubmitTypeRef={currentSubmitTypeRef}
      />
    </Card>
  );
};
export default PdfTypes;
