import { memo } from 'react';
import { Page } from 'react-pdf';
import equal from 'fast-deep-equal/es6/react';
import concatClassNames from 'utils/classNames';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import classes from './RenderedPage.module.less';

/**
 * RenderedPage component for one rendered page
 * @param {Object} inputParameters - Input parameters for component
 * @param {Number} inputParameters.scale - Scale value for page
 * @param {Number} inputParameters.pageHeight - Height for page
 * @param {Number} inputParameters.pageNumber - Page number
 * @param {Object} inputParameters.style - Style object for surrounding contaier
 * @returns {JSX.Element} rendered page component for pages list
 * @component
 */
const RenderedPage = ({ scale, pageHeight, pageNumber, style }) => {
  return (
    <div style={style}>
      <Page
        pageNumber={pageNumber}
        className={concatClassNames(classes.Page, 'RenderedPage_Page')}
        height={pageHeight}
        renderTextLayer={false}
        scale={scale}
      />
    </div>
  );
};

const MemorizedRenderedPage = memo(RenderedPage, equal);

export default MemorizedRenderedPage;
