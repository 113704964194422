/* eslint-disable no-shadow */
import { PlusOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Tooltip } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import equal from 'fast-deep-equal/es6/react';
import { Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Transforms } from 'slate';
import { useSlate } from 'slate-react';
import { isMentionActive } from './plugins/withMentions';
import classes from './RichEditorPlaceholders.module.less';

export const useInsertRichEditor = () => {
  const editor = useSlate();

  const insertNode = (node, { isEditableMention } = {}) => {
    Transforms.insertNodes(editor, node);
    Transforms.move(editor, isEditableMention ? { unit: 'offset', reverse: true } : {});
  };
  const insertMention = ({ text, ...props } = {}) => {
    if (isMentionActive(editor)) return;
    const { mentionType, _id } = props;
    const isEditableMention = mentionType === 'formula_function' && _id === 'text';
    const mention = {
      type: 'mention',
      character: text,
      children: [{ text: isEditableMention ? '  ' : '' }],
      ...props,
    };
    insertNode(mention, { isEditableMention });
  };
  return { insertMention, editor, insertNode };
};
const Delimiter = () => <div className={classes.delimiter} />;

function PlaceholderSubMenu({ title, disabled, fields, insertMention, insertNode, editor }) {
  return (
    <Fragment key={title}>
      <SubMenu disabled={disabled} selectedKeys={undefined} title={title}>
        {fields.map(({ title, onClick, disabled, disabledTooltip, fields: subFields } = {}) =>
          subFields?.length ? (
            PlaceholderSubMenu({ fields: subFields, title, insertMention, insertNode, editor })
          ) : (
            <Fragment key={title}>
              <Menu.Item active disabled={disabled} onClick={() => onClick({ insertMention, insertNode, editor })}>
                <Tooltip title={disabled ? disabledTooltip : title}>
                  <div className={classes.itemMenu}>{title}</div>
                </Tooltip>
              </Menu.Item>
              <Delimiter />
            </Fragment>
          ),
        )}
      </SubMenu>
      <Delimiter />
    </Fragment>
  );
}

// schema: { title: String, disabled: Boolean, fields: { disabled: Boolean, name: String, onClick: ({ insertMention, insertNode, editor }) => void }}
const CustomMenu = ({ schema, insertMention, insertNode, editor }) => {
  return (
    <Menu selectedKeys={undefined}>
      {schema.map((field) => {
        if (!field.fields.length) return null;
        return PlaceholderSubMenu({ ...field, insertMention, insertNode, editor });
      })}
    </Menu>
  );
};

const RichEditorPlaceholdersMemo = ({ schema = [] }) => {
  const { insertMention, editor, insertNode } = useInsertRichEditor();
  const { t } = useTranslation();

  return (
    <Dropdown
      overlay={<CustomMenu schema={schema} insertMention={insertMention} insertNode={insertNode} editor={editor} />}
    >
      <div className={`${classes.placeholder} ant-btn`}>
        <PlusOutlined />
        <span>{t('admin.itemModal.inputs.pricingFormula.insertVariableMenu.title')}</span>
        <i className="ant-menu-submenu-arrow" />
      </div>
    </Dropdown>
    /* <Button
      onMouseDown={(event) => {
        event.preventDefault();
        insertMention({ text: '123 123' });
      }}
    >
      123
    </Button> */
  );
};
export const RichEditorPlaceholders = memo(RichEditorPlaceholdersMemo, equal);
