import FormItem from 'components/common/FormItem';
import { Input } from 'formik-antd';

export const TitleMonthlyPayment = ({ t }) => {
  return (
    <FormItem
      label={t('admin.Setting.MonthlyPaymentPreferences.generalSettings.monthlyPaymentTitle.label')}
      name="monthlyPaymentTitle"
      tooltip={t('admin.Setting.MonthlyPaymentPreferences.generalSettings.monthlyPaymentTitle.tooltip')}
    >
      <Input name="monthlyPaymentTitle" />
    </FormItem>
  );
};
