import apollo from 'graphql/apollo';
import { deleteStatusMutation } from 'graphql/mutations';
import { userGetStatusesQuery } from 'graphql/queries';
import i18n from 'i18n';
import confirmModal from 'utils/confirmModal';
import { grabFirstGQLDataResult, toastGraphQLError } from 'utils/helpers';
import toast from 'utils/toast';

const deleteStatus = async ({ _id, type }) =>
  apollo.mutate({
    mutation: deleteStatusMutation,
    variables: { _id },
    update: (cache, { data }) => {
      const isDeleteStatus = grabFirstGQLDataResult(data);
      if (isDeleteStatus) {
        const cachedData = cache.readQuery({ query: userGetStatusesQuery, variables: { type } });

        const statuses = grabFirstGQLDataResult(cachedData);
        const newStatuses = statuses.filter((status) => status._id !== _id);

        cache.writeQuery({
          query: userGetStatusesQuery,
          variables: {
            type,
          },
          data: {
            getStatuses: newStatuses,
          },
        });
      }
    },
  });

export const deleteStatusWithConfirmation = ({ _id, isDefault, type }) =>
  isDefault
    ? toast.error(i18n.t('BackendErrors.status.removeDefaultStatus'))
    : confirmModal({
        cancelText: i18n.t('admin.deleteStatusConfirmation.cancel'),
        maskClosable: true,
        okText: i18n.t('admin.deleteStatusConfirmation.ok'),
        okType: 'danger',
        onOk: () => deleteStatus({ _id, type }).then(toast.successDefault).catch(toastGraphQLError),
        title: i18n.t('admin.deleteStatusConfirmation.title'),
      });
export default deleteStatus;
