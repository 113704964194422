import { useState, useRef } from 'react';
import { FullscreenModeWrapper } from '../FullscreenMode';
import PdfView from './PdfView';

/**
 * PdfViewer component for pdf drawer to show pdf documents
 * @param {Object} inputParameters - Input parameters of component
 * @param {Uint8Array} inputParameters.data - Binary data of pdf document as Uint8Array or null
 * @param {Object} inputParameters.documentInfo - Informations about loading/loaded document
 * @param {String} inputParameters.documentInfo.documentTemplateName - Name of document template related to loading/loaded document
 * @param {Boolean} inputParameters.widthControlled - Is component in width controlled mode loaded
 * @param {Object} inputParameters.error - Document loading error or null/undefined
 * @param {String} inputParameters.error.message - Error message
 * @param {Number|String} inputParameters.error.status - Error status (like 404 or Network error)
 * @returns {JSX.Element} pdf viewer for pdf drawer, which shows pdf documents inline with scroll, navigation, zoom and fullscreen mode
 * @component
 */
const PdfViewer = ({ data, documentInfo, widthControlled, error }) => {
  const [numPages, setNumPages] = useState(null);
  const documentControlRef = useRef();

  const onItemClick = ({ pageNumber }) => {
    if (documentControlRef && documentControlRef.current) documentControlRef.current.navigateToPage(pageNumber);
  };

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages);
  };

  return (
    <FullscreenModeWrapper data={data} documentInfo={documentInfo}>
      {({ openFullscreenMode }) => (
        <PdfView
          openFullscreenMode={openFullscreenMode}
          data={data}
          documentControlRef={documentControlRef}
          widthControlled={widthControlled}
          onDocumentLoadSuccess={onDocumentLoadSuccess}
          onItemClick={onItemClick}
          documentInfo={documentInfo}
          numPages={numPages}
          error={error}
        />
      )}
    </FullscreenModeWrapper>
  );
};

export default PdfViewer;
