import { gql } from '@apollo/client';
import { adminDocumentTemplateListForImportModalFragment } from 'graphql/fragments';

const adminDocumentTemplateListForImportModalQuery = gql`
  query adminDocumentTemplateListForImportModalQuery($isLibrary: Boolean) {
    documentTemplates(isLibrary: $isLibrary) {
      ...adminDocumentTemplateListForImportModalFragment
    }
  }
  ${adminDocumentTemplateListForImportModalFragment}
`;

export default adminDocumentTemplateListForImportModalQuery;
