import { FaCheckCircle } from 'react-icons/fa';
import { Sans22px400, Sans30px400, Sans30px600 } from './Text/Text';
import Note from './Note';
import { Br100 } from './Text/Br';
import Checkbox from './Checkbox';

const AdditionalService = ({ item }) => {
  1;

  return (
    <div>
      <Note title={<Sans30px600 mobileTextStyle={{ fontSize: 14 }}>{item.name}</Sans30px600>}>
        <Sans30px400 mobileTextStyle={{ fontSize: 14 }}>
          The {item.name} has a lot of benefits. So here the benefits:
        </Sans30px400>
        <Br100 />
        {item.benefits.map((b) => {
          return (
            <Sans30px400
              iconStyle={{ fontSize: 18, marginTop: 4 }}
              mobileIconStyle={{ fontSize: 15 }}
              mobileTextStyle={{ fontSize: 14 }}
              icon={<FaCheckCircle />}
              key={b._id}
            >
              {b.text}
            </Sans30px400>
          );
        })}
        <div>
          <Sans22px400 textStyle={{ marginLeft: 'auto' }}>price: + monthly {item.pricing} €</Sans22px400>
        </div>
      </Note>
      <Br100 />
      <Checkbox>
        <Sans30px400>Yes, I want to additionally book „{item.name}“</Sans30px400>
      </Checkbox>
      <Br100 />
    </div>
  );
};
export default AdditionalService;
