import { gql } from '@apollo/client';

const superAdminTenantActionFragment = gql`
  fragment superAdminTenantActionFragment on TenantAction {
    _id
    action
    createdAt
    createdBy {
      _id
      fullName
    }
  }
`;

export default superAdminTenantActionFragment;
