import PageContainer from 'components/layout/PageContainer';
import equal from 'fast-deep-equal/es6/react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import UserList from './UserList';
import UserInvitation from './UserInvitation';
import ManageUsersHelperWidget from './ManageUsersHelperWidget';

function ManageUsers() {
  return (
    <>
      <UserInvitation />
      <UserList />
    </>
  );
}

function ManageUsersLayout() {
  const { t } = useTranslation();
  return (
    <PageContainer title={t('admin.ManageUsers.title')} left={<ManageUsersHelperWidget />}>
      <ManageUsers />
    </PageContainer>
  );
}

export default memo(ManageUsersLayout, equal);
