import apollo from 'graphql/apollo';
import { importCategoriesFromLibraryMutation } from 'graphql/mutations';
import { adminCategoryListQuery } from 'graphql/queries';
import { sortBy } from 'lodash';
import { grabFirstGQLDataResult } from 'utils/helpers';

const importCategoriesFromLibrary = async (categories) =>
  apollo.mutate({
    mutation: importCategoriesFromLibraryMutation,
    variables: { input: categories },
    update: (cache, { data }) => {
      const importedCategories = grabFirstGQLDataResult(data);
      const cachedData = cache.readQuery({ query: adminCategoryListQuery });
      cache.writeQuery({
        query: adminCategoryListQuery,
        data: {
          categories: sortBy([...importedCategories, ...cachedData.categories], 'order').reverse(),
        },
      });
    },
  });

export default importCategoriesFromLibrary;
