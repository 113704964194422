import { gql } from '@apollo/client';
import { userShoppingCartHistoryFragment } from 'graphql/fragments';

const userHistoricRevisionsViewQuery = gql`
  query userHistoryViewQuery($_id: ID!) {
    userShoppingCart(_id: $_id) {
      _id
      history {
        ...userShoppingCartHistoryFragment
      }
    }
  }
  ${userShoppingCartHistoryFragment}
`;

export default userHistoricRevisionsViewQuery;
