import { useCachedLazyQuery } from 'graphql/utils';

import { useEffect, useMemo } from 'react';

const useComparingData = ({ queries = [], shoppingCartId, fetchPolicy = 'network-only', isFetchGlobal }) => {
  const [globalQuery, independentQuery = globalQuery] = queries;
  // global
  // region TODO: enable comparison feature; remove the section
  const [getGlobalConfiguration, { data: global, loading: globalLoading, error: globalError, called: globalCalled }] =
    useCachedLazyQuery(globalQuery, {
      fetchPolicy,
      variables: { isLibrary: false },
    });
  // endregion

  // region TODO: enable comparison feature
  // const {
  //   data: global,
  //   loading: globalLoading,
  //   error: globalError,
  // } = useCachedQuery(globalQuery, { fetchPolicy, variables: { isLibrary: false } });
  // endregion

  // independent
  const [
    getIndependentConfiguration,
    { data: independent, loading: independentLoading, error: independentError, called: independentCalled },
  ] = useCachedLazyQuery(independentQuery, {
    fetchPolicy,
    variables: {
      isLibrary: false,
      shoppingCartId,
      _id: shoppingCartId,
    },
  });
  useEffect(() => {
    if (!independentCalled && shoppingCartId) {
      getIndependentConfiguration();
    }
    // region TODO: enable comparison feature; remove the section
    else if (!globalCalled && (isFetchGlobal || !shoppingCartId)) {
      getGlobalConfiguration();
    }
    // endregion
  }, [
    fetchPolicy,
    getGlobalConfiguration,
    getIndependentConfiguration,
    globalCalled,
    independentCalled,
    isFetchGlobal,
    shoppingCartId,
  ]);

  const error = useMemo(() => globalError || independentError, [globalError, independentError]);
  const loading = useMemo(
    () =>
      globalLoading ||
      independentLoading ||
      (shoppingCartId && !independentCalled) ||
      ((!shoppingCartId || isFetchGlobal) && !globalCalled),
    [globalCalled, globalLoading, independentCalled, independentLoading, isFetchGlobal, shoppingCartId],
  );
  const data = useMemo(() => {
    // region TODO: enable comparison feature; remove the section
    if (isFetchGlobal && shoppingCartId) {
      if (independent && global) return { independent, global };
    } else if (independent || global) return { global: shoppingCartId ? independent : global };
    return null;
    // endregion

    // region TODO: enable comparison feature
    // if (!shoppingCartId) return { global };
    // if (independent && global) return { independent, global };
    // return null;
    // endregion
  }, [global, independent, isFetchGlobal, shoppingCartId]);

  return { loading, error, ...data };
};

export default useComparingData;
