/* eslint-disable no-underscore-dangle */
import { useState, useCallback } from 'react';
import { useMutation, useApolloClient } from '@apollo/client';
import first from 'lodash/first';
import { loginAsInternalSigneeMutation } from 'graphql/mutations';
import { signingContextQuery } from 'graphql/queries';
import { isEqual } from 'lodash';

export const SIGNING_ACCESS_TOKEN_KEY = 'signing-access-token';

/**
 * @typedef {Array} LoginInternalSigneeReturn
 * @property {Function} - async function to login current user as signee (params variables for the mutation) (waits for loading mutation if called multiple times with same props)
 * @property {Boolean} - is mutation triggered
 */

/**
 * Prepares method to login current user as signee
 * @returns {LoginInternalSigneeReturn} returns as first item the login function and as second item the loading state of the mutation
 */
const useLoginInternalSignee = () => {
  const [isMutating, setIsMutating] = useState(false);
  const [mutatingPromise, setMutatingPromise] = useState(null);
  const [variablesOfLastRequest, setVariablesOfLastRequest] = useState(null);

  const [loginAsSignee, { loading }] = useMutation(loginAsInternalSigneeMutation);
  const client = useApolloClient();

  const login = useCallback(
    async (variables) => {
      setIsMutating(true);
      const res = await loginAsSignee({ variables });
      const resultData = first(Object.values(res.data));
      localStorage.setItem(SIGNING_ACCESS_TOKEN_KEY, resultData.authToken);
      client.writeQuery({
        query: signingContextQuery,
        data: { signingContext: { ...resultData, shoppingCart: null } },
      });
      setIsMutating(false);
      return resultData;
    },
    [loginAsSignee, client],
  );

  const waitForLoginInternalSignee = useCallback(
    async (variables) => {
      if (isEqual(variablesOfLastRequest, variables) && mutatingPromise && isMutating) return mutatingPromise;
      setVariablesOfLastRequest(variables);
      const mutationPromise = new Promise((resolve) => {
        login(variables).then((resultData) => resolve(resultData));
      });
      setMutatingPromise(mutationPromise);
      return mutationPromise;
    },
    [isMutating, login, mutatingPromise, variablesOfLastRequest],
  );

  return [waitForLoginInternalSignee, loading];
};

export default useLoginInternalSignee;
