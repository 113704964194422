import { gql } from '@apollo/client';

const notificationSubscription = gql`
  subscription Notification {
    notification {
      type
      message
      dataValues
    }
  }
`;

export default notificationSubscription;
