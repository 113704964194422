import { gql } from '@apollo/client';
import { adminCategoryListFragment } from 'graphql/fragments';

const duplicateCategoryMutation = gql`
  mutation duplicateItemMutation($_id: ID!, $name: String!, $isLibrary: Boolean) {
    duplicateCategory(_id: $_id, name: $name, isLibrary: $isLibrary) {
      ...adminCategoryListFragment
    }
  }
  ${adminCategoryListFragment}
`;

export default duplicateCategoryMutation;
