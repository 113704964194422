import { Tabs } from 'antd';

const { TabPane } = Tabs;

const ConfigurationTabs = ({ setPreviewBlockDescriptor, fieldDescriptor, buildTabs }) => {
  const groupFieldsWithSubtitles = (fields) => {
    return fields.reduce((reducedObject, currentField) => {
      if (!currentField.subtitle && !reducedObject['']) {
        // eslint-disable-next-line no-param-reassign
        reducedObject[''] = [currentField];
        return reducedObject;
      }
      if (!currentField.subtitle) {
        reducedObject[''].push(currentField);
        return reducedObject;
      }
      if (reducedObject[currentField.subtitle]) {
        reducedObject[currentField.subtitle].push(currentField);
        return reducedObject;
      }
      // eslint-disable-next-line no-param-reassign
      reducedObject[currentField.subtitle] = [currentField];
      return reducedObject;
    }, {});
  };

  return (
    <Tabs
      defaultActiveKey="1"
      destroyInactiveTabPane
      onChange={(key) => {
        const tab = fieldDescriptor.tabs.find((d) => d.name === key);
        setPreviewBlockDescriptor(tab);
      }}
    >
      {fieldDescriptor.tabs.map((tab) => (
        <TabPane tab={tab.displayName} key={tab.name}>
          {Object.entries(groupFieldsWithSubtitles(tab.props)).map(([subtitle, fields]) =>
            buildTabs({ subtitle, fields }),
          )}
        </TabPane>
      ))}
    </Tabs>
  );
};

export default ConfigurationTabs;
