/**
 * This hook saves the time of the last fetching of the query from the server
 * and only fetches the data from server if no data is in cache or the provided time is expired
 * the query needs a field for the timeOfLastFetching
 */

import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client';

const useCurrentlyCachedQuery = (query, options, timeForExpirationInSeconds = 60) => {
  const apolloClient = useApolloClient();
  const { data, loading, error } = useQuery(query, { ...options, fetchPolicy: 'cache-only' });
  const [getQueryFromServer, { data: serverData, loading: waitForResponse, error: serverError }] = useLazyQuery(query, {
    ...options,
    fetchPolicy: 'cache-and-network', // used to get result from server and client fields
  });

  if (serverData) {
    serverData.timeOfLastFetching = new Date(Date.now());
    apolloClient.cache.writeQuery({ query, data: serverData, variables: options.variables });
    return { data: serverData, loading: waitForResponse, error: serverError, refetch: getQueryFromServer };
  }

  if (waitForResponse || serverError) {
    return { data: serverData, loading: waitForResponse, error: serverError, refetch: getQueryFromServer };
  }

  if (!loading && !error && !data && options?.fetchPolicy !== 'cache-only' && !options?.skip) {
    getQueryFromServer();
    return { data: serverData, loading: waitForResponse, error: serverError, refetch: getQueryFromServer };
  }

  const currentDate = new Date(Date.now());
  if (
    !loading &&
    !error &&
    data.timeOfLastFetching &&
    currentDate - data.timeOfLastFetching >= timeForExpirationInSeconds &&
    options?.fetchPolicy !== 'cache-only' &&
    !options?.skip
  ) {
    getQueryFromServer();
    return { data: serverData, loading: waitForResponse, error: serverError, refetch: getQueryFromServer };
  }

  return { data, loading, error, refetch: getQueryFromServer };
};

export default useCurrentlyCachedQuery;
