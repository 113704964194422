import ItemTypes from 'constants/ItemTypes';
import { RowProvider } from 'contexts/RowContext';
import equal from 'fast-deep-equal/es6/react';
import { useDragNDrop } from 'hooks/useDragNDrop';
import { memo } from 'react';

const DraggableBodyRow = ({
  children,
  context,
  extraDropProps,
  isConnectedPreview = true,
  isTable = true,
  itemTypes,
  move,
  mutation,
  recordId,
  ...props
}) => {
  const { ref, drop, drag, preview, isDragging, isDraggingInContainer } = useDragNDrop({
    extraDropProps,
    itemTypes: itemTypes || ItemTypes.EMPTY,
    moveMutation: mutation?.move || mutation,
    moveMutationCache: move,
    recordId,
  });
  if (isConnectedPreview) preview(drop(ref));
  else drop(ref);
  const F = isTable ? 'tr' : 'div';
  return (
    <RowProvider value={{ preview, drag, isDragging, isDraggingInContainer, ...context }}>
      <F {...props} ref={ref}>
        {children}
      </F>
    </RowProvider>
  );
};

export default memo(DraggableBodyRow, equal);
