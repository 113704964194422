import sortBy from 'lodash/sortBy';
import { grabFirstGQLDataResult } from 'utils/helpers';
import apollo from 'graphql/apollo';
import { singleInputConfirmModal } from 'utils/inputConfirmModal';
import { adminCategoryListQuery, adminLibraryCategoriesQuery } from 'graphql/queries';
import i18n from 'i18n';
import { createCategoryMutation, createCommonCategoryMutation } from 'graphql/mutations';

const tAddCategory = ({ name, createMutation, queryList }) =>
  apollo.mutate({
    mutation: createMutation,
    variables: { category: { name } },
    update: (cache, { data }) => {
      const result = { ...grabFirstGQLDataResult(data), isNew: true };
      const cachedData = cache.readQuery({ query: queryList });
      cache.writeQuery({
        query: queryList,
        data: { categories: sortBy([result, ...cachedData.categories], 'order').reverse() },
      });
    },
  });
export const addCategory = ({ name }) =>
  tAddCategory({
    createMutation: createCategoryMutation,
    queryList: adminCategoryListQuery,
    name,
  });
export const addCommonCategory = ({ name }) =>
  tAddCategory({
    createMutation: createCommonCategoryMutation,
    queryList: adminLibraryCategoriesQuery,
    name,
  });

const createCategoryWrapper =
  ({ fn }) =>
  () =>
    singleInputConfirmModal({
      fieldLabel: i18n.t('admin.addCategoryModal.nameFieldLabel'),
      headerText: i18n.t('admin.addCategoryModal.addModalTitle'),
      okText: i18n.t('admin.addCategoryModal.ok'),
      cancelText: i18n.t('admin.addCategoryModal.cancel'),
      onSubmit: (name) => fn({ name: name.input }),
      fieldDescription: i18n.t('admin.addCategoryModal.nameFieldDescription'),
      errorResolver: { Duplicated: ['input', i18n.t('admin.addCategoryModal.duplicatedErrorMessage')] },
      value: { input: '' },
      forceMultiField: true,
    });
// export default createCategory;

export const createCategory = createCategoryWrapper({ fn: addCategory });
export const createCommonCategory = createCategoryWrapper({ fn: addCommonCategory });
