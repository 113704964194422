import Switch from 'components/common/Switch';

const PasswordEnabledSwitch = ({ t }) => {
  return (
    <Switch
      name="passwordEnabled"
      label={t('admin.Setting.DigitalSignaturePreferences.generalSettings.passwordEnabled.label')}
      tooltip={t('admin.Setting.DigitalSignaturePreferences.generalSettings.passwordEnabled.tooltip')}
      defaultChildren
    />
  );
};

export default PasswordEnabledSwitch;
