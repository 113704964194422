import { Tooltip, Tag } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import classes from './OnActualCostsInfo.module.less';

/**
 * OnActualCostsInfo component to show tag for service paid by actual cost for service overview step for signing process
 * @returns {JSX.Element} tag for service paid by actual costs
 * @component
 */
const OnActualCostsInfo = () => {
  const { t: translation } = useTranslation();
  return (
    <Tooltip title={translation('signing.serviceOverviewStep.onActualCostsTooltip')} overlayClassName={classes.tooltip}>
      <Tag icon={<ClockCircleOutlined />} color="default">
        {translation('signing.serviceOverviewStep.onActualCostsBadge')}
      </Tag>
    </Tooltip>
  );
};

export default OnActualCostsInfo;
