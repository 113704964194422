import { some } from 'lodash';
import { Transforms, Editor, Element as SlateElement } from 'slate';
import { useSlate } from 'slate-react';
import { Button } from 'antd';
import { Plugins } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { getSelectionRootBlocks } from './utils';

export const { LIST_TYPES } = Plugins.withMultiLevelList;

const definitions = {
  marks: ['bold', 'italic', 'underline'],
  blocks: [
    'bulleted-list',
    'heading-one',
    'heading-three',
    'heading-two',
    'list-item',
    'mention',
    'link',
    'numbered-list',
    'checked-list',
    'title',
  ],
};

export const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const getClosestListType = (editor) => {
  let listType = '';
  // eslint-disable-next-line no-unused-vars
  Editor.above(editor, {
    match: (n) => {
      if (!Editor.isEditor(n) && SlateElement.isElement(n) && LIST_TYPES.includes(n.type)) {
        listType = n.type;
        return true;
      }
      return false;
    },
  });
  return listType;
};

export const isBlockActive = (editor, format) => {
  if (LIST_TYPES.includes(format)) return format === getClosestListType(editor);
  const [match] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  });
  return match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks && marks[format];
};
const toggleBlock = (editor, format, { removeMarks, rootElement = 'div' } = {}) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => LIST_TYPES.includes(!Editor.isEditor(n) && SlateElement.isElement(n) && n.type),
    split: true,
  });
  const list = Editor.above(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && LIST_TYPES.includes(n.type),
  });
  const newProperties = {
    // eslint-disable-next-line no-nested-ternary
    type: isActive && !list ? rootElement : isList ? 'list-item' : format,
  };
  Transforms.setNodes(editor, newProperties);
  if (removeMarks) {
    Transforms.unsetNodes(editor, ['bold', 'underline', 'italic'], {
      match: Text.isText,
      at: getSelectionRootBlocks(editor),
    });
  }
  if (!isActive && isList) {
    const block = { type: format, children: [] };
    if (format !== 'checked-list') block.listType = format === 'numbered-list' ? '1' : 'disc';
    Transforms.wrapNodes(editor, block);
  }
};
export const BlockButton = ({ format, icon, disableIn, removeMarks, rootElement, customFn }) => {
  const editor = useSlate();
  const isBlock = definitions.blocks.find((f) => f === format);

  const isDisabled = some(disableIn, (type) => isBlockActive(editor, type) || isMarkActive(editor, type));
  return (
    <Button
      size="small"
      disabled={isDisabled}
      type={(isBlock ? isBlockActive : isMarkActive)(editor, format) ? 'primary' : 'default'}
      onMouseDown={(event) => {
        event.preventDefault();
        const fn = customFn || (isBlock ? toggleBlock : toggleMark);
        fn(editor, format, { removeMarks, rootElement });
      }}
      icon={icon}
    />
  );
};
