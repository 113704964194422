import { useMemo } from 'react';
import { steps as defaultSteps } from './Onboarding.utils';
import OnboardingLayout from './OnboardingLayout';
import Finish from './steps/Finish';
import { OnboardingContextProvider } from './OnboardingContext';

export default function OnboardingCompletedPage() {
  const state = useMemo(() => {
    const stepsJson = localStorage.getItem('onboardingSteps');
    const steps = stepsJson && JSON.parse(stepsJson);
    localStorage.removeItem('onboardingSteps');
    return steps;
  }, []);
  return (
    <OnboardingContextProvider steps={state || defaultSteps}>
      <OnboardingLayout currentIndex={(state || defaultSteps).length}>
        <Finish />
      </OnboardingLayout>
    </OnboardingContextProvider>
  );
}
