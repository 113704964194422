import MoreInfoWidget from 'components/common/MoreInfoWidget';
import equal from 'fast-deep-equal/es6/react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

const ImportCategoriesFromLibraryHelperWidget = ({ buttonClassName }) => {
  const { t } = useTranslation();
  return (
    <MoreInfoWidget
      buttonClassName={buttonClassName}
      buttonText={t('admin.CatalogueConfiguration.ImportHelperWidget.howUseButton')}
      title={t('admin.CatalogueConfiguration.ImportHelperWidget.modalInfo.title')}
      helpText={t('admin.CatalogueConfiguration.ImportHelperWidget.modalInfo.helpText')}
      videoCaption={t('admin.CatalogueConfiguration.ImportHelperWidget.modalInfo.videoCaption')}
      videoUrl={t('admin.CatalogueConfiguration.ImportHelperWidget.modalInfo.videoUrl')}
      imageUrl={t('admin.CatalogueConfiguration.ImportHelperWidget.modalInfo.imageUrl')}
    />
  );
};

export default memo(ImportCategoriesFromLibraryHelperWidget, equal);
