import { gql } from '@apollo/client';
import { adminDocumentTemplateListFragment } from 'graphql/fragments';

const createDocumentTemplateMutation = gql`
  mutation createDocumentTemplateMutation($documentTemplate: CreateDocumentTemplateInput!, $isLibrary: Boolean) {
    createDocumentTemplate(documentTemplate: $documentTemplate, isLibrary: $isLibrary) {
      ...adminDocumentTemplateListFragment
    }
  }
  ${adminDocumentTemplateListFragment}
`;

export default createDocumentTemplateMutation;
