import { useState } from 'react';
import { List } from 'react-virtualized';
import { isMobileDevice } from 'utils/helpers';
import {
  PIXEL_HEIGHT_A4_SCALE_1,
  PIXEL_WIDTH_A4_SCALE_1,
  calculateCurrentScale,
  convertScale,
  convertScaleToScaleStep,
} from '../../utils/ZoomUtils';
import { calculateDocumentWidth } from '../../utils/PdfViewerUtils';
import ScaleButtonBar from './ScaleButtonBar';
import RenderedPage from './RenderedPage';

/**
 * Generates a function, which generates the RenderedPage components
 * @param {Object} optionsForRenderedPages - Options for the generated rendered pages
 * @param {Number} optionsForRenderedPages.scale - Page scale for page rendering
 * @param {Number} optionsForRenderedPages.pageHeight - Height to use to render pages at scale 1
 * @returns {Function} returns generator function for RenderedPage components
 */
const GetRenderedPageComponent = ({ scale, pageHeight }) => {
  return ({ index, key, style }) => {
    const pageIndex = index + 1;
    return <RenderedPage scale={scale} pageHeight={pageHeight} pageNumber={pageIndex} key={key} style={style} />;
  };
};

/**
 * Pages component for document to render pages of a document as a list
 * @param {Object} inputParameters - Input parameters for component
 * @param {Number} inputParameters.heightForList - Height for the shown list view
 * @param {Number} inputParameters.numberOfPages - Number of pages of the document
 * @param {Number} inputParameters.heightForPage - Height for one page at scale 1
 * @param {React.Ref} inputParameters.pdfViewPaneRef - Reference to pdf view pane to attach zoom control bar
 * @param {React.Ref} inputParameters.documentControlRef - Reference to access zoom and navigation functions
 * @param {Function} inputParameters.onOpenFullscreenViewer - Function called, when open fullscreen viewer button was pressed
 * @param {String} inputParameters.listClassName - Classname for list container
 * @param {String} inputParameters.scaleButtonBarClassName - Classname for scale button bar
 * @param {Function} inputParameters.onUpdateScrollLeft - Function, which is called during zoom event to correct left scroll position
 * @returns {JSX.Element} pages for document to show pages of a pdf document
 * @component
 */
const Pages = ({
  heightForList,
  numberOfPages,
  heightForPage,
  pdfViewPaneRef,
  documentControlRef,
  onOpenFullscreenViewer,
  listClassName,
  scaleButtonBarClassName,
  onUpdateScrollLeft,
}) => {
  const [scaleScroll, setScaleScroll] = useState({
    scale: isMobileDevice() ? 1 : calculateCurrentScale({ height: heightForPage }),
    scrollTop: 0,
  });

  const rowHeight = (isMobileDevice() ? heightForPage : PIXEL_HEIGHT_A4_SCALE_1) * scaleScroll.scale;

  const updateScale = (newScale) => {
    setScaleScroll(({ scale, scrollTop }) => {
      let newScaleValue = newScale;
      if (newScaleValue instanceof Function) newScaleValue = newScaleValue(scale);
      const scaleDifference = newScaleValue / scale;
      const newScrollPosition = scrollTop * scaleDifference;
      if (onUpdateScrollLeft) onUpdateScrollLeft({ scaleDifference });
      return { scale: newScaleValue, scrollTop: newScrollPosition };
    });
  };

  const zoomIn = (value) => {
    updateScale((currentScale) => convertScaleToScaleStep(currentScale * (value / 2.5)));
  };
  const zoomOut = (value) => {
    updateScale((currentScale) => convertScaleToScaleStep(currentScale / (value / 2.5)));
  };

  const updateScaleOnPinch = (newScale, pinchEventCoord) => {
    setScaleScroll(({ scale, scrollTop }) => {
      let newScaleValue = newScale;
      if (newScaleValue instanceof Function) newScaleValue = newScaleValue(scale);
      const scaleDifference = newScaleValue / scale;
      const pinchYRelatedDocs = scrollTop + pinchEventCoord.y;
      const newScrollPosition = scaleDifference * pinchYRelatedDocs - pinchEventCoord.y;
      if (onUpdateScrollLeft) onUpdateScrollLeft({ scaleDifference, pinchEventXCoord: pinchEventCoord.x });
      return { scale: newScaleValue, scrollTop: newScrollPosition < 0 ? 0 : newScrollPosition };
    });
  };

  const zoomInPinch = (scaleValue, pinchEventCoord) => {
    updateScaleOnPinch((currentScale) => convertScale(currentScale + scaleValue), pinchEventCoord);
  };

  const zoomOutPinch = (scaleValue, pinchEventCoord) => {
    updateScaleOnPinch((currentScale) => convertScale(currentScale - scaleValue), pinchEventCoord);
  };

  const scrollHandler = ({ scrollTop }) => {
    setScaleScroll(({ scale }) => ({
      scrollTop,
      scale,
    }));
  };

  const navigateToPage = (pageNumber) => {
    const newScrollPosition = (pageNumber - 1) * rowHeight;
    setScaleScroll(({ scale }) => ({
      scale,
      scrollTop: newScrollPosition,
    }));
  };

  const renderedPageComponentOptions = {
    scale: scaleScroll.scale,
    pageHeight: isMobileDevice() ? heightForPage : PIXEL_HEIGHT_A4_SCALE_1,
  };

  // eslint-disable-next-line no-param-reassign
  documentControlRef.current = {
    navigateToPage,
    zoomIn,
    zoomOut,
    zoomInPinch,
    zoomOutPinch,
  };

  return (
    <>
      <ScaleButtonBar
        currentScale={scaleScroll.scale}
        onUpdateScale={updateScale}
        scrollPane={pdfViewPaneRef.current}
        onOpenFullscreenViewer={onOpenFullscreenViewer}
        className={scaleButtonBarClassName}
      />
      <List
        width={(isMobileDevice() ? calculateDocumentWidth(heightForPage) : PIXEL_WIDTH_A4_SCALE_1) * scaleScroll.scale}
        height={heightForList}
        rowCount={numberOfPages}
        rowHeight={rowHeight}
        onScroll={scrollHandler}
        scrollToAlignment="start"
        scrollTop={scaleScroll.scrollTop}
        rowRenderer={GetRenderedPageComponent(renderedPageComponentOptions)}
        className={listClassName}
      />
    </>
  );
};

export default Pages;
