import { gql } from '@apollo/client';
import apollo from 'graphql/apollo';

const blockFragment = gql`
  fragment blockFragment on Block {
    _id
    name
  }
`;

function getDocumentTemplateBlockById(_id) {
  const data = apollo.readFragment({
    id: `Block___${_id}`,
    fragment: blockFragment,
  });
  return data;
}

export default getDocumentTemplateBlockById;
