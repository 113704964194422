import { gql } from '@apollo/client';

const adminPdfComponentThemeFragment = gql`
  fragment adminPdfComponentThemeFragment on PdfComponentTheme {
    bold
    color
    fontFamily
    fontSize
    italic
    lineHeight
    marginBottom
    marginTop
    underline
  }
`;

export default adminPdfComponentThemeFragment;
