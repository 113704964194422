import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { doUserNeedToSign } from 'model/ShoppingCart';
import ICONS_BY_STATE from 'components/user/SigningStateIcon';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import classes from './SigningStateRow.module.less';

const SigningStateRow = ({ digitalSignatureState, signatureData }) => {
  const { t: translation } = useTranslation();
  const [me] = useCurrentUser();

  const stateColumnIcon = ICONS_BY_STATE[digitalSignatureState]({ size: '19' });

  const stateTextAdditional = doUserNeedToSign(digitalSignatureState, signatureData, me)
    ? translation('viewer.ShoppingCartView.DigitalSignature.userHasntSignedYetStateAdditional')
    : '';

  const stateColumn = (
    <div className={classes.stateColumn}>
      {stateColumnIcon}
      <p className={classes.stateColumnText}>
        {translation(`viewer.ShoppingCartView.DigitalSignature.states.${digitalSignatureState}`)}
        {stateTextAdditional}
      </p>
    </div>
  );

  return (
    <Row className={classes.stateRow}>
      <Col span={13}>{translation('viewer.ShoppingCartView.DigitalSignature.stateRowLabel')}</Col>
      <Col>{stateColumn}</Col>
    </Row>
  );
};

export default SigningStateRow;
