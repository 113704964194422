import { Button } from 'antd';
import { FaSync } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { useIsOldContactData } from '../hooks';

function SwitchCompanyButton({ onClick }) {
  const isOldContactData = useIsOldContactData();
  const params = useParams();
  if (isOldContactData || params?.id) return null;
  return <Button className="ant-button-transparent" onClick={onClick} icon={<FaSync size={20} />} />;
}
export default SwitchCompanyButton;
