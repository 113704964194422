import apollo from 'graphql/apollo';
import { createContactMutation } from 'graphql/mutations';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { userContactListQuery } from 'graphql/queries';
import { concat } from 'lodash';

const createContact = async ({
  salutation,
  firstName,
  lastName,
  email,
  position,
  phoneNumber,
  phoneNumberAlternative,
  fullSalutation,
}) =>
  apollo.mutate({
    mutation: createContactMutation,
    variables: {
      contact: {
        salutation,
        firstName,
        lastName,
        position,
        email,
        phoneNumber,
        phoneNumberAlternative,
        fullSalutation,
      },
    },
    update: (cache, { data }) => {
      const result = grabFirstGQLDataResult(data);
      const cachedData = cache.readQuery({ query: userContactListQuery });
      cache.writeQuery({
        query: userContactListQuery,
        data: {
          contacts: concat(cachedData?.contacts, result).filter(Boolean),
        },
      });
    },
  });
export default createContact;
