import { gql } from '@apollo/client';

const userContactFragment = gql`
  fragment userContactFragment on Contact {
    _id
    _apolloCacheKey
    email
    firstName
    fullSalutation
    initializationConfigDate
    lastName
    phoneNumber
    phoneNumberAlternative
    position
    salutation
  }
`;

export default userContactFragment;
