import { RichText, Calc } from '@JavaScriptSuperstars/kanzleipilot-shared';
import TranslationPrice from 'components/common/TranslationPrice';
import { useCompareToGlobalContext } from 'contexts/CompareToGlobalContext';
import equal from 'fast-deep-equal/es6/react';
import { TableMemo } from 'memo';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ActiveComparisonTypes } from 'constants/shoppingCart';
import { find } from 'lodash';
import ItemDifferences from 'components/user/compareToGlobal/ItemDifferences';
import classes from './BookedServices.module.less';
import InputFields from './components/InputFields';

const RichTextWithLabel = ({ value, label }) =>
  !RichText.isRichTextEmpty(value) ? (
    <div className={classes.officialReasonText}>
      {label} <span className="info-text">{RichText.renderRichText(value)}</span>
    </div>
  ) : null;

const bookedServiceColumns = ({ t, showDigits, isShowAction }) =>
  [
    {
      title: t('viewer.ShoppingCartView.columns.item'),
      key: 'name',
      width: '20%',
      dataIndex: 'name',
    },
    {
      title: t('viewer.ShoppingCartView.columns.inputFields'),
      key: 'inputFields',
      width: '20%',
      render: ({ customPrice, inputFields }) => (
        <InputFields isCustomPrice={Number.isFinite(customPrice)} inputFields={inputFields} showDigits={showDigits} />
      ),
      dataIndex: '',
    },
    {
      title: t('viewer.ShoppingCartView.columns.interval'),
      key: 'interval',
      width: '20%',
      dataIndex: 'paymentInterval',
      render: (paymentInterval) => t(`common.Item.paymentIntervalValue.${paymentInterval}`),
    },
    {
      title: t('viewer.ShoppingCartView.columns.price'),
      key: 'price',
      className: 'overflowWrapAnywhere',
      width: '20%',
      render: ({
        value,
        discountedValue,
        customPrice,
        activeDiscount,
        paymentInterval,
        showCalculatedPrice,
        officialReasonText,
        calculationMode,
        highlightBindingness,
      } = {}) => {
        const discountedCustomPrice = Calc.applyDiscountToValue({ value: customPrice, discount: activeDiscount });
        const priceTranslation = Calc.priceInfoAsText({
          calculationMode,
          discount: activeDiscount,
          highlightBindingness,
          paymentInterval,
          showCalculatedPrice,
          showDigits,
          value: {
            customPrice,
            discountedCustomPrice,
            priceBeforeDiscount: value,
            discountedValue,
          },
        });
        return (
          <>
            <TranslationPrice
              priceTranslation={priceTranslation}
              customPrice={customPrice}
              showCalculatedPrice={showCalculatedPrice}
              discountedCustomPrice={discountedCustomPrice}
            />
            <RichTextWithLabel
              value={officialReasonText}
              label={t('user.ShoppingCart.Category.Item.displayOfficialReasonText')}
            />
          </>
        );
      },
    },
    {
      title: t('viewer.ShoppingCartView.columns.internalNote'),
      key: 'notes',
      className: 'table-cell-break-all',
      width: '20%',
      render: ({ notesToEmployee, internalNoteToTeam }) => (
        <>
          <RichTextWithLabel
            value={notesToEmployee}
            label={t('user.ShoppingCart.Category.Item.displayNotesToEmployee')}
          />
          <RichTextWithLabel
            value={internalNoteToTeam}
            label={t('user.ShoppingCart.Category.Item.displayInternalNoteToTeam')}
          />
        </>
      ),
    },
    isShowAction && {
      width: 25,
      key: 'action',
      className: 'ant-table-cell-background-unset',
      render: (item) => <ItemDifferences item={item} />,
    },
  ].filter(Boolean);

const BookedServices = ({ items, isBookmarked, showDigits }) => {
  const { t } = useTranslation();
  const { isCompare } = useCompareToGlobalContext();

  const columns = useMemo(
    () =>
      bookedServiceColumns({
        t,
        showDigits,
        isShowAction: isCompare && find(items, (e) => ActiveComparisonTypes.includes(e.comparisonType)),
      }),
    [isCompare, items, showDigits, t],
  );

  return (
    <>
      <h2>{t(`viewer.ShoppingCartView.BookedServices.${isBookmarked ? 'bookmarkedItems' : 'items'}`)}</h2>
      <TableMemo
        className="shopping-cart-table margin-bottom-16"
        dataSource={items}
        columns={columns}
        pagination={false}
        scroll={{ x: 600 }}
      />
    </>
  );
};
export default memo(BookedServices, equal);
