import ItemTypes from 'constants/ItemTypes';
import equal from 'fast-deep-equal/es6/react';
import { memo } from 'react';
import { useDragLayer } from 'react-dnd';
import DocumentBlockDrag from './DocumentBlockDrag';

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: -20,
  top: -13,
  width: '100%',
};
function getItemStyles(initialOffset, currentOffset) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }

  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;
  return {
    opacity: 0.7,
    transform,
    WebkitTransform: transform,
  };
}
function RenderItem({ itemType, _id }) {
  switch (itemType) {
    case ItemTypes.DOCUMENT_BLOCK:
      return <DocumentBlockDrag _id={_id} />;
    default:
      return null;
  }
}
const RenderItemMemo = memo(RenderItem, equal);

const DocumentBlockDragLayer = () => {
  const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => {
    return {
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    };
  });

  if (!isDragging) {
    return null;
  }
  return (
    <div style={layerStyles}>
      <div style={getItemStyles(initialOffset, currentOffset)}>
        <RenderItemMemo _id={item?._id} itemType={itemType} />
      </div>
    </div>
  );
};

export default DocumentBlockDragLayer;
