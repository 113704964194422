/* eslint-disable no-nested-ternary */
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import equal from 'fast-deep-equal/es6/react';
import { useCompareToGlobalContext } from 'contexts/CompareToGlobalContext';
import Card from 'components/common/Card';
import { Divider } from 'antd';
import Variables from './Variables';

const CompareToGlobalCard = ({ isCategories, variables, children }) => {
  const { t } = useTranslation();
  const { isCompare } = useCompareToGlobalContext();
  return (
    <Card
      {...(isCompare
        ? { title: t('viewer.ShoppingCartView.CompareToGlobal.title') }
        : { title: t('viewer.ShoppingCartView.BookedServices.items') })}
    >
      {isCompare ? (
        <>
          <div>{t('viewer.ShoppingCartView.CompareToGlobal.usedVariablesTitle')}</div>
          <Variables variables={variables} />
          {isCategories ? <Divider /> : null}
        </>
      ) : null}
      {children}
    </Card>
  );
};

export default memo(CompareToGlobalCard, equal);
