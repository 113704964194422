import { gql } from '@apollo/client';
import { adminTenantCompanyFragment } from 'graphql/fragments';

const updateTenantCompanyMutation = gql`
  mutation updateTenantCompanyMutation($company: TenantCompanyInput!) {
    updateTenantCompany(company: $company) {
      ...adminTenantCompanyFragment
    }
  }
  ${adminTenantCompanyFragment}
`;

export default updateTenantCompanyMutation;
