import { useEffect } from 'react';
import useUserShoppingCart from 'graphql/hooks/useUserShoppingCart';
import { useParams, useNavigate, generatePath } from 'react-router-dom';
import { CreationMode } from 'constants/shoppingCart';
import routePaths from 'router/route-paths';
import ShoppingCart from '../ShoppingCart/ShoppingCart';

const ShoppingCartRevision = () => {
  const { id: _id, mode } = useParams();

  const [shoppingCart, loading] = useUserShoppingCart({ _id });
  const navigate = useNavigate();

  useEffect(() => {
    if (shoppingCart && !shoppingCart.isLatest) navigate(generatePath(routePaths.shoppingCartView, { id: _id }));
    if (shoppingCart?.migrated && mode === CreationMode.INDEPENDENT) {
      navigate(generatePath(routePaths.routerShoppingCartRevision, { id: _id, mode: CreationMode.GLOBAL }));
    }
  });

  return <ShoppingCart dbId={_id} dbLoading={loading} dbValues={!loading && { cart: shoppingCart }} />;
};

export default ShoppingCartRevision;
