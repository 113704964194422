import { gql } from '@apollo/client';
import { adminStatusFragment } from 'graphql/fragments';

const createStatusMutation = gql`
  mutation createStatusMutation($status: StatusInput) {
    createStatus(status: $status) {
      ...adminStatusFragment
    }
  }
  ${adminStatusFragment}
`;

export default createStatusMutation;
