import { gql } from '@apollo/client';

const adminShowDigitsQuery = gql`
  query adminShowDigitsQuery {
    tenantSettings {
      _id
      shoppingCartPreferences {
        _id
        showDigits
      }
    }
  }
`;

export default adminShowDigitsQuery;
