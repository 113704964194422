import { gql } from '@apollo/client';

const getSigneeSigningPasswordMutation = gql`
  mutation getSigneeSigningPasswordMutation($shoppingCartId: ID!, $signeeRelatedObjectId: ID!, $signeeEmail: String!) {
    getSigneeSigningPassword(
      shoppingCartId: $shoppingCartId
      signeeRelatedObjectId: $signeeRelatedObjectId
      signeeEmail: $signeeEmail
    )
  }
`;

export default getSigneeSigningPasswordMutation;
