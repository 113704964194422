import { Button } from 'antd';
import equal from 'fast-deep-equal/es6/react';
import { memo } from 'react';
import { FaPen } from 'react-icons/fa';
import { useIsOldContactData } from '../hooks';

const EditButtonContainer = memo(function EditButton({ onEdit }) {
  const isOldContactData = useIsOldContactData();
  if (isOldContactData) return null;
  return (
    <div className="align-center">
      <Button
        disabled={isOldContactData}
        className="ant-button-transparent"
        onClick={onEdit}
        icon={<FaPen size={20} />}
      />
    </div>
  );
}, equal);

export default EditButtonContainer;
