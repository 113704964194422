import { useTranslation } from 'react-i18next';
import DocumentTemplateSelect from 'components/common/DocumentTemplateSelect';

/**
 * NoticeOfCancellationTemplateSelect component to select a commonlibrary document template for the notice of cancellation default template (Required formik context)
 * @returns {JSX.Element} document template select for the default templates settings for the super admin
 * @component
 */
const NoticeOfCancellationTemplateSelect = () => {
  const { t } = useTranslation();
  return (
    <DocumentTemplateSelect
      fieldName="noticeOfCancellationTemplate"
      label={t('superAdmin.ManageDigitalSignature.defaultTemplatesCard.noticeOfCancellationTemplate.label')}
      placeholder={t('superAdmin.ManageDigitalSignature.defaultTemplatesCard.noticeOfCancellationTemplate.placeholder')}
      tooltip={t('superAdmin.ManageDigitalSignature.defaultTemplatesCard.noticeOfCancellationTemplate.tooltip')}
      isLibrary
    />
  );
};

export default NoticeOfCancellationTemplateSelect;
