import { gql } from '@apollo/client';

const moveInputFieldMutation = gql`
  mutation moveInputFieldMutation($_id: ID!, $offset: Int, $order: Int) {
    moveInput(_id: $_id, offset: $offset, order: $order) {
      _id
      order
    }
  }
`;

export default moveInputFieldMutation;
