import { useEffect, useState } from 'react';
import { Input } from 'formik-antd';
import { useFormikContext } from 'formik';
import FormItem from 'components/common/FormItem';
import { useFormikField } from 'hooks/common/useFormikField';
import { CheckCircleFilled } from '@ant-design/icons';

/**
 * Renders an input form item for entering the BIC (Bank Identifier Code).
 * It initializes the field value with a BIC from the server if the current value is empty.
 * The component utilizes the useFormikField hook for form field management.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.t - A function for localizing the label and placeholder text.
 * @param {string} props.serverBankData - The bank data value fetched from the server, used as an initial value if the current value is empty.
 * @param {string} props.serverBankData.bic - BIC of the bank
 * @param {string} props.serverBankData.bankName - Name of the bank
 * @param {string} props.serverBankData.bankCity - City of the bank
 * @param {boolean} [props.disabled=false] - Indicates whether the input field should be disabled.
 * @returns {ReactElement} A form item containing a labeled input field for the BIC.
 */
const BicInput = ({ t, serverBankData, disabled, prefilledBic }) => {
  const { setFieldValue } = useFormikContext();
  const { onChange, value } = useFormikField('bic');
  const [bicVerified, setBicVerified] = useState(false);
  useEffect(() => {
    if (!value || value === '') {
      onChange(serverBankData.bic);
    } else if (serverBankData && serverBankData?.bic !== '') {
      setFieldValue('bankData.bankName', '');
      setFieldValue('bankData.bankCity', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverBankData, onChange]);

  useEffect(() => {
    if (serverBankData && serverBankData.bic !== '' && serverBankData.bic === value) {
      setBicVerified(true);
      setFieldValue('bankData.bankCity', serverBankData?.bankCity);
      setFieldValue('bankData.bankName', serverBankData?.bankName);
    } else {
      setBicVerified(false);
      if (value && value !== '') {
        setFieldValue('bankData.bankCity', '');
        setFieldValue('bankData.bankName', '');
      }
    }
  }, [value, serverBankData, setFieldValue]);

  return (
    <FormItem name="bic" label={t('bic.label')}>
      <Input
        disabled={disabled}
        name="bic"
        placeholder={prefilledBic || t('bic.placeholder')}
        addonAfter={bicVerified ? <CheckCircleFilled style={{ color: '#16b513' }} /> : null}
      />
    </FormItem>
  );
};

export default BicInput;
