import { useCallback, useRef, useState, useImperativeHandle, forwardRef, useMemo, useEffect } from 'react';
import { Modal, Card, Skeleton, Alert } from 'antd';
import find from 'lodash/find';
import { ErrorComponent } from 'components/common/ErrorComponent';
import intersectionBy from 'lodash/intersectionBy';
import EmptyBox from 'components/common/EmptyBox';
import { differenceBy, isEmpty } from 'lodash';
import { useCachedQuery } from 'graphql/utils';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { LibraryProvider } from 'contexts/LibraryContext';
import { PremiumClAdvertisementAlert } from './PremiumClAdvertisementAlert';

const skeletons = [...new Array(2)].map((_, i) => i + 1);

export default function ImportFromLibraryModal({
  handleClose,
  onOk,
  onCancel,
  modalProps = {},
  query,
  title,
  renderItem,
  errorMessage,
  emptyMessage,
  alert,
  showClAdvertisement,
}) {
  const [isSubmitting, setIsSubmitting] = useState();
  const importFromLibraryRef = useRef();
  const handleOk = useCallback(async () => {
    setIsSubmitting(true);
    if (isSubmitting) return;
    try {
      const value = importFromLibraryRef.current.preSubmit();
      if (value) {
        onOk && (await onOk(value));
        handleClose(false);
        importFromLibraryRef.current.reset();
      }
    } catch {
      // noop
    }
    setIsSubmitting(false);
  }, [isSubmitting, onOk, handleClose]);

  const handleCancel = useCallback(() => {
    handleClose(false);
    importFromLibraryRef.current.reset();
    onCancel && onCancel();
  }, [onCancel, handleClose]);

  return (
    <LibraryProvider value={{ isLibrary: true }}>
      <Modal width={800} title={title} onOk={handleOk} onCancel={handleCancel} {...modalProps}>
        {showClAdvertisement && <PremiumClAdvertisementAlert />}
        {alert ? <Alert showIcon closable message={alert} type="info" className="alert-info" /> : null}
        <ImportFromLibrary
          ref={importFromLibraryRef}
          query={query}
          renderItem={renderItem}
          errorMessage={errorMessage}
          emptyMessage={emptyMessage}
        />
      </Modal>
    </LibraryProvider>
  );
}

export const ImportFromLibrary = forwardRef(
  (
    { initialSelection, query, checkAll = false, renderItem: RenderItem, errorMessage, emptyMessage, ...props },
    ref,
  ) => {
    const { data } = useCachedQuery(query, { variables: { isLibrary: false }, fetchPolicy: 'cache-and-network' });
    const items = useMemo(() => grabFirstGQLDataResult(data), [data]);
    const selectedItemsRef = useRef(initialSelection || []);
    const [duplicatedItems, setDuplicatedItems] = useState([]);

    const handleSetDuplicatedItems = useCallback(() => {
      const intersection = intersectionBy(selectedItemsRef.current, items, 'name');
      setDuplicatedItems(intersection.reduce((acc, c) => [...acc, c.name], []));
    }, [items]);

    const handleSelect = useCallback(
      (item, select) => {
        if (select) {
          selectedItemsRef.current.push(item);
        } else {
          selectedItemsRef.current = selectedItemsRef.current.filter(({ _id }) => _id !== item._id);
        }
        handleSetDuplicatedItems();
      },
      [handleSetDuplicatedItems],
    );

    const {
      data: _data,
      loading,
      error,
    } = useCachedQuery(query, {
      variables: { isLibrary: true },
      fetchPolicy: 'cache-and-network',
    });
    const commonLibraryData = useMemo(() => grabFirstGQLDataResult(_data) ?? [], [_data]);

    useEffect(() => {
      if (checkAll && commonLibraryData && items && !selectedItemsRef.current?.length) {
        const difference = differenceBy(commonLibraryData, items, 'name');
        selectedItemsRef.current = [...difference];
      }
      if (selectedItemsRef.current?.length) {
        handleSetDuplicatedItems();
      }
    }, [checkAll, commonLibraryData, handleSetDuplicatedItems, items]);

    useImperativeHandle(ref, () => ({
      getSelection: () => selectedItemsRef.current,
      preSubmit: () => {
        if (!duplicatedItems.length) {
          return selectedItemsRef.current;
        }
        const errorEl = document.querySelector('.ant-alert');
        if (errorEl) {
          errorEl.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
        }
        return false;
      },
      reset: () => {
        setDuplicatedItems([]);
        selectedItemsRef.current = [];
      },
    }));

    if (loading && isEmpty(commonLibraryData)) {
      return skeletons.map((k) => <Skeleton title loading={loading} active key={k} />);
    }
    return (
      <>
        {duplicatedItems.length !== 0 ? (
          <ErrorComponent
            description={
              typeof errorMessage === 'function'
                ? errorMessage(duplicatedItems.map((c) => `"${c}"`).join(', '))
                : errorMessage
            }
          />
        ) : null}
        {isEmpty(commonLibraryData) && !loading && !error ? (
          <Card>
            <EmptyBox label={emptyMessage} />
          </Card>
        ) : null}
        {commonLibraryData.map((item) => {
          const isSelected = !!find(selectedItemsRef.current, { _id: item._id });
          const isDuplicated = duplicatedItems.includes(item.name);
          return (
            <RenderItem
              {...props}
              key={item._id}
              id={item._id}
              data={item}
              isSelected={isSelected}
              isDuplicated={isDuplicated}
              handleSelect={handleSelect}
            />
          );
        })}
      </>
    );
  },
);
