import { FaInfoCircle } from 'react-icons/fa';
import cn from 'classnames';
import classes from './Alert.module.less';

const InfoAlert = ({ className, children }) => {
  return (
    <div className={cn(classes.alert, className)}>
      <div>
        <FaInfoCircle size={20} />
      </div>
      <div>{children}</div>
    </div>
  );
};

export default InfoAlert;
