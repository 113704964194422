import i18n from 'i18n';
import * as Yup from 'yup';

const passwordSchema = () => ({
  currentPassword: Yup.string()
    .required()
    .label(i18n.t('user.Setting.AccountSetting.tabs.password.fields.currentPassword')),
  newPassword: Yup.string()
    .required()
    .min(8)
    .label(i18n.t('user.Setting.AccountSetting.tabs.password.fields.newPassword')),
  passwordConfirmation: Yup.string()
    .required()
    .oneOf([Yup.ref('newPassword'), null], i18n.t('user.Setting.AccountSetting.tabs.password.errors.noMatch'))
    .min(8)
    .label(i18n.t('user.Setting.AccountSetting.tabs.password.fields.passwordConfirmation')),
});

export const resetPasswordSchema = () => Yup.object().shape(passwordSchema());
