import { Spin as SpinAntD } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

/**
 * Spin component with a spinner (circle) to indicate, that something is loading
 * @param {Object} inputParameter - Input parameter of component
 * @param {Number} inputParameter.fontSize - Fontsize for loading icon
 * @param {String} inputParameter.className - Classname for used antd spin component
 * @returns {JSX.Element} Spin component to indicate loading stuff
 * @component
 */
const Spin = ({ fontSize, className }) => {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize,
      }}
      spin
    />
  );
  return <SpinAntD indicator={antIcon} className={className} />;
};

export default Spin;
