import { useCallback, useMemo, useState } from 'react';
import { Button, Input, Radio, Skeleton, Typography } from 'antd';
import { useNavigate, generatePath } from 'react-router-dom';
import routePaths from 'router/route-paths';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { EditOutlined, SearchOutlined, EyeOutlined } from '@ant-design/icons';
import PageContainer from 'components/layout/PageContainer';
import { QueryErrorAlert } from 'components/common/ErrorComponent';
import { useCachedQuery } from 'graphql/utils';
import { formatDateTime } from 'utils/date';
import { updateShoppingCart } from 'graphql/methods';
import { TableMemo } from 'memo';
import { DIGITAL_SIGNATURE_STATE } from '@JavaScriptSuperstars/kanzleipilot-shared';
import useFasterPreferences from 'hooks/user/useFasterPreferences';
import usePagination from 'utils/usePagination';
import inputConfirmModal from 'utils/inputConfirmModal';
import i18n from 'i18n';
import { Select } from 'formik-antd';
import { userGetStatusesQuery } from 'graphql/queries/user';
import { grabFirstGQLDataResult } from 'utils/helpers';
import * as Yup from 'yup';
import FormItem from 'components/common/FormItem';
import ICONS_BY_STATE from 'components/user/SigningStateIcon';
import { userShoppingCartEntriesTable } from 'graphql/queries';
import Card from 'components/common/Card';
import { totalValueToString } from 'utils/formatValues';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import roles from 'constants/roles';
import ShoppingCartEntriesHelperWidget from './ShoppingCartEntriesHelperWidget';
import classes from './ShoppingCartEntries.module.less';
import ShoppingCartAdditionalFilters from './ShoppingCartAdditionalFilters';

const { Text } = Typography;

const ShoppingCartStatusesFilter = ({ statusId, updateQuery }) => {
  const { data, loading } = useCachedQuery(userGetStatusesQuery, { variables: { type: 'shoppingCart' } });

  const statuses = useMemo(() => grabFirstGQLDataResult(data), [data]);

  const { t } = useTranslation();
  if (loading && !data)
    return (
      <div>
        <Skeleton.Input active size="small" shape="default" style={{ width: '200px', marginTop: '16px' }} />
      </div>
    );
  return (
    <>
      <div style={{ marginTop: '16px', marginBottom: '5px' }}>
        <Text>{t('ShoppingCartEntriesPage.menuCartFilterLabel')}</Text>
      </div>
      <Radio.Group
        size="small"
        defaultValue={statusId}
        onChange={(e) => updateQuery({ field: 'statusId', value: e.target.value })}
      >
        <Radio.Button value={undefined}>{t(`ShoppingCartEntriesPage.statusAll`)}</Radio.Button>

        {statuses?.map(({ _id, name }) => (
          <Radio.Button key={_id} value={_id}>
            {name}
          </Radio.Button>
        ))}
      </Radio.Group>
    </>
  );
};

const ChangeShoppingCartStatusContent = ({ status: initialValue }) => {
  const { t } = useTranslation();
  const { data, loading } = useCachedQuery(userGetStatusesQuery, { variables: { type: 'shoppingCart' } });
  const statuses = useMemo(() => grabFirstGQLDataResult(data), [data]);

  return (
    <FormItem name="status" label={t('ShoppingCartEntriesPage.entriesTable.columnTitles.status')}>
      <Select
        name="status"
        loading={loading}
        defaultValue={initialValue && t(`ShoppingCartEntriesPage.statusModal.placeholder`)}
        disabled={loading}
        showSearch
        style={{ width: '100%' }}
        placeholder={t('ShoppingCartEntriesPage.statusModal.placeholder')}
      >
        {loading
          ? null
          : statuses.map((status) => (
              <Select.Option value={status._id} key={status._id}>
                {status.name}
              </Select.Option>
            ))}
      </Select>
    </FormItem>
  );
};

const updateShoppingCartStatusSchema = () => ({
  status: Yup.string()
    .transform((value) => value || '')
    .required()
    .label(i18n.t('ShoppingCartEntriesPage.entriesTable.columnTitles.status')),
});

export const updateShoppingCartStatus = () => Yup.object().shape(updateShoppingCartStatusSchema());

const changeShoppingCartStatus = ({ _id, status }) =>
  inputConfirmModal({
    formContent: () => <ChangeShoppingCartStatusContent status={status} />,
    fields: [],
    onSubmit: async (modifier) => {
      if (modifier.status !== status) await updateShoppingCart({ _id, modifier: { statusId: modifier.status } });
    },
    value: { status },
    validationSchema: updateShoppingCartStatus,
    headerText: i18n.t('ShoppingCartEntriesPage.statusModal.title'),
    okText: i18n.t('common.ok'),
    cancelText: i18n.t('common.cancel'),
    forceMultiField: true,
    width: '600px',
  });

export const useShoppingCartsPaginated = ({ ...queryParams }) => {
  const variables = queryParams;
  const {
    data: response,
    loading,
    error,
  } = useCachedQuery(userShoppingCartEntriesTable, {
    variables,
    fetchPolicy: 'cache-and-network',
  });
  return useMemo(() => {
    const shoppingCarts = response?.shoppingCartEntries?.shoppingCarts;
    const count = response?.shoppingCartEntries?.count;
    const sc = Array.isArray(shoppingCarts)
      ? shoppingCarts.map((row) => ({
          key: row._id,
          ...row,
          createdAt: new Date(row.createdAt),
        }))
      : [];
    return { data: sc, count, loading, error };
  }, [loading, error, response]);
};
export const contactsToString = (contacts = []) =>
  contacts?.map((contact) => `${contact.firstName} ${contact.lastName}`).join(', ');

export default function ShoppingCartEntriesPage() {
  const [me] = useCurrentUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { preferences } = useFasterPreferences();
  const { otherQueryData, error, query, setQuery, ...paginationProps } = usePagination({
    queryHook: useShoppingCartsPaginated,
  });

  const isDigitalSignatureEnabled = preferences?.digitalSignaturePreferences?.digitalSignatureEnabled || false;

  const columns = useMemo(
    () =>
      [
        {
          title: t('ShoppingCartEntriesPage.entriesTable.columnTitles.createdAt'),
          dataIndex: 'createdAt',
          key: 'createdAt',
          render: (date) => formatDateTime(date),
          sorter: true,
        },
        {
          title: t('ShoppingCartEntriesPage.entriesTable.columnTitles.companyIdentifier'),
          dataIndex: 'companies.identifier',
          key: 'identifier',
          render: (_, data) => data?.company?.identifier,
          sorter: true,
        },
        {
          title: t('ShoppingCartEntriesPage.entriesTable.columnTitles.name'),
          dataIndex: 'name',
          key: 'name',
          sorter: true,
        },
        {
          title: t('ShoppingCartEntriesPage.entriesTable.columnTitles.companyName'),
          dataIndex: 'companies.name',
          key: 'companyName',
          render: (_, data) => data?.company?.name,
          sorter: true,
        },
        {
          title: t('ShoppingCartEntriesPage.entriesTable.columnTitles.relatedContacts'),
          dataIndex: 'relatedContacts',
          key: 'contactsName',
          render: (_, data) => contactsToString(data?.contacts),
          sorter: true,
        },
        {
          title: t('ShoppingCartEntriesPage.entriesTable.columnTitles.status'),
          key: 'status',
          sorter: true,
          width: 160,
          render: ({ status }) => {
            return (
              <>
                {status ? status.name : t(`ShoppingCartEntriesPage.noStatus`)}
                {me?.role === roles.VIEWER ? null : (
                  <>
                    {' '}
                    <Button
                      className="ant-btn-default"
                      icon={<EditOutlined />}
                      onClick={() => changeShoppingCartStatus({ _id: status.parentId, status: status?._id })}
                    />
                  </>
                )}
              </>
            );
          },
        },
        isDigitalSignatureEnabled && {
          title: t('ShoppingCartEntriesPage.entriesTable.columnTitles.digitalSignatureState'),
          dataIndex: 'digitalSignatureStatePriority',
          key: 'digitalSignatureState',
          render: (_, data) => {
            const digitalSignatureState = data?.digitalSignatureState || DIGITAL_SIGNATURE_STATE.NO_DIGITAL_SIGNATURE;
            const iconForState = ICONS_BY_STATE[digitalSignatureState]({ size: '16px' });
            return (
              <div className={classes.digitalSignatureStateColumnItem}>
                <div className={classes.digitalSignatureStateIcon}>{iconForState}</div>
                <div>{t(`ShoppingCartEntriesPage.entriesTable.digitalSignatureState.${digitalSignatureState}`)}</div>
              </div>
            );
          },
          sorter: true,
        },
        {
          title: t('ShoppingCartEntriesPage.entriesTable.columnTitles.valueOneOff'),
          key: 'oneOff',
          dataIndex: 'oneOff',
          sorter: true,
          render: (_, { oneOff, showDigits }) => totalValueToString({ paymentIntervalValues: oneOff, showDigits }),
        },
        {
          title: t('ShoppingCartEntriesPage.entriesTable.columnTitles.valueMonthly'),
          key: 'monthly',
          dataIndex: 'monthly',
          sorter: true,
          render: (_, { monthly, showDigits }) => totalValueToString({ paymentIntervalValues: monthly, showDigits }),
        },
        {
          title: t('ShoppingCartEntriesPage.entriesTable.columnTitles.valueYearly'),
          key: 'yearly',
          dataIndex: 'yearly',
          sorter: true,
          render: (_, { yearly, showDigits }) => totalValueToString({ paymentIntervalValues: yearly, showDigits }),
        },
        {
          title: t('ShoppingCartEntriesPage.entriesTable.columnTitles.actions'),
          key: 'actions',
          fixed: 'right',
          render: (text, row) => (
            <div className="center">
              <Button
                className="ant-btn-default"
                type="primary"
                ghost
                icon={<EyeOutlined />}
                onClick={() => navigate(generatePath(routePaths.shoppingCartView, { id: row._id }))}
              />
            </div>
          ),
        },
      ].filter(Boolean),
    [me?.role, navigate, t, isDigitalSignatureEnabled],
  );

  const onChangeSearch = useMemo(() => debounce(setQuery, 300), [setQuery]);
  const [stateQuery, setStateQuery] = useState({ query, status: undefined, digitalSignatureState: undefined });
  const updateQuery = useCallback(
    ({ field, value }) => {
      const newStateQuery = { ...stateQuery, [field]: value };
      setStateQuery(newStateQuery);
      onChangeSearch(newStateQuery);
    },
    [onChangeSearch, stateQuery],
  );

  const dataSource = useMemo(() => {
    return paginationProps.dataSource?.map((d) => ({ ...d, status: { ...d.status, parentId: d._id } }));
  }, [paginationProps.dataSource]);

  return (
    <PageContainer title={t('ShoppingCartEntriesPage.title')} left={<ShoppingCartEntriesHelperWidget />}>
      <Card>
        <Card icon={<SearchOutlined />} title={t('ShoppingCartEntriesPage.searchTitle')}>
          <Input
            placeholder={t('ShoppingCartEntriesPage.search')}
            value={stateQuery.query}
            onChange={(e) => updateQuery({ field: 'query', value: e.target.value })}
            suffix={<SearchOutlined />}
          />
          <ShoppingCartStatusesFilter statusId={stateQuery.statusId} updateQuery={updateQuery} />
          <ShoppingCartAdditionalFilters
            digitalSignatureState={stateQuery.digitalSignatureState}
            updateQuery={updateQuery}
          />
        </Card>
        <QueryErrorAlert error={error} />
        <TableMemo
          columns={columns}
          loading={paginationProps.loading && !paginationProps.dataSource}
          {...paginationProps}
          dataSource={dataSource}
          scroll={{ x: 992 }}
        />
      </Card>
    </PageContainer>
  );
}
