export const InputFieldType = {
  INPUT: 'input',
  COMBO: 'combo',
  INTERNAL: 'internal',
};

export const InternalInputFieldType = {
  VARIABLE: 'variable',
  INPUT: 'input',
};
