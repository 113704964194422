import { gql } from '@apollo/client';
import { adminStatusFragment } from 'graphql/fragments';

const updateStatusMutation = gql`
  mutation updateStatusMutation($_id: ID!, $modifier: StatusUpdateInput) {
    updateStatus(_id: $_id, modifier: $modifier) {
      ...adminStatusFragment
    }
  }
  ${adminStatusFragment}
`;

export default updateStatusMutation;
