import { gql } from '@apollo/client';

const generateSigneeSigningLinkMutation = gql`
  mutation generateSigneeSigningLinkMutation($shoppingCartId: ID!, $signeeRelatedObjectId: ID!, $signeeEmail: String!) {
    generateSigneeSigningLink(
      shoppingCartId: $shoppingCartId
      signeeRelatedObjectId: $signeeRelatedObjectId
      signeeEmail: $signeeEmail
    )
  }
`;

export default generateSigneeSigningLinkMutation;
