import { gql } from '@apollo/client';
import { currentUserFragment } from 'graphql/fragments';

const loginMutation = gql`
  mutation loginWithPassword($email: String!, $password: String!) {
    loginWithPassword(email: $email, password: $password) {
      token
      refreshToken
      user {
        ...currentUserFragment
      }
    }
  }
  ${currentUserFragment}
`;
export default loginMutation;
