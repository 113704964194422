import { gql } from '@apollo/client';
import { adminCategoryListFragment } from 'graphql/fragments';

const updateCategoryMutation = gql`
  mutation updateCategoryMutation($_id: ID!, $modifier: UpdateInput!) {
    updateCategory(_id: $_id, modifier: $modifier) {
      category {
        ...adminCategoryListFragment
      }
      warnings {
        key
        variables
      }
    }
  }
  ${adminCategoryListFragment}
`;

export default updateCategoryMutation;
