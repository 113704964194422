import { gql } from '@apollo/client';
import { adminItemFragment } from 'graphql/fragments';

const duplicateItemMutation = gql`
  mutation duplicateItemMutation($itemId: ID!, $name: String!) {
    duplicateCategoryItem(itemId: $itemId, name: $name) {
      ...adminItemFragment
    }
  }
  ${adminItemFragment}
`;

export default duplicateItemMutation;
