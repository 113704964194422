import { sendEmailModal } from 'pages/shoppingCartManagement/ShoppingCart/components/SendEmail';
import { startDigitalSignature } from 'graphql/methods';
import { useDigitalSignatureEmailContext } from 'components/user/shoppingCart/context';
import confirmModal from './confirmModal';
import classes from './OpenStartDigitalSignatureModal.module.less';

/**
 * Return type of useStartDigitalSignatureDrawer
 * @typedef {Object} useStartDigitalSignatureDrawerOutput
 * @property {Function} openStartDigitalSignatureDrawer - Function to open the email drawer for digital signature
 */

/**
 * A hook that maintains the values of the digital signature drawer
 * @returns {useStartDigitalSignatureDrawerOutput} - An object containing the function to open the digital signature drawer
 */
export function useStartDigitalSignatureDrawer() {
  // const [initialValues, setInitialValues] = useState({});
  const { initialValues, setInitialValues } = useDigitalSignatureEmailContext();

  /**
   * Opens send email drawer for digital signature start mail for client signees
   * @param {Object} inputParameter - Input parameters
   * @param {Object} inputParameter.preferences - Current tenant preferences
   * @param {Object} inputParameter.shoppingCart - Shopping cart object, on which the digital signature should be started
   * @param {Function} inputParameter.translation - Function for i18n translations
   * @param {Function} inputParameter.onCloseDrawer - Function, which called on close drawer (Optional) (Can be used for navigation)
   */
  const openStartDigitalSignatureDrawer = ({ preferences, shoppingCart, translation, onCloseDrawer }) => {
    if (!preferences || !shoppingCart) return;
    const emailTemplateId =
      preferences?.digitalSignaturePreferences?.notifyClientSigneesForDigitalSignatureStartTemplate;

    const initialRecipients = shoppingCart.signatureData.companySignees.map((signee) => signee.id);
    const allRecipients = shoppingCart.signatureData.companySignees.map((signee) => ({
      _id: signee.id,
      email: signee.email,
    }));

    const customBodyValidation = {
      name: 'body-contains-link-button',
      test: (value) => /"name":"linkButton","mentionType":"common"/g.test(value),
      message: translation('user.ShoppingCart.DigitalSignature.noLinkButtonPresent'),
    };

    const onCloseAfter = (formik) => {
      setInitialValues((state) => ({ ...state, ...formik.values }));
      if (onCloseDrawer) onCloseDrawer();
    };

    const onOk = (values, closeDrawer) => {
      startDigitalSignature({
        shoppingCartId: shoppingCart._id,
        emailProps: {
          subject: values.subject,
          body: values.body,
          signature: values.signature,
          shouldSendAttachments: values.shouldSendAttachments,
        },
      });
      if (onCloseDrawer) onCloseDrawer();
      closeDrawer();
    };

    /**
     * Opens a confirm modal to check, if user really wants to exit the drawer
     * @returns {Promise<Boolean>} Returns a promise which resolves when user took an option and returns a boolean, if drawer should close or not
     */
    const onClose = async () => {
      return new Promise((resolve) => {
        confirmModal({
          okText: translation('user.ShoppingCart.DigitalSignature.onCloseModal.okButtonLabel'),
          title: translation('user.ShoppingCart.DigitalSignature.onCloseModal.message'),
          cancelText: translation('user.ShoppingCart.DigitalSignature.onCloseModal.cancelButtonLabel'),
          onOk: () => resolve(false),
          onCancel: () => resolve(true),
          className: classes.closeModal,
        });
      });
    };

    sendEmailModal({
      allRecipients,
      emailTemplateId,
      initialRecipients,
      onOk,
      shoppingCart,
      notificationMode: true,
      customBodyValidation,
      onCloseAfter,
      headerText: translation('user.ShoppingCart.DigitalSignature.title'),
      signature: preferences?.signature,
      additionalAlertText: translation('user.ShoppingCart.DigitalSignature.infoAlert'),
      onClose,
      ...initialValues,
    });
  };
  return { openStartDigitalSignatureDrawer };
}

export default useStartDigitalSignatureDrawer;
