import { useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useDocumentsAreGenerated from 'hooks/signing/useDocumentsAreGenerated';
import useSigningContext from 'hooks/signing/useSigningContext';
import classNames from 'classnames';
import AgreementsForm from './ViewDocumentsAndSign/AgreementsForm';
import DocumentsList from './ViewDocumentsAndSign/DocumentsList';
import SigningButton from './ViewDocumentsAndSign/SigningButton';
import CustomWarningAlert from './CustomWarningAlert';
import classes from './ViewDocumentsAndSign.module.less';

/**
 * ViewDocumentsAndSign component is a step component for view and sign documents in the signing process
 * @param {Object} inputParameters - Input parameters of the component
 * @param {React.Ref} inputParameters.submitReference - Reference, which gets the onContinue and onBack function of the step
 * @param {Boolean} inputParameters.hasDocumentGenerationTriggered - Has a previous step triggered the generation of new documents
 * @param {Object} inputParameters.stepState - Saved state of the step
 * @param {Function} inputParameters.updateCurrentStepState - Function to update current step state
 * @returns {JSX.Element} step for view and sign documents in the signing process
 */
const ViewDocumentsAndSign = ({
  submitReference,
  hasDocumentGenerationTriggered,
  stepState,
  updateCurrentStepState,
}) => {
  const { t: translation } = useTranslation();
  const { signingContext } = useSigningContext();
  const submitReferenceForForm = useRef();
  const signingButtonReference = useRef();
  useDocumentsAreGenerated(hasDocumentGenerationTriggered);

  // eslint-disable-next-line no-param-reassign
  submitReference.current = {
    onContinue: async () => {
      if (signingButtonReference?.current?.onContinue) {
        const signatureImage = signingButtonReference?.current?.onContinue();
        if (signatureImage) {
          return {
            stepState: {
              checkNoticeOfCancellation: true,
              checkDocumentsSeen: true,
              signed: true,
              signatureImage,
            },
          };
        }
        return false;
      }
      return false;
    },
    onBack: async () => {
      if (signingButtonReference?.current?.onBack) return signingButtonReference.current.onBack();
      return true;
    },
  };

  const isTenantSignee = signingContext?.typeOfSignee === 'TENANT';
  const tenantEmail = signingContext?.officeEmail || '';
  const components = {
    br: <br />,
    // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
    mailto: <a href={`mailto:${tenantEmail}`} />,
    p: <p className={classes.contactUsLine} />,
  };
  const values = {
    tenantEmail,
  };

  return (
    <>
      <div className={classes.introText}>
        <CustomWarningAlert
          message={translation('signing.viewDocumentsAndSignStep.introtext.headline')}
          description={
            <Trans i18nKey="signing.viewDocumentsAndSignStep.introtext.text" components={components} values={values} />
          }
        />
      </div>
      <div className={classes.stepWrapper}>
        <DocumentsList />
      </div>
      <div className={classNames(classes.stepWrapper, classes.formItemFix)}>
        <AgreementsForm
          isPrivatePerson={signingContext?.isPrivatePerson}
          submitReference={submitReferenceForForm}
          stepState={stepState}
          isTenantSignee={isTenantSignee}
        />
      </div>
      <div className={classes.stepWrapper}>
        <SigningButton
          agreementFormReference={submitReferenceForForm}
          buttonReference={signingButtonReference}
          signatureImage={stepState?.signatureImage}
          updateCurrentStepState={updateCurrentStepState}
        />
      </div>
    </>
  );
};

export default ViewDocumentsAndSign;
