import apollo from 'graphql/apollo';
import { copyItemMutation } from 'graphql/mutations';
import { grabFirstGQLDataResult } from 'utils/helpers';

const copyCategoryItem = async ({ itemId, categoryId, categoryListQuery, name }) =>
  apollo.mutate({
    mutation: copyItemMutation,
    variables: {
      itemId,
      categoryId,
      name,
    },
    update: (cache, { data }) => {
      const result = grabFirstGQLDataResult(data);
      const cachedData = cache.readQuery({ query: categoryListQuery });

      cache.writeQuery({
        query: categoryListQuery,
        data: {
          categories: cachedData.categories.map((c) => {
            if (c._id === result.categoryId) {
              const items = [...c.items, result];
              return { ...c, items };
            }
            return c;
          }),
        },
      });
    },
  });
export default copyCategoryItem;
