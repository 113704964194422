import { useTranslation } from 'react-i18next';
import EmailTemplateSelect from 'components/common/EmailTemplateSelect';

/**
 * StartClientNotificationTemplateSelect component to select a commonlibrary email template for the client digital signature start email default template (Required formik context)
 * @returns {JSX.Element} email template select for the default templates settings for the super admin
 * @component
 */
const StartClientNotificationTemplateSelect = () => {
  const { t } = useTranslation();
  return (
    <EmailTemplateSelect
      fieldName="startClientNotificationTemplate"
      label={t('superAdmin.ManageDigitalSignature.defaultTemplatesCard.startClientNotificationTemplate.label')}
      placeholder={t(
        'superAdmin.ManageDigitalSignature.defaultTemplatesCard.startClientNotificationTemplate.placeholder',
      )}
      tooltip={t('superAdmin.ManageDigitalSignature.defaultTemplatesCard.startClientNotificationTemplate.tooltip')}
      isLibrary
    />
  );
};

export default StartClientNotificationTemplateSelect;
