import { Collapse as CollapseAntd } from 'antd';
import { FaAngleRight } from 'react-icons/fa';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { Sans30px600, Sans26px600, Sans22px400 } from './Text/Text';
import ItemInCollapse from './ItemInCollapse';
import Collapse from './Collapse';

export const { Panel } = CollapseAntd;
export const ExpandIcon = ({ isActive, className }) => {
  const isSmallWidthDevice = useMediaQuery({ maxWidth: 500 });
  return (
    <span className={`collapse-arrow ${isActive && 'collapse-arrow-active'} ${className}`}>
      <FaAngleRight size={isSmallWidthDevice ? 15 : 18} />
    </span>
  );
};
const NumberInCircle = ({ value }) => {
  const isSmallWidthDevice = useMediaQuery({ maxWidth: 500 });

  return (
    <div
      style={{
        marginLeft: 'auto',
        marginTop: isSmallWidthDevice ? 2 : 4,
        width: isSmallWidthDevice ? 18 : 21,
        height: isSmallWidthDevice ? 18 : 21,
        borderRadius: 100,
        backgroundColor: '#FCAD00',
      }}
    >
      <Sans26px600
        style={{
          display: 'flex',
          placeContent: 'space-evenly',
        }}
        textStyle={{ color: 'white', marginTop: isSmallWidthDevice ? 0 : -2 }}
      >
        {value}
      </Sans26px600>
    </div>
  );
};
// {/* {<FaInfoCircle  style={{verticalAlign: -3}}/>} */}
// TODO: display items, abstract collapse
const CategoriesCollapse = ({ categories }) => {
  return (
    // eslint-disable-next-line react/no-unstable-nested-components
    <Collapse expandIcon={(props) => <ExpandIcon {...props} />}>
      {categories.map((category) => {
        return (
          <Panel
            key={category._id}
            header={
              <div style={{ display: 'flex' }}>
                <Sans30px600>{category.name}</Sans30px600>
                <NumberInCircle value={category.items.length} />
              </div>
            }
          >
            <div style={{ display: 'flex', placeContent: 'space-between' }}>
              <Sans22px400>Leistungsbeginn ab {moment(category.startDate).format('MM/YYYY')}</Sans22px400>
              <Sans22px400>Umsatz {category.total} Euro</Sans22px400>
            </div>
            <div style={{ marginTop: 10 }}>
              {category.items.map((item) => {
                return <ItemInCollapse item={item} key={item._id} />;
              })}
            </div>
          </Panel>
        );
      })}
    </Collapse>
  );
};

export default CategoriesCollapse;
