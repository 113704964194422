import { gql } from '@apollo/client';
import { adminEmailTemplateFragment } from 'graphql/fragments';

const createEmailTemplateMutation = gql`
  mutation createEmailTemplateMutation($emailTemplate: EmailTemplateInput!, $isLibrary: Boolean) {
    createEmailTemplate(emailTemplate: $emailTemplate, isLibrary: $isLibrary) {
      ...adminEmailTemplateFragment
    }
  }
  ${adminEmailTemplateFragment}
`;

export default createEmailTemplateMutation;
