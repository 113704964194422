/* eslint-disable no-underscore-dangle */
import React from 'react';
import { useMutation, useApolloClient } from '@apollo/client';
import first from 'lodash/first';
import RefreshTokenService from 'utils/RefreshTokenService';
import { currentUserQuery } from 'graphql/queries';

const useAuth = (mutation) => {
  const [authMutation, { loading }] = useMutation(mutation);
  const client = useApolloClient();
  const auth = React.useCallback(
    async (variables) => {
      const res = await authMutation({ variables });
      const resultData = first(Object.values(res.data));
      const { refreshToken } = resultData;
      RefreshTokenService.setRefreshToken(refreshToken);
      client.writeQuery({
        query: currentUserQuery,
        data: { me: resultData.user },
      });
      return resultData;
    },
    [authMutation, client],
  );

  return [auth, loading];
};

export default useAuth;
