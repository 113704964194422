import { moveDocumentBlockCache } from 'graphql/cache';
import { updateDocumentTemplate } from 'graphql/methods';
import pick from 'lodash/pick';
import { clientShift } from 'utils/helpers';

class BlockController {
  normalizeBlockInput = (block) => pick(block, ['_id', 'name', 'props']);

  normalizeBlocksInput = (blocks) => blocks.map(this.normalizeBlockInput);

  addBlock = ({ blocks, block, position = 'top' }) => {
    let newBlocks = [];
    const newBlock = {
      name: block.name,
      props: undefined,
    };
    if (position === 'top') {
      newBlocks = [newBlock, ...blocks];
    } else newBlocks = [...blocks, newBlock];
    return newBlocks;
  };

  deleteBlockById = ({ blocks, blockId }) => blocks.filter(({ _id }) => _id !== blockId);

  reorderBlockById = ({ blocks, blockId, shift }) => {
    const newBlocks = clientShift({ array: blocks, _id: blockId, shift });
    return newBlocks;
  };

  updateBlockForm = ({ blocks, blockId, formValues }) => {
    return blocks.map((block) => {
      if (block._id === blockId) {
        return { ...block, props: formValues };
      }
      return block;
    });
  };

  persistBlocks = ({ _id, blocks }) => {
    return updateDocumentTemplate({ _id, modifier: { blocks: this.normalizeBlocksInput(blocks) } });
  };

  persistBlocksInCache = ({ documentTemplateId, blocks }) => {
    moveDocumentBlockCache({ documentTemplateId, blocks });
  };
}

export default new BlockController();
