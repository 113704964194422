import { gql } from '@apollo/client';
import { currentUserFragment } from 'graphql/fragments';

const updateUserMutation = gql`
  mutation updateUserMutation($profile: UserProfileInput, $role: Role, $email: String, $userId: ID!) {
    updateUser(profile: $profile, role: $role, email: $email, userId: $userId) {
      ...currentUserFragment
    }
  }
  ${currentUserFragment}
`;

export default updateUserMutation;
