import { gql } from '@apollo/client';
import { adminTenantCompanyFragment } from 'graphql/fragments';

const adminTenantCompanyQuery = gql`
  query adminTenantCompanyQuery {
    getTenantCompany {
      ...adminTenantCompanyFragment
    }
  }
  ${adminTenantCompanyFragment}
`;

export default adminTenantCompanyQuery;
