import Switch from 'components/common/Switch';

const ConfigurationSwitch = ({ fieldDescriptor, label, i18n }) => {
  return (
    <Switch
      name={fieldDescriptor.name}
      label={label}
      checkedChildren={i18n.t('common.on')}
      unCheckedChildren={i18n.t('common.off')}
      tooltip={fieldDescriptor.tooltip}
    />
  );
};

export default ConfigurationSwitch;
