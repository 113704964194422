import { gql } from '@apollo/client';

const waitForDocsGeneratedSubscription = gql`
  subscription WaitForDocsGenerated {
    waitForDocsGenerated {
      state
      errorMessage
      documents {
        _id
        documentTemplateId
        type
        isGenerating
        name
        templateName
        documentNumber
        isSigned
      }
    }
  }
`;

export default waitForDocsGeneratedSubscription;
