import { Tabs } from 'antd';
import PageContainer from 'components/layout/PageContainer';
import equal from 'fast-deep-equal/es6/react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import MainForm from './Main/MainForm';
import PasswordForm from './Password/PasswordForm';
import classes from './AccountSetting.module.less';

const { TabPane } = Tabs;

function AccountSettingTabs() {
  const { t } = useTranslation();
  return (
    <Tabs type="card" className={classes.tabs}>
      <TabPane tab={t('user.Setting.AccountSetting.tabs.main.title')} key="1">
        <MainForm />
      </TabPane>
      <TabPane tab={t('user.Setting.AccountSetting.tabs.password.title')} key="2">
        <PasswordForm />
      </TabPane>
    </Tabs>
  );
}
const AccountSettingTabsMemo = memo(AccountSettingTabs, equal);

function AccountSettingWrapper() {
  const { t } = useTranslation();
  return (
    <PageContainer title={t('user.Setting.AccountSetting.title')}>
      <AccountSettingTabsMemo />
    </PageContainer>
  );
}

export default memo(AccountSettingWrapper, equal);
