import equal from 'fast-deep-equal/es6/react';
import { DatePicker as I } from 'formik-antd';
import { useFunctionToRefCB } from 'memo';
import { memo, useCallback, useState } from 'react';
import moment from 'moment';
import 'moment/locale/de';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useFormikField } from 'hooks/common/useFormikField';
import { Button } from 'antd';

export const ThisMonthButton = memo(({ onFormat, onClose, name }) => {
  const { t } = useTranslation();
  const { onChange } = useFormikField(name);
  return (
    <Button
      type="link"
      className="ant-picker-today-btn"
      onClick={() => {
        const today = new Date();
        const firstDayOfThisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        onChange(onFormat(firstDayOfThisMonth));
        onClose();
      }}
    >
      {t('user.ShoppingCart.validityContract.thisMonth')}
    </Button>
  );
}, equal);

const II = memo(I, equal);
const InputDate = (props) => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState();
  const onClose = useCallback(() => setIsOpen(false), []);
  const { setFieldValueAndTouched } = useFormikContext();
  const { format = 'MM/YYYY', formatTo = 'YYYY-MM' } = props;
  const onFormat = useCallback((value) => moment(value, format).format(formatTo), [format, formatTo]);
  const { name, renderExtraFooter } = props;
  return (
    <II
      {...props}
      onOpenChange={setIsOpen}
      open={isOpen}
      format={format}
      key={i18n.language}
      // https://github.com/jannikbuschke/formik-antd/issues/151
      // format issue dirty hack with moment applied below
      onChange={useFunctionToRefCB((e, string) => {
        setFieldValueAndTouched(name, string ? onFormat(string) : null);
      })}
      {...('renderExtraFooter' in props && {
        renderExtraFooter: (p) => renderExtraFooter?.({ ...p, onFormat, onClose }),
      })}
    />
  );
};

export default memo(InputDate, equal);
