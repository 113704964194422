import { gql } from '@apollo/client';
import { adminPersonFragment } from 'graphql/fragments';

const createPersonMutation = gql`
  mutation createPersonMutation($person: PersonInput!) {
    createPerson(person: $person) {
      ...adminPersonFragment
    }
  }
  ${adminPersonFragment}
`;

export default createPersonMutation;
