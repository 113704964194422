import { gql } from '@apollo/client';
import { adminUserFragment } from 'graphql/fragments';

const adminUsersInTenantQuery = gql`
  query adminUsersInTenantQuery {
    usersInTenant {
      ...adminUserFragment
    }
  }
  ${adminUserFragment}
`;

export default adminUsersInTenantQuery;
