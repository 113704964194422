import apollo from 'graphql/apollo';
import { createHtmlPreviewByIdMutation } from 'graphql/mutations';

const createHtmlPreviewById = async ({ shoppingCartId, documentTemplate }) =>
  (
    await apollo.mutate({
      mutation: createHtmlPreviewByIdMutation,
      variables: { shoppingCartId, documentTemplate },
    })
  )?.data.generateDocumentHTMLPreviewById;
export default createHtmlPreviewById;
