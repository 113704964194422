import apollo from 'graphql/apollo';
import { tenantLogoQuery } from 'graphql/queries';
import { uploadFile } from '../file';

export const updateTenantLogoInCache = async ({ url }) => {
  apollo.writeQuery({
    query: tenantLogoQuery,
    data: {
      getTenantLogo: url,
    },
  });
};
const updateTenantLogo = async ({ logo, tenantId }) => {
  const data = await uploadFile({
    name: `CompanyLogo_${new Date().toISOString()}`,
    parentId: tenantId,
    parentType: 'tenants',
    size: logo.size,
    file: logo,
  });
  updateTenantLogoInCache(data);
};

export default updateTenantLogo;
