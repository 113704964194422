import { gql } from '@apollo/client';

const userPreferencesShoppingCartViewQuery = gql`
  query userPreferencesShoppingCartViewQuery {
    tenantSettings {
      _id
      shoppingCartPreferences {
        _id
        emailTemplateId
      }
      emailPreferences {
        _id
        signature
      }
    }
  }
`;

export default userPreferencesShoppingCartViewQuery;
