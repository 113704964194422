import { useEffect, useMemo, useState } from 'react';
import { Alert, Button, Divider, Space, Typography } from 'antd';
import SubHeading from 'components/common/SubHeading';
import { useTranslation } from 'react-i18next';
import Collapsable from 'components/common/InlineCollapsable';
import { DkimHelperWidget } from 'pages/admin/EmailSetting/DkimHelperWidget';
import { useFormikContext } from 'formik';
import { DownloadOutlined } from '@ant-design/icons';
import { DKIMVerificationStatus } from './DKIMVerificationStatus';
import { SPFRecord } from './SPFRecord';
import { DKIMRecord } from './DKIMRecord';
import DMARCRecord from './DMARCRecord';
import { downloadDNSConfig } from './downloadDnsConfig';
import { createDmarcHostname } from './createDmarcHostname';
import classes from './DKIM_SPFConfigurationStep.module.less';

const { Text } = Typography;

/**
 * This component displays the DKIM and SPF configuration on the email settings page
 * @param domain {string} - the custom domain
 * @returns {JSX.Element}
 * @component
 */
export const DKIM_SPFConfigurationStep = ({ domain }) => {
  const { t } = useTranslation();

  const { values } = useFormikContext();

  const isDomainEmpty = useMemo(() => !domain || domain === '', [domain]);

  const [prevDomain, setPrevDomain] = useState(domain);

  useEffect(() => {
    setPrevDomain(domain);
  }, [domain, prevDomain]);

  if (isDomainEmpty) return null;

  const onDownloadConfigClicked = () =>
    downloadDNSConfig(
      domain,
      { hostname: values.dkimSettings.dkimRecordHostname, value: values.dkimSettings.dkimRecord },
      { hostname: values.dkimSettings.spfRecordHostname, value: values.dkimSettings.spfRecord },
      {
        hostname: createDmarcHostname(values.dkimSettings.domainName),
        value: t('admin.Setting.Email.inputs.dnsSettings.dmarcRecord.value'),
      },
    );

  return (
    <>
      <Divider />
      <Collapsable
        open={prevDomain !== domain}
        header={
          <SubHeading title={t('admin.Setting.Email.inputs.dnsSettings.heading')} paddingBottom="0px" marginTop="0px" />
        }
      >
        <Alert
          description={
            <>
              <Text style={{ display: 'inline-block' }}>
                {t('admin.Setting.Email.inputs.dnsSettings.introAlertText')}
              </Text>
              <Space direction="vertical" size={0}>
                <DkimHelperWidget />
                <Button
                  type="link"
                  onClick={onDownloadConfigClicked}
                  icon={<DownloadOutlined />}
                  className={classes.downloadButton}
                >
                  {t('admin.Setting.Email.inputs.dnsSettings.downloadButton')}
                </Button>
              </Space>
            </>
          }
          showIcon
          type="info"
          className="alert-info"
        />
        <DKIMRecord />
        <SPFRecord />
        <DMARCRecord />
        <SubHeading title={t('admin.Setting.Email.inputs.dkimStatusCheck.heading')} />
        <DKIMVerificationStatus domain={domain} />
      </Collapsable>
    </>
  );
};
