import { gql } from '@apollo/client';

const createShoppingCartMutation = gql`
  mutation createShoppingCartMutation($shoppingCart: ShoppingCartInput!) {
    createShoppingCart(shoppingCart: $shoppingCart) {
      _id
      digitalSignatureState
      signatureData {
        companySignees {
          email
          firstName
          lastName
          id
        }
        tenantSignees {
          email
          firstName
          lastName
          id
        }
        signedCompanySignees {
          signeeEmail
          signeeName
          relatedContactObject
          signDate
          signIP
        }
        signedTenantSignees {
          signeeEmail
          signeeName
          relatedTenantObject
          signDate
          signIP
        }
      }
    }
  }
`;

export default createShoppingCartMutation;
