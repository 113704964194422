import { gql } from '@apollo/client';

const adminStaticItemFragment = gql`
  fragment adminStaticItemFragment on StaticItem {
    _id
    nameRaw
    valueRaw
    readableTexts {
      name
      value
    }
    order
  }
`;

export default adminStaticItemFragment;
