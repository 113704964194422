import { useCachedQuery } from 'graphql/utils';
import { adminPlaceholderDataQuery } from 'graphql/queries';
import i18n from 'i18n';
import apolloClient from 'graphql/apollo';
import { orderBy, find } from 'lodash';
import { useLibraryContext } from 'contexts/LibraryContext';
import { CalculationMode } from 'constants/item';
import { InternalInputFieldType, InputFieldType } from 'constants/inputField';

const getFormulaTranslate = (name) => (props) => i18n.t(`FormulaNames.${name}`, props);
// USED ONLY FOR FORMULA CALCULATION
const getPrefix = () => {
  return {
    category: getFormulaTranslate('categoryType'),
    categoryItem: getFormulaTranslate('itemType'),
    itemTotal: getFormulaTranslate('itemTotal'),
    categoryMonthlyAdvance: getFormulaTranslate('categoryMonthlyAdvance'),
    variable: getFormulaTranslate('variable'),
  };
};
export const sortPriorities = {
  categoryItem: 1,
  category: 2,
  itemTotal: 3,
  categoryMonthlyAdvance: 4,
};
export const sortedFieldsByType = ({ fields, parentId }) => {
  const withSortPriorities = fields.map((s) => ({
    ...s,
    sortPriority: parentId === s.parentId ? 0 : sortPriorities[s.type] || 100,
  }));
  return orderBy(withSortPriorities, ['sortPriority', 'name']);
};

const formatCategoryItem = ({ item, name }) => {
  let disabled = '';
  if (!item.active) disabled = 'inactiveItem';
  else if (item.calculationMode === CalculationMode.ON_ACTUAL_COST) disabled = 'onActualCostItem';
  const disabledTooltip = disabled
    ? i18n.t(`admin.itemModal.inputs.pricingFormula.insertVariableMenu.disabledMenuTooltip.${disabled}`)
    : '';
  return {
    color: 'magenta',
    type: 'itemTotal',
    name: getFormulaTranslate('item')({ name, categoryName: item?.category?.name }),
    disabled: !!disabled,
    disabledTooltip,
  };
};
const formatCategory = ({ category, name }) => {
  // eslint-disable-next-line no-nested-ternary
  const disabled = !category.items.find((someInput) => someInput.paymentInterval !== 'oneOff' && someInput.active)
    ? 'categoryMonthlyAdvance'
    : '';
  const disabledTooltip = disabled
    ? i18n.t(`admin.itemModal.inputs.pricingFormula.insertVariableMenu.disabledMenuTooltip.${disabled}`)
    : '';

  return {
    color: 'green',
    type: 'categoryMonthlyAdvance',
    name: getFormulaTranslate('category')({ name }),
    disabled: !!disabled,
    disabledTooltip,
  };
};
const getInputFieldPrefix = (inputField) => {
  if (inputField.type === InputFieldType.INTERNAL) return 'inputField_internal';
  return inputField.parentType === 'category' ? 'inputField' : 'inputFieldItem';
};
const getInputTranslation = () => i18n.t('common.InputField.name');
export const getFieldsFromData = ({ data, parentId, categoryId, _id }) => {
  const prefix = getPrefix();
  const variables = data?.variables?.variables?.filter((v) => ['float', 'percent', 'pricePerHour'].includes(v.type));
  const all = [
    ...(data?.inputs || []),
    ...(data?.categoryItems || []),
    ...(data?.categories || []),
    ...(variables || []),
  ];
  const fields = all
    .map((input) => {
      const { name } = input;
      if (input.parentType === 'categoryItem' && input.parentId !== parentId) return null;
      return {
        _id: input._id,
        originalName: input.name,
        autoCompleteName: name,
        description: `(Value of "${input.name}" in category "${input?.category?.name}")`,
        parentId: input.parentId,
        ...(input.__typename === 'InputField' &&
          !input.recursiveFormulaInfo?.usedIdsInFormula?.find((i) => _id === i) && {
            color: input.parentType === 'category' ? 'orange' : 'geekblue',
            type: input.parentType,
            name: getFormulaTranslate(getInputFieldPrefix(input))({
              categoryName: input?.parent?.name,
              name,
              ...(input.type === InputFieldType.INTERNAL && {
                default:
                  input.defaultType === InternalInputFieldType.INPUT
                    ? input.defaultValue
                    : find(variables, { _id: input.defaultValue })?.label,
              }),
            }),
            description: `(${getInputTranslation()} "${input.name}" in ${prefix[input.parentType]} "${
              input?.parent?.name
            }")`,
          }),
        ...(input.__typename === 'CategoryItem' &&
          !(
            [parentId, categoryId].includes(input._id) ||
            input.recursiveFormulaInfo?.usedItemIdsInFormula?.find((i) => [parentId, categoryId].includes(i))
          ) &&
          formatCategoryItem({ item: input, name })),
        ...(input.__typename === 'Category' &&
          !input.items.find(
            (someInput) =>
              [parentId, categoryId].includes(someInput._id) ||
              someInput.recursiveFormulaInfo?.usedItemIdsInFormula?.find((i) => [parentId, categoryId].includes(i)),
          ) &&
          formatCategory({ category: input, name })),
        ...(input.__typename === 'Variable' && {
          // color: 'green',
          type: 'variable',
          name: getFormulaTranslate('variable')({ name: input.label }),
          originalName: input.label,
          // disabled: input.items.find(
          //   (someInput) =>
          //     [parentId, categoryId].includes(someInput._id) ||
          //     someInput.recursiveFormulaInfo?.usedItemIdsInFormula?.find((i) => [parentId, categoryId].includes(i)),
          // ),
        }),
      };
    })
    .filter((e) => e?.type && e._id !== _id);
  const sortedFieldsByPriority = sortedFieldsByType({ fields, parentId });
  return sortedFieldsByPriority;
};
export const getAdminInputFields = ({ categoryId, parentId, isLibrary }) => {
  const data = apolloClient.readQuery({
    query: adminPlaceholderDataQuery,
    variables: { categoryId, parentId, isLibrary },
  });
  const fields = getFieldsFromData({ data, parentId, categoryId });
  return fields;
};
const useAdminInputFields = ({ categoryId, parentId, fetchPolicy = 'network-only' }) => {
  const { isLibrary } = useLibraryContext();
  const { data, loading } = useCachedQuery(adminPlaceholderDataQuery, {
    variables: { categoryId, parentId, isLibrary },
    fetchPolicy,
    nextFetchPolicy: 'cache-only',
  });
  if (loading) return [null, true];
  const fields = getFieldsFromData({ data, parentId, categoryId });
  return [fields, loading];
};

export default useAdminInputFields;
