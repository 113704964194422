import { gql } from '@apollo/client';

const adminPdfMainThemeFragment = gql`
  fragment adminPdfMainThemeFragment on PdfMainTheme {
    primaryColor
    fontFamily
    color
  }
`;

export default adminPdfMainThemeFragment;
