import { gql } from '@apollo/client';

const userHistoricalDocumentTemplateQuery = gql`
  query userHistoricalDocumentTemplateQuery($shoppingCartId: ID!) {
    userShoppingCart(_id: $shoppingCartId) {
      documentTemplates {
        initializationConfigDate
        _id
        _apolloCacheKey
        name
        order
        customizableBlocks {
          _id
          _apolloCacheKey
          name
          props
          isCustomizableForShoppingCart
        }
      }
    }
  }
`;

export default userHistoricalDocumentTemplateQuery;
