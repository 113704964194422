import { useMemo } from 'react';
import { Button } from 'antd';
import { FaSignature } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import useFasterPreferences from 'hooks/user/useFasterPreferences';
import { getBaseShapeForShoppingCartSchema } from 'pages/shoppingCartManagement/ShoppingCart/utils';
import useDocumentTemplateList from 'hooks/user/useDocumentTemplateList';
import { DIGITAL_SIGNATURE_STATE } from '@JavaScriptSuperstars/kanzleipilot-shared/lib/digitalSignature';
import useStartDigitalSignatureDrawer from 'utils/OpenStartDigitalSignatureModal';

/**
 * Maps signee of a shopping cart to json string
 * @param {Object} signee - Signee infos
 * @returns {string} json of a signee
 */
const mapSignee = (signee) => {
  const jsonfiedSignee = JSON.stringify({ id: signee.id, email: signee.email });
  return jsonfiedSignee;
};

/**
 * Maps selected document template infos of a shopping cart to json string
 * @param {Object} documentTemplate - Shopping cart document template infos, which should be mapped to json version
 * @returns {string} json of a selected document template of a shopping cart
 */
const mapDocumentTemplate = (documentTemplate) => {
  return JSON.stringify(documentTemplate);
};

/**
 * StartDigitalSignatureProcessButton component for shopping cart view page to start the digital signature process on a shopping cart, if cart is ready
 * @param {Object} inputParameter - Input parameter
 * @param {Object} inputParameter.shoppingCart - Shopping cart object, on which the digital signature process should be started
 * @returns {JSX.Element} StartDigitalSignatureProcessButton component for shopping cart view page
 */
const StartDigitalSignatureProcessButton = ({ shoppingCart }) => {
  const { data: documentTemplateList } = useDocumentTemplateList();
  const { preferences: currentPreferences } = useFasterPreferences();
  const { t: translation } = useTranslation();

  const modifiedShoppingCart = useMemo(
    () => ({
      ...shoppingCart,
      contacts: shoppingCart?.contacts?.map((id) => ({ id })),
      companyId: shoppingCart?.company?._id,
      companySignees: shoppingCart?.signatureData?.companySignees?.map(mapSignee),
      documentTemplates: shoppingCart?.documentTemplates?.map(mapDocumentTemplate),
      tenantSignees: shoppingCart?.signatureData?.tenantSignees?.map(mapSignee),
      enableDigitalSignature: true,
    }),
    [shoppingCart],
  );

  const { openStartDigitalSignatureDrawer } = useStartDigitalSignatureDrawer();

  if (!shoppingCart || !documentTemplateList || shoppingCart.isStarting) return null;

  if (shoppingCart.digitalSignatureState !== DIGITAL_SIGNATURE_STATE.DRAFT) return false;

  if (currentPreferences?.digitalSignaturePreferences?.digitalSignatureEnabled === false) {
    return null;
  }

  const shapeForShoppingCartValidation = getBaseShapeForShoppingCartSchema(documentTemplateList);

  try {
    const validationResult = Yup.object().shape(shapeForShoppingCartValidation).validateSync(modifiedShoppingCart);

    if (!validationResult) return null;

    return (
      <Button
        icon={
          <span className="anticon">
            <FaSignature />
          </span>
        }
        type="primary"
        onClick={() => openStartDigitalSignatureDrawer({ shoppingCart, preferences: currentPreferences, translation })}
      >
        {translation('viewer.ShoppingCartView.startDigitalSignature')}
      </Button>
    );
  } catch (e) {
    return null;
  }
};

export default StartDigitalSignatureProcessButton;
