import Card from 'components/common/Card';
import classes from './ShoppingCartView.module.less';

const GeneralShoppingCartDataCard = ({ projectName, t }) => {
  return (
    <Card title={t('viewer.ShoppingCartView.GeneralProjectData.projectName')}>
      <div className={classes.generalProjectDataBody}> {projectName} </div>
    </Card>
  );
};

export default GeneralShoppingCartDataCard;
