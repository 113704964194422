// https://github.com/formium/formik/issues/284
import equal from 'fast-deep-equal/es6/react';
import { Input as InputAntd } from 'antd';
import { useFormikContext } from 'formik';
import { useFunctionToRefCB } from 'memo';
import { memo } from 'react';
import './InputNumber.less';
import { FieldFormikWithDebounce } from './FieldFormikWithDebounce';

const I = memo(InputAntd, equal);
const II = memo(FieldFormikWithDebounce, equal);

const Input = ({ className, debounceTime, ...props }) => {
  const { setFieldValueAndTouched } = useFormikContext();

  return (
    <II
      {...props}
      onChange={useFunctionToRefCB((e) => setFieldValueAndTouched(props.name, e))}
      debounceTime={debounceTime}
      component={I}
    />
  );
};

export default memo(Input, equal);
