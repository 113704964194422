import { gql } from '@apollo/client';

const userShoppingCartPDFsQuery = gql`
  query userShoppingCartPDFsQuery($_id: ID!) {
    userShoppingCart(_id: $_id) {
      _id
      company {
        _id
        name
      }
      pdfs {
        _id
        documentTemplateId
        link
        isGenerating
        name
        size
        templateName
        documentNumber
        isSigned
      }
    }
  }
`;

export default userShoppingCartPDFsQuery;
