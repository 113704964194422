import { gql } from '@apollo/client';
import { adminCanCreateCompanyFragment } from 'graphql/fragments';

const adminCanCreateCompanyQuery = gql`
  query adminCanCreateCompanyQuery {
    canCreateCompany {
      ...adminCanCreateCompanyFragment
    }
  }
  ${adminCanCreateCompanyFragment}
`;

export default adminCanCreateCompanyQuery;
