import { gql } from '@apollo/client';
import { userContactFragment } from 'graphql/fragments';

const userContactListQuery = gql`
  query userContactListQuery($initializationConfigDate: DateTime) {
    contacts(initializationConfigDate: $initializationConfigDate) {
      ...userContactFragment
    }
  }
  ${userContactFragment}
`;

export default userContactListQuery;
