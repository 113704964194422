import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { FormItem as OriginalFormItem } from 'formik-antd';

const FormItem = ({ containerProps, label, sublabel, tooltip, wideTooltip, ...props }) => {
  const { name } = props;
  let formLabel = label;

  if (tooltip) {
    formLabel = (
      <div>
        <div className="align-center">
          <span>{label}</span>
          <span style={{ marginLeft: '8px' }}>
            <Tooltip title={tooltip} autoAdjustOverflow overlayStyle={wideTooltip ? { maxWidth: '500px' } : undefined}>
              <InfoCircleOutlined />
            </Tooltip>
          </span>
        </div>
        {sublabel ? <div>{sublabel}</div> : null}
      </div>
    );
  }

  return (
    <div fieldname={name} {...containerProps}>
      <OriginalFormItem {...props} label={formLabel} />
    </div>
  );
};

export default FormItem;
