import { gql } from '@apollo/client';
import { userShoppingCartEntriesTableFragment } from 'graphql/fragments';

const updateShoppingCart = gql`
  mutation updateShoppingCart($_id: ID!, $modifier: ShoppingCartUpdateInput!) {
    updateShoppingCart(_id: $_id, modifier: $modifier) {
      ...userShoppingCartEntriesTableFragment
    }
  }
  ${userShoppingCartEntriesTableFragment}
`;

export default updateShoppingCart;
