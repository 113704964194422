import EmailTemplateSelect from 'components/common/EmailTemplateSelect';
import { useTranslation } from 'react-i18next';

/**
 * RevokeEmailTemplateSelect component to select a commonlibrary email template for the digital signature revoked email default template (Required formik context)
 * @returns {JSX.Element} email template select for the default templates settings for the super admin
 * @component
 */
const RevokeEmailTemplateSelect = () => {
  const { t } = useTranslation();
  return (
    <EmailTemplateSelect
      fieldName="revokeEmailTemplate"
      label={t('superAdmin.ManageDigitalSignature.defaultTemplatesCard.revokeEmailTemplate.label')}
      placeholder={t('superAdmin.ManageDigitalSignature.defaultTemplatesCard.revokeEmailTemplate.placeholder')}
      tooltip={t('superAdmin.ManageDigitalSignature.defaultTemplatesCard.revokeEmailTemplate.tooltip')}
      isLibrary
    />
  );
};

export default RevokeEmailTemplateSelect;
