import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import { grabFirstGQLDataResult } from 'utils/helpers';
import inputConfirmModal from 'utils/inputConfirmModal';
import { Select } from 'formik-antd';
import * as Yup from 'yup';
import { useCachedQuery } from 'graphql/utils';
import GraphQLLoadingWrapper from 'components/common/GraphQLLoadingWrapper';
import FormItem from 'components/common/FormItem';
import { filter } from 'lodash';
import { copyCategoryItem } from 'graphql/methods';
import { RichText } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { adminGetCategoryListQuery } from 'graphql/queries';
import { Alert } from 'antd';
import toast from 'utils/toast';
import classes from './CollapsibleCategoryConfiguration.module.less';
import { NameFormikInput } from './itemModal/components';
import { textsSchema } from './itemModal/schema';

const { Option } = Select;

export const schema = () => ({
  ...textsSchema(),
  categoryId: Yup.string()
    .label(i18n.t('admin.CatalogueConfiguration.copyItem.inputs.category.errorLabel'))
    .required()
    .nullable(),
});

const validationSchema = () => Yup.object().shape(schema());

const CategoryFormikInput = ({ categories }) => {
  const { t } = useTranslation();
  const name = 'categoryId';
  return (
    <FormItem
      label={t('admin.CatalogueConfiguration.copyItem.inputs.category.label')}
      className={classes.discount}
      name={name}
    >
      <Select name={name} className={classes.discountSelect} allowClear>
        {categories.map((category) => (
          <Option key={category._id} value={category._id}>
            {category.name}
          </Option>
        ))}
      </Select>
    </FormItem>
  );
};
const ItemForm = ({ categoryId, isLibrary, isComplex }) => {
  const { t } = useTranslation();
  const result = useCachedQuery(adminGetCategoryListQuery(isLibrary), {});
  const { data } = result;
  const categories = useMemo(
    () => filter(grabFirstGQLDataResult(data), (c) => c._id !== categoryId),
    [categoryId, data],
  );
  return (
    <GraphQLLoadingWrapper {...result}>
      {isComplex ? (
        <Alert
          showIcon
          closable
          message={RichText.renderRichText(t('admin.CatalogueConfiguration.copyItem.complexPricingFormula'))}
          description=" "
          type="info"
          className="alert-info"
        />
      ) : null}
      <NameFormikInput label={t('admin.CatalogueConfiguration.copyItem.inputs.name.label')} />
      <CategoryFormikInput categories={categories} />
    </GraphQLLoadingWrapper>
  );
};

export const formatCopyItem = (name) => {
  let formattedName = name;
  if (!(formattedName ?? '').endsWith(' ')) formattedName += ' ';
  return formattedName + i18n.t('admin.CatalogueConfiguration.copyItem.addonAfter');
};

export const copyCategoryItemModal = ({ _id, categoryId, name, isLibrary, isComplex, isImport } = {}) =>
  inputConfirmModal({
    fields: [],
    formContent: () => (
      <ItemForm categoryId={categoryId} isComplex={isComplex} isLibrary={isImport ? false : isLibrary} />
    ),
    onSubmit: async (modifier) =>
      copyCategoryItem({
        ...modifier,
        itemId: _id,
        categoryListQuery: adminGetCategoryListQuery(isImport ? false : isLibrary),
      }).then(() =>
        toast.success(i18n.t('admin.CatalogueConfiguration.copyItem.success.title'), {
          subTitle: i18n.t('admin.CatalogueConfiguration.copyItem.success.subTitle'),
          duration: 4,
        }),
      ),
    value: {
      name: formatCopyItem(name),
      categoryId: undefined,
    },
    errorResolver: { Duplicated: ['name', i18n.t('admin.CatalogueConfiguration.copyItem.duplicatedErrorMessage')] },
    headerText: i18n.t('admin.CatalogueConfiguration.copyItem.title'),
    okText: i18n.t('admin.CatalogueConfiguration.copyItem.ok'),
    cancelText: i18n.t('admin.CatalogueConfiguration.copyItem.cancel'),
    validationSchema,
    width: '600px',
  });
