import { useEffect, useState } from 'react';
import { isWidthControlledMode } from '../utils/PdfViewerUtils';

/**
 * Listen to window size changes and return if pdf drawer should work in widthControlled mode
 * @returns {Boolean} should pdf viewer work in width controlled mode
 */
const useWidthControlledMode = () => {
  const [widthControlled, setWidthControlled] = useState(isWidthControlledMode());

  useEffect(() => {
    const handleResize = () => {
      const isWidthControlled = isWidthControlledMode();
      setWidthControlled(isWidthControlled);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return widthControlled;
};

export default useWidthControlledMode;
