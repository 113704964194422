import { gql } from '@apollo/client';

const signingLoginMutation = gql`
  mutation signingLoginMutation($linkToken: String!, $password: String) {
    loginAsSignee(linkToken: $linkToken, password: $password) {
      authToken
      signingProcessId
      theme
      firstName
      lastName
      nameOfCompany
      typeOfSignee
      positionOfSignee
      isPrivatePerson
      steps
    }
  }
`;

export default signingLoginMutation;
