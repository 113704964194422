const REMOVING_STYLE_OPTIONS = [
  'bulleted-list',
  'heading-one',
  'heading-three',
  'heading-two',
  'list-item',
  'numbered-list',
  'title',
  'paragraph',
  'div',
];

const removeStylingElements = (value) => {
  const flatArray = [];
  const processFn = (element) => {
    if (element.type === 'mention') {
      const newElement = {};
      Object.assign(newElement, element, { bold: false, italic: false, underline: false });
      flatArray.push(newElement);
    } else if (REMOVING_STYLE_OPTIONS.includes(element.type)) flatArray.push('\n');
    if (typeof element.text === 'string') flatArray.push(element.text);
    element.children?.forEach((child) => processFn(child));
  };
  value.forEach((element) => processFn(element));
  return flatArray;
};

const mergeStrings = (flattedArray) => {
  const jsonArr = [];
  flattedArray.forEach((element) => {
    const lastEl = jsonArr[jsonArr.length - 1];

    if (element === '\n' && (!jsonArr.length || lastEl?.endsWith?.('\n'))) return;
    if (typeof lastEl === 'string' && typeof element === 'string') {
      jsonArr[jsonArr.length - 1] = lastEl + element;
    } else jsonArr.push(element);
  });
  return jsonArr;
};

const getParagraph = (paragraph, rootElement) => ({
  type: rootElement,
  children: [
    {
      text: paragraph,
    },
  ],
});

const mapStringsToObject = (jsonArray, rootElement) => {
  const resultArray = [];
  jsonArray.forEach((element) => {
    if (typeof element === 'string') {
      resultArray.push(
        ...element
          .split('\n')
          .filter((e) => e.length)
          .map((paragraph) => getParagraph(paragraph, rootElement)),
      );
    } else if (typeof element === 'object' && element.type === 'mention') {
      resultArray.push({
        type: rootElement,
        children: [element],
      });
    } else resultArray.push(element);
  });
  return resultArray;
};

export const resetRichText = ({ value, rootElement = 'div' }) => {
  const flattedArray = removeStylingElements(value);
  const jsonArray = mergeStrings(flattedArray);
  const resultArray = mapStringsToObject(jsonArray, rootElement);

  if (resultArray.length === 0) return [getParagraph('')];

  return resultArray;
};

export const getSelectionRootBlocks = (editor) => {
  const arr = [editor?.selection?.anchor?.path?.[0] || 0, editor?.selection?.focus?.path?.[0] || 0];
  const min = Math.min(...arr);
  const max = Math.max(...arr);
  return {
    anchor: { offset: 0, path: [min, 0] },
    focus: { offset: 9999, path: [max, 9999] },
  };
};
