import { gql } from '@apollo/client';

const userCurrentDocumentTemplateQuery = gql`
  query userCurrentDocumentTemplateQuery($isLibrary: Boolean) {
    documentTemplates(isLibrary: $isLibrary) {
      initializationConfigDate
      _id
      _apolloCacheKey
      name
      order
      customizableBlocks {
        _id
        _apolloCacheKey
        name
        props
        isCustomizableForShoppingCart
      }
    }
  }
`;

export default userCurrentDocumentTemplateQuery;
