import { gql } from '@apollo/client';
import {
  adminCategoryListFragment,
  adminInputFieldFragment,
  adminItemFragment,
  adminVariableListFragment,
} from 'graphql/fragments';

const adminInputFieldsQuery = gql`
  query adminInputFieldsQuery($categoryId: ID!, $isLibrary: Boolean, $parentId: ID) {
    inputs(categoryId: $categoryId, parentId: $parentId) {
      ...adminInputFieldFragment
    }
    categoryItems(categoryId: $categoryId) {
      ...adminItemFragment
    }
    categories {
      ...adminCategoryListFragment
    }
    variables(isLibrary: $isLibrary) {
      ...adminVariableListFragment
    }
  }
  ${adminVariableListFragment}
  ${adminInputFieldFragment}
  ${adminItemFragment}
  ${adminCategoryListFragment}
`;

export default adminInputFieldsQuery;
