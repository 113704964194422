import { gql } from '@apollo/client';
import { adminDocumentTemplateListForPageFragment } from 'graphql/fragments';

const adminDocumentTemplateListForPageQuery = gql`
  query adminDocumentTemplateListForPageQuery($isLibrary: Boolean) {
    documentTemplates(isLibrary: $isLibrary) {
      ...adminDocumentTemplateListForPageFragment
    }
  }
  ${adminDocumentTemplateListForPageFragment}
`;

export default adminDocumentTemplateListForPageQuery;
