import { Space } from 'antd';
import I18nFormik from 'components/common/I18nFormik';
import { Radio as RadioFormik, Form } from 'formik-antd';
import { Radio } from 'components/common/Radio';
import FormItem from 'components/common/FormItem';
import { Trans, useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { useNavigate } from 'react-router';
import routePaths from 'router/route-paths';
import classes from './IdentityQuestionForm.module.less';

/**
 * IdentityQuestionForm component which represents the form for the identity step in the signing process
 * @param {Object} inputParameters - Input parameters of the component
 * @param {String} inputParameters.name - Name of signee
 * @param {String} inputParameters.company - Name of company of signee
 * @param {String} inputParameters.privateOrCompany - Is the signee a 'private' person or part of a 'company'
 * @param {React.Ref} inputParameters.submitReference - Reference, which accesses the onContinue function, which tries to submit the form on call
 * @param {Object} inputParameters.stepState - State of step for back function
 * @returns {JSX.Element} form for identity step
 * @component
 */
const IdentityQuestionForm = ({ name, company, privateOrCompany, submitReference, stepState }) => {
  const { t: translation } = useTranslation();
  const navigate = useNavigate();
  const innerRef = useRef();

  // eslint-disable-next-line no-param-reassign
  submitReference.current = {
    onContinue: async () => {
      if (innerRef?.current?.submitForm) {
        await innerRef.current.submitForm();
        return innerRef?.current?.isValid && { stepState: innerRef?.current?.values };
      }
      return false;
    },
  };

  const onSubmit = ({ identityCheck }, formik) => {
    const errors = {};
    if (!identityCheck) errors.identityCheck = translation('signing.identityStep.errors.noOptionSelected');
    if (!errors.identityCheck && identityCheck !== 'yes') {
      navigate(routePaths.signingWrongIdentity);
      return;
    }
    formik.setErrors(errors);
  };

  return (
    <I18nFormik
      initialValues={stepState || { identityCheck: undefined }}
      onSubmit={onSubmit}
      innerRef={innerRef}
      submitForm
    >
      <Form>
        <FormItem
          name="identityCheck"
          id="identityCheck"
          style={{ marginLeft: '2px', marginBottom: '8px' }}
          className={classes.options}
        >
          <RadioFormik.Group name="identityCheck">
            <Space direction="vertical">
              <Radio name="identityCheck" value="yes">
                <Trans
                  i18nKey={`signing.identityStep.options.${privateOrCompany}.yes`}
                  values={{ name, company }}
                  components={{ b: <strong /> }}
                />
              </Radio>
              <Radio name="identityCheck" value="no">
                <Trans
                  i18nKey={`signing.identityStep.options.${privateOrCompany}.no`}
                  values={{ name, company }}
                  components={{ b: <strong /> }}
                />
              </Radio>
            </Space>
          </RadioFormik.Group>
        </FormItem>
      </Form>
    </I18nFormik>
  );
};

export default IdentityQuestionForm;
