import apollo from 'graphql/apollo';
import { createTenantMutation } from 'graphql/mutations';
import { superAdminAvailableAccessGroupsQuery, superAdminTenantsQuery } from 'graphql/queries';

const createTenant = async ({
  name,
  firstName,
  lastName,
  email,
  nrOfAllowedCompaniesByPricingPlan,
  nrOfAllowedUsersByPricingPlan,
  licenseStarted,
  locale,
  commonLibraryAccessGroups,
}) =>
  apollo.mutate({
    mutation: createTenantMutation,
    variables: {
      name,
      firstName,
      lastName,
      email,
      nrOfAllowedCompaniesByPricingPlan,
      nrOfAllowedUsersByPricingPlan,
      licenseStarted,
      locale,
      commonLibraryAccessGroups,
    },
    refetchQueries: [{ query: superAdminTenantsQuery }, superAdminAvailableAccessGroupsQuery],
  });
export default createTenant;
