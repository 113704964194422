import { useState } from 'react';
import concatClassNames from 'utils/classNames';
import PinchZoomPane, { RESPONSE_MODE } from '../PinchZoomPane';
import { calculateDocumentHeight, calculateDocumentWidth } from '../utils/PdfViewerUtils';
import useCurrentSizeOfElement from '../hooks/useCurrentSizeOfElement';
import classes from './PdfViewerViewPane.module.less';

/**
 * PdfViewerViewPane component for pdf view, which controlles document sizes, horizontal scroll and pinch zoom
 * @param {Object} inputParameters - Input parameters for component
 * @param {JSX.Element} inputParameters.children - Child component, which gets the props: pageHeight:Number, pdfWrapperRef:React.Ref, updateScrollLeft:Function, availableHeight:Number, availableWidth:Number
 * @param {React.Ref} inputParameters.documentControlRef - Reference to control document (zoom and navigation)
 * @param {Boolean} inputParameters.widthControlled - Is pdf viewer in width controlled mode
 * @returns {JSX.Element} view pane for pdf view, which controlles available document sizes, horizontal scroll and pinch zoom
 * @component
 */
const PdfViewerViewPane = ({ children, documentControlRef, widthControlled }) => {
  const [pdfWrapperRef, setPdfWrapperRef] = useState(null);
  const { width: currentAvailableWidthForDoc, height: currentAvailableHeightForDoc } =
    useCurrentSizeOfElement(pdfWrapperRef);

  const scrollBarWidth = (pdfWrapperRef?.offsetWidth ?? 0) - (pdfWrapperRef?.clientWidth ?? 0);

  const onPinchOut = (scale, pinchEventCoord) => documentControlRef.current.zoomInPinch(scale, pinchEventCoord);
  const onPinchIn = (scale, pinchEventCoord) => documentControlRef.current.zoomOutPinch(scale, pinchEventCoord);

  const pageHeight =
    (widthControlled ? calculateDocumentHeight(currentAvailableWidthForDoc) : currentAvailableHeightForDoc) -
    scrollBarWidth;

  const updateScrollLeft = ({ scaleDifference, pinchEventXCoord }) => {
    if (!pdfWrapperRef) return;
    if (scaleDifference && pinchEventXCoord) {
      const pinchXRelatedToDoc = pdfWrapperRef.scrollLeft + pinchEventXCoord;
      const newScrollLeft = scaleDifference * pinchXRelatedToDoc - pinchEventXCoord;
      pdfWrapperRef.scrollLeft = newScrollLeft < 0 ? 0 : newScrollLeft;
      return;
    }
    if (scaleDifference) {
      pdfWrapperRef.scrollLeft *= scaleDifference;
    }
  };

  return (
    <PinchZoomPane
      className={concatClassNames(
        classes.PdfWrapper_PinchZoomPane,
        widthControlled ? classes.PdfWrapper_PinchZoomPaneWidthControlled : null,
      )}
      onPinchIn={onPinchIn}
      onPinchOut={onPinchOut}
      responseMode={RESPONSE_MODE.DIRECT}
    >
      <div
        className={concatClassNames(
          classes.PdfWrapper,
          widthControlled ? classes.PdfWrapperWidthControlled : classes.PdfWrapperHeightControlled,
        )}
        ref={(ref) => setPdfWrapperRef(ref)}
        style={!widthControlled ? { width: calculateDocumentWidth(currentAvailableHeightForDoc) } : null}
      >
        {children({
          pageHeight,
          pdfWrapperRef,
          updateScrollLeft,
          availableHeight: currentAvailableHeightForDoc - scrollBarWidth,
          availableWidth: currentAvailableWidthForDoc - scrollBarWidth,
        })}
      </div>
    </PinchZoomPane>
  );
};

export default PdfViewerViewPane;
