export const MONTHLY_PAYMENT_MODE = {
  REQUIRED: 'required',
  OPTIONAL: 'optional',
  DISABLED: 'disabled',
};

export const MONTHLY_PAYMENT_DECISION = {
  YES: 'yes',
  NO: 'no',
  DECIDE_LATER: 'decideLater',
};
