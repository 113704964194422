import { RedoOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';
import PageContainer from 'components/layout/PageContainer';
import React from 'react';
import { gql } from '@apollo/client';
import apollo from 'graphql/apollo';
import { formatDateTime } from 'utils/date';
import toast from 'utils/toast';
import { useCachedQuery } from 'graphql/utils';
import { TableMemo } from 'memo';

const migrationStatusFragment = gql`
  fragment migrationStatusFragment on MigrationStatus {
    _id
    migrations {
      _id
      name
      createdAt
      updatedAt
      existsInCode
    }
  }
`;
const reRunMigration = ({ name }) =>
  apollo.mutate({
    mutation: gql`
      mutation reRunMigration($name: String) {
        reRunMigration(name: $name) {
          ...migrationStatusFragment
        }
      }
      ${migrationStatusFragment}
    `,
    variables: { name },
  });

const userTableColumns = () => [
  {
    key: 'name',
    title: 'Migration name',
    render: (e) => <div>{e.name}</div>,
  },
  {
    key: 'createdAt',
    title: 'Migrated first time at',
    render: (e) => <div>{e.createdAt ? formatDateTime(e.createdAt) : '-'}</div>,
  },
  {
    key: 'updatedAt',
    title: 'Migrated last time at',
    render: (e) => <div>{e.updatedAt ? formatDateTime(e.updatedAt) : '-'}</div>,
  },
  {
    key: 'reRun',
    title: 'Re-run migration',
    render: (e) =>
      e.existsInCode ? (
        <div>
          <Button
            icon={<RedoOutlined />}
            onClick={() => {
              reRunMigration({ name: e.name })
                .then(() => toast.success('Success'))
                .catch(() => toast.error('Error'));
            }}
          />
        </div>
      ) : null,
  },
];

export const Migrations = () => {
  const { data, loading } = useCachedQuery(
    gql`
      query migrationStatus {
        migrationStatus {
          ...migrationStatusFragment
        }
      }
      ${migrationStatusFragment}
    `,
    { fetchPolicy: 'cache-and-network' },
  );

  return (
    <PageContainer title="Migrations">
      <Card title="Migrations table">
        {!data && loading ? null : (
          <TableMemo
            rowKey={({ _id }) => _id}
            bordered
            dataSource={data?.migrationStatus?.migrations || []}
            columns={userTableColumns()}
            pagination={false}
            scroll={{ x: 700 }}
          />
        )}
      </Card>
    </PageContainer>
  );
};

export default Migrations;
