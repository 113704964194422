import { gql } from '@apollo/client';
import superAdminUserFragment from './superAdminUserFragment';

const superAdminTenantFragment = gql`
  fragment superAdminTenantFragment on Tenant {
    _id
    name
    disabled
    rootAdmin {
      ...superAdminUserFragment
    }
    licenseStarted
    nrOfAllowedCompaniesByPricingPlan
    nrOfAllowedUsersByPricingPlan
    numberOf {
      _id
      catalogueConfigurationEdits
      companies
      companyTypeEdits
      createdShoppingCarts
      logins
      totalActions
      users
    }
    commonLibraryAccessGroups
    timeStampOfLastAction
  }
  ${superAdminUserFragment}
`;

export default superAdminTenantFragment;
