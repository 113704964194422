import { omit } from 'lodash';

const roles = {
  ADMIN: 'ADMIN',
  USER: 'USER',
  SUPER_ADMIN: 'SUPER_ADMIN',
  ROOT_ADMIN: 'ROOT_ADMIN',
  VIEWER: 'VIEWER',
};

export const rolesCanBeSet = omit(roles, roles.SUPER_ADMIN);

export default roles;
