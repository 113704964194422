import { Space } from 'antd';
import { Radio } from 'components/common/Radio';
import { Radio as RadioFormik } from 'formik-antd';
import FormItem from 'components/common/FormItem';

export const MonthlyPaymentMode = ({ t }) => {
  return (
    <FormItem
      label={t('admin.Setting.MonthlyPaymentPreferences.generalSettings.monthlyPaymentMode.label')}
      name="monthlyPaymentMode"
    >
      <RadioFormik.Group name="monthlyPaymentMode">
        <Space direction="vertical">
          <Radio
            value="optional"
            name="monthlyPaymentMode"
            key="optional"
            tooltip={t(
              'admin.Setting.MonthlyPaymentPreferences.generalSettings.monthlyPaymentMode.optionalOptionTooltip',
            )}
          >
            {t('admin.Setting.MonthlyPaymentPreferences.generalSettings.monthlyPaymentMode.optionalOption')}
          </Radio>
          <Radio
            value="required"
            name="monthlyPaymentMode"
            key="required"
            tooltip={t(
              'admin.Setting.MonthlyPaymentPreferences.generalSettings.monthlyPaymentMode.requiredOptionTooltip',
            )}
          >
            {t('admin.Setting.MonthlyPaymentPreferences.generalSettings.monthlyPaymentMode.requiredOption')}
          </Radio>
          <Radio
            value="disabled"
            name="monthlyPaymentMode"
            key="disabled"
            tooltip={t(
              'admin.Setting.MonthlyPaymentPreferences.generalSettings.monthlyPaymentMode.disabledOptionTooltip',
            )}
          >
            {t('admin.Setting.MonthlyPaymentPreferences.generalSettings.monthlyPaymentMode.disabledOption')}
          </Radio>
        </Space>
      </RadioFormik.Group>
    </FormItem>
  );
};
