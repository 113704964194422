import { Button } from 'antd';
import confirmModal from 'utils/confirmModal';
import { MailOutlined, SearchOutlined, SendOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import sendTestEmail from 'graphql/methods/admin/tenant/sendTestEmail';
import useCurrentUser from 'hooks/auth/useCurrentUser';

const SMTPHelperButton = ({ mailServiceProvider, setBestSMTPSettings }) => {
  const { t } = useTranslation();
  const [me] = useCurrentUser();

  const onFindBestSMTPSettings = async () => {
    if (mailServiceProvider !== 'smtp') {
      return confirmModal({
        disableCloseButton: true,
        okText: t('common.ok'),
        okType: 'danger',
        onOk: () => {},
        title: t('admin.Setting.Email.smtpSetting.validationError'),
      });
    }

    return setBestSMTPSettings();
  };

  const onTestEmail = async () => {
    confirmModal({
      cancelText: t('admin.Setting.Email.saveAndTestEmailModal.cancelText'),
      icon: <MailOutlined />,
      okText: t('admin.Setting.Email.saveAndTestEmailModal.okText'),
      onOk: async () => {
        await sendTestEmail();
      },
      title: t('admin.Setting.Email.saveAndTestEmailModal.title', { email: me?.email }),
    });
  };

  return (
    <>
      <Button
        icon={<SearchOutlined />}
        onClick={() => {
          onFindBestSMTPSettings();
        }}
        hidden={mailServiceProvider !== 'smtp'}
      >
        {t('admin.Setting.Email.smtpSetting.button')}
      </Button>{' '}
      <Button
        icon={<SendOutlined />}
        onClick={() => {
          onTestEmail();
        }}
        hidden={mailServiceProvider !== 'smtp'}
      >
        {t('admin.Setting.Email.saveAndTestEmail')}
      </Button>
    </>
  );
};

export default SMTPHelperButton;
