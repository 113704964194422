import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDownloadZipShoppingCartPdf } from 'pages/shoppingCartManagement/ShoppingCartSuccessful/hooks';
import classes from '../DocumentsCard.module.less';
import { formatStateDownload } from './formatStateDownload';

/**
 * Renders a button that downloads all documents as a zip file
 * @param {Object} inputParameters - Input parameters of the component
 * @param {Array} inputParameters.documents - The list of documents to download
 * @param {Array} inputParameters.generatedFiles - The list of generated PDF files
 * @param {Function} inputParameters.addGeneratedPdf - The function to add a generated PDF file
 * @param {Boolean} inputParameters.isAllDocumentsGenerated - Indicates if all documents have been generated
 * @param {String} inputParameters.companyName - The name of the company
 * @returns {JSX.Element}
 */
export const DownloadAll = ({ documents, generatedFiles, addGeneratedPdf, isAllDocumentsGenerated, companyName }) => {
  const { t } = useTranslation();
  const [downloadAllFilesAsZip, { loading, stateLoading, progress }] = useDownloadZipShoppingCartPdf({
    documents,
    generatedFiles,
    addGeneratedPdf,
    companyName,
  });
  if (!documents?.length) return null;
  return (
    <Button
      loading={loading || !isAllDocumentsGenerated}
      disabled={loading || !isAllDocumentsGenerated}
      onClick={downloadAllFilesAsZip}
      className={classes.downloadButton}
    >
      {formatStateDownload({ stateLoading, progress, inactive: t('user.PrintShoppingCartPage.downloadAll') })}
    </Button>
  );
};
