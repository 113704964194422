import { Alert } from 'antd';
import { useQuery } from '@apollo/client';
import { statusQuery } from 'graphql/queries';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import { isGlobalPage } from 'utils/isGlobalPage';
import { useTranslation } from 'react-i18next';

const POLLING_INTERVAL = 5 * 60 * 1000;

/**
 * Alert banner for service outages and or degradations
 * @param {object} properties - Props of the component
 * @param {string} properties.level - Level of the alert
 * @param {string} properties.service - Name of the service
 * @returns {JSX.Element} Alert banner for service outages and or degradations
 * @component
 */
const ServiceAlert = ({ level, service }) => {
  const { t } = useTranslation();
  const message = t(`ExternalServicesWarnings.${level}.${service}`);
  const type = level === 'DEGRADED' ? 'warning' : 'error';
  return <Alert message={message} type={type} banner closable />;
};

/**
 * Shows alerts for service outages and or degradations
 * @returns {JSX.Element} Handles status of required services for the app
 */
const ServiceIsRestrictedWarningHandler = () => {
  const [user] = useCurrentUser(isGlobalPage());
  const { data } = useQuery(statusQuery, { skip: !user, pollInterval: POLLING_INTERVAL });

  if (!data || !data.status) {
    return null;
  }

  const { status } = data;

  const outagesOrDegradations = Object.entries(status)
    .filter(([, value]) => value)
    .filter(([field]) => field !== '__typename');

  if (!outagesOrDegradations.length) {
    return null;
  }

  return (
    <div>
      {outagesOrDegradations.map(([service, level]) => (
        <ServiceAlert service={service} level={level} key={service} />
      ))}
    </div>
  );
};

export default ServiceIsRestrictedWarningHandler;
