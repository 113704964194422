import { gql } from '@apollo/client';

const signingInternalLoginMutation = gql`
  mutation signingInternalLoginMutation($signingProcessId: String!) {
    loginAsInternalSignee(signingProcessId: $signingProcessId) {
      authToken
      signingProcessId
      theme
      firstName
      lastName
      nameOfCompany
      typeOfSignee
      positionOfSignee
      isPrivatePerson
      steps
    }
  }
`;

export default signingInternalLoginMutation;
