import equal from 'fast-deep-equal/es6/react';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card } from 'antd';
import { sortBy } from 'lodash';
import { TableMemo } from 'memo';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import inputConfirmModal from 'utils/inputConfirmModal';
import i18n from 'i18n';
import { deleteVariableWithConfirmation, updateVariable } from 'graphql/methods';
import { adminVariableListQuery } from 'graphql/queries';
import { useCachedQuery } from 'graphql/utils';
import { grabFirstGQLDataResult } from 'utils/helpers';
import toast from 'utils/toast';
import variableTypes from 'constants/variableTypes';
import GraphQLLoadingWrapper from 'components/common/GraphQLLoadingWrapper';
import { numberToLocaleString, Variable } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { getVariableFields, variableValidationSchema, errorResolver } from './utils';

const editVariable = ({ variable }) =>
  inputConfirmModal({
    fields: getVariableFields({ isInternal: variable.isInternal, isEditMode: true }),
    onSubmit: (modifier) => {
      let { value } = modifier;
      if (modifier.type === 'percent') {
        value = Variable.formatPercentValue(modifier.value, true);
      }
      updateVariable({
        _id: variable._id,
        modifier: {
          ...modifier,
          value: value.toString(),
        },
      })
        .then(toast.successDefault)
        .catch((error) => {
          toast.error(error.message);
        });
    },
    value: {
      label: variable.label ?? '',
      value: variable.value ?? '',
      type: variable.type ?? '',
      description: variable.description ?? '',
    },
    errorResolver,
    headerText: i18n.t('admin.ConstantsAndPlaceholders.editVariableModalTitle'),
    okText: i18n.t('common.ok'),
    cancelText: i18n.t('common.cancel'),
    validationSchema: () => variableValidationSchema({ isInternal: variable.isInternal }),
    forceMultiField: true,
    width: '600px',
  });

function renderValue(value, t) {
  if (typeof value !== 'string') {
    numberToLocaleString(value, t('localeCode'), { isRestrictMaximumDigits: false });
  }

  return value;
}

const columns = ({ t }) => [
  {
    title: t('admin.ConstantsAndPlaceholders.columns.name'),
    dataIndex: 'label',
    key: 'label',
  },
  {
    title: t('admin.ConstantsAndPlaceholders.columns.value'),
    dataIndex: 'value',
    key: 'value',
    render: (value) => renderValue(value, t),
  },
  {
    title: t('admin.ConstantsAndPlaceholders.columns.type'),
    dataIndex: 'type',
    key: 'type',
    render: (type) => i18n.t(`common.Variables.types.${type}`),
  },
  {
    title: t('admin.ConstantsAndPlaceholders.columns.description'),
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: t('admin.ConstantsAndPlaceholders.columns.mandatory'),
    dataIndex: 'isInternal',
    render: (isInternal) => (isInternal ? t('common.yes') : t('common.no')),
    key: 'isInternal',
  },
  {
    key: 'actions',
    render: (variable) => {
      return (
        <>
          <Button
            ghost
            type="primary"
            className="ant-btn-default"
            icon={<EditOutlined />}
            onClick={() => editVariable({ variable })}
          />{' '}
          {variable.isInternal || (
            <Button
              type="danger"
              className="ant-btn-default"
              ghost
              icon={<DeleteOutlined />}
              onClick={() => deleteVariableWithConfirmation({ _id: variable._id })}
            />
          )}
        </>
      );
    },
  },
];

function VariableList() {
  const { data, ...rest } = useCachedQuery(adminVariableListQuery);
  const variables = useMemo(() => {
    return sortBy(
      grabFirstGQLDataResult(data)?.variables?.map((v) => ({
        ...v,
        value: v.type === variableTypes.percent ? Variable.formatPercentValue(v.value) : v.value,
      })),
      (e) => e?.label?.toLowerCase?.(),
    );
  }, [data]);
  const { t } = useTranslation();

  return (
    <GraphQLLoadingWrapper data={data} {...rest}>
      <Card title={t('admin.ConstantsAndPlaceholders.variableListTitle')}>
        <TableMemo pagination={false} dataSource={variables} columns={columns({ t })} scroll={{ x: 600 }} />
      </Card>
    </GraphQLLoadingWrapper>
  );
}

export default memo(VariableList, equal);
