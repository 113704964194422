import { useMemo } from 'react';
import GraphQLLoadingWrapper from 'components/common/GraphQLLoadingWrapper';
import { useDocuments } from 'pages/shoppingCartManagement/ShoppingCartSuccessful/hooks';
import { DocumentTemplateTable } from './DocumentTemplateTable';

/**
 * DocumentTable component shows a table with all documents of a shopping cart with print and download functions
 * @param {Object} inputParameters - Input parameters of the component
 * @param {Boolean} inputParameters.isShowCompare
 * @param {String} inputParameters.shoppingCartId - Id of shopping cart from which the documents should be showed
 * @param {Boolean} inputParameters.displayIfEmptyCard
 * @param {String} inputParameters.fetchPolicy - Fetch policy for fetching the documents of the specified shopping cart
 * @param {React.Component} inputParameters.customBottomCell
 * @param {Function} inputParameters.filterDocuments - Gets called to filter for documents to show (gets fetched documents as input and should return a collection of documents)
 * @param {boolean} inputParameters.showBottomCell - Should the bottom cell be shown
 * @returns {JSX.Element} table component with all documents of a shopping cart
 * @component
 */
export const DocumentTable = ({
  shoppingCartId,
  displayIfEmptyCard,
  fetchPolicy = 'cache-first',
  customBottomCell = null,
  filterDocuments = null,
  showBottomCell = true,
}) => {
  const {
    documents: fetchedDocuments,
    isAllDocumentsGenerated,
    companyName,
    loading,
    error: documentsError,
  } = useDocuments({
    fetchPolicy,
    shoppingCartId,
  });

  const documents = filterDocuments ? filterDocuments(fetchedDocuments) : fetchedDocuments;

  const error = useMemo(
    () => (documentsError && !documentsError?.networkError ? documentsError : undefined),
    [documentsError],
  );
  if (!customBottomCell && documents && !documents.length) return null;
  return (
    <GraphQLLoadingWrapper
      data={documents}
      loading={loading}
      error={error}
      isAlwaysDisplay={displayIfEmptyCard || documents?.length}
    >
      <DocumentTemplateTable
        customBottomCell={customBottomCell}
        shoppingCartId={shoppingCartId}
        documents={documents}
        isAllDocumentsGenerated={isAllDocumentsGenerated}
        displayIfEmptyCard={displayIfEmptyCard}
        companyName={companyName}
        showBottomCell={showBottomCell}
      />
    </GraphQLLoadingWrapper>
  );
};
