import apollo from 'graphql/apollo';
import { importEmailTemplatesFromLibraryMutation } from 'graphql/mutations';
import { adminEmailTemplatesQuery } from 'graphql/queries';
import { sortBy } from 'lodash';
import { grabFirstGQLDataResult } from 'utils/helpers';

const importEmailTemplatesFromLibrary = async ({ emailTemplates }) =>
  apollo.mutate({
    mutation: importEmailTemplatesFromLibraryMutation,
    variables: { input: emailTemplates },
    update: (cache, { data }) => {
      const newEmailTemplates = grabFirstGQLDataResult(data);
      const cachedData = cache.readQuery({
        query: adminEmailTemplatesQuery,
        variables: { isLibrary: false },
      });
      cache.writeQuery({
        query: adminEmailTemplatesQuery,
        variables: { isLibrary: false },
        data: {
          emailTemplates: sortBy([...cachedData.emailTemplates, ...newEmailTemplates], ['name']),
        },
      });
    },
  });

export default importEmailTemplatesFromLibrary;
