import { useState } from 'react';
import { confirmAlert } from '@uplab/react-confirm-alert';
import HtmlModalComponent from './htmlModal/HtmlModalComponent';
import PdfModalComponent from './pdfModal/PdfModalComponent';

const DocumentModalContainer = ({
  type,
  onSubmit,
  onClose,
  value,
  headerText,
  formatError,
  categoryId,
  okText,
  cancelText,
}) => {
  const [isSubmitting, setIsSubmitting] = useState();
  const [isClosing, setIsClosing] = useState(false);
  const [error, setError] = useState();
  const handleClose = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setIsClosing(true);
  };
  const onSubmit_ = async (values) => {
    setIsSubmitting(true);
    if (isSubmitting) return;
    try {
      await onSubmit(values);
      setIsClosing(true);
      setError();
    } catch (e) {
      setError(e);
    }
    setIsSubmitting(false);
  };
  const props = {
    categoryId,
    closeModal: onClose,
    error,
    formatError,
    handleClose,
    headerText,
    isClosing,
    isSubmitting,
    onSubmit: onSubmit_,
    value,
    okText,
    cancelText,
  };
  if (type === 'html') return <HtmlModalComponent {...props} />;
  if (type === 'pdf') return <PdfModalComponent {...props} />;
  return null;
};

const DocumentModal = ({ value, onSubmit, type = 'html', headerText, formatError, categoryId, okText, cancelText }) => {
  confirmAlert({
    customUI: ({ onClose }) => (
      <DocumentModalContainer
        type={type}
        onClose={onClose}
        value={value}
        formatError={formatError}
        headerText={headerText}
        onSubmit={onSubmit}
        categoryId={categoryId}
        okText={okText}
        cancelText={cancelText}
      />
    ),
  });
};
export default DocumentModal;
