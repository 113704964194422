import { gql } from '@apollo/client';

const userCompanyImportFieldsFragment = gql`
  fragment userCompanyImportFieldsFragment on CompanyImportFields {
    _id
    commercialObject
    importAcademicTitle
    importCompanyType
    importEmail
    importSalutation
  }
`;

export default userCompanyImportFieldsFragment;
