import { Button, Slider } from 'antd';
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { SCALE_MAX, SCALE_MIN, SCALE_STEP_SIZE } from '../../utils/ZoomUtils';
import classes from './ScaleControl.module.less';

/**
 * ScaleControl component for updating the scale value for the pdf viewer
 * @param {Object} inputParameters - Input parameters for the component
 * @param {Function} inputParameters.onUpdateScale - Function, which is called, when the scale will be updated with the new scale as parameter
 * @param {Number} inputParameters.currentScale - Current scale value
 * @returns {JSX.Element} ScaleControl component to control the scale input
 * @component
 */
const ScaleControl = ({ onUpdateScale, currentScale }) => {
  const onZoomIn = () => {
    if (currentScale + SCALE_STEP_SIZE > SCALE_MAX) return;
    onUpdateScale(currentScale + SCALE_STEP_SIZE);
  };
  const onZoomOut = () => {
    if (currentScale - SCALE_STEP_SIZE < SCALE_MIN) return;
    onUpdateScale(currentScale - SCALE_STEP_SIZE);
  };
  const onZoomSliderChanged = (newValue) => {
    onUpdateScale(newValue);
  };
  return (
    <>
      <Button icon={<ZoomInOutlined />} type="text" onClick={onZoomIn} />
      <Button icon={<ZoomOutOutlined />} type="text" onClick={onZoomOut} />
      <Slider
        min={SCALE_MIN}
        max={SCALE_MAX}
        step={SCALE_STEP_SIZE}
        onChange={onZoomSliderChanged}
        value={currentScale}
        className={classes.ZoomSlider}
      />
    </>
  );
};

export default ScaleControl;
