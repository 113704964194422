import { Row, Col } from 'antd';
import equal from 'fast-deep-equal/es6/react';
import Card from 'components/common/Card';
import { Form } from 'formik-antd';
import CompanyType from 'pages/shoppingCartManagement/ShoppingCart/CompanyType';
import { FeeTypeFormikInput } from 'pages/shoppingCartManagement/ShoppingCart/ShoppingCartOptions';
import { PDFTypeFormikSelect } from 'pages/shoppingCartManagement/ShoppingCart/DocumentTypesSelect/ShoppingCartPdfTypesSelect';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FolderViewOutlined } from '@ant-design/icons';
import { FaFile } from 'react-icons/fa';
import Switch from 'components/common/Switch';
import { DocumentTemplatesForShoppingCartContextProvider } from 'contexts/DocumentTemplatesForShoppingCartContext';
import SetDefaultEmailTemplate from './SetDefaultEmailTemplate';
import classes from './ShoppingCartPreferences.module.less';
import PricingSetting from './PricingSetting';
import CustomPrice from './CustomPrice';

const OptionCart = () => {
  const { t } = useTranslation();
  return (
    <Card icon={<FolderViewOutlined />} title={t('admin.Setting.ShoppingCartPreferences.card.viewOptions')}>
      <Form layout="vertical">
        <FeeTypeFormikInput tooltip={t('admin.Setting.ShoppingCartPreferences.fields.feeType.tooltip')} />
        <Switch
          name="showPrices"
          label={t('admin.Setting.ShoppingCartPreferences.fields.showPrice.label')}
          tooltip={t('admin.Setting.ShoppingCartPreferences.fields.showPrice.tooltip')}
          checkedChildren={t('user.ShoppingCart.options.switch.on')}
          unCheckedChildren={t('user.ShoppingCart.options.switch.off')}
        />
        <Switch
          name="showDiscounts"
          label={t('admin.Setting.ShoppingCartPreferences.fields.showDiscount.label')}
          tooltip={t('admin.Setting.ShoppingCartPreferences.fields.showDiscount.tooltip')}
          checkedChildren={t('user.ShoppingCart.options.switch.on')}
          unCheckedChildren={t('user.ShoppingCart.options.switch.off')}
        />
        <Switch
          name="showCategoryTotal"
          label={t('admin.Setting.ShoppingCartPreferences.fields.showCategoryTotal.label')}
          tooltip={t('admin.Setting.ShoppingCartPreferences.fields.showCategoryTotal.tooltip')}
          checkedChildren={t('user.ShoppingCart.options.switch.on')}
          unCheckedChildren={t('user.ShoppingCart.options.switch.off')}
        />
      </Form>
    </Card>
  );
};

/**
 * Default shopping cart preferences form component
 * @returns {JSX.Element} default settings for new shopping carts
 * @component
 */
function ShoppingCartPreferencesForm() {
  const { t } = useTranslation();
  return (
    <div className={classes.preferences}>
      <OptionCart />
      <Card icon={<FaFile />} title={t('user.ShoppingCart.DocumentTypes.label')}>
        <DocumentTemplatesForShoppingCartContextProvider isAllowHistoricalDocumentTemplates={false}>
          <PDFTypeFormikSelect />
        </DocumentTemplatesForShoppingCartContextProvider>
      </Card>
      <CompanyType />
      <Row className={classes.rowOfCards} gutter={16}>
        <Col span={12}>
          <PricingSetting />
        </Col>
        <Col span={12}>
          <CustomPrice />
        </Col>
      </Row>
      <SetDefaultEmailTemplate />
    </div>
  );
}

export default memo(ShoppingCartPreferencesForm, equal);
