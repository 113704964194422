import { Button } from 'antd';
import { useDownloadShoppingCartPdf } from 'pages/shoppingCartManagement/ShoppingCartSuccessful/hooks';
import classes from '../DocumentsCard.module.less';
import { formatStateDownload } from './formatStateDownload';

/**
 * Renders a button that downloads a single document when clicked
 * @param {Object} inputParameters - Input parameters of the component
 * @param {Object} inputParameters.document - The document to download
 * @param {Array} inputParameters.generatedFiles - The generated files for the document
 * @param {Function} inputParameters.addGeneratedPdf - A function to add the generated PDF to the state
 * @returns {JSX.Element}
 */
export const DownloadOne = ({ document, generatedFiles, addGeneratedPdf }) => {
  const [downloadFile, { loading, stateLoading, progress }] = useDownloadShoppingCartPdf({
    document,
    generatedFiles,
    addGeneratedPdf,
    options: { isSaveAs: true },
  });

  return (
    <Button
      loading={loading || document.isGenerating}
      disabled={loading || document.isGenerating}
      onClick={downloadFile}
      className={classes.downloadButton}
    >
      {formatStateDownload({ stateLoading, progress })}
    </Button>
  );
};
