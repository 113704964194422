class MemoryStorage_ {
  _storage = {};

  get(key) {
    return this._storage[key];
  }

  set(key, value) {
    this._storage[key] = value;
  }
}

const MemoryStorage = new MemoryStorage_();
export default MemoryStorage;
