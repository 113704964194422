import { Collapse as CollapseAntd } from 'antd';
import classNames from 'classnames';
import c from './Collapse.module.less';

const Collapse = ({ children, expandIcon, className }) => {
  return (
    <CollapseAntd defaultActiveKey={null} ghost className={classNames(c.collapse, className)} expandIcon={expandIcon}>
      {children}
    </CollapseAntd>
  );
};

export default Collapse;
