import PageContainer from 'components/layout/PageContainer';
import equal from 'fast-deep-equal/es6/react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import GeneralSettingsCard from 'components/admin/MonthlyPaymentPreferences/GeneralSettingsCard';
import ShoppingCartSettingsCard from 'components/admin/MonthlyPaymentPreferences/ShoppingCartSettingsCard';
import SignatureSettingsCard from 'components/admin/MonthlyPaymentPreferences/SignatureSettingsCard';
import MonthlyPaymentPreferencesHelperWidget from 'components/admin/MonthlyPaymentPreferences/MonthlyPaymentPreferencesHelperWidget';
import DocumentsSettingsCard from 'components/admin/MonthlyPaymentPreferences/DocumentsSettingsCard';
import useFasterPreferences from 'hooks/user/useFasterPreferences';
import MonthlyPaymentPreferencesForm from '../../../components/admin/MonthlyPaymentPreferences/MonthlyPaymentPreferencesForm';

function MonthlyPaymentPreferences() {
  const { t } = useTranslation();
  const { preferences } = useFasterPreferences();
  const isDigitalSignatureEnabled = preferences?.digitalSignaturePreferences?.digitalSignatureEnabled;

  return (
    <PageContainer
      title={t('admin.Setting.MonthlyPaymentPreferences.title')}
      left={<MonthlyPaymentPreferencesHelperWidget />}
    >
      <MonthlyPaymentPreferencesForm>
        <GeneralSettingsCard />
        <ShoppingCartSettingsCard />
        {isDigitalSignatureEnabled ? <SignatureSettingsCard /> : null}
        <DocumentsSettingsCard />
      </MonthlyPaymentPreferencesForm>
    </PageContainer>
  );
}

export default memo(MonthlyPaymentPreferences, equal);
