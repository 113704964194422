import Card from 'components/common/Card';
import equal from 'fast-deep-equal/es6/react';
import { TableMemo } from 'memo';
import { contactsToString } from 'pages/shoppingCartManagement/ShoppingCartEntries/ShoppingCartEntries';
import { companyToString } from 'pages/shoppingCartManagement/ShoppingCart/ContactData/components/Inputs';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const overviewColumn = ({ t, monthlyPaymentTitle }) => [
  {
    title: t('viewer.ShoppingCartView.columns.data'),
    key: 'data',
    render: (data) => t(`viewer.ShoppingCartView.Overview.fields.${data}`, { monthlyPaymentTitle }),
    dataIndex: 'data',
  },
  {
    title: t('viewer.ShoppingCartView.columns.value'),
    key: 'value',
    dataIndex: 'value',
  },
];

const ShoppingCartOverview = ({ overviewData, monthlyPaymentTitle }) => {
  const { t } = useTranslation();

  const dataSource = useMemo(
    () =>
      Object.entries(overviewData).map(([key, _value]) => {
        let value;
        switch (key) {
          case 'company':
            value = companyToString(_value);
            break;
          case 'contacts':
            value = contactsToString(_value);
            break;
          default:
            value = _value;
        }
        return { data: key, value };
      }),
    [overviewData],
  );

  return (
    <Card title={t('viewer.ShoppingCartView.Overview.title')}>
      <TableMemo
        rowKey={(record) => record?.data}
        dataSource={dataSource}
        columns={overviewColumn({ t, monthlyPaymentTitle })}
        pagination={false}
        scroll={{ x: 390 }}
      />
    </Card>
  );
};
export default memo(ShoppingCartOverview, equal);
