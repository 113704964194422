import apollo from 'graphql/apollo';
import { deleteDocumentTemplateMutation } from 'graphql/mutations';
import i18n from 'i18n';
import confirmModal from 'utils/confirmModal';
import toast from 'utils/toast';
import {
  adminIsDocumentTemplateUsed,
  adminDocumentTemplateListForPageQuery,
  adminHasTenantImportedDefaultTemplatesQuery,
} from 'graphql/queries';
import { toastGraphQLError } from 'utils/helpers';

/**
 * Deletes document template and refetches relevant queries and shows result alert
 * @param {Object} inputParameters - Input parameters of function0
 * @param {String} inputParameters._id - Id of document template, which should be deleted
 * @param {Boolean} inputParameters.isLibrary - Is the document template are a common library template
 * @returns {*}
 */
export const deleteDocumentTemplate = async ({ _id, isLibrary }) =>
  apollo
    .mutate({
      mutation: deleteDocumentTemplateMutation,
      variables: { _id },
      refetchQueries: [
        { query: adminDocumentTemplateListForPageQuery, variables: { isLibrary } },
        { query: adminHasTenantImportedDefaultTemplatesQuery },
      ],
    })
    .then(toast.successDefault)
    .catch(toastGraphQLError);
/**
 * Checks, if document template is in use and shows info modal in this case, deletes the document template on confirm or if not in use
 * @param {Object} inputParameters - Input parameters of function
 * @param {String} inputParameters._id - Id of document template, which should be deleted
 * @param {Boolean} inputParameters.isLibrary - Is the document template are a common library template
 */
export const deleteDocumentTemplateWithUseCheck = async ({ _id, isLibrary }) => {
  const { data } = await apollo.query({
    query: adminIsDocumentTemplateUsed,
    variables: { _id, isLibrary },
    fetchPolicy: 'network-only',
  });
  if (!data.isDocumentTemplateUsed) {
    await deleteDocumentTemplate({ _id, isLibrary });
  } else {
    confirmModal({
      cancelText: i18n.t('deleteDocumentTemplateConfirmation.cancel'),
      okText: i18n.t('deleteDocumentTemplateConfirmation.ok'),
      okType: 'danger',
      onOk: () => deleteDocumentTemplate({ _id, isLibrary }),
      title: i18n.t('deleteDocumentTemplateConfirmation.documentIsUsed'),
    });
  }
};
export default deleteDocumentTemplateWithUseCheck;

/**
 * Shows a delete confirm modal and deletes document template on confirm
 * @param {Object} inputParameters - Input parameters of the function
 * @param {String} inputParameters._id - Id of document template, which should be deleted
 * @param {Boolean} inputParameters.isLibrary - Is the document template are a common library template
 * @returns {Object} object with manipulation functions for modal
 */
export const deleteDocumentTemplateWithConfirmation = ({ _id, isLibrary }) =>
  confirmModal({
    cancelText: i18n.t('deleteDocumentTemplateConfirmation.cancel'),
    okText: i18n.t('deleteDocumentTemplateConfirmation.ok'),
    okType: 'danger',
    onOk: () => deleteDocumentTemplateWithUseCheck({ _id, isLibrary }),
    title: i18n.t('deleteDocumentTemplateConfirmation.title'),
  });
