import { MoreInfoWidgetContent } from 'components/common/MoreInfoWidget';
import equal from 'fast-deep-equal/es6/react';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import { memo, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { isAdmin } from 'utils/role';
import Card from 'components/common/Card';
import { FaRocket } from 'react-icons/fa';
import cn from 'classnames';
import Alert from 'components/common/Alert';
import classes from './Dashboard.module.less';

const OnboardingChecklist = () => {
  const { t } = useTranslation();
  const [me] = useCurrentUser();
  if (!isAdmin(me?.role)) return null;
  return (
    <Card
      icon={<FaRocket />}
      title={t('DashboardPage.onboardingChecklist.title')}
      className={cn('ant-card-head-borderless')}
    >
      <Alert
        showIcon
        message={`${t('DashboardPage.onboardingChecklist.infoAlert.title')}: ${t(
          'DashboardPage.onboardingChecklist.infoAlert.description',
        )}`}
      />
      <div className={classes.steps}>
        {[...new Array(5)].map((_, i) => {
          const path = `DashboardPage.onboardingChecklist.step${i + 1}`;
          return (
            <Fragment key={path}>
              <MoreInfoWidgetContent
                videoUrl={t(`${path}.modalInfo.videoUrl`)}
                hideBook
                playerProps={{
                  playing: false,
                  config: {
                    vimeo: {
                      playerOptions: {
                        responsive: true,
                      },
                    },
                  },
                }}
                className={classes.stepCard}
              >
                <br />
                <div>
                  <b>
                    {t('DashboardPage.onboardingChecklist.step')} {i + 1}
                  </b>
                </div>
                <p>{t(`${path}.description`)}</p>
              </MoreInfoWidgetContent>
            </Fragment>
          );
        })}
      </div>
    </Card>
  );
};

export default memo(OnboardingChecklist, equal);
