import { gql } from '@apollo/client';

const adminEmailServiceFragment = gql`
  fragment adminEmailServiceFragment on EmailService {
    _id
    mailServiceProvider
    smtpUserName
    smtpPassword
    from
    smtpServer
    smtpPort
    useTLS
    useSSLv3
    requireTLS
    dkimSettings {
      useCustomDomain
      isCustomDomainVerified
      isSpfRecordVerified
      isDkimRecordVerified
      lastVerificationCheck
      domainName
      dkimRecord
      dkimRecordHostname
      spfRecordHostname
      spfRecord
    }
  }
`;

export default adminEmailServiceFragment;
