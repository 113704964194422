import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-cycle
import ConfigurationFieldRenderer from './ConfigurationFieldRenderer';

const ConfigurationSubtitleWithFields = ({ subtitle, fields, setPreviewBlockDescriptor }) => {
  const { t } = useTranslation();
  return (
    <>
      {subtitle ? (
        <div className="margin-bottom-16">
          <h4>{t(`admin.DocumentTemplateConfigurationPage.blockSubtitles.${subtitle}`)}</h4>
        </div>
      ) : null}
      {fields.map((fieldDescriptor) => (
        <ConfigurationFieldRenderer
          key={fieldDescriptor.name}
          fieldDescriptor={fieldDescriptor}
          setPreviewBlockDescriptor={setPreviewBlockDescriptor}
        />
      ))}
    </>
  );
};

export default ConfigurationSubtitleWithFields;
