import React from 'react';
import { useTranslation } from 'react-i18next';
import { ImportCategoryPresets } from 'components/admin/ImportCategoryPresetsModal';
import OnboardingStepTemplate from '../components/OnboardingStepTemplate';
import { useOnboardingContext } from '../OnboardingContext';

export default function PickCategories() {
  const { t } = useTranslation();
  const importCategoriesFromLibraryRef = React.useRef();
  const { onSubmit, formValuesRef } = useOnboardingContext();
  return (
    <OnboardingStepTemplate
      title={t('onboarding.pickCategories.title')}
      description={t('onboarding.pickCategories.textAboveVideo')}
      videoUrl={t('onboarding.pickCategories.videoUrl')}
      videoCaption={t('onboarding.pickCategories.videoCaption')}
      onNext={() => {
        const preSubmitSuccessful = importCategoriesFromLibraryRef.current.preSubmit();
        if (preSubmitSuccessful) {
          const selection = importCategoriesFromLibraryRef.current.getSelection();
          onSubmit({ importCategories: selection });
        }
      }}
      onPrev={() => {
        const selection = importCategoriesFromLibraryRef.current.getSelection();
        formValuesRef.current = { ...formValuesRef.current, importCategories: selection };
      }}
    >
      <ImportCategoryPresets
        ref={importCategoriesFromLibraryRef}
        initialSelection={formValuesRef.current.importCategories}
        isOnboarding
        checkAll
      />
    </OnboardingStepTemplate>
  );
}
