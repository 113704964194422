import Switch from 'components/common/Switch';

/**
 * A React function component that renders a Switch component for enabling or disabling SEPA.
 *
 * @component
 * @param {Object} props - The properties passed to this component.
 * @param {function} props.t - The translation function used for internationalization.
 * @returns {ReactElement} - A Switch component to enable or disable SEPA.
 * @description
 * `SepaEnable` is a React function component that provides a user interface for toggling SEPA (Single Euro Payments Area) functionality.
 * It utilizes the `Switch` component, passing in specific props to set the name, label, tooltip, and default children for the switch.
 * The `t` function is used to retrieve localized strings for the label and tooltip.
 *
 * @example
 * <SepaEnable t={yourTranslationFunction} />
 */
export const SepaEnable = ({ t }) => {
  return (
    <Switch
      name="sepaEnabled"
      label={t('admin.Setting.DigitalSignaturePreferences.sepa.sepaEnables.label')}
      tooltip={t('admin.Setting.DigitalSignaturePreferences.sepa.sepaEnables.tooltip')}
      defaultChildren
    />
  );
};
