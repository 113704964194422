import PageContainer from 'components/layout/PageContainer';
import React from 'react';
import Page from './Page';

export const Logs = () => {
  return (
    <PageContainer title="Logs" style={{ height: '100%' }}>
      <Page />
    </PageContainer>
  );
};

export default Logs;
