import { useRowContext } from 'contexts/RowContext';
import equal from 'fast-deep-equal/es6/react';
import { memo, useEffect } from 'react';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { GrDrag } from 'react-icons/gr';
import classes from './MoveButton.module.less';

const MoveButton = ({ isPreview = true, color, fill, ...buttonProps }) => {
  const { drag, preview } = useRowContext();
  useEffect(() => {
    if (!isPreview) {
      preview(getEmptyImage(), { captureDraggingState: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={(ref) => drag(ref)} className={classes.container}>
      <GrDrag className={classes.drag} {...buttonProps} />
    </div>
  );
};

export default memo(MoveButton, equal);
