import apollo from 'graphql/apollo';
import { updateInputFieldMutation } from 'graphql/mutations';
import { adminCategoryListQuery } from 'graphql/queries';
import { pick } from 'lodash';

const updateInputField = async ({
  _id,
  allowedRangeMaxValue,
  allowedRangeMinValue,
  defaultType,
  defaultValue,
  name,
  optionPDFNameTitle,
  optionPDFValueTitle,
  options,
  type,
  unitName,
}) =>
  apollo.mutate({
    mutation: updateInputFieldMutation,
    variables: {
      _id,
      modifier: {
        allowedRangeMaxValue,
        allowedRangeMinValue,
        defaultType,
        defaultValue,
        name,
        optionPDFNameTitle,
        optionPDFValueTitle,
        options: options?.map((option) => pick(option, ['_id', 'order', 'name', 'value'])),
        type,
        unitName,
      },
    },
    refetchQueries: [{ query: adminCategoryListQuery }],
  });

export default updateInputField;
