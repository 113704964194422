import { Input } from 'antd';
import FormItem from 'components/common/FormItem';
import { useState } from 'react';
import { CopyFilled } from '@ant-design/icons';
import copyToClipboard from 'utils/copyToClipboard';
import { useTranslation } from 'react-i18next';
import concatClassNames from 'utils/classNames';
import classes from './MultilineInput.module.less';

/**
 * MultilineInput component for multiline text input, also used for readonly copy to clipboard multinline text
 * @param name {String} - name of the input
 * @param label {String} - label of the input
 * @param tooltip {String} - tooltip of the input
 * @param isCopyAndReadOnly {Boolean} - is the input copy and readonly
 * @param value {String} - value of the input
 * @param labelAppendix {JSX.Element} - JSX.Element to append to the label
 * @param textAreaProps {Object} - props for the text area
 * @param formItemProps {Object} - props for the form item
 * @returns {JSX.Element} - MultilineInput component
 * @component
 */
export default function MultilineInput({
  name,
  label,
  tooltip,
  isCopyAndReadOnly = false,
  value,
  labelAppendix,
  textAreaProps,
  className,
  ...formItemProps
}) {
  const { t } = useTranslation();
  const [showCopy, setShowCopy] = useState(false);
  const [clickedCopy, setClickedCopy] = useState(false);

  const onCopy = () => {
    if (!value) return;
    setClickedCopy(true);
    setTimeout(() => setClickedCopy(false), 250);
    copyToClipboard(value, `${label} ${t('common.InputField.copiedToClipboard')}`);
  };

  if (!isCopyAndReadOnly) {
    return (
      <FormItem name={name} label={label} tooltip={tooltip || null} {...formItemProps}>
        <Input.TextArea name={name} value={value} autoSize {...textAreaProps} />
      </FormItem>
    );
  }

  return (
    <FormItem
      name={name}
      tooltip={tooltip || null}
      label={
        <span>
          {label} {labelAppendix}
        </span>
      }
      className={concatClassNames(classes.formItem, className)}
      {...formItemProps}
    >
      <div className={classes.textAreaWrapper}>
        <Input.TextArea
          className={classes.textAreaCopy}
          name={name}
          value={value}
          onClick={onCopy}
          onMouseEnter={() => {
            setShowCopy(true);
          }}
          onMouseLeave={() => {
            setShowCopy(false);
          }}
          autoSize
          readOnly
          {...textAreaProps}
        />
        <CopyFilled
          className={concatClassNames(
            classes.copyIcon,
            showCopy && classes.copyIconOnHover,
            clickedCopy && classes.copyIconClicked,
          )}
          onClick={onCopy}
        />
      </div>
    </FormItem>
  );
}
