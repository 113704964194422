import { Document as ReactPdfDocument, pdfjs } from 'react-pdf';
import concatClassNames from 'utils/classNames';
import OnLoadingComponent from './OnDocumentLoading';
import classes from './Document.module.less';

const DOCUMENT_OPTIONS = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
  verbosity: pdfjs.VerbosityLevel.ERRORS,
};

/**
 * Document component to load a pdf by Uint8Array
 * @param {Object} inputParameters - Input parameters of component
 * @param {JSX.Element[]} inputParameters.children - Children of document component to render pages of document
 * @param {Uint8Array} inputParameters.data - Binary data of pdf document as Uint8Array or null
 * @param {String} inputParameters.className - Classname for surrounding container
 * @param {Function} inputParameters.onDocumentLoadSuccess - Function called, when document was successfully loaded with object with pagesNum as parameter
 * @param {Object} inputParameters.documentInfo - Informations about the document
 * @param {String} inputParameters.documentInfo.shoppingCartId - Id of related shopping cart object
 * @param {String} inputParameters.documentInfo.documentTemplateId - Id of related document template object
 * @param {Function} inputParameters.onItemClick - Function, which is called, when item (link, toc item) was clicked in document
 * @returns {JSX.Element} loads Uint8Array as pdf document
 * @component
 */
const Document = ({ children, data, className, onDocumentLoadSuccess, documentInfo, onItemClick }) => {
  const { documentTemplateId, shoppingCartId } = documentInfo;

  const onLoadError = (error) => {
    console.error(
      `Error on loading pdf: (shopping cart id: ${shoppingCartId}; document template id: ${documentTemplateId})`,
      error,
    );
  };

  const onSourceError = (error) => {
    console.error(
      `Error on pdf source: (shopping cart id: ${shoppingCartId}; document template id: ${documentTemplateId})`,
      error,
    );
  };

  return (
    <ReactPdfDocument
      file={{ data }}
      className={concatClassNames(classes.PdfDocument, className)}
      onLoadSuccess={onDocumentLoadSuccess}
      onLoadError={onLoadError}
      onSourceError={onSourceError}
      options={DOCUMENT_OPTIONS}
      loading={<OnLoadingComponent className="Document_OnLoadingComponent" />}
      onItemClick={onItemClick}
    >
      {children}
    </ReactPdfDocument>
  );
};

export default Document;
