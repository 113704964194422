import { gql } from '@apollo/client';
import { adminYourLicenseFragment } from 'graphql/fragments';

const adminYourLicense = gql`
  query adminYourLicense {
    adminYourLicense {
      ...adminYourLicenseFragment
    }
  }
  ${adminYourLicenseFragment}
`;

export default adminYourLicense;
