import apollo from 'graphql/apollo';
import { deleteVariableMutation } from 'graphql/mutations';
import { richEditorSchemaQuery } from 'graphql/queries';
import i18n from 'i18n';
import confirmModal from 'utils/confirmModal';
import { toastGraphQLError } from 'utils/helpers';
import toast from 'utils/toast';

const deleteVariable = async ({ _id }) =>
  apollo.mutate({
    mutation: deleteVariableMutation,
    variables: { _id },
    refetchQueries: [{ query: richEditorSchemaQuery, variables: { isLibrary: false } }],
  });

export const deleteVariableWithConfirmation = ({ _id }) =>
  confirmModal({
    cancelText: i18n.t('admin.deleteVariableConfirmation.cancel'),
    maskClosable: true,
    okText: i18n.t('admin.deleteVariableConfirmation.ok'),
    okType: 'danger',
    onOk: () => deleteVariable({ _id }).then(toast.successDefault).catch(toastGraphQLError),
    title: i18n.t('admin.deleteVariableConfirmation.title'),
  });

export default deleteVariable;
