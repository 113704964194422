import { useTranslation } from 'react-i18next';
import { FaProjectDiagram } from 'react-icons/fa';
import Card from 'components/common/Card';
import { Form } from 'formik-antd';
import MeetingAtFormikInput from './MeetingAtFormitInput';
import ValidityContractFormikInput from './ValidityContractFormikInput';
import ProjectNameFormikInput from './ProjectNameFormikInput';

/**
 * Form to configure the project information
 * @returns {JSX.Element} let's the user enter information about the project
 * @component
 */
const ProjectInformation = () => {
  const { t } = useTranslation();
  return (
    <Card bordered title={t('user.ShoppingCart.ProjectInformation.projectLabel')} icon={<FaProjectDiagram />}>
      <Form layout="vertical">
        <ProjectNameFormikInput />
        <ValidityContractFormikInput />
        <MeetingAtFormikInput />
      </Form>
    </Card>
  );
};

export default ProjectInformation;
