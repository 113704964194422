import { memo } from 'react';
import equal from 'fast-deep-equal/es6/react';
// import { useTranslation } from 'react-i18next';
// import Switch from 'components/common/Switch';
// import { useCompareToGlobalContext } from 'contexts/CompareToGlobalContext';

const CompareToGlobalSwitch = () => {
  // region TODO: enable comparison feature
  // const { t } = useTranslation();
  // const { isCompare, toggleCompare } = useCompareToGlobalContext();
  // return <Switch checked={isCompare} onChange={toggleCompare} label={t('user.ShoppingCart.compareOptions.global')} />;
  // endregion
  return null;
};

export default memo(CompareToGlobalSwitch, equal);
