import { Trans } from 'react-i18next';
import useSigningContext from 'hooks/signing/useSigningContext';
import ServiceOverview from './ServiceOverviewStep/ServiceOverview';
import CustomWarningAlert from './CustomWarningAlert';
import classes from './ServiceOverviewStep.module.less';

/**
 * ServiceOverviewStep component represents the service overview step of the signing process
 * @param {Object} inputParameters - Input parameters of the component
 * @param {React.Ref} inputParameters.submitReference - Reference, which gets the onContinue function of the step
 * @returns {JSX.Element} step component for the service overview step
 * @component
 */
const ServiceOverviewStep = ({ submitReference }) => {
  const { signingContext } = useSigningContext();

  // eslint-disable-next-line no-param-reassign
  submitReference.current = {
    onContinue: async () => {
      return true;
    },
  };
  const tenantEmail = signingContext?.officeEmail || '';
  const components = {
    // eslint-disable-next-line
    emailLink: <a href={`mailto:${tenantEmail}`} />,
  };
  return (
    <>
      <div className={classes.introText}>
        <CustomWarningAlert
          description={<Trans i18nKey="signing.serviceOverviewStep.introtext.text.client" components={components} />}
        />
      </div>
      <ServiceOverview />
    </>
  );
};

export default ServiceOverviewStep;
