import { remindAllSignees } from 'graphql/methods';
import { differenceBy } from 'lodash';
import { sendEmailModal } from 'pages/shoppingCartManagement/ShoppingCart/components/SendEmail';
import confirmModal from 'utils/confirmModal';
import classes from './OpenRemindAllEmailDrawer.module.less';

/**
 * Maps signee to recipient
 * @param {Object} signee - Signee object
 * @returns {Object} recipient object (email, _id)
 */
const mapSigneeToEmailRecipient = (signee) => ({
  _id: signee.id,
  email: signee.email,
});

/**
 * Filter for signees which arent't in signedSignees
 * @param {Object} signees - Signees to filter
 * @param {Object} signedSignees - Signees, which shouldn't be in the filtered signees
 * @returns {Object} filter signees with only signees, which haven't signed yet
 */
const filterForUnsignedSignees = (signees, signedSignees) => differenceBy(signees, signedSignees, '_id');

/**
 * Get recipients out of the signees of the shopping cart and applied filters
 * @param {Object} shoppingCart - Shopping cart for which the emails should be sent
 * @param {Object} filterOptions - Filter options to define which signees should be recipients
 * @param {Boolean} filterOptions.onlyUnsignedSignees - Should only unsigned signees get an email
 * @param {String} filterOptions.signeeType - Which type of signees should get an email
 * @returns {Object[]} Recipients for the email, which are signees of the digital signature process and which passed the filter
 */
const getCorrectRecipients = (shoppingCart, filterOptions) => {
  const { signeeType, onlyUnsignedSignees } = filterOptions;
  const { companySignees, tenantSignees, signedCompanySignees, signedTenantSignees } = shoppingCart.signatureData;

  if (!onlyUnsignedSignees) {
    if (signeeType === 'TENANT') return tenantSignees.map(mapSigneeToEmailRecipient);
    if (signeeType === 'COMPANY') return companySignees.map(mapSigneeToEmailRecipient);
    return [...companySignees, ...tenantSignees].map(mapSigneeToEmailRecipient);
  }
  const unsignedTenantSignees = filterForUnsignedSignees(tenantSignees, signedTenantSignees);
  const unsignedCompanySignees = filterForUnsignedSignees(companySignees, signedCompanySignees);

  if (signeeType === 'TENANT') return unsignedTenantSignees.map(mapSigneeToEmailRecipient);
  if (signeeType === 'COMPANY') return unsignedCompanySignees.map(mapSigneeToEmailRecipient);
  return [...unsignedCompanySignees, ...unsignedTenantSignees].map(mapSigneeToEmailRecipient);
};

/**
 * Opens the email drawer for the remind all email
 * @param {Object} inputParameters - Input parameters of the function
 * @param {Object} inputParameters.shoppingCart - Shopping cart object, for which the emails should be sent
 * @param {Object} inputParameters.filterOptions - Filter options to define which signees should be recipients
 * @param {Boolean} inputParameters.filterOptions.onlyUnsignedSignees - Should only unsigned signees get an email
 * @param {String} inputParameters.filterOptions.signeeType - Which type of signees should get an email
 * @param {String} inputParameters.emailTemplateId - Database of email template, which should be preselected
 * @param {Object} inputParameters.preferences - Tenant preferences object
 * @param {Function} inputParameter.translation - Translation function
 * @returns {void}
 */
const openRemindAllMailDrawer = ({ shoppingCart, filterOptions, emailTemplateId, preferences, translation }) => {
  if (!preferences || !shoppingCart) return;
  const recipients = getCorrectRecipients(shoppingCart, filterOptions);

  const initialRecipients = recipients.map((signee) => signee._id);
  const allRecipients = recipients;

  const onOk = (values, closeDrawer) => {
    remindAllSignees({
      shoppingCartId: shoppingCart._id,
      emailTemplateId: values.emailTemplateId,
      onlyRemindUnsigned: filterOptions.onlyUnsignedSignees,
      signeeType: filterOptions.signeeType,
      emailProps: {
        subject: values.subject,
        body: values.body,
        signature: values.signature,
        shouldSendAttachments: values.shouldSendAttachments,
      },
    });
    closeDrawer();
  };

  /**
   * Opens a confirm modal to check, if user really wants to exit the drawer
   * @returns {Promise<Boolean>} Returns a promise which resolves when user took an option and returns a boolean, if drawer should close or not
   */
  const onClose = async () => {
    return new Promise((resolve) => {
      confirmModal({
        okText: translation('viewer.ShoppingCartView.RemindAllSigneesModal.emailDrawer.onCloseModal.okButtonLabel'),
        title: translation('viewer.ShoppingCartView.RemindAllSigneesModal.emailDrawer.onCloseModal.message'),
        cancelText: translation(
          'viewer.ShoppingCartView.RemindAllSigneesModal.emailDrawer.onCloseModal.cancelButtonLabel',
        ),
        onOk: () => resolve(false),
        onCancel: () => resolve(true),
        className: classes.closeModal,
      });
    });
  };

  sendEmailModal({
    allRecipients,
    emailTemplateId,
    initialRecipients,
    onOk,
    shoppingCart,
    notificationMode: true,
    headerText: translation('viewer.ShoppingCartView.RemindAllSigneesModal.emailDrawer.title'),
    signature: preferences?.signature,
    onClose,
  });
};

export default openRemindAllMailDrawer;
