import { useMemo, useEffect, useCallback } from 'react';
import i18n from 'i18n';
import { Radio, Space, Skeleton } from 'antd';
import { adminEmailServiceQuery } from 'graphql/queries';
import KPEmailServiceSettings from 'pages/admin/EmailSetting/KPEmailServiceSettings';
import { useCachedQuery } from 'graphql/utils';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { defaults } from 'lodash';
import { DisplaySMTPSettings } from './DisplaySMTPSettings';

const defaultValues = {
  mailServiceProvider: 'default',
  smtpUserName: '',
  smtpPassword: '',
  smtpServer: '',
  smtpPort: '',
  from: '',
  useTLS: false,
  useSSLv3: false,
  requireTLS: false,
  dkimSettings: {
    useCustomDomain: false,
    domainName: '',
    isCustomDomainVerified: false,
    isSpfRecordVerified: false,
    isDkimRecordVerified: false,
    lastVerificationCheck: '',
    dkimRecordHostname: '',
    spfRecordHostname: '',
    dkimRecord: '',
    spfRecord: '',
  },
};
const skeletons = [...new Array(2)].map((_, i) => i + 1);

/**
 * The useInitialValues hook is used to get the initial values for the email settings.
 * @returns {Object} - The initial values and loading state.
 */
const useInitialValues = () => {
  const { data, loading } = useCachedQuery(adminEmailServiceQuery);
  const emailService = grabFirstGQLDataResult(data);

  const initialValues = useMemo(() => defaults({}, emailService, defaultValues), [emailService]);
  return { initialValues, loading, initialLoading: !data && loading };
};

/**
 * The EmailSettingsFormFields component is used to render the form fields for the email settings.
 * @param {Object} props - The props object containing the following properties:
 * @param {string} props.mailServiceProvider - The mail service provider.
 * @param {Function} props.setMailServiceProvider - The function to set the mail service provider.
 * @param {Function} props.setBestSMTPSettings - The function to set the best SMTP settings.
 * @returns {JSX.Element} - The EmailSettingsFormFields component.
 * @component
 */
export const EmailSettingsFormFields = ({ mailServiceProvider, setMailServiceProvider, setBestSMTPSettings }) => {
  const { initialValues, initialLoading } = useInitialValues();

  useEffect(() => {
    setMailServiceProvider(initialValues.mailServiceProvider);
  }, [initialValues, setMailServiceProvider]);

  const updateEmailServiceProvider = useCallback(
    (value) => {
      setMailServiceProvider(value);
    },
    [setMailServiceProvider],
  );

  if (initialLoading) return skeletons.map((k) => <Skeleton title loading active key={k} />);

  return (
    <Space direction="vertical" size="large" style={{ width: '100%' }}>
      <Radio.Group
        name="mailServiceProvider"
        value={mailServiceProvider}
        onChange={(e) => updateEmailServiceProvider(e.target.value)}
      >
        <Radio.Button name="mailServiceProvider" value="default">
          {i18n.t('admin.Setting.Email.inputs.mailServiceProvider.default')}
        </Radio.Button>
        <Radio.Button name="mailServiceProvider" value="smtp">
          {i18n.t('admin.Setting.Email.inputs.mailServiceProvider.smtp')}
        </Radio.Button>
      </Radio.Group>
      <KPEmailServiceSettings
        initialLoading={initialLoading}
        initialValues={initialValues}
        show={mailServiceProvider === 'default'}
      />
      <DisplaySMTPSettings
        initialLoading={initialLoading}
        initialValues={initialValues}
        show={mailServiceProvider === 'smtp'}
        setBestSMTPSettings={setBestSMTPSettings}
      />
    </Space>
  );
};
