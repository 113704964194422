import apollo from 'graphql/apollo';
import { deleteUserMutation } from 'graphql/mutations';
import { adminUsersInTenantQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';
import stateInvitation from 'constants/stateInvitation';
import { find } from 'lodash';

const deleteUser = async ({ userId }) =>
  apollo.mutate({
    mutation: deleteUserMutation,
    variables: { userId },
    update: (cache, { data }) => {
      const isDeleteUser = grabFirstGQLDataResult(data);
      if (isDeleteUser) {
        const cacheData = cache.readQuery({ query: adminUsersInTenantQuery });
        const users = grabFirstGQLDataResult(cacheData);
        const deletingUser = find(users, { _id: userId });
        let newUsers;
        if (deletingUser.state === stateInvitation.INVITATION_SEND) {
          newUsers = users.filter(({ _id }) => _id !== userId);
        } else {
          newUsers = users.map((user) => (user._id === userId ? { ...user, state: stateInvitation.INACTIVE } : user));
        }
        cache.writeQuery({
          query: adminUsersInTenantQuery,
          data: {
            usersInTenant: newUsers,
          },
        });
      }
    },
  });

export default deleteUser;
