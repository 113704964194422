import { Routes, Route, Navigate } from 'react-router-dom';
import AuthLayout from 'components/layouts/AuthLayout';
import Login from 'pages/auth/Login';
import ResetPassword from 'pages/auth/ResetPassword';
import ScrollToTop from 'components/common/ScrollToTop';
import routePaths from './route-paths';

export const authRoutes = [
  {
    path: routePaths.login,
    component: Login,
  },
  {
    path: routePaths.resetPassword,
    component: ResetPassword,
  },
];

const AuthRouter = () => (
  <AuthLayout>
    <ScrollToTop />
    <Routes>
      {authRoutes.map(({ path, component: C, ...rest }) => (
        <Route key={path} path={path} element={<C />} {...rest} />
      ))}
      <Route path="*" element={<Navigate to={routePaths.login} />} />
    </Routes>
  </AuthLayout>
);

export default AuthRouter;
