import { gql } from '@apollo/client';

const addDocumentsToShoppingCartMutation = gql`
  mutation addDocumentsToShoppingCartMutation($shoppingCartId: ID!, $documentIds: [ID]) {
    addDocumentsToShoppingCart(shoppingCartId: $shoppingCartId, documentIds: $documentIds) {
      _id
      documentTemplates {
        _id
        name
      }
      pdfs {
        _id
        type
        link
        isGenerating
        name
      }
    }
  }
`;

export default addDocumentsToShoppingCartMutation;
