import apollo from 'graphql/apollo';
import { sendTestEmailWithSMTPQuery } from 'graphql/queries';
import toast from 'utils/toast';
import i18n from 'i18n';

const sendTestEmail = async () =>
  apollo
    .query({
      query: sendTestEmailWithSMTPQuery,
      fetchPolicy: 'network-only',
    })
    .then(() => toast.success(i18n.t('admin.Setting.Email.saveAndTestEmailModal.successMessage')))
    .catch(() => toast.error(i18n.t('admin.Setting.Email.saveAndTestEmailModal.errorMessage')));

export default sendTestEmail;
