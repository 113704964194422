/* eslint-disable no-param-reassign */
import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Editable, withReact, useSlate, Slate, useSelected, useFocused, ReactEditor } from 'slate-react';
import { Editor, Transforms, createEditor, Element as SlateElement, Path, Text, Range, Node } from 'slate';
import { withHistory } from 'slate-history';
import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  MenuOutlined,
  AlignRightOutlined,
  BoldOutlined,
  ClearOutlined,
  ItalicOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  OrderedListOutlined,
  UnderlineOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { usePrevious } from 'utils/helpers';
import { Field } from 'formik';
import FormItem from 'components/common/FormItem';
import { useTranslation } from 'react-i18next';
import { MdLooks3, MdLooksOne, MdLooksTwo, MdTitle } from 'react-icons/md';
import { styledComponents, Mentions, RichText } from '@JavaScriptSuperstars/kanzleipilot-shared';
import equal from 'fast-deep-equal/es6/react';
import toast from 'utils/toast';
import cn from 'classnames';
import confirmModal from 'utils/confirmModal';
import i18n from 'i18n';
import { Button } from 'antd';
import { useFunctionToRefCB } from 'memo';
import { BsListCheck } from 'react-icons/bs';
import { castArray, isEqual } from 'lodash';
import { Toolbar } from './components';
import classes from './components/classes.module.less';
import { MentionElement, withMentions } from './plugins/withMentions';
import { LinkBlockButton, withLinks } from './plugins/withLinks';
import { BlockButton, toggleMark, LIST_TYPES } from './BlockButton';
import { alignFn } from './plugins/withAlign';
import { decreaseListItemDepth, increaseListItemDepth } from './plugins/withMultiLevelList';
import { resetRichText } from './utils';
import { useMentionContext } from './MentionContext';

const { H1, H2, H3, P, A, UL, OL, Title, Li, ULChecked } = styledComponents;

// dev purposes
window.SLATE_P = {
  Editor,
  Transforms,
  createEditor,
  SlateElement,
  Path,
  Text,
};
// dev purposes
window.SLATE_P_R = {
  Editable,
  withReact,
  useSlate,
  Slate,
  useSelected,
  useFocused,
};
// window.showRichTextRaw = true;
const Element = (props) => {
  const { attributes, children, element } = props;
  const style = {};
  if (element.align) style.textAlign = element.align;
  const attr = { ...attributes, style };
  if (LIST_TYPES.includes(element.type)) attr.type = element.listType;
  switch (element.type) {
    case 'bulleted-list':
      return <UL {...attr}>{children}</UL>;
    case 'checked-list':
      return <ULChecked {...attr}>{children}</ULChecked>;
    case 'list-item':
      return <Li {...attr}>{children}</Li>;
    case 'numbered-list':
      return <OL {...attr}>{children}</OL>;
    case 'heading-one':
      return <H1 {...attr}>{children}</H1>;
    case 'heading-two':
      return <H2 {...attr}>{children}</H2>;
    case 'div':
      return <div {...attr}>{children}</div>;
    case 'heading-three':
      return <H3 {...attr}>{children}</H3>;
    case 'title':
      if (!attr.style.textAlign) attr.style.textAlign = 'left';
      return <Title {...attr}>{children}</Title>;
    case 'mention':
      return <MentionElement {...props} />;
    case 'link':
      return (
        <A {...attr} href={element.url} target="_blank" rel="noopener noreferrer">
          {children}
        </A>
      );
    default:
      return <P {...attr}>{children}</P>;
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) children = <strong>{children}</strong>;
  if (leaf.italic) children = <em>{children}</em>;
  if (leaf.underline) children = <u>{children}</u>;
  return <span {...attributes}>{children}</span>;
};

const preventDefault = (e) => e.preventDefault();

const useHideToolbar = () => {
  const focused = useFocused();
  const { focused: prevFocused } = usePrevious({ focused }, { focused });
  const [isToolbarHidden, setIsToolbarHidden] = useState(true);
  const timeoutRef = useRef();
  const timeoutFocusRef = useRef();
  const onFocus = () => {
    clearTimeout(timeoutRef.current);
    clearTimeout(timeoutFocusRef.current);
    timeoutFocusRef.current = window.setTimeout(() => setIsToolbarHidden(false), 100);
  };
  const onBlur = () => {
    clearTimeout(timeoutRef.current);
    clearTimeout(timeoutFocusRef.current);
    timeoutRef.current = window.setTimeout(() => {
      setIsToolbarHidden(true);
    }, 300);
  };
  if (prevFocused && !focused) onBlur();
  if (!prevFocused && focused) onFocus();
  return { isToolbarHidden };
};
const formattingDisabledIn = ['heading-one', 'heading-two', 'heading-three', 'title'];
const alignDisableIn = [];
const SlateToolbar = ({ toolbarChildren, allowedModifiers, rootElement, isAlwaysShow, resetFormattingMemoCB } = {}) => {
  const { isToolbarHidden } = useHideToolbar();
  if (!isAlwaysShow && isToolbarHidden) return null;
  const renderButton = (format, component) =>
    (allowedModifiers ? allowedModifiers?.includes?.(format) : true) ? component({ format, rootElement }) : null;
  return (
    <Toolbar onMouseDown={preventDefault}>
      {renderButton('bold', (props) => (<BlockButton {...props} icon={<BoldOutlined />} disableIn={formattingDisabledIn} />)) /* prettier-ignore *//* eslint-disable-line prettier/prettier */}
      {renderButton('italic', (props) => (<BlockButton {...props} icon={<ItalicOutlined />} disableIn={formattingDisabledIn} />)) /* prettier-ignore *//* eslint-disable-line prettier/prettier */}
      {renderButton('underline', (props) => (<BlockButton {...props} icon={<UnderlineOutlined />} disableIn={formattingDisabledIn} />)) /* prettier-ignore *//* eslint-disable-line prettier/prettier */}
      {renderButton('numbered-list', (props) => (<BlockButton {...props} icon={<OrderedListOutlined />} />)) /* prettier-ignore *//* eslint-disable-line prettier/prettier */}
      {renderButton('bulleted-list', (props) => (<BlockButton {...props} icon={<UnorderedListOutlined />} />)) /* prettier-ignore *//* eslint-disable-line prettier/prettier */}
      {renderButton('checked-list', (props) => (<BlockButton {...props} icon={<BsListCheck strokeWidth="0.3" />} />)) /* prettier-ignore *//* eslint-disable-line prettier/prettier */}
      {renderButton('indent', (props) => (<BlockButton {...props} icon={<MenuFoldOutlined />} customFn={decreaseListItemDepth} />)) /* prettier-ignore *//* eslint-disable-line prettier/prettier */}
      {renderButton('outdent', (props) => (<BlockButton {...props} icon={<MenuUnfoldOutlined />} customFn={increaseListItemDepth} />)) /* prettier-ignore *//* eslint-disable-line prettier/prettier */}
      {renderButton('title', (props) => (<BlockButton {...props} removeMarks icon={<MdTitle />} />)) /* prettier-ignore *//* eslint-disable-line prettier/prettier */}
      {renderButton('heading-one', (props) => (<BlockButton {...props} removeMarks icon={<MdLooksOne />} />)) /* prettier-ignore *//* eslint-disable-line prettier/prettier */}
      {renderButton('heading-two', (props) => (<BlockButton {...props} removeMarks icon={<MdLooksTwo />} />)) /* prettier-ignore *//* eslint-disable-line prettier/prettier */}
      {renderButton('heading-three', (props) => (<BlockButton {...props} removeMarks icon={<MdLooks3 />} />)) /* prettier-ignore *//* eslint-disable-line prettier/prettier */}
      {renderButton('align-left', (props) => (<BlockButton {...props} disableIn={alignDisableIn} customFn={alignFn()} icon={<AlignLeftOutlined />} />)) /* prettier-ignore *//* eslint-disable-line prettier/prettier */}
      {renderButton('align-center', (props) => (<BlockButton {...props} disableIn={alignDisableIn} customFn={alignFn('center')} icon={<AlignCenterOutlined />} />)) /* prettier-ignore *//* eslint-disable-line prettier/prettier */}
      {renderButton('align-right', (props) => (<BlockButton {...props} disableIn={alignDisableIn} customFn={alignFn('right')} icon={<AlignRightOutlined />} />)) /* prettier-ignore *//* eslint-disable-line prettier/prettier */}
      {renderButton('align-justify', (props) => (<BlockButton {...props} disableIn={alignDisableIn} customFn={alignFn('justify')} icon={<MenuOutlined />} />)) /* prettier-ignore *//* eslint-disable-line prettier/prettier */}
      {renderButton('link', LinkBlockButton)}
      {renderButton('reset-formatting', () => (<Button size="small" type="default" onMouseDown={resetFormattingMemoCB} icon={<ClearOutlined />} />)) /* prettier-ignore *//* eslint-disable-line prettier/prettier */}
      {toolbarChildren && (typeof toolbarChildren === 'function' ? toolbarChildren() : toolbarChildren)}
    </Toolbar>
  );
};
const SlateComponent = memo(
  forwardRef(function SlateComponent(
    {
      toolbarChildren,
      toolbarProps = {},
      readOnly = false,
      renderElement,
      renderLeaf,
      allowedModifiers,
      rootElement,
      resetFormattingMemoCB,
    },
    ref,
  ) {
    const editor = useSlate();
    ref.current = editor;
    return (
      <>
        <SlateToolbar
          toolbarChildren={toolbarChildren}
          allowedModifiers={allowedModifiers}
          rootElement={rootElement}
          resetFormattingMemoCB={resetFormattingMemoCB}
          {...toolbarProps}
        />
        <div className={cn(classes.editorValue, 'slate-editor-wrapper')}>
          <Editable
            className="slate-editable"
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            readOnly={readOnly}
            // placeholder="Enter some rich text..."
            spellCheck
            // autoFocus fix KJ-611
            onKeyDown={(event) => {
              // https://github.com/ianstormtaylor/slate/issues/3456#issuecomment-635985399
              if (event.key === 'Backspace' || event.key === 'Delete') {
                const { selection } = editor;
                if (selection && Range.isCollapsed(selection)) {
                  const currentNode = Node.parent(editor, selection.anchor.path);
                  if (SlateElement.isElement(currentNode)) {
                    if (editor.isVoid(currentNode)) {
                      event.preventDefault();
                      editor.deleteBackward('block');
                    }
                  }
                }
              }
              // soft break
              if (event.key === 'Enter' && event.shiftKey) {
                event.preventDefault();
                editor.insertText('\n');
              }
              // tabulator
              if (event.key === 'Tab' && !event.shiftKey) {
                event.preventDefault();
                const { selection } = editor;
                if (Range.isCollapsed(selection)) {
                  editor.insertText('\t');
                }
              }
              // remove tabulator
              if (event.key === 'Tab' && event.shiftKey) {
                event.preventDefault();
                const { selection } = editor;
                if (Range.isCollapsed(selection)) {
                  const node = Node.get(editor, selection.anchor.path);
                  if (node?.text?.[selection.anchor.offset - 1] === '\t')
                    Editor.deleteBackward(editor, { unit: 'character' });
                }
              }
              // highlighted text as bold
              if (event.code === 'KeyB' && (event.altKey || event.ctrlKey || event.metaKey)) {
                event.preventDefault();
                toggleMark(editor, 'bold');
              }
            }}
          />
        </div>
      </>
    );
  }),
  equal,
);

const tryGetInitialValue = (initialValueJSON, { rootElement = 'div', schemaIds } = {}) => {
  const defaultValue = RichText.getDefaultRichEditorValue({ rootElement, toJsObject: true });
  let value = defaultValue;
  if (!initialValueJSON) return value;
  try {
    value = Mentions.replaceMentions({
      value: initialValueJSON,
      normalizeMention: (node) =>
        schemaIds.includes(node._id) || schemaIds.includes(node.name) ? node : Mentions.generateDeletedMention(node),
      toJsObject: true,
    });
    // console.log(value);
    // eslint-disable-next-line no-empty
  } catch (e) {
    // console.debug(e);
    value = defaultValue;
  }
  if (!Node.isNodeList(value)) value = defaultValue;
  return value;
};
const getValueForI18n = (value) => {
  if (!window.showRichTextForI18n) return null;
  const value1 = `'${JSON.stringify(value)}'`;
  return (
    <div
      role="presentation"
      onClick={() => {
        navigator.clipboard.writeText(value1);
        toast.success('copied');
      }}
      style={{ whiteSpace: 'pre-line', fontSize: 13, lineHeight: '12px', fontFamily: 'monospace' }}
    >
      {value1}
    </div>
  );
};
const getValueForDebug = (value) => {
  if (!window.showRichTextRaw) return null;
  const value1 = JSON.stringify(value, null, 2);
  return (
    <div
      role="presentation"
      onClick={() => {
        navigator.clipboard.writeText(value1);
        toast.success('copied');
      }}
      style={{ whiteSpace: 'pre-wrap', fontSize: 13, lineHeight: '12px', fontFamily: 'monospace' }}
    >
      {value1}
    </div>
  );
};
function getField(fields) {
  return castArray(fields)
    .map((field) => {
      return field.fields ? getField(field.fields) : [field._id ?? field.name];
    })
    .flat();
}
const useSchemaIds = (schema) => {
  return useMemo(() => getField(castArray(schema).filter(Boolean).flat()), [schema]);
};
const defaultWithPlugins = (e) => e;
const RichTextExample = (
  {
    toolbarChildren,
    toolbarProps,
    readOnly,
    withPlugins = defaultWithPlugins,
    initialValue,
    onChange,
    allowedModifiers,
    rootElement = 'div',
    className,
  },
  ref,
) => {
  const { schema } = useMentionContext();
  const schemaIds = useSchemaIds(schema);
  const [key, setKey] = useState(1);
  const onUpdateKey = useCallback(() => setKey((p) => p + 1), []);
  const [value, setValue] = useState(() => tryGetInitialValue(initialValue, { rootElement, schemaIds }));
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(
    () => withPlugins(withLinks(withMentions(withHistory(withReact(createEditor()))))),
    [withPlugins],
  );
  const slateRef = useRef();
  const slateComponentRef = useRef();
  useImperativeHandle(ref, () => ({ editor }));
  if (!window.showRichTextRawLogged)
    window.showRichTextRawLogged =
      console.log(
        'use window.showRichTextRaw=true or window.showRichTextForI18n=true to show rich text editor value',
      ) || true;
  const onChangeMemoCB = useFunctionToRefCB((newValue, refresh) => {
    setValue(newValue);
    window.setTimeout(() => {
      onChange && onChange(JSON.stringify(newValue));
    }, 0);
    refresh && onUpdateKey();
  });
  // update the initial value if it is incorrect
  useEffect(
    () => {
      const valueJSON = JSON.stringify(value);
      if (initialValue !== valueJSON)
        window.setTimeout(() => {
          onChange && onChange(valueJSON);
        }, 0);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  useEffect(() => {
    if (slateComponentRef.current && !ReactEditor.isFocused(slateComponentRef.current)) {
      const initialValueObj = tryGetInitialValue(initialValue, { rootElement, schemaIds });
      if (!isEqual(initialValueObj, value)) {
        setValue(initialValueObj);
        onUpdateKey();
      }
    }
  }, [initialValue, value, rootElement, onUpdateKey, schemaIds]);

  const resetFormattingMemoCB = useFunctionToRefCB(() => {
    const onConfirm = () => {
      window.getSelection().removeAllRanges();
      window.setTimeout(() => {
        onChangeMemoCB(resetRichText({ value, rootElement }), true);
      }, 100);
    };
    confirmModal({
      cancelText: i18n.t('admin.resetTextStylesConfirmation.cancel'),
      okText: i18n.t('admin.resetTextStylesConfirmation.yes'),
      okType: 'danger',
      onOk: onConfirm,
      title: i18n.t('admin.resetTextStylesConfirmation.title'),
    });
  });
  return (
    <div key={key} className={cn('slate', className)}>
      <Slate ref={slateRef} editor={editor} value={value} onChange={onChangeMemoCB}>
        <SlateComponent
          ref={slateComponentRef}
          allowedModifiers={allowedModifiers}
          toolbarChildren={toolbarChildren}
          toolbarProps={toolbarProps}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          rootElement={rootElement}
          readOnly={readOnly}
          resetFormattingMemoCB={resetFormattingMemoCB}
        />
        {getValueForDebug(value)}
        {getValueForI18n(value)}
        {/* window.showRichTextSerialized ? <div>{serialize(value)}</div> : null */}
        {/* window.showRichTextHTML ? <div dangerouslySetInnerHTML={{ __html: serialize(value) }} /> : null */}
      </Slate>
    </div>
  );
};
const RichTextWithRef = forwardRef(RichTextExample);

const RichEditorFormik_ = ({ name, label, onChange = () => {}, tooltip, containerProps, ...props }, ref) => {
  const { t } = useTranslation();
  if (name)
    return (
      <FormItem
        name={name}
        label={typeof label === 'string' ? t(label) : label}
        tooltip={tooltip}
        containerProps={containerProps}
      >
        <Field name={name}>
          {({ field, form }) => {
            return (
              <RichTextWithRef
                ref={ref}
                onChange={(contentState) => {
                  form.setFieldValueAndTouched(name, contentState);
                }}
                initialValue={field.value}
                {...props}
              />
            );
          }}
        </Field>
      </FormItem>
    );
  return <RichTextWithRef ref={ref} onChange={onChange} {...props} />;
};
export const BaseRichEditorField = forwardRef(RichEditorFormik_);
