import { gql } from '@apollo/client';
import { documentTemplateBlockDescriptorFragment } from 'graphql/fragments';

const adminDocumentTemplateBlockDescriptorsQuery = gql`
  query documentTemplateBlockDescriptorsQuery {
    documentTemplateBlockDescriptors {
      ...documentTemplateBlockDescriptorFragment
    }
  }
  ${documentTemplateBlockDescriptorFragment}
`;

export default adminDocumentTemplateBlockDescriptorsQuery;
