import { useTranslation } from 'react-i18next';
import { formatDateToAvailableTime } from 'pages/shoppingCartManagement/ShoppingCartView/DigitalSignatureCard/utils';
import classes from './DateAndAvailableTimeField.module.less';

const DateAndAvailableTimeField = ({ date, label }) => {
  const { t: translation } = useTranslation();
  const availableTime = formatDateToAvailableTime(date, translation);
  const formattedSignDate = date.toLocaleString('de-DE', {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
  return (
    <div>
      <div>{label}</div>
      <div className={classes.infos}>
        <span>{formattedSignDate}</span> <span>{availableTime}</span>
      </div>
    </div>
  );
};

export default DateAndAvailableTimeField;
