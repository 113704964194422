import { find } from 'lodash';
import { useCallback, useMemo, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

export const useFindRecord = (data) => {
  const findRecord = useCallback(
    (_id) => {
      const _record = find(data, { _id });
      return {
        record: _record,
        index: data.indexOf(_record),
      };
    },
    [data],
  );
  return findRecord;
};
export const useDragNDrop = ({ recordId, moveMutation, itemTypes, moveMutationCache, extraDropProps = {} }) => {
  const ref = useRef(null);
  const [{ isDraggingInContainer }, drop] = useDrop({
    accept: itemTypes,
    hover: ({ _id: draggedId }) => {
      if (draggedId !== recordId) {
        moveMutationCache(recordId, draggedId);
      }
    },
    collect: (monitor) => ({
      isDraggingInContainer: monitor.getItemType(),
    }),
  });
  const [{ isDragging }, drag, preview] = useDrag({
    type: itemTypes,
    item: () => {
      return { _id: recordId };
    },
    end: () => {
      moveMutation({ _id: recordId, ...extraDropProps });
    },
    collect: (monitor) => ({
      isDragging: recordId === monitor.getItem()?._id,
    }),
  });
  return useMemo(
    () => ({ drag, drop, preview, ref, isDragging, isDraggingInContainer }),
    [drag, drop, isDragging, isDraggingInContainer, preview],
  );
};
