import { Form } from 'antd';
import { FaFileSignature } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useFormikField } from 'hooks/common/useFormikField';
import Card from 'components/common/Card';
import NoticeOfCancellationDocumentTemplateSelect from './NoticeOfCancellationCard/NoticeOfCancellationDocumentTemplateSelect';

const NoticeOfCancellationCard = () => {
  const { t } = useTranslation();
  const { value: digitalSignatureEnabled } = useFormikField('digitalSignatureEnabled');
  if (!digitalSignatureEnabled) return null;
  return (
    <Card
      icon={<FaFileSignature />}
      title={t('admin.Setting.DigitalSignaturePreferences.noticeOfCancellation.cardLabel')}
      style={{ padding: 0 }}
    >
      <Form layout="vertical">
        <NoticeOfCancellationDocumentTemplateSelect t={t} />
      </Form>
    </Card>
  );
};

export default NoticeOfCancellationCard;
