import { gql } from '@apollo/client';

const adminContactFragment = gql`
  fragment adminContactFragment on Contact {
    _id
    email
    firstName
    fullSalutation
    lastName
    phoneNumber
    phoneNumberAlternative
    position
    salutation
  }
`;

export default adminContactFragment;
