import SigningInfoPagesLayout from 'components/layouts/SigningInfoPagesLayout';
import useSigningContext from 'hooks/signing/useSigningContext';
import { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router';
import routePaths from 'router/route-paths';
import classes from './InfoPages.module.less';

const WaitForDocumentsTimeout = () => {
  const { loading, signingContext } = useSigningContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && !signingContext) navigate(routePaths.signingUnvalid);
  }, [loading, signingContext, navigate]);

  const urlText = `${window.location.host}${routePaths.signing}`;
  const url = `${window.location.origin}${routePaths.signing}`;
  const officeEmail = signingContext?.officeEmail;
  // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label
  const linkComponent = <a href={url} />;

  return (
    <SigningInfoPagesLayout theme={signingContext?.theme} contentAreaClassName={classes.contentArea}>
      <div className={classes.headingWrapper}>
        <h1 className={classes.infoPageHeading}>
          <Trans
            i18nKey="signing.infoPages.waitForDocumentsTimeout.heading"
            values={{ url: urlText, officeEmail }}
            components={{ a: linkComponent }}
          />
        </h1>
      </div>
    </SigningInfoPagesLayout>
  );
};

export default WaitForDocumentsTimeout;
