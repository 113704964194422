import { forwardRef, memo, useState } from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import equal from 'fast-deep-equal/es6/react';
import BlockPreviewContent from './BlockPreviewContent';
import DocumentLayoutBlockForm from './DocumentLayoutBlockForm';
import BlockHelperWidget from './BlockHelperWidget';

const i18nPrefix = 'admin.DocumentTemplateConfigurationPage.layoutSection';

function DocumentLayoutBlockContent({ block, getDocumentTemplate, visibleFields, autosaveChanges, onSubmit }, ref) {
  const { t } = useTranslation();
  const { descriptor: blockDescriptor, _id, props, blockNumber } = block;

  // in case of tabs we need to change the preview image
  const [previewBlockDescriptor, setPreviewBlockDescriptor] = useState(blockDescriptor);
  return (
    <div>
      {blockDescriptor ? (
        <>
          {!visibleFields ? <BlockHelperWidget block={blockDescriptor} /> : null}
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={!visibleFields ? 16 : 24}>
              <DocumentLayoutBlockForm
                blockId={_id}
                ref={ref}
                autosaveChanges={autosaveChanges}
                blockNumber={blockNumber}
                form={blockDescriptor.form}
                getDocumentTemplate={getDocumentTemplate}
                initialValues={props}
                setPreviewBlockDescriptor={setPreviewBlockDescriptor}
                onSubmit={onSubmit}
                visibleFields={visibleFields}
              />
            </Col>
            {!visibleFields ? (
              <Col sm={24} md={8}>
                <BlockPreviewContent
                  block={previewBlockDescriptor}
                  hideBlockName
                  title={t(`${i18nPrefix}.infoSectionTitle`)}
                />
              </Col>
            ) : null}
          </Row>
        </>
      ) : null}
    </div>
  );
}
export default memo(forwardRef(DocumentLayoutBlockContent), equal);
