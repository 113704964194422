import { gql } from '@apollo/client';
import { adminDocumentTemplateByIdFragment } from 'graphql/fragments';

const updateDocumentTemplateMutation = gql`
  mutation updateDocumentTemplateMutation($modifier: UpdateDocumentTemplateInput!, $_id: ID!) {
    updateDocumentTemplate(_id: $_id, modifier: $modifier) {
      ...adminDocumentTemplateByIdFragment
    }
  }
  ${adminDocumentTemplateByIdFragment}
`;

export default updateDocumentTemplateMutation;
