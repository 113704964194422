import apollo from 'graphql/apollo';
import { moveItemMutation } from 'graphql/mutations';

const moveCategoryItem = async ({ _id, offset, order }, categoryListQuery) =>
  apollo.mutate({
    mutation: moveItemMutation,
    variables: { _id, offset, order },
    refetchQueries: [{ query: categoryListQuery }],
  });
export default moveCategoryItem;
