import { gql } from '@apollo/client';
import { superAdminTenantFragment } from 'graphql/fragments';

const superAdminTenantsQuery = gql`
  query superAdminTenantsQuery {
    tenants {
      ...superAdminTenantFragment
    }
  }
  ${superAdminTenantFragment}
`;

export default superAdminTenantsQuery;
