import { useMemo } from 'react';
import { useCachedQuery } from 'graphql/utils';
import { userDocumentTemplateMultiSelectQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';

/**
 * Fetches document templates and converts createdAt date to date object
 * @param {Boolean} isLibrary - Should load common library document templates
 * @returns {Object} (data, loading, error)
 */
const useDocumentTemplateList = (isLibrary) => {
  const { data, ...rest } = useCachedQuery(userDocumentTemplateMultiSelectQuery, {
    variables: { isLibrary },
  });
  return useMemo(() => {
    const documentTemplates = grabFirstGQLDataResult(data) || [];
    return {
      data: Array.isArray(documentTemplates)
        ? documentTemplates.map((row) => ({
            ...row,
            createdAt: new Date(row.createdAt),
          }))
        : [],
      ...rest,
    };
  }, [data, rest]);
};

export default useDocumentTemplateList;
