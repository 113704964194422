import { gql } from '@apollo/client';

const signingContextQuery = gql`
  query signingContext {
    signingContext {
      authToken
      signingProcessId
      theme
      firstName
      lastName
      nameOfCompany
      typeOfSignee
      positionOfSignee
      isPrivatePerson
      officeEmail
      steps
      currentState @client
      timeOfLastFetching @client
    }
  }
`;
export default signingContextQuery;
