import { Typography } from 'antd';
import classes from './OutOfServiceCardContent.module.less';

const { Title, Text } = Typography;

/**
 * Card content for out of service page
 * @returns {JSX.Element} out of service card
 * @component
 */
const OutOfServiceCardContent = () => {
  return (
    <div className={classes.flexContainer}>
      <Title level={1} className={classes.title}>
        Out of service
      </Title>
      <Text className={classes.text}>Es wird gerade eine außerplanmäßige Wartung durchgeführt.</Text>
      <Text className={classes.text}>Bitte probiere es später wieder.</Text>
    </div>
  );
};

export default OutOfServiceCardContent;
