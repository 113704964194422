// https://github.com/ianstormtaylor/slate/blob/master/site/examples/links.tsx
import { Transforms, Editor, Range, Element as SlateElement } from 'slate';
import isUrl from 'is-url';
import inputConfirmModal from 'utils/inputConfirmModal';
import i18n from 'i18n';
import * as Yup from 'yup';
import { cloneDeep } from 'lodash';
import { ReactEditor } from 'slate-react';
import { MdLink } from 'react-icons/md';
import { BlockButton } from '../BlockButton';

export const insertLink = (editor, url, text) => {
  if (editor.selection) {
    wrapLink(editor, url, text);
  }
};

export const isLinkActive = (editor) => {
  const [link] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  });
  return link;
};

export const unwrapLink = (editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  });
};
export const wrapLink = (editor, url, text) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: text || '' }] : [],
  };

  if (isCollapsed) {
    // no selection, just caret
    Transforms.insertNodes(editor, link);
  } else {
    // text selected
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' }); // deselect text
  }
};

export const withLinks = (editor) => {
  const { insertData, insertText, isInline, normalizeNode } = editor;
  // eslint-disable-next-line no-param-reassign
  editor.isInline = (element) => (element.type === 'link' ? true : isInline(element));
  // eslint-disable-next-line no-param-reassign
  editor.insertText = (text) => {
    // ctrl v
    if (text && isUrl(text)) wrapLink(editor, text, text);
    else insertText(text);
  };
  // eslint-disable-next-line no-param-reassign
  editor.insertData = (data) => {
    // ctrl v
    const text = data.getData('text/plain');
    if (text && isUrl(text)) wrapLink(editor, text, text);
    else insertData(data);
  };
  // eslint-disable-next-line no-param-reassign
  editor.normalizeNode = ([node, path]) => {
    if (node.type === 'link' && node.children.length <= 1 && !node.children[0]?.text) {
      Transforms.delete(editor, {
        at: path,
      });
    }
    return normalizeNode([node, path]);
  };

  return editor;
};

const linkFieldsSchema = ({ hideName }) =>
  [
    !hideName && {
      label: i18n.t('admin.RichEditor.toolbar.link.createModalName'),
      name: 'name',
      description: i18n.t('admin.RichEditor.toolbar.link.createModalName'),
    },
    {
      label: i18n.t('admin.RichEditor.toolbar.link.createModalValue'),
      name: 'value',
      description: i18n.t('admin.RichEditor.toolbar.link.createModalValue'),
      wideTooltip: true,
      tooltip: (
        // eslint-disable-next-line react/no-danger
        <div dangerouslySetInnerHTML={{ __html: i18n.t('admin.RichEditor.toolbar.link.modalValueTooltipHTML') }} />
      ),
    },
  ].filter(Boolean);
const optionValidationSchema =
  ({ hideName }) =>
  () =>
    Yup.object().shape({
      ...(hideName
        ? {}
        : {
            name: Yup.string().label(i18n.t('admin.RichEditor.toolbar.link.createModalName')).required().min(1),
          }),
      value: Yup.string()
        .label(i18n.t('admin.RichEditor.toolbar.link.createModalValue'))
        .required()
        .test('code-no-empty', i18n.t(`admin.RichEditor.toolbar.link.modalValueInvalidError`), (value) => {
          // eslint-disable-next-line no-param-reassign
          value = String(value);
          return (
            value.startsWith('tel:') ||
            (value.startsWith('mailto:') && value.split('mailto:')[1].match(Yup.rEmail)) ||
            (value?.toLowerCase?.()?.startsWith?.('http://') && value.match(Yup.rUrl)) ||
            (value?.toLowerCase?.()?.startsWith?.('https://') && value.match(Yup.rUrl))
          );
        })
        .min(1),
    });

export const createLinkModal = ({ onSubmit, hideName }) => {
  return inputConfirmModal({
    fields: linkFieldsSchema({ hideName }),
    headerText: i18n.t('admin.RichEditor.toolbar.link.createModalTitle'),
    onSubmit,
    okText: i18n.t('admin.inputFieldModal.comboOptions.modal.ok'),
    cancelText: i18n.t('admin.inputFieldModal.comboOptions.modal.cancel'),
    value: { name: '', value: '' },
    validationSchema: optionValidationSchema({ hideName }),
    width: '800px',
    forceMultiField: true,
  });
};

export const LinkBlockButton = (props) => (
  <BlockButton
    {...props}
    customFn={(editor) => {
      const prevSelection = cloneDeep(editor.selection);
      const isCollapsed = editor.selection && Range.isCollapsed(editor.selection);
      const linkActive = isLinkActive(editor);
      if (linkActive) {
        unwrapLink(editor);
        return;
      }
      createLinkModal({
        hideName: !isCollapsed,
        onSubmit: ({ value, name }) => {
          ReactEditor.focus(editor);
          Transforms.select(editor, prevSelection);
          isCollapsed ? insertLink(editor, value, name) : insertLink(editor, value, name);
        },
      });
    }}
    icon={<MdLink />}
  />
);
