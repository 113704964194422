import { gql } from '@apollo/client';
import apollo from 'graphql/apollo';
import { updateStatusMutation } from 'graphql/mutations';
import { userGetStatusesQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';

const statusFragment = gql`
  fragment statusFragment on Status {
    name
    type
    isDefault
  }
`;

const updateStatus = ({ _id, prevType, modifier }) =>
  apollo.mutate({
    mutation: updateStatusMutation,
    variables: { _id, modifier },
    update: (cache, { data }) => {
      const newStatus = grabFirstGQLDataResult(data);
      if (prevType === modifier.type) {
        cache.writeFragment({
          id: `Status___${_id}`,
          fragment: statusFragment,
          fragmentName: 'statusFragment',
          data: newStatus,
        });
        return;
      }
      const cachedData = cache.readQuery({ query: userGetStatusesQuery, variables: { type: prevType } });
      const statusesWithPrevType = grabFirstGQLDataResult(cachedData);
      cache.writeQuery({
        query: userGetStatusesQuery,
        variables: {
          type: prevType,
        },
        data: {
          getStatuses: statusesWithPrevType.filter((status) => status._id !== _id),
        },
      });

      const cachedNewData = cache.readQuery({ query: userGetStatusesQuery, variables: { type: modifier } });
      const statusesWithNewType = grabFirstGQLDataResult(cachedNewData);

      cache.writeQuery({
        query: userGetStatusesQuery,
        variables: {
          type: modifier.type,
        },
        data: {
          getStatuses: [newStatus, ...statusesWithNewType],
        },
      });
    },
  });
export default updateStatus;

export const updateStatusIsDefault = ({ _id, type, isDefault }) =>
  apollo.mutate({
    mutation: updateStatusMutation,
    variables: { _id, modifier: { isDefault } },
    refetchQueries: [{ query: userGetStatusesQuery, variables: { type } }],
  });
