import apollo from 'graphql/apollo';
import { userContactFragment } from 'graphql/fragments';
import updateContact from 'graphql/methods/admin/contact/updateContact';
import i18n from 'i18n';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { grabFirstGQLDataResult } from 'utils/helpers';
import inputConfirmModal from 'utils/inputConfirmModal';
import * as Yup from 'yup';
import { PhoneController } from 'components/common/PhoneInputFormik';
import { FaTrashAlt } from 'react-icons/fa';
import { Button } from 'antd';
import { useCachedQuery } from 'graphql/utils';
import { userContactListQuery } from 'graphql/queries';
import GraphQLLoadingWrapper from 'components/common/GraphQLLoadingWrapper';
import { pick, intersectionBy } from 'lodash';
import { ContactForm, NumberPhoneAlternativeFormikInput, NumberPhoneBusinessFormikInput } from '../components/Inputs';
import { contactFormSchema } from '../components/schema';
import classes from './ContactList.module.less';
import EditButton from '../components/EditButton';
import { useIsOldContactData } from '../hooks';

export const editContactFormSchema = () => {
  return Yup.object().shape({
    ...contactFormSchema(),
  });
};

const editContact = ({ contact, onChange }) =>
  inputConfirmModal({
    formContent: () => (
      <>
        <ContactForm isUpdate />
        <NumberPhoneBusinessFormikInput typeContactData="contact" />
        <NumberPhoneAlternativeFormikInput typeContactData="contact" />
      </>
    ),
    fields: [],
    onSubmit: async (modifier) => {
      const { data } = await updateContact({ _id: contact?._id, ...modifier });
      const currentContact = grabFirstGQLDataResult(data);
      onChange(currentContact);
    },
    value: {
      _id: contact?._id || '',
      email: contact?.email || '',
      firstName: contact?.firstName || '',
      fullSalutation: contact?.fullSalutation || '',
      lastName: contact?.lastName || '',
      phoneNumber: contact?.phoneNumber || '49-',
      phoneNumberAlternative: contact?.phoneNumberAlternative || '49-',
      position: contact?.position || '',
      salutation: contact?.salutation || null,
    },
    headerText: i18n.t('user.ShoppingCart.ContactData.modal.titles.editContact'),
    okText: i18n.t('user.ShoppingCart.ContactData.modal.ok'),
    cancelText: i18n.t('user.ShoppingCart.ContactData.modal.cancel'),
    validationSchema: editContactFormSchema,
    forceMultiField: true,
    width: '600px',
    errorResolver: {
      Duplicated: ['email', i18n.t('user.ShoppingCart.ContactData.contact.duplicatedErrorMessage.email')],
    },
  });

const useContacts = (initializationConfigDateForCompany) => {
  const { data, loading, error } = useCachedQuery(userContactListQuery, {
    variables: { initializationConfigDate: initializationConfigDateForCompany },
  });
  const contacts = useMemo(() => {
    const contactsList = grabFirstGQLDataResult(data);
    if (contactsList) {
      return contactsList.filter(Boolean);
    }
    return undefined;
  }, [data]);

  return { contacts, loading, error };
};

const ContactCard = ({ contact, onDelete, onChange }) => {
  const { _id, firstName, lastName, salutation, position, email, phoneNumber, phoneNumberAlternative } = contact;
  const { t } = useTranslation();
  const isOldContactData = useIsOldContactData();
  const onEdit = useCallback(() => {
    const contactFragment = apollo.readFragment({ id: `Contact___${_id}`, fragment: userContactFragment });
    editContact({ contact: contactFragment, onChange });
  }, [_id, onChange]);
  const onClickDelete = useCallback(() => {
    onDelete(_id);
  }, [_id, onDelete]);
  return (
    <div className={classes.contactCard}>
      <div className={classes.contactCardContent}>
        <div>
          {salutation && t(`user.ShoppingCart.ContactData.contact.inputs.salutation.options.${salutation}`)} {firstName}{' '}
          {lastName}
        </div>
        {position ? <div>{position}</div> : null}
        {email ? <div>{email}</div> : null}
        {PhoneController.parse(phoneNumber)?.phone ? <div>{`${phoneNumber}`}</div> : null}
        {PhoneController.parse(phoneNumberAlternative)?.phone ? <div>{`${phoneNumberAlternative}`}</div> : null}
      </div>
      <div className={classes.contactCardActions}>
        <EditButton onEdit={onEdit} />
        {isOldContactData ? null : (
          <Button
            disabled={isOldContactData}
            className="ant-button-transparent"
            onClick={onClickDelete}
            icon={<FaTrashAlt size={20} />}
          />
        )}
      </div>
    </div>
  );
};

const ContactList = ({ contacts: contactsFormik = [], initializationConfigDateForCompany, onDelete, onChange }) => {
  const { contacts, ...rest } = useContacts(initializationConfigDateForCompany);

  const searchedContacts = useMemo(
    () =>
      contactsFormik?.length &&
      contacts?.length &&
      intersectionBy(contacts, contactsFormik, (contact) =>
        Object.values(pick(contact, ['_id']))
          .filter(Boolean)
          .join('_'),
      ),
    [contacts, contactsFormik],
  );
  return (
    <GraphQLLoadingWrapper data={contacts} {...rest}>
      {searchedContacts?.map((contact) => (
        <ContactCard
          key={contact._id}
          contact={contact}
          onChange={onChange}
          onDelete={onDelete}
          initializationConfigDateForCompany={initializationConfigDateForCompany}
        />
      ))}
    </GraphQLLoadingWrapper>
  );
};
export default ContactList;
