import { useCachedQuery } from 'graphql/utils';
import { userPreferencesQuery } from 'graphql/queries';
import { omit } from 'lodash';
import { useMemo } from 'react';
import { grabFirstGQLDataResult } from 'utils/helpers';
import routePaths from 'router/route-paths';
import { useMatch } from 'react-router-dom';

function useFasterPreferences({ fetchPolicy = 'cache-and-network', shoppingCartId, configDate } = {}) {
  const { data } = useCachedQuery(userPreferencesQuery, { fetchPolicy: 'cache-only' });
  const {
    data: dataWithId,
    loading: loadingWithId,
    error: errorWithId,
  } = useCachedQuery(userPreferencesQuery, { fetchPolicy, variables: { shoppingCartId, configDate } });
  const isRevisionMode = useMatch(routePaths.routerShoppingCartRevision);

  const preferences = useMemo(() => {
    if (!data && !dataWithId) return null;
    const preferencesData = grabFirstGQLDataResult(dataWithId ?? data);
    const { emailPreferences } = preferencesData;
    const { shoppingCartPreferences } = preferencesData;
    const { monthlyPaymentPreferences, digitalSignaturePreferences } = preferencesData;
    return {
      ...emailPreferences,
      ...omit(shoppingCartPreferences, ['documentTemplates', 'feeType']),
      digitalSignaturePreferences,
      monthlyPaymentPreferences,
      documentTemplates: shoppingCartPreferences?.documentTemplates?.map((documentTemplate) =>
        JSON.stringify({ _id: documentTemplate._id }),
      ),
      feeType: shoppingCartPreferences?.pricingMode,
      ...(!!isRevisionMode && !shoppingCartPreferences?.allowCustomPrices && { allowCustomPrices: true }),
    };
  }, [data, dataWithId, isRevisionMode]);
  return {
    preferences,
    loading: loadingWithId,
    error: errorWithId,
  };
}
export default useFasterPreferences;
