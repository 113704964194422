import { memo, useMemo } from 'react';
import equal from 'fast-deep-equal/es6/react';
import { FaBookmark, FaCheck, FaInfoCircle } from 'react-icons/fa';
import { Button, Collapse, Tooltip } from 'antd';
import { useFormikField } from 'hooks/common/useFormikField';
import { RichText } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { isMobile } from 'react-device-detect';
import { RichEditorField } from 'components/common/RichEditor';
import { useLibraryContext, LibraryProvider } from 'contexts/LibraryContext';
import { isEmpty } from 'lodash';
import infoModal from 'utils/infoModal';
import ItemDifferences from 'components/user/compareToGlobal/ItemDifferences';
import classes from './ItemCard.module.less';
import { useDeselectItem } from '../utils';

const { Panel } = Collapse;

const CheckButton = ({ _id }) => {
  const { t } = useTranslation();
  const { value } = useFormikField(_id);
  const deselectItem = useDeselectItem();
  return (
    <Tooltip title={!isMobile ? t('user.ShoppingCart.Category.Item.selectTooltip') : ''} mouseEnterDelay={1}>
      <Button
        onClick={() => {
          deselectItem({ itemId: _id, isBookmark: false });
        }}
        icon={<FaCheck className={classes.checkIcon} />}
        className={cn(classes.itemButton, value && classes.activeItemCheckbox, 'primary-color-wave')}
      />
    </Tooltip>
  );
};
export const CheckButtonMemo = memo(CheckButton, equal);

const BookmarkButton = ({ _id }) => {
  const { t } = useTranslation();
  const { value } = useFormikField(`${_id}_bookmark`);
  const deselectItem = useDeselectItem();
  return (
    <Tooltip title={!isMobile ? t('user.ShoppingCart.Category.Item.bookmarkTooltip') : ''} mouseEnterDelay={0.5}>
      <Button
        onClick={() => {
          deselectItem({ itemId: _id, isBookmark: true });
        }}
        icon={<FaBookmark className={classes.checkIcon} />}
        className={cn(classes.itemButton, value && classes.activeItemCheckbox, 'primary-color-wave')}
      />
    </Tooltip>
  );
};
export const BookmarkButtonMemo = memo(BookmarkButton, equal);

const showInfoTextModal = ({
  benefits,
  descriptionForContract,
  infoText,
  isLibrary,
  name,
  notesForFeeAgreement,
  notesToEmployee,
  pleaseNote,
  t,
}) => {
  infoModal({
    title: `${name}`,
    maskClosable: true,
    content: (
      <LibraryProvider value={isLibrary}>
        <div>
          {infoText ? (
            <RichEditorField readOnly className="rich-editor-read-only margin-bottom-16" initialValue={infoText} />
          ) : null}
          {benefits ? (
            <>
              <div>
                <b>{t('user.ShoppingCart.Category.Item.infoTextModal.benefits')}</b>
              </div>
              <RichEditorField readOnly className="rich-editor-read-only" initialValue={benefits} />
            </>
          ) : null}
          {pleaseNote || descriptionForContract || notesForFeeAgreement || notesToEmployee ? (
            <Collapse ghost className={classes.infoTextModalCollapse}>
              <Panel header={t('user.ShoppingCart.Category.Item.infoTextModal.collapsePanelTitle')}>
                {descriptionForContract ? (
                  <>
                    <div>
                      <b>{t('user.ShoppingCart.Category.Item.infoTextModal.descriptionForContract')}</b>
                    </div>
                    <RichEditorField readOnly className="rich-editor-read-only" initialValue={descriptionForContract} />
                  </>
                ) : null}
                {notesForFeeAgreement ? (
                  <>
                    <div>
                      <b>{t('user.ShoppingCart.Category.Item.infoTextModal.notesForFeeAgreement')}</b>
                    </div>
                    <RichEditorField readOnly className="rich-editor-read-only" initialValue={notesForFeeAgreement} />
                  </>
                ) : null}
                {pleaseNote ? (
                  <>
                    <div>
                      <b>{t('user.ShoppingCart.Category.Item.infoTextModal.pleaseNote')}</b>
                    </div>
                    <RichEditorField readOnly className="rich-editor-read-only" initialValue={pleaseNote} />
                  </>
                ) : null}
                {notesToEmployee ? (
                  <>
                    <div>
                      <b>{t('user.ShoppingCart.Category.Item.infoTextModal.notesToEmployee')}</b>
                    </div>
                    <RichEditorField readOnly className="rich-editor-read-only" initialValue={notesToEmployee} />
                  </>
                ) : null}
              </Panel>
            </Collapse>
          ) : null}
        </div>
      </LibraryProvider>
    ),
    width: 800,
  });
};

const ItemCardHeaderPanel = ({
  benefits,
  descriptionForContract,
  infoText,
  name,
  notesForFeeAgreement,
  notesToEmployee,
  pleaseNote,
  subTitle,
  item,
}) => {
  const { t } = useTranslation();
  const isLibrary = useLibraryContext();
  const showInfoValues = useMemo(() => {
    const values = {};
    if (!RichText.isRichTextEmpty(benefits)) values.benefits = benefits;
    if (!RichText.isRichTextEmpty(descriptionForContract)) values.descriptionForContract = descriptionForContract;
    if (!RichText.isRichTextEmpty(infoText)) values.infoText = infoText;
    if (!RichText.isRichTextEmpty(notesForFeeAgreement)) values.notesForFeeAgreement = notesForFeeAgreement;
    if (!RichText.isRichTextEmpty(notesToEmployee)) values.notesToEmployee = notesToEmployee;
    if (!RichText.isRichTextEmpty(pleaseNote)) values.pleaseNote = pleaseNote;
    return values;
  }, [benefits, descriptionForContract, infoText, notesForFeeAgreement, notesToEmployee, pleaseNote]);
  return (
    <div className={classes.itemHeader}>
      <div>
        <div>
          <h3 className={classes.title}>{name}</h3>
          {!isEmpty(showInfoValues) ? (
            <Button
              icon={<FaInfoCircle className={classes.infoIcon} />}
              className={cn(classes.itemButton, 'button-border-none button-box-shadow-none center')}
              ghost
              onClick={() => {
                showInfoTextModal({
                  ...showInfoValues,
                  isLibrary,
                  name,
                  t,
                });
              }}
            />
          ) : null}
        </div>
        <div>{subTitle}</div>
      </div>
      <div className="center">
        <ItemDifferences className={classes.differences} item={item} />
      </div>
    </div>
  );
};

export default memo(ItemCardHeaderPanel, equal);
