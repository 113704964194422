import { Input as InputAntd } from 'antd';
import c from './Input.module.less';

const Input = () => {
  1;

  return <InputAntd className={c.input} />;
};

export default Input;
