/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useMemo, useRef, useState } from 'react';
import { groupBy, maxBy, minBy, sortBy, sumBy } from 'lodash';
import { gql } from '@apollo/client';
import ApolloClient from 'graphql/apollo';
import { Button, Input, Switch, Tooltip } from 'antd';
import { downloadText } from './utils';

const startTracing = ({ isTracingResponse }) =>
  ApolloClient.mutate({
    mutation: gql`
      mutation startDBTracing($isTracingResponse: Boolean) {
        startDBTracing(isTracingResponse: $isTracingResponse)
      }
    `,
    variables: { isTracingResponse },
  });
const stopTracing = () =>
  ApolloClient.mutate({
    mutation: gql`
      mutation stopDBTracing {
        stopDBTracing
      }
    `,
  });

const Toolbar = (props) => {
  const {
    traceResponses,
    setTraceResponses,
    toggleTracing,
    isTracing,
    setDisplayPerConnection,
    displayPerConnection,
    setRawLog,
    zoom,
    setZoom,
    log,
  } = props;
  const fileInputRef = useRef();
  return (
    <div className="margin-5px-children" style={{ display: 'flex', marginRight: 'auto', alignItems: 'center' }}>
      Include query responses
      <Switch
        checked={traceResponses}
        onChange={(checked) => {
          setTraceResponses(checked);
        }}
      />
      <Button style={{ marginRight: 'auto' }} onClick={toggleTracing}>
        {isTracing ? 'Stop' : 'Start'} tracing
      </Button>
      <Button
        style={{ marginRight: 'auto' }}
        onClick={() => {
          setDisplayPerConnection((prev) => !prev);
        }}
      >
        {displayPerConnection ? 'Set waterfall view' : 'Set view per connection'}
      </Button>
      <Button
        style={{ marginRight: 'auto' }}
        onClick={() => {
          downloadText(`db-log-${new Date().toISOString()}.log`, JSON.stringify(log));
        }}
      >
        Save Log
      </Button>
      <input
        onChange={(event) => {
          const reader = new FileReader();
          reader.readAsText(event.target.files[0], 'UTF-8');
          reader.onload = (readerEvent) => {
            setRawLog(JSON.parse(readerEvent.target.result));
          };
        }}
        ref={fileInputRef}
        type="file"
        name="name"
        style={{ display: 'none' }}
      />
      <Button
        style={{ marginRight: 'auto' }}
        onClick={() => {
          fileInputRef.current.click();
        }}
      >
        Load Log
      </Button>
      <div className="margin-5px-children" style={{ display: 'flex', alignItems: 'center' }}>
        <span>Zoom</span>
        {/* eslint-disable-next-line no-unsafe-optional-chaining */}
        <Input type="number" value={+zoom} onChange={(event) => setZoom(+event?.target?.value)} />
      </div>
    </div>
  );
};

const Item = (props) => {
  const { item, displayPerConnection, connectionIds, index, minDate, zoomCoefficient } = props;
  const tooltipOverlay = (
    <div style={{ maxWidth: 800 }}>
      <div>
        {item.type} on {JSON.stringify(item.collection)}
      </div>
      <div /* style={{ whiteSpace: 'pre' }} */>{JSON.stringify(item.data, null, 2)}</div>
    </div>
  );
  return (
    <Tooltip placement="left" trigger={['click']} overlay={tooltipOverlay}>
      <div
        style={{
          background: '#cccccc',
          position: 'absolute',
          top: 24 * (displayPerConnection ? connectionIds.indexOf(item.connectionId) : index),
          left: (item.startDate - minDate) * zoomCoefficient,
          width: (item.endDate - item.startDate) * zoomCoefficient,
          height: 22,
          border: '1px solid black',
          whiteSpace: 'pre',
        }}
        onClick={() => {
          console.log(item);
        }}
      >
        {item.type} on {JSON.stringify(item.collection)} {`${(item.size / 1024).toFixed(1)} KB`}{' '}
        {`${item.endDate - item.startDate} ms`}
        {/* {item.filteredStack?.join(' ')} */}
      </div>
    </Tooltip>
  );
};

export const DBCallsPage = () => {
  const [isTracing, setIsTracing] = useState(false);
  const [rawLog, setRawLog] = useState();
  const [traceResponses, setTraceResponses] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [displayPerConnection, setDisplayPerConnection] = useState(false);

  const log = useMemo(
    () =>
      sortBy(rawLog, 'startDate').map((originalItem) => {
        const item = { ...originalItem };
        item.startDate = new Date(item.startDate);
        item.endDate = new Date(item.endDate);
        [
          ['update', (item) => item.cmd.updates],
          ['find', (item) => item.cmd.filter],
          ['count', (item) => item.cmd.query],
          ['insert', (item) => item.cmd.documents],
          ['aggregate', (item) => item.cmd.pipeline],
          ['findAndModify', (item) => item.cmd.findAndModify],
        ].forEach(([propName, getDataFn]) => {
          if (item.cmd[propName]) {
            item.type = propName;
            item.collection = item.cmd[propName];
            item.data = getDataFn(item);
            item.stack = (() => {
              try {
                return JSON.parse(item?.cmd?.comment)?.synchronousCursorStack;
              } catch (e) {
                return null;
              }
            })()
              ?.replace?.('Error\n', '')
              ?.split('\n')
              ?.map((e) => e.trim());
            item.filteredStack = item.stack?.slice?.(5);
          }
        });
        return item;
      }),
    [rawLog],
  );

  const toggleTracing = () => {
    if (isTracing) stopTracing().then((response) => setRawLog(JSON.parse(response.data.stopDBTracing)));
    else startTracing({ isTracingResponse: traceResponses });
    setIsTracing(!isTracing);
  };
  const minDate = minBy(log, (item) => +item.startDate)?.startDate;
  const maxDate = maxBy(log, (item) => +item.endDate)?.endDate;
  const connectionIds = Object.keys(groupBy(log || {}, 'connectionId')).map((idString) => +idString);
  const zoomCoefficient = zoom > 0 ? zoom : 1 / (-zoom || 1);
  return (
    <div className="container-fluid" style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Toolbar
        {...{
          traceResponses,
          setTraceResponses,
          toggleTracing,
          isTracing,
          setDisplayPerConnection,
          displayPerConnection,
          setRawLog,
          zoom,
          setZoom,
          log,
        }}
      />
      <div>
        {log?.length
          ? `Mongoose used pool of ${connectionIds.length} connections to the db. Recorded ${(
              (maxDate - minDate) /
              1000
            ).toFixed(1)} seconds. Received ${(sumBy(log, (item) => item.size || 0) / 1024).toFixed(
              1,
            )} KB. Click on the item to get detailed info in console and tooltip`
          : null}
      </div>
      <div style={{ flex: 1, position: 'relative', overflow: 'scroll' }}>
        {log?.length ? (
          <>
            {log.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Item key={index} {...{ item, displayPerConnection, connectionIds, index, minDate, zoomCoefficient }} />
            ))}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default DBCallsPage;
