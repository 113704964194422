import { gql } from '@apollo/client';

const userVariableFragment = gql`
  fragment userVariableFragment on Variable {
    _id
    _apolloCacheKey
    label
    name
    value
    type
  }
`;

export default userVariableFragment;
