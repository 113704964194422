import { gql } from '@apollo/client';
import { documentTemplateBlockDescriptorFragment } from 'graphql/fragments';

const adminDocumentTemplateBlocksByIdQuery = gql`
  query adminDocumentTemplateBlocksByIdQuery($documentTemplateId: ID!) {
    documentTemplateById(documentTemplateId: $documentTemplateId) {
      _id
      blocks {
        _id
        name
        props
        descriptor {
          ...documentTemplateBlockDescriptorFragment
        }
      }
    }
  }
  ${documentTemplateBlockDescriptorFragment}
`;

export default adminDocumentTemplateBlocksByIdQuery;
