import { FaFileSignature } from 'react-icons/fa';
import SignDocumentsLayout from '../../../components/layouts/SignDocumentsLayout';
import { Sans30px400, Sans30px600, Sans35px600 } from '../Text/Text';
import { Br100, Br200, Br050, Br150 } from '../Text/Br';
import NextButton from '../NextButton';
import DocumentsList from '../DocumentsList';
import Checkbox from '../Checkbox';
import SignButton from '../SignButton';

const ViewAndSignDocuments = ({ setPage }) => {
  return (
    <SignDocumentsLayout>
      <Sans35px600 icon={<FaFileSignature />}>View and Sign your documents</Sans35px600>
      <Br100 />
      <Sans30px600 mobileTextStyle={{ fontSize: 14 }}>View your documents</Sans30px600>
      <Br100 />
      <Sans30px400 mobileTextStyle={{ fontSize: 14 }}>
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.
      </Sans30px400>
      <Br200 />
      <DocumentsList
        documents={[
          { _id: '1', name: 'Angebot' },
          { _id: '2', name: 'Vergütungs-vereinbarung' },
          { _id: '3', name: 'Vertrag 2' },
          { _id: '4', name: 'Vollmacht für Steuer' },
          { _id: '5', name: 'SEPA Mandat' },
        ]}
      />
      <Br200 />
      <Sans30px400>Please confirm that you have read the documents</Sans30px400>
      <Br050 />
      <Checkbox>
        <Sans30px400>Yes, I confirm that I have read the documents</Sans30px400>
      </Checkbox>
      <Br150 />
      <Sans30px400>Please confirm that you agree to “Widerrufsrecht“ (nur für Einkommensteuer)</Sans30px400>
      <Br050 />
      <Checkbox>
        <Sans30px400>Yes, I confirm that I agree to the „Widerrufsrecht“</Sans30px400>
      </Checkbox>
      <Br200 />
      <SignButton />
      <Br200 />
      <NextButton
        onClick={() => {
          setPage('sepa');
        }}
      >
        Next
      </NextButton>
    </SignDocumentsLayout>
  );
};
export default ViewAndSignDocuments;
