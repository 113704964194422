import { signingContextQuery } from 'graphql/queries';
import useCurrentlyCachedQuery from 'hooks/common/useCurrentlyCachedQuery';
import useSigningGraphqlContext from './useSigningGraphqlContext';

const useSigningContext = () => {
  const context = useSigningGraphqlContext();
  const {
    loading,
    error,
    data: signingContext,
  } = useCurrentlyCachedQuery(
    signingContextQuery,
    {
      fetchPolicy: !context.headers ? 'cache-only' : 'cache-and-network',
      context,
    },
    120,
  );
  if (!context.headers) return { loading: false, error: null, signingContext: null };
  return { loading, error, signingContext: signingContext?.signingContext };
};

export default useSigningContext;
