import { gql } from '@apollo/client';
import { adminPersonFragment } from 'graphql/fragments';

const adminPersonsInTenantQuery = gql`
  query adminPersonsInTenantQuery {
    persons {
      ...adminPersonFragment
    }
  }
  ${adminPersonFragment}
`;

export default adminPersonsInTenantQuery;
