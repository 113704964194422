import { find } from 'lodash';

export const getItemsIds = ({ shoppingCartCategories }) => {
  return shoppingCartCategories.map((category) => category.itemIds).flat();
};
export const getCategoryItems = ({ categories, shoppingCart }) => {
  if (!categories) return [];
  return categories
    .map((category) => {
      const activeDiscount = find(shoppingCart.categories, { _id: category._id })?.discountId;
      return category.items.map((item) => ({
        ...item,
        parentCategory: {
          ...category,
          activeDiscount: activeDiscount ? find(category.discounts, { _id: activeDiscount }) : null,
        },
      }));
    })
    .flat();
};
