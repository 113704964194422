/* eslint-disable no-nested-ternary */
import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import equal from 'fast-deep-equal/es6/react';
import { RichText, numberToLocaleString, StaticService } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { TableMemo } from 'memo';
import Switch from 'components/common/Switch';
import classnames from 'classnames';
import { useCompareToGlobalContext } from 'contexts/CompareToGlobalContext';
import { useGroupComparingData } from 'graphql/hooks/compareToGlobal/utils';
import { useVATContext, useVariablesContext } from '../shoppingCart/context';

const getStaticItemColumns = ({ t, showPrices, showVat, showDigits, vatValue, locale, placeholderObject }) =>
  [
    {
      width: showPrices ? '40%' : '100%',
      title: t('admin.staticItemsFieldModal.nameFieldLabel'),
      dataIndex: 'nameRaw',
      key: 'nameRaw',
      render: RichText.renderRichText,
    },
    showPrices && {
      width: '60%',
      title: t('admin.staticItemsFieldModal.valueFieldLabel'),
      dataIndex: 'valueRaw',
      key: 'valueRaw',
      render: (value) => {
        const staticService = new StaticService({ valueRaw: value });
        return staticService.getValueAsNumberedReadableText(showVat, showDigits, vatValue, locale, placeholderObject);
      },
    },
  ].filter(Boolean);

export const useStaticItemPlaceholderObject = ({ showDigits, showVat }) => {
  const variables = useVariablesContext();
  const vat = useVATContext();
  const locale = useTranslation().i18n.language;
  return useMemo(() => {
    const partialPlaceholderObject = variables.reduce((currentPlaceholderObject, variable) => {
      if (variable.type !== 'pricePerHour') return { ...currentPlaceholderObject, [variable._id]: variable.value };
      return {
        ...currentPlaceholderObject,
        [`net_${variable._id}`]: variable.value,
        [variable._id]: showVat
          ? numberToLocaleString(variable.value * (1 + vat.value), locale, { showDigits })
          : variable.value,
      };
    }, {});
    partialPlaceholderObject.showDigits = showDigits;
    return partialPlaceholderObject;
  }, [showDigits, variables, showVat, vat, locale]);
};

const IntroOutroComponent = memo(({ text, changedStaticItems }) => {
  const isNeedRender = useMemo(
    () => changedStaticItems.length && !RichText.isRichTextEmpty(text),
    [changedStaticItems.length, text],
  );
  if (!isNeedRender) return null;
  return RichText.renderRichText(text);
}, equal);

const rowKeyFunc = (record) => record?._id;

const StaticItems = ({
  staticItems,
  showPrices,
  introForStaticItem,
  outroForStaticItem,
  className,
  showVat,
  showDigits,
}) => {
  const { t, i18n } = useTranslation();
  const { isCompare } = useCompareToGlobalContext();
  const placeholderObject = useStaticItemPlaceholderObject({ showDigits, showVat });
  const { value: vatValue } = useVATContext();

  const [changedStaticItems, newStaticItems] = useGroupComparingData(staticItems);

  const columns = useMemo(
    () =>
      getStaticItemColumns({
        t,
        showPrices,
        showVat,
        showDigits,
        placeholderObject,
        vatValue,
        locale: i18n.language,
      }),
    [t, showPrices, i18n.language, vatValue, showVat, showDigits, placeholderObject],
  );
  const newColumns = useMemo(
    () => getStaticItemColumns({ t, showPrices, isShowCompare: isCompare && newStaticItems.length }),
    [t, showPrices, isCompare, newStaticItems.length],
  );
  const [isShow, setIsShow] = useState(false);
  return (
    <>
      <div className="flex-align-right margin-bottom-16">
        <Switch checked={isShow} onChange={setIsShow} label={t('user.ShoppingCart.Category.showStaticItems')} />
      </div>
      <div className={classnames(className, { 'display-none': !isShow })}>
        <h2>{t('viewer.ShoppingCartView.BookedServices.staticItems')}</h2>
        <IntroOutroComponent changedStaticItems={changedStaticItems} text={introForStaticItem} />
        <TableMemo
          className="shopping-cart-table"
          rowKey={rowKeyFunc}
          pagination={false}
          dataSource={changedStaticItems}
          columns={columns}
        />
        {isCompare && newStaticItems.length ? (
          <>
            <h2>{t('user.CompareToGlobal.new.staticItems')}</h2>
            <TableMemo
              className="shopping-cart-table"
              rowKey={rowKeyFunc}
              pagination={false}
              dataSource={newStaticItems}
              columns={newColumns}
            />
          </>
        ) : null}
        <IntroOutroComponent changedStaticItems={changedStaticItems} text={outroForStaticItem} />
      </div>
    </>
  );
};
export default memo(StaticItems, equal);
