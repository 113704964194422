import { gql } from '@apollo/client';

const remindAllSigneesMutation = gql`
  mutation remindAllSigneesMutation(
    $shoppingCartId: ID!
    $emailTemplateId: ID!
    $onlyRemindUnsigned: Boolean!
    $signeeType: SelectableSigneeType
    $emailProps: EmailPropsInput
  ) {
    remindAllSignees(
      shoppingCartId: $shoppingCartId
      emailTemplateId: $emailTemplateId
      onlyRemindUnsigned: $onlyRemindUnsigned
      signeeType: $signeeType
      emailProps: $emailProps
    )
  }
`;

export default remindAllSigneesMutation;
