import { useQuery } from '@apollo/client';
import { Statistic, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import Card from 'components/common/Card';
import SpinForCard from 'components/common/SpinForCard';
import { superAdminDigitalSignatureMetricsQuery } from 'graphql/queries';

/**
 * Card component with current digital signature statistics globally
 * @returns {JSX.Element} StatisticCard component to show current digital signature statistics
 * @component
 */
const StatisticCard = () => {
  const { t } = useTranslation();
  const { data: metricsQueryResult, loading } = useQuery(superAdminDigitalSignatureMetricsQuery);
  const metrics = metricsQueryResult?.getDigitalSignatureMetrics;

  return (
    <Card title={t('superAdmin.ManageDigitalSignature.statistics.title')}>
      {loading && !metrics ? <SpinForCard /> : null}
      {metrics ? (
        <Row>
          <Col span={8}>
            <Statistic
              title={t('superAdmin.ManageDigitalSignature.statistics.numberOfTotalTenants')}
              value={metrics.numberOfTotalTenants}
            />
            <Statistic
              title={t('superAdmin.ManageDigitalSignature.statistics.currentlyOpenDigitalSignatureProcesses')}
              value={metrics.currentlyOpenDigitalSignatureProcesses}
            />
            <Statistic
              title={t('superAdmin.ManageDigitalSignature.statistics.openedDigitalSignatureProcessesThisMonth')}
              value={metrics.openedDigitalSignatureProcessesThisMonth}
            />
            <Statistic
              title={t('superAdmin.ManageDigitalSignature.statistics.finishedSigningProcesses')}
              value={metrics.finishedSigningProcesses}
            />
          </Col>
          <Col span={8}>
            <Statistic
              title={t('superAdmin.ManageDigitalSignature.statistics.numberOfTenantWithEnabledDigitalSignature')}
              value={metrics.numberOfTenantWithEnabledDigitalSignature}
            />
            <Statistic
              title={t('superAdmin.ManageDigitalSignature.statistics.currentlyOpenSigningProcesses')}
              value={metrics.currentlyOpenSigningProcesses}
            />
            <Statistic
              title={t('superAdmin.ManageDigitalSignature.statistics.openedSigningProcessesThisMonth')}
              value={metrics.openedSigningProcessesThisMonth}
            />
            <Statistic
              title={t('superAdmin.ManageDigitalSignature.statistics.finishedDigitalSignatureProcessesThisMonth')}
              value={metrics.finishedDigitalSignatureProcessesThisMonth}
            />
          </Col>
          <Col span={8}>
            <Statistic
              title={t('superAdmin.ManageDigitalSignature.statistics.openedDigitalSignatureProcesses')}
              value={metrics.openedDigitalSignatureProcesses}
            />
            <Statistic
              title={t('superAdmin.ManageDigitalSignature.statistics.openedSigningProcesses')}
              value={metrics.openedSigningProcesses}
            />
            <Statistic
              title={t('superAdmin.ManageDigitalSignature.statistics.finishedDigitalSignatureProcesses')}
              value={metrics.finishedDigitalSignatureProcesses}
            />
            <Statistic
              title={t('superAdmin.ManageDigitalSignature.statistics.finishedSigningProcessesThisMonth')}
              value={metrics.finishedSigningProcessesThisMonth}
            />
          </Col>
        </Row>
      ) : null}
    </Card>
  );
};

export default StatisticCard;
