import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Row, Col } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import cn from 'classnames';
import OnboardingStepTemplate from '../components/OnboardingStepTemplate';
import c from './PricingMode.module.less';
import { useOnboardingContext } from '../OnboardingContext';

const options = [
  {
    value: 'standard',
    i18nTitle: 'onboarding.pricingMode.standardModeTitle',
    i18nDescription: 'onboarding.pricingMode.standardModeDescription',
  },
  {
    value: 'fixedMonthly',
    i18nTitle: 'onboarding.pricingMode.fixedMonthlyModeTitle',
    i18nDescription: 'onboarding.pricingMode.fixedMonthlyModeDescription',
  },
];
export default function PricingMode() {
  const { onSubmit, formValuesRef } = useOnboardingContext();
  const { t } = useTranslation();
  const [pricingMode, setPricingMode] = React.useState(formValuesRef.current.pricingMode || options[0].value);
  return (
    <OnboardingStepTemplate
      title={t('onboarding.pricingMode.title')}
      description={t('onboarding.pricingMode.textAboveVideo')}
      videoUrl={t('onboarding.pricingMode.videoUrl')}
      videoCaption={t('onboarding.pricingMode.videoCaption')}
      onNext={() => onSubmit({ pricingMode })}
    >
      <Row gutter={10}>
        {options.map((option) => (
          <Col md={24 / options.length} key={option.value}>
            <CardOption
              onClick={() => setPricingMode(option.value)}
              isActive={option.value === pricingMode}
              title={t(option.i18nTitle)}
              description={t(option.i18nDescription)}
            />
          </Col>
        ))}
      </Row>
    </OnboardingStepTemplate>
  );
}

function CardOption({ onClick, title, description, isActive }) {
  return (
    <Card hoverable onClick={onClick} className={cn(isActive && c.cardOptionActive, c.cardOption)}>
      <h2>
        {title} {isActive ? <CheckCircleOutlined /> : null}
      </h2>
      {description}
    </Card>
  );
}
