import { FaArrowRight } from 'react-icons/fa';
import Button from './Button';
import c from './NextButton.module.less';
import { Sans35px600 } from './Text/Text';

const NextButton = ({ onClick }) => {
  return (
    <Button className={c.button} onClick={onClick} icon={<FaArrowRight />}>
      <Sans35px600 style={{ justifyContent: 'center' }} textStyle={{ color: 'white', fontWeight: 800 }}>
        Next
      </Sans35px600>
    </Button>
  );
};
export default NextButton;
