/*
  Global app settings
  Every process.env variable should be listed in this settings object
  
  General rule: no process.env references should be present in any other file!

  TODO: Add validation for critical env variables
*/
export default {
  isDevelopment: process.env.NODE_ENV !== 'production', // on localhost this is true
  isProduction: process.env.NODE_ENV === 'production', // on staging or prod this is true
  debug: process.env.REACT_APP_DEBUG ? JSON.parse(process.env.REACT_APP_DEBUG) : false,
  graphqlServerUrl: process.env.REACT_APP_GRAPHQL_SERVER_URL,
  graphqlHttpTimeout: process.env.REACT_APP_GRAPHQL_HTTP_TIMEOUT
    ? Number.parseInt(process.env.REACT_APP_GRAPHQL_HTTP_TIMEOUT, 10)
    : undefined,
  graphqlHttpRetries: process.env.REACT_APP_GRAPHQL_HTTP_RETRIES
    ? Number.parseInt(process.env.REACT_APP_GRAPHQL_HTTP_RETRIES, 10)
    : 3,
  outOfServiceWebhookEnabled: process.env.REACT_APP_OUT_OF_SERVICE_WEBHOOK_ENABLED === 'true',
  outOfServiceWebhookRoute: process.env.REACT_APP_OUT_OF_SERVICE_WEBHOOK_ROUTE,
  isStagingServer: process.env.REACT_APP_ENVIRONMENT === 'staging',
  isProductionServer: process.env.REACT_APP_ENVIRONMENT === 'production',
  hideEmailButtons: process.env.REACT_APP_HIDE_EMAIL_BUTTONS,
};
