import { gql } from '@apollo/client';
import { userContactFragment } from 'graphql/fragments';

const userShoppingCartContactsQuery = gql`
  query userShoppingCartContactsQuery($_id: ID!) {
    userShoppingCart(_id: $_id) {
      _id
      contacts {
        ...userContactFragment
      }
    }
  }
  ${userContactFragment}
`;

export default userShoppingCartContactsQuery;
