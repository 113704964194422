import MoreInfoWidget from 'components/common/MoreInfoWidget';
import { useTranslation } from 'react-i18next';
import classes from './DkimHelperWidget.module.less';

/**
 * This is a DKIM configuration guide displayed on the email settings page
 * @returns {JSX.Element}
 * @component
 */
export const DkimHelperWidget = () => {
  const { t } = useTranslation();

  return (
    <MoreInfoWidget
      buttonText={t('admin.Setting.Email.dkimHelperWidget.howUseButton')}
      title={t('admin.Setting.Email.dkimHelperWidget.modalInfo.title')}
      helpText={<DkimHelperText />}
      videoCaption={t('admin.Setting.Email.dkimHelperWidget.modalInfo.videoCaption')}
      videoUrl={t('admin.Setting.Email.dkimHelperWidget.modalInfo.videoUrl')}
      imageUrl={t('admin.Setting.Email.dkimHelperWidget.modalInfo.imageUrl')}
      buttonClassName={classes.helperText}
    />
  );
};

/**
 * This is the DKIM guide's text
 * @returns {JSX.Element}
 * @component
 */
const DkimHelperText = () => {
  const { t } = useTranslation();

  return (
    <span>
      {t('admin.Setting.Email.dkimHelperWidget.modalInfo.helpText')}
      <ul>
        <li>
          <a
            href={t('admin.Setting.Email.dkimHelperWidget.modalInfo.stratoGuide.link')}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('admin.Setting.Email.dkimHelperWidget.modalInfo.stratoGuide.title')}
          </a>
        </li>
        <li>
          <a
            href={t('admin.Setting.Email.dkimHelperWidget.modalInfo.ionosGuide.link')}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('admin.Setting.Email.dkimHelperWidget.modalInfo.ionosGuide.title')}
          </a>
        </li>
      </ul>
    </span>
  );
};
