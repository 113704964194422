import useCurrentUser from 'hooks/auth/useCurrentUser';
import { useTranslation } from 'react-i18next';
import OnboardingStepTemplate from '../components/OnboardingStepTemplate';
import { useOnboardingContext } from '../OnboardingContext';

export default function WelcomeStep() {
  const [user] = useCurrentUser();
  const { t } = useTranslation();
  const { onSubmit } = useOnboardingContext();
  return (
    <OnboardingStepTemplate
      title={t('onboarding.welcome.title')}
      description={t('onboarding.welcome.textAboveVideo', { userFirstName: user?.profile.firstName })}
      videoUrl={t('onboarding.welcome.videoUrl')}
      videoCaption={t('onboarding.welcome.videoCaption')}
      onNext={() => onSubmit({})}
    />
  );
}
