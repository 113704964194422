import useSigningShoppingCart from 'hooks/signing/useSigningShoppingCart';
import { SigningProcessShoppingCartContextProvider } from 'contexts/SigningProcessShoppingCartContext';
import SigningContextErrorHandler from '../SigningContextErrorHandler';
import CategoryOverview from './CategoryOverview';
import PriceCard from './PriceCard';

// import classes from './ServiceOverview.module.less';

/**
 * ServiceOverview component for the service overview step, which shows all active categories and the pricing card for signing process
 * @returns {JSX.Element} component for signing overview step
 */
const ServiceOverview = () => {
  const { shoppingCart, error } = useSigningShoppingCart();

  const { categories } = shoppingCart || { categories: [] };

  const categoryComponents = categories.map((category) => (
    <CategoryOverview category={category} key={category.texts.name} />
  ));

  return (
    <SigningContextErrorHandler error={error}>
      <SigningProcessShoppingCartContextProvider value={shoppingCart}>
        <div>
          {categoryComponents}
          <PriceCard />
        </div>
      </SigningProcessShoppingCartContextProvider>
    </SigningContextErrorHandler>
  );
};

export default ServiceOverview;
