import Document from './Document';
import c from './DocumentsList.module.less';

const DocumentsList = ({ documents }) => {
  return (
    <div className={c.documentsList}>
      {documents.map((document) => (
        <Document key={document._id} document={document} />
      ))}
    </div>
  );
};
export default DocumentsList;
