import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * Component, which represents button bar for digital signature revoke modal with two buttons
 * @param {Object} inputParameter
 * @param {Function} onYes - Function for on yes clicked event
 * @param {Function} onNo - Function for on no clicked event
 * @returns {Object} ButtonBar component for digital signature revoke modal
 * @component
 */
const ButtonBar = ({ onOk, onCancel }) => {
  const { t: translation } = useTranslation();
  return (
    <div>
      <Button type="default" onClick={onCancel}>
        {translation('viewer.ShoppingCartView.ChangeExpirationDateOnSigningLinkModal.buttons.cancel')}
      </Button>
      <Button type="primary" onClick={onOk}>
        {translation('viewer.ShoppingCartView.ChangeExpirationDateOnSigningLinkModal.buttons.ok')}
      </Button>
    </div>
  );
};

export default ButtonBar;
