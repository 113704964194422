import Switch from 'components/common/Switch';

const AutoSignForTenantSigneesEnabledSwitch = ({ t }) => {
  return (
    <Switch
      name="autoSignForTenantSigneesEnabled"
      label={t('admin.Setting.DigitalSignaturePreferences.generalSettings.autoSignForTenantSigneesEnabled.label')}
      tooltip={t('admin.Setting.DigitalSignaturePreferences.generalSettings.autoSignForTenantSigneesEnabled.tooltip')}
      defaultChildren
    />
  );
};

export default AutoSignForTenantSigneesEnabledSwitch;
