import EmailTemplateSelect from 'components/common/EmailTemplateSelect';
import { useTranslation } from 'react-i18next';

/**
 * NotifySigneesAboutSignedBySigneeTemplateSelect component is the email template select for the notify signees about signed by signee function (needs formik context)
 * @returns {JSX.Element} email template select for the additional notification settings card in the digital signature preferences page
 * @component
 */
const NotifySigneesAboutSignedBySigneeTemplateSelect = () => {
  const { t } = useTranslation();
  return (
    <EmailTemplateSelect
      fieldName="notifySigneesAboutSignedBySigneeTemplate"
      label={t(
        'admin.Setting.DigitalSignaturePreferences.additionalNotificationSettings.notifySigneesAboutSignedBySigneeTemplate.label',
      )}
      tooltip={t(
        'admin.Setting.DigitalSignaturePreferences.additionalNotificationSettings.notifySigneesAboutSignedBySigneeTemplate.tooltip',
      )}
      placeholder={t(
        'admin.Setting.DigitalSignaturePreferences.additionalNotificationSettings.notifySigneesAboutSignedBySigneeTemplate.placeholder',
      )}
    />
  );
};

export default NotifySigneesAboutSignedBySigneeTemplateSelect;
