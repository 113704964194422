import SigningInfoPagesLayout from 'components/layouts/SigningInfoPagesLayout';
import { SIGNING_CSS_PATH_KEY } from 'hooks/common/useLoadTheme';
import useResetSigningContext from 'hooks/signing/useResetSigningContext';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import routePaths from 'router/route-paths';
import classes from './InfoPages.module.less';

const AlreadySigned = () => {
  const { t: translation } = useTranslation();
  const { resetSigningContext, resetSigningTheme } = useResetSigningContext();
  const navigate = useNavigate();
  useEffect(() => {
    if (!window.localStorage.getItem(SIGNING_CSS_PATH_KEY)) {
      navigate(routePaths.signingUnvalid);
    }
  });

  resetSigningContext();

  setTimeout(() => resetSigningTheme(), 5000);

  return (
    <SigningInfoPagesLayout contentAreaClassName={classes.contentArea}>
      <div className={classes.headingWrapper}>
        <h1 className={classes.infoPageHeading}>{translation('signing.infoPages.alreadySigned.heading')}</h1>
      </div>
    </SigningInfoPagesLayout>
  );
};

export default AlreadySigned;
