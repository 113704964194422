import FormItem from 'components/common/FormItem';
import { RichEditorField } from 'components/common/RichEditor';
/**
 * SepaIntroText is a React functional component that renders a form item with a rich text editor.
 * It is specifically designed to allow users to edit the introduction text for SEPA (Single Euro Payments Area).
 *
 * @component
 * @param {Object} props - The properties passed to this component.
 * @param {function} props.t - The translation function from i18next, used for internationalization.
 * @returns {ReactElement} The rendered component.
 *
 * @example
 * <SepaIntroText t={translationFunction} />
 */
export const SepaIntroText = ({ t }) => {
  return (
    <FormItem
      label={t('admin.Setting.DigitalSignaturePreferences.sepa.introductionText.label')}
      tooltip={t('admin.Setting.DigitalSignaturePreferences.sepa.introductionText.tooltip')}
      name="sepaIntroductionText"
    >
      <RichEditorField
        name="sepaIntroductionText"
        modifyPlaceholderSchema={(option) => {
          const tenantCompany = option.find((o) => o.name === 'tenantCompany');
          return [
            { ...tenantCompany, fields: tenantCompany.fields.filter((field) => field.name === 'tenantCompanyName') },
          ];
        }}
      />
    </FormItem>
  );
};
