import { setGraphqlErrors } from 'components/common/ErrorComponent';
import apollo from 'graphql/apollo';
import { updatePersonMutation } from 'graphql/mutations';
import i18n from 'i18n';

const updatePerson = ({ _id, modifier }) =>
  apollo.mutate({ mutation: updatePersonMutation, variables: { _id, modifier } }).catch((error) => {
    throw setGraphqlErrors({
      error,
      errorResolver: { Duplicated: ['name', i18n.t('admin.addCategoryModal.duplicatedErrorMessage')] },
    });
  });
export default updatePerson;
