import Card from 'components/common/Card';
import equal from 'fast-deep-equal/es6/react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentTable } from './DocumentTable';

/**
 * Renders a card containing a table of documents to be printed for a shopping cart
 * @param {string} title - The title of the card
 * @param {string} icon - The icon to display on the card
 * @param {string} shoppingCartId - The ID of the shopping cart to display documents for
 * @param {boolean} displayIfEmptyCard - Whether to display the card even if there are no documents to printá
 * @param {ReactNode} customBottomCell - A custom cell to display at the bottom of the table
 * @param {string} fetchPolicy - The fetch policy to use when fetching the documents
 * @returns {JSX.Element} The rendered DocumentsCard component
 * @component
 */
export const DocumentsCard = memo(
  ({ title, icon, shoppingCartId, displayIfEmptyCard, customBottomCell = null, fetchPolicy = 'cache-first' }) => {
    const { t } = useTranslation();
    return (
      <Card icon={icon} title={title ?? t('user.PrintShoppingCartPage.downloadDocuments')}>
        <DocumentTable
          shoppingCartId={shoppingCartId}
          displayIfEmptyCard={displayIfEmptyCard}
          fetchPolicy={fetchPolicy}
          customBottomCell={customBottomCell}
        />
      </Card>
    );
  },

  equal,
);
