import { memo, useCallback, useMemo } from 'react';
import equal from 'fast-deep-equal';
import { Alert, Drawer } from 'antd';
import { FcCancel, FcFlashOn, FcPlus } from 'react-icons/fc';
import { ComparisonTypes } from 'constants/shoppingCart';
import { TableMemo } from 'memo';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';

const icons = {
  [ComparisonTypes.DELETED]: <FcCancel className="font-size-1-5" />,
  [ComparisonTypes.NEW]: <FcPlus className="font-size-1-5" />,
  [ComparisonTypes.CHANGED]: <FcFlashOn className="font-size-1-5" />,
};

const columns = ({ t }) => [
  {
    width: '50%',
    title: t('user.CompareToGlobal.changed.drawer.independent'),
    key: 'independent',
    render: ({ label, render }) => {
      return (
        <>
          {label ? <div>{label}</div> : null}
          {render ?? t('user.CompareToGlobal.changed.drawer.notSet')}
        </>
      );
    },
  },
  {
    width: '50%',
    title: t('user.CompareToGlobal.changed.drawer.global'),
    key: 'global',
    render: ({ label, globalRender }) => {
      return (
        <>
          {label ? <div>{label}</div> : null}
          {globalRender ?? t('user.CompareToGlobal.changed.drawer.notSet')}
        </>
      );
    },
  },
];

const getRenderedValue = (data, dataIndex) => (dataIndex ? get(data, dataIndex) : data);
const renderValue = (renderedValue, render) => (typeof render === 'function' ? render(renderedValue) : renderedValue);

const ChangedContent = ({ data: _data, rows, title }) => {
  const { t } = useTranslation();
  const data = useMemo(() => {
    const newData = rows
      ?.map(({ label, render, dataIndex, shouldRenderFn }) => {
        if (typeof shouldRenderFn === 'function' && !shouldRenderFn({ values: _data })) return null;
        return {
          label,
          render: renderValue(getRenderedValue(_data, dataIndex), render),
          globalRender: renderValue(getRenderedValue(_data.global, dataIndex), render),
        };
      })
      .filter(Boolean);
    return newData;
  }, [_data, rows]);
  const changedColumns = useMemo(() => columns({ t }), [t]);
  return (
    <>
      <Alert
        type="warning"
        showIcon
        icon={icons[ComparisonTypes.CHANGED]}
        description={title ?? t('user.CompareToGlobal.changed.drawer.label')}
      />
      {rows ? (
        <TableMemo
          pagination={false}
          dataSource={data}
          columns={changedColumns}
          rowClassName="ant-table-row-divider"
          className="ant-table-header-remove-background ant-table-remove-all-borders"
          scroll={{
            x: 600,
          }}
        />
      ) : null}
    </>
  );
};

const ChangedDifferencesDrawer = ({ setIsOpen, width, data, rows, title }) => {
  const { t } = useTranslation();
  const onClose = useCallback(() => setIsOpen(false), [setIsOpen]);

  return (
    <Drawer
      title={t('user.CompareToGlobal.changed.drawer.label')}
      contentWrapperStyle={width ? { width } : { maxWidth: '100%', width: 1000 }}
      visible
      onClose={onClose}
    >
      <ChangedContent data={data} rows={rows} title={title} />
    </Drawer>
  );
};
export default memo(ChangedDifferencesDrawer, equal);
