import classNames from 'classnames';
import c from './Panel.module.less';

const Panel = ({ title, children }) => {
  1;

  return (
    <div className={classNames(c.panel, 'panel')}>
      {title && <div className={c.title}>{title}</div>}
      <div>{children}</div>
    </div>
  );
};

export default Panel;
