import { useTranslation } from 'react-i18next';
import CustomInfoAlert from '../CustomInfoAlert';

/**
 * DiscountsAlert component is an alert for the pricing card in the service overview step to list all active discounts
 * @param {Object} inputParameters - Input parameters of the component
 * @returns {JSX.Element} alert with a list of all active discounts
 * @component
 */
const DiscountsAlert = ({ discountsByCategories }) => {
  const { t: translation } = useTranslation();
  if (!discountsByCategories || discountsByCategories.length === 0) return null;
  const discountsList = discountsByCategories.map(({ discount, name }) =>
    translation('signing.serviceOverviewStep.priceCard.discounts.discountItem', { discount, name }),
  );
  const discountsItems = discountsList.map((text) => <li key={text}>{text}</li>);
  return (
    <CustomInfoAlert
      message={translation('signing.serviceOverviewStep.priceCard.discounts.message')}
      description={<ul>{discountsItems}</ul>}
    />
  );
};

export default DiscountsAlert;
