import { adminYourLicenseQuery } from 'graphql/queries';
import { useCachedQuery } from 'graphql/utils';
import { grabFirstGQLDataResult } from 'utils/helpers';

export const useYourLicense = () => {
  const { data: _data, loading } = useCachedQuery(adminYourLicenseQuery);
  const data = grabFirstGQLDataResult(_data);
  return { loading, data };
};

export default useYourLicense;
