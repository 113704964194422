import { gql } from '@apollo/client';

const getMyEmailQuery = gql`
  query getMyEmailQuery {
    me {
      _id
      email
    }
  }
`;

export default getMyEmailQuery;
