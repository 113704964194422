import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import equal from 'fast-deep-equal';
import MoreInfoWidget from 'components/common/MoreInfoWidget';

const EditShoppingCartHelperWidgetMemo = memo(function EditShoppingCartHelperWidget() {
  const { t } = useTranslation();
  return (
    <MoreInfoWidget
      buttonText={t('editShoppingCartModal.HelperWidget.howUseButton')}
      title={t('editShoppingCartModal.HelperWidget.modalInfo.title')}
      helpText={t('editShoppingCartModal.HelperWidget.modalInfo.helpText')}
      videoCaption={t('editShoppingCartModal.HelperWidget.modalInfo.videoCaption')}
      videoUrl={t('editShoppingCartModal.HelperWidget.modalInfo.videoUrl')}
      imageUrl={t('editShoppingCartModal.HelperWidget.modalInfo.imageUrl')}
    />
  );
}, equal);

export default EditShoppingCartHelperWidgetMemo;
