import { PlusOutlined } from '@ant-design/icons';
import { useCachedQuery } from 'graphql/utils';
import { Alert, Button, Card, Skeleton } from 'antd';
import { AlertFromFormik } from 'components/common/ErrorComponent';
import FormItem from 'components/common/FormItem';
import I18nFormik from 'components/common/I18nFormik';
import SaveChangesButton from 'components/common/SaveChangesButton';
import PageContainer from 'components/layout/PageContainer';
import statusTypes from 'constants/statusTypes';
import equal from 'fast-deep-equal/es6/react';
import { Form, Select } from 'formik-antd';
import { createStatus, updateStatusIsDefault } from 'graphql/methods';
import { userGetStatusesQuery } from 'graphql/queries';
import i18n from 'i18n';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getMessageFromGraphQLError, grabFirstGQLDataResult } from 'utils/helpers';
import inputConfirmModal from 'utils/inputConfirmModal';
import toast from 'utils/toast';
import ManageStatusesHelperWidget from './ManageStatusesHelperWidget';
import StatusForm, { statusValidationSchema } from './StatusForm';
import StatusList from './StatusList';

const skeletons = [...new Array(2)].map((_, i) => i + 1);

const createStatusModal = () =>
  inputConfirmModal({
    okText: i18n.t('admin.ManageShoppingCartStatus.addStatusModal.ok'),
    cancelText: i18n.t('admin.ManageShoppingCartStatus.addStatusModal.cancel'),
    formContent: () => <StatusForm />,
    fields: [],
    headerText: i18n.t('admin.ManageShoppingCartStatus.addStatusModal.title'),
    onSubmit: (props) => createStatus({ ...props }).then(toast.successDefault),
    value: { name: '', type: statusTypes.shoppingCart },
    validationSchema: statusValidationSchema,
    errorResolver: { Duplicated: ['name', i18n.t('common.duplicatedErrorMessage', { name: 'Name' })] },
    width: '600px',
  });

const SetDefaultStatus = ({ statuses }) => {
  const { t } = useTranslation();
  const initialValues = useMemo(
    () => ({
      statusId: statuses.find((status) => status.isDefault)?._id,
    }),
    [statuses],
  );
  return (
    <Card title={t('admin.ManageShoppingCartStatus.defaultStatusTitle')}>
      <I18nFormik
        initialValues={initialValues}
        onSubmit={({ statusId }) => {
          updateStatusIsDefault({
            _id: statusId,
            type: statusTypes.shoppingCart,
            isDefault: true,
          }).then(toast.successDefault);
        }}
      >
        <Form layout="vertical">
          <AlertFromFormik />
          <FormItem name="statusId" label="">
            <Select name="statusId">
              {statuses.map(({ _id, name }) => (
                <Select.Option key={_id} value={_id}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
          <SaveChangesButton initialValues={initialValues} />
        </Form>
      </I18nFormik>
    </Card>
  );
};
const SetDefaultStatusMemo = memo(SetDefaultStatus, equal);

function ManageShoppingCartStatus() {
  const type = statusTypes.shoppingCart;
  const { data, loading, error } = useCachedQuery(userGetStatusesQuery, {
    variables: { type },
    fetchPolicy: 'cache-and-network',
  });
  const statuses = useMemo(() => grabFirstGQLDataResult(data), [data]);
  return (
    <>
      {error ? <Alert type="error" message={getMessageFromGraphQLError(error)} /> : null}
      {!data && loading ? (
        skeletons.map((k) => <Skeleton title loading active key={k} />)
      ) : (
        <div>
          <StatusList statuses={statuses} />
          <div className="margin-bottom-16" />
          <SetDefaultStatusMemo statuses={statuses} />
        </div>
      )}
    </>
  );
}
const ManageShoppingCartStatusMemo = memo(ManageShoppingCartStatus, equal);

function ManageShoppingCartStatusContainer() {
  const { t } = useTranslation();
  return (
    <PageContainer
      title={t('admin.ManageShoppingCartStatus.title')}
      right={
        <div className="ant-modal-confirm-btns">
          <Button icon={<PlusOutlined />} onClick={createStatusModal} type="primary">
            {t('admin.ManageShoppingCartStatus.addStatus')}
          </Button>
        </div>
      }
      left={<ManageStatusesHelperWidget />}
    >
      <ManageShoppingCartStatusMemo />
    </PageContainer>
  );
}

export default memo(ManageShoppingCartStatusContainer, equal);
