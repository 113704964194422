/* eslint-disable react/no-danger */
import { Button, Collapse } from 'antd';
import EmptyBox from 'components/common/EmptyBox';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import { TableMemo } from 'memo';
import { ImportOutlined } from '@ant-design/icons';
import classes from './CollapsibleCommonCategory.module.less';
import HeaderCollapse from './components/HeaderCollapse/HeaderCollapse';
import { copyCategoryItemModal } from './ItemsCopyModal';
import { ImportedAlert } from './itemModal/components';
import { PricingFormula } from './ItemsContainer';
import { isCategoryItemComplex } from './utils';

const { Panel } = Collapse;

const columns = ({ isOnboarding }) =>
  [
    {
      width: '40%',
      title: i18n.t('admin.itemTable.columns.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      width: '40%',
      title: i18n.t('admin.itemTable.columns.pricingFormula'),
      render: (e) => (
        <PricingFormula
          calculationMode={e.calculationMode}
          pricingFormula={e.pricingFormulaExtended.formatted}
          inputFields={e.inputFields}
        />
      ),
      key: 'pricingFormula',
    },
    {
      width: '20%',
      title: i18n.t('admin.itemTable.columns.paymentInterval'),
      render: (e) => i18n.t(`common.Item.paymentIntervalValue.${e.paymentInterval}`),
      key: 'paymentInterval',
    },
    !isOnboarding && {
      className: 'action-column-2',
      key: 'actions',
      render: ({ _id, categoryId, inputFields, name, pricingFormulaExtended }) => (
        <Button
          size="small"
          className={classes.import}
          icon={<ImportOutlined />}
          onClick={() =>
            copyCategoryItemModal({
              _id,
              categoryId,
              isLibrary: true,
              isImport: true,
              isComplex: isCategoryItemComplex({ pricingFormulaExtended, inputFields }),
              name,
            })
          }
        >
          {i18n.t('admin.importCategoryPresetsModal.import')}
        </Button>
      ),
    },
  ].filter(Boolean);

const ItemsContainer = ({ items, isOnboarding }) => {
  const { t } = useTranslation();
  const itemColumns = useMemo(() => columns({ isOnboarding }), [isOnboarding]);
  return (
    <div className={classes.itemsContainer}>
      <TableMemo
        locale={{ emptyText: <EmptyBox label={t('admin.CatalogueConfiguration.emptyItemListMessage')} /> }}
        rowKey={(record) => record?._id}
        pagination={false}
        dataSource={items}
        columns={itemColumns}
        className="pointer-not-last-cell"
      />
    </div>
  );
};

function CollapsibleCommonCategory({ category, isOnboarding }) {
  const { _id, name, isNew, guidanceText, guidanceVideoId } = category;
  return (
    <Collapse
      defaultActiveKey={isNew ? [_id] : undefined}
      className={`site-collapse-custom-collapse ${classes.collapse}`}
    >
      <Panel forceRender header={<HeaderCollapse name={name} />} className={classes.siteCollapseCustomPanel}>
        <ImportedAlert type="category" guidanceText={guidanceText} guidanceVideoId={guidanceVideoId} uuid={_id} />
        <ItemsContainer _id={_id} items={category.items} isOnboarding={isOnboarding} />
      </Panel>
    </Collapse>
  );
}

export default memo(CollapsibleCommonCategory);
