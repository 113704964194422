/* eslint-disable no-underscore-dangle */
import React from 'react';
import { useMutation, useApolloClient } from '@apollo/client';
import first from 'lodash/first';
import { loginAsSigneeMutation } from 'graphql/mutations';
import { signingContextQuery } from 'graphql/queries';

export const SIGNING_ACCESS_TOKEN_KEY = 'signing-access-token';

const useLoginSignee = () => {
  const [loginAsSignee, { loading }] = useMutation(loginAsSigneeMutation);
  const client = useApolloClient();
  const loginSignee = React.useCallback(
    async (variables) => {
      const res = await loginAsSignee({ variables });
      const resultData = first(Object.values(res.data));
      localStorage.setItem(SIGNING_ACCESS_TOKEN_KEY, resultData.authToken);
      client.writeQuery({
        query: signingContextQuery,
        data: { signingContext: { ...resultData, shoppingCart: null } },
      });
      return resultData;
    },
    [loginAsSignee, client],
  );

  return [loginSignee, loading];
};

export default useLoginSignee;
