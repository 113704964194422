import FormItem from 'components/common/FormItem';
import { RichEditorField } from 'components/common/RichEditor';

/**
 * SepaMandateTextPrivate is a React functional component designed for users to edit the mandate text
 * that applies to private entities for SEPA.
 *
 * @component
 * @param {Object} props - The properties passed to this component.
 * @param {function} props.t - The translation function from i18next, used for internationalization.
 * @returns {ReactElement} The rendered component.
 *
 * @example
 * <SepaMandateTextPrivate t={translationFunction} />
 */
export const SepaMandateTextPrivate = ({ t }) => {
  return (
    <FormItem
      label={t('admin.Setting.DigitalSignaturePreferences.sepa.mandatePrivate.label')}
      tooltip={t('admin.Setting.DigitalSignaturePreferences.sepa.mandatePrivate.tooltip')}
      name="sepaMandatePrivateText"
    >
      <RichEditorField
        name="sepaMandatePrivateText"
        modifyPlaceholderSchema={(option) => {
          const tenantCompany = option.find((o) => o.name === 'tenantCompany');
          return [
            {
              ...tenantCompany,
              fields: tenantCompany.fields.filter((field) =>
                ['tenantCompanyCreditorIdentNr', 'tenantCompanyName'].includes(field.name),
              ),
            },
          ];
        }}
      />
    </FormItem>
  );
};
