import apollo from 'graphql/apollo';
import { gql } from '@apollo/client';
import { documentTemplateBlockDescriptorFragment } from 'graphql/fragments';

export const documentTemplateBlocksFragment = gql`
  fragment documentTemplateBlocksFragment on DocumentTemplate {
    blocks {
      _id
      name
      props
      descriptor {
        ...documentTemplateBlockDescriptorFragment
      }
    }
  }
  ${documentTemplateBlockDescriptorFragment}
`;

export default function moveDocumentBlock({ documentTemplateId, blocks }) {
  apollo.writeFragment({
    id: `DocumentTemplate___${documentTemplateId}`,
    data: { blocks },
    fragment: documentTemplateBlocksFragment,
    fragmentName: 'documentTemplateBlocksFragment',
  });
}
