import { useTranslation } from 'react-i18next';
import concatClassNames from 'utils/classNames';
import Spin from 'components/common/Spin';
import classes from './OnDocumentLoading.module.less';

/**
 * OnLoadingComponent for pdf viewer, which indicates that the document is on loading
 * @param {Object} inputParameter - Input parameter of component
 * @param {String} inputParameter.className - Classname for loading component
 * @returns {JSX.Element} Loading component for pdf viewer
 * @component
 */
const OnLoadingComponent = ({ className }) => {
  const { t } = useTranslation();
  return (
    <div className={concatClassNames(classes.OnLoadingComponent, className)}>
      <Spin className={concatClassNames(classes.spin, 'OnLoadingComponent_Spin')} fontSize={100} />
      <span>{t('PdfDrawer.onLoadingDocument')}</span>
    </div>
  );
};

export default OnLoadingComponent;
