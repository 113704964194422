import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import PageContainer from 'components/layout/PageContainer';
import inputConfirmModal from 'utils/inputConfirmModal';
import equal from 'fast-deep-equal/es6/react';
import { memo } from 'react';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import createPersonMethod from 'graphql/methods/admin/person/createPerson';
import PersonsList from './PersonsList';
import { PersonSchema } from './PersonSchema';
import ManagePersonsHelperWidget from './ManagePersonsHelperWidget';

const getPersonFields = () => [
  {
    label: i18n.t('admin.ManagePersons.fields.name.label'),
    name: 'name',
    description: i18n.t('admin.ManagePersons.fields.name.label'),
  },
  {
    label: i18n.t('admin.ManagePersons.fields.position.label'),
    name: 'position',
    description: i18n.t('admin.ManagePersons.fields.position.label'),
  },
  // {
  //   label: i18n.t('admin.ManagePersons.fields.signature'),
  //   name: 'signature',
  //   component: (props) => {
  //     return <FormikImage {...props} uploadLabel={i18n.t('admin.ManagePersons.uploadLabel')} showImage={false} />;
  //   },
  // },
];

export const createPerson = () =>
  inputConfirmModal({
    okText: i18n.t('admin.ManagePersons.addPersonModal.ok'),
    cancelText: i18n.t('admin.ManagePersons.addPersonModal.cancel'),
    fields: getPersonFields(),
    headerText: i18n.t('admin.ManagePersons.addPersonModal.title'),
    onSubmit: (props) => createPersonMethod({ ...props }),
    value: { name: '', position: '' },
    validationSchema: PersonSchema,
    errorResolver: { Duplicated: ['name', i18n.t('admin.inputFieldModal.duplicatedErrorMessage')] },
    width: '800px',
  });

function ManagePersonsLayout() {
  const { t } = useTranslation();
  return (
    <PageContainer
      title={t('admin.ManagePersons.title')}
      right={
        <div className="ant-modal-confirm-btns">
          <Button icon={<PlusOutlined />} onClick={createPerson} type="primary">
            {t('admin.ManagePersons.addPerson')}
          </Button>
        </div>
      }
      left={<ManagePersonsHelperWidget />}
    >
      <PersonsList />
    </PageContainer>
  );
}

export default memo(ManagePersonsLayout, equal);
