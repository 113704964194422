import { Row, Col, Alert } from 'antd';
import { generatePath } from 'react-router-dom';
import { CreationMode } from 'constants/shoppingCart';
import routePaths from 'router/route-paths';
import i18n from 'i18n';
import SelectionModal from 'components/common/Modal/SelectionModal';
import { useThemeRichEditor } from 'graphql/hooks';
import { ThemeProvider } from 'styled-components';
import { FaCog, FaHistory } from 'react-icons/fa';
import classes from './EditShoppingCartModal.module.less';
import EditShoppingCartHelperWidget from './EditShoppingCartHelperWidget';

const options = ({ _id, navigate, hideModal, migrated }) => [
  {
    text: i18n.t('editShoppingCartModal.independentOption'),
    handleClick: () => {
      hideModal();
      navigate(generatePath(routePaths.routerShoppingCartRevision, { id: _id, mode: CreationMode.INDEPENDENT }));
    },
    className: classes.option1,
    icon: (
      <span className="anticon">
        <FaHistory size={19} />
      </span>
    ),
    disabled: migrated,
  },
  {
    text: i18n.t('editShoppingCartModal.globalOption'),
    handleClick: () => {
      hideModal();
      navigate(generatePath(routePaths.routerShoppingCartRevision, { id: _id, mode: CreationMode.GLOBAL }));
    },
    className: classes.option2,
    icon: (
      <span className="anticon">
        <FaCog size={19} />
      </span>
    ),
  },
];

const explanationBlock = (migrated) => {
  return (
    <div>
      {migrated ? (
        <Row className={classes.whiteSpaceBetween}>
          <Alert
            type="info"
            className="alert-info"
            description={i18n.t('editShoppingCartModal.migrationInfoText')}
            showIcon
          />
        </Row>
      ) : null}
      <Row className={classes.whiteSpaceBetween}>
        <Col span={1}>
          <FaHistory className={classes.icons} />
        </Col>
        <Col span={23}>
          <b>{i18n.t('editShoppingCartModal.labelIndependent')}</b>
          {': '}
          {i18n.t('editShoppingCartModal.explanationIndependent')}
        </Col>
      </Row>
      <Row>
        <Col span={1}>
          <FaCog className={classes.icons} />
        </Col>
        <Col span={23}>
          <b>{i18n.t('editShoppingCartModal.labelGlobal')}</b>: {i18n.t('editShoppingCartModal.explanationGlobal')}
        </Col>
      </Row>
    </div>
  );
};

const EditShoppingCartModal = ({ _id, navigate, isShowEditModal, hideModal, migrated }) => {
  const { theme } = useThemeRichEditor();

  return (
    <ThemeProvider theme={theme}>
      <SelectionModal
        title={i18n.t('editShoppingCartModal.title')}
        subtitle={<EditShoppingCartHelperWidget />}
        options={options({ _id, navigate, hideModal, migrated })}
        visible={isShowEditModal}
        className={classes.selectionModal}
        classNameContent={classes.selectionModalContent}
        handleCancel={hideModal}
      >
        {explanationBlock(migrated)}
      </SelectionModal>
    </ThemeProvider>
  );
};

export default EditShoppingCartModal;
