import apollo from 'graphql/apollo';
import { updateItemMutation } from 'graphql/mutations';

const updateCategoryItem = async ({
  _id,
  benefits,
  calculationMode,
  highlightBindingness,
  companyTypeIds,
  descriptionForContract,
  graduatedScaleMode,
  guidanceText,
  guidanceVideoId,
  infoText,
  internalNote,
  minPrice,
  name,
  notesToEmployee,
  notesForFeeAgreement,
  paymentInterval,
  pleaseNote,
  pricingFormula,
  roundPriceId,
  scalePricingFormulaTitle,
  scaleTitle,
  scales,
  scalesEnabled,
  scalesScaleUnitName,
  scalesUnitName,
  subTitle,
}) =>
  apollo.mutate({
    mutation: updateItemMutation,
    variables: {
      _id,
      modifier: {
        benefits,
        calculationMode,
        highlightBindingness,
        companyTypeIds,
        descriptionForContract,
        graduatedScaleMode,
        guidanceText,
        guidanceVideoId,
        infoText,
        internalNote,
        minPrice,
        name,
        notesToEmployee,
        notesForFeeAgreement,
        paymentInterval,
        pleaseNote,
        pricingFormula,
        roundPriceId: roundPriceId || null,
        scalePricingFormulaTitle,
        scaleTitle,
        scales,
        scalesEnabled,
        scalesScaleUnitName,
        scalesUnitName,
        subTitle,
      },
    },
  });

export default updateCategoryItem;
