import { gql } from '@apollo/client';

const generateSigneeSigningPasswordMutation = gql`
  mutation generateSigneeSigningPasswordMutation(
    $shoppingCartId: ID!
    $signeeRelatedObjectId: ID!
    $signeeEmail: String!
  ) {
    generateSigneeSigningPassword(
      shoppingCartId: $shoppingCartId
      signeeRelatedObjectId: $signeeRelatedObjectId
      signeeEmail: $signeeEmail
    )
  }
`;

export default generateSigneeSigningPasswordMutation;
