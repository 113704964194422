import { Button, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { importDefaultTemplatesForDigitalSignature } from 'graphql/methods';

/**
 * ReimportFooter component to show the button combination for the case that all default templates are imported
 * @param {Object} inputParameters - Input parameters of the component
 * @param {Boolean} inputParameters.shouldSetSelectionValuesToImportedTemplates - Should the imported templates be set as selection the settings
 * @param {Function} inputParameters.onCloseModal - Function to close the modal
 * @returns {JSX.Element} button bar for import modal for the case if all default templates are imported to tenant instance
 * @component
 */
const ReimportFooter = ({ shouldSetSelectionValuesToImportedTemplates, onCloseModal }) => {
  const { t } = useTranslation();

  const onImportWithReimport = () => {
    onCloseModal();
    importDefaultTemplatesForDigitalSignature(true, shouldSetSelectionValuesToImportedTemplates);
  };

  const onImportWithoutReimport = () => {
    onCloseModal();
    importDefaultTemplatesForDigitalSignature(false, true);
  };

  return (
    <Space>
      <Button onClick={onCloseModal}>
        {t('admin.Setting.DigitalSignaturePreferences.importDefaultTemplatesModal.cancelButton')}
      </Button>
      <Button onClick={onImportWithReimport} type={shouldSetSelectionValuesToImportedTemplates ? 'default' : 'primary'}>
        {t('admin.Setting.DigitalSignaturePreferences.importDefaultTemplatesModal.importAllTemplatesButton')}
      </Button>
      {shouldSetSelectionValuesToImportedTemplates ? (
        <Button type="primary" onClick={onImportWithoutReimport}>
          {t('admin.Setting.DigitalSignaturePreferences.importDefaultTemplatesModal.setSettingsButton')}
        </Button>
      ) : null}
    </Space>
  );
};

export default ReimportFooter;
