import { useMemo } from 'react';
import { useCachedQuery } from 'graphql/utils';
import { adminCanCreateCompanyQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';

const useCanCreateCompany = (types) => {
  const { data, loading } = useCachedQuery(adminCanCreateCompanyQuery, {
    variables: { types },
    fetchPolicy: 'cache-and-network',
  });
  const result = useMemo(() => {
    return grabFirstGQLDataResult(data) || {};
  }, [data]);
  return { data: result, loading };
};

export default useCanCreateCompany;
