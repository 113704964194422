import { gql } from '@apollo/client';
import { currentUserFragment } from 'graphql/fragments';

const superAdminAllUsersQuery = gql`
  query superAdminAllUsersQuery {
    allUsers {
      ...currentUserFragment
      isTenantOnboardingCompleted
    }
  }
  ${currentUserFragment}
`;

export default superAdminAllUsersQuery;
