import { gql } from '@apollo/client';
import { adminThemeFragment } from 'graphql/fragments';

const resetThemeMutation = gql`
  mutation resetThemeMutation {
    resetTheme {
      ...adminThemeFragment
    }
  }
  ${adminThemeFragment}
`;

export default resetThemeMutation;
