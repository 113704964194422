const MINUTES_IN_MILLISEC = 60 * 1000;
const HOURS_IN_MILLISEC = 60 * MINUTES_IN_MILLISEC;
const DAYS_IN_MILLISEC = 24 * HOURS_IN_MILLISEC;

/**
 * Formats date to available time to the current time
 * @param {Date} dateToFormat - Date, which should be formatted
 * @param {Function} i18nTranslation - I18n translation function for the outputted text
 * @returns {String} formatted time as text, which says how long time it needs to reach the date
 */
export const formatDateToAvailableTime = (dateToFormat, i18nTranslation) => {
  if (!dateToFormat || !(dateToFormat instanceof Date) || !i18nTranslation) return '';
  const currentDate = new Date();
  const leftTime = dateToFormat - currentDate;
  if (leftTime <= 0) {
    return i18nTranslation('availableTime.noTimeLeft');
  }
  if (leftTime > DAYS_IN_MILLISEC) {
    const daysLeft = Math.floor(leftTime / DAYS_IN_MILLISEC);
    return i18nTranslation('availableTime.daysLeft', { daysLeft, count: daysLeft });
  }
  if (leftTime > HOURS_IN_MILLISEC) {
    const hoursLeft = Math.floor(leftTime / HOURS_IN_MILLISEC);
    return i18nTranslation('availableTime.hoursLeft', { hoursLeft, count: hoursLeft });
  }
  if (leftTime > MINUTES_IN_MILLISEC) {
    const minutesLeft = Math.floor(leftTime / MINUTES_IN_MILLISEC);
    return i18nTranslation('availableTime.minutesLeft', { minutesLeft, count: minutesLeft });
  }
  return i18nTranslation('availableTime.noTimeLeft');
};
