import { gql } from '@apollo/client';

const monthlyPaymentDecisionMutation = gql`
  mutation monthlyPaymentDecisionMutation($decision: String!) {
    setMonthlyPaymentDecision(decision: $decision) {
      success
    }
  }
`;

export default monthlyPaymentDecisionMutation;
