import apollo from 'graphql/apollo';
import { updateVariableMutation } from 'graphql/mutations';
import { richEditorSchemaQuery } from 'graphql/queries';

const updateVariable = ({ _id, modifier }) =>
  apollo.mutate({
    mutation: updateVariableMutation,
    variables: { _id, modifier },
    refetchQueries: [{ query: richEditorSchemaQuery, variables: { isLibrary: false } }],
  });
export default updateVariable;
