import { Checkbox, Form } from 'formik-antd';
import { memo, useCallback, useMemo } from 'react';
import { AlertFromFormik } from 'components/common/ErrorComponent';
import FormItem from 'components/common/FormItem';
import SaveChangesButton from 'components/common/SaveChangesButton';
import I18nFormik from 'components/common/I18nFormik';
import equal from 'fast-deep-equal/es6/react';
import { pickBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { adminCompanyTypesQuery } from 'graphql/queries';
import { useCachedQuery } from 'graphql/utils';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { Skeleton, Checkbox as CheckboxAntd } from 'antd';
import { updateCategory, updateCategoryItem } from 'graphql/methods';
import { useFormikContext } from 'formik';
import MoreInfoWidget from 'components/common/MoreInfoWidget';

const skeletons = [...new Array(2)].map((_, i) => i + 1);

const CheckAll = () => {
  const { t } = useTranslation();
  const formik = useFormikContext();
  const { values, resetForm } = formik;
  const countCheckedTypes = useMemo(() => Object.keys(pickBy(values, Boolean)).length, [values]);
  const countFields = useMemo(() => Object.keys(values).length, [values]);

  const onCheck = useCallback(() => {
    const newValues = {};
    if (countCheckedTypes === countFields)
      Object.keys(values).forEach((key) => {
        newValues[key] = false;
      });
    else
      Object.keys(values).forEach((key) => {
        newValues[key] = true;
      });
    resetForm({
      values: newValues,
    });
  }, [countCheckedTypes, countFields, resetForm, values]);
  return (
    <CheckboxAntd
      indeterminate={countCheckedTypes !== 0 && countCheckedTypes !== countFields}
      checked={countCheckedTypes}
      onChange={onCheck}
      style={{ marginBottom: '24px' }}
    >
      {t(`common.ConditionalVisibility.${countCheckedTypes === countFields ? 'uncheckAll' : 'checkAll'}`)}
    </CheckboxAntd>
  );
};
const CheckAllMemo = memo(CheckAll, equal);

export const ConditionalVisibilityHelperWidget = ({ parentType }) => {
  const { t } = useTranslation();
  return (
    <MoreInfoWidget
      buttonText={t(
        `admin.${
          parentType === 'categoryItem' ? 'itemModal' : 'CatalogueConfiguration'
        }.ConditionalVisibilityHelperWidget.howUseButton`,
      )}
      title={t(
        `admin.${
          parentType === 'categoryItem' ? 'itemModal' : 'CatalogueConfiguration'
        }.ConditionalVisibilityHelperWidget.modalInfo.title`,
      )}
      helpText={t(
        `admin.${
          parentType === 'categoryItem' ? 'itemModal' : 'CatalogueConfiguration'
        }.ConditionalVisibilityHelperWidget.modalInfo.helpText`,
      )}
      videoCaption={t(
        `admin.${
          parentType === 'categoryItem' ? 'itemModal' : 'CatalogueConfiguration'
        }.ConditionalVisibilityHelperWidget.modalInfo.videoCaption`,
      )}
      videoUrl={t(
        `admin.${
          parentType === 'categoryItem' ? 'itemModal' : 'CatalogueConfiguration'
        }.ConditionalVisibilityHelperWidget.modalInfo.videoUrl`,
      )}
      imageUrl={t(
        `admin.${
          parentType === 'categoryItem' ? 'itemModal' : 'CatalogueConfiguration'
        }.ConditionalVisibilityHelperWidget.modalInfo.imageUrl`,
      )}
    />
  );
};
const ConditionalVisibilityHelperWidgetMemo = memo(ConditionalVisibilityHelperWidget, equal);

const ConditionalVisibilityTab = ({ parentId, parentType = 'category', companyTypeIds = [] }) => {
  const { data, loading } = useCachedQuery(adminCompanyTypesQuery);
  const companyTypes = useMemo(() => grabFirstGQLDataResult(data), [data]);
  const { t } = useTranslation();
  const initialValues = useMemo(() => {
    if (!companyTypes) return {};
    const defaultCompanyTypes = companyTypes.reduce((acc, { _id }) => {
      acc[_id] = false;
      return acc;
    }, {});
    companyTypeIds.forEach((e) => {
      defaultCompanyTypes[e] = true;
    });
    return defaultCompanyTypes;
  }, [companyTypeIds, companyTypes]);

  return (
    <>
      <ConditionalVisibilityHelperWidgetMemo parentType={parentType} />
      <I18nFormik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => {
          if (parentType === 'category')
            updateCategory({
              _id: parentId,
              modifier: {
                companyTypeIds: Object.keys(pickBy(values, Boolean)),
              },
            });
          else if (parentType === 'categoryItem')
            updateCategoryItem({
              _id: parentId,
              companyTypeIds: Object.keys(pickBy(values, Boolean)),
            });
        }}
      >
        <Form layout="vertical">
          <AlertFromFormik />
          <h4>
            {t('admin.CatalogueConfiguration.ConfigureCategoryProperties.properties.conditionalVisibility.bodyTitle')}
          </h4>
          <CheckAllMemo />
          {skeletons.map((k) => (
            <Skeleton title loading={!data && loading} active key={k} />
          ))}
          {companyTypes?.map(({ label, _id }) => (
            <FormItem name={_id} key={_id}>
              <Checkbox name={_id}>{t(label)}</Checkbox>
            </FormItem>
          ))}
          <SaveChangesButton initialValues={initialValues} />
        </Form>
      </I18nFormik>
    </>
  );
};
export default memo(ConditionalVisibilityTab, equal);
