import { Tooltip } from 'antd';
import { Fragment } from 'react';
import { Transforms, Editor, Element as SlateElement } from 'slate';
import { useFocused, useSelected } from 'slate-react';
import { useMentionContext } from '../MentionContext';

export const isMentionActive = (editor) => {
  const [mention] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'mention',
  });
  return mention;
};

export const withMentions = (editor) => {
  const { isInline, isVoid, normalizeNode } = editor;

  // eslint-disable-next-line no-param-reassign
  editor.isInline = (element) => {
    return element.type === 'mention' ? true : isInline(element);
  };

  // eslint-disable-next-line no-param-reassign
  editor.isVoid = (element) => {
    return element.type === 'mention' && !(element.mentionType === 'formula_function' && element._id === 'text')
      ? true
      : isVoid(element);
  };

  // eslint-disable-next-line no-param-reassign
  editor.normalizeNode = ([node, path]) => {
    if (
      node.type === 'mention' &&
      node.mentionType === 'formula_function' &&
      node._id === 'text' &&
      node.children.length <= 1 &&
      !node.children[0]?.text
    ) {
      Transforms.delete(editor, {
        at: path,
      });
    }
    return normalizeNode([node, path]);
  };

  return editor;
};

export const MentionElement = ({ attributes, children, element }) => {
  const mentionContext = useMentionContext();
  const selected = useSelected();
  const focused = useFocused();
  if (!mentionContext) {
    console.error('no mention context');
    return null;
  }
  const { renderMention, getMentionStyle, getMentionComponent } = mentionContext;
  const MentionComponent = getMentionComponent?.({ element, attributes, selected, focused }) || 'span';
  const mentionStyle = getMentionStyle?.({ element, attributes, selected, focused });
  const Container = element?.tooltip ? Tooltip : Fragment;
  const renderValue = renderMention({ element, attributes, selected, focused });
  const contentEditable = !!(element.mentionType === 'formula_function' && element._id === 'text');
  return (
    <Container {...(element?.tooltip && { title: element.tooltip })}>
      <MentionComponent
        {...attributes}
        {...(contentEditable ? {} : { contentEditable })}
        style={{
          padding: '3px 3px 2px',
          margin: '0 1px',
          verticalAlign: 'baseline',
          display: 'inline-block',
          borderRadius: '4px',
          backgroundColor: '#eee',
          fontSize: '0.9em',
          fontStyle: element.italic ? 'italic' : 'normal',
          textDecoration: element.underline ? 'underline' : '',
          fontWeight: element.bold ? 'bold' : 'normal',
          boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
          ...mentionStyle,
        }}
      >
        {contentEditable ? null : renderValue}
        {children}
      </MentionComponent>
    </Container>
  );
};
