import { useEffect, useRef, useState } from 'react';
import { Row, Skeleton, message } from 'antd';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import {
  generateSigneeSigningPasswordMutation,
  resendSigneeSigningPasswordMutation,
  sendDigitalSignatureReminderMailMutation,
  generateSigneeSigningLinkMutation,
} from 'graphql/mutations';
import { userShoppingCartViewQuery, userSigneesSigningLinkInfosQuery } from 'graphql/queries';
import { useTranslation } from 'react-i18next';
import useFasterPreferences from 'hooks/user/useFasterPreferences';
import ChangeExpirationDateOnSignignLinkModal from 'components/user/ChangeExpirationDateOnSigningLinkModal/ChangeExpirationDateOnSigningLinkModal';
import Button from '../Button';
import PasswordAndLinkManagement from './PasswordAndLinkManagement';
import classes from './SigneeManagement.module.less';

/**
 * ButtonRow component for signee management in digital signature card in shopping cart view
 * @param {Object} inputParameter - Input parameter
 * @param {Function} inputParameter.translation - I18n translation function
 * @param {Object} inputParameter.signee - Informations about the signee
 * @param {String} inputParameter.shoppingCartId - Database id of related shopping cart object
 * @param {React.Ref} inputParameter.resetPasswordAndLinkManagementReference - Reference with reset function
 * @param {Object} inputParameter.signingLinkInfos - Informations about the signees signing link
 * @param {Boolean} inputParameter.signingLinkInfos.usePassword - Is password for the signing process required
 * @param {Date} inputParameter.signingLinkInfos.expirationDate - Expiration date of the signing link
 * @returns {JSX.Element} ButtonRow component for signee management
 * @component
 */
const ButtonRow = ({
  translation,
  shoppingCartId,
  signee,
  resetPasswordAndLinkManagementReference,
  signingLinkInfos,
}) => {
  const { preferences } = useFasterPreferences();
  const [showChangeExpirationDateModal, setShowChangeExpirationDateModal] = useState(false);
  const apolloClient = useApolloClient();
  const [generateSigneeSigningPassword] = useMutation(generateSigneeSigningPasswordMutation, {
    variables: {
      shoppingCartId,
      signeeEmail: signee.email,
      signeeRelatedObjectId: signee.id,
    },
  });
  const [generateSigneeSigningLink] = useMutation(generateSigneeSigningLinkMutation, {
    variables: {
      shoppingCartId,
      signeeEmail: signee.email,
      signeeRelatedObjectId: signee.id,
    },
  });
  const [resendSigneeSigningPassword] = useMutation(resendSigneeSigningPasswordMutation, {
    variables: {
      shoppingCartId,
      signeeEmail: signee.email,
      signeeRelatedObjectId: signee.id,
    },
  });
  const [remindSignee] = useMutation(sendDigitalSignatureReminderMailMutation, {
    variables: {
      shoppingCartId,
      signeeEmail: signee.email,
      signeeRelatedObjectId: signee.id,
    },
  });
  const showSuccessMessage = (successMessage) => {
    message.success(successMessage, 3);
  };

  const handleNotFoundError = (specificMessage) => {
    message.error(
      translation('viewer.ShoppingCartView.DigitalSignature.signeeManagement.notFoundError', { specificMessage }),
      3,
    );
    apolloClient.refetchQueries({
      include: [{ query: userShoppingCartViewQuery, variables: { _id: shoppingCartId } }],
    });
  };

  const handleCommonErrors = (error, i18nPrefix) => {
    const { message: errorMessage } = error;
    if (errorMessage.includes('notFound')) {
      handleNotFoundError(translation(`${i18nPrefix}.notFound`), translation, shoppingCartId, apolloClient);
      return false;
    }
    return errorMessage;
  };

  const onGenerateNewPassword = () => {
    generateSigneeSigningPassword()
      .then(() => {
        showSuccessMessage(
          translation('viewer.ShoppingCartView.DigitalSignature.signeeManagement.generateNewPassword.success'),
        );
      })
      .catch((error) => {
        const notHandledError = handleCommonErrors(
          error,
          'viewer.ShoppingCartView.DigitalSignature.signeeManagement.generateNewPassword',
        );
        if (notHandledError) {
          if (notHandledError.includes('Use password not enabled'))
            message.error(
              'viewer.ShoppingCartView.DigitalSignature.signeeManagement.generateNewPassword.usePasswordNotEnabledError',
              3,
            );
          else message.error('Unknown error', 3);
        }
      });
    if (resetPasswordAndLinkManagementReference.current.reset) resetPasswordAndLinkManagementReference.current.reset();
  };
  const onGenerateNewLink = () => {
    generateSigneeSigningLink()
      .then(() => {
        showSuccessMessage(
          translation('viewer.ShoppingCartView.DigitalSignature.signeeManagement.generateNewLink.success'),
        );
      })
      .catch((error) => {
        const notHandledError = handleCommonErrors(
          error,
          'viewer.ShoppingCartView.DigitalSignature.signeeManagement.generateNewLink',
        );
        if (notHandledError) message.error('Unknown error', 3);
      });
    if (resetPasswordAndLinkManagementReference.current.reset) resetPasswordAndLinkManagementReference.current.reset();
  };
  const onExpandLinkValidity = () => {
    setShowChangeExpirationDateModal(true);
  };
  const onResendPassword = () => {
    resendSigneeSigningPassword()
      .then(() => {
        showSuccessMessage(
          translation('viewer.ShoppingCartView.DigitalSignature.signeeManagement.resendPassword.success'),
        );
      })
      .catch((error) => {
        const notHandledError = handleCommonErrors(
          error,
          'viewer.ShoppingCartView.DigitalSignature.signeeManagement.resendPassword',
        );
        if (notHandledError) {
          if (notHandledError.includes('Use password not enabled'))
            message.error(
              'viewer.ShoppingCartView.DigitalSignature.signeeManagement.resendPassword.usePasswordNotEnabledError',
              3,
            );
          else message.error('Unknown error', 3);
        }
      });
  };
  const onSendRemainderMail = () => {
    remindSignee()
      .then(() => {
        showSuccessMessage(
          translation('viewer.ShoppingCartView.DigitalSignature.signeeManagement.sendRemainderMail.success'),
        );
      })
      .catch((error) => {
        const notHandledError = handleCommonErrors(
          error,
          'viewer.ShoppingCartView.DigitalSignature.signeeManagement.sendRemainderMail',
        );
        if (notHandledError) message.error('Unknown error', 3);
      });
  };
  const onCloseChangeExpirationDateModal = () => {
    setShowChangeExpirationDateModal(false);
  };

  const showPasswordOptions = signingLinkInfos ? signingLinkInfos.usePassword : false;

  return (
    <Row className={classes.buttonRow}>
      {showPasswordOptions ? (
        <Button onClick={onGenerateNewPassword}>
          {translation('viewer.ShoppingCartView.DigitalSignature.signeeManagement.generateNewPassword.button')}
        </Button>
      ) : null}
      <Button onClick={onGenerateNewLink}>
        {translation('viewer.ShoppingCartView.DigitalSignature.signeeManagement.generateNewLink.button')}
      </Button>
      <Button onClick={onExpandLinkValidity}>
        {translation('viewer.ShoppingCartView.DigitalSignature.signeeManagement.expandLinkValidity.button')}
      </Button>
      {showPasswordOptions && preferences.digitalSignaturePreferences?.sendPasswordToSigneesTemplate ? (
        <Button onClick={onResendPassword}>
          {translation('viewer.ShoppingCartView.DigitalSignature.signeeManagement.resendPassword.button')}
        </Button>
      ) : null}
      {preferences.digitalSignaturePreferences?.remindSigneeAboutDigitalSignatureProcessTemplate && (
        <Button onClick={onSendRemainderMail}>
          {translation('viewer.ShoppingCartView.DigitalSignature.signeeManagement.sendRemainderMail.button')}
        </Button>
      )}
      {showChangeExpirationDateModal && (
        <ChangeExpirationDateOnSignignLinkModal
          shoppingCartId={shoppingCartId}
          signee={signee}
          successMessage={translation(
            'viewer.ShoppingCartView.DigitalSignature.signeeManagement.expandLinkValidity.button',
          )}
          onCloseModal={onCloseChangeExpirationDateModal}
        />
      )}
    </Row>
  );
};

/**
 * SigneeManagement component for signee management in digital signature card for shopping cart view
 * @param {Object} inputParameter - Input parameter
 * @param {Object} inputParameter.signee - Informations about the signee
 * @param {String} inputParameter.shoppingCartId - Database id of related shopping cart object
 * @returns {JSX.Element} SigneeManagement component for signee management
 * @component
 */
const SigneeManagement = ({ signee, shoppingCartId }) => {
  const { t: translation } = useTranslation();
  const resetPasswordAndLinkManagementReference = useRef();
  const apolloClient = useApolloClient();
  const {
    data: signingLinkInfos,
    loading,
    error,
  } = useQuery(userSigneesSigningLinkInfosQuery, {
    variables: {
      shoppingCartId,
      signeeEmail: signee.email,
      signeeRelatedObjectId: signee.id,
    },
  });

  useEffect(() => {
    if (error) {
      apolloClient.refetchQueries({
        include: [{ query: userShoppingCartViewQuery, variables: { _id: shoppingCartId } }],
      });
    }
  }, [error, apolloClient, shoppingCartId]);

  return (
    <div className={classes.signeeManagement}>
      {!signingLinkInfos && loading ? <Skeleton title loading active /> : null}
      {signingLinkInfos ? (
        <>
          <PasswordAndLinkManagement
            translation={translation}
            signee={signee}
            shoppingCartId={shoppingCartId}
            resetReference={resetPasswordAndLinkManagementReference}
            signingLinkInfos={signingLinkInfos.getSigneesLinkInfo}
          />
          <ButtonRow
            translation={translation}
            signee={signee}
            shoppingCartId={shoppingCartId}
            resetPasswordAndLinkManagementReference={resetPasswordAndLinkManagementReference}
            signingLinkInfos={signingLinkInfos.getSigneesLinkInfo}
          />
        </>
      ) : null}
    </div>
  );
};

export default SigneeManagement;
