import { useMemo, useState } from 'react';
import Hello from './pages/Hello';
import ReviewServices from './pages/ReviewServices';
import ConfirmIdentity from './pages/ConfirmIdentity';
import SignDocumentsContext from './SignDocumentsContext';
import AdditionalServices from './pages/AdditionalServices';
import ViewAndSignDocuments from './pages/ViewAndSignDocuments';
import SEPA from './pages/SEPA';
import Done from './pages/Done';

const pageMap = {
  hello: Hello,
  confirmIdentity: ConfirmIdentity,
  reviewServices: ReviewServices,
  additionalServices: AdditionalServices,
  viewAndSignDocuments: ViewAndSignDocuments,
  sepa: SEPA,
  done: Done,
};
const SignDocuments = () => {
  const [page, setPage] = useState('hello');
  const signDocumentsContextValue = useMemo(() => ({ page, setPage }), [page]);
  const Component = pageMap[page];
  return (
    <SignDocumentsContext.Provider value={signDocumentsContextValue}>
      <Component setPage={setPage} />
    </SignDocumentsContext.Provider>
  );
};
export default SignDocuments;
