import EmailTemplateSelect from 'components/common/EmailTemplateSelect';

const SendPasswordToSigneesTemplateSelect = ({ t }) => {
  return (
    <EmailTemplateSelect
      fieldName="sendPasswordToSigneesTemplate"
      label={t('admin.Setting.DigitalSignaturePreferences.generalSettings.sendPasswordToSigneesTemplate.label')}
      tooltip={t('admin.Setting.DigitalSignaturePreferences.generalSettings.sendPasswordToSigneesTemplate.tooltip')}
      placeholder={t(
        'admin.Setting.DigitalSignaturePreferences.generalSettings.sendPasswordToSigneesTemplate.placeholder',
      )}
    />
  );
};

export default SendPasswordToSigneesTemplateSelect;
