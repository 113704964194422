import { gql } from '@apollo/client';

const adminVariableFragment = gql`
  fragment adminVariableFragment on Variable {
    _id
    name
    label
    value
    type
    description
    isInternal
  }
`;

export default adminVariableFragment;
