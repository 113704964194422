import classes from './SubHeading.module.less';

/**
 * SubHeading component for displaying a subheading to structure the page content
 * @param title {String} - The title of the subheading
 * @param marginTop {String} - The margin top of the subheading
 * @param paddingBottom {String} - The padding bottom of the subheading
 * @returns {JSX.Element} - The SubHeading component
 * @component
 */
const SubHeading = ({ title, marginTop = '8px', paddingBottom = '10px' }) => {
  return (
    <div className={classes.subHeading} style={{ marginTop, paddingBottom }}>
      <h3 className={classes.subHeadingText}>{title}</h3>
    </div>
  );
};

export default SubHeading;
