import { useTranslation } from 'react-i18next';
import Switch from 'components/common/Switch';

/**
 * NotifyTenantSigneesAboutOpenBySigneeSwitch component is the switch for the additional notification settings card to enable or disable the notification of tenant signees about that a signee opened its signing process (Needs formik context)
 * @returns {JSX.Element} switch component for additional notification settings card in digital signature preferences page
 * @component
 */
const NotifyTenantSigneesAboutOpenBySigneeSwitch = () => {
  const { t } = useTranslation();
  return (
    <Switch
      name="notifyTenantSigneesAboutOpenBySignee"
      label={t(
        'admin.Setting.DigitalSignaturePreferences.additionalNotificationSettings.notifyTenantSigneesAboutOpenBySignee.label',
      )}
      tooltip={t(
        'admin.Setting.DigitalSignaturePreferences.additionalNotificationSettings.notifyTenantSigneesAboutOpenBySignee.tooltip',
      )}
      defaultChildren
    />
  );
};

export default NotifyTenantSigneesAboutOpenBySigneeSwitch;
