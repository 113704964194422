import React from 'react';
import { Steps, Card, Row, Col, Divider, Grid, Progress } from 'antd';
import { useTranslation } from 'react-i18next';
import theme from 'config/theme';
import c from './Onboarding.module.less';
import { useOnboardingContext } from './OnboardingContext';

const { Step } = Steps;

export default function Onboarding({ children, currentIndex }) {
  const { t } = useTranslation();
  const { steps } = useOnboardingContext();

  const screens = Grid.useBreakpoint();
  const isDesktop = screens.md;

  return (
    <Card className={c.card} style={{ flex: '1 0' }}>
      <Row gutter={10} wrap={isDesktop !== true} align={!isDesktop ? 'center' : null}>
        <Col flex="none">
          <Steps current={currentIndex} direction="vertical">
            {steps.map((item) => (
              <Step key={item.titleI18nKey} title={t(item.titleI18nKey)} />
            ))}
          </Steps>
        </Col>
        {isDesktop ? (
          <Col flex="none">
            <Divider type="vertical" style={{ height: '100%' }} />
          </Col>
        ) : null}
        <Col xs={24} flex={isDesktop ? 'auto' : null}>
          {!isDesktop ? <Divider /> : null}
          {!isDesktop ? (
            <Progress
              strokeColor={theme.primaryColor}
              percent={(currentIndex / (steps.length - 1)) * 100}
              showInfo={false}
            />
          ) : null}
          {children}
        </Col>
      </Row>
    </Card>
  );
}
