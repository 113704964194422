import { ActiveComparisonTypes } from 'constants/shoppingCart';
import { useCompareToGlobalContext } from 'contexts/CompareToGlobalContext';
import equal from 'fast-deep-equal';
import { find } from 'lodash';
import { TableMemo } from 'memo';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DifferencesPopover from './DifferencesPopover';

const rows = ({ t }) => [
  { label: t('common.fields.name.label'), dataIndex: 'label' },
  { label: t('common.fields.value.label'), dataIndex: 'value' },
];

const getVariableColumns = ({ t, isShowCompare }) =>
  [
    {
      width: '50%',
      title: t('common.fields.name.label'),
      dataIndex: 'label',
      key: 'label',
    },
    {
      width: '50%',
      title: t('common.fields.value.label'),
      dataIndex: 'value',
      key: 'value',
    },
    isShowCompare && {
      width: 25,
      key: 'action',
      className: 'ant-table-cell-background-unset',
      render: (variable) => <DifferencesPopover data={variable} rows={rows({ t })} />,
    },
  ].filter(Boolean);

const Variables = ({ variables }) => {
  const { t } = useTranslation();
  const { isCompare } = useCompareToGlobalContext();
  const columns = useMemo(
    () =>
      getVariableColumns({
        t,
        isShowCompare: isCompare && find(variables, (e) => ActiveComparisonTypes.includes(e.comparisonType)),
      }),
    [isCompare, t, variables],
  );

  return (
    <TableMemo
      className="shopping-cart-table"
      rowKey={(record) => record?._id}
      pagination={false}
      dataSource={variables}
      columns={columns}
    />
  );
};

export default memo(Variables, equal);
