import { useEffect, useState } from 'react';
import Hammer from 'hammerjs';

/**
 * Type of response for a pinch zoom
 * DIRECT: on pinch functions will be called multiple times during pinch event
 * ON_END: on pinch functions will be called only on the end of a pinch event
 * @enum {String}
 */
export const RESPONSE_MODE = {
  DIRECT: 'DIRECT',
  ON_END: 'ON_END',
};

/**
 * PinchZoomPane for zoom on touch screens
 * @param {Object} inputParameter - Input parameter of component
 * @param {String} inputParameter.className - Classname for container
 * @param {Object} inputParameter.style - Style object for container
 * @param {Function} inputParameter.onPinchIn - Function, which is called on pinchIn event or after pinch event ended (Configured by responseMode)
 * @param {Function} inputParameter.onPinchOut - Function, which is called on pinchOut event or after pinch event ended (Configured by responseMode)
 * @param {RESPONSE_MODE} inputParameter.responseMode - Response mode for the pane: Direct mode response on each pinch event, ON_END reponse on end of a pinch
 * @returns {JSX.Element} PinchZoomPane component, which is a container with event listeners for pinch events to implement a zoom function on touch screens
 */
const PinchZoomPane = ({ children, className, style, onPinchIn, onPinchOut, responseMode }) => {
  const [paneReference, setPaneReference] = useState();

  const onEndPinchMode = (hammerManager, pinchRecognizer) => {
    const pinchEnd = ({ additionalEvent, scale }) => {
      if (additionalEvent === 'pinchout') {
        onPinchOut(scale);
      } else if (additionalEvent === 'pinchin') {
        const zoom = 1 / scale;
        onPinchIn(zoom);
      }
    };

    hammerManager.on('pinchend', pinchEnd);
    return () => {
      hammerManager.off('pinchend', pinchEnd);
      hammerManager.remove(pinchRecognizer);
    };
  };

  const directPinchMode = (hammerManager, pinchRecognizer) => {
    const previousScaleValueObject = { previousScale: 1 };
    const pinchOut = ({ scale, center }) => {
      const scaleDifference = scale - previousScaleValueObject.previousScale;
      previousScaleValueObject.previousScale = scale;
      onPinchOut(scaleDifference, center);
    };
    const pinchIn = ({ scale, center }) => {
      const scaleDifference = scale - previousScaleValueObject.previousScale;
      previousScaleValueObject.previousScale = scale;
      onPinchOut(scaleDifference, center);
    };
    const pinchEnd = () => {
      previousScaleValueObject.previousScale = 1;
    };

    hammerManager.on('pinchout', pinchOut);
    hammerManager.on('pinchin', pinchIn);
    hammerManager.on('pinchend', pinchEnd);
    return () => {
      hammerManager.off('pinchout', pinchOut);
      hammerManager.off('pinchin', pinchIn);
      hammerManager.off('pinchend', pinchEnd);
      hammerManager.remove(pinchRecognizer);
    };
  };

  useEffect(() => {
    if (!paneReference) return () => {};
    const hammerManager = new Hammer(paneReference);
    const pinchRecognizer = new Hammer.Pinch();
    hammerManager.add(pinchRecognizer);

    paneReference.style += 'touch-action: pan-x pan-y;';

    if (responseMode === RESPONSE_MODE.ON_END) return onEndPinchMode(hammerManager, pinchRecognizer);
    if (responseMode === RESPONSE_MODE.DIRECT) return directPinchMode(hammerManager, pinchRecognizer);
    return () => {
      hammerManager.remove(pinchRecognizer);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paneReference]);

  return (
    <div className={className} ref={(innerRef) => setPaneReference(innerRef)} style={style}>
      {children}
    </div>
  );
};

export default PinchZoomPane;
