import { gql } from '@apollo/client';
import { adminVariableFragment } from 'graphql/fragments';

const updateVariableMutation = gql`
  mutation updateVariableMutation($_id: ID!, $modifier: VariableInput) {
    updateVariable(_id: $_id, modifier: $modifier) {
      ...adminVariableFragment
    }
  }
  ${adminVariableFragment}
`;

export default updateVariableMutation;
