// https://github.com/formium/formik/issues/284
import equal from 'fast-deep-equal/es6/react';
import { memo } from 'react';
import { Button } from 'antd';
import cn from 'classnames';
import classes from './LinkButton.module.less';

export const LinkButton = ({ className, ...props }) => {
  return <Button className={cn(className, classes.linkButton)} type="link" {...props} />;
};

export default memo(LinkButton, equal);
