import gql from 'graphql-tag';
import React from 'react';
import { useCachedQuery } from 'graphql/utils';
import ConsoleLogs from './ConsoleLogs';

const getLogListQuery = gql`
  query getLogList {
    getLogList {
      _id
      name
      size
    }
  }
`;
const useLogList = () => {
  const { data } = useCachedQuery(getLogListQuery, { fetchPolicy: 'network-only' });
  return [data?.getLogList];
};

const LoggingConfiguration = () => {
  const [logList] = useLogList();

  return (
    <div>
      <div>
        <ConsoleLogs />
        {logList?.map((e) => (
          <a href={`/getLog/${e._id}`}>
            <div>
              {e.name} - {(e.size / 1024).toFixed(2)} KB
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default LoggingConfiguration;
