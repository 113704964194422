import { UndoOutlined } from '@ant-design/icons';
import { Alert, Button, Skeleton } from 'antd';
import I18nFormik from 'components/common/I18nFormik';
import SaveChangesButton from 'components/common/SaveChangesButton';
import PageContainer from 'components/layout/PageContainer';
import { FeeType as feeTypeConstants, ShowDigits } from 'constants/shoppingCart';
import equal from 'fast-deep-equal/es6/react';
import { resetTenantSettings, updateTenantSettings } from 'graphql/methods';
import usePreferences from 'hooks/user/usePreferences';
import i18n from 'i18n';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import confirmModal from 'utils/confirmModal';
import { getMessageFromGraphQLError } from 'utils/helpers';
import toast from 'utils/toast';
import ShoppingCartPreferencesForm from './ShoppingCartPreferencesForm';
import ShoppingCartPreferencesHelperWidget from './ShoppingCartPreferencesHelperWidget';

const skeletons = [...new Array(2)].map((_, i) => i + 1);

function ShoppingCartPreferences() {
  const { preferences, loading, error } = usePreferences();

  const initialValues = useMemo(() => {
    return {
      allowCustomPrices: preferences?.allowCustomPrices || false,
      companyTypeId: preferences?.companyTypeId || null,
      documentTemplates: preferences?.documentTemplates || [],
      emailTemplateId: preferences?.emailTemplateId || null,
      feeType: preferences?.feeType || feeTypeConstants.STANDARD,
      roundPriceId: preferences?.roundPriceId || null,
      showCalculated: preferences?.showCalculated ?? true,
      showCategoryTotal: preferences?.showCategoryTotal ?? false,
      showDigits: preferences?.showDigits || ShowDigits.avoid,
      showDiscounts: preferences?.showDiscounts || false,
      showPrices: preferences?.showPrices || false,
    };
  }, [
    preferences?.allowCustomPrices,
    preferences?.companyTypeId,
    preferences?.documentTemplates,
    preferences?.emailTemplateId,
    preferences?.feeType,
    preferences?.roundPriceId,
    preferences?.showCalculated,
    preferences?.showCategoryTotal,
    preferences?.showDigits,
    preferences?.showDiscounts,
    preferences?.showPrices,
  ]);

  return (
    <>
      {error ? <Alert type="error" message={getMessageFromGraphQLError(error)} /> : null}
      {!preferences && loading ? skeletons.map((k) => <Skeleton title loading active key={k} />) : null}
      {preferences ? (
        <I18nFormik
          initialValues={initialValues}
          onSubmit={async ({
            allowCustomPrices,
            companyTypeId,
            documentTemplates,
            emailTemplateId,
            feeType,
            roundPriceId,
            showCalculated,
            showCategoryTotal,
            showDigits,
            showDiscounts,
            showPrices,
          }) => {
            const newDocumentTemplates = documentTemplates.map((documentTemplate) => JSON.parse(documentTemplate));
            return updateTenantSettings({
              settings: {
                shoppingCartPreferences: {
                  allowCustomPrices,
                  companyTypeId,
                  documentTemplates: newDocumentTemplates,
                  emailTemplateId: emailTemplateId || null,
                  pricingMode: feeType,
                  roundPriceId,
                  showCalculated,
                  showCategoryTotal,
                  showDigits,
                  showDiscounts,
                  showPrices,
                },
              },
            });
          }}
          enableReinitialize
        >
          <div>
            <ShoppingCartPreferencesForm />
            <SaveChangesButton initialValues={initialValues} />
          </div>
        </I18nFormik>
      ) : null}
    </>
  );
}

const onResetSettings = async () => {
  const onConfirm = () =>
    resetTenantSettings({ type: 'shoppingCartPreferences' })
      .then(() => toast.successDefault())
      .catch((e) => console.error(e) || toast.error(i18n.t('admin.Setting.ShoppingCartPreferences.errors.reset')));

  confirmModal({
    cancelText: i18n.t('common.cancel'),
    okText: i18n.t('common.ok'),
    okType: 'danger',
    onOk: onConfirm,
    title: i18n.t('admin.Setting.ShoppingCartPreferences.resetConfirmModalMessage'),
  });
};

function ShoppingCartPreferencesWrapper() {
  const { t } = useTranslation();
  return (
    <PageContainer
      title={t('admin.Setting.ShoppingCartPreferences.title')}
      right={
        <Button icon={<UndoOutlined />} onClick={onResetSettings}>
          {t('admin.Setting.ShoppingCartPreferences.reset')}
        </Button>
      }
      left={<ShoppingCartPreferencesHelperWidget />}
    >
      <ShoppingCartPreferences />
    </PageContainer>
  );
}

export default memo(ShoppingCartPreferencesWrapper, equal);
