import cn from 'classnames';
import s from './PageContainer.module.less';

export default function PageContainer({
  children,
  className,
  title,
  right,
  rightProps,
  left,
  leftProps,
  topComponent,
  style,
  ...rest
}) {
  return (
    <div className={cn('container', 'xs-pt-20', className)} {...rest}>
      {topComponent}
      <div className={cn(s.header, 'container-header')}>
        <div className={cn(s.left, 'xs-m-0', 'max-width-100')}>
          <h1 className="max-width-100">{title}</h1>
          {left ? <div {...leftProps}>{left}</div> : null}
        </div>
        {right ? <div {...rightProps}>{right}</div> : null}
      </div>
      <div className={s.content} style={style}>
        {children}
      </div>
    </div>
  );
}
