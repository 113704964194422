import { Badge, Divider, Drawer, Layout, Menu, Tooltip } from 'antd';
import { RiMenuUnfoldFill, RiMenuFoldFill, RiMoneyEuroBoxLine } from 'react-icons/ri';
import { FaSignature } from 'react-icons/fa';
import equal from 'fast-deep-equal/es6/react';
import { memo, useCallback, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import routePaths from 'router/route-paths';
import ROLES from 'constants/roles';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import { useTranslation } from 'react-i18next';
import {
  DropboxOutlined,
  FileDoneOutlined,
  SettingOutlined,
  BankOutlined,
  HighlightOutlined,
  UnorderedListOutlined,
  PlusOutlined,
  EuroCircleOutlined,
  BookOutlined,
  TeamOutlined,
  MailOutlined,
  StarOutlined,
  ClusterOutlined,
  DashboardOutlined,
  WarningOutlined,
  FundViewOutlined,
  ImportOutlined,
  BugOutlined,
  DatabaseOutlined,
  GlobalOutlined,
  RobotOutlined,
} from '@ant-design/icons';
import cn from 'classnames';
import { isAdmin as isAdminRole } from 'utils/role';
import WarningsComponent from 'components/common/WarningsComponent';
import useGetWarnings from 'graphql/hooks/useGetWarnings';
import settings from 'config/settings';
import Icon from 'atom/Icon';
import s from './NavBar.module.less';
import './NavBar.less';
import Logo from './Logo';

const { Sider } = Layout;

function CustomTrigger({ visible, sizeIcon, className, onClick }) {
  return (
    <div className={cn(s.trigger, className, 'navbar-trigger')} onClick={onClick} aria-hidden="true">
      {visible ? <RiMenuFoldFill size={sizeIcon} /> : <RiMenuUnfoldFill size={sizeIcon} />}
    </div>
  );
}
export const CustomTriggerMemo = memo(CustomTrigger, equal);

function NavBar({ breakpoint, visible, setVisible }) {
  const changeVisible = useCallback(() => {
    setVisible(!visible);
  }, [setVisible, visible]);
  const onClose = useCallback(() => {
    setVisible(false);
  }, [setVisible]);
  return breakpoint ? (
    <CustomNavBarForSmallWidthDevice changeVisible={changeVisible} onClose={onClose} visible={visible} />
  ) : (
    <CustomNavBar changeVisible={changeVisible} onClose={onClose} visible={visible} />
  );
}
function CustomNavBar({ visible, changeVisible, onClose }) {
  return (
    <>
      <Sider theme="light" width={80} />
      <Sider
        width={250}
        theme="light"
        collapsible
        collapsed={!visible}
        onCollapse={changeVisible}
        className={s.sider}
        trigger={null}
      >
        <div aria-hidden="true" className={cn(s.siderTrigger, visible ? s.siderOpen : '')} onClick={changeVisible}>
          <CustomTriggerMemo visible={visible} sizeIcon={25} />
        </div>
        <MenuNavigation
          visible={visible}
          onClickLogo={changeVisible}
          onClickMenu={() => {
            onClose(!false);
          }}
        />
      </Sider>
    </>
  );
}

const WarningDrawer = ({ visible, onClose }) => {
  const { t } = useTranslation();
  return (
    <Drawer visible={visible} onClose={onClose} placement="right" width={300} title={t('NavBar.warnings.title')}>
      <WarningsComponent warningsTypes={['nrOfCompanies', 'nrOfUsers']} />
    </Drawer>
  );
};

function CustomNavBarForSmallWidthDevice({ visible, onClose, changeVisible }) {
  const onClickMenu = useCallback(() => {
    onClose(!false);
  }, [onClose]);
  return (
    <Drawer className={s.drawer} visible={visible} placement="left" closable={false} onClose={onClose} width={250}>
      <MenuNavigation onClickLogo={changeVisible} visible={visible} onClickMenu={onClickMenu} />
    </Drawer>
  );
}

const useSettingItems = () => {
  const { t } = useTranslation();
  return [
    {
      path: routePaths.catalogueConfiguration,
      icon: <EuroCircleOutlined />,
      label: t('NavBar.catalogueConfiguration.title'),
    },
    {
      path: routePaths.documentTemplateList,
      icon: <FileDoneOutlined />,
      label: t('NavBar.documentTemplateListPage.title'),
    },
    {
      path: routePaths.constantsAndPlaceholdersSetting,
      icon: <BookOutlined />,
      label: t('NavBar.constantsAndPlaceholders.title'),
    },
    ...(settings.hideEmailButtons
      ? []
      : [
          {
            path: routePaths.emailTemplates,
            icon: <MailOutlined />,
            label: t('NavBar.emailTemplates.title'),
          },
          {
            path: routePaths.emailSettings,
            icon: <MailOutlined />,
            label: t('NavBar.emailSettings.title'),
          },
        ]),
    {
      label: t('NavBar.shoppingCart.title'),
      menuItem: [
        {
          path: routePaths.shoppingCartPreferences,
          icon: (
            <span className="anticon">
              <Icon name="GrDocumentConfig" />
            </span>
          ),
          label: t('NavBar.shoppingCartPreferences.title'),
        },
        {
          path: routePaths.digitalSignaturePreferences,
          icon: (
            <span className="anticon">
              <FaSignature />
            </span>
          ),
          label: t('NavBar.digitalSignaturePreferences.title'),
        },
        {
          path: routePaths.monthlyPaymentPreferences,
          icon: (
            <span className="anticon">
              <RiMoneyEuroBoxLine />
            </span>
          ),
          label: t('NavBar.monthlyPaymentPreferences.title'),
        },
        {
          path: routePaths.manageShoppingCartStatus,
          icon: <StarOutlined />,
          label: t('NavBar.manageShoppingCartStatus.title'),
        },
      ],
    },
    {
      label: t('NavBar.ourCompany.title'),
      menuItem: [
        {
          path: routePaths.companySetting,
          icon: <BankOutlined />,
          label: t('NavBar.companyTenant.title'),
        },
        {
          path: routePaths.manageUsers,
          icon: <TeamOutlined />,
          label: t('NavBar.manageUsers.title'),
        },
        {
          path: routePaths.managePersons,
          icon: <TeamOutlined />,
          label: t('NavBar.managePersons.title'),
        },
        {
          path: routePaths.importCompanies,
          icon: <ImportOutlined />,
          label: t('NavBar.importCompanies.title'),
        },
        {
          path: routePaths.corporateIdentitySetting,
          icon: <HighlightOutlined />,
          label: t('NavBar.corporateIdentity.title'),
        },
        {
          path: routePaths.licenseOverviewSetting,
          icon: <FundViewOutlined />,
          label: t('NavBar.licenseOverview.title'),
        },
      ].filter(Boolean),
    },
    {
      path: routePaths.otherSettings,
      icon: <GlobalOutlined />,
      label: t('NavBar.otherSettings.title'),
    },
  ];
};
const useSuperAdminItems = ({ visible }) => {
  const { t } = useTranslation();
  return [
    {
      path: routePaths.commonLibraryConfiguration,
      icon: <EuroCircleOutlined />,
      label: visible
        ? t('NavBar.commonLibrary.catalogueCommonLibrary.titleSort')
        : t('NavBar.commonLibrary.catalogueCommonLibrary.title'),
    },
    {
      path: routePaths.commonLibraryDocumentTemplateList,
      icon: <FileDoneOutlined />,
      label: visible
        ? t('NavBar.commonLibrary.documentCommonLibrary.titleSort')
        : t('NavBar.commonLibrary.documentCommonLibrary.title'),
    },
    settings.hideEmailButtons
      ? null
      : {
          path: routePaths.commonLibraryEmailTemplates,
          icon: <MailOutlined />,
          label: visible
            ? t('NavBar.commonLibrary.emailCommonLibrary.titleSort')
            : t('NavBar.commonLibrary.emailCommonLibrary.title'),
        },
    {
      path: routePaths.manageTenants,
      icon: <ClusterOutlined />,
      label: t('NavBar.manageTenants.title'),
    },
    {
      path: routePaths.manageAllUsers,
      icon: <ClusterOutlined />,
      label: t('NavBar.manageAllUsers.title'),
    },
    {
      path: routePaths.manageDigitalSignature,
      icon: <ClusterOutlined />,
      label: t('NavBar.manageDigitalSignature.title'),
    },
  ].filter(Boolean);
};

const useDevItems = () => {
  return [
    {
      icon: <DatabaseOutlined />,
      path: routePaths.migrations,
      label: 'Migrations',
    },
    {
      icon: <DatabaseOutlined />,
      path: routePaths.dbCalls,
      label: 'DB Calls',
    },
    {
      icon: <RobotOutlined />,
      path: routePaths.profileBackendCPU,
      label: 'Profile Backend CPU',
    },
    {
      icon: <RobotOutlined />,
      path: routePaths.CPUMEMUsage,
      label: 'CPU/MEM usage',
    },
    {
      icon: <RobotOutlined />,
      path: routePaths.devLogging,
      label: 'Logging',
    },
  ];
};

function DisplayMenuItem({ menuItem = {}, me }) {
  const currentRole = me?.role;
  return menuItem.map(({ path, tooltip, icon, label, role, menuItem: menuItemProps } = {}) => {
    if (role && role !== currentRole) return null;
    if (menuItemProps) {
      return (
        <Menu.SubMenu
          key={label}
          className={s.subMenu}
          popupClassName={s.itemSubMenu}
          icon={<SettingOutlined />}
          title={label}
        >
          {DisplayMenuItem({ menuItem: menuItemProps, me })}
        </Menu.SubMenu>
      );
    }
    return (
      <Menu.Item key={path} title={tooltip}>
        <Link to={path}>
          {icon}
          <span className="ant-menu-title-content">{label}</span>
        </Link>
      </Menu.Item>
    );
  });
}
function MenuNavigation({ onClickMenu, visible, onClickLogo }) {
  const { t } = useTranslation();
  const [me] = useCurrentUser();
  const currentRole = me?.role;
  const isAdmin = isAdminRole(currentRole);
  const isDev = me?.isDev;
  const isSuperAdmin = currentRole === ROLES.SUPER_ADMIN;
  const isViewer = currentRole === ROLES.VIEWER;
  const { data: warnings } = useGetWarnings(['nrOfCompanies', 'nrOfUsers']);
  const [showWarningDrawer, setShowWarningDrawer] = useState(false);

  const { pathname } = useLocation();
  const settingItems = useSettingItems();
  const superAdminItems = useSuperAdminItems({ visible });
  const devItems = useDevItems();
  return (
    <>
      <WarningDrawer
        visible={showWarningDrawer}
        onClose={() => {
          setShowWarningDrawer(false);
        }}
      />
      <Tooltip placement="right" title={!visible && t('NavBar.logo.tooltip')}>
        <div>
          <Logo placeholder="" onClick={onClickLogo} classNameWrapper={visible ? '' : s.logoCollapse} />
        </div>
      </Tooltip>
      <Divider style={{ margin: 0 }} />

      <Menu onClick={onClickMenu} className="navbar" selectedKeys={[pathname]} mode="inline">
        <Menu.Item key={routePaths.dashboard} title={!visible && t('NavBar.dashboard.tooltip')}>
          <Link to={routePaths.dashboard}>
            <DashboardOutlined />
            {visible && <span className="ant-menu-title-content">{t('NavBar.dashboard.title')}</span>}
          </Link>
        </Menu.Item>
        <Menu.Item key={routePaths.shoppingCartEntries} title={!visible && t('NavBar.shoppingCartEntries.tooltip')}>
          <Link to={routePaths.shoppingCartEntries}>
            <UnorderedListOutlined />
            {visible && <span className="ant-menu-title-content">{t('NavBar.shoppingCartEntries.title')}</span>}
          </Link>
        </Menu.Item>
        {isViewer || (
          <Menu.Item key={routePaths.shoppingCart} title={!visible && t('NavBar.createShoppingCart.tooltip')}>
            <Link to={routePaths.shoppingCart}>
              <PlusOutlined />
              {visible && <span className="ant-menu-title-content">{t('NavBar.createShoppingCart.title')}</span>}
            </Link>
          </Menu.Item>
        )}
        {isAdmin ? (
          <Menu.SubMenu
            className={s.subMenu}
            popupClassName={s.itemSubMenu}
            icon={<SettingOutlined />}
            title={t('NavBar.settingTitle')}
          >
            {DisplayMenuItem({ menuItem: settingItems, me })}
          </Menu.SubMenu>
        ) : null}
        {isSuperAdmin ? (
          <Menu.SubMenu
            className={s.subMenu}
            popupClassName={s.itemSubMenu}
            icon={<DropboxOutlined />}
            title={t('NavBar.commonLibrary.title')}
          >
            {DisplayMenuItem({ menuItem: superAdminItems, me })}
          </Menu.SubMenu>
        ) : null}
        {isDev ? (
          <Menu.SubMenu
            className={s.subMenu}
            popupClassName={s.itemSubMenu}
            icon={<BugOutlined />}
            title="Dev settings"
          >
            {DisplayMenuItem({ menuItem: devItems, me })}
          </Menu.SubMenu>
        ) : null}
        {warnings?.length ? (
          <Menu.Item
            key="warnings"
            title={!visible && t('NavBar.warnings.tooltip')}
            onClick={() => {
              setShowWarningDrawer(true);
            }}
          >
            <Badge count={warnings?.length} offset={[10, 10]} size="small">
              <WarningOutlined style={{ color: '#f00' }} />
              {visible && t('NavBar.warnings.title')}
            </Badge>
          </Menu.Item>
        ) : null}
      </Menu>
    </>
  );
}

export default memo(NavBar, equal);
