import Card from 'components/common/Card';
import concatClassNames from 'utils/classNames';
import classes from './InformationCard.module.less';

/**
 * InformationCard component is a card for the history card to show additional infos of an event
 * @param {Object} inputParameters - Input parameters of the component
 * @param {String} inputParameters.message - Information message
 * @param {String} inputParameters.className - Additional classname
 * @returns {JSX.Element} card component, which shows an information message
 * @component
 */
const InformationCard = ({ message, className }) => {
  return <Card className={concatClassNames(classes.InformationCard, className)}>{message}</Card>;
};

export default InformationCard;
