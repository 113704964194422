import { gql } from '@apollo/client';
import apollo from 'graphql/apollo';
import { userCompanyImportFieldsFragment } from 'graphql/fragments';

const companyFragment = gql`
  fragment companyFragment on Company {
    importFields {
      ...userCompanyImportFieldsFragment
    }
  }
  ${userCompanyImportFieldsFragment}
`;

function getCompanyImportFieldsById({ companyId }) {
  const importFields = apollo.readFragment({
    id: `Company___${companyId}`,
    fragment: companyFragment,
    fragmentName: 'companyFragment',
  })?.importFields;
  return importFields;
}

export default getCompanyImportFieldsById;
