import * as Yup from 'yup';
import { Mentions } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { getAdminInputFields } from 'graphql/hooks/useAdminInputFields';
import { find, uniqBy } from 'lodash';
import { errorWithTouched } from 'components/user/shoppingCart/utils';
import i18n from 'i18n';
import { CalculationMode } from 'constants/item';

export const textsSchema = () => ({
  name: Yup.string().label(i18n.t('admin.categoryNameInput.label')).required().min(1),
});

export const pricingFormulaValidationSchema = ({
  _id,
  categoryId,
  formikRef,
  getFields = getAdminInputFields,
  isLibrary,
  label,
  parentId,
}) =>
  Yup.string()
    .label(label)
    .required()
    .min(1)
    .test(
      'invalid',
      i18n.t('admin.itemModal.inputs.pricingFormula.errors.invalid', { name: label }),
      function validate(value) {
        const { path, createError } = this;
        const recursiveError = () =>
          errorWithTouched({ formikRef, error: createError({ path, message: i18n.t('common.Item.recursive') }) });
        const scaleIsRequiredError = () =>
          errorWithTouched({ formikRef, error: createError({ path, message: i18n.t('common.Item.scaleError') }) });
        try {
          const fields = getFields({ _id, categoryId, parentId, isLibrary });
          const isIncludesRecursiveField = find(fields, (field) => field.disabled && value?.includes(field._id));
          if (isIncludesRecursiveField) return recursiveError();
          const currentFormula = Mentions.getItemFormulaWithScales({
            pricingFormula: value,
            graduatedScaleMode: formikRef.current.values.graduatedScaleMode,
            scales: formikRef?.current?.values?.scalesEnabled && formikRef.current.values.scales,
          });
          Mentions.isValidFormula(currentFormula);
        } catch (e) {
          console.log(value, e);
          if (e.message?.includes('circular')) return recursiveError();
          if (e.message?.includes('@scale is empty')) return scaleIsRequiredError();
          return errorWithTouched({ formikRef, error: createError({ path }) });
        }
        return true;
      },
    );

export const priceSchema = ({ formikRef, categoryId, _id, isLibrary }) => {
  const schemaObject = {
    paymentInterval: Yup.string().label(i18n.t('admin.itemModal.inputs.paymentInterval.label')).required(),
    ...(formikRef.current.values.calculationMode === CalculationMode.VALUE && {
      pricingFormula: pricingFormulaValidationSchema({
        categoryId,
        formikRef,
        isLibrary,
        label: i18n.t('admin.itemModal.inputs.pricingFormula.label'),
        parentId: _id,
      }),
      highlightBindingness: Yup.string()
        .min(0)
        .nullable()
        .required()
        .label(i18n.t('admin.itemModal.inputs.highlightBindingness.label')),
    }),
    minPrice: Yup.number().min(0).nullable().label(i18n.t('admin.itemModal.inputs.minPrice.label')),
    ...(formikRef?.current?.values?.scalesEnabled && {
      scales: Yup.array()
        .label(i18n.t('admin.itemModal.inputs.scalesList.label'))
        .required()
        .min(2, i18n.t('admin.itemModal.inputs.scales.atLeastTwoError'))
        .test('invalid', i18n.t('admin.itemModal.inputs.scales.duplicatedError'), function test(arr) {
          const { path, createError } = this;
          if (uniqBy(arr, 'value').length !== arr.length)
            return errorWithTouched({ formikRef, error: createError({ path }), fieldName: 'scales' });
          return true;
        })
        .nullable(),
      scaleTitle: Yup.string()
        .label(i18n.t('admin.itemModal.inputs.scales.inputs.scaleTitle.label'))
        .required()
        .min(1)
        .nullable(),
      scalePricingFormulaTitle: Yup.string()
        .label(i18n.t('admin.itemModal.inputs.scales.inputs.pricingFormulaTitle.label'))
        .required()
        .min(1)
        .nullable(),
      graduatedScaleMode: Yup.boolean()
        .label(i18n.t('admin.itemModal.inputs.scales.inputs.graduatedScaleMode.label'))
        .nullable(),
    }),
  };
  // console.log(schemaObject);
  return schemaObject;
};
