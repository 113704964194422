import React, { useContext, useEffect, useMemo } from 'react';

const OnboardingContext = React.createContext({
  currentIndex: 0,
  setCurrentIndex: () => {},
  currentStep: null,
  formValuesRef: null,
  onSubmit: () => {},
  isSubmitting: false,
  setIsSubmitting: () => {},
});
export default OnboardingContext;

export const OnboardingContextProvider = ({
  formValues,
  children,
  steps,
  onComplete,
  isSubmitting,
  setIsSubmitting,
}) => {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  useEffect(() => {
    currentIndex;
    window.scrollTo(0, 0);
  }, [currentIndex]);
  const currentStep = steps[currentIndex];

  const onSubmit = React.useCallback(
    (values) => {
      const isLast = currentIndex === steps.length - 1;
      formValuesRef.current = { ...formValuesRef.current, ...values };
      if (isLast) {
        onComplete(formValuesRef.current);
      } else {
        setCurrentIndex(currentIndex + 1);
      }
    },
    [currentIndex, steps, onComplete],
  );
  const formValuesRef = React.useRef(formValues || {});

  const value = useMemo(
    () => ({
      currentIndex,
      setCurrentIndex,
      currentStep,
      formValuesRef,
      onSubmit,
      steps,
      isSubmitting,
      setIsSubmitting,
    }),
    [currentIndex, currentStep, isSubmitting, onSubmit, setIsSubmitting, steps],
  );
  return <OnboardingContext.Provider value={value}>{children}</OnboardingContext.Provider>;
};

export const useOnboardingContext = () => useContext(OnboardingContext);
