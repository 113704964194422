import { useTranslation } from 'react-i18next';
import { Checkbox } from 'formik-antd';
import FormItem from 'components/common/FormItem';
import Input from 'components/common/Input';
import { Row, Col, Tooltip, Form } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import I18nFormik from 'components/common/I18nFormik';
import i18n from 'i18n';
import { AlertFromFormik } from 'components/common/ErrorComponent';
import SaveChangesButton from 'components/common/SaveChangesButton';
import { updateEmailService } from 'graphql/methods';
import toast from 'utils/toast';
import { toastGraphQLError } from 'utils/helpers';
import confirmModal from 'utils/confirmModal';
import { updateSMTPEmailSettingSchema as schema } from './FormSchemas';

export const DisplaySMTPSettings = ({ show, initialValues, setBestSMTPSettings }) => {
  const { t } = useTranslation();

  const onSubmit = async (values) => {
    confirmModal({
      cancelText: i18n.t('common.no'),
      maskClosable: true,
      okText: i18n.t('common.yes'),
      okType: 'danger',
      onOk: async () => {
        await updateEmailService({ ...values, mailServiceProvider: 'smtp' })
          .then(toast.successDefault)
          .catch(toastGraphQLError);
        setBestSMTPSettings().catch(toastGraphQLError);
      },
      onCancel: () =>
        updateEmailService({ ...values, mailServiceProvider: 'smtp' })
          .then(toast.successDefault)
          .catch(toastGraphQLError),
      title: i18n.t('admin.Setting.Email.setBestSMTPSettingsTitle'),
    });
  };

  if (!show) return null;
  return (
    <I18nFormik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema} enableReinitialize>
      <Form layout="vertical">
        <AlertFromFormik />
        <FormItem
          name="from"
          label={t('admin.Setting.Email.inputs.from.label')}
          tooltip={t('admin.Setting.Email.inputs.from.tooltip')}
        >
          <Input name="from" autoComplete="off" />
        </FormItem>
        <Row gutter={{ xs: 0, sm: 16, md: 24 }}>
          <Col xs={24} sm={12}>
            <FormItem name="smtpUserName" label={t('admin.Setting.Email.inputs.smtpUserName')}>
              <Input name="smtpUserName" />
            </FormItem>
          </Col>
          <Col xs={24} sm={12}>
            <FormItem name="smtpPassword" label={t('admin.Setting.Email.inputs.smtpPassword')}>
              <Input name="smtpPassword" type="password" />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={{ xs: 0, sm: 16, md: 24 }}>
          <Col xs={24} sm={12}>
            <FormItem name="smtpServer" label={t('admin.Setting.Email.inputs.smtpServer')}>
              <Input name="smtpServer" />
            </FormItem>
          </Col>
          <Col xs={24} sm={12}>
            <FormItem name="smtpPort" label={t('admin.Setting.Email.inputs.smtpPort')}>
              <Input name="smtpPort" />
            </FormItem>
          </Col>
        </Row>
        <FormItem name="placeholder" label={t('admin.Setting.Email.inputs.options.label')}>
          <Checkbox name="useTLS">
            {t('admin.Setting.Email.inputs.useTLS')}{' '}
            <Tooltip title={t('admin.Setting.Email.inputs.options.useTLS.tooltip')} autoAdjustOverflow>
              <InfoCircleOutlined />
            </Tooltip>
          </Checkbox>
          <Checkbox name="requireTLS">
            {t('admin.Setting.Email.inputs.requireTLS')}{' '}
            <Tooltip title={t('admin.Setting.Email.inputs.options.requireTLS.tooltip')} autoAdjustOverflow>
              <InfoCircleOutlined />
            </Tooltip>
          </Checkbox>
          <Checkbox name="useSSLv3">
            {t('admin.Setting.Email.inputs.useSSLv3')}{' '}
            <Tooltip title={t('admin.Setting.Email.inputs.options.useSSLv3.tooltip')} autoAdjustOverflow>
              <InfoCircleOutlined />
            </Tooltip>
          </Checkbox>
        </FormItem>
        <SaveChangesButton
          forceVisible={initialValues.mailServiceProvider === 'default'}
          initialValues={initialValues}
          marginTop={0}
        />
      </Form>
    </I18nFormik>
  );
};
