import { gql } from '@apollo/client';
import { userHistoryItemFragment } from 'graphql/fragments';

const userHistoryItemsViewQuery = gql`
  query userHistoryItemsViewQuery($_id: ID!) {
    userShoppingCart(_id: $_id) {
      _id
      historyItems {
        ...userHistoryItemFragment
      }
    }
  }
  ${userHistoryItemFragment}
`;

export default userHistoryItemsViewQuery;
