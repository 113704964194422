import { gql } from '@apollo/client';
import { adminVariableListFragment } from 'graphql/fragments';

const deleteVariableMutation = gql`
  mutation deleteVariableMutation($_id: ID!) {
    deleteVariable(_id: $_id) {
      ...adminVariableListFragment
    }
  }
  ${adminVariableListFragment}
`;

export default deleteVariableMutation;
