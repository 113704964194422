import ItemTypes from 'constants/ItemTypes';
import equal from 'fast-deep-equal/es6/react';
import { memo } from 'react';
import { useDragLayer } from 'react-dnd';
import CategoryDrag from './CategoryDrag';

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: -20,
  top: -13,
  width: '100%',
};
function getItemStyles(currentOffset) {
  if (!currentOffset) return null;

  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;
  return {
    opacity: 0.7,
    transform,
    WebkitTransform: transform,
  };
}
const RenderItem = ({ _id, itemType }) => {
  switch (itemType) {
    case ItemTypes.CATEGORY:
      return <CategoryDrag _id={_id} />;
    default:
      return null;
  }
};
const RenderItemMemo = memo(RenderItem, equal);

const CategoryDragLayer = () => {
  const { itemType, isDragging, item, currentOffset } = useDragLayer((monitor) => {
    return {
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    };
  });
  const style = getItemStyles(currentOffset);

  if (!isDragging) {
    return null;
  }

  return (
    <div style={style}>
      <RenderItemMemo _id={item?._id} itemType={itemType} />
    </div>
  );
};
const CategoryDragLayerMemo = memo(CategoryDragLayer, equal);

const CategoryDragLayerWrapper = () => (
  <div style={layerStyles}>
    <CategoryDragLayerMemo />
  </div>
);
export default memo(CategoryDragLayerWrapper, equal);
