import { gql } from '@apollo/client';
import { adminEmailServiceFragment } from 'graphql/fragments';

const adminEmailServiceQuery = gql`
  query adminEmailServiceQuery {
    getEmailService {
      ...adminEmailServiceFragment
    }
  }
  ${adminEmailServiceFragment}
`;

export default adminEmailServiceQuery;
