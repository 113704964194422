import { useTranslation } from 'react-i18next';
import { Button, Upload } from 'antd';
import Papa from 'papaparse';
import { UploadOutlined } from '@ant-design/icons';
import { useFormikContext } from 'formik';
import { merge, toLower, intersectionBy } from 'lodash';
import { useState } from 'react';
import { trimObj } from 'utils/helpers';

const DragableUploadListItem = ({ originNode, file }) => {
  return (
    <div className="ant-upload-draggable-list-item" style={{ pointerEvents: 'none' }}>
      {file.status === 'error' ? originNode.props.children : originNode}
    </div>
  );
};

const Step1 = ({ setFieldsFromCsv, setCompaniesData, companyFields, currentStep }) => {
  const { t } = useTranslation();
  const { setValues } = useFormikContext();
  const [fileList, setFileList] = useState([]);
  const onChange = ({ fileList: newFileList }) => setFileList(newFileList);
  if (currentStep !== 0) return null;
  return (
    <Upload
      maxCount={1}
      accept=".csv"
      fileList={fileList}
      onChange={onChange}
      // eslint-disable-next-line react/no-unstable-nested-components
      itemRender={(originNode, file, currFileList) => (
        <DragableUploadListItem originNode={originNode} file={file} fileList={currFileList} />
      )}
      beforeUpload={(file) => {
        const reader = new FileReader();
        reader.onload = () => {
          const parseObject = Papa.parse(reader.result, { header: true });
          if (parseObject) {
            const newFields = parseObject.meta?.fields;
            setFieldsFromCsv(newFields);
            if (newFields) {
              setValues(
                merge(
                  {
                    defaultCompanyType: 'company',
                  },
                  ...companyFields.map((field) => {
                    const value = intersectionBy(newFields, field.possibleNames, (x) =>
                      toLower(x.replace(/[- _]/gm, '')),
                    )?.[0];
                    if (value) return { [field.columnTarget]: value };
                    return {};
                  }),
                ),
              );
            }
            setCompaniesData(trimObj(parseObject.data));
          }
        };
        reader.readAsText(file);
        return false;
      }}
    >
      <Button type="primary" icon={<UploadOutlined />}>
        {t('admin.Setting.ImportCompanies.uploadButton')}
      </Button>
    </Upload>
  );
};

export default Step1;
