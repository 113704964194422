import { gql } from '@apollo/client';
import { adminRoundPricesFragment } from 'graphql/fragments';

const adminRoundPricesQuery = gql`
  query adminRoundPricesQuery {
    availableRoundPrices {
      ...adminRoundPricesFragment
    }
  }
  ${adminRoundPricesFragment}
`;

export default adminRoundPricesQuery;
