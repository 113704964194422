import { useCachedQuery } from 'graphql/utils';
import { Select } from 'formik-antd';
import equal from 'fast-deep-equal/es6/react';
import { adminPersonsInTenantQuery } from 'graphql/queries';
import { memo, useEffect, useRef } from 'react';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { useFormikField } from 'hooks/common/useFormikField';
import { intersectionBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import toast from 'utils/toast';

const formatPerson = ({ position, name }) => `${position ? `${position} ` : ''}${name}`;
const emptyArr = [];
export const PersonAllowedToSignFormikMultiSelect = memo(({ name }) => {
  const { t } = useTranslation();
  const { data, loading } = useCachedQuery(adminPersonsInTenantQuery);
  const persons = grabFirstGQLDataResult(data);
  const { value, onChange, submitForm } = useFormikField(name);
  const valueArray = value || emptyArr;
  const isRefreshRef = useRef();
  useEffect(() => {
    if (!loading && !isRefreshRef.current) {
      isRefreshRef.current = true;
      const intersection = intersectionBy(valueArray, persons, (e) => (typeof e === 'object' ? e._id : e));
      if (valueArray.length !== intersection.length) {
        onChange(intersection);
        toast.info(t('common.toast.info.updateIntroductionBlock'));
        window.setTimeout(submitForm);
      }
    }
  }, [loading, onChange, persons, submitForm, t, valueArray]);
  return (
    <Select name={name} mode="multiple">
      {loading
        ? null
        : persons?.map((person) => {
            return (
              <Select.Option valueArray={person._id} key={person._id}>
                {formatPerson(person)}
              </Select.Option>
            );
          })}
    </Select>
  );
}, equal);
