import apollo from 'graphql/apollo';
import { remindAllSigneesMutation } from 'graphql/mutations';

/**
 * Remind all signees by filter and email template and or email props
 * @param {Object} inputParameters - Input parameters of the function
 * @param {String} inputParameters.shoppingCartId - Database id of shopping cart, for which the remind emails should be sent
 * @param {String} inputParameters.emailTemplateId - Database id of email template to use (Also when email props are set)
 * @param {Boolean} inputParameters.onlyRemindUnsigned - Should only unsigned signees get a reminder email
 * @param {String} inputParameters.signeeType - Type of signee, which should get a reminder email
 * @param {Object} inputParameters.emailProps - Optional: Modified email properties
 * @param {String} inputParameters.emailProps.subject - Email subject rich text
 * @param {String} inputParameters.emailProps.body - Email body rich text
 * @param {String} inputParamaters.emailProps.signature - Email signture rich text
 * @param {Boolean} inputParameters.emailProps.shouldSendAttachments - Should docs be attached as attachments to the email
 * @returns {Promise<Object>} mutation result
 */
const remindAllSignees = async ({ shoppingCartId, emailTemplateId, onlyRemindUnsigned, signeeType, emailProps }) =>
  apollo.mutate({
    mutation: remindAllSigneesMutation,
    variables: {
      shoppingCartId,
      emailTemplateId,
      onlyRemindUnsigned,
      signeeType,
      emailProps,
    },
  });

export default remindAllSignees;
