import { gql } from '@apollo/client';

const superAdminUserFragment = gql`
  fragment superAdminUserFragment on User {
    _id
    email
    profile {
      _id
      firstName
      lastName
    }
    state
    locale
  }
`;

export default superAdminUserFragment;
