import apollo from 'graphql/apollo';
import { createVariableMutation } from 'graphql/mutations';
import { richEditorSchemaQuery } from 'graphql/queries';

const createVariable = async ({ label, value, type, description }) =>
  apollo.mutate({
    mutation: createVariableMutation,
    variables: {
      modifier: { label, value, type, description },
    },
    refetchQueries: [{ query: richEditorSchemaQuery, variables: { isLibrary: false } }],
  });
export default createVariable;
