import { Select } from 'formik-antd';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

/**
 * AddDocumentForm component for the add document modal in the shopping cart view
 * @param {Object} inputParameters - Input parameters of the component
 * @param {Object[]} inputParameters.availableDocumentTemplates - Collection of available document templates to select from
 * @returns {JSX.Element} form with the document template select to add additional documents to existing shopping cart
 * @component
 */
const AddDocumentForm = ({ availableDocumentTemplates }) => {
  const { t } = useTranslation();
  return (
    <Select
      name="documents"
      placeholder={t('viewer.ShoppingCartView.CreatedDocuments.addDocumentsModal.placeholder')}
      showSearch
      style={{ width: '100%' }}
      mode="multiple"
    >
      {availableDocumentTemplates.map((option) => (
        <Option value={option._id} key={option._id}>
          {option.name}
        </Option>
      ))}
    </Select>
  );
};

export default AddDocumentForm;
