import { useMemo } from 'react';
import { userContactListQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { useCachedQuery } from 'graphql/utils';

const useContactList = (initializationConfigDate) => {
  const { data, loading } = useCachedQuery(userContactListQuery, {
    variables: { initializationConfigDate },
    fetchPolicy: 'cache-and-network',
  });
  const contacts = useMemo(() => grabFirstGQLDataResult(data), [data]);
  return { data: contacts, loading };
};

export default useContactList;
