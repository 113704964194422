import { DIGITAL_SIGNATURE_STATE } from '@JavaScriptSuperstars/kanzleipilot-shared/lib/digitalSignature';
import { Collapse, Row, Col, Tooltip } from 'antd';
import { FaUserCheck, FaUserTimes } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import concatClassNames from 'utils/classNames';
import SigneeManagement from './SigneeManagement/SigneeManagement';
import classes from './SigneeRow.module.less';

const { Panel } = Collapse;

/**
 * NotSignedSignee component, which provides informations and options for signee, which havent signed yet
 * @param {Object} inputParameter - Input parameter
 * @param {Object} inputParameter.signee - Informations about the signee
 * @param {Function} inputParameter.translation - I18n translation function
 * @param {String} inputParameter.shoppingCartId - Database id of the related shopping cart object
 * @returns {JSX.Element} NotSignedSignee component for digital signature card in shopping cart view
 * @component
 */
const NotSignedSignee = ({ signee, translation, shoppingCartId }) => {
  return (
    <Collapse className={classes.collapsable} ghost>
      <Panel
        header={
          <Tooltip title={signee?.email} placement="topLeft" overlayStyle={{ maxWidth: 500 }}>
            {/* Without the additional span the tooltip on hover does not work */}
            <span>
              <Header
                signee={signee}
                translation={translation}
                digitalSignatureState={DIGITAL_SIGNATURE_STATE.STARTED}
              />
            </span>
          </Tooltip>
        }
        className={cn(classes.collapsable, classes.collapsablePanel)}
      >
        <SigneeManagement signee={signee} shoppingCartId={shoppingCartId} />
      </Panel>
    </Collapse>
  );
};

/**
 * Header component for signee infos for a signed signee or signee if digital signature state is not started
 * @param {Object} inputParameter - Input parameter
 * @param {Object} inputParameter.signee - Informations about the signee
 * @param {String} inputParameter.digitalSignatureState - Digital signature state of related shopping cart
 * @param {Function} inputParameter.translation - I18n translation function
 * @returns {JSX.Element} Header component for digital signature card in shopping cart view
 * @component
 */
const Header = ({ signee, digitalSignatureState, translation }) => {
  const { firstName, lastName, hasSigned, signDate } = signee;

  const isStateStartedOrSigned =
    digitalSignatureState === DIGITAL_SIGNATURE_STATE.STARTED ||
    digitalSignatureState === DIGITAL_SIGNATURE_STATE.SIGNED;

  const date = new Date(signDate);
  const formattedSignDate = date.toLocaleString('de-DE', {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
  const state =
    isStateStartedOrSigned &&
    (hasSigned
      ? translation('viewer.ShoppingCartView.DigitalSignature.signed', { signDate: formattedSignDate })
      : translation('viewer.ShoppingCartView.DigitalSignature.notSigned'));
  const formattedLastRemindedDate =
    signee.lastRemindedDate &&
    new Date(signee.lastRemindedDate).toLocaleDateString('de-DE', {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  const reminderState = signee.lastRemindedDate
    ? translation('viewer.ShoppingCartView.DigitalSignature.reminded', { lastRemindedDate: formattedLastRemindedDate })
    : translation('viewer.ShoppingCartView.DigitalSignature.notReminded');
  const name = `${firstName} ${lastName}`;
  return (
    <>
      <h3>{name}</h3>
      <span className={classes.state}>{state}</span>
      {digitalSignatureState === DIGITAL_SIGNATURE_STATE.STARTED && !hasSigned ? (
        <span className={classes.state}>{reminderState}</span>
      ) : null}
    </>
  );
};

/**
 * SigneeRow component for signee infos for digital signature card in shopping cart view
 * @param {Object} inputParameters - Input parameters of the component
 * @param {Object} inputParameters.signee - Informations about the signee
 * @param {String} inputParameters.digitalSignatureState - Digital signature state of related shopping cart
 * @param {String} inputParameters.shoppingCartId - Database id of the related shopping cart object
 * @param {String} inputParameters.className - Additional classname for row component
 * @returns {JSX.Element} SigneeRow component for digital signature card
 * @component
 */
const SigneeRow = ({ signee, digitalSignatureState, shoppingCartId, className }) => {
  const { t: translation } = useTranslation();
  const { hasSigned } = signee;

  const showAsCollapsable = digitalSignatureState === DIGITAL_SIGNATURE_STATE.STARTED && !hasSigned;

  const icon = hasSigned ? (
    <FaUserCheck
      size="16"
      className={cn(classes.icon, classes.signed, !showAsCollapsable && classes.collapseButtonPlaceholder)}
    />
  ) : (
    <FaUserTimes size="16" className={cn(classes.icon, !showAsCollapsable && classes.collapseButtonPlaceholder)} />
  );

  return (
    <Row className={concatClassNames(classes.row, className)}>
      <Col flex="0 1 24px">{icon}</Col>
      <Col flex="1 1 100px">
        {showAsCollapsable && (
          <NotSignedSignee signee={signee} translation={translation} shoppingCartId={shoppingCartId} />
        )}
        {!showAsCollapsable && (
          <Tooltip title={signee?.email} placement="topLeft" overlayStyle={{ maxWidth: 500 }}>
            {/* Without the additional span the tooltip on hover does not work */}
            <span>
              <Header signee={signee} digitalSignatureState={digitalSignatureState} translation={translation} />
            </span>
          </Tooltip>
        )}
      </Col>
    </Row>
  );
};

export default SigneeRow;
