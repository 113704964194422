import { gql } from '@apollo/client';

const getUploadFileLinkMutation = gql`
  mutation getUploadFileLinkMutation($name: String!, $parentId: ID!, $parentType: String, $size: Int) {
    getUploadFileLink(name: $name, parentId: $parentId, parentType: $parentType, size: $size) {
      _id
      url
    }
  }
`;

export default getUploadFileLinkMutation;
