import { Route, Routes } from 'react-router-dom';

export const DisplayByRoute = ({ path, children, component: Component, ...props }) => {
  // eslint-disable-next-line no-param-reassign
  path = Array.isArray(path) ? path : [path];
  return (
    <Routes>
      {path.map((p) => (
        <Route key={p} path={p} element={<Component {...props} />} />
      ))}
    </Routes>
  );
};

export default DisplayByRoute;
