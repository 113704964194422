import { gql } from '@apollo/client';

const userSigneesSigningLinkInfosQuery = gql`
  query userSigneesSigningLinkInfosQuery($shoppingCartId: ID!, $signeeRelatedObjectId: ID!, $signeeEmail: String!) {
    getSigneesLinkInfo(
      shoppingCartId: $shoppingCartId
      signeeRelatedObjectId: $signeeRelatedObjectId
      signeeEmail: $signeeEmail
    ) {
      expirationDate
      usePassword
    }
  }
`;

export default userSigneesSigningLinkInfosQuery;
