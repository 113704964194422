import apollo from 'graphql/apollo';
import { moveStatusMutation } from 'graphql/mutations';
import { userGetStatusesQuery } from 'graphql/queries';

const moveStatus = ({ _id, offset, order, type }) =>
  apollo.mutate({
    mutation: moveStatusMutation,
    variables: { _id, offset, order },
    refetchQueries: [{ query: userGetStatusesQuery, variables: { type } }],
  });
export default moveStatus;
