import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import classes from './SpinForCard.module.less';

/**
 * Spin component for a card component
 * @returns {JSX.Element} SpinForCard component
 * @component
 */
const SpinForCard = () => {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 64,
      }}
      spin
    />
  );

  return (
    <div className={classes.SpinWrapper}>
      <Spin indicator={antIcon} />
    </div>
  );
};

export default SpinForCard;
