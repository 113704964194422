import { myThemeRichEditorQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { useMemo } from 'react';
import { merge } from 'lodash';
import { useCachedQuery } from 'graphql/utils';

const defaultTheme = {
  // used in rich editor
  title: {
    fontSize: '36pt',
  },
  textBlock: { marginBottom: '11pt' },
};
export const useThemeRichEditor = () => {
  const { data, loading } = useCachedQuery(myThemeRichEditorQuery, { fetchPolicy: 'cache-first' });
  const theme = useMemo(() => merge(defaultTheme, grabFirstGQLDataResult(data)?.pdf), [data]);
  return { loading, theme };
};

export default useThemeRichEditor;
