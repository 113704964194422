const useIsSigningPage = () => {
  return isSigningPage;
};

export const isSigningPage = () => {
  const currentPagepath = window.location.pathname;
  const matchingRegExp = /\/signing\/([^/]*)/;
  return matchingRegExp.test(currentPagepath);
};

export default useIsSigningPage;
