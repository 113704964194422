import { gql } from '@apollo/client';

const userStaticItemFragment = gql`
  fragment userStaticItemFragment on StaticItem {
    _id
    _apolloCacheKey
    nameRaw
    valueRaw
  }
`;

export default userStaticItemFragment;
