import { gql } from '@apollo/client';

const adminFontFragment = gql`
  fragment adminFontFragment on Font {
    name
    link
  }
`;

export default adminFontFragment;
