import apollo from 'graphql/apollo';
import { updateTenantCompanyMutation } from 'graphql/mutations';
import { adminTenantCompanyQuery } from 'graphql/queries';

const updateCategoryItem = async ({
  companyName,
  street,
  streetNr,
  cityCode,
  city,
  phoneNr,
  country,
  websiteUrl,
  officeEmail,
  creditorIdentNr,
}) =>
  apollo.mutate({
    mutation: updateTenantCompanyMutation,
    variables: {
      company: {
        companyName,
        street,
        streetNr,
        cityCode,
        city,
        phoneNr,
        country,
        websiteUrl,
        officeEmail,
        creditorIdentNr,
      },
    },
    update: (cache, { data }) => {
      cache.writeQuery({
        query: adminTenantCompanyQuery,
        data: {
          getTenantCompany: data?.updateTenantCompany,
        },
      });
    },
  });
export default updateCategoryItem;
