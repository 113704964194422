import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { superAdminAvailableAccessGroupsQuery } from 'graphql/queries';
import SelectInputField from './SelectInputField';

/**
 * Renders a multi-select input for access groups for super admin
 * @param {Object} inputParameters - Input parameters of the component
 * @param {string} inputParameters.fieldName - Name attribute for the select input field.
 * @param {string} inputParameters.label - Label text for the select input field.
 * @param {string} inputParameters.tooltip - Tooltip text to provide additional information about the select input.
 * @param {Array} inputParameters.specialOptions - Additional options that can be added to the list of options.
 * @param {string} inputParameters.className - Optional additional CSS classes to apply to the component.
 * @param {string} inputParameters.placeholder - Placeholder text for the select input field when no option is selected.
 * @returns {JSX.Element} showing a multi-select of access groups
 */
const AccessGroupsMultiSelect = ({ fieldName, label, tooltip, specialOptions, className, placeholder }) => {
  const { t } = useTranslation();
  const { data: availableAccessGroups } = useQuery(superAdminAvailableAccessGroupsQuery);
  const accessGroupsOptions = availableAccessGroups?.getCommonLibraryAccessGroups?.map((group) => ({
    label: group,
    value: group,
  }));
  if (!specialOptions)
    accessGroupsOptions?.unshift({ value: 'ALL', label: t('superAdmin.CommonLibraryAccessGroups.all') });
  if (specialOptions && specialOptions.length) accessGroupsOptions?.push(...specialOptions);
  return (
    <SelectInputField
      name={fieldName}
      options={accessGroupsOptions}
      label={label}
      tooltip={tooltip}
      placeholder={placeholder}
      className={className}
      mode="tags"
      size="small"
    />
  );
};

export default AccessGroupsMultiSelect;
