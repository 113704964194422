import { useEffect, useState } from 'react';

/**
 * @typedef {Object} FileFetchResult
 * @property {Uint8Array} data - Binary data of fetched file
 * @property {Object} error - Error, if request was bad or failed
 */

/**
 * Fetch file from remote and returns it as Uint8Array
 * @param {String} url - Url to file
 * @param {string} fileType - Type of file like 'application/pdf'
 * @returns {FileFetchResult} returns fetched file as data, if successful fetched, error, if request was bad or failed and returns data and error as null, when loading
 */
const useFetchFileAsUint8Array = (url, fileType) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setData(null);
    setError(null);

    if (!url || !fileType) {
      const errorObject = new Error('No url or file type set!');
      errorObject.status = 'Bad request';
      setError(errorObject);
    }

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': fileType,
      },
    })
      .then((response) => {
        if (!response.ok) {
          const errorObject = new Error(response.statusText);
          errorObject.status = response.status;
          throw errorObject;
        }
        return response.arrayBuffer();
      })
      .then((arrayBuffer) => setData(new Uint8Array(arrayBuffer)))
      .catch((errorObject) => {
        if (errorObject.status) {
          setError({ message: errorObject.message, status: errorObject.status });
        } else {
          setError({ message: errorObject.message, status: 'Network error' });
        }
      });
  }, [url, fileType]);

  return { data, error };
};

export default useFetchFileAsUint8Array;
