import { gql } from '@apollo/client';
import { adminTenantSettingsFragment } from 'graphql/fragments';

const adminTenantSettingsQuery = gql`
  query adminTenantSettingsQuery {
    tenantSettings {
      ...adminTenantSettingsFragment
    }
  }
  ${adminTenantSettingsFragment}
`;

export default adminTenantSettingsQuery;
