import React, { useMemo, useRef, useEffect } from 'react';
import { Button } from 'antd';
import { useFormikContext } from 'formik';
import { SubmitButton } from 'formik-antd';
import { debounce, isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';

const AUTOSAVE_INTERVAL = 1000;

/**
 * SaveChangesButton component is a button component that allows users to save changes made to a form.
 * This component is typically used in conjunction with the DigitalSignaturePreferencesForm.
 *
 * @param {Object} inputParameters - The props object containing the following properties:
 * @param {Object} inputParameters.style - The style object to be applied to the component.
 * @param {number} inputParameters.marginTop=16 - The margin top value for the component.
 * @param {Object} inputParameters.initialValues - The initial values of the form.
 * @param {boolean} inputParameters.withDisable=false- A boolean value indicating whether the button should be disabled.
 * @param {boolean} inputParameters.autosaveEnabled=false - A boolean value indicating whether autosave is enabled.
 * @param {Function} inputParameters.onClick=() => true - The function to be called when the button is clicked.
 * @param {string} inputParameters.title - The title of the button.
 * @param {String[]} inputParameters.excludeFields=[] - An array of fields to be excluded from the isDirty check.
 * @param {String[]} inputParameters.fieldsInScope=[] - An array of fields to be only included in the isDirty check.
 * @returns {JSX.Element} - The SaveChangesButton component.
 * @component
 */
const SaveChangesButton = ({
  style,
  marginTop = 16,
  initialValues,
  withDisable = false,
  forceDisable = false,
  autosaveEnabled = false,
  onClick = () => true,
  title,
  forceVisible = false,
  excludeFields = [],
  fieldsInScope = [],
}) => {
  const { t } = useTranslation();
  const { values, handleSubmit, isSubmitting, isValid } = useFormikContext();
  const isChangedValues = useRef(false);

  const SaveButton = withDisable ? SubmitButton : Button;

  const debounced = useMemo(
    () =>
      debounce(async () => {
        await handleSubmit();
        isChangedValues.current = true;
      }, AUTOSAVE_INTERVAL),
    [handleSubmit],
  );

  const handleSubmitWithConfirmation = (e) => {
    Promise.resolve(onClick(values, e)).then((res) => {
      if (!res) return;
      handleSubmit(e);
    });
  };

  const shouldBeIgnoredForCheck = (fieldName) => excludeFields.includes(fieldName);
  const shouldOnlyBeIncludedForCheck = (fieldName) => fieldsInScope.length > 0 && !fieldsInScope.includes(fieldName);
  const shouldOnlyBeIncludedIfDirty = (fieldName) => isEqual(values[fieldName], initialValues[fieldName]);

  const canBeSaved = Object.keys(values).some((key) => {
    if (shouldBeIgnoredForCheck(key)) return false;

    if (shouldOnlyBeIncludedForCheck(key)) return false;

    if (shouldOnlyBeIncludedIfDirty(key)) return false;

    // The form can only be saved if any of the above conditions are not met
    return true;
  });

  useEffect(() => {
    if (autosaveEnabled && canBeSaved) {
      debounced();
    }
  }, [autosaveEnabled, canBeSaved, debounced, values]);

  if (autosaveEnabled && canBeSaved) return <div className="autosave-changes-button">{t('common.saving')}</div>;
  if (autosaveEnabled)
    return isChangedValues.current ? <div className="autosave-changes-button">{t('common.saved')}</div> : null;
  return (
    (canBeSaved || forceVisible) && (
      <SaveButton
        style={{ marginTop, ...style }}
        onClick={handleSubmitWithConfirmation}
        disabled={isSubmitting || forceDisable || !isValid}
        type="primary"
        className="save-changes-button"
      >
        {title ?? t('admin.textsTabSubmitButton')}
      </SaveButton>
    )
  );
};

export default SaveChangesButton;
