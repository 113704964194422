import { gql } from '@apollo/client';

const openSigningProcessesChangedSubscription = gql`
  subscription OpenSigningProcessesChanged {
    openSigningProcessesChanged {
      signingProcesses {
        link
        projectName
        nameOfClient
        shoppingCartId
      }
    }
  }
`;

export default openSigningProcessesChangedSubscription;
