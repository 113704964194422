import i18n from 'i18n';

export const formatBackendError = (error) => {
  const message = `BackendErrors.${error.message}`;
  const translatedData = error?.extensions?.exception?.i18nVariables || {};
  if (/^(usedInFormula\.)/.test(error.message)) {
    translatedData.count =
      translatedData?.items?.length ||
      translatedData?.staticItemCategories?.length ||
      translatedData?.emailTemplates?.length ||
      translatedData?.documentTemplates?.length;
    translatedData.tenant = translatedData?.tenant?.name;
    translatedData.documentTemplates = translatedData?.documentTemplates
      ?.map((document) => `"${document?.name}"`)
      ?.join(', ');
    translatedData.emailTemplates = translatedData?.emailTemplates
      ?.map((emailTemplate) => `"${emailTemplate?.name}"`)
      ?.join(', ');
    translatedData.items = translatedData?.items
      ?.map(
        (item) =>
          `"${item?.name}"${
            item?.category ? ` ${i18n.t('BackendErrors.usedInFormula.category')} "${item?.category.name}"` : ''
          }`,
      )
      ?.join(', ');
    translatedData.staticItemCategories = translatedData?.staticItemCategories
      ?.map((category) => `"${category.name}"`)
      ?.join(', ');
  }
  const translated = i18n.exists(message) ? i18n.t(message, translatedData) : message;
  if (translated !== message) {
    // eslint-disable-next-line no-param-reassign
    error.originalMessage = error.message;
    // eslint-disable-next-line no-param-reassign
    error.message = translated;
  }
};
