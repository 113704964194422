import cn from 'classnames';
import useLogo from 'hooks/signing/useLogo';
import classes from './TenantLogo.module.less';

const TenantLogo = ({ className, getLogoUrlUrl, altLogo }) => {
  const { logoURL } = useLogo(getLogoUrlUrl);
  let logo = null;
  if (altLogo) logo = <img src={altLogo} alt="companyLogo" height="60px" />;
  if (!altLogo && logoURL) logo = <img src={logoURL} alt="companyLogo" height="60px" />;
  return <div className={cn(classes.logo, className)}>{logo}</div>;
};

export default TenantLogo;
