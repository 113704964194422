import { gql } from '@apollo/client';
import userTotalValueFragment from './userTotalValueFragment';

const userShoppingCartHistoryFragment = gql`
  fragment userShoppingCartHistoryFragment on ShoppingCart {
    _id
    categories {
      _apolloCacheKey
      _id
      discountId
      inputFields {
        _apolloCacheKey
        _id
        value
      }
      itemIds
      startOfService
    }
    createdBy {
      _id
      fullName
      profile {
        firstName
        lastName
      }
    }
    createdAt
    feeType

    monthly {
      ...userTotalValueFragment
    }
    monthlyAdvance {
      ...userTotalValueFragment
    }
    oneOff {
      ...userTotalValueFragment
    }
    yearly {
      ...userTotalValueFragment
    }
  }
  ${userTotalValueFragment}
`;

export default userShoppingCartHistoryFragment;
