import { gql } from '@apollo/client';

const adminUserFragment = gql`
  fragment adminUserFragment on User {
    _id
    role
    email
    profile {
      _id
      firstName
      lastName
      salutation
      position
      phone
      fullSalutation
    }
    state
    tenantId
    locale
    isAlpha
    isDev
    customSignatureImage
  }
`;

export default adminUserFragment;
