/* eslint-disable jsx-a11y/iframe-has-title */
import Modal from 'antd/lib/modal/Modal';
import { useEffect, useState } from 'react';
import { toastGraphQLError } from 'utils/helpers';

const PDFViewer = ({ file }) => {
  // const url = `https://docs.google.com/viewerng/viewer?url=${this.props.src}&embedded=true`;
  const url = `${window.location.origin}/web/viewer.html?file=${encodeURIComponent(file.replace(':4000', ':3000'))}`;
  // console.log(url);
  return (
    <iframe
      style={{
        marginTop: 24,
        width: 'calc(90vw - 48px)',
        height: 'calc(100vh - 50px - 24px - 24px - 53px - 24px - 20px - 10px)',
      }}
      src={url}
    />
  );
  // return <iframe style={{ marginTop: 24, width: 'calc(90vw - 48px)', height: 'calc(100vh - 100px - 24px - 24px - 53px - 24px - 20px - 10px)' }} src={url} />;
};

const ItemModalComponent = (props) => {
  const {
    // categoryId,
    closeModal,
    formatError,
    handleClose,
    headerText,
    isClosing,
    isSubmitting,
    // onSubmit,
    value,
  } = props;
  const [url, setUrl] = useState();
  useEffect(() => value.then(setUrl).catch(toastGraphQLError), [value]);

  return (
    <Modal
      afterClose={closeModal}
      confirmLoading={isSubmitting}
      formatError={formatError}
      onOk={handleClose}
      onCancel={handleClose}
      title={headerText}
      visible={!isClosing}
      width="90vw"
      className="modal-top-50px"
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <div>{url ? <PDFViewer file={url} /> : 'Loading Pdf...'}</div>
    </Modal>
  );
};
export default ItemModalComponent;
