/**
 * Creates the config text for the text file for dkim, spf and dmarc configuration
 * @param {String} domain - Domain for which the config is
 * @param {Object} dkim - Dkim config
 * @param {String} dkim.hostname - Hostname for dkim entry
 * @param {String} dkim.value - Value for dkim entry
 * @param {Object} spf - Spf config
 * @param {String} spf.hostname - Hostname for spf entry
 * @param {String} spf.value - Value for spf entry
 * @param {Object} dmarc - Dmarc config
 * @param {String} dmarc.hostname - Hostname for dmarc entry
 * @param {String} dmarc.value - Value for dmarc entry
 * @returns {String} config text as string
 */
const createConfigText = (domain, dkim, spf, dmarc) => {
  const { hostname: dkimHostname, value: dkimValue } = dkim;
  const { hostname: spfHostname, value: spfValue } = spf;
  const { hostname: dmarcHostname, value: dmarcValue } = dmarc;

  const text = `# DNS Config for dkim, spf and dmarc for email sending

Domain to authentificate: ${domain}

## DKIM Entry

Type: TXT
Hostname: ${dkimHostname}
Value: ${dkimValue}

## SPF Entry

Type: TXT
Hostname: ${spfHostname}
Value: ${spfValue}

## Optional: DMARC Entry
We recommend to set also a dmarc entry for the domain to increase deliverability of the emails.

Type: TXT
Hostname: ${dmarcHostname}
Value: ${dmarcValue}`;

  return text;
};

/**
 * Generates file name for config file
 * @param {String} domain - Domain for which the config is
 * @returns {String} config name
 */
const createFileName = (domain) => `${domain?.replaceAll('.', '_')}-dkim_spf_dmarc_configuration.txt`;

/**
 * Generates and downloads text file with config for dkim, spf and dmarc
 * @param {String} domain - Domain for which the config is
 * @param {Object} dkim - Dkim config
 * @param {String} dkim.hostname - Hostname for dkim entry
 * @param {String} dkim.value - Value for dkim entry
 * @param {Object} spf - Spf config
 * @param {String} spf.hostname - Hostname for spf entry
 * @param {String} spf.value - Value for spf entry
 * @param {Object} dmarc - Dmarc config
 * @param {String} dmarc.hostname - Hostname for dmarc entry
 * @param {String} dmarc.value - Value for dmarc entry
 */
export const downloadDNSConfig = (domain, dkim, spf, dmarc) => {
  const configText = createConfigText(domain, dkim, spf, dmarc);
  const file = new Blob([configText], { type: 'text/plain' });
  const href = URL.createObjectURL(file);
  const anchorElement = document.createElement('a');
  anchorElement.href = href;
  anchorElement.download = createFileName(domain);
  document.querySelector('body').append(anchorElement);
  anchorElement.click();
  anchorElement.remove();
  URL.revokeObjectURL(href);
};
