import { Alert, Skeleton } from 'antd';
import equal from 'fast-deep-equal/es6/react';
import { getMessageFromGraphQLError } from 'utils/helpers';

const { useMemo, memo } = require('react');

const GraphQLLoadingWrapper = ({ data, loading, error, children, countSkeletons = 2, isAlwaysDisplay }) => {
  const skeletons = useMemo(() => {
    return [...new Array(countSkeletons)].map((_, i) => i + 1);
  }, [countSkeletons]);
  return (
    <>
      {error ? <Alert type="error" message={getMessageFromGraphQLError(error)} /> : null}
      {skeletons.map((k) => (
        <Skeleton title loading={!data && loading} active key={k} />
      ))}
      {((!loading && isAlwaysDisplay) || data) && children}
    </>
  );
};

export default memo(GraphQLLoadingWrapper, equal);
