import { useTranslation } from 'react-i18next';
import PageContainer from 'components/layout/PageContainer';
import DigitalSignatureKillSwitchCard from './DigitalSignatureKillSwitchCard';
import StatisticCard from './StatisticCard';
import DefaultTemplatesCard from './DefaultTemplatesCard';

/**
 * manage digital signature page component
 * @returns {JSX.Element} ManageDigitalSignaturePage component for the global digital signature management page for super admin
 * @component
 */
const ManageDigitalSignaturePage = () => {
  const { t } = useTranslation();
  return (
    <PageContainer title={t('superAdmin.ManageDigitalSignature.title')}>
      <StatisticCard />
      <DigitalSignatureKillSwitchCard />
      <DefaultTemplatesCard />
    </PageContainer>
  );
};

export default ManageDigitalSignaturePage;
