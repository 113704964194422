// import { formatDateTime } from 'utils/date';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import DocumentBlockDragLayer from 'components/admin/drag/DragLayer/DocumentTemplate/DocumentBlockDragLayer';
import { HelperIcon } from 'components/common/HelperIcon';
import { getDocumentTemplateBlocksCache } from 'graphql/cache/get';
import BlockController from './BlockController';
import AddBlockModal from './AddBlockModal';
import classes from './DocumentLayoutBlock.module.less';
import DocumentLayoutDraggable from './DocumentLayoutDraggable';

export default function DocumentLayout({ _id }) {
  const { t } = useTranslation();
  // const initialValues = getFormInitialValues(documentTemplate);
  const [isAddBlockModalVisible, setIsAddBlockModalVisible] = useState(false);
  const [newBlockKeys, setNewBlockKeys] = useState([]);

  const mutation = useCallback(
    (_, data) => {
      BlockController.persistBlocks({ _id, blocks: data });
    },
    [_id],
  );

  const getDocumentTemplate = useCallback(() => getDocumentTemplateBlocksCache({ _id }), [_id]);

  return (
    <Card
      className={classes.container}
      title={
        <>
          {t('admin.DocumentTemplateConfigurationPage.layoutSection.title')}{' '}
          <HelperIcon title={t('admin.DocumentTemplateConfigurationPage.layoutSection.tooltip')} />
        </>
      }
      extra={
        <Button icon={<PlusOutlined />} type="primary" onClick={() => setIsAddBlockModalVisible(true)}>
          {t('admin.DocumentTemplateConfigurationPage.layoutSection.addBlockButton')}
        </Button>
      }
    >
      <DocumentBlockDragLayer />
      <DocumentLayoutDraggable
        _id={_id}
        newBlockKeys={newBlockKeys}
        getDocumentTemplate={getDocumentTemplate}
        moveMutation={mutation}
      />
      <AddBlockModal
        isVisible={isAddBlockModalVisible}
        changeActiveKey={({ blockId }) => {
          setNewBlockKeys([...newBlockKeys, blockId]);
        }}
        close={useCallback(() => setIsAddBlockModalVisible(false), [])}
        getDocumentTemplate={getDocumentTemplate}
      />
    </Card>
  );
}
