import { useMemo } from 'react';
import { Alert, Skeleton } from 'antd';
import { getMessageFromGraphQLError } from 'utils/helpers';
import { updateTenantSettings } from 'graphql/methods';
import { useCachedQuery } from 'graphql/utils';
import {
  adminEmailTemplatesQuery,
  adminDocumentTemplateListQuery,
  userDigitalSignatureEnabledGlobalStateQuery,
} from 'graphql/queries';
import I18nFormik from 'components/common/I18nFormik';
import SaveChangesButton from 'components/common/SaveChangesButton';
import usePreferences from 'hooks/user/usePreferences';
import { SEPA_MODE } from 'constants/shoppingCart';

/**
 * DigitalSignaturePreferencesForm component is the form component for the digital signature preferences page, which contains the formik context and the save button
 * @param {Object} inputParameters - Input parameters of the component
 * @param {JSX.Element} inputParameters.children - Children components for the form with the form inputs
 * @returns {JSX.Element} form component for the digital signature preferences page
 * @component
 */
const DigitalSignaturePreferencesForm = ({ children }) => {
  const { preferences, loading, error: preferencesError } = usePreferences({ fetchPolicy: 'cache-and-network' });
  const {
    data: emailTemplatesData,
    loading: emailTemplatesLoading,
    error: emailTemplatesError,
  } = useCachedQuery(adminEmailTemplatesQuery, {
    variables: { isLibrary: false },
    fetchPolicy: 'cache-and-network',
  });
  const {
    data: documentTemplatesData,
    loading: documentTemplatesLoading,
    error: documentTemplatesError,
  } = useCachedQuery(adminDocumentTemplateListQuery, {
    variables: { isLibrary: false },
    fetchPolicy: 'cache-and-network',
  });
  const {
    loading: dsStateGloballyLoading,
    data: dsStateGloballyData,
    error: dsStateGloballyError,
  } = useCachedQuery(userDigitalSignatureEnabledGlobalStateQuery, { fetchPolicy: 'cache-and-network' });

  const isData = preferences && emailTemplatesData && documentTemplatesData && dsStateGloballyData;
  const isSomethingLoading = loading || emailTemplatesLoading || documentTemplatesLoading || dsStateGloballyLoading;
  const error = preferencesError || emailTemplatesError || documentTemplatesError || dsStateGloballyError;

  const skeletons = [...new Array(2)].map((_, i) => i + 1);

  const { digitalSignaturePreferences } = preferences || {};

  const initialValues = useMemo(() => {
    return {
      automaticRemindSigneesAboutSignatureProcess:
        digitalSignaturePreferences?.automaticRemindSigneesAboutSignatureProcess || false,
      automaticRemindSigneesAboutSignatureProcessInterval:
        digitalSignaturePreferences?.automaticRemindSigneesAboutSignatureProcessInterval || 7,
      digitalSignatureEnabled: digitalSignaturePreferences?.digitalSignatureEnabled || false,
      notifyUsersByMail:
        digitalSignaturePreferences?.notifyUsersByMail !== null ? digitalSignaturePreferences?.notifyUsersByMail : true,
      passwordEnabled:
        digitalSignaturePreferences?.passwordEnabled !== null ? digitalSignaturePreferences?.passwordEnabled : true,
      autoSignForTenantSigneesEnabled: digitalSignaturePreferences?.autoSignForTenantSigneesEnabled || false,
      defaultLinkValidityTime: digitalSignaturePreferences?.defaultLinkValidityTime || 1,
      defaultTenantSignees: digitalSignaturePreferences?.defaultTenantSignees || [],
      notifyClientSigneesForDigitalSignatureStartTemplate:
        digitalSignaturePreferences?.notifyClientSigneesForDigitalSignatureStartTemplate || null,
      notifySigneesAboutSignedBySignee: digitalSignaturePreferences?.notifySigneesAboutSignedBySignee || false,
      notifySigneesAboutSignedBySigneeTemplate:
        digitalSignaturePreferences?.notifySigneesAboutSignedBySigneeTemplate || null,
      notifyTenantSigneesForDigitalSignatureStartTemplate:
        digitalSignaturePreferences?.notifyTenantSigneesForDigitalSignatureStartTemplate || null,
      sendPasswordToSigneesTemplate: digitalSignaturePreferences?.sendPasswordToSigneesTemplate || null,
      noticeOfCancellationDocumentTemplate: digitalSignaturePreferences?.noticeOfCancellationDocumentTemplate || null,
      notifySigneesForRevokeTemplate: digitalSignaturePreferences?.notifySigneesForRevokeTemplate || null,
      notifySigneesForSuccessfulFinishTemplate:
        digitalSignaturePreferences?.notifySigneesForSuccessfulFinishTemplate || null,
      notifyTenantSigneesAboutOpenBySignee: digitalSignaturePreferences?.notifyTenantSigneesAboutOpenBySignee || false,
      notifyTenantSigneesAboutOpenBySigneeTemplate:
        digitalSignaturePreferences?.notifyTenantSigneesAboutOpenBySigneeTemplate || null,
      remindSigneeAboutDigitalSignatureProcessTemplate:
        digitalSignaturePreferences?.remindSigneeAboutDigitalSignatureProcessTemplate || null,
      postSuccessfulFinishState: digitalSignaturePreferences?.postSuccessfulFinishState || null,
      sepaDocumentTemplate: digitalSignaturePreferences?.sepaDocumentTemplate || null,
      sepaEnabled: digitalSignaturePreferences?.sepaEnabled || false,
      sepaIntroductionText: digitalSignaturePreferences?.sepaIntroductionText || null,
      sepaMandateCompanyText: digitalSignaturePreferences?.sepaMandateCompanyText || null,
      sepaMandatePrivateText: digitalSignaturePreferences?.sepaMandatePrivateText || null,
      sepaMode: digitalSignaturePreferences?.sepaMode || SEPA_MODE.optional,
    };
  }, [
    digitalSignaturePreferences?.automaticRemindSigneesAboutSignatureProcess,
    digitalSignaturePreferences?.automaticRemindSigneesAboutSignatureProcessInterval,
    digitalSignaturePreferences?.digitalSignatureEnabled,
    digitalSignaturePreferences?.notifyUsersByMail,
    digitalSignaturePreferences?.passwordEnabled,
    digitalSignaturePreferences?.autoSignForTenantSigneesEnabled,
    digitalSignaturePreferences?.defaultLinkValidityTime,
    digitalSignaturePreferences?.defaultTenantSignees,
    digitalSignaturePreferences?.notifyClientSigneesForDigitalSignatureStartTemplate,
    digitalSignaturePreferences?.notifyTenantSigneesForDigitalSignatureStartTemplate,
    digitalSignaturePreferences?.sendPasswordToSigneesTemplate,
    digitalSignaturePreferences?.noticeOfCancellationDocumentTemplate,
    digitalSignaturePreferences?.notifySigneesAboutSignedBySignee,
    digitalSignaturePreferences?.notifySigneesAboutSignedBySigneeTemplate,
    digitalSignaturePreferences?.notifySigneesForRevokeTemplate,
    digitalSignaturePreferences?.notifySigneesForSuccessfulFinishTemplate,
    digitalSignaturePreferences?.notifyTenantSigneesAboutOpenBySignee,
    digitalSignaturePreferences?.notifyTenantSigneesAboutOpenBySigneeTemplate,
    digitalSignaturePreferences?.remindSigneeAboutDigitalSignatureProcessTemplate,
    digitalSignaturePreferences?.postSuccessfulFinishState,
    digitalSignaturePreferences?.sepaDocumentTemplate,
    digitalSignaturePreferences?.sepaEnabled,
    digitalSignaturePreferences?.sepaIntroductionText,
    digitalSignaturePreferences?.sepaMandateCompanyText,
    digitalSignaturePreferences?.sepaMandatePrivateText,
    digitalSignaturePreferences?.sepaMode,
  ]);

  return (
    <>
      {error ? <Alert type="error" message={getMessageFromGraphQLError(error)} /> : null}
      {!isData && isSomethingLoading ? skeletons.map((k) => <Skeleton title loading active key={k} />) : null}
      {isData ? (
        <I18nFormik
          initialValues={initialValues}
          onSubmit={async ({
            automaticRemindSigneesAboutSignatureProcess,
            automaticRemindSigneesAboutSignatureProcessInterval,
            digitalSignatureEnabled,
            notifyUsersByMail,
            passwordEnabled,
            autoSignForTenantSigneesEnabled,
            defaultLinkValidityTime,
            defaultTenantSignees,
            notifyClientSigneesForDigitalSignatureStartTemplate,
            notifyTenantSigneesForDigitalSignatureStartTemplate,
            sendPasswordToSigneesTemplate,
            noticeOfCancellationDocumentTemplate,
            notifySigneesAboutSignedBySignee,
            notifySigneesAboutSignedBySigneeTemplate,
            notifySigneesForRevokeTemplate,
            notifySigneesForSuccessfulFinishTemplate,
            notifyTenantSigneesAboutOpenBySignee,
            notifyTenantSigneesAboutOpenBySigneeTemplate,
            remindSigneeAboutDigitalSignatureProcessTemplate,
            postSuccessfulFinishState,
            sepaDocumentTemplate,
            sepaEnabled,
            sepaIntroductionText,
            sepaMandateCompanyText,
            sepaMandatePrivateText,
            sepaMode,
          }) => {
            return updateTenantSettings({
              settings: {
                digitalSignaturePreferences: {
                  automaticRemindSigneesAboutSignatureProcess,
                  automaticRemindSigneesAboutSignatureProcessInterval,
                  digitalSignatureEnabled,
                  notifyUsersByMail,
                  passwordEnabled,
                  autoSignForTenantSigneesEnabled,
                  defaultLinkValidityTime,
                  defaultTenantSignees: defaultTenantSignees || null,
                  notifyClientSigneesForDigitalSignatureStartTemplate:
                    notifyClientSigneesForDigitalSignatureStartTemplate || null,
                  notifyTenantSigneesForDigitalSignatureStartTemplate:
                    notifyTenantSigneesForDigitalSignatureStartTemplate || null,
                  sendPasswordToSigneesTemplate: sendPasswordToSigneesTemplate || null,
                  noticeOfCancellationDocumentTemplate: noticeOfCancellationDocumentTemplate || null,
                  notifySigneesAboutSignedBySignee,
                  notifySigneesAboutSignedBySigneeTemplate: notifySigneesAboutSignedBySigneeTemplate || null,
                  notifySigneesForRevokeTemplate: notifySigneesForRevokeTemplate || null,
                  notifySigneesForSuccessfulFinishTemplate: notifySigneesForSuccessfulFinishTemplate || null,
                  notifyTenantSigneesAboutOpenBySignee,
                  notifyTenantSigneesAboutOpenBySigneeTemplate: notifyTenantSigneesAboutOpenBySigneeTemplate || null,
                  remindSigneeAboutDigitalSignatureProcessTemplate:
                    remindSigneeAboutDigitalSignatureProcessTemplate || null,
                  postSuccessfulFinishState: postSuccessfulFinishState || null,
                  sepaDocumentTemplate,
                  sepaEnabled,
                  sepaIntroductionText,
                  sepaMandateCompanyText,
                  sepaMandatePrivateText,
                  sepaMode,
                },
              },
            });
          }}
          enableReinitialize
        >
          {children}
          <SaveChangesButton initialValues={initialValues} />
        </I18nFormik>
      ) : null}
    </>
  );
};

export default DigitalSignaturePreferencesForm;
