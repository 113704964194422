import { gql } from '@apollo/client';
import { userVariableListFragment } from 'graphql/fragments';

const userVariableListQuery = gql`
  query userVariableListQuery($isLibrary: Boolean, $shoppingCartId: ID) {
    variables(isLibrary: $isLibrary, shoppingCartId: $shoppingCartId) {
      ...userVariableListFragment
    }
  }
  ${userVariableListFragment}
`;

export default userVariableListQuery;
