import apollo from 'graphql/apollo';
import { addDocumentsToShoppingCartMutation } from 'graphql/mutations';
import { userShoppingCartPDFsQuery } from 'graphql/queries';

const addDocumentsToShoppingCart = async ({ shoppingCartId, documentIds }) =>
  apollo.mutate({
    mutation: addDocumentsToShoppingCartMutation,
    variables: {
      shoppingCartId,
      documentIds,
    },
    refetchQueries: [{ query: userShoppingCartPDFsQuery, variables: { _id: shoppingCartId } }],
  });

export default addDocumentsToShoppingCart;
