// import ReactPlayer from 'react-player';
import { Row, Col, Grid, Alert } from 'antd';
import { MoreInfoWidgetContent } from 'components/common/MoreInfoWidget';
import { memo, useMemo } from 'react';
import equal from 'fast-deep-equal/es6/react';
import c from './OnboardingStepTemplate.module.less';
import WizardNavigationButtons from './WizardNavigationButtons';

function OnboardingStepTemplate({
  title,
  titleButton,
  description,
  videoUrl,
  videoCaption,
  children,
  isSubmitting,
  onNext,
  onPrev,
  hideNavigationButtons,
  showAlert,
  alertProp = {},
}) {
  const screens = Grid.useBreakpoint();
  const isDesktop = screens.md;
  const showInfoColumn = useMemo(() => description || videoUrl, [description, videoUrl]);
  return (
    <>
      <div className={c.container}>
        {!titleButton ? <h1>{title}</h1> : null}
        {showAlert ? <Alert showIcon closable {...alertProp} /> : null}

        <Row gutter={10}>
          {showInfoColumn ? (
            <Col xs={{ order: 1, span: 24 }} md={{ span: 10, order: 2 }} className={c.videoCol}>
              {description ? <p>{description}</p> : null}
              <div className={isDesktop ? c.stickyWrapper : null}>
                <div className={c.playerWrapper}>
                  {videoUrl ? (
                    <MoreInfoWidgetContent
                      {...{
                        videoUrl,
                        playerProps: {
                          style: { position: 'absolute', left: 0, top: 0 },
                          width: '100%',
                          height: '100%',
                        },
                      }}
                    />
                  ) : null}
                </div>
                {videoCaption ? <div>{videoCaption}</div> : null}
              </div>
            </Col>
          ) : null}
          {children ? (
            <Col xs={{ order: 2, span: 24 }} md={{ span: showInfoColumn ? 14 : 24, order: 1 }}>
              {titleButton ? (
                <Row align="middle">
                  <Col flex="auto">
                    <h1>{title}</h1>
                  </Col>
                  <Col className="margin-bottom-16">{titleButton}</Col>
                </Row>
              ) : null}
              {children}
            </Col>
          ) : null}
        </Row>
      </div>
      {!hideNavigationButtons ? <WizardNavigationButtons {...{ onNext, isSubmitting, onPrev }} /> : null}
    </>
  );
}

export default memo(OnboardingStepTemplate, equal);
