import { getDocumentTemplateBlockByIdCache } from 'graphql/cache';
import CollapseDrag from '../CollapseDrag';

const DocumentBlockDrag = ({ _id }) => {
  const data = getDocumentTemplateBlockByIdCache(_id);

  return <CollapseDrag label={data?.name} />;
};

export default DocumentBlockDrag;
