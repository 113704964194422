import { gql } from '@apollo/client';
import { userCompanyFragment } from 'graphql/fragments';

const createCompanyMutation = gql`
  mutation createCompanyMutation($company: CompanyInput!) {
    createCompany(company: $company) {
      ...userCompanyFragment
    }
  }
  ${userCompanyFragment}
`;

export default createCompanyMutation;
