import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import PageContainer from 'components/layout/PageContainer';
import equal from 'fast-deep-equal/es6/react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { createTenantModal } from './TenantForm';
import TenantList from './TenantList';

function ManageTenants() {
  const { t } = useTranslation();
  return (
    <PageContainer
      title={t('superAdmin.ManageTenants.title')}
      right={
        <div className="ant-modal-confirm-btns">
          <Button icon={<PlusOutlined />} onClick={createTenantModal} type="primary">
            {t('superAdmin.ManageTenants.addTenant')}
          </Button>
        </div>
      }
    >
      <TenantList />
    </PageContainer>
  );
}

export default memo(ManageTenants, equal);
