import I18nFormik from 'components/common/I18nFormik';
import equal from 'fast-deep-equal/es6/react';
import { memo, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'formik-antd';
import { Slider } from 'antd';
import { AlertFromFormik } from 'components/common/ErrorComponent';
import SaveChangesButton from 'components/common/SaveChangesButton';
import { adminEmailPreferencesQuery } from 'graphql/queries';
import { useCachedQuery } from 'graphql/utils';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { updateTenantSettings } from 'graphql/methods';
import { SettingOutlined } from '@ant-design/icons';
import Card from 'components/common/Card';
import GraphQLLoadingWrapper from 'components/common/GraphQLLoadingWrapper';
import * as Yup from 'yup';
import i18n from 'i18n';
import FormItem from 'components/common/FormItem';
import { useFormikField } from 'hooks/common/useFormikField';
import Switch from 'components/common/Switch';

const bccSchema = () => ({
  bcc: Yup.string().email().nullable().label(i18n.t('admin.Setting.Email.inputs.bcc.label')),
});
const otherEmailSettingSchema = () => Yup.object().shape(bccSchema());

const BCCEmailFormikInput = (props) => {
  const { t } = useTranslation();
  return (
    <FormItem name="bcc" label={t('admin.Setting.Email.inputs.bcc.label')} {...props}>
      <Input name="bcc" />
    </FormItem>
  );
};
const AttachmentsSizeLimitFormikSlider = ({ mailServiceProvider, ...props }) => {
  const { t } = useTranslation();
  const { onChange, value } = useFormikField('attachmentsSizeLimit');
  const marks = useMemo(() => ({ [value]: value }), [value]);
  return (
    <FormItem name="attachmentsSizeLimit" label={t('admin.Setting.Email.inputs.attachmentsSizeLimit.label')} {...props}>
      <Slider
        defaultValue={value}
        name="attachmentsSizeLimit"
        max={mailServiceProvider === 'default' ? 4 : 50}
        marks={marks}
        onAfterChange={(v) => {
          onChange(v);
        }}
      />
    </FormItem>
  );
};

const AddActiveUserToBCCSwitch = () => {
  const { t } = useTranslation();
  return (
    <Switch
      name="activeUserToBCC"
      label={t('admin.Setting.Email.inputs.activeUserToBCC.label')}
      checkedChildren={t('common.on')}
      unCheckedChildren={t('common.off')}
      tooltip={t('admin.Setting.Email.inputs.activeUserToBCC.tooltip')}
    />
  );
};

const OtherEmailSettings = ({ mailServiceProvider }) => {
  const formikRef = useRef();
  const { data, ...rest } = useCachedQuery(adminEmailPreferencesQuery);
  const { emailPreferences } = useMemo(() => grabFirstGQLDataResult(data) || {}, [data]);
  const { t } = useTranslation();
  const initialValues = useMemo(
    () => ({
      bcc: emailPreferences?.bcc ?? '',
      attachmentsSizeLimit: emailPreferences?.attachmentsSizeLimit ?? 0,
      activeUserToBCC: emailPreferences?.activeUserToBCC ?? false,
    }),
    [emailPreferences],
  );
  const onSubmit = useCallback(async ({ bcc, attachmentsSizeLimit, activeUserToBCC }) => {
    await updateTenantSettings({
      settings: {
        emailPreferences: {
          attachmentsSizeLimit,
          bcc,
          activeUserToBCC,
        },
      },
    });
  }, []);
  return (
    <GraphQLLoadingWrapper data={data} {...rest} countSkeletons={1}>
      <Card icon={<SettingOutlined />} title={t('admin.Setting.Email.otherSettingTitle')}>
        <I18nFormik
          ref={formikRef}
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={otherEmailSettingSchema}
          enableReinitialize
        >
          <Form layout="vertical">
            <AlertFromFormik />
            <BCCEmailFormikInput />
            <AttachmentsSizeLimitFormikSlider mailServiceProvider={mailServiceProvider} />
            <AddActiveUserToBCCSwitch />
            <SaveChangesButton initialValues={initialValues} />
          </Form>
        </I18nFormik>
      </Card>
    </GraphQLLoadingWrapper>
  );
};
export default memo(OtherEmailSettings, equal);
