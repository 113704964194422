import { Space, message } from 'antd';
import I18nFormik from 'components/common/I18nFormik';
import { Radio as RadioFormik, Form } from 'formik-antd';
import { Radio } from 'components/common/Radio';
import FormItem from 'components/common/FormItem';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { useMutation } from '@apollo/client';
import monthlyPaymentDecisionMutation from 'graphql/mutations/signing/monthlyPaymentDecisionMutation';
import useSigningGraphqlContext from 'hooks/signing/useSigningGraphqlContext';

/**
 * DecisionForm component which represents the form to decide on monthly payment
 * @param {Object} inputParameters - Input parameters of the component
 * @param {React.Ref} inputParameters.submitReference - Reference, which accesses the onContinue function, which tries to submit the form on call
 * @param {Boolean} inputParameters.disabled - If step was already done the form should render as disabled
 * @param {Object} inputParameters.defaultValues - Default values
 * @param {Function} inputParameters.onDecisionChange - Function that handles if the value changed
 * @param {Object} inputParameters.labels - Labels for the radio buttons
 * @returns {JSX.Element} form for decition step
 * @component
 */
const DecisionForm = ({ submitReference, disabled, defaultValues, onDecisionChange, labels, onError }) => {
  const { t: translation } = useTranslation();
  const innerRef = useRef();
  const signingGraphlQlContext = useSigningGraphqlContext();
  const [decide] = useMutation(monthlyPaymentDecisionMutation, {
    context: signingGraphlQlContext,
  });

  // eslint-disable-next-line no-param-reassign
  submitReference.current = {
    showContinueLoading: true,
    onContinue: async () => {
      if (innerRef?.current?.submitForm) {
        await innerRef.current.submitForm();
        return innerRef?.current?.isValid && { stepState: innerRef?.current?.values };
      }
      return false;
    },
  };

  const onSubmit = async ({ decision }, formik) => {
    const errors = {};

    if (disabled) {
      return;
    }

    if (!decision || !['yes', 'no'].includes(decision)) {
      errors.decision = translation('signing.monthlyPaymentDecisionStep.errors.noOptionSelected');
      formik.setErrors(errors);
      return;
    }

    // call mutation and wait before navigating
    try {
      await decide({ variables: { decision } });
    } catch (e) {
      message.error(e.message, 5);
      formik.setErrors({ decision: e.message });
      onError && onError();
    }
  };

  return (
    <I18nFormik initialValues={defaultValues} onSubmit={onSubmit} innerRef={innerRef} submitForm>
      <Form>
        <FormItem name="decision" id="decision" style={{ marginLeft: '2px', marginBottom: '8px' }}>
          <RadioFormik.Group name="decision" disabled={disabled} onChange={(e) => onDecisionChange(e.target.value)}>
            <Space direction="vertical">
              <Radio name="decision" value="yes" disabled={disabled}>
                {labels.yes}
              </Radio>
              <Radio name="decision" value="no" disabled={disabled}>
                {labels.no}
              </Radio>
            </Space>
          </RadioFormik.Group>
        </FormItem>
      </Form>
    </I18nFormik>
  );
};

export default DecisionForm;
