import { gql } from '@apollo/client';

const startDigitalSignatureMutation = gql`
  mutation startDigitalSignatureProcess($shoppingCartId: ID!, $emailProps: EmailPropsInput) {
    startDigitalSignatureProcess(shoppingCartId: $shoppingCartId, emailProps: $emailProps) {
      _id
    }
  }
`;

export default startDigitalSignatureMutation;
