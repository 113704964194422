import Card from 'components/common/Card';
import { FaSignature } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import usePreferences from 'hooks/user/usePreferences';
import useSubscribeToOpenSigningProcesses from 'hooks/user/useSubscibeToOpenSigningProcesses';
import OpenSigningProcessesTable from 'components/user/openSigningProcessesTable/OpenSigningProcessesTable';

/**
 * OpenSigningProcessesCard component shows the current open signing processes for current logged in user in dashboard
 * @returns {JSX.Element} Card with table with current signing processes and navigation optins
 * @component
 */
const OpenSigningProcessesCard = () => {
  const { t } = useTranslation();
  const { preferences } = usePreferences();
  const skipSubscription = !preferences?.digitalSignaturePreferences?.digitalSignatureEnabled;
  const { acticeSigningProcesses } = useSubscribeToOpenSigningProcesses(skipSubscription);

  if (!preferences?.digitalSignaturePreferences?.digitalSignatureEnabled) {
    return null;
  }

  return (
    <Card className="ant-card-head-borderless" icon={<FaSignature />} title={t('DashboardPage.digitalSignature.title')}>
      <OpenSigningProcessesTable openSigningProcesses={acticeSigningProcesses} />
    </Card>
  );
};

export default OpenSigningProcessesCard;
