import { Checkbox as CheckboxAntd } from 'antd';
import c from './Checkbox.module.less';

const Checkbox = ({ onChange, children }) => {
  1;

  return (
    <CheckboxAntd className={c.checkbox} onChange={onChange}>
      {children}
    </CheckboxAntd>
  );
};

export default Checkbox;
