import equal from 'fast-deep-equal/es6/react';
import { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CalculationMode } from 'constants/item';

const TranslationPrice = ({ currentFeeTypeMessage, priceTranslation, customPrice, showCalculatedPrice }) => {
  const { t } = useTranslation();
  const isTextDecoration =
    priceTranslation.variables.calculationMode !== CalculationMode.ON_ACTUAL_COST &&
    Number.isFinite(customPrice) &&
    (currentFeeTypeMessage || !showCalculatedPrice);
  return (
    <span className={isTextDecoration ? 'text-decoration-style-dotted' : ''}>
      <Trans
        i18nKey={`sharedPackage.${priceTranslation.code}`}
        components={{ del: <del /> }}
        values={{
          ...priceTranslation.variables,
          newlineOrWhitespace: '<br/>',
          paymentInterval: t(`common.Item.paymentIntervalValue.${priceTranslation.variables.paymentInterval}`, {
            defaultValue: '',
          }),
          separator: ',',
        }}
      />
    </span>
  );
};

export default memo(TranslationPrice, equal);
