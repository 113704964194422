import Field from 'formik-antd/es/field';
import { useCreateRefCB, useFunctionToRefCB } from 'memo';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce, has } from 'lodash';

const FieldWithDebounce = (props) => {
  const { debounceTime, value, component: C, onChange: onChange_, onBlur: _onBlur, ...rest } = props;
  const [internalValue, setInternalValue] = useState(value);
  const [showValue, setShowValue] = useState(value);
  useEffect(() => setShowValue(value), [value]);
  const updateInternalValue = useFunctionToRefCB(() => {
    if (value !== internalValue) setInternalValue(value);
  });
  useEffect(() => {
    updateInternalValue(value);
  }, [updateInternalValue, value]);
  const onChangeCB = useFunctionToRefCB(onChange_);
  const onChangeDebounced = useMemo(
    () => (debounceTime ? debounce(onChangeCB, debounceTime) : onChangeCB),
    [debounceTime, onChangeCB],
  );
  const onChange = useCallback(
    (event) => {
      const newValue = has(event, 'target.value') ? event.target.value : event;
      setShowValue(newValue);
      onChangeDebounced(newValue);
    },
    [onChangeDebounced],
  );
  const onBlur = useCallback(
    (event) => {
      _onBlur(event);
      setInternalValue(showValue);
      onChangeDebounced(showValue);
    },
    [_onBlur, onChangeDebounced, showValue],
  );
  return (
    <C
      onChange={onChange}
      onBlur={onBlur}
      value={showValue ?? null /* when the undefined field becomes uncontrolled  */}
      onPressEnter={onBlur}
      {...rest}
    />
  );
};

export const FieldFormikWithDebounce = (_a) => {
  const { name, validate, fast, onChange: $onChange, onBlur: $onBlur, component, ...restProps } = _a;
  const memoBlur = useCreateRefCB();
  const memoChange = useCreateRefCB();
  return (
    <Field {...{ name, validate, fast }}>
      {({ field: { value, onBlur }, form: { setFieldValueAndTouched } }) => (
        <FieldWithDebounce
          {...{
            name,
            value,
            // eslint-disable-next-line no-shadow
            onChange: memoChange((value) => {
              setFieldValueAndTouched(name, value);
              $onChange && $onChange(value);
            }),
            onBlur: memoBlur((event) => {
              onBlur(event);
              $onBlur && $onBlur(event);
            }),
            component,
            ...restProps,
          }}
        />
      )}
    </Field>
  );
};
export default FieldFormikWithDebounce;
