import { Trans } from 'react-i18next';

/**
 * CustomPriceInformation component to show custom price info text for service price in service overview step in signing process
 * @param {Object} inputParameters - Input parameters of the component
 * @param {ServicePriceInformation} - Information about the service price
 * @returns {JSX.Element} info text about custom price
 * @component
 */
const CustomPriceInformation = ({ priceInformations }) => {
  if (priceInformations.isFixedMonthlyFee) return null;
  if (!priceInformations.showCalculatedPrice) return null;

  const components = { s: <s /> };
  const values = { valueWithoutDiscount: priceInformations.formattedCalculatedPrice };
  return (
    <Trans
      i18nKey="signing.serviceOverviewStep.service.customPriceInformation"
      components={components}
      values={values}
    />
  );
};

export default CustomPriceInformation;
