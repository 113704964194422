import apollo from 'graphql/apollo';
import { createCompanyMutation } from 'graphql/mutations';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { userCompanyListQuery } from 'graphql/queries';

const createCompany = async ({
  city,
  cityCode,
  commercialObject,
  country,
  houseNumber,
  identifier,
  name,
  street,
  type,
}) =>
  apollo.mutate({
    mutation: createCompanyMutation,
    variables: {
      company: {
        address: {
          city,
          cityCode,
          country,
          houseNumber,
          street,
        },
        commercialObject,
        identifier,
        name,
        type,
      },
    },
    update: (cache, { data }) => {
      const result = grabFirstGQLDataResult(data);
      const cachedData = cache.readQuery({ query: userCompanyListQuery });
      cache.writeQuery({
        query: userCompanyListQuery,
        data: {
          companies: [result, ...cachedData.companies],
        },
      });
    },
  });

export default createCompany;
