import gql from 'graphql-tag';
import { userComparingDocumentTemplatesFragment } from 'graphql/fragments';

const userComparingDocumentTemplatesQuery = gql`
  query userComparingDocumentTemplatesQuery($isLibrary: Boolean) {
    documentTemplates(isLibrary: $isLibrary) {
      ...userComparingDocumentTemplatesFragment
    }
  }
  ${userComparingDocumentTemplatesFragment}
`;

export default userComparingDocumentTemplatesQuery;
