import { Button, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { isMobileDevice } from 'utils/helpers';
import useDownloadFile from 'hooks/signing/useDownloadFile';
import usePrintFile from 'hooks/signing/usePrintFile';

/**
 * PdfDrawerFooter component for the PdfDrawer with buttons for close print and download
 * @param {Object} inputParameters - Input parameters for the component
 * @param {String} inputParameters.url - Url to the file, which is currently shown in the drawer and which should be printed/downloaded
 * @param {String} inputParameters.fileType - Type of file for print and download
 * @param {String} inputParameters.fileName - Name of file for download
 * @param {Function} inputParameters.onClose - Function, which should be called on press of close button
 * @param {U8intArray} inputParameters.data - Already downloaded data (locale in browser), for download and print
 * @returns {JSX.Element} Footer component for pdf drawer to close drawer and print or download document as file
 * @component
 */
const PdfDrawerFooter = ({ url, fileType, fileName, onClose, data }) => {
  const { t } = useTranslation();
  const { downloadFile } = useDownloadFile(url, fileType, fileName);
  const { printFile } = usePrintFile(url, fileType, fileName);

  return (
    <Space>
      <Button onClick={onClose} type="primary">
        {t('PdfDrawer.closeButton')}
      </Button>
      {!isMobileDevice() ? <Button onClick={() => printFile(data)}>{t('PdfDrawer.printButton')}</Button> : null}
      <Button onClick={() => downloadFile(data)}>{t('PdfDrawer.downloadButton')}</Button>
    </Space>
  );
};

export default PdfDrawerFooter;
