/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Button as AntdButton } from 'antd';
import { memo } from 'react';
import s from './ButtonSupportOnClickWhenDisabled.module.less';

const ButtonSupportOnClickWhenDisabled = ({
  buttonProps: { disabledProps, ...buttonProps } = {},
  children,
  ...props
}) => {
  const { disabled, onClick } = props;
  const disabledButtonProps = disabledProps || buttonProps;
  if (disabled) {
    return (
      <div className={s['button-container']}>
        <div className={s['above-button']} onClick={onClick} />
        <AntdButton {...props} {...buttonProps} {...disabledButtonProps}>
          {children}
        </AntdButton>
      </div>
    );
  }
  return (
    <AntdButton {...props} {...buttonProps}>
      {children}
    </AntdButton>
  );
};
export default memo(ButtonSupportOnClickWhenDisabled);
