import { gql } from '@apollo/client';

const userComparingDocumentTemplatesFragment = gql`
  fragment userComparingDocumentTemplatesFragment on DocumentTemplate {
    _id
    name
    customizableBlocks {
      _id
      _apolloCacheKey
      name
      props
      descriptor {
        _id
        name
        displayName
        form
      }
    }
    isSignable
  }
`;

export default userComparingDocumentTemplatesFragment;
