import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import { memo, useMemo } from 'react';
import equal from 'fast-deep-equal/es6/react';
import SaveChangesButton from 'components/common/SaveChangesButton';
import FormItem from 'components/common/FormItem';
import { Form, Input } from 'formik-antd';
import { updateTheme } from 'graphql/methods';
import I18nFormik from 'components/common/I18nFormik';
import { ColorPickerFormikInput, FontFormikInput } from './components/Inputs';
import classes from './CorporateIdentitySetting.module.less';

function TableOfContent({ tableOfContent }) {
  const { t } = useTranslation();
  const initialValues = useMemo(
    () => ({
      tableOfContent: {
        fontFamily: tableOfContent?.fontFamily || 'ProximaNova',
        color: tableOfContent?.color || '#000000',
        fontSize: tableOfContent?.fontSize || '11pt',
      },
    }),
    [tableOfContent],
  );
  const name = 'tableOfContent';

  return (
    <Card title={t('admin.Setting.CorporateIdentity.TableOfContent.title')}>
      <I18nFormik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(modifier) => {
          updateTheme({ pdf: modifier });
        }}
      >
        <Form layout="vertical">
          <div className={classes.tableOfContentFields}>
            <FormItem
              name={`${name}.fontFamily`}
              label={t('admin.Setting.CorporateIdentity.TableOfContent.field.fontFamily')}
            >
              <FontFormikInput name={`${name}.fontFamily`} />
            </FormItem>
            <FormItem name={`${name}.color`} label={t('admin.Setting.CorporateIdentity.TableOfContent.field.color')}>
              <ColorPickerFormikInput name={`${name}.color`} />
            </FormItem>
            <FormItem
              name={`${name}.fontFamily`}
              label={t('admin.Setting.CorporateIdentity.TableOfContent.field.fontSize')}
            >
              <Input name={`${name}.fontSize`} />
            </FormItem>
          </div>
          <SaveChangesButton initialValues={initialValues} />
        </Form>
      </I18nFormik>
    </Card>
  );
}

export default memo(TableOfContent, equal);
