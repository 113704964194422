import apollo from 'graphql/apollo';
import { updateUserMutation } from 'graphql/mutations';
import { adminUsersInTenantQuery } from 'graphql/queries';

const updateUser = async ({ profile, role, email, userId }) =>
  apollo.mutate({
    mutation: updateUserMutation,
    variables: { profile, role, email, userId },
    refetchQueries: [{ query: adminUsersInTenantQuery }],
  });

export default updateUser;
