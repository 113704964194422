import { useCachedQuery } from 'graphql/utils';
import { Alert, Skeleton, Tabs, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import PageContainer from 'components/layout/PageContainer';
import { pick, omit } from 'lodash';
import toast from 'utils/toast';
import { setGraphqlErrors } from 'components/common/ErrorComponent';
import { moveItemInputField, updateCategoryItem } from 'graphql/methods';
import { useCallback } from 'react';
import InputFieldsTab from 'components/admin/InputFieldsTab';
import { adminCategoryItemQuery } from 'graphql/queries';
import ConditionalVisibilityTab from 'components/admin/ConditionalVisibilityTab';
import { ImportedAlert } from 'components/admin/itemModal/components';
import moveItemInputFieldsCache from 'graphql/cache/moveItemInputFieldsCache';
import { useSortEnd } from 'components/admin/drag/DragTable';
import { checkChangesInModal, grabFirstGQLDataResult } from 'utils/helpers';
import { useParams } from 'react-router-dom';
import { CalculationMode } from 'constants/item';
import { RichText } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { CopyOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useLibraryContext } from 'contexts/LibraryContext';
import { isKanzleipilotTeamUser } from 'components/user/shoppingCart/utils';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import copyToClipboard from 'utils/copyToClipboard';
import PriceTab from './PriceTab';
import TextsTab from './TextsTab';
import classes from './ItemCatalogueConfiguration.module.less';

const skeletons = [...new Array(2)].map((_, i) => i + 1);

const { TabPane } = Tabs;

const useItem = ({ _id }) => {
  const { loading, data } = useCachedQuery(adminCategoryItemQuery, {
    variables: { _id },
    fetchPolicy: 'cache-and-network',
  });
  const item = data?.categoryItem;

  const itemMapped = item && { ...item, scales: item.scales && item.scales.map(({ __typename, ...rest }) => rest) };
  return { item: itemMapped, loading };
};

const ItemCatalogueConfiguration = () => {
  const [user] = useCurrentUser();
  const { id: _id, categoryId } = useParams();
  const { loading, item } = useItem({ _id });
  const inputFields = item?.inputFields;
  const { t } = useTranslation();
  const { isLibrary } = useLibraryContext();
  const allInitialValues = pick(item, [
    'calculationMode',
    'highlightBindingness',
    'infoText',
    'minPrice',
    'name',
    'paymentInterval',
    'pricingFormula',
    'scalePricingFormulaTitle',
    'scaleTitle',
    'scales',
    'scalesEnabled',
    'scalesScaleUnitName',
    'scalesUnitName',
  ]);
  const update = useCallback(
    (modifier, formik) =>
      updateCategoryItem({
        _id,
        ...allInitialValues,
        ...(modifier.calculationMode === CalculationMode.ON_ACTUAL_COST
          ? omit(modifier, [
              'graduatedScaleMode',
              'highlightBindingness',
              'minPrice',
              'pricingFormula',
              'roundPriceId',
              'scalePricingFormulaTitle',
              'scaleTitle',
              'scales',
              'scalesEnabled',
              'scalesScaleUnitName',
              'scalesUnitName',
            ])
          : modifier),
      })
        .then((response) => {
          const warnings = grabFirstGQLDataResult(response.data)?.warnings;
          warnings &&
            warnings.forEach((warning) => toast.warning(t(`BackendWarnings.${warning.key}`, warning.variables)));
          return response;
        })
        .catch((error) => {
          formik.setErrors(
            setGraphqlErrors({
              error,
              errorResolver: { Duplicated: ['name', t('admin.itemModal.duplicatedErrorMessage')] },
              formik,
              showErrorsInFormForValues: ['name'],
            }),
          );
        }),
    [_id, allInitialValues, t],
  );

  const moveInCache = useCallback((newData) => moveItemInputFieldsCache({ _id, inputFields: newData }), [_id]);

  const { onSortEnd } = useSortEnd({
    dataSource: inputFields,
    moveInCache,
    moveMutation: moveItemInputField,
    refetchQueryProps: { itemId: _id },
  });

  if (loading && !item) return skeletons.map((k) => <Skeleton title loading active key={k} />);
  if (!item) return null;
  return (
    <PageContainer
      topComponent={
        <div className="ant-page-header-heading-title">
          {t('admin.itemModal.editSubTitleForItem', { itemName: item.name })}
        </div>
      }
    >
      {isLibrary && !RichText.isRichTextEmpty(item.internalNote) ? (
        <Alert
          icon={<InfoCircleOutlined />}
          type="warning"
          description={<>{RichText.renderRichText(item.internalNote)}</>}
          showIcon
          closable
          message={t('admin.itemModal.internalNoteWarning')}
        />
      ) : null}
      {isKanzleipilotTeamUser(user) && item.originalCommonLibraryId ? (
        <Button
          className={classes.copyCLLinkButton}
          type="link"
          icon={<CopyOutlined />}
          onClick={() => {
            copyToClipboard(
              `${window?.location?.origin}/secure/admin/common-library/item/${item.originalCommonLibraryId}/${item.commonLibraryCategoryId}`,
              t('admin.itemModal.copied'),
            );
          }}
        >
          {t('admin.itemModal.copyCLLink')}
        </Button>
      ) : null}
      <ImportedAlert
        uuid={item._id}
        type="item"
        guidanceText={item.guidanceText}
        guidanceVideoId={item.guidanceVideoId}
      />
      <div className="xs-mt-20 white-background">
        <Tabs onTabClick={(tabId, event) => checkChangesInModal({ event })} destroyInactiveTabPane type="card">
          <TabPane tab={t('admin.itemModal.tabs.price')} key="1">
            <PriceTab
              _id={_id}
              onSubmit={update}
              calculationMode={item.calculationMode}
              highlightBindingness={item.highlightBindingness}
              graduatedScaleMode={item.graduatedScaleMode}
              minPrice={item.minPrice}
              paymentInterval={item.paymentInterval}
              pricingFormula={item.pricingFormula}
              roundPriceId={item.roundPriceId}
              scalePricingFormulaTitle={item.scalePricingFormulaTitle}
              scaleTitle={item.scaleTitle}
              scales={item.scales}
              scalesEnabled={item.scalesEnabled}
              scalesScaleUnitName={item.scalesScaleUnitName}
              scalesUnitName={item.scalesUnitName}
              categoryId={categoryId}
            />
          </TabPane>
          <TabPane tab={t('admin.itemModal.tabs.inputs')} key="2">
            <div className="margin-16 margin-top-0">
              <InputFieldsTab
                inputFields={inputFields}
                parentId={_id}
                categoryId={categoryId}
                parentType="categoryItem"
                onSortEnd={onSortEnd}
              />
            </div>
          </TabPane>
          <TabPane tab={t('admin.itemModal.tabs.texts')} key="3">
            <TextsTab
              initialValues={{
                internalNote: item.internalNote || RichText.getDefaultRichEditorValue(),
                benefits: item.benefits || RichText.getDefaultRichEditorValue(),
                descriptionForContract: item.descriptionForContract || RichText.getDefaultRichEditorValue(),
                guidanceText: item.guidanceText || RichText.getDefaultRichEditorValue(),
                guidanceVideoId: item.guidanceVideoId,
                infoText: item.infoText || RichText.getDefaultRichEditorValue(),
                name: item.name || '',
                notesToEmployee: item.notesToEmployee || RichText.getDefaultRichEditorValue(),
                notesForFeeAgreement: item.notesForFeeAgreement || RichText.getDefaultRichEditorValue(),
                pleaseNote: item.pleaseNote || RichText.getDefaultRichEditorValue(),
                subTitle: item.subTitle,
              }}
              onSubmit={update}
            />
          </TabPane>
          <TabPane tab={t('admin.itemModal.tabs.conditionalVisibility')} key="4">
            <div className="margin-16 margin-top-0">
              <ConditionalVisibilityTab parentId={_id} parentType="categoryItem" companyTypeIds={item.companyTypeIds} />
            </div>
          </TabPane>
        </Tabs>
      </div>
    </PageContainer>
  );
};

export default ItemCatalogueConfiguration;
