import { memo, useRef } from 'react';
import equal from 'fast-deep-equal/es6/react';
import { useTranslation } from 'react-i18next';
import { TableMemo } from 'memo';
import { sortBy } from 'lodash';
import { Mentions, getScaleRange, Calc } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { formatPaymentIntervalAndHighlightBindingness } from 'utils/formatValues';
import { PricingFormula } from 'components/admin/ItemsContainer';
import classes from './ItemCard.module.less';

const formatRoundPriceStep = (roundPrice) => {
  return roundPrice?.roundUpToMultiple || '';
};

const ItemInfoDebugMode = ({
  calculationMode,
  debugMode,
  highlightBindingness,
  internalInputFields,
  minPrice,
  paymentInterval,
  pricingFormulaExtended,
  roundPrice,
  showDigits,
}) => {
  const { t } = useTranslation();

  return debugMode ? (
    <div className="info-text">
      <div>
        {t('user.ShoppingCart.Category.Item.pricingFormula')}
        <PricingFormula
          calculationMode={calculationMode}
          pricingFormula={pricingFormulaExtended.formatted}
          inputFields={internalInputFields}
        />
      </div>
      {Number.isFinite(minPrice) ? (
        <div>
          {t('user.ShoppingCart.Category.Item.minPrice', { minPrice: Calc.formatCurrency(minPrice, { showDigits }) })}
        </div>
      ) : null}
      <div>
        {t('user.ShoppingCart.Category.Item.paymentIntervalAndHighlightBindingness', {
          paymentIntervalAndHighlightBindingness: formatPaymentIntervalAndHighlightBindingness({
            calculationMode,
            highlightBindingness,
            paymentInterval,
          }),
        })}
      </div>
      {roundPrice._id !== 'noRound' ? (
        <div>{t('user.ShoppingCart.Category.Item.roundPrice', { step: formatRoundPriceStep(roundPrice) })}</div>
      ) : null}
    </div>
  ) : null;
};
export const ItemInfoDebugModeMemo = memo(ItemInfoDebugMode, equal);

const getInfoItemTableColumns = ({ scalesRef, titlesScaleTable, showDigits }) => [
  {
    title: titlesScaleTable.value,
    key: 'value',
    width: 150,
    render: (v) => getScaleRange(scalesRef.current, v, { showDigits }),
  },
  {
    title: titlesScaleTable.pricingFormula,
    dataIndex: 'pricingFormula',
    render: (v) => Mentions.removeBrackets(Mentions.richTextToFormula(v)),
    key: 'pricingFormula',
  },
];

const ScaleInfoDebugMode = ({
  debugMode,
  scales,
  scaleTitle,
  scalePricingFormulaTitle,
  graduatedScaleMode,
  showDigits,
}) => {
  const { t } = useTranslation();
  const titlesScaleTable = {
    value: scaleTitle,
    pricingFormula: scalePricingFormulaTitle,
  };
  const sortedScales = sortBy(scales, 'value');
  const scalesRef = useRef();
  scalesRef.current = sortedScales;

  return debugMode && sortedScales.length ? (
    <div className={classes.scalesTable}>
      <TableMemo
        columns={getInfoItemTableColumns({ scalesRef, titlesScaleTable, showDigits })}
        dataSource={sortedScales}
        bordered
        pagination={false}
      />
      <div className={classes.itemText}>
        {graduatedScaleMode ? t('user.ShoppingCart.Category.Item.graduatedScaleMode') : null}
      </div>
    </div>
  ) : null;
};
export const ScaleInfoDebugModeMemo = memo(ScaleInfoDebugMode, equal);
