import { gql } from '@apollo/client';
import { userShoppingCartEntriesTableFragment } from 'graphql/fragments';

const userShoppingCartEntriesTable = gql`
  query shoppingCartEntries(
    $query: String
    $limit: Int!
    $skip: Int
    $sortField: String
    $sortOrder: Int
    $statusId: ID
    $digitalSignatureState: DigitalSignatureState
  ) {
    shoppingCartEntries(
      query: $query
      limit: $limit
      skip: $skip
      sortField: $sortField
      sortOrder: $sortOrder
      statusId: $statusId
      digitalSignatureState: $digitalSignatureState
    ) {
      _id
      count
      shoppingCarts {
        ...userShoppingCartEntriesTableFragment
      }
    }
  }
  ${userShoppingCartEntriesTableFragment}
`;

export default userShoppingCartEntriesTable;
