import { gql } from '@apollo/client';
import { adminDocumentTemplateListFragment } from 'graphql/fragments';

const duplicateDocumentTemplateMutation = gql`
  mutation duplicateDocumentTemplateMutation($documentTemplateId: ID!, $name: String!, $isLibrary: Boolean) {
    duplicateDocumentTemplate(documentTemplateId: $documentTemplateId, name: $name, isLibrary: $isLibrary) {
      ...adminDocumentTemplateListFragment
    }
  }
  ${adminDocumentTemplateListFragment}
`;

export default duplicateDocumentTemplateMutation;
