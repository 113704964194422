import { gql } from '@apollo/client';
import { adminEmailTemplateFragment } from 'graphql/fragments';

const updateEmailTemplateMutation = gql`
  mutation updateEmailTemplateMutation($_id: ID!, $modifier: EmailTemplateInput) {
    updateEmailTemplate(_id: $_id, modifier: $modifier) {
      ...adminEmailTemplateFragment
    }
  }
  ${adminEmailTemplateFragment}
`;

export default updateEmailTemplateMutation;
