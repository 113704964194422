import { useTranslation } from 'react-i18next';
import PageContainer from 'components/layout/PageContainer';
import DigitalSignaturePreferencesForm from 'components/admin/DigitalSignaturePreferences/DigitalSignaturePreferencesForm';
import DigitalSignaturePreferencesHelperWidget from 'components/admin/DigitalSignaturePreferences/DigitalSignaturePreferencesHelperWidget';
import GeneralSettingsCard from 'components/admin/DigitalSignaturePreferences/GeneralSettingsCard';
import NoticeOfCancellationCard from 'components/admin/DigitalSignaturePreferences/NoticeOfCancellationCard';
import AutomateSignatureProcessCard from 'components/admin/DigitalSignaturePreferences/AutomateSignatureProcessCard';
import AdditionalNotificationsSettingsCard from 'components/admin/DigitalSignaturePreferences/AdditionalNotificationSettingsCard';
import PageButtonBar from 'components/admin/DigitalSignaturePreferences/PageButtonBar/PageButtonBar';
import SEPASettings from 'components/admin/DigitalSignaturePreferences/SEPASettings';
import AlphaWarningAlert from './AlphaWarningAlert';

/**
 * DigitalSignaturePreferences component is the page component for the digital signature preferences for the tenant instance
 * @returns {JSX.Element} page for digital signature preferences of the tenant
 * @component
 */
const DigitalSignaturePreferences = () => {
  const { t } = useTranslation();
  return (
    <PageContainer
      title={t('admin.Setting.DigitalSignaturePreferences.title')}
      left={<DigitalSignaturePreferencesHelperWidget />}
      right={<PageButtonBar />}
    >
      <AlphaWarningAlert />
      <DigitalSignaturePreferencesForm>
        <GeneralSettingsCard />
        <SEPASettings />
        <NoticeOfCancellationCard />
        <AutomateSignatureProcessCard />
        <AdditionalNotificationsSettingsCard />
      </DigitalSignaturePreferencesForm>
    </PageContainer>
  );
};

export default DigitalSignaturePreferences;
