import apollo from 'graphql/apollo';
import { createHtmlPreviewMutation } from 'graphql/mutations';

const createHtmlPreview = async ({ shoppingCart, documentTemplate }) => {
  return (
    await apollo.mutate({
      mutation: createHtmlPreviewMutation,
      variables: { shoppingCart, documentTemplate },
    })
  )?.data.generateDocumentHTMLPreview;
};
export default createHtmlPreview;
