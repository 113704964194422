import { gql } from '@apollo/client';

const validateIbanMutation = gql`
  mutation validateIbanMutation($iban: String!) {
    validateIban(iban: $iban) {
      valid
      bic
      bankName
      bankCity
    }
  }
`;

export default validateIbanMutation;
