import { memo, useCallback, useMemo, useState, Fragment } from 'react';
import equal from 'fast-deep-equal';
import { Popover } from 'antd';
import { FcCancel, FcFlashOn, FcPlus } from 'react-icons/fc';
import { ComparisonTypes } from 'constants/shoppingCart';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { isMobile } from 'react-device-detect';
import classes from './DifferencesPopover.module.less';
import ChangedDifferencesDrawer from './ChangedDifferencesDrawer';

const icons = {
  [ComparisonTypes.DELETED]: ({ onClick }) => <FcCancel onClick={onClick} className="font-size-1-5" />,
  [ComparisonTypes.NEW]: ({ onClick }) => <FcPlus onClick={onClick} className="font-size-1-5" />,
  [ComparisonTypes.CHANGED]: ({ onClick }) => <FcFlashOn onClick={onClick} className="font-size-1-5" />,
};

const DifferencesComponent = memo(({ isOpen, setIsOpen, data, rows, changedProps }) => {
  if (!isOpen || data.comparisonType !== ComparisonTypes.CHANGED) return null;
  return <ChangedDifferencesDrawer {...changedProps} setIsOpen={setIsOpen} data={data} rows={rows} />;
}, equal);

const Differences = ({ comparisonType }) => {
  const { t } = useTranslation();
  if (ComparisonTypes.CHANGED === comparisonType) return t('user.CompareToGlobal.changed.label');
  if (ComparisonTypes.NEW === comparisonType) return t('user.CompareToGlobal.new.label');
  if (ComparisonTypes.DELETED === comparisonType) return t('user.CompareToGlobal.deleted.label');
  return null;
};

const DifferencesPopover = ({ className, data, rows, width, changedProps }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const Icon = useMemo(() => icons[data.comparisonType], [data.comparisonType]);
  const onClick = useCallback(() => {
    setVisible(!visible);
    setIsOpen(true);
  }, [visible]);
  const isFragment = useMemo(() => isMobile && ComparisonTypes.CHANGED === data.comparisonType, [data.comparisonType]);
  if (!Icon) return null;
  return (
    <>
      <DifferencesComponent isOpen={isOpen} setIsOpen={setIsOpen} data={data} rows={rows} changedProps={changedProps} />
      {isFragment ? (
        <Icon onClick={onClick} />
      ) : (
        <Popover
          visible={visible}
          onVisibleChange={setVisible}
          placement="bottomLeft"
          className={className}
          overlayClassName={cn(classes.popover, 'ant-popover-arrow-hide')}
          overlayStyle={{ width }}
          content={<Differences comparisonType={data.comparisonType} />}
        >
          <span>
            <Icon onClick={onClick} />
          </span>
        </Popover>
      )}
    </>
  );
};
export default memo(DifferencesPopover, equal);
