import { find, intersectionBy } from 'lodash';
import { useMemo } from 'react';

export const useFormatActiveCategories = (shoppingCartCategories, categories) => {
  return useMemo(
    () =>
      shoppingCartCategories
        .map((shoppingCartCategory) => {
          const category = find(categories, { _id: shoppingCartCategory._id });
          if (!category) return null;
          const activeDiscount = find(category.discounts, { _id: shoppingCartCategory.discountId });
          return {
            ...category,
            ...shoppingCartCategory,
            inputFields: intersectionBy(shoppingCartCategory.inputFields, category.inputFields, '_id').map(
              (inputField) => ({
                ...inputField,
                ...find(category.inputFields, { _id: inputField._id }),
              }),
            ),
            discount: activeDiscount,
            ...['items', 'bookmarkedItems'].reduce(
              (acc, name) => ({
                ...acc,
                [name]: shoppingCartCategory[name]
                  .map((shoppingCartItem) => {
                    const item = find(category.items, { _id: shoppingCartItem._id });
                    if (!item) return null;
                    return {
                      ...item,
                      ...shoppingCartItem,
                      activeDiscount,
                      inputFields: intersectionBy(shoppingCartCategory.inputFields, item?.inputFields, '_id').map(
                        (inputField) => ({
                          ...inputField,
                          ...find(item.inputFields, { _id: inputField._id }),
                        }),
                      ),
                    };
                  })
                  .filter(Boolean),
              }),
              {},
            ),
          };
        })
        .filter((category) => category && (category.items.length || category.bookmarkedItems.length)),
    [categories, shoppingCartCategories],
  );
};
