import { useTranslation } from 'react-i18next';

const ConfigurationHeading = ({ fieldDescriptor }) => {
  const { t } = useTranslation();

  const headingText = fieldDescriptor.translated
    ? fieldDescriptor.displayName
    : t(`admin.DocumentTemplateConfigurationPage.blockSubtitles.${fieldDescriptor.name}`);

  return <h4>{headingText}</h4>;
};

export default ConfigurationHeading;
