import { FaUserShield, FaExclamationCircle } from 'react-icons/fa';
import SignDocumentsLayout from '../../../components/layouts/SignDocumentsLayout';
import { Sans30px400, Sans35px600 } from '../Text/Text';
import { Br100, Br150, Br200 } from '../Text/Br';
import NextButton from '../NextButton';
import Note from '../Note';
import Checkbox from '../Checkbox';

const ReviewServices = ({ setPage }) => {
  return (
    <SignDocumentsLayout>
      <Sans35px600 icon={<FaUserShield />} iconStyle={{ fontSize: 33 }} mobileIconStyle={{ fontSize: 24 }}>
        Confirm your identity
      </Sans35px600>
      <Br100 />
      <Note title={<Sans30px400 icon={<FaExclamationCircle>123</FaExclamationCircle>}>Important note:</Sans30px400>}>
        <Sans30px400>
          If you are not the person mentioned below then you act illigally. We log IP-adresses and should not press
          forward. Please only press forward if you are really that person.
        </Sans30px400>
      </Note>
      <Br200 />
      <Sans30px400>{'Are you {firstname} {surname} and represent company {companyName}?'}</Sans30px400>
      <Br150 />
      <Checkbox>
        <Sans30px400>{'Yes, I confirm that I am {firstname} {surname}, representing {companyName}.'}</Sans30px400>
      </Checkbox>
      <Br200 />
      <NextButton
        onClick={() => {
          setPage('reviewServices');
        }}
      >
        Next
      </NextButton>
    </SignDocumentsLayout>
  );
};
export default ReviewServices;
