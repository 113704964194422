import { gql } from '@apollo/client';

export const verifyCustomDomainMutation = gql`
  mutation verifyCustomDomain($domain: String!) {
    verifyCustomDomain(domain: $domain) {
      verified
      lastChecked
    }
  }
`;
