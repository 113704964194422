import { useCallback } from 'react';
import { FaFile } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { formatIbanString } from 'utils/formatValues';
import { DocumentTable } from 'components/common/DocumentsCard/DocumentTable';
import CollapsableInformationCard from './CollapsableInformationCard';

/**
 * A React function component that renders a card displaying SEPA mandate information.
 *
 * @component
 * @param {Object} props - The properties passed to this component.
 * @param {string} props.bic - The BIC (Bank Identifier Code) associated with the SEPA mandate.
 * @param {string} props.iban - The IBAN (International Bank Account Number) associated with the SEPA mandate.
 * @param {string} props.accountOwnerName - The name of the account owner associated with the SEPA mandate.
 * @param {string} props.shoppingCartId - Database id of the related shopping cart
 * @param {string} props.sepaMandateDocumentTemplateId - Document template id of document template, which was used to generate the sepa mandate document
 * @returns {ReactElement} - A card component displaying the SEPA mandate information.
 * @description
 * `SepaMandateGrantedCell` is a React function component that displays a card with SEPA mandate information.
 * This includes the account owner's name, BIC, and formatted IBAN. The card can be toggled to show/hide the information.
 *
 * @example
 * <SepaMandateGrantedCell
 *   bic="DEUTDEDBBER"
 *   iban="DE89370400440532013000"
 *   accountOwnerName="John Doe"
 * />
 */
const SepaMandateGrantedCell = ({ bic, iban, accountOwnerName, shoppingCartId, sepaMandateDocumentTemplateId }) => {
  const { t } = useTranslation();
  const filterDocuments = useCallback(
    (documents) => {
      if (!documents) return documents;
      if (!sepaMandateDocumentTemplateId) return null;
      return documents.filter((document) => document.documentTemplateId === sepaMandateDocumentTemplateId);
    },
    [sepaMandateDocumentTemplateId],
  );

  return (
    <>
      <CollapsableInformationCard
        title={t(
          'viewer.ShoppingCartView.ShoppingCartHistoricItems.DigitalSignature.sepaMandateGrantedCard.bankaccountInformation',
        )}
      >
        <dd>
          <div>
            <span style={{ fontWeight: 'bold' }}>
              {t(
                'viewer.ShoppingCartView.ShoppingCartHistoricItems.DigitalSignature.sepaMandateGrantedCard.accountOwnerName',
              )}
              :
            </span>{' '}
            {accountOwnerName}
          </div>
          <div>
            <span style={{ fontWeight: 'bold' }}>IBAN:</span> {formatIbanString(iban)}
          </div>
          <div>
            <span style={{ fontWeight: 'bold' }}>BIC:</span> {bic}
          </div>
        </dd>
      </CollapsableInformationCard>
      {sepaMandateDocumentTemplateId ? (
        <DocumentTable
          filterDocuments={filterDocuments}
          shoppingCartId={shoppingCartId}
          icon={<FaFile />}
          title={t('user.ShoppingCart.SendEmail.attachments')}
          showBottomCell={false}
        />
      ) : null}
    </>
  );
};

export default SepaMandateGrantedCell;
