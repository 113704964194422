import { useMediaQuery } from 'react-responsive';

export const Br050 = () => {
  const isSmallWidthDevice = useMediaQuery({ maxWidth: 500 });

  return (
    <div style={{ fontSize: isSmallWidthDevice ? 5 : 6 }}>
      <br />
    </div>
  );
};
export const Br100 = () => {
  const isSmallWidthDevice = useMediaQuery({ maxWidth: 500 });

  return (
    <div style={{ fontSize: isSmallWidthDevice ? 11 : 13 }}>
      <br />
    </div>
  );
};
export const Br150 = () => {
  const isSmallWidthDevice = useMediaQuery({ maxWidth: 500 });

  return (
    <div style={{ fontSize: isSmallWidthDevice ? 14 : 18 }}>
      <br />
    </div>
  );
};
export const Br200 = () => {
  const isSmallWidthDevice = useMediaQuery({ maxWidth: 500 });

  return (
    <div style={{ fontSize: isSmallWidthDevice ? 22 : 26 }}>
      <br />
    </div>
  );
};
