import { InfoCircleOutlined } from '@ant-design/icons';
import { useCachedQuery } from 'graphql/utils';
import { Alert, Tooltip } from 'antd';
import { useFormikContext } from 'formik';
import { userCompanyListQuery } from 'graphql/queries';
import { castArray, findIndex, get, isEqual, lowerCase, trim } from 'lodash';
import difference from 'lodash/difference';
import find from 'lodash/find';
import merge from 'lodash/merge';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { useCountries } from 'utils/locale';
import * as Yup from 'yup';
import VirtualTable from './VirtualTable';

const addErrorIfNotUnique = (_array, queries) => {
  const f = (array, query) => {
    const { path, error } = query;
    const getParam = (object) => get(object, path);
    return array.map((item, index) => {
      const param = getParam(item);
      const firstIndex = findIndex(array, (o) => getParam(o) === param);
      if (param && firstIndex < index && !item.error) {
        return { ...item, error };
      }
      return item;
    });
  };
  return castArray(queries).reduce((accumulator, query) => f(accumulator, query), _array);
};

const customIsEqual = (a, b) => lowerCase(trim(a)) === lowerCase(trim(b));

const textSchemaRequire = () => Yup.string().required().min(1).nullable();

const schema = Yup.object().shape({
  importCompanyType: Yup.string().nullable(),
  identifier: textSchemaRequire(),
  name: textSchemaRequire(),
  street: textSchemaRequire(),
  houseNumber: Yup.string().nullable(),
  commercialObject: Yup.string().nullable(),
  cityCode: Yup.number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .required()
    .nullable(),
  city: textSchemaRequire(),
  country: textSchemaRequire(),
});

const UpdateRow = ({ object, fieldName, t }) => {
  return (
    <div>{`${t(`admin.Setting.ImportCompanies.fields.${fieldName}`)}: ${
      object[fieldName] ? `"${object[fieldName]}"` : '-'
    }`}</div>
  );
};

const UpdateObject = ({ companyFields, object }) => {
  const { t } = useTranslation();
  if (!object) return '-';
  return (
    <>
      {companyFields.map((companyField) => (
        <UpdateRow object={object} fieldName={companyField.columnTarget} t={t} />
      ))}
    </>
  );
};

const columnsRequiredFields = ({ t, companyFields }) => [
  {
    title: t('admin.Setting.ImportCompanies.table.status'),
    render: (rowValue, { error }) => {
      if (error) {
        return (
          <>
            {t('admin.Setting.ImportCompanies.status.invalid')}{' '}
            <Tooltip title={error} overlayStyle={{ maxWidth: '500px' }}>
              <InfoCircleOutlined />
            </Tooltip>
          </>
        );
      }
      return t('admin.Setting.ImportCompanies.status.valid');
    },
    key: 'status',
    width: 160,
    shouldCellUpdate: () => false,
  },
  {
    title: t('admin.Setting.ImportCompanies.table.importType.label'),
    render: (rowValue, { company }) => {
      const isUpdate = !!company;
      return isUpdate
        ? t('admin.Setting.ImportCompanies.table.importType.update')
        : t('admin.Setting.ImportCompanies.table.importType.insert');
    },
    key: 'importType',
    width: 120,
    shouldCellUpdate: () => false,
  },
  {
    title: t('admin.Setting.ImportCompanies.table.before'),
    render: (rowValue, { company }) => {
      return <UpdateObject companyFields={companyFields} object={company} />;
    },
    key: 'after',
    shouldCellUpdate: () => false,
  },
  {
    title: t('admin.Setting.ImportCompanies.table.after'),
    render: (rowValue, { afterObject }) => {
      return <UpdateObject companyFields={companyFields} object={afterObject} />;
    },
    key: 'after',
    shouldCellUpdate: () => false,
  },
];

const getCheckboxProps = ({ key, error }) => ({
  disabled: !!error, // Column configuration not to be checked
  key,
});

const Step4 = ({
  companiesData,
  companyFields,
  currentStep,
  license,
  numberCompaniesInsert,
  selectedRowKeys,
  setNumberCompaniesInsert,
  setSelectedRowKeys,
}) => {
  const { values } = useFormikContext();
  const { data } = useCachedQuery(userCompanyListQuery, { fetchPolicy: 'cache-and-network' });
  const companies = grabFirstGQLDataResult(data);
  const countries = useCountries();
  const { t } = useTranslation();
  const newCompaniesData = useMemo(() => {
    let _newCompaniesData = companiesData.map((companyData, index) => {
      const newCompanyData = {};
      companyFields.forEach((requiredField) => {
        newCompanyData[requiredField.columnTarget] = companyData[values[requiredField.columnTarget]];
      });
      if (!values.importCompanyType) {
        if (values.defaultCompanyType) newCompanyData.importCompanyType = values.defaultCompanyType;
      } else {
        newCompanyData.importCompanyType = companyData[values.importCompanyType] ? 'company' : 'individual';
      }
      newCompanyData.country =
        find(
          countries,
          (country) =>
            customIsEqual(country._id, newCompanyData.country) || customIsEqual(country.label, newCompanyData.country),
        )?._id || 'DE';
      let company = find(companies, { identifier: newCompanyData?.identifier?.toString() });
      company = company ? { ...company, ...company?.address, ...company?.importFields } : null;
      const afterObject = merge({}, company, newCompanyData);
      let error = '';
      try {
        schema.validateSync(afterObject);
      } catch (e) {
        error = e.message;
      }
      return { object: newCompanyData, company, key: index, afterObject, error };
    });
    _newCompaniesData = addErrorIfNotUnique(_newCompaniesData, [
      { path: 'object.identifier', error: t('admin.Setting.ImportCompanies.errors.duplicateIdentifier') },
      { path: 'object.name', error: t('admin.Setting.ImportCompanies.errors.duplicateName') },
    ]);
    return _newCompaniesData;
  }, [companies, companiesData, companyFields, countries, t, values]);

  useEffect(() => {
    const invalidKeys = newCompaniesData
      .filter((companyData) => !!companyData.error)
      .map((companyData) => companyData.key);
    const newSelectedRowKeys = difference(selectedRowKeys, invalidKeys);
    if (!isEqual(selectedRowKeys, newSelectedRowKeys)) {
      setSelectedRowKeys(newSelectedRowKeys);
    }
  }, [newCompaniesData, selectedRowKeys, setSelectedRowKeys]);

  // calculate numberCompaniesInsert
  useEffect(() => {
    const newNumberCompaniesInsert = newCompaniesData.filter(
      ({ company }, index) => !company && selectedRowKeys?.includes(index),
    ).length;
    if (newNumberCompaniesInsert !== numberCompaniesInsert) {
      setNumberCompaniesInsert(newNumberCompaniesInsert);
    }
  }, [newCompaniesData, numberCompaniesInsert, selectedRowKeys, setNumberCompaniesInsert]);
  const numberCompaniesUpdate = useMemo(
    () => newCompaniesData.filter(({ company }, index) => !!company && selectedRowKeys?.includes(index)).length,
    [newCompaniesData, selectedRowKeys],
  );

  if (currentStep !== 3) return null;
  return (
    <>
      <VirtualTable
        dataSource={newCompaniesData}
        columns={columnsRequiredFields({
          t,
          companyFields,
        })}
        height={600}
        rowSelection={{
          onChange: setSelectedRowKeys,
          type: 'checkbox',
          selectedRowKeys,
          getCheckboxProps,
        }}
      />
      <p>{t('admin.Setting.ImportCompanies.numberImport', { numberCompaniesInsert })}</p>
      <p>{t('admin.Setting.ImportCompanies.numberUpdate', { numberCompaniesUpdate })}</p>
      {/* eslint-disable-next-line no-unsafe-optional-chaining */}
      {license?.nrOfCompaniesLimit - license?.nrOfCompaniesCreated < numberCompaniesInsert ? (
        <Alert
          type="error"
          message={t('admin.Setting.ImportCompanies.alertLimitCompany', {
            numberCompaniesInsert,
            // eslint-disable-next-line no-unsafe-optional-chaining
            nrOfCompaniesLimit: license?.nrOfCompaniesLimit - license?.nrOfCompaniesCreated,
          })}
        />
      ) : null}
    </>
  );
};

export default Step4;
