import { Button, Empty, Popover, Space, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { EyeOutlined } from '@ant-design/icons';
import { FaSignature } from 'react-icons/fa';
import { generatePath, useNavigate } from 'react-router';
import routePaths from 'router/route-paths';
import cn from 'classnames';
import classes from './OpenSigningProcessesTable.module.less';

/**
 * OpenShoppingCartButton component is a button component for the open signing processes table to access the shopping cart related to current signing process
 * @param {Object} inputParameters - Input parameters of the component
 * @param {String} inputParameters.shoppingCartId - Id of related shopping cart object
 * @param {Function} inputParameters.onCloseAndNavigate - Function, which is called on press on the button and gets the link and a flag, if the link should be opened in a new tab
 * @returns {JSX.Element} button component to open shopping cart related to current signing process
 * @component
 */
const OpenShoppingCartButton = ({ shoppingCartId, onCloseAndNavigate }) => {
  const { t: translation } = useTranslation();
  const openShoppingCartClicked = () => {
    const link = generatePath(routePaths.shoppingCartView, { id: shoppingCartId });
    onCloseAndNavigate(link);
  };

  return (
    <Popover
      content={translation('DashboardPage.digitalSignature.table.openShoppingCart')}
      trigger="hover"
      placement="topRight"
    >
      <Button
        icon={<EyeOutlined />}
        onClick={openShoppingCartClicked}
        className={cn('ant-btn-default', classes.openShoppingCartButton)}
      />
    </Popover>
  );
};

/**
 * SignShoppingCartButton component is a button component for the open signing processes table to access the current signing process as signee
 * @param {Object} inputParameters - Input parameters of component
 * @param {String} inputParameters.signingLink - Current link token for the current logged in user for the signing process
 * @param {Function} inputParameters.onCloseAndNavigate - Function, which is called on press on the button and gets the link and a flag, if the link should be opened in a new tab
 * @returns {JSX.Element} button component to open current signing process
 * @component
 */
const SignShoppingCartButton = ({ signLink, onCloseAndNavigate }) => {
  const { t: translation } = useTranslation();
  const signShoppingCartClicked = () => {
    const link = generatePath(routePaths.signingStart, { token: signLink });
    onCloseAndNavigate(link, true);
  };

  return (
    <Popover
      content={translation('DashboardPage.digitalSignature.table.signShoppingCart')}
      trigger="hover"
      placement="topRight"
    >
      <Button
        icon={
          <span className="anticon">
            <FaSignature />
          </span>
        }
        onClick={signShoppingCartClicked}
        type="primary"
        ghost
        className={cn('ant-btn-default', classes.signShoppingCartButton)}
      />
    </Popover>
  );
};

/**
 * LinkColumnItem component for the link column cell of a signing process
 * @param {Object} inputParameters - Input parameters of the component
 * @param {Object} inputParameters.record - Record of the signing process
 * @param {String} inputParameters.record.shoppingCartId - Id of related shopping cart object
 * @param {String} inputParameters.record.link - Current link token for the current logged in user for the signing process
 * @param {Function} inputParameters.onCloseAndNavigate - Function, which is called on press on the button and gets the link and a flag, if the link should be opened in a new tab
 * @returns {JSX.Element} shows the button to navigate to the related shopping cart and to navigate to the signing process
 * @component
 */
const LinkColumnItem = ({ record, onCloseAndNavigate }) => {
  const { shoppingCartId, link } = record;

  return (
    <Space size="small">
      <OpenShoppingCartButton shoppingCartId={shoppingCartId} onCloseAndNavigate={onCloseAndNavigate} />
      <SignShoppingCartButton signLink={link} onCloseAndNavigate={onCloseAndNavigate} />
    </Space>
  );
};

/**
 * OpenSigningProcessesTable component to show and visit open signing processes and the related shopping carts
 * @param {Object} inputParameters - Input parameters for component
 * @param {Object[]} inputParameters.openSigningProcesses - Open signing process to show in the table
 * @param {String} inputParameters.openSigningProcesses.shoppingCartId - Id of related shopping cart object
 * @param {String} inputParameters.openSigningProcesses.link - Current link token for the current logged in user for the signing process
 * @param {String} inputParameters.openSigningProcesses.projectName - Name of the project
 * @param {String} inputParameters.openSigningProcesses.nameOfClient - Name of the related client
 * @returns {JSX.Element} table component for open signing processes for a logged in user
 * @component
 */
const OpenSigningProcessesTable = ({ openSigningProcesses }) => {
  const { t: translation } = useTranslation();
  const navigate = useNavigate();
  const closeAndNavigate = (pathToNavigate, inNewTab) => {
    if (inNewTab) {
      window.open(pathToNavigate.toString());
      return;
    }
    navigate(pathToNavigate);
  };

  const columns = [
    {
      title: translation('DashboardPage.digitalSignature.table.projectNameHeader'),
      dataIndex: 'projectName',
      key: 'projectName',
      width: '40%',
    },
    {
      title: translation('DashboardPage.digitalSignature.table.nameOfClientHeader'),
      dataIndex: 'nameOfClient',
      key: 'nameOfClient',
      width: '40%',
    },
    {
      title: translation('DashboardPage.digitalSignature.table.linksHeader'),
      dataIndex: '',
      key: 'links',
      width: '20%',
      render: (_, record) => <LinkColumnItem record={record} onCloseAndNavigate={closeAndNavigate} />,
    },
  ];
  const data = openSigningProcesses.map(({ shoppingCartId, link, projectName, nameOfClient }) => ({
    key: shoppingCartId,
    link,
    projectName,
    nameOfClient,
    shoppingCartId,
  }));

  return (
    <Table
      columns={columns}
      dataSource={data}
      size="small"
      locale={{
        emptyText: (
          <Empty description={<span>{translation('DashboardPage.digitalSignature.noActiveSigningProcesses')}</span>} />
        ),
      }}
      pagination={{ position: ['bottomRight'], hideOnSinglePage: true }}
    />
  );
};

export default OpenSigningProcessesTable;
