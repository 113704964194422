import React from 'react';
import { SubmitButton, Input, Form } from 'formik-antd';
import { LockOutlined } from '@ant-design/icons';
import * as Yup from 'yup';
import routePaths from 'router/route-paths';
import { AlertFromFormik, setGraphqlErrors } from 'components/common/ErrorComponent';
import { useTranslation } from 'react-i18next';
import FormItem from 'components/common/FormItem';
import I18nFormik from 'components/common/I18nFormik';
import i18n from 'i18n';
import { acceptInvitation } from 'graphql/methods';
import toast from 'utils/toast';
import { Alert } from 'antd';
import { useNavigate, useMatch } from 'react-router-dom';

const LoginSchema = () =>
  Yup.object().shape({
    password: Yup.string().required().min(8).max(64).label(i18n.t('common.fields.password.label')),
    passwordConfirmation: Yup.string()
      .required()
      .oneOf([Yup.ref('password'), null], i18n.t('ResetPassword.errors.noMatch'))
      .min(8)
      .max(64)
      .label(i18n.t('common.fields.passwordConfirmation.label')),
  });

const initialValues = {
  password: '',
  passwordConfirmation: '',
};

export default function AcceptInvitationForm() {
  const navigate = useNavigate();
  const match = useMatch(routePaths.acceptInvitation);

  const { t } = useTranslation();

  const onSubmit = async ({ password }, formik) => {
    try {
      formik.setSubmitting(true);
      await acceptInvitation({
        password,
        invitationToken: match.params.invitationToken,
      });
      toast.info(t('common.toast.success.acceptInvitation'));
      navigate(routePaths.home, { replace: true });
    } catch (error) {
      console.error(error);
      formik.setErrors(
        setGraphqlErrors({
          error,
          errorResolver: ({ message }) => {
            if (message.includes('password')) return ['password', message];
            return undefined;
          },
        }),
      );
    } finally {
      formik.setSubmitting(false);
    }
  };

  return (
    <I18nFormik initialValues={initialValues} onSubmit={onSubmit} validationSchema={LoginSchema()}>
      <Form layout="vertical">
        <AlertFromFormik />
        <Alert description={t('common.alertInAcceptInvitationForm')} type="info" showIcon />
        <FormItem name="password" label={t('Login.inputs.password.label')}>
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            name="password"
            placeholder={t('Login.inputs.password.placeholder')}
            autoComplete="current-password"
          />
        </FormItem>
        <FormItem name="passwordConfirmation" label={t('common.fields.passwordConfirmation.label')}>
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            name="passwordConfirmation"
            placeholder={t('common.fields.passwordConfirmation.placeholder')}
            autoComplete="current-password"
          />
        </FormItem>
        <SubmitButton className="margin-right-16">{t('common.activateYourAccount')}</SubmitButton>
      </Form>
    </I18nFormik>
  );
}
