import { gql } from '@apollo/client';

const adminCanCreateCompanyFragment = gql`
  fragment adminCanCreateCompanyFragment on CanCreateCompany {
    allow
    error
  }
`;

export default adminCanCreateCompanyFragment;
