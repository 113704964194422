import { useState } from 'react';
import { Button } from 'antd';
import { RightOutlined, DownOutlined } from '@ant-design/icons';
import Card from 'components/common/Card';
import classes from './CollapsableInformationCard.module.less';

/**
 * Collapsable card component for history items
 * @param {object} inputParameters - Input parameters of the component
 * @param {string|JSX.Element} inputParameters.title - Title for the card
 * @param {JSX.Element[]|JSX.Element} inputParameters.children - Content of the card as children
 * @returns {JSX.Element} card component, which is collapsable and closed in default
 * @component
 */
const CollapsableInformationCard = ({ title, children }) => {
  const [showCardOpen, setShowCardOpen] = useState(false);
  return (
    <Card
      className={classes.previewCard}
      title={title}
      extra={
        <Button
          icon={showCardOpen ? <DownOutlined /> : <RightOutlined />}
          type="text"
          onClick={() => setShowCardOpen((currentOpen) => !currentOpen)}
        />
      }
    >
      {showCardOpen ? children : null}
    </Card>
  );
};

export default CollapsableInformationCard;
