import { useCachedQuery } from 'graphql/utils';
import { Button, Tooltip, Menu } from 'antd';
import equal from 'fast-deep-equal/es6/react';
import { userPreferencesShoppingCartViewQuery } from 'graphql/queries';
import i18n from 'i18n';
import { intersectionBy } from 'lodash';
import { sendEmailModal } from 'pages/shoppingCartManagement/ShoppingCart/components/SendEmail';
import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GrMail } from 'react-icons/gr';
import { useNavigate, generatePath } from 'react-router-dom';
import routePaths from 'router/route-paths';
import confirmModal from 'utils/confirmModal';
import { grabFirstGQLDataResult } from 'utils/helpers';

const sendEmailConfirmation = ({ onOk }) => {
  return confirmModal({
    cancelText: i18n.t('common.cancel'),
    okText: i18n.t('common.ok'),
    onOk,
    closable: true,
    maskClosable: true,
    width: 800,
    title: i18n.t('viewer.ShoppingCartView.sendEmailConfirmationTitle'),
  });
};

const SendEmailButton = ({ tooltip, ...props }) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={tooltip}>
      <Button
        {...props}
        icon={
          <span className="anticon anticon-edit">
            <GrMail size={19} />
          </span>
        }
      >
        {t('viewer.ShoppingCartView.sendEmailButton')}
      </Button>
    </Tooltip>
  );
};

/**
 * Menu item for send email item
 * @param {Object} inputParameters - Input parameters of the component
 * @returns {JSX.Element} Menu item to send email for a shopping cart
 * @component
 */
const SendEmailMenuItem = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <Menu.Item {...props}>
      <span className="anticon">
        <GrMail />
      </span>
      {t('viewer.ShoppingCartView.sendEmailButton')}
    </Menu.Item>
  );
};

/**
 * SendEmail component represents the send email button/menu item and handles the email drawer
 * @param {Object} inputParameters - Input parameters of the component
 * @returns {JSX.Element} button/menu item with email drawer handling
 * @component
 */
const SendEmail = ({ preferences, shoppingCart, allRecipients = [], className, menuItem }) => {
  const navigate = useNavigate();
  const { emailTemplateId, signature } = useMemo(() => {
    return {
      emailTemplateId: preferences?.shoppingCartPreferences?.emailTemplateId,
      signature: preferences?.emailPreferences?.signature,
    };
  }, [preferences?.emailPreferences?.signature, preferences?.shoppingCartPreferences?.emailTemplateId]);

  const [initialValues, setInitialValues] = useState({
    emailTemplateId,
    signature,
  });

  const onCloseAfter = useCallback((formik) => setInitialValues((state) => ({ ...state, ...formik.values })), []);
  const onOk = useCallback(
    (values, closeConfirmation) => {
      setInitialValues((state) => ({ ...state, ...values }));
      sendEmailConfirmation({
        onOk: async () => {
          const state = {
            shoppingCartId: shoppingCart._id,
            subject: values?.subject,
            body: values?.body,
            signature: values?.signature,
            bcc: values?.bcc,
            shouldSendAttachments: values?.shouldSendAttachments,
            recipients: intersectionBy(allRecipients, values?.recipients, (e) =>
              typeof e === 'object' ? e._id : e,
            ).map((recipient) => ({
              name: [recipient?.firstName, recipient?.lastName].filter(Boolean).join(' '),
              email: recipient?.email,
            })),
          };
          const route = generatePath(routePaths.sendEmailShoppingCart, {
            id: shoppingCart?._id,
          });

          navigate(route, { state });
          closeConfirmation();
        },
      });
    },
    [allRecipients, navigate, shoppingCart._id],
  );
  const onOpen = useCallback(() => {
    sendEmailModal({
      allRecipients,
      initialRecipients: initialValues.recipients || allRecipients.map((contact) => contact._id),
      onOk,
      onCloseAfter,
      shoppingCart,
      ...initialValues,
    });
  }, [allRecipients, initialValues, onCloseAfter, onOk, shoppingCart]);
  if (!menuItem) return <SendEmailButton onClick={onOpen} className={className} />;
  return <SendEmailMenuItem onClick={onOpen} />;
};

/**
 * SendEmailContainer component represents the send email button/menu item and handles the email drawer
 * @param {Object} inputParameters - Input parameters of the component
 * @param {String} inputParameters.shoppingCart - Shopping cart object for which the email should be sent
 * @param {String} inputParameters.className - Styling classname for the send email button
 * @param {Boolean} inputParameters.menuItem - Should the component be a menu item instead of a button
 * @returns {JSX.Element} button/menu item with email drawer handling
 * @component
 */
const SendEmailContainer = ({ shoppingCart, className, menuItem }) => {
  const { t } = useTranslation();
  const { data } = useCachedQuery(userPreferencesShoppingCartViewQuery);
  const preferences = useMemo(() => grabFirstGQLDataResult(data), [data]);
  const allRecipients = useMemo(
    () => shoppingCart?.contacts?.filter((contact) => !!contact.email),
    [shoppingCart?.contacts],
  );
  const disabled = useMemo(() => {
    return !(shoppingCart && data && allRecipients?.length);
  }, [allRecipients?.length, data, shoppingCart]);

  if (disabled && !menuItem)
    return (
      <SendEmailButton
        disabled={disabled}
        tooltip={allRecipients?.length ? '' : t('viewer.ShoppingCartView.noRecipientsWithEmail')}
        className={className}
      />
    );
  if (disabled && menuItem) return <SendEmailMenuItem disabled={disabled} />;
  return (
    <SendEmail
      allRecipients={allRecipients}
      shoppingCart={shoppingCart}
      preferences={preferences}
      className={className}
      menuItem={menuItem}
    />
  );
};
export default memo(SendEmailContainer, equal);
