import { gql } from '@apollo/client';

const userStatusFragment = gql`
  fragment userStatusFragment on Status {
    _id
    name
    type
    order
    isDefault
  }
`;

export default userStatusFragment;
