import apollo from 'graphql/apollo';
import { createStatusMutation } from 'graphql/mutations';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { userGetStatusesQuery } from 'graphql/queries';

const createStatus = async ({ name, type }) =>
  apollo.mutate({
    mutation: createStatusMutation,
    variables: { status: { name, type } },
    update: (cache, { data }) => {
      const result = grabFirstGQLDataResult(data);
      const cachedData = cache.readQuery({ query: userGetStatusesQuery, variables: { type } });
      const newStatuses = [...cachedData.getStatuses, result];
      cache.writeQuery({
        query: userGetStatusesQuery,
        variables: {
          type,
        },
        data: {
          getStatuses: newStatuses,
        },
      });
    },
  });
export default createStatus;
