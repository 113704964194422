// import { formatDateTime } from 'utils/date';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import EmptyBox from 'components/common/EmptyBox';
import ItemTypes from 'constants/ItemTypes';
import DraggableCollapse from 'components/admin/drag/Collapse/Collapse';
import { toastGraphQLError } from 'utils/helpers';
import toast from 'utils/toast';
import confirmModal from 'utils/confirmModal';
import CustomExpandIcon from 'components/admin/components/CustomExpandIcon/CustomExpandIcon';
import DraggableDecoration from 'components/admin/drag/DraggableDecoration';
import equal from 'fast-deep-equal/es6/react';
import { useDocumentTemplateBlocks } from 'graphql/hooks';
import BlockController from './BlockController';
import DocumentLayoutBlockContent from './DocumentLayoutBlockContent';

function Extra({ _id: blockId, getDocumentTemplate }) {
  const { t } = useTranslation();
  return (
    <Button
      className="ant-btn-default"
      onClick={(e) => {
        e.stopPropagation();
        confirmModal({
          cancelText: t('admin.DocumentTemplateConfigurationPage.deleteBlockConfirmation.cancel'),
          okText: t('admin.DocumentTemplateConfigurationPage.deleteBlockConfirmation.ok'),
          okType: 'danger',
          onOk: () => {
            const documentInfo = getDocumentTemplate();
            const newBlocks = BlockController.deleteBlockById({ blocks: documentInfo.blocks, blockId });
            BlockController.persistBlocks({ _id: documentInfo._id, blocks: newBlocks })
              .then(toast.successDefault)
              .catch(toastGraphQLError);
          },
          title: t('admin.DocumentTemplateConfigurationPage.deleteBlockConfirmation.title'),
        });
      }}
      type="danger"
      ghost
      size="small"
      icon={<DeleteOutlined />}
    />
  );
}
const ExtraMemo = memo(Extra, equal);

const panelProps = ({ getDocumentTemplate }) => ({
  extra: (props) => <ExtraMemo {...props} getDocumentTemplate={getDocumentTemplate} />,
  header: (block) => block.descriptor.displayName,
});
const draggableProps = { isConnectedPreview: false, isTable: false };
const collapseProps = {
  expandIcon: (props) => <CustomExpandIcon {...props} />,
};
const DragDecoration = <DraggableDecoration height="49px" />;

const DocumentLayoutDraggable = ({ _id, newBlockKeys, getDocumentTemplate, move, moveMutation }) => {
  const { t } = useTranslation();
  const panelPropsData = useMemo(() => {
    return panelProps({ getDocumentTemplate });
  }, [getDocumentTemplate]);

  const Component = useCallback(
    (props) => <DocumentLayoutBlockContent getDocumentTemplate={getDocumentTemplate} {...props} />,
    [getDocumentTemplate],
  );

  const { blocks } = useDocumentTemplateBlocks(_id);
  if (!blocks) return <EmptyBox label={t('admin.DocumentTemplateConfigurationPage.layoutSection.emptyBlockList')} />;
  return (
    <DraggableCollapse
      newKeys={newBlockKeys}
      component={Component}
      moveInCache={move}
      moveMutation={moveMutation}
      data={blocks}
      itemTypes={ItemTypes.DOCUMENT_BLOCK}
      nameElementData="block"
      draggableProps={draggableProps}
      collapseProps={collapseProps}
      panelProps={panelPropsData}
      decorate={DragDecoration}
    />
  );
};
export default memo(DocumentLayoutDraggable, equal);
