import { EyeOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';
import EditShoppingCartButton from 'components/user/shoppingCart/EditShoppingCartButton';
import { useTranslation } from 'react-i18next';
import { AiFillDashboard } from 'react-icons/ai';
import { generatePath, Link, useParams } from 'react-router-dom';
import routePaths from 'router/route-paths';
import classes from './NextActions.module.less';

const NextActions = ({ shoppingCartId: shoppingCartIdProp }) => {
  const { t } = useTranslation();
  const params = useParams();
  const shoppingCartId = shoppingCartIdProp || params?.id;
  return (
    <Card title={t('user.ShoppingCart.NextActions.title')} className={classes.container}>
      <Link to={generatePath(routePaths.shoppingCartView, { id: shoppingCartId })}>
        <Button icon={<EyeOutlined />}>{t('user.ShoppingCart.NextActions.buttons.goToView')}</Button>
      </Link>
      <EditShoppingCartButton _id={shoppingCartId} />
      <Link to={routePaths.dashboard}>
        <Button icon={<AiFillDashboard className="anticon" />}>
          {t('user.ShoppingCart.NextActions.buttons.goToDashBoard')}
        </Button>
      </Link>
    </Card>
  );
};

export default NextActions;
