import { useTranslation } from 'react-i18next';

const useModifyI18N = (newLocale) => {
  const { i18n } = useTranslation();
  if (newLocale !== 'de' && newLocale !== 'en') return;
  if (newLocale === i18n.language) return;
  i18n.changeLanguage(newLocale);
};

export default useModifyI18N;
