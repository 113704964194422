import { gql } from '@apollo/client';

const statusQuery = gql`
  query statusQuery {
    status {
      database
      documentGeneration
      emailSMTP
      emailAPI
      storage
    }
  }
`;

export default statusQuery;
