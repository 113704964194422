import { gql } from '@apollo/client';
import { currentUserFragment } from 'graphql/fragments';

const loginAsUserMutation = gql`
  mutation loginAsUserMutation($userId: ID!) {
    loginAsUser(userId: $userId) {
      token
      refreshToken
      user {
        ...currentUserFragment
      }
    }
  }
  ${currentUserFragment}
`;
export default loginAsUserMutation;
