import inputConfirmModal from 'utils/inputConfirmModal';
import i18n from 'i18n';
import { Button, Card, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useCallback, useState } from 'react';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { useCachedQuery } from 'graphql/utils';
import { userContactListQuery } from 'graphql/queries';
import { createContact } from 'graphql/methods';
import apollo from 'graphql/apollo';
import { userContactFragment } from 'graphql/fragments';
import { pick } from 'lodash';
import { FaPlus, FaUserPlus } from 'react-icons/fa';
import { useFormikField } from 'hooks/common/useFormikField';
import cn from 'classnames';
import classes from '../ContactData.module.less';
import classesContact from './ContactList.module.less';
import {
  ContactExistingFormikInput,
  ContactForm,
  NumberPhoneAlternativeFormikInput,
  NumberPhoneBusinessFormikInput,
} from '../components/Inputs';
import { contactFormTabSchema } from '../components/schema';
import ContactList from './ContactList';
import ImportFields from '../components/HiddenImportData';
import ShoppingCartFormItem from '../../components/ShoppingCartFormItem';
import { useIsOldContactData } from '../hooks';

const { TabPane } = Tabs;

const AddExistingContactTab = ({ changeTab }) => {
  const { t } = useTranslation();

  const { data, loading } = useCachedQuery(userContactListQuery, { fetchPolicy: 'cache-first' });
  const contacts = grabFirstGQLDataResult(data);

  return (
    <div>
      <ContactExistingFormikInput
        contacts={contacts}
        loading={loading}
        changeTab={changeTab}
        placeholder={t('user.ShoppingCart.ContactData.contact.inputs.existingContact.placeholder')}
      />
    </div>
  );
};

const AddContactContent = ({ contacts, importFieldsProps = {}, loading }) => {
  const { t } = useTranslation();

  const [activeKey, setActiveKey] = useState('1');

  const { setFieldValueAndTouched } = useFormikContext();

  const changeTab = useCallback(
    (key) => {
      setActiveKey(key);
      setFieldValueAndTouched('tab', key);
    },
    [setFieldValueAndTouched],
  );
  return (
    <Tabs type="card" activeKey={activeKey} className={classes.tabs} onChange={changeTab}>
      <TabPane tab={t('user.ShoppingCart.ContactData.contact.addExistingContactTab')} key="1">
        <AddExistingContactTab contacts={contacts} loading={loading} changeTab={changeTab} />
      </TabPane>
      <TabPane tab={t('user.ShoppingCart.ContactData.contact.createNewContactTab')} key="2">
        <ImportFields {...importFieldsProps} />
        <ContactForm changeTab={changeTab} />
        <NumberPhoneBusinessFormikInput typeContactData="contact" />
        <NumberPhoneAlternativeFormikInput typeContactData="contact" />
      </TabPane>
    </Tabs>
  );
};

const addNewContact = ({ contacts, companyId, onAdd }) =>
  inputConfirmModal({
    formContent: () => <AddContactContent importFieldsProps={{ companyId, isOpen: !contacts?.length }} />,
    fields: [],
    onSubmit: async ({ contact, tab, ...newContact }) => {
      if (tab === '1') {
        if (contacts.find((e) => contact === e._id)) throw new Error('DuplicatedContact');
        const currentContact = apollo.readFragment({ id: `Contact___${contact}`, fragment: userContactFragment });
        onAdd(currentContact);
      } else {
        const { data } = await createContact(newContact);
        onAdd(grabFirstGQLDataResult(data));
      }
    },
    value: {
      tab: '1',
      contact: null,
      salutation: null,
      firstName: '',
      lastName: '',
      position: '',
      email: '',
      phoneNumber: '49-',
      phoneNumberAlternative: '49-',
    },
    headerText: i18n.t('user.ShoppingCart.ContactData.modal.titles.addContact'),
    okText: i18n.t('user.ShoppingCart.ContactData.modal.ok'),
    cancelText: i18n.t('user.ShoppingCart.ContactData.modal.cancel'),
    validationSchema: contactFormTabSchema,
    forceMultiField: true,
    width: '600px',
    errorResolver: {
      Duplicated: ['email', i18n.t('user.ShoppingCart.ContactData.contact.duplicatedErrorMessage.email')],
      DuplicatedContact: ['contact', i18n.t('user.ShoppingCart.ContactData.contact.duplicatedErrorMessage.contact')],
    },
  });

const produceContact = (contact) => pick(contact, ['_id', 'email']);

const name = 'contacts';

function ContactsField() {
  const { value: contacts, onChange: onChangeFormik } = useFormikField(name);
  const { value: companyId } = useFormikField('companyId');
  const { value: initializationConfigDateForCompany } = useFormikField('initializationConfigDateForCompany');
  const { setFieldValueAndTouched } = useFormikContext();
  const isOldContactData = useIsOldContactData();

  const onAdd = useCallback(
    (newContact) => {
      onChangeFormik([...contacts, produceContact(newContact)]);
    },
    [contacts, onChangeFormik],
  );
  const onDelete = useCallback(
    (_id) => {
      const filterContacts = contacts.filter((e) => e._id !== _id);
      setFieldValueAndTouched(name, filterContacts);
    },
    [contacts, setFieldValueAndTouched],
  );
  const onChange = useCallback(
    (newContact) => {
      setFieldValueAndTouched(
        name,
        contacts.map((contact) => (newContact._id === contact._id ? produceContact(newContact) : contact)),
      );
    },
    [contacts, setFieldValueAndTouched],
  );
  const { t } = useTranslation();
  return (
    <ShoppingCartFormItem name="contacts" className="form-item-no-margin">
      <Card
        onClick={contacts.length ? null : () => addNewContact({ contacts, companyId, onAdd })}
        className={cn(classesContact.contact, contacts.length ? '' : 'cursor-pointer')}
        title={t('user.ShoppingCart.ContactData.contact.title')}
        extra={
          isOldContactData ? null : (
            <Button
              disabled={isOldContactData}
              className="button-border-none center ant-button-transparent"
              icon={<FaPlus />}
              onClick={(e) => {
                e.stopPropagation();
                addNewContact({ contacts, companyId, onAdd });
              }}
            />
          )
        }
      >
        {contacts.length ? (
          <ContactList
            contacts={contacts}
            onDelete={onDelete}
            onChange={onChange}
            initializationConfigDateForCompany={initializationConfigDateForCompany}
          />
        ) : (
          <div className={classes.empty}>
            <div>
              <FaUserPlus size="50px" />
            </div>
            <div>{t('user.ShoppingCart.ContactData.contact.empty')}</div>
          </div>
        )}
      </Card>
    </ShoppingCartFormItem>
  );
}
export default ContactsField;
