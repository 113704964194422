import EmailTemplateSelect from 'components/common/EmailTemplateSelect';

const NotifyClientSigneesForDigitalSignatureStartTemplateSelect = ({ t }) => {
  return (
    <EmailTemplateSelect
      fieldName="notifyClientSigneesForDigitalSignatureStartTemplate"
      label={t(
        'admin.Setting.DigitalSignaturePreferences.generalSettings.notifyClientSigneesForDigitalSignatureStartTemplate.label',
      )}
      tooltip={t(
        'admin.Setting.DigitalSignaturePreferences.generalSettings.notifyClientSigneesForDigitalSignatureStartTemplate.tooltip',
      )}
      placeholder={t(
        'admin.Setting.DigitalSignaturePreferences.generalSettings.notifyClientSigneesForDigitalSignatureStartTemplate.placeholder',
      )}
    />
  );
};

export default NotifyClientSigneesForDigitalSignatureStartTemplateSelect;
