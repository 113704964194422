import settings from 'config/settings';
import { useEffect } from 'react';

/**
 * Triggers out of service webhook if enabled
 * @param {String} page - Signing or global out of service page
 * @returns {void}
 */
export const triggerOutOfServiceWebhook = (page) => {
  if (!settings.outOfServiceWebhookEnabled) return;
  const headers = new Headers();
  // headers.append('X-Forwarded-For', '');
  headers.append('Content-Type', 'application/json');
  // headers.append('', '');
  fetch(settings.outOfServiceWebhookRoute, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      page,
    }),
  });
};

/**
 * Triggers out of service webhook if enabled on init load
 * @param {String} page - Signing or global out of service page
 */
const useTriggerOutOfServiceWebhook = (page) => {
  useEffect(() => {
    triggerOutOfServiceWebhook(page);
  }, [page]);
};

export default useTriggerOutOfServiceWebhook;
