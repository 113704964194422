import ROLES from 'constants/roles';
import { omit } from 'lodash';

export const adminRoles = [ROLES.ADMIN, ROLES.ROOT_ADMIN, ROLES.SUPER_ADMIN];

export const rolesWithoutViewer = Object.keys(omit(ROLES, [ROLES.VIEWER]));

export function isAdmin(role) {
  return adminRoles.includes(role);
}

export function denyForViewer(role) {
  return rolesWithoutViewer.includes(role);
}
