import { Alert as AlertAntd } from 'antd';
import cn from 'classnames';
import equal from 'fast-deep-equal';
import { FaLightbulb } from 'react-icons/fa';
import { memo } from 'react';
import classes from './Alert.module.less';

const Alert = ({ icon = <FaLightbulb />, message, description, className }) => {
  return (
    <AlertAntd
      showIcon
      className={cn(className, classes.alert)}
      icon={icon}
      message={message}
      description={description}
    />
  );
};

export default memo(Alert, equal);
