import { useState } from 'react';
import { Button } from 'antd';
import { ImportOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import ImportDefaultTemplatesModal from '../ImportDefaultTemplatesModal/ImportDefaultTemplatesModal';

/**
 * ImportDefaultTemplatesButton component is the page button to import default templates for digital signature
 * @returns {JSX.Element} page button for digital signature preferences page
 * @component
 */
const ImportDefaultTemplatesButton = () => {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      <Button onClick={() => setModalVisible(true)} icon={<ImportOutlined />}>
        {t('admin.Setting.DigitalSignaturePreferences.importDefaultTemplatesButton')}
      </Button>
      <ImportDefaultTemplatesModal open={modalVisible} onCloseModal={() => setModalVisible(false)} />
    </>
  );
};

export default ImportDefaultTemplatesButton;
