import equal from 'fast-deep-equal';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import formattedInputValue from './InputFieldsValuePreparation';

const InputFields = ({ isCustomPrice, inputFields, showDigits, className }) => {
  const { t } = useTranslation();
  return (
    <div className={className}>
      {inputFields.map((inputField) => {
        return (
          <div>
            {inputField.name}: {formattedInputValue({ inputField, showDigits, t, isCustomPrice })}
          </div>
        );
      })}
    </div>
  );
};

export default memo(InputFields, equal);
