import { gql, useSubscription } from '@apollo/client';
import apollo from 'graphql/apollo';
import { useRerender } from 'graphql/utils';
import { get } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Convert from 'ansi-to-html';
import htmr from 'htmr';
import Switch from 'components/common/Switch';

const logLineSubscription = gql`
  subscription logLine {
    logLine {
      _id
      line
      date
      rawLine
      type
    }
  }
`;
const logLinesQuery = gql`
  query logLines {
    logLines {
      _id
      line
      date
      rawLine
      type
    }
  }
`;

const convert = new Convert({ newline: true, fg: 'rgb(200, 200, 200)', bg: 'rgb(30, 30, 30)' });

const ConsoleLogs = () => {
  const logIdsRef = useRef([]);
  const logsRef = useRef([]);
  const rerender = useRerender();

  const processLines = useCallback(
    (lines) => {
      lines.forEach((l) => {
        const line = { ...l };
        const { _id } = line;
        if (!logIdsRef.current.includes(_id)) {
          logIdsRef.current.push(_id);
          logsRef.current.push(line);
          if (logsRef.current.length > 1) {
            const lastLog = logsRef.current[logsRef.current.length - 1];
            if (+lastLog._id > +_id) logsRef.current.sort((a, b) => (+a._id < +b._id ? 1 : -1));
          }
        }
      });
      rerender();
    },
    [rerender],
  );
  useEffect(
    () =>
      apollo
        .query({ query: logLinesQuery, fetchPolicy: 'network-only' })
        .then((res) => processLines(get(res, 'data.logLines', []))),
    [processLines],
  );
  useSubscription(logLineSubscription, {
    onSubscriptionData: ({ subscriptionData }) => {
      const lines = get(subscriptionData, 'data.logLine');
      processLines(lines);
    },
  });

  const [isAutoScrollEnabled, setAutoScroll] = useState(true);
  const divRef = useRef();
  useEffect(() => {
    const id = window.setInterval(() => {
      if (isAutoScrollEnabled) divRef.current.scrollTop = divRef.current.scrollHeight;
    }, 500);
    return () => window.clearInterval(id);
  }, [isAutoScrollEnabled]);

  return (
    <div>
      Auto Scroll <Switch checked={isAutoScrollEnabled} onChange={(e) => setAutoScroll(e)} />
      <div
        ref={divRef}
        style={{
          marginTop: 15,
          marginBottom: 15,
          background: 'rgb(30, 30, 30)',
          lineBreak: 'anywhere',
          fontSize: '12px',
          fontFeatureSettings: '"liga" 0, "calt" 0',
          lineHeight: '18px',
          letterSpacing: '0px',
          fontFamily: 'Menlo, Monaco, "Courier New", monospace',
          fontWeight: 'normal',
          textSizeAdjust: '100%',
          overflow: 'auto',
          maxHeight: '600px',
          color: 'rgb(200, 200, 200)',
          whiteSpace: 'pre',
          padding: 10,
        }}
      >
        {htmr(convert.toHtml(logsRef.current.map((e) => e.line).join('')))}
      </div>
    </div>
  );
};
export default ConsoleLogs;
