import { Button, Spin, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { FaSignature } from 'react-icons/fa';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import routePaths from 'router/route-paths';
import cn from 'classnames';
import signeeSignMutation from 'graphql/mutations/signing/signeeSignMutation';
import useSigningGraphqlContext from 'hooks/signing/useSigningGraphqlContext';
import classes from './SigningButton.module.less';
import SigningContextErrorHandler from '../SigningContextErrorHandler';

const { Title } = Typography;

const NotSigned = ({ label }) => {
  return (
    <div className={classes.notSignedContainer}>
      <div>
        <FaSignature size="26" />
      </div>
      <div className={classes.buttonText}>{label}</div>
    </div>
  );
};

const LoadingState = ({ label }) => {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  return (
    <div className={classes.notSignedContainer}>
      <div className={classes.spinContainer}>
        <Spin indicator={antIcon} />
      </div>
      <div className={classes.buttonText}>{label}</div>
    </div>
  );
};

const SigningButton = ({ agreementFormReference, signatureImage, buttonReference, updateCurrentStepState }) => {
  const { t: translation } = useTranslation();
  const navigate = useNavigate();
  const [signed, setSigned] = useState(signatureImage !== undefined);
  const [showError, setShowError] = useState(false);
  const signingGraphlQlContext = useSigningGraphqlContext();
  const [imageString, setImageString] = useState(signatureImage || '');
  const [sign, { data: signData, loading: signing, error }] = useMutation(signeeSignMutation, {
    context: signingGraphlQlContext,
  });

  useEffect(() => {
    if (!signingGraphlQlContext?.headers) navigate(routePaths.signingUnvalid);
  }, [signingGraphlQlContext.headers, navigate]);

  useEffect(() => {
    if (!signed && signData && signData.sign.success) {
      setSigned(true);
      setImageString(signData.sign.signaturePng);
      updateCurrentStepState({
        signed: true,
        signatureImage: signData.sign.signaturePng,
        identityCheck: true,
        checkDocumentsSeen: true,
        checkNoticeOfCancellation: true,
      });
    }
  }, [signed, signData, updateCurrentStepState]);

  // eslint-disable-next-line no-param-reassign
  buttonReference.current = {
    onContinue: () => {
      if (signed && imageString) return imageString;
      setShowError(true);
      return false;
    },
    onBack: () => {
      return !signing;
    },
  };

  const onClick = async () => {
    if (agreementFormReference?.current?.onSubmit && !signed) {
      const isFormValid = await agreementFormReference.current.onSubmit();
      if (isFormValid) {
        setShowError(false);
        sign({ variables: { identityCheck: true, checkDocumentsSeen: true, checkNoticeOfCancellation: true } }).catch(
          () => {},
        ); // Needed to catch the promise errors (Error handling by error component)
      }
    }
  };

  const image = <img src={`data:image/png;base64,${imageString}`} height="142" alt="signature" />;

  return (
    <div className={classes.container}>
      <Title level={5}>{translation('signing.viewDocumentsAndSignStep.signingButton.stepHeadline')}</Title>
      <SigningContextErrorHandler error={error}>
        <Button
          onClick={onClick}
          className={signed ? cn(classes.button, classes.signedButton) : classes.button}
          disabled={signed || signing}
        >
          {!signed && !signing && (
            <NotSigned label={translation('signing.viewDocumentsAndSignStep.signingButton.label')} />
          )}
          {!signed && signing && (
            <LoadingState label={translation('signing.viewDocumentsAndSignStep.signingButton.signing')} />
          )}
          {signed && image}
        </Button>
      </SigningContextErrorHandler>
      {showError && (
        <p className={classes.errorMessage}>
          {translation('signing.viewDocumentsAndSignStep.signingButton.notSignedYet')}
        </p>
      )}
    </div>
  );
};

export default SigningButton;
