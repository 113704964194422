import { gql } from '@apollo/client';
import { adminItemFragment } from 'graphql/fragments';

const updateItemMutation = gql`
  mutation updateItemMutation($_id: ID!, $modifier: CategoryItemInput!) {
    updateCategoryItem(_id: $_id, modifier: $modifier) {
      item {
        ...adminItemFragment
      }
      warnings {
        key
        variables
      }
    }
  }
  ${adminItemFragment}
`;

export default updateItemMutation;
