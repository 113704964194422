import { gql } from '@apollo/client';

const adminDocumentTemplateListForImportModalFragment = gql`
  fragment adminDocumentTemplateListForImportModalFragment on DocumentTemplate {
    _id
    name
    description
  }
`;

export default adminDocumentTemplateListForImportModalFragment;
