import { memo } from 'react';
import ResetPasswordForm from 'components/auth/ResetPasswordForm';
import { useTranslation } from 'react-i18next';
import equal from 'fast-deep-equal';

const ResetPassword = () => {
  const { t } = useTranslation();
  return (
    <>
      <h2>{t('ResetPassword.title')}</h2>
      <ResetPasswordForm />
    </>
  );
};

export default memo(ResetPassword, equal);
