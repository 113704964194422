import { createContext, useCallback, useContext, useState } from 'react';

const useShowVATState = ({ defaultValue }) => {
  const [isShowVAT, setIsShowVAT] = useState(defaultValue ?? useShowVATState.defaults.isShowVAT);
  const toggleVAT = useCallback((v) => setIsShowVAT(typeof v === 'boolean' ? v : (s) => !s), []);
  return { isShowVAT, toggleVAT };
};
useShowVATState.defaults = {
  isShowVAT: false,
  toggleVAT: () => {},
};

export const ShowVATContext = createContext({
  ...useShowVATState.defaults,
});

export const ShowVATProvider = ({ defaultValue, children }) => {
  const showVATState = useShowVATState({ defaultValue });
  return <ShowVATContext.Provider value={showVATState}>{children}</ShowVATContext.Provider>;
};

export const useShowVATContext = () => useContext(ShowVATContext);
