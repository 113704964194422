import DocumentTemplateSelect from 'components/common/DocumentTemplateSelect';

const NoticeOfCancellationDocumentTemplateSelect = ({ t }) => {
  return (
    <DocumentTemplateSelect
      fieldName="noticeOfCancellationDocumentTemplate"
      label={t(
        'admin.Setting.DigitalSignaturePreferences.noticeOfCancellation.noticeOfCancellationDocumentTemplate.label',
      )}
      placeholder={t(
        'admin.Setting.DigitalSignaturePreferences.noticeOfCancellation.noticeOfCancellationDocumentTemplate.placeholder',
      )}
      tooltip={t(
        'admin.Setting.DigitalSignaturePreferences.noticeOfCancellation.noticeOfCancellationDocumentTemplate.tooltip',
      )}
    />
  );
};

export default NoticeOfCancellationDocumentTemplateSelect;
