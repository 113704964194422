import apollo from 'graphql/apollo';
import { startDigitalSignatureMutation } from 'graphql/mutations';
import { userShoppingCartViewQuery } from 'graphql/queries';

/**
 * Mutates a shopping cart for digital signature start
 * @param {Object} inputParameter - Input parameter
 * @param {string} inputParameter.shoppingCartId - Database id of related shopping cart object, on which the digital signature should be started
 * @param {Object} inputParameter.emailProps - Email message properties for client signees start digital signature email
 * @returns {Object} returns mutation result (shopping cart or error)
 */
const startDigitalSignature = async ({ shoppingCartId, emailProps }) =>
  apollo.mutate({
    mutation: startDigitalSignatureMutation,
    variables: { shoppingCartId, emailProps },
    refetchQueries: [{ query: userShoppingCartViewQuery, variables: { _id: shoppingCartId } }],
  });

export default startDigitalSignature;
