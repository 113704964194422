import { useCachedQuery } from 'graphql/utils';
import { tenantLogoQuery } from 'graphql/queries';
import { useTranslation } from 'react-i18next';
import { grabFirstGQLDataResult } from 'utils/helpers';
import cn from 'classnames';
import { Skeleton } from 'antd';
import { memo } from 'react';
import equal from 'fast-deep-equal/es6/react';
import s from '../ShoppingCart.module.less';

const ShoppingCartLogo = () => {
  const { t } = useTranslation();
  const { data, loading } = useCachedQuery(tenantLogoQuery);
  const logo = grabFirstGQLDataResult(data);
  if (!data && loading)
    return (
      <div className={cn(s.shoppingCartLogo, s.shoppingCartLogoLoading, 'margin-bottom-16')}>
        <Skeleton.Avatar shape="square" active />
      </div>
    );
  if (!logo) return null;
  return (
    <div className="margin-bottom-16">
      <img src={logo} alt={t('user.ShoppingCart.titleAlt')} className={s.shoppingCartLogo} />
    </div>
  );
};
export default memo(ShoppingCartLogo, equal);
