import { Layout } from 'antd';
import kanzleiPilotLogoSrc from 'assets/images/KanzleiPilotLogo.svg';
import Footer from 'components/layout/Footer';
import s from './OnboardingLayout.module.less';

const { Header, Content } = Layout;

export default function OnboardingLayout({ children }) {
  return (
    <Layout className={s.layout}>
      <Header className={s.header}>
        <img className={s.logo} alt="logo" src={kanzleiPilotLogoSrc} />
      </Header>
      <Content className={s.content}>{children}</Content>
      <Footer />
    </Layout>
  );
}
