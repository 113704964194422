import EmailTemplateSelect from 'components/common/EmailTemplateSelect';

const NotifySigneesForRevokeTemplateSelect = ({ t }) => {
  return (
    <EmailTemplateSelect
      fieldName="notifySigneesForRevokeTemplate"
      label={t(
        'admin.Setting.DigitalSignaturePreferences.automateSignatureProcess.notifySigneesForRevokeTemplate.label',
      )}
      placeholder={t(
        'admin.Setting.DigitalSignaturePreferences.automateSignatureProcess.notifySigneesForRevokeTemplate.placeholder',
      )}
      tooltip={t(
        'admin.Setting.DigitalSignaturePreferences.automateSignatureProcess.notifySigneesForRevokeTemplate.tooltip',
      )}
    />
  );
};

export default NotifySigneesForRevokeTemplateSelect;
