import { gql } from '@apollo/client';

const adminPdfBodyPrimaryFragment = gql`
  fragment adminPdfBodyPrimaryFragment on PdfBodyPrimary {
    fontFamily
    color
    background
    marginBottom
    marginTop
  }
`;

export default adminPdfBodyPrimaryFragment;
