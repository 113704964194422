import Switch from 'components/common/Switch';
import { useTranslation } from 'react-i18next';

/**
 * NotifySigneesAboutSignedBySigneeSwitch component is the switch for the additional notification settings card to enable or disable the notification of signees about the sign of an other signee (Needs formik context)
 * @returns {JSX.Element} switch component for additional notification settings card in digital signature preferences page
 * @component
 */
const NotifySigneesAboutSignedBySigneeSwitch = () => {
  const { t } = useTranslation();
  return (
    <Switch
      name="notifySigneesAboutSignedBySignee"
      label={t(
        'admin.Setting.DigitalSignaturePreferences.additionalNotificationSettings.notifySigneesAboutSignedBySignee.label',
      )}
      tooltip={t(
        'admin.Setting.DigitalSignaturePreferences.additionalNotificationSettings.notifySigneesAboutSignedBySignee.tooltip',
      )}
      defaultChildren
    />
  );
};
export default NotifySigneesAboutSignedBySigneeSwitch;
