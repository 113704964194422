import equal from 'fast-deep-equal';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

const Quotes = () => {
  const { t } = useTranslation();
  const quotes = t('common.quotes', { returnObjects: true });
  const quote = quotes[new Date() % quotes.length];
  return (
    <>
      <h2>
        <i>
          <b>{quote.quote}</b>
        </i>
      </h2>
      <span>
        <i>{quote.author}</i>
      </span>
    </>
  );
};

export default memo(Quotes, equal);
