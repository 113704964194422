import { Card, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import PageContainer from 'components/layout/PageContainer';
import { QueryErrorAlert } from 'components/common/ErrorComponent';
import PageBack from 'components/common/PageBack';
import routePaths from 'router/route-paths';
import { useLibraryContext } from 'contexts/LibraryContext';
import { useDocumentTemplate } from 'graphql/hooks';
import { memo, useMemo } from 'react';
import equal from 'fast-deep-equal/es6/react';
import { useParams } from 'react-router-dom';
import { pick } from 'lodash';
import GeneralInfoForm from './GeneralInfoForm';
import DocumentLayout from './DocumentLayout';
import EditDocumentTemplateHelperWidget from './EditDocumentTemplateHelperWidget';

function DocumentTemplateConfiguration() {
  const { id: documentTemplateId } = useParams();
  const { data: documentTemplate, error } = useDocumentTemplate(documentTemplateId);
  const generalInfo = useMemo(
    () =>
      pick(documentTemplate, [
        '_id',
        'description',
        'lastUsedDocumentNr',
        'name',
        'nrOfDocumentsCreated',
        'commonLibraryAccessGroups',
      ]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      documentTemplate?._id,
      documentTemplate?.description,
      documentTemplate?.lastUsedDocumentNr,
      documentTemplate?.name,
      documentTemplate?.nrOfDocumentsCreated,
      documentTemplate?.commonLibraryAccessGroups,
    ],
  );

  const loading = true;
  return (
    <>
      {error ? (
        <Card>
          <QueryErrorAlert error={error} />
        </Card>
      ) : null}
      {loading && !documentTemplate ? (
        <Card>
          <Skeleton title loading={loading} active />
        </Card>
      ) : null}
      {documentTemplate ? (
        <>
          <GeneralInfoForm generalInfo={generalInfo} />
          <div className="margin-bottom-16" />
          <DocumentLayout _id={documentTemplate?._id} />
        </>
      ) : null}
    </>
  );
}

const DocumentTemplateConfigurationMemo = memo(DocumentTemplateConfiguration, equal);

const DocumentTemplateConfigurationWrapper = () => {
  const { isLibrary } = useLibraryContext();

  const { t } = useTranslation();
  return (
    <PageContainer
      title={t('admin.DocumentTemplateConfigurationPage.title')}
      topComponent={
        <PageBack
          to={isLibrary ? routePaths.commonLibraryDocumentTemplateList : routePaths.documentTemplateList}
          label={t('admin.DocumentTemplateConfigurationPage.backTitle')}
        />
      }
      left={<EditDocumentTemplateHelperWidget />}
    >
      <div className="xs-mt-20">
        <DocumentTemplateConfigurationMemo />
      </div>
    </PageContainer>
  );
};

export default memo(DocumentTemplateConfigurationWrapper, equal);
