import { memo, useCallback, useMemo } from 'react';
import equal from 'fast-deep-equal/es6/react';
import { useFormikContext } from 'formik';
import { FaArrowUp } from 'react-icons/fa';
import { useCategoriesContext } from 'components/user/shoppingCart/context';
import { Trans, useTranslation } from 'react-i18next';
import { find, pickBy } from 'lodash';
import classes from './ErrorList.module.less';
import { RequiredFieldsAlertWrapper } from './ShoppingCartFormItem';

const ErrorView = ({ errorSelector }) => {
  const { t } = useTranslation();

  const { touched, setFieldTouched } = useFormikContext();
  const onClick = useCallback(
    (e) => {
      if (errorSelector?.name && !touched[errorSelector.name]) setFieldTouched(errorSelector.name, true);
      const elementWithError = document.querySelector(
        typeof errorSelector === 'object' ? errorSelector.selector : errorSelector,
      );
      const tab = elementWithError
        ?.closest('.ant-collapse-item')
        ?.querySelector('.ant-collapse-header[aria-expanded="false"]');
      if (tab) {
        const currentOffset = e.target.offsetTop - window.scrollY;
        tab.click();
        window.scrollTo({ top: e.target.offsetTop - currentOffset });
      }
      window.setTimeout(
        () => elementWithError?.scrollIntoView({ block: 'center', behavior: 'smooth' }),
        tab ? 500 : 100,
      );
    },
    [errorSelector, setFieldTouched, touched],
  );
  return (
    <div className={classes.errorView} tabIndex={0} role="button" onKeyDown={onClick} onClick={onClick}>
      <div>{t('user.ShoppingCart.errorList.scroll')}</div>
      <div>
        <FaArrowUp />
      </div>
    </div>
  );
};
const ErrorViewMemo = memo(ErrorView, equal);

const ErrorList = () => {
  const categories = useCategoriesContext();
  const allItemIds = useMemo(
    () =>
      categories
        .map((c) => c.items.map((i) => [i._id, `${i._id}_bookmark`]))
        .flat()
        .flat(),
    [categories],
  );
  const { errors, values } = useFormikContext();

  const errorSelector = useMemo(() => {
    let name;
    // is selected items
    if (!find(Object.keys(pickBy(values, Boolean)), (f) => find(allItemIds, (i) => i === f)))
      return '#category-in-cart';
    if (!name) name = Object.keys(errors)?.[0];
    if (name) {
      return { name, selector: `[fieldname="${name}"]` };
    }
    return undefined;
  }, [allItemIds, errors, values]);
  if (!errorSelector) return null;
  return (
    <RequiredFieldsAlertWrapper>
      <div className={classes.errorListContainer}>
        <Trans i18nKey="user.ShoppingCart.errorList.title" />
        <ErrorViewMemo errorSelector={errorSelector} />
      </div>
    </RequiredFieldsAlertWrapper>
  );
};
export default memo(ErrorList, equal);
