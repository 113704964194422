import { memo, useMemo, useCallback } from 'react';
import equal from 'fast-deep-equal/es6/react';
import { useTranslation } from 'react-i18next';
import { FaHistory } from 'react-icons/fa';
import { Button, Popover } from 'antd';
import { CreationMode } from 'constants/shoppingCart';
import MoreInfoWidget from 'components/common/MoreInfoWidget';
import { useParams, useNavigate, generatePath } from 'react-router-dom';
import routePaths from 'router/route-paths';
import { formatDate } from 'utils/date';
import i18n from 'i18n';
import confirmModal from 'utils/confirmModal';
import CompareToGlobalSwitch from 'components/user/compareToGlobal/CompareToGlobalSwitch';
import { cloneDeep } from 'lodash';

const goToEditShoppingCartWithConfirmation = ({ onOk }) =>
  confirmModal({
    cancelText: i18n.t('common.cancel'),
    maskClosable: true,
    okText: i18n.t('common.yes'),
    onOk,
    title: i18n.t('user.lockedIcon.confirmationTitle'),
  });

const ShoppingCartHelperWidget = memo(({ buttonClassName }) => {
  const { t } = useTranslation();
  return (
    <MoreInfoWidget
      buttonClassName={buttonClassName}
      buttonText={t('user.lockedIcon.LockHelperWidget.howUseButton')}
      title={t('user.lockedIcon.LockHelperWidget.modalInfo.title')}
      helpText={t('editShoppingCartModal.HelperWidget.modalInfo.helpText')}
      videoCaption={t('editShoppingCartModal.HelperWidget.modalInfo.videoCaption')}
      videoUrl={t('editShoppingCartModal.HelperWidget.modalInfo.videoUrl')}
      imageUrl={t('editShoppingCartModal.HelperWidget.modalInfo.imageUrl')}
    />
  );
}, equal);

const Content = ({ initializationConfigDate, stateRef }) => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();

  const showEditButton = useMemo(() => params.mode === CreationMode.INDEPENDENT, [params.mode]);
  const onClick = useCallback(
    () =>
      navigate(generatePath(routePaths.routerShoppingCartRevision, { ...params, mode: CreationMode.GLOBAL }), {
        state: cloneDeep(stateRef?.current),
      }),
    [navigate, params, stateRef],
  );
  return (
    <>
      <div className={showEditButton ? 'margin-bottom-16' : ''}>
        {t('user.lockedIcon.title', { date: formatDate(initializationConfigDate) })}
      </div>
      {showEditButton ? (
        <Button
          className="margin-bottom-16 display-block"
          type="primary"
          onClick={() => goToEditShoppingCartWithConfirmation({ onOk: onClick })}
        >
          {t('user.lockedIcon.editButton')}
        </Button>
      ) : null}
      <CompareToGlobalSwitch />
      <ShoppingCartHelperWidget />
    </>
  );
};
const LockButton = ({ stateRef, initializationConfigDate, className }) => {
  if (!initializationConfigDate) return null;
  return (
    <div className={className}>
      <Popover
        zIndex={80}
        placement="bottomRight"
        content={<Content stateRef={stateRef} initializationConfigDate={initializationConfigDate} />}
      >
        <span className="anticon">
          <FaHistory size={20} />
        </span>
      </Popover>
    </div>
  );
};

export default memo(LockButton, equal);
