import { useState } from 'react';
import { Modal, InputNumber, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { userShoppingCartViewQuery, userSigneesSigningLinkInfosQuery } from 'graphql/queries';
import { changeSigningLinkExpirationDateMutation } from 'graphql/mutations';
import DateAndAvailableTimeField from './DateAndAvailableTimeField';
import ButtonBar from './ButtonBar';
import classes from './ChangeExpirationDateOnSigningLinkModal.module.less';

/**
 * ChangeExpirationDateOnSigningLinkModal component for adding additional time to available time of the signees signing link
 * @param {Object} inputParameter - Input parameter
 * @param {String} inputParameter.shoppingCartId - Database id of related shopping cart object
 * @param {Object} inputParameter.signee - Informations about the signee
 * @param {String} inputParameter.signee.signeeEmail - Email address of signee
 * @param {String} inputParameter.signee.signeeRelatedObjectId - Database of related object to signee
 * @param {Function} inputParameter.onCloseModal - Function, which handles the close of the modal
 * @param {String} inputParameter.successMessage - Messsage, which should be shown, if change was successful
 * @returns {JSX.Element} ChangeExpirationDateOnSigningLinkModal component for changing expiration date
 * @component
 */
const ChangeExpirationDateOnSignignLinkModal = ({ shoppingCartId, signee, onCloseModal, successMessage }) => {
  const { t: translation } = useTranslation();
  const [hoursToAdd, setHoursToAdd] = useState(0);
  const apolloClient = useApolloClient();
  const { data: signingLinkInfos } = useQuery(userSigneesSigningLinkInfosQuery, {
    variables: {
      shoppingCartId,
      signeeEmail: signee.email,
      signeeRelatedObjectId: signee.id,
    },
  });

  const currentDate = new Date(signingLinkInfos?.getSigneesLinkInfo?.expirationDate);
  const hoursToAddInMilliSeconds = 1000 * 60 * 60 * hoursToAdd;
  const newDate = new Date(currentDate.getTime() + hoursToAddInMilliSeconds);

  const [changeExpirationDateOfLink] = useMutation(changeSigningLinkExpirationDateMutation, {
    variables: {
      shoppingCartId,
      signeeEmail: signee.email,
      signeeRelatedObjectId: signee.id,
      newExpirationDate: newDate,
    },
    refetchQueries: [
      {
        query: userSigneesSigningLinkInfosQuery,
        variables: {
          shoppingCartId,
          signeeEmail: signee.email,
          signeeRelatedObjectId: signee.id,
        },
      },
    ],
  });

  const onOkClicked = () => {
    if (hoursToAdd > 0) {
      changeExpirationDateOfLink()
        .then(() => {
          message.success(successMessage, 3);
        })
        .catch((error) => {
          const { message: errorMessage } = error;
          if (errorMessage.includes('notFound')) {
            message.error(
              translation('viewer.ShoppingCartView.DigitalSignature.signeeManagement.notFoundError', {
                specificMessage: translation(
                  'viewer.ShoppingCartView.DigitalSignature.signeeManagement.expandLinkValidity.notFound',
                ),
              }),
              3,
            );
            apolloClient.refetchQueries({
              include: [{ query: userShoppingCartViewQuery, _id: shoppingCartId }],
            });
          } else if (errorMessage.includes('date is before current expiration dat')) {
            message.error(
              'viewer.ShoppingCartView.DigitalSignature.signeeManagement.expandLinkValidity.dateNotValidError',
              3,
            );
          } else message.error('Unknown error', 3);
        });
    }
    onCloseModal();
  };
  const onCancelClicked = () => {
    onCloseModal();
  };

  const onChangeHoursToAdd = (value) => {
    setHoursToAdd(value);
  };

  if (!signingLinkInfos) return null;

  return (
    <Modal
      title={translation('viewer.ShoppingCartView.ChangeExpirationDateOnSigningLinkModal.title')}
      visible
      footer={<ButtonBar onOk={onOkClicked} onCancel={onCancelClicked} />}
      onCancel={onCloseModal}
    >
      <DateAndAvailableTimeField
        label={translation('viewer.ShoppingCartView.ChangeExpirationDateOnSigningLinkModal.currentDateLabel')}
        date={currentDate}
      />
      <DateAndAvailableTimeField
        label={translation('viewer.ShoppingCartView.ChangeExpirationDateOnSigningLinkModal.newDateLabel')}
        date={newDate}
      />
      <div>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="hoursToAdd" className={classes.hoursFieldLabel}>
          {translation('viewer.ShoppingCartView.ChangeExpirationDateOnSigningLinkModal.addHours.label')}
        </label>
        <InputNumber
          onChange={onChangeHoursToAdd}
          id="hoursToAdd"
          addonAfter={translation('viewer.ShoppingCartView.ChangeExpirationDateOnSigningLinkModal.addHours.unit')}
          value={hoursToAdd}
          controls={false}
          className={classes.hoursInputField}
        />
      </div>
    </Modal>
  );
};

export default ChangeExpirationDateOnSignignLinkModal;
