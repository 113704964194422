import { Space } from 'antd';
import ImportDefaultTemplatesButton from './ImportDefaultTemplatesButton';
import ResetPreferencesButton from './ResetPreferencesButton';

/**
 * PageButtonBar component is the page button bar for the digital signature preferences page, which shows the import default templates and the reset settings button
 * @returns {JSX.Element} button bar for digital signature preferences page for the upper right side
 * @component
 */
const PageButtonBar = () => {
  return (
    <Space>
      <ImportDefaultTemplatesButton />
      <ResetPreferencesButton />
    </Space>
  );
};

export default PageButtonBar;
