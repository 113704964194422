import { gql } from '@apollo/client';
import { adminCategoryListFragment } from 'graphql/fragments';

const adminLibraryCategoriesQuery = gql`
  query adminLibraryCategoriesQuery {
    categories(isLibrary: true) {
      ...adminCategoryListFragment
    }
  }
  ${adminCategoryListFragment}
`;

export default adminLibraryCategoriesQuery;
