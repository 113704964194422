import { Calc } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { CalculationMode, HighlightBindingness } from 'constants/item';
import i18n from 'i18n';

export const totalValueToString = ({ paymentIntervalValues, showDigits }) => {
  return Calc.formatCurrency(paymentIntervalValues?.discountedValue, { showDigits });
};

/**
 * Formats an IBAN string into blocks of four characters.
 * @param {string} iban - The raw IBAN string.
 * @returns {string} The formatted IBAN string in blocks of four characters.
 */
export const formatIbanString = (iban) => {
  const cleanedIban = iban.replace(/\s+/g, '').trim();
  let formattedIban = '';
  for (let i = 0; i < cleanedIban.length; i += 4) {
    const block = cleanedIban.substr(i, 4);
    formattedIban += block + (i + 4 < cleanedIban.length ? ' ' : '');
  }
  return formattedIban;
};

export const formatPaymentIntervalAndHighlightBindingness = ({
  highlightBindingness,
  paymentInterval,
  calculationMode,
}) =>
  [
    i18n.t(`common.Item.paymentIntervalValue.${paymentInterval}`),
    calculationMode === CalculationMode.ON_ACTUAL_COST || highlightBindingness === HighlightBindingness.NO_HIGHLIGHT
      ? null
      : i18n.t(`common.Item.highlightBindingness.${highlightBindingness}`),
  ]
    .filter(Boolean)
    .join(', ');

export const formatPricingFormula = ({ calculationMode, formula }) =>
  calculationMode === CalculationMode.ON_ACTUAL_COST
    ? i18n.t(`common.Item.calculationMode.${calculationMode}`)
    : formula;

/**
 * Removes all whitespaces of the string and trims it
 * @param {string} str - The string to format.
 * @returns {string} The formatted string.
 */
export const removeWhiteSpaces = (str) => {
  return str.replace(/[ ]/g, '').trim();
};
