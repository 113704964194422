import { FaArrowRight } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';
import Button from './Button';
import c from './WhiteButton.module.less';
import { Sans35px600 } from './Text/Text';

const WhiteButton = ({ onClick }) => {
  const isSmallWidthDevice = useMediaQuery({ maxWidth: 500 });
  return (
    <Button className={c.button} onClick={onClick} icon={<FaArrowRight />}>
      <Sans35px600
        style={{ justifyContent: 'center' }}
        textStyle={{ color: 'unset', fontWeight: 800, ...(isSmallWidthDevice && { fontSize: 19 }) }}
      >
        Get started
      </Sans35px600>
    </Button>
  );
};
export default WhiteButton;
