import { Modal } from 'antd';
import './ConfirmModal.less';
import { ApolloProvider } from '@apollo/client';
import apolloClient from 'graphql/apollo';
import { ThemeProvider } from 'styled-components';
import { useThemeRichEditor } from 'graphql/hooks';

const { info } = Modal;
const InfoModal = ({ content }) => {
  const { theme } = useThemeRichEditor();
  return <ThemeProvider theme={theme}>{content}</ThemeProvider>;
};

const infoModal = ({
  width,
  content,
  maskClosable = false, // grey background clickable
  title,
  onOk = () => {},
}) =>
  info({
    title,
    width,
    content: (
      <ApolloProvider client={apolloClient}>
        <InfoModal content={content} />
      </ApolloProvider>
    ),
    onOk,
    maskClosable,
  });

export default infoModal;
