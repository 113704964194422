import { useTranslation } from 'react-i18next';
import classes from './PriceVatInfo.module.less';

/**
 * PriceVatInfo component to show if prices have vat or not
 * @param {Object} inputParameters - Input parameters of the component
 * @param {Object} inputParameters.shoppingCart - Shopping Cart object to get the vat options
 * @returns {JSX.Element} component to show if total prices of cart has vat
 * @component
 */
const PriceVatInfo = ({ shoppingCart }) => {
  const { t } = useTranslation();
  const { showVat, vat, vatType } = shoppingCart;

  const textProps = { vatValue: (vat?.value || 0) * 100, vatType };

  if (!showVat)
    return <p className={classes.vatInfo}>{t('signing.serviceOverviewStep.priceCard.vatInfo.noVat', textProps)}</p>;

  return <p className={classes.vatInfo}>{t('signing.serviceOverviewStep.priceCard.vatInfo.vatIncluded', textProps)}</p>;
};

export default PriceVatInfo;
