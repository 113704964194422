import equal from 'fast-deep-equal/es6/react';
import { memo, useCallback, useMemo, useState } from 'react';
import { superAdminTenantActionsQuery } from 'graphql/queries';
import { useCachedQuery } from 'graphql/utils';
import { getMessageFromGraphQLError, grabFirstGQLDataResult } from 'utils/helpers';
import { formatDateTime } from 'utils/date';
import { Alert, Button, Card, Drawer, Skeleton, Timeline } from 'antd';
import { FundViewOutlined, LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import EmptyBox from 'components/common/EmptyBox';

const skeletons = [...new Array(2)].map((_, i) => i + 1);

const TenantActionList = ({ _id, name, onClose, visible }) => {
  const { t } = useTranslation();
  const { data, loading, error } = useCachedQuery(superAdminTenantActionsQuery, {
    variables: { _id },
    fetchPolicy: 'cache-and-network',
  });
  const tenantActions = useMemo(() => grabFirstGQLDataResult(data), [data]);
  return (
    <Drawer
      title={t('superAdmin.ManageTenants.logBookModalTitle', { tenantName: name })}
      width={600}
      visible={visible}
      onClose={onClose}
    >
      {error ? <Alert type="error" message={getMessageFromGraphQLError(error)} /> : null}
      {!data && loading ? skeletons.map((k) => <Skeleton title loading active key={k} />) : null}
      {isEmpty(tenantActions) && !loading && !error ? (
        <Card>
          <EmptyBox label={t('superAdmin.ManageTenants.emptyListMessage')} />
        </Card>
      ) : null}
      <Timeline mode="right">
        {loading && tenantActions?.length ? (
          <Timeline.Item key="loading" dot={<LoadingOutlined />}>
            {t('superAdmin.ManageTenants.loadingTenantsActions')}
          </Timeline.Item>
        ) : null}
        {
          // eslint-disable-next-line no-shadow
          tenantActions?.map(({ _id, createdAt, createdBy, action }) => (
            <Timeline.Item
              key={_id}
              label={
                <div>
                  <div>{createdBy?.fullName}</div>
                  <div>{createdAt && formatDateTime(new Date(createdAt))}</div>
                </div>
              }
            >
              {action}
            </Timeline.Item>
          ))
        }
      </Timeline>
    </Drawer>
  );
};
const TenantActionButton = ({ classNameButton, _id, name }) => {
  const [isOpen, setIsOpen] = useState(false);
  const onClose = useCallback(() => setIsOpen(false), []);
  return (
    <>
      <Button className={classNameButton} icon={<FundViewOutlined />} onClick={() => setIsOpen(true)} />
      {isOpen && <TenantActionList name={name} visible={isOpen} onClose={onClose} _id={_id} />}
    </>
  );
};
export default memo(TenantActionButton, equal);
