import { useState, useEffect } from 'react';

/**
 * @typedef {Object} ElementSize
 * @property {Number} width - Width of element
 * @property {Number} height - Height of element
 */

/**
 * Listen on size changes of a specified element
 * @param {Element} elementReference - Element, for which the size should be monitored
 * @returns {ElementSize} returns current size of element (height and width)
 */
const useCurrentSizeOfElement = (elementReference) => {
  const [width, setWidth] = useState(elementReference?.getBoundingClientRect().width ?? 0);
  const [height, setHeight] = useState(elementReference?.getBoundingClientRect().height ?? 0);

  const handleResize = (elementReferenceToUse) => {
    setWidth(elementReferenceToUse?.getBoundingClientRect().width ?? 0);
    setHeight(elementReferenceToUse?.getBoundingClientRect().height ?? 0);
  };

  useEffect(() => {
    handleResize.bind(null, elementReference)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementReference]);

  useEffect(() => {
    window.addEventListener('resize', handleResize.bind(null, elementReference));
    return () => {
      window.removeEventListener('resize', handleResize.bind(null, elementReference));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementReference]);

  return { width, height };
};

export default useCurrentSizeOfElement;
