import { Alert, Typography } from 'antd';
import { BulbOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import DnsEntry from './DnsEntry';
import { createDmarcHostname } from './createDmarcHostname';
import classes from './DMARCRecord.module.less';

const { Title } = Typography;

const DMARCRecord = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext();

  return (
    <>
      <Title level={4} className={classes.h4Subheading}>
        {t('admin.Setting.Email.inputs.dnsSettings.dmarcRecord.heading')}
      </Title>
      <Alert
        type="info"
        icon={<BulbOutlined />}
        message={t('admin.Setting.Email.inputs.dnsSettings.dmarcRecord.message')}
        description={t('admin.Setting.Email.inputs.dnsSettings.dmarcRecord.description')}
        className="alert-info"
        showIcon
      />
      <DnsEntry
        name="dmarcRecord"
        label={t('admin.Setting.Email.inputs.dnsSettings.dmarcRecord.label')}
        value={t('admin.Setting.Email.inputs.dnsSettings.dmarcRecord.value')}
        recordHostnameLabel={t('admin.Setting.Email.inputs.dnsSettings.dmarcRecord.hostnameLabel')}
        recordHostname={createDmarcHostname(values.dkimSettings.domainName)}
        recordType={t('admin.Setting.Email.inputs.dnsSettings.dmarcRecord.type')}
        recordTypeLabel={t('admin.Setting.Email.inputs.dnsSettings.dmarcRecord.typeLabel')}
        key="dmarcRecord"
        noCheck
      />
    </>
  );
};

export default DMARCRecord;
