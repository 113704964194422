import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { createPerson } from 'pages/admin/ManagePersons/ManagePersons';
import PersonsList from 'pages/admin/ManagePersons/PersonsList';
import I18nFormik from 'components/common/I18nFormik';
import FormItem from 'components/common/FormItem';
import { Form } from 'formik-antd';
import * as Yup from 'yup';
import { useMemo } from 'react';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { useCachedQuery } from 'graphql/utils';
import { adminPersonsInTenantQuery } from 'graphql/queries';
import i18n from 'i18n';
import { useOnboardingContext } from '../OnboardingContext';
import OnboardingStepTemplate from '../components/OnboardingStepTemplate';

const signaturesSchema = () =>
  Yup.object().shape({
    persons: Yup.number().min(1, i18n.t('onboarding.signatures.submitError')),
  });
export default function Signatures() {
  const { t } = useTranslation();
  const { onSubmit } = useOnboardingContext();
  const { data } = useCachedQuery(adminPersonsInTenantQuery);
  const numberOfPersons = useMemo(() => {
    return grabFirstGQLDataResult(data)?.length;
  }, [data]);
  return (
    <I18nFormik
      enableReinitialize
      initialValues={{
        persons: numberOfPersons,
      }}
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
      validationSchema={signaturesSchema}
    >
      {({ isSubmitting }) => (
        <Form layout="vertical">
          <OnboardingStepTemplate
            title={t('onboarding.signatures.title')}
            titleButton={
              <Button icon={<PlusOutlined />} onClick={createPerson} type="primary">
                {t('onboarding.signatures.addButton')}
              </Button>
            }
            description={t('onboarding.signatures.textAboveVideo')}
            videoUrl={t('onboarding.signatures.videoUrl')}
            videoCaption={t('onboarding.signatures.videoCaption')}
            isSubmitting={isSubmitting}
          >
            <FormItem name="persons">
              <PersonsList />
            </FormItem>
          </OnboardingStepTemplate>
        </Form>
      )}
    </I18nFormik>
  );
}
