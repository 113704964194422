import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import { Link } from 'react-router-dom';
import routePaths from 'router/route-paths';
import { CalendarOutlined, EuroCircleOutlined, PlusOutlined, TeamOutlined } from '@ant-design/icons';
import OnboardingStepTemplate from '../components/OnboardingStepTemplate';

export default function Finish() {
  const { t } = useTranslation();
  const [user] = useCurrentUser();

  return (
    <OnboardingStepTemplate
      title={t('onboarding.finish.title')}
      description={t('onboarding.finish.textAboveVideo', { userFirstName: user?.profile.firstName })}
      videoUrl={t('onboarding.finish.videoUrl')}
      videoCaption={t('onboarding.finish.videoCaption')}
      hideNavigationButtons
      showAlert
      alertProp={{ message: t('onboarding.finish.successfulMessage'), type: 'success' }}
    >
      <p>{t('onboarding.finish.buttonsTitle')}</p>
      <Link to={routePaths.shoppingCart}>
        <Button type="primary" icon={<PlusOutlined />}>
          {t('onboarding.finish.shoppingCartButton')}
        </Button>
      </Link>
      <br />
      <br />
      <Link to={routePaths.manageUsers}>
        <Button icon={<TeamOutlined />}>{t('onboarding.finish.manageUsersButton')}</Button>
      </Link>
      <br />
      <br />
      <Link to={routePaths.catalogueConfiguration}>
        <Button icon={<EuroCircleOutlined />}>{t('onboarding.finish.catalogueConfigurationButton')}</Button>
      </Link>
      <br />
      <br />
      <a href={t('onboarding.finish.calendlyUrl')} target="_blank" rel="noreferrer">
        <Button icon={<CalendarOutlined />}>{t('onboarding.finish.calendly')}</Button>
      </a>
    </OnboardingStepTemplate>
  );
}
