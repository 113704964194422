import { gql } from '@apollo/client';
import userViewShoppingCartDiscountFragment from './userViewShoppingCartDiscountFragment';
import userViewShoppingCartInputFieldFragment from './userViewShoppingCartInputFieldFragment';
import userViewShoppingCartItemFragment from './userViewShoppingCartItemFragment';
import userViewShoppingCartStaticItemFragment from './userViewShoppingCartStaticItemFragment';

const userViewShoppingCartCategoriesFragment = gql`
  fragment userViewShoppingCartCategoriesFragment on Category {
    _id
    _apolloCacheKey
    introForStaticItem
    name
    outroForStaticItem
    inputFields {
      ...userViewShoppingCartInputFieldFragment
    }
    discounts {
      ...userViewShoppingCartDiscountFragment
    }
    items {
      ...userViewShoppingCartItemFragment
    }
    staticItems {
      ...userViewShoppingCartStaticItemFragment
    }
  }
  ${userViewShoppingCartInputFieldFragment}
  ${userViewShoppingCartDiscountFragment}
  ${userViewShoppingCartItemFragment}
  ${userViewShoppingCartStaticItemFragment}
`;

export default userViewShoppingCartCategoriesFragment;
