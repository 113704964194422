import Switch from 'components/common/Switch';

const NotifyUsersByMailSwitch = ({ t }) => {
  return (
    <Switch
      name="notifyUsersByMail"
      label={t('admin.Setting.DigitalSignaturePreferences.generalSettings.notifyUsersByMail.label')}
      tooltip={t('admin.Setting.DigitalSignaturePreferences.generalSettings.notifyUsersByMail.tooltip')}
      defaultChildren
    />
  );
};

export default NotifyUsersByMailSwitch;
