import countries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import de from 'i18n-iso-countries/langs/de.json';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

const countriesByLocale = { en, de };
export function useCountries() {
  const { t } = useTranslation();
  const locale = t('localeCode');

  return useMemo(() => {
    countries.registerLocale(countriesByLocale[locale]);
    return Object.entries(countries.getNames(locale, { select: 'official' })).map(([_id, label]) => ({ _id, label }));
  }, [locale]);
}
