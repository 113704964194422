import { gql } from '@apollo/client';

const moveItemMutation = gql`
  mutation moveItemMutation($_id: ID!, $offset: Int, $order: Int) {
    moveCategoryItem(_id: $_id, offset: $offset, order: $order) {
      _id
      order
    }
  }
`;

export default moveItemMutation;
