import ScrollToTop from 'components/common/ScrollToTop';
import AuthLayout from 'components/layouts/AuthLayout';
import equal from 'fast-deep-equal/es6/react';
import AcceptInvitation from 'pages/verify/AcceptInvitation';
import { memo } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import routePaths from './route-paths';

export const verifyRoutes = [
  {
    path: routePaths.acceptInvitation,
    component: AcceptInvitation,
  },
];

const VerifyRouter = () => (
  <AuthLayout>
    <ScrollToTop />
    <Routes>
      {verifyRoutes.map(({ path, component: C, ...rest }) => (
        <Route key={path} path={path} element={<C />} {...rest} />
      ))}
      <Route path="*" element={<Navigate to={routePaths.dashboard} />} />
    </Routes>
  </AuthLayout>
);
export default memo(VerifyRouter, equal);
