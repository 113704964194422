import { FaEllipsisV, FaFileAlt } from 'react-icons/fa';
import c from './Document.module.less';
import { Sans26px400 } from './Text/Text';

const Document = ({ document }) => {
  return (
    <div className={c.document}>
      <div className={c.menu}>
        <FaEllipsisV />
      </div>
      <div className={c.fileContainer}>
        <FaFileAlt className={c.file} />
      </div>
      <div className={c.nameContainer}>
        <Sans26px400 mobileTextStyle={{ fontSize: 10 }}>{document.name}</Sans26px400>
      </div>
    </div>
  );
};

export default Document;
