import apollo from 'graphql/apollo';
import { deleteEmailTemplateMutation } from 'graphql/mutations';
import {
  adminEmailTemplatesQuery,
  adminHasTenantImportedDefaultTemplatesQuery,
  adminIsEmailTemplateUsedQuery,
  currentUserQuery,
} from 'graphql/queries';
import i18n from 'i18n';
import confirmModal from 'utils/confirmModal';
import { grabFirstGQLDataResult } from 'utils/helpers';
import toast from 'utils/toast';
import shoppingCartPreferencesFragmentForDeleteEmailTemplate from 'graphql/fragments/admin/adminSCPreferencesForDeleteEmailTemplateFragment';

/**
 * Deletes email template and updates/refetches relevant queries
 * @param {Object} inputParameters - Input parameters of the function
 * @param {String} inputParameters._id - Id of email template, which should be deleted
 * @param {Boolean} inputParameters.isLibrary - Is the email template are a common library template
 * @returns {*}
 */
const deleteEmailTemplate = async ({ _id, isLibrary }) =>
  apollo
    .mutate({
      mutation: deleteEmailTemplateMutation,
      variables: { _id },
      refetchQueries: [{ query: adminHasTenantImportedDefaultTemplatesQuery }],
      update: (cache, { data }) => {
        const isRemoved = grabFirstGQLDataResult(data);
        if (isRemoved) {
          if (!isLibrary) {
            const tenantId = cache.readQuery({ query: currentUserQuery })?.me?.tenantId;
            const preferences = cache.readFragment({
              id: `ShoppingCartPreferences___${tenantId}`,
              fragment: shoppingCartPreferencesFragmentForDeleteEmailTemplate,
            });
            if (preferences?.emailTemplateId === _id) {
              cache.writeFragment({
                id: `ShoppingCartPreferences___${tenantId}`,
                fragment: shoppingCartPreferencesFragmentForDeleteEmailTemplate,
                data: {
                  emailTemplateId: null,
                },
              });
            }
          }

          const cachedData = cache.readQuery({
            query: adminEmailTemplatesQuery,
            variables: { isLibrary },
          });
          cache.writeQuery({
            query: adminEmailTemplatesQuery,
            variables: { isLibrary },
            data: {
              emailTemplates: cachedData.emailTemplates.filter((emailTemplate) => emailTemplate._id !== _id),
            },
          });
        }
      },
    })
    .then(() => toast.successDefault())
    .catch(() => toast.error(i18n.t('admin.EmailTemplates.modal.error.delete')));

/**
 * Checks, if template is in use and shows info modal in this case and deletes template after confirm or if not used
 * @param {Object} inputParameters - Input parameters of function
 * @param {String} inputParameters._id - Id of email template, which should be deleted
 * @param {Boolean} inputParameters.isLibrary - Is the email template are a common library template
 */
const deleteEmailTemplateWithIsUsedCheck = async ({ _id, isLibrary }) => {
  const { data } = await apollo.query({
    query: adminIsEmailTemplateUsedQuery,
    variables: { _id, isLibrary },
    fetchPolicy: 'network-only',
  });
  if (!data.isEmailTemplateUsed) {
    await deleteEmailTemplate({ _id, isLibrary });
  } else {
    confirmModal({
      cancelText: i18n.t('deleteEmailTemplateConfirmation.cancel'),
      okText: i18n.t('deleteEmailTemplateConfirmation.ok'),
      okType: 'danger',
      onOk: () => deleteEmailTemplate({ _id, isLibrary }),
      title: i18n.t('deleteEmailTemplateConfirmation.documentIsUsed'),
    });
  }
};

/**
 * Shows confirm delete modal for email templates and deletes emails
 * @param {Object} inputParameters - Input parameters of the function
 * @param {String} inputParameters._id - Id of the email template, which should be deleted
 * @param {Boolean} inputParameters.isLibrary - Is the email template are a common library template
 * @returns {Object} object with manipulation functions for modal
 */
export const deleteEmailTemplatesWithConfirmation = ({ _id, isLibrary }) =>
  confirmModal({
    okText: i18n.t('common.ok'),
    cancelText: i18n.t('common.cancel'),
    okType: 'danger',
    onOk: async () => deleteEmailTemplateWithIsUsedCheck({ _id, isLibrary }),
    title: i18n.t('admin.EmailTemplates.modal.deleteTitle'),
  });

export default deleteEmailTemplate;
