import { useCachedQuery } from 'graphql/utils';
import { useDocumentTemplateList } from 'graphql/hooks';
import { gql } from '@apollo/client';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { differenceBy } from 'lodash';
import { useMemo } from 'react';

export const userShoppingCartDocumentTemplatesQuery = gql`
  query userShoppingCartDocumentTemplatesQuery($_id: ID!) {
    userShoppingCart(_id: $_id) {
      _id
      documentTemplates {
        _id
      }
    }
  }
`;

function useAvailableDocumentTemplatesToAdd({ shoppingCartId }) {
  const { data: documentList, loading: documentListLoading, error: documentListError } = useDocumentTemplateList(false);
  const {
    data: shoppingCartDocumentsData,
    loading: shoppingCartDocumentsLoading,
    error: shoppingCartDocumentsError,
  } = useCachedQuery(userShoppingCartDocumentTemplatesQuery, {
    variables: { _id: shoppingCartId },
  });

  const shoppingCartDocuments = useMemo(
    () => grabFirstGQLDataResult(shoppingCartDocumentsData)?.documentTemplates,
    [shoppingCartDocumentsData],
  );

  const availableDocuments = useMemo(() => {
    return differenceBy(documentList, shoppingCartDocuments, (e) => e._id);
  }, [documentList, shoppingCartDocuments]);

  return {
    shoppingCartDocuments,
    availableDocuments,
    loading: shoppingCartDocumentsLoading || documentListLoading,
    error: shoppingCartDocumentsError || documentListError,
  };
}

export default useAvailableDocumentTemplatesToAdd;
