import { gql } from '@apollo/client';
import { adminEmailTemplateFragment } from 'graphql/fragments';

const importEmailTemplatesFromLibraryMutation = gql`
  mutation importEmailTemplatesFromLibraryMutation($input: [EmailTemplateImportInput]) {
    importEmailTemplatesFromLibrary(input: $input) {
      ...adminEmailTemplateFragment
    }
  }
  ${adminEmailTemplateFragment}
`;

export default importEmailTemplatesFromLibraryMutation;
