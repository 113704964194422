import EmailTemplateSelect from 'components/common/EmailTemplateSelect';
import { useTranslation } from 'react-i18next';

/**
 * SignedBySigneeNotificationTemplateSelect component to select a commonlibrary email template for the signed by signee notification email default template (Required formik context)
 * @returns {JSX.Element} email template select for the default templates settings for the super admin
 * @component
 */
const SignedBySigneeNotificationTemplateSelect = () => {
  const { t } = useTranslation();
  return (
    <EmailTemplateSelect
      fieldName="signedBySigneeNotificationTemplate"
      label={t('superAdmin.ManageDigitalSignature.defaultTemplatesCard.signedBySigneeNotificationTemplate.label')}
      placeholder={t(
        'superAdmin.ManageDigitalSignature.defaultTemplatesCard.signedBySigneeNotificationTemplate.placeholder',
      )}
      tooltip={t('superAdmin.ManageDigitalSignature.defaultTemplatesCard.signedBySigneeNotificationTemplate.tooltip')}
      isLibrary
    />
  );
};

export default SignedBySigneeNotificationTemplateSelect;
