import { useState } from 'react';
import { DIGITAL_SIGNATURE_STATE } from '@JavaScriptSuperstars/kanzleipilot-shared/lib/digitalSignature';
import { Row, Button as AntdButton } from 'antd';
import { useTranslation } from 'react-i18next';
import useFasterPreferences from 'hooks/user/useFasterPreferences';
import useStartDigitalSignatureDrawer from 'utils/OpenStartDigitalSignatureModal';
import RevokeDigitalSignatureProcessModal from 'components/user/RevokeDigitalSignatureProcessModal/RevokeDigitalSignatureProcessModal';
import RemindAllSigneesModal from 'components/user/RemindAllSigneesModal/RemindAllSigneesModal';
import Button from './Button';
import classes from './AdditionalButton.module.less';

/**
 * ButtonRow component for AdditionalButton component to show button in a row
 * @param {Object} inputParameter - Input parameter of button row component
 * @returns {JSX.Element} ButtonRow component for AdditionalButton component
 * @component
 */
const ButtonRow = ({ children }) => {
  return <Row className={classes.additionalButtonRow}>{children}</Row>;
};

/**
 * AdditionalButton component for digital signature card for additional actions by digital signature state
 * @param {Object} inputParameters - Input parameter of additional button component
 * @param {String} inputParameters.digitalSignatureState - Digital signature state of current shopping cart
 * @param {String} inputParameters.shoppingCartId - Shopping cart id of current shopping cart
 * @param {Object} inputParameters.shoppingCart - Current shopping cart
 * @returns {JSX.Element} AdditionalButton component for digital signature card of shopping cart view
 * @component
 */
const AdditionalButton = ({ digitalSignatureState, shoppingCartId, shoppingCart }) => {
  const { t: translation } = useTranslation();
  const [showRevokeModal, setShowRevokeModal] = useState(false);
  const [showRemindAllModal, setShowRemindAllModal] = useState(false);
  const { preferences: currentPreferences } = useFasterPreferences();
  const { openStartDigitalSignatureDrawer } = useStartDigitalSignatureDrawer();

  const onShowRevokeModal = () => {
    setShowRevokeModal(true);
  };

  const onCloseRevokeModal = () => {
    setShowRevokeModal(false);
  };

  const onShowRemindAllModal = () => {
    setShowRemindAllModal(true);
  };

  const onCloseRemindAllModal = () => {
    setShowRemindAllModal(false);
  };

  if (digitalSignatureState === DIGITAL_SIGNATURE_STATE.DRAFT && !shoppingCart?.isStarting) {
    const onStartDigitalSignature = () => {
      openStartDigitalSignatureDrawer({ preferences: currentPreferences, shoppingCart, translation });
    };
    return (
      <ButtonRow
        label={translation('viewer.ShoppingCartView.DigitalSignature.additionalButtons.startDigitalSignature')}
        onClick={onStartDigitalSignature}
      >
        <AntdButton onClick={onStartDigitalSignature} type="primary" shape="round">
          {translation('viewer.ShoppingCartView.DigitalSignature.additionalButtons.startDigitalSignature')}
        </AntdButton>
      </ButtonRow>
    );
  }
  if (digitalSignatureState === DIGITAL_SIGNATURE_STATE.STARTED) {
    // const onRevokeCart = () => {};
    return (
      <>
        <ButtonRow>
          <Button onClick={onShowRevokeModal}>
            {translation('viewer.ShoppingCartView.DigitalSignature.additionalButtons.revokeCart')}
          </Button>
          <Button onClick={onShowRemindAllModal}>
            {translation('viewer.ShoppingCartView.DigitalSignature.additionalButtons.remindAll')}
          </Button>
        </ButtonRow>
        {showRevokeModal && (
          <RevokeDigitalSignatureProcessModal onCloseModal={onCloseRevokeModal} shoppingCartId={shoppingCartId} />
        )}
        {showRemindAllModal && (
          <RemindAllSigneesModal
            onCloseModal={onCloseRemindAllModal}
            shoppingCartId={shoppingCartId}
            shoppingCart={shoppingCart}
          />
        )}
      </>
    );
  }
  return null;
};

export default AdditionalButton;
