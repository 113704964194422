/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/iframe-has-title */
import Modal from 'antd/lib/modal/Modal';
import { useEffect, useState } from 'react';
import { toastGraphQLError } from 'utils/helpers';
import LoadingOverlay from 'components/common/LoadingOverlay';
import css from '!!raw-loader!!less-loader!./html.module.less';
import classes from './htmlModal.module.less';

const insertString = (string, source, position) => {
  return [string.slice(0, position), source, string.slice(position)].join('');
};
const addWrapper = (html) => {
  const openWrapperTagsHtml = insertString(html, '<div class="HtmlPreviewer"><div>', html.indexOf('<body>') + 6);
  const closeWrapperTagsHtml = insertString(
    openWrapperTagsHtml,
    '</div></div>',
    openWrapperTagsHtml.lastIndexOf('</body>'),
  );
  return closeWrapperTagsHtml;
};
const HTMLViewer = ({ html }) => {
  // const url = `https://docs.google.com/viewerng/viewer?url=${this.props.src}&embedded=true`;
  let newHtml = html.replace('</head>', `<style>${css}</style></head>`);
  newHtml = addWrapper(newHtml);
  newHtml = newHtml.replace(
    // eslint-disable-next-line prefer-regex-literals
    new RegExp(`(href="#(.*?)")`, 'g'),
    `$1 onclick="event.preventDefault(); document.getElementById('$2').scrollIntoView(); return false;"`,
  );
  return <iframe name="html-preview" width="100%" height="100%" srcDoc={newHtml} />;
  // return <iframe style={{ marginTop: 24, width: 'calc(90vw - 48px)', height: 'calc(100vh - 100px - 24px - 24px - 53px - 24px - 20px - 10px)' }} src={url} />;
};

const HtmlModalComponent = (props) => {
  const {
    // categoryId,
    closeModal,
    formatError,
    handleClose,
    headerText,
    isClosing,
    isSubmitting,
    // onSubmit,
    value,
  } = props;
  const [html, setHtml] = useState();
  useEffect(() => value.then(setHtml).catch(toastGraphQLError), [value]);

  return (
    <Modal
      afterClose={closeModal}
      confirmLoading={isSubmitting}
      formatError={formatError}
      onOk={handleClose}
      onCancel={handleClose}
      title={headerText}
      visible={!isClosing}
      width="90vw"
      className="modal-top-50px"
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <div className={classes.modal}>{html ? <HTMLViewer html={html} /> : <LoadingOverlay />}</div>
    </Modal>
  );
};
export default HtmlModalComponent;
