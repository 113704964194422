import { createContext, useContext } from 'react';

const ResetContext = createContext(null);
export const ResetProvider = ResetContext.Provider;
export function useResetContext() {
  const context = useContext(ResetContext);
  return context;
}

export const resetStore = {
  email: '',
  setEmail(email) {
    this.email = email;
  },
};
