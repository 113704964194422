import { gql } from '@apollo/client';
import { adminDocumentTemplateByIdFragment } from 'graphql/fragments';

const adminDocumentTemplateByIdQuery = gql`
  query adminDocumentTemplateByIdQuery($documentTemplateId: ID!) {
    documentTemplateById(documentTemplateId: $documentTemplateId) {
      ...adminDocumentTemplateByIdFragment
    }
  }
  ${adminDocumentTemplateByIdFragment}
`;

export default adminDocumentTemplateByIdQuery;
