import { setGraphqlErrors } from 'components/common/ErrorComponent';
import apollo from 'graphql/apollo';
import { updateCategoryMutation } from 'graphql/mutations';
import { superAdminAvailableAccessGroupsQuery } from 'graphql/queries';
import i18n from 'i18n';

const updateCategory = ({ _id, modifier }) =>
  apollo
    .mutate({
      mutation: updateCategoryMutation,
      variables: { _id, modifier },
      refetchQueries: [superAdminAvailableAccessGroupsQuery],
    })
    .catch((error) => {
      throw setGraphqlErrors({
        error,
        errorResolver: { Duplicated: ['name', i18n.t('admin.addCategoryModal.duplicatedErrorMessage')] },
      });
    });

export default updateCategory;
