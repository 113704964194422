import { setGraphqlErrors } from 'components/common/ErrorComponent';
import apollo from 'graphql/apollo';
import { setFileUploadedMutation } from 'graphql/mutations';
import i18n from 'i18n';

const setFileUploaded = ({ _id }) =>
  apollo.mutate({ mutation: setFileUploadedMutation, variables: { _id } }).catch((error) => {
    throw setGraphqlErrors({
      error,
      errorResolver: { Duplicated: ['name', i18n.t('admin.addCategoryModal.duplicatedErrorMessage')] },
    });
  });
export default setFileUploaded;
