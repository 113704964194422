import apollo from 'graphql/apollo';
import { moveDiscountMutation } from 'graphql/mutations';

const moveCategoryDiscount = async ({ _id, offset, order }, categoryListQuery) => {
  return apollo.mutate({
    mutation: moveDiscountMutation,
    variables: { _id, offset, order },
    refetchQueries: [{ query: categoryListQuery }],
  });
};
export default moveCategoryDiscount;
