import PageContainer from 'components/layout/PageContainer';
import React from 'react';
import Page from './Page';

export const CPUMEMUsage = () => {
  return (
    <PageContainer title="CPU/MEM Usage" style={{ height: '100%' }}>
      <Page />
    </PageContainer>
  );
};

export default CPUMEMUsage;
