import { Trans } from 'react-i18next';

/**
 * DiscountedInformation component to show discounted info text for service price in service overview step in signing process
 * @param {Object} inputParameters - Input parameters of the component
 * @param {Object} inputParameters.priceInformations - Price informations object of the service
 * @param {Object} inputParameters.acticeDiscount - Active discount of the service
 * @returns {JSX.Element} info text about discounted price
 * @component
 */
const DiscountedInformation = ({ priceInformations, activeDiscount }) => {
  if (priceInformations.isFixedMonthlyFee) return null;

  const { value, type } = activeDiscount;

  const discountValueWithUnit = type === 'percent' ? `${value} %` : `${value} €`;

  const components = { s: <s /> };
  const values = {
    discountValueWithUnit,
    valueWithoutDiscount: priceInformations.isCustomPrice
      ? priceInformations.formattedCustomPrice
      : priceInformations.formattedCalculatedPrice,
  };
  return (
    <Trans i18nKey="signing.serviceOverviewStep.service.discountInformation" components={components} values={values} />
  );
};

export default DiscountedInformation;
