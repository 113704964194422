import { InputFieldType, InternalInputFieldType } from 'constants/inputField';
import { filter } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

function usePricingFormula({ pricingFormula, inputFields }) {
  const { t } = useTranslation();
  const internalInputFields = useMemo(
    () =>
      filter(inputFields, (inputField) => inputField.type === InputFieldType.INTERNAL).map((inputField) => ({
        _id: inputField._id,
        name: t('FormulaNames.inputField_internal', {
          name: inputField.name,
          default:
            inputField.defaultType === InternalInputFieldType.INPUT
              ? inputField.defaultValue
              : inputField.variable.label,
        }),
      })),
    [inputFields, t],
  );
  return useMemo(
    () =>
      internalInputFields.reduce((acc, inputField) => acc.replaceAll(inputField._id, inputField.name), pricingFormula),
    [internalInputFields, pricingFormula],
  );
}

export default usePricingFormula;
