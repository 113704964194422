import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import equal from 'fast-deep-equal';
import MoreInfoWidget from 'components/common/MoreInfoWidget';

const MigratedShoppingCartHelperWidget = ({ buttonClassName }) => {
  const { t } = useTranslation();
  return (
    <MoreInfoWidget
      buttonClassName={buttonClassName}
      buttonText={t('user.MigratedHelperWidget.howUseButton')}
      title={t('user.MigratedHelperWidget.modalInfo.title')}
      helpText={t('user.MigratedHelperWidget.modalInfo.helpText')}
      videoCaption={t('user.MigratedHelperWidget.modalInfo.videoCaption')}
      videoUrl={t('user.MigratedHelperWidget.modalInfo.videoUrl')}
      imageUrl={t('user.MigratedHelperWidget.modalInfo.imageUrl')}
    />
  );
};

export default memo(MigratedShoppingCartHelperWidget, equal);
