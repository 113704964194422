import * as Yup from 'yup';
import i18n from 'i18n';

const schema = () => ({
  name: Yup.string()
    .transform((value) => value || '')
    .label(i18n.t('admin.ManagePersons.fields.name.label'))
    .required()
    .min(1),
  position: Yup.string()
    .transform((value) => value || '')
    .label(i18n.t('admin.ManagePersons.fields.position.label'))
    .required(),
});

export const PersonSchema = () => Yup.object().shape(schema());
