import { Alert } from 'antd';
import useWindowSize from 'hooks/common/useWindowSize';

/**
 * CustomWarningAlert component is a warning alert for the signing process pages
 * @param {Object} inputParameters - Input parameters of the component
 * @param {String | JSX.Element} inputParameters.message - Message of the alert
 * @param {String | JSX.Element} inputParameters.description - Description text of the alert
 * @param {String} inputParameters.className - Custom classname for the alert
 * @param {JSX.Element} inputParameters.icon - Custom icon for the alert
 * @returns {JSX.Element} warning alert for the signing process
 */
const CustomWarningAlert = ({ message, description, className, icon }) => {
  const { width } = useWindowSize();
  return (
    <Alert
      type="warning"
      showIcon={width > 576}
      description={description}
      message={message}
      className={className}
      icon={icon}
    />
  );
};

export default CustomWarningAlert;
