/**
 * calculates, if pdf drawer should be in width controlled mode by window size and an aspectratio
 * @returns {Boolean} returns true, if pdf drawer should be in width controlled mode, else returns false
 */
export const isWidthControlledMode = () => {
  const aspectRatio = 210 / 297;
  const windowAspectRatio = window.innerWidth / window.innerHeight;
  const widthControlled = windowAspectRatio < aspectRatio;
  return widthControlled;
};

/**
 * Calculates the width for a document with an aspectratio and the inputted height
 * @param {Number} height - Current height by which the width should be calculated
 * @returns {Number} calculated width related to inputted height
 */
export const calculateDocumentWidth = (height) => {
  const dinA4CorrectedAspectRatio = 212 / 297;
  const calculatedWidth = dinA4CorrectedAspectRatio * height;
  return calculatedWidth;
};

/**
 * Calculates the height for a document with an aspectratio and the inputted width
 * @param {Number} width - Current width by which the height should be calculated
 * @returns {Number} calculated height related to inputted width
 */
export const calculateDocumentHeight = (width) => {
  const revertedDinA4CorrectedAspectRatio = 297 / 212;
  const calculatedHeight = revertedDinA4CorrectedAspectRatio * width;
  return calculatedHeight;
};
