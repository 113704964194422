import { gql } from '@apollo/client';
import { adminFontFragment } from 'graphql/fragments';

const adminAvailableFontsQuery = gql`
  query adminAvailableFontsQuery {
    availableFonts {
      ...adminFontFragment
    }
  }
  ${adminFontFragment}
`;

export default adminAvailableFontsQuery;
