import { FaEye } from 'react-icons/fa';
import SignDocumentsLayout from '../../../components/layouts/SignDocumentsLayout';
import { Sans30px400, Sans35px600 } from '../Text/Text';
import CategoriesCollapse from '../CategoriesCollapse';
import { Br100, Br150, Br200 } from '../Text/Br';
import NextButton from '../NextButton';
import PricingOverview from '../PricingOverview';

const info =
  'Das ist der Nutzentext consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium.';
const benefits = [
  { _id: '1', text: 'benefit 1' },
  { _id: '2', text: 'benefit 2' },
  { _id: '3', text: 'benefit 3' },
];
const items = [
  {
    _id: '1',
    benefits,
    description: 'Zeitintervall: halbjährliches Strategiegespräch',
    info,
    name: 'Einrichtung Ihres FiBu-Mandats',
    pricing: '300',
    pricingMode: 'yearly',
  },
  {
    _id: '2',
    benefits,
    description: 'Zeitintervall: halbjährliches Strategiegespräch',
    info,
    name: 'Einrichtung Ihres FiBu-Mandats',
    pricing: '300',
    pricingMode: 'yearly',
  },
  {
    _id: '3',
    benefits,
    description: 'Zeitintervall: halbjährliches Strategiegespräch',
    info,
    name: 'Einrichtung Ihres FiBu-Mandats',
    pricing: '300',
    pricingMode: 'yearly',
  },
];
const data = {
  categories: [
    {
      _id: 'Finanzbuchhaltung',
      name: 'Finanzbuchhaltung',
      startDate: new Date(),
      total: 100,
      items,
    },
    {
      _id: 'Jahresabschluss',
      name: 'Jahresabschluss',
      startDate: new Date(),
      total: 200,
      items,
    },
    {
      _id: 'Lohn',
      name: 'Lohn',
      startDate: new Date(),
      total: 300,
      items,
    },
  ],
};
const ReviewServices = ({ setPage }) => {
  return (
    <SignDocumentsLayout>
      <Sans35px600 icon={<FaEye />}>Review your services</Sans35px600>
      <Br100 />
      <Sans30px400>
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.
      </Sans30px400>
      <Br150 />
      <CategoriesCollapse categories={data.categories} />
      <Br150 />
      <PricingOverview note="Existenzgründer-Nachlass (20% im ersten Jahr) im Bereich Finanzbuchhaltung" />
      <Br200 />
      <NextButton
        onClick={() => {
          setPage('additionalServices');
        }}
      >
        Next
      </NextButton>
    </SignDocumentsLayout>
  );
};
export default ReviewServices;
