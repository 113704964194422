export default function ButtonColorPreview({ text, primaryColor, fontFamily, color }) {
  return (
    <div
      style={{ backgroundColor: primaryColor, color, fontFamily, transition: 'none' }}
      className="ant-btn ant-btn-lg"
    >
      {text}
    </div>
  );
}
