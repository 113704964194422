/**
 * Format a date to a readable string
 * @param date created by 'new Date()' - example: Tue Nov 28 2023 17:33:45 GMT+0100 (Central European Standard Time)
 * @returns readable string - example: 28.11.2023, 17:35:10
 */
export function formatDateTime(date) {
  return new Date(date).toLocaleString('de-DE', {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
}
