import MoveButton from 'components/admin/drag/MoveButton';
import equal from 'fast-deep-equal/es6/react';
import { memo } from 'react';
import { AiOutlineRight } from 'react-icons/ai';
import { colors } from 'styles/colors';
import cn from 'classnames';
import classes from './CustomExpandIcon.module.less';

function CustomExpandIcon({ isActive }) {
  return (
    <div className={classes.expandIcon}>
      <MoveButton size={14} color={colors.dragButton} isPreview={false} />
      <div className={cn(classes.arrow, 'collapse-arrow', isActive && 'collapse-arrow-active')}>
        <AiOutlineRight size={14} color={colors.expandArrow} />
      </div>
    </div>
  );
}
const CustomExpandIconMemo = memo(CustomExpandIcon, equal);

function CustomExpandIconWrapper({ isActive }) {
  return <CustomExpandIconMemo isActive={isActive} />;
}

export default memo(CustomExpandIconWrapper, equal);
