import { forwardRef, memo } from 'react';
import { find, groupBy, map } from 'lodash';
import { useAdminInputFields } from 'graphql/hooks';
import { MentionContextProvider } from 'components/common/RichEditor/MentionContext';
import { BaseRichEditorField } from 'components/common/RichEditor/RichEditor';
import { RichEditorPlaceholders } from 'components/common/RichEditor/RichEditorPlaceholders';
import { useTranslation } from 'react-i18next';
import { withSingleLine } from 'components/common/RichEditor/plugins/withSingleLine';
import { Link } from 'react-router-dom';
import routePaths from 'router/route-paths';
import { EditOutlined } from '@ant-design/icons';
import { Button, Skeleton } from 'antd';
import equal from 'fast-deep-equal/es6/react';
import FormItem from 'components/common/FormItem';
import classes from './PricingFormulaInput.module.less';

const useDefaultFieldsHook = ({ categoryId, parentId }) => {
  return useAdminInputFields({ categoryId, parentId });
};

const defaultMentionContextValue = (fields) => {
  const renderMention = ({ element }) => {
    const { mentionType, _id } = element;
    if (mentionType === 'formula_function') return find(fields, (f) => f._id === _id)?.name ?? _id;
    if (mentionType === 'formula_reference') {
      const ff = find(fields, (f) => f._id === _id);
      if (!ff) return _id;
      return (
        // <Tooltip title={f.description}>
        <span>{ff.name}</span>
        // </Tooltip>
      );
    }
    return '?';
  };
  return { renderMention };
};
const EditConstants = () => {
  const { t } = useTranslation();
  return (
    <Link to={routePaths.constantsAndPlaceholdersSetting} target="_blank">
      <Button type="text" icon={<EditOutlined />}>
        {t('admin.itemModal.inputs.pricingFormula.editConstants')}
      </Button>
    </Link>
  );
};
const EditConstantsMemo = memo(EditConstants, equal);

const formulaMentions = (t) => [
  { _id: 'round' },
  { _id: 'RVG_Value_Fee', displayName: t('FormulaMentions.RVG_Value_Fee') },
  { _id: 'text' },
  { _id: 'StBVV_TabelleA' },
  { _id: 'StBVV_TabelleB' },
  { _id: 'StBVV_TabelleC' },
  { _id: 'StBVV_TabelleD_a' },
  { _id: 'StBVV_TabelleD_b' },
  { _id: 'scale' },
];
const typeFields = {
  categoryItem: 'item',
  itemTotal: 'itemTotal',
  category: 'category',
  function: 'function',
  categoryMonthlyAdvance: 'categoryMonthlyAdvance',
  scale: 'scaleFunctionParameters',
  variable: 'variable',
};

const usePrepareSchemaHook = ([fields, loading], { disabledFunctions, disabledSubmenu }) => {
  const { t } = useTranslation();
  if (loading) return [[], loading];
  const functionFields = formulaMentions(t)
    .filter((e) => !disabledFunctions.includes(e._id))
    .map(({ _id, displayName, disabled = false }) => {
      return {
        name: displayName ?? _id,
        originalName: displayName ?? _id,
        color: 'function',
        disabled,
        type: 'function',
        _id,
      };
    });
  const fields_ = [...fields.map((input) => ({ ...input, disabled: !!input?.disabled })), ...functionFields];
  const grouped = groupBy(fields_, 'type');
  const arrayGrouped = map(grouped, (value, key) => ({
    name: key,
    disabled: disabledSubmenu.includes(key),
    title: t(`admin.itemModal.inputs.pricingFormula.insertVariableMenu.subMenu.${typeFields[key]}`),
    fields: value.map((v) => ({
      ...v,
      title: v.originalName,
      onClick: ({ insertMention }) => {
        insertMention({ _id: v._id, mentionType: key === 'function' ? 'formula_function' : 'formula_reference' });
      },
    })),
  }));
  return [arrayGrouped, fields_, loading];
};
const emptyArr = [];
const PricingFormulaInput = (
  {
    hookProps,
    fieldsHook = useDefaultFieldsHook,
    disabledFunctions = emptyArr,
    disabledSubmenu = emptyArr,
    toolbarProps = {},
    ...props
  },
  ref,
) => {
  const { t } = useTranslation();
  const { name, label, tooltip } = props;
  const [fields, loading] = fieldsHook(hookProps);
  const [schema, allFields] = usePrepareSchemaHook([fields, loading], { disabledFunctions, disabledSubmenu });
  if (loading)
    return (
      <FormItem name={name} label={typeof label === 'string' ? t(label) : label} tooltip={tooltip}>
        <Skeleton.Input active className={classes.skeletonToolbar} />
        <Skeleton.Input active className={classes.skeletonInput} />
      </FormItem>
    );
  const toolbarChildren = () => {
    return [<RichEditorPlaceholders schema={schema} />, <EditConstantsMemo />];
  };

  return (
    <MentionContextProvider value={{ ...defaultMentionContextValue(allFields), schema }}>
      <BaseRichEditorField
        allowedModifiers={emptyArr}
        ref={ref}
        {...props}
        toolbarChildren={toolbarChildren}
        toolbarProps={toolbarProps}
        rootElement="div"
        withPlugins={withSingleLine}
      />
    </MentionContextProvider>
  );
};

export default forwardRef(PricingFormulaInput);
