import { gql } from '@apollo/client';

const userSigningProcessesQuery = gql`
  query signingProcessesQuery {
    openSigningProcessesOfUser {
      signingProcesses {
        link
        projectName
        nameOfClient
        shoppingCartId
      }
    }
  }
`;

export default userSigningProcessesQuery;
