import { Form } from 'antd';
import { NotificationFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useFormikField } from 'hooks/common/useFormikField';
import Card from 'components/common/Card';
import NotifySigneesAboutSignedBySigneeSwitch from './AdditionalNotificationSettingsCard/NotifySigneesAboutSignedBySigneeSwitch';
import NotifySigneesAboutSignedBySigneeTemplateSelect from './AdditionalNotificationSettingsCard/NotifySigneesAboutSignedBySigneeTemplateSelect';
import NotifyTenantSigneesAboutOpenBySigneeSwitch from './AdditionalNotificationSettingsCard/NotifyTenantSigneesAboutOpenBySigneeSwitch';
import NotifyTenantSigneesAboutOpenBySigneeTemplateSelect from './AdditionalNotificationSettingsCard/NotifyTenantSigneesAboutOpenBySigneeTemplateSelect';

/**
 * AdditionalNotificationsSettingsCard component contains additional notification settings for the digital signature
 * @returns {JSX.Element} card for the digital signature preferences page
 * @component
 */
const AdditionalNotificationsSettingsCard = () => {
  const { t } = useTranslation();
  const { value: digitalSignatureEnabled } = useFormikField('digitalSignatureEnabled');
  if (!digitalSignatureEnabled) return null;
  return (
    <Card
      title={t('admin.Setting.DigitalSignaturePreferences.additionalNotificationSettings.cardLabel')}
      icon={<NotificationFilled />}
      style={{ padding: 0 }}
    >
      <Form layout="vertical">
        <NotifySigneesAboutSignedBySigneeSwitch />
        <NotifySigneesAboutSignedBySigneeTemplateSelect />
        <NotifyTenantSigneesAboutOpenBySigneeSwitch />
        <NotifyTenantSigneesAboutOpenBySigneeTemplateSelect />
      </Form>
    </Card>
  );
};

export default AdditionalNotificationsSettingsCard;
