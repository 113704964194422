import { Result, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import concatClassNames from 'utils/classNames';
import classes from './OnDocumentLoading.module.less';

const { Paragraph, Text } = Typography;

/**
 * OnError component, which is used to show an error screen in the pdf view
 * @param {Object} inputParameters - Input parameters of component
 * @param {Object} inputParameters.error - Error object
 * @param {String} inputParameters.error.message - Error message
 * @param {Number|String} inputParameters.error.status - Error status like 404 or Network error
 * @param {String} inputParameters.className - Classname for surrounding container
 * @returns {JSX.Element} error screen for pdf view to show fetching errors
 * @component
 */
const OnError = ({ error, className }) => {
  const { t } = useTranslation();
  if (!error) return null;

  const { message, status } = error;

  return (
    <div className={concatClassNames(classes.OnLoadingComponent, className)}>
      <Result status="error" title={status} subTitle={message}>
        <Paragraph>
          <Text>{t('PdfDrawer.onErrorAdditionalText')}</Text>
        </Paragraph>
      </Result>
    </div>
  );
};

export default OnError;
