import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import SigningInfoPagesLayout from 'components/layouts/SigningInfoPagesLayout';
import useSigningContext from 'hooks/signing/useSigningContext';
import signingShoppingCartQuery from 'graphql/queries/signing/signingShoppingCartQuery';
import { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router';
import routePaths from 'router/route-paths';
import { useApolloClient, useSubscription } from '@apollo/client';
import waitForDocsGeneratedSubscription from 'graphql/subscriptions/signing/waitForDocsGeneratedSubscription';
import classes from './InfoPages.module.less';
import pageClasses from './WaitForDocuments.module.less';

const WaitForDocuments = () => {
  const { loading, signingContext } = useSigningContext();
  const apolloClient = useApolloClient();
  const navigate = useNavigate();
  const eightMinutes = 8 * 60 * 1000;

  useEffect(() => {
    if (!loading && !signingContext) navigate(routePaths.signingUnvalid);
  }, [loading, signingContext, navigate]);

  useEffect(() => {
    setTimeout(() => {
      if (loading) navigate(routePaths.signingDocumentsPreparationTimeout);
    }, eightMinutes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSuccessfulGeneratedDocs = (documents) => {
    const cachedQueryData = apolloClient.cache.readQuery({ query: signingShoppingCartQuery });
    apolloClient.cache.writeQuery({ query: signingShoppingCartQuery, data: { ...cachedQueryData, pdfs: documents } });
    navigate(routePaths.signing);
  };
  const handleFailedToGenerateDocs = (errorMessage) => {
    console.error('Missing Docs Error', errorMessage);
    navigate(routePaths.signingDocumentsPreparationFailed);
  };

  useSubscription(waitForDocsGeneratedSubscription, {
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData.data.waitForDocsGenerated.state === 'SUCCESS')
        handleSuccessfulGeneratedDocs(subscriptionData.data.waitForDocsGenerated.documents);
      else handleFailedToGenerateDocs(subscriptionData.data.waitForDocsGenerated.errorMessage);
    },
  });

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 100,
      }}
      spin
    />
  );

  return (
    <SigningInfoPagesLayout contentAreaClassName={classes.contentArea}>
      <div className={classes.headingWrapper}>
        <h1 className={classes.infoPageHeading}>
          <Trans i18nKey="signing.infoPages.waitForDocuments.heading" components={{ br: <br /> }} />
        </h1>
        <Spin indicator={antIcon} className={pageClasses.spin} />
      </div>
    </SigningInfoPagesLayout>
  );
};

export default WaitForDocuments;
