import { useEffect } from 'react';
import { Divider, Tooltip, Typography } from 'antd';
import { useFormikContext, ErrorMessage } from 'formik';
import { Form } from 'formik-antd';
import { CheckCircleFilled } from '@ant-design/icons';
import FormItem from 'components/common/FormItem';
import SubHeading from 'components/common/SubHeading';
import Input from 'components/common/Input';
import Switch from 'components/common/Switch';
import SaveChangesButton from 'components/common/SaveChangesButton';
import { useFormikField } from 'hooks/common/useFormikField';
import I18nFormik from 'components/common/I18nFormik';
import { AlertFromFormik } from 'components/common/ErrorComponent';
import { toastGraphQLError } from 'utils/helpers';
import { updateEmailService } from 'graphql/methods';
import InfoAlert from 'components/user/shoppingCart/InfoAlert';
import toast from 'utils/toast';
import { useTranslation } from 'react-i18next';
import { updateKPEmailSettingSchema as schema } from './FormSchemas';
import { DKIM_SPFConfigurationStep } from './DKIM_SPFConfigurationStep';
import classes from './KPEmailServiceSettings.module.less';

const { Text } = Typography;

const KPEmailServiceSettingsForm = ({ show, initialValues }) => {
  const onSubmit = async (submitValues) => {
    await updateEmailService({ ...submitValues, mailServiceProvider: 'default' })
      .then(toast.successDefault)
      .catch(toastGraphQLError);
  };

  if (!show) return null;
  return (
    <I18nFormik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema} enableReinitialize>
      <Form layout="vertical">
        <AlertFromFormik />
        <KPEmailServiceSettings initialValues={initialValues} />
      </Form>
    </I18nFormik>
  );
};

const KPEmailServiceSettings = ({ initialValues }) => {
  const { setFieldValue, values } = useFormikContext();
  const { t } = useTranslation();
  const customDomainEnabled = values.dkimSettings.useCustomDomain;

  useEffect(() => {
    const domain = values.dkimSettings.domainName;
    const regexResult = /@(.+\.[^>]+)/g.exec(values.from);
    if (!regexResult && domain && domain !== '') setFieldValue('dkimSettings.domainName', '');
    if (!regexResult) return;
    setFieldValue('dkimSettings.domainName', regexResult[1] || '');
  }, [values.from, setFieldValue, values.dkimSettings?.domainName, values.domainName]);

  return (
    <>
      <FormItem
        name="from"
        label={t('admin.Setting.Email.inputs.from.label')}
        tooltip={t('admin.Setting.Email.inputs.from.tooltip')}
      >
        <Input name="from" autoComplete="off" />
      </FormItem>
      <Switch
        name="dkimSettings.useCustomDomain"
        label={t('admin.Setting.Email.inputs.customDomain.useCustomDomain')}
      />
      {!customDomainEnabled ? (
        <SaveChangesButton
          excludeFields={['domainName']}
          initialValues={initialValues}
          forceVisible={initialValues.mailServiceProvider === 'smtp'}
          marginTop="0px"
        />
      ) : null}
      <DisplayCustomDomainConfiguration show={customDomainEnabled} initialValues={initialValues} />
    </>
  );
};

function DisplayCustomDomainConfiguration({ show, initialValues }) {
  const { t } = useTranslation();
  const { value: domainIsVerified } = useFormikField('dkimSettings.isCustomDomainVerified');

  if (!show) return null;

  return (
    <>
      <Divider className={classes.divider} />
      <SubHeading marginTop="0px" paddingBottom="4px" title={t('admin.Setting.Email.inputs.customDomain.subHeading')} />
      <Text className={classes.introtext}>{t('admin.Setting.Email.inputs.customDomain.introtext')}</Text>
      <FormItem
        label={t('admin.Setting.Email.inputs.customDomain.label')}
        name="dkimSettings.domainName"
        help={<ErrorMessage style={{ marginBlock: 6 }} name="domainName" component="div" />}
        tooltip={t('admin.Setting.Email.inputs.customDomain.tooltip')}
      >
        <Input
          readOnly
          name="dkimSettings.domainName"
          placeholder={t('admin.Setting.Email.inputs.customDomain.placeholder')}
          autoComplete="off"
          className={classes.domainNameField}
          addonAfter={
            domainIsVerified ? (
              <Tooltip title={t('admin.Setting.Email.inputs.dkimStatusCheck.tooltip.connected')}>
                <CheckCircleFilled className={classes.domainAuthentificatedIcon} />
              </Tooltip>
            ) : null
          }
        />
      </FormItem>
      {!domainIsVerified ? (
        <InfoAlert className={classes.infoAlert}>{t('admin.Setting.Email.inputs.customDomain.notAuthText')}</InfoAlert>
      ) : null}
      <SaveChangesButton
        excludeFields={['domainName']}
        initialValues={initialValues}
        forceVisible={initialValues.mailServiceProvider === 'smtp'}
        marginTop="0px"
      />
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <DKIM_SPFConfigurationStep domain={initialValues?.dkimSettings.domainName} />
    </>
  );
}

export default KPEmailServiceSettingsForm;
