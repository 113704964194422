import useAvailableDocumentTemplatesToAdd from 'graphql/hooks/useAvailableDocumentTemplatesToAdd';
import { useCompareToGlobalContext } from 'contexts/CompareToGlobalContext';
import DocumentsCard from 'components/common/DocumentsCard';
import AddDocumentsCell from './AddDocumentsCell';

/**
 * CustomizedDocumentsCard component is the card for generated documents in the shopping cart view with an add button to add additional documents
 * @param {Object} inputParameters - Input parameters of the component
 * @param {String} inputParameters.shoppingCartId - Id of the shopping cart object
 * @param {Boolean} inputParameters.disabledAdding - Should the add button be disabled
 * @param {String} inputParameters.digitalSignatureState - Digital signature state of the shopping cart
 * @returns {JSX.Element} card for generated documents in the shopping cart view
 * @component
 */
const CustomizedDocumentsCard = ({ shoppingCartId, disabledAdding, digitalSignatureState }) => {
  const { isCompare } = useCompareToGlobalContext();
  const { availableDocuments, shoppingCartDocuments } = useAvailableDocumentTemplatesToAdd({ shoppingCartId });
  return availableDocuments?.length || shoppingCartDocuments?.length ? (
    <DocumentsCard
      fetchPolicy="cache-and-network"
      shoppingCartId={shoppingCartId}
      isShowCompare={isCompare}
      // eslint-disable-next-line react/no-unstable-nested-components
      customBottomCell={() => (
        <AddDocumentsCell
          shoppingCartId={shoppingCartId}
          disabledAdding={disabledAdding}
          availableDocuments={availableDocuments}
          digitalSignatureState={digitalSignatureState}
        />
      )}
      displayIfEmptyCard
    />
  ) : null;
};

export default CustomizedDocumentsCard;
