/**
 * Defined scale steps for scale value for document scale
 */
export const SCALE_STEPS = [0.25, 0.5, 0.75, 1.0, 1.25, 1.5, 1.75, 2.0, 2.25, 2.5, 2.75];
/**
 * Min scale value
 */
export const SCALE_MIN = SCALE_STEPS[0];
/**
 * Max scale value
 */
export const SCALE_MAX = SCALE_STEPS[SCALE_STEPS.length - 1];
/**
 * Default scale value (for not corrected mode)
 */
export const SCALE_DEFAULT = 1.0;
const SCALE_STEP_SIZE_DIVISOR = 4;
/**
 * Scale step size
 */
export const SCALE_STEP_SIZE = 1 / SCALE_STEP_SIZE_DIVISOR;
/**
 * Height for DIN A4 in pixel (for corrected mode)
 */
export const PIXEL_HEIGHT_A4_SCALE_1 = 1285;
/**
 * Width for DIN A4 in pixel (for corrected mode)
 */
export const PIXEL_WIDTH_A4_SCALE_1 = 910;

/**
 * Convertes continues scale value to discret scale value by specified scale steps
 * @param {Number} scale - Free calculated width
 * @returns {Number} returns discret scale (scale step to inputted scale)
 */
export const convertScaleToScaleStep = (scale) => {
  const scaleMultiplied = scale * SCALE_STEP_SIZE_DIVISOR;
  const roundedScaleMultiplied = Math.round(scaleMultiplied);
  const newScale = roundedScaleMultiplied * SCALE_STEP_SIZE;
  if (newScale > SCALE_MAX) return SCALE_MAX;
  if (newScale < SCALE_MIN) return SCALE_MIN;
  return newScale;
};

/**
 * Limits scale on both side
 * @param {Number} scale - Free calculated scale
 * @returns {Number} returns scale, if it is between min and max scale, else returns min or max scale
 */
export const convertScale = (scale) => {
  if (scale > SCALE_MAX) return SCALE_MAX;
  if (scale < SCALE_MIN) return SCALE_MIN;
  return scale;
};

/**
 * Calculates current scale of document by inputted side size and fixed dinA4SideSizes
 * Need to input height or width (if both is inputted, then height has more priority)
 * @param {Object} inputParameter - Input parameter object
 * @param {Number} inputParameter.heigth - Current document height, calculates scale by current height and fixed dinA4Height value
 * @param {Number} inputParameter.width - Current document width, calculates scale by current width and fixed dinA4width value
 * @returns {Number} current document scale by inputted side size and fixed dinA4SideSize
 */
export const calculateCurrentScale = ({ width, height }) => {
  if (!width && !height) return 0;
  if (height) return height / PIXEL_HEIGHT_A4_SCALE_1;
  return width / PIXEL_WIDTH_A4_SCALE_1;
};
