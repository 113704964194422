import { useCachedQuery } from 'graphql/utils';
import { InputFieldType } from 'constants/inputField';
import { CalculationMode } from 'constants/item';
import { adminRoundPricesQuery, userCategoryListQuery } from 'graphql/queries';
import usePreferences from 'hooks/user/usePreferences';
import { find, intersectionBy, concat } from 'lodash';
import { useCallback, useMemo, useRef } from 'react';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { getDefaultValueFromInternalInputField } from 'pages/shoppingCartManagement/ShoppingCart/utils';

const useShoppingCartCategories = ({ shoppingCartId, fetchPolicy }) => {
  const {
    data: categoriesData,
    loading: categoriesLoading,
    error: categoriesError,
  } = useCachedQuery(userCategoryListQuery, {
    variables: {
      shoppingCartId,
      _id: shoppingCartId,
      isLibrary: false,
    },
    fetchPolicy,
    context: {
      batchingAllowed: false,
    },
  });
  const { preferences, loading: preferencesLoading } = usePreferences({ shoppingCartId, fetchPolicy });

  const {
    data: roundData,
    loading: roundLoading,
    error: roundError,
  } = useCachedQuery(adminRoundPricesQuery, {
    fetchPolicy,
  });
  const categories = useMemo(() => {
    if (categoriesLoading || roundLoading || preferencesLoading) return null;
    const roundPrices = grabFirstGQLDataResult(roundData);
    const filterCategories = categoriesData.categories
      .map((c) => {
        const items = c.items
          .filter((item) => item.active)
          .map((i) => {
            const inputFields =
              i.calculationMode === CalculationMode.ON_ACTUAL_COST
                ? []
                : intersectionBy(i.inputFields.filter(Boolean), i.recursiveFormulaInfo.usedIdsInFormula, (e) =>
                    typeof e === 'object' ? e._id : e,
                  ).map((inputField) =>
                    inputField.type === InputFieldType.INTERNAL
                      ? {
                          ...inputField,
                          value: getDefaultValueFromInternalInputField(inputField),
                        }
                      : inputField,
                  );
            const displayInputFields = inputFields.filter((inputField) => inputField.type !== InputFieldType.INTERNAL);
            return {
              ...i,
              roundPrice: find(roundPrices, { _id: i?.roundPriceId || preferences?.roundPriceId }),
              inputFields,
              internalInputFields: inputFields.filter((inputField) => inputField.type === InputFieldType.INTERNAL),
              displayInputFields,
              allAffectedInputFields: intersectionBy(
                concat(displayInputFields, c.inputFields)
                  .filter(Boolean)
                  .filter((inputField) => inputField.type !== InputFieldType.INTERNAL),
                i.recursiveFormulaInfo.usedIdsInFormula,
                (e) => (typeof e === 'object' ? e._id : e),
              ),
            };
          });
        return {
          ...c,
          allAffectedInputFields: [...c.inputFields, ...items.map((item) => item.allAffectedInputFields)]
            .flat()
            .filter((inputField) => inputField && inputField.type !== InputFieldType.INTERNAL),
          inputFieldDocuments: items
            .map((item) => item.allAffectedInputFields.filter((inputField) => inputField.type === InputFieldType.COMBO))
            .flat(),
          displayInputFields: c.inputFields,
          items,
        };
      })
      .filter((c) => c.items.length);
    return filterCategories;
  }, [categoriesData, categoriesLoading, preferences?.roundPriceId, preferencesLoading, roundData, roundLoading]);
  const allApolloCategoriesRef = useRef();
  allApolloCategoriesRef.current = categories;
  const getAllApolloCategories = useCallback(() => allApolloCategoriesRef.current, []);
  return {
    data: categories,
    getAllApolloCategories,
    loading: categoriesLoading || roundLoading || preferencesLoading,
    error: categoriesError || roundError,
  };
};

export default useShoppingCartCategories;
