import cn from 'classnames';

/**
 * Filters classNames for only strings and concat them to a string
 * @param  {...any} classNames - Classnames to concat to classnames string
 * @returns {String} a string with concated classnames or empty string
 */
const concatClassNames = (...classNames) => {
  const filteredClassNames = classNames.filter(Boolean).filter((string) => typeof string === 'string' && string !== '');
  if (!filteredClassNames || filteredClassNames.length < 1) return '';
  return cn(...filteredClassNames);
};

export default concatClassNames;
