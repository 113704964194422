import { useFormikContext } from 'formik';
import { castArray, intersectionBy, isEqual, pick } from 'lodash';
import { RichText } from '@JavaScriptSuperstars/kanzleipilot-shared';
import equal from 'fast-deep-equal/es6/react';
import { memo, useCallback, useMemo } from 'react';
import { FaEye, FaPen } from 'react-icons/fa';
import { Collapse, Tooltip } from 'antd';
import DocumentLayoutBlockContent from 'pages/admin/DocumentTemplateConfiguration/DocumentLayoutBlockContent';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useFormikField } from 'hooks/common/useFormikField';
import { formatDateTime } from 'utils/date';
import { useShoppingCartRevisionContext } from 'components/user/shoppingCart/context';
import { useDocumentTemplatesForShoppingCartContext } from 'contexts/DocumentTemplatesForShoppingCartContext';
import { useParams } from 'react-router';
import { CreationMode } from 'constants/shoppingCart';
import classes from './DocumentTypesSelect.module.less';
import Buttons from '../Buttons';
import './DocumentTypesSelect.less';

const { Panel } = Collapse;

const DocumentTypeRowButtons = ({
  _id,
  initializationConfigDate,
  buttonState: { errors, ...buttonState },
  currentSubmitTypeRef,
}) => {
  const rowButtonsSchema = useMemo(
    () => [
      {
        type: 'html',
        error: errors?.all,
        buttonProps: {
          disabledProps: {
            className: classes.documentTemplateDisabledButton,
          },
          className: classes.documentTemplateButton,
          ghost: true,
          icon: <FaEye />,
        },
        submitProps: { documentTemplate: { _id, initializationConfigDate } },
      },
    ],
    [_id, initializationConfigDate, errors?.all],
  );
  return (
    <div tabIndex={0} role="button" onClick={(e) => e.stopPropagation()} onKeyDown={(e) => e.stopPropagation()}>
      <Buttons
        currentSubmitTypeRef={currentSubmitTypeRef}
        buttonState={buttonState}
        onlyButtons
        schema={rowButtonsSchema}
      />
    </div>
  );
};
const DocumentTemplateIndicator = ({ block }) => {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  const isChanged = useMemo(() => {
    const formikBlock = values?.documentTemplateBlocks?.[block._id];
    try {
      if ('props' in formikBlock) return !isEqual(formikBlock.props, block.props);
    } catch {
      return false;
    }
    return false;
  }, [block._id, block.props, values?.documentTemplateBlocks]);
  return (
    <div>
      <Tooltip
        title={t(`user.ShoppingCart.DocumentTypes.${isChanged ? 'active' : 'inactive'}Tooltip`, {
          blockName: block.blockNumber,
        })}
      >
        <span className={cn(classes.indicator, classes.dot, isChanged ? classes.activeDot : '')} />
        <span>
          {
            // eslint-disable-next-line no-nested-ternary
            block.blockNumber
              ? isChanged
                ? t('user.ShoppingCart.DocumentTypes.customPersonalizedText', { blockName: block.blockNumber })
                : block.blockNumber
              : t(`admin.DocumentTemplateConfigurationPage.blocks.body`)
          }
        </span>
      </Tooltip>
    </div>
  );
};
const DocumentTemplateIndicatorMemo = memo(DocumentTemplateIndicator, equal);

const DocumentTemplateIndicators = ({ editingBlocks, visibleFields }) => {
  const hideInvisibleFields = (block) => {
    return {
      ...block,
      props: pick(block.props, visibleFields),
    };
  };
  return (
    <div className={cn(classes.indicators, 'info-text')}>
      {editingBlocks?.map((block) => (
        <DocumentTemplateIndicatorMemo key={block._id} block={hideInvisibleFields(block)} />
      ))}
    </div>
  );
};
const DocumentTemplateIndicatorsMemo = memo(DocumentTemplateIndicators, equal);

/**
 * Selected document template custom block settings component for shopping cart
 * @param {Object} inputParameters - Input parameters of the component
 * @param {Object[]} inputParameters.editingBlocks - Customizable blocks
 * @param {Object} inputParameters.documentTemplate - Document template
 * @param {String[]} inputParameters.visibleFields - Customizable block properties
 * @returns {JSX.Element} custom block properties for a selected document template
 * @component
 */
const DocumentLayoutBlockContentList = ({ editingBlocks, documentTemplate, visibleFields }) => {
  const { initializationConfigDate } = useShoppingCartRevisionContext();
  const { mode } = useParams();
  const { value, onChange } = useFormikField('documentTemplateBlocks');
  const onSubmit = useCallback(
    ({ blockId, values }) => {
      try {
        const clonedValue = { ...value };
        const newProps = pick(values, visibleFields);
        clonedValue[blockId] = { props: newProps };
        onChange(clonedValue);
      } catch (e) {
        console.log(e);
      }
    },
    [onChange, value, visibleFields],
  );
  return editingBlocks?.map((block) => {
    const blockCopy = { ...block, descriptor: { ...block.descriptor, form: [...block.descriptor.form] } };
    const formikBlockProps = value?.[blockCopy._id]?.props;
    if (typeof formikBlockProps === 'object' && !isEqual(pick(blockCopy.props, visibleFields), formikBlockProps)) {
      // eslint-disable-next-line no-param-reassign
      blockCopy.props = {
        ...blockCopy.props,
        ...formikBlockProps,
      };
    }
    if (mode === CreationMode.INDEPENDENT)
      blockCopy.descriptor.form = blockCopy.descriptor.form.map((field) => {
        if (field.type === 'multi-line-text') return { ...field, variables: { initializationConfigDate } };
        return field;
      });

    return (
      <DocumentLayoutBlockContent
        block={blockCopy}
        allowSave={false}
        key={blockCopy._id}
        getDocumentTemplate={() => documentTemplate}
        autosaveChanges
        visibleFields={visibleFields}
        onSubmit={onSubmit}
      />
    );
  });
};
const DocumentLayoutBlockContentListMemo = memo(DocumentLayoutBlockContentList, equal);

/**
 * Document template collapsable component for selected document template in a shopping cart for list and customization
 * @param {Object} inputParameters - Input parameters of the component
 * @param {Object} inputParameters.documentTemplate - Selected document template
 * @param {*} inputParameters.buttonState -
 * @param {React.Ref} inputParameters.currentSubmitTypeRef -
 * @returns {JSX.Element} collapsable for selected document template
 * @component
 */
export const DocumentTemplateCartMemo = memo(function DocumentTemplateCart({
  documentTemplate,
  buttonState,
  currentSubmitTypeRef,
}) {
  const visibleFields = useMemo(() => ['body'], []);
  const getDocumentTemplate = useCallback(() => documentTemplate, [documentTemplate]);
  const editingBlocks = documentTemplate.customizableBlocks.map((block) => ({
    ...block,
    blockNumber: RichText.richEditorTextToText(block.props.title),
  }));

  return (
    <Collapse
      expandIconPosition="left"
      ghost
      key={documentTemplate._id}
      {...(!editingBlocks?.length ? { activeKey: undefined } : null)} // disable collapse when no introductionBlocks
      // eslint-disable-next-line react/no-unstable-nested-components
      expandIcon={() => (
        <span className={classes.editDocument}>
          <FaPen size={15} />
        </span>
      )}
    >
      <Panel
        header={
          <div className={classes.headerPanel}>
            <div>
              {documentTemplate.name}{' '}
              {documentTemplate.initializationConfigDate
                ? formatDateTime(documentTemplate.initializationConfigDate)
                : null}
            </div>
            {editingBlocks?.length ? (
              <DocumentTemplateIndicatorsMemo editingBlocks={editingBlocks} visibleFields={visibleFields} />
            ) : null}
          </div>
        }
        className={cn(
          classes.documentTemplateCart,
          'document-template-panel',
          !editingBlocks?.length ? classes.onClickableHeaderPanel : '',
        )}
        key={documentTemplate._id}
        showArrow={!!editingBlocks?.length}
        extra={
          <div className="center">
            <DocumentTypeRowButtons
              _id={documentTemplate._id}
              initializationConfigDate={documentTemplate.initializationConfigDate}
              buttonState={buttonState}
              currentSubmitTypeRef={currentSubmitTypeRef}
              isIntroductionBlocks={editingBlocks}
            />
          </div>
        }
      >
        <DocumentLayoutBlockContentListMemo
          editingBlocks={editingBlocks}
          visibleFields={visibleFields}
          getDocumentTemplate={getDocumentTemplate}
        />
      </Panel>
    </Collapse>
  );
},
equal);

const DocumentTypesList = ({ templates, buttonState, currentSubmitTypeRef }) => {
  return (
    <div className={classes.documentTemplates}>
      {templates.map((template) => (
        <DocumentTemplateCartMemo
          key={template._id}
          buttonState={buttonState}
          currentSubmitTypeRef={currentSubmitTypeRef}
          documentTemplate={template}
        />
      ))}
    </div>
  );
};

/**
 * List of selected document templates component with preview button and customizable block settings (Needs a document template for shopping cart context)
 * @param {Object} inputParameters - Input parameters of the component
 * @param {*} inputParameters.buttonState -
 * @param {React.Ref} inputParameters.currentSubmitTypeRef -
 * @returns {JSX.Element} list of selected document templates collapsables
 * @component
 */
const DocumentTypesContainerMemo = memo(function DocumentTypesContainer({ buttonState, currentSubmitTypeRef }) {
  const { values } = useFormikContext();
  const { documentTemplates: documentTemplatesJSON } = values || {};
  const { allDocumentTemplateOptions } = useDocumentTemplatesForShoppingCartContext();
  const selectedOptions = castArray(documentTemplatesJSON).filter(Boolean);
  const templates = useMemo(() => {
    const intersected = intersectionBy(allDocumentTemplateOptions, selectedOptions, (e) =>
      typeof e === 'object' ? e.value : e,
    );
    return intersected;
  }, [allDocumentTemplateOptions, selectedOptions]);

  if (!templates.length) return null;

  return (
    <DocumentTypesList buttonState={buttonState} currentSubmitTypeRef={currentSubmitTypeRef} templates={templates} />
  );
}, equal);

export default DocumentTypesContainerMemo;
