import CartStateSelect from 'components/common/CartStateSelect';

const PostSuccessfulFinishStateSelect = ({ t }) => {
  return (
    <CartStateSelect
      fieldName="postSuccessfulFinishState"
      label={t('admin.Setting.DigitalSignaturePreferences.automateSignatureProcess.postSuccessfulFinishState.label')}
      placeholder={t(
        'admin.Setting.DigitalSignaturePreferences.automateSignatureProcess.postSuccessfulFinishState.placeholder',
      )}
      tooltip={t(
        'admin.Setting.DigitalSignaturePreferences.automateSignatureProcess.postSuccessfulFinishState.tooltip',
      )}
    />
  );
};

export default PostSuccessfulFinishStateSelect;
