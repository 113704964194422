import { gql } from '@apollo/client';
import { currentUserFragment } from 'graphql/fragments';

const sendInvitationMutation = gql`
  mutation sendInvitationMutation($profile: UserProfileInput, $role: Role, $email: String) {
    sendInvitation(profile: $profile, role: $role, email: $email) {
      ...currentUserFragment
    }
  }
  ${currentUserFragment}
`;

export default sendInvitationMutation;
