import SigningInfoPagesLayout from 'components/layouts/SigningInfoPagesLayout';
import useResetSigningContext from 'hooks/signing/useResetSigningContext';
import useSigningContext from 'hooks/signing/useSigningContext';
import { useEffect, useState } from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import routePaths from 'router/route-paths';
import classes from './InfoPages.module.less';

/**
 * SuccessfullySigned component is the page component for the successfully signed page
 * @returns {JSX.Element} successfully signed page
 * @component
 */
const SuccessfullySigned = () => {
  const { t: translation } = useTranslation();
  const { loading, signingContext } = useSigningContext();
  const navigate = useNavigate();
  const { resetSigningContext, resetSigningTheme } = useResetSigningContext();
  const [firstCheck, setFirstCheck] = useState(true);

  useEffect(() => {
    if (!loading && signingContext) setFirstCheck(false);
    if (!loading && !signingContext && firstCheck) navigate(routePaths.signingUnvalid);
  }, [loading, signingContext, navigate, firstCheck]);

  setTimeout(() => resetSigningContext(), 2000);
  setTimeout(() => resetSigningTheme(), 5000);

  const isTenantSignee = signingContext?.typeOfSignee === 'TENANT';
  const message = isTenantSignee
    ? translation('signing.infoPages.successfullySigned.heading.tenant')
    : translation('signing.infoPages.successfullySigned.heading.client');

  return (
    <SigningInfoPagesLayout theme={signingContext?.theme} contentAreaClassName={classes.contentArea}>
      <div className={classes.headingWrapper}>
        <div className={classes.additionalIconWrapper}>
          <CheckCircleFilled className={classes.additionalIcon} />
        </div>
        <h1 className={classes.infoPageHeading}>{message}</h1>
      </div>
    </SigningInfoPagesLayout>
  );
};

export default SuccessfullySigned;
