import apollo from 'graphql/apollo';
import { updateTenantSettingsMutation } from 'graphql/mutations';
import { adminTenantSettingsQuery, userBCCQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';

const updateTenantSettings = async ({
  settings: {
    emailPreferences,
    hideCountryInAddresses,
    shoppingCartPreferences,
    monthlyPaymentPreferences,
    digitalSignaturePreferences,
  },
}) =>
  apollo.mutate({
    mutation: updateTenantSettingsMutation,
    variables: {
      settings: {
        digitalSignaturePreferences,
        emailPreferences,
        hideCountryInAddresses,
        shoppingCartPreferences,
        monthlyPaymentPreferences,
      },
    },
    refetchQueries: [{ query: userBCCQuery }],
    update: (cache, { data }) => {
      const settings = grabFirstGQLDataResult(data);
      cache.writeQuery({
        query: adminTenantSettingsQuery,
        data: {
          tenantSettings: settings,
        },
      });
    },
  });
export default updateTenantSettings;
