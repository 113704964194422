import React, { useState } from 'react';
import { Radio, Space } from 'antd';
import { useSubscription, gql } from '@apollo/client';
import { CPUSvgs } from './CPUSvg';

const cpuUsage = gql`
  subscription cpuUsage {
    cpuUsage
  }
`;
const memUsage = gql`
  subscription memUsage {
    memUsage
  }
`;
const cpuGraph = gql`
  subscription cpuGraph {
    cpuGraph
  }
`;

export const ProfileBackendCPUPage = () => {
  const [orderBy, setOrderBy] = useState('cpu');
  const [state, setState] = useState();
  const [stateGraph, setStateGraph] = useState();

  useSubscription(orderBy === 'cpu' ? cpuUsage : memUsage, {
    onSubscriptionData: ({ subscriptionData }) => {
      setState(subscriptionData.data.memUsage || subscriptionData.data.cpuUsage);
    },
  });
  useSubscription(cpuGraph, {
    onSubscriptionData: ({ subscriptionData }) => {
      setStateGraph(JSON.parse(subscriptionData.data.cpuGraph));
    },
  });
  return (
    <div className="container-fluid">
      <CPUSvgs data={stateGraph} />
      Order by{' '}
      <Radio.Group onChange={(e) => setOrderBy(e.target.value)} value={orderBy}>
        <Space direction="horizontal">
          <Radio value="cpu">%CPU</Radio>
          <Radio value="mem">MEM</Radio>
        </Space>
      </Radio.Group>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <pre>{state}</pre>
      </div>
    </div>
  );
};

export default ProfileBackendCPUPage;
