import { gql } from '@apollo/client';
import userVariableFragment from './userVariableFragment';

const userVariableListFragment = gql`
  fragment userVariableListFragment on Variables {
    _id
    _apolloCacheKey
    variables {
      ...userVariableFragment
    }
  }
  ${userVariableFragment}
`;

export default userVariableListFragment;
