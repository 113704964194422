import { useQuery, useMutation } from '@apollo/client';
import { Form, message } from 'antd';
import I18nFormik from 'components/common/I18nFormik';
import isSepaMandateStepDoneQuery from 'graphql/queries/signing/isSepaMandateStepDoneQuery';
import useSigningContext from 'hooks/signing/useSigningContext';
import useSigningGraphqlContext from 'hooks/signing/useSigningGraphqlContext';
import useSigningShoppingCart from 'hooks/signing/useSigningShoppingCart';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { validateBic, validateIban } from 'utils/banking';
import completeSepaMandateStep from 'graphql/mutations/signing/completeSepaMandateStep';
import CustomWarningAlert from './CustomWarningAlert';
import SepaMandateForm from './SepaStep/SepaMandateForm';
/**
 * SepaStep component is the step component when sepa mandate needs to be configured
 * @param {Object} inputParameters - Input parameters of the component
 * @param {React.Ref} inputParameters.submitReference - Reference, which gets the onContinue function
 * @returns {JSX.Element} step to configure sepa mandate
 */
const SepaStep = ({ submitReference }) => {
  const { signingContext } = useSigningContext();
  const { shoppingCart } = useSigningShoppingCart();
  const {
    bic: defaultBic,
    iban: defaultIban,
    accountOwnerName: defaultAccountOwnerName,
    hasSepaMandate: defaultHasSepaMandate,
    bankData,
  } = shoppingCart?.signatureData?.sepa || {};
  const { t: translation } = useTranslation('translation', { keyPrefix: 'signing.sepaMandateStep' });
  const signingGraphlQlContext = useSigningGraphqlContext();
  const { data: isSepaDoneData } = useQuery(isSepaMandateStepDoneQuery, {
    context: signingGraphlQlContext,
    pollInterval: 1000 * 60 * 10, // 10 minutes
    fetchPolicy: 'network-only',
  });
  const stepDone = isSepaDoneData?.isSepaMandateStepDone?.isDone;
  const innerRef = useRef();

  const [submitSepa] = useMutation(completeSepaMandateStep, {
    context: signingGraphlQlContext,
  });

  // const stepWasDone = shoppingCart?.signatureData.is;
  const isTenant = signingContext?.typeOfSignee?.toLowerCase() === 'tenant';
  const isDisabled = isTenant || stepDone;

  const defaultValues = {
    bic: '',
    iban: '',
    accountOwnerName: '',
    sepaDecision: defaultIban ? 'yes' : undefined,
    bankData: {
      bankName: bankData?.bankName || '',
      bankCity: bankData?.bankCity || '',
    },
  };

  if (isTenant && !stepDone) {
    defaultValues.sepaDecision = undefined;
  }

  if (stepDone && !defaultHasSepaMandate) {
    defaultValues.sepaDecision = 'no';
  }

  // eslint-disable-next-line no-param-reassign
  submitReference.current = {
    showContinueLoading: true,
    onContinue: async () => {
      if (innerRef?.current?.submitForm) {
        await innerRef.current.submitForm();
        return innerRef?.current?.isValid && { stepState: innerRef?.current?.values };
      }
      return false;
    },
  };

  const bankAccount = {
    bic: defaultBic,
    iban: defaultIban,
    accountOwnerName: defaultAccountOwnerName,
  };

  const onSubmit = async (values, formik) => {
    const { bic, iban, accountOwnerName } = values;
    const errors = {};

    if (stepDone || isTenant) {
      return;
    }

    if (values.sepaDecision === 'yes') {
      if (!bic && (iban || accountOwnerName || !defaultBic)) {
        errors.bic = translation('bic.errors.emptyInput');
      }
      if (bic && !validateBic(bic)) {
        errors.bic = translation('bic.errors.wrongFormat');
      }
      if (!iban && (bic || accountOwnerName || !defaultIban)) {
        errors.iban = translation('iban.errors.emptyInput');
      }
      if (iban && !validateIban(iban)) {
        errors.iban = translation('iban.errors.wrongFormat');
      }
      if (!accountOwnerName && (iban || bic || !defaultAccountOwnerName)) {
        errors.accountOwnerName = translation('accountOwnerName.errors.emptyInput');
      }
      if (accountOwnerName && accountOwnerName?.split(' ').length < 2) {
        errors.accountOwnerName = translation('accountOwnerName.errors.wrongFormat');
      }
    }

    if (values.sepaDecision === 'no' && shoppingCart.sepaMandateSignatureSettings?.sepaMode === 'mandatory') {
      errors.sepaDecision = translation('grantSepa.errors.mandatory');
    }

    formik.setErrors(errors);
    if (Object.keys(errors).length > 0) {
      return;
    }

    try {
      await submitSepa({
        variables: {
          hasGranted: values.sepaDecision === 'yes' || false,
          iban: values.iban || defaultIban,
          bic: values.bic || defaultBic,
          accountOwnerName: values.accountOwnerName || defaultAccountOwnerName,
          bankData: values.bankData,
        },
      });
      return;
    } catch (e) {
      message.error(e.message, 5);
      formik.setErrors({ sepaDecision: e.message });
    }
  };

  return (
    <>
      {stepDone ? (
        <CustomWarningAlert key="decision-alert" message="" description={translation('stepAlreadyDone')} />
      ) : null}
      {!stepDone && isTenant ? (
        <CustomWarningAlert key="decision-alert" message="" description={translation('stepNotDoneYet')} />
      ) : null}
      <CustomWarningAlert message="" description={shoppingCart?.sepaMandateSignatureSettings?.sepaIntroductionText} />

      <div style={{ marginTop: '20px' }}>
        {shoppingCart && (
          <I18nFormik initialValues={defaultValues} onSubmit={onSubmit} innerRef={innerRef} submitForm>
            <Form layout="vertical">
              <SepaMandateForm
                bankAccount={bankAccount}
                canDeny={!defaultIban}
                disabled={isDisabled}
                t={translation}
                tenantName={shoppingCart?.sepaMandateSignatureSettings?.tenantCompanyName}
                agreementText={shoppingCart?.sepaMandateSignatureSettings?.mandateText}
              />
            </Form>
          </I18nFormik>
        )}
      </div>
    </>
  );
};

export default SepaStep;
