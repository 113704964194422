import { gql } from '@apollo/client';

const userViewShoppingCartStaticItemFragment = gql`
  fragment userViewShoppingCartStaticItemFragment on StaticItem {
    _id
    _apolloCacheKey
    nameRaw
    valueRaw
  }
`;

export default userViewShoppingCartStaticItemFragment;
