import apollo from 'graphql/apollo';
import { completeOnboardingMutation } from 'graphql/mutations';
import { adminEmailPreferencesQuery, isTenantOnboardingCompletedQuery } from 'graphql/queries';

const completeOnboarding = async (modifier) =>
  apollo.mutate({
    mutation: completeOnboardingMutation,
    variables: { modifier },
    refetchQueries: [{ query: adminEmailPreferencesQuery }],
    update: (cache) => {
      cache.writeQuery({
        query: isTenantOnboardingCompletedQuery,
        data: { isTenantOnboardingCompleted: true },
      });
    },
  });
export default completeOnboarding;
