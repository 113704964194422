import { useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { calculateDocumentHeight, isWidthControlledMode } from '../utils/PdfViewerUtils';
import { useCurrentSizeOfElement } from '../hooks';
import { Pages, Document } from '../Document';
import FullscreenModeHeader from './FullscreenModeHeader';
import PinchZoomPane, { RESPONSE_MODE } from '../PinchZoomPane';
import classes from './FullViewPdfViewer.module.less';

/**
 * FullscreenPdfViewer component for the pdf viewer to show pdf documents with full width and height of browser window
 * @param {Object} inputParameters - Input parameters for component
 * @param {Uint8Array} inputParameters.data - Binary data of document as Uint8Array
 * @param {Function} inputParameters.onClose - Function, which is called on close fullscreen mode press
 * @param {Object} inputParameters.documentInfo - Informations about the document
 * @param {String} inputParameters.documentInfo.documentTemplateName - Name of document template
 * @returns {JSX.Element} fullscreen mode for pdf drawer to show document on full width and height of browser window with navigation and zoom function
 * @component
 */
const FullscreenPdfViewer = ({ data, onClose, documentInfo }) => {
  return createPortal(
    <InnerFullscreenPdfViewer data={data} onClose={onClose} documentInfo={documentInfo} />,
    document.getElementById('pdfViewer'),
  );
};

/**
 * InnerFullscreenPdfViewer component for fullscreen mode, which handles the show of header and content of the fullscreen mode
 * @param {Object} inputParameters - Input parameters for component
 * @param {Uint8Array} inputParameters.data - Binary data of document as Uint8Array
 * @param {Function} inputParameters.onClose - Function, which is called on close fullscreen mode press
 * @param {Object} inputParameters.documentInfo - Informations about the document
 * @param {String} inputParameters.documentInfo.documentTemplateName - Name of document template
 * @returns {JSX.Element} inner component to handle header and content of fullscreen mode
 * @component
 */
const InnerFullscreenPdfViewer = ({ onClose, data, documentInfo }) => {
  const [numPages, setNumPages] = useState(null);
  const [pdfViewPaneRef, setPdfViewPaneRef] = useState();
  const documentControlRef = useRef();
  const { height: currentAvailableHeightForDoc } = useCurrentSizeOfElement(pdfViewPaneRef);

  const onPinchOut = (scale, pinchEventCoord) => documentControlRef.current.zoomInPinch(scale, pinchEventCoord);
  const onPinchIn = (scale, pinchEventCoord) => documentControlRef.current.zoomOutPinch(scale, pinchEventCoord);
  const scrollBarWidth = (pdfViewPaneRef?.current?.offsetWidth || 0) - (pdfViewPaneRef?.current?.clientWidth || 0);

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages);
  };

  const widthControlled = isWidthControlledMode();

  const onItemClick = ({ pageNumber }) => {
    if (documentControlRef && documentControlRef.current) documentControlRef.current.navigateToPage(pageNumber);
  };

  const onUpdateScrollLeft = ({ scaleDifference, pinchEventXCoord }) => {
    if (!pdfViewPaneRef) return;
    if (scaleDifference && pinchEventXCoord) {
      const pinchXRelatedToDoc = pdfViewPaneRef.scrollLeft + pinchEventXCoord;
      const newScrollLeft = scaleDifference * pinchXRelatedToDoc - pinchEventXCoord;
      pdfViewPaneRef.scrollLeft = newScrollLeft < 0 ? 0 : newScrollLeft;
      return;
    }
    if (scaleDifference) {
      pdfViewPaneRef.scrollLeft *= scaleDifference;
    }
  };

  return (
    <div className={classes.Background}>
      <FullscreenModeHeader documentInfo={documentInfo} onClose={onClose} />
      <PinchZoomPane
        className={classes.ScrollPane}
        onPinchIn={onPinchIn}
        onPinchOut={onPinchOut}
        responseMode={RESPONSE_MODE.DIRECT}
      >
        <div className={classes.Document} ref={(ref) => setPdfViewPaneRef(ref)}>
          <Document
            data={data}
            onDocumentLoadSuccess={onDocumentLoadSuccess}
            onItemClick={onItemClick}
            documentInfo={documentInfo}
          >
            {numPages && pdfViewPaneRef ? (
              <Pages
                numberOfPages={numPages}
                heightForPage={
                  (widthControlled ? calculateDocumentHeight(window.innerWidth) : currentAvailableHeightForDoc) -
                  scrollBarWidth
                }
                heightForList={currentAvailableHeightForDoc - scrollBarWidth}
                pdfViewPaneRef={{ current: pdfViewPaneRef }}
                documentControlRef={documentControlRef}
                listClassName={classes.List}
                scaleButtonBarClassName={classes.StickyBar}
                onUpdateScrollLeft={onUpdateScrollLeft}
              />
            ) : null}
          </Document>
        </div>
      </PinchZoomPane>
    </div>
  );
};

export default FullscreenPdfViewer;
