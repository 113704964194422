import { useMemo } from 'react';
import { useCachedQuery } from 'graphql/utils';
import { getWarningsQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';

const useGetWarnings = (types) => {
  const { data, ...rest } = useCachedQuery(getWarningsQuery, {
    variables: { types },
    fetchPolicy: 'cache-and-network',
  });
  return useMemo(() => {
    const warnings = grabFirstGQLDataResult(data) || [];
    return {
      data: warnings,
      ...rest,
    };
  }, [data, rest]);
};

export default useGetWarnings;
