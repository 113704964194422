import { Empty, Typography } from 'antd';
import { FaRegFile } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import useSigningShoppingCart from 'hooks/signing/useSigningShoppingCart';
import Card from '../Card';
import DocumentEntry from './DocumentEntry';
import SigningContextErrorHandler from '../SigningContextErrorHandler';

const { Title } = Typography;

const addDocumentEntry = (document, shoppingCartId, documents) => (
  <DocumentEntry
    document={document}
    shoppingCartId={shoppingCartId}
    key={document.templateName}
    documents={documents}
  />
);

const DocumentsList = () => {
  const { shoppingCart, loading, error } = useSigningShoppingCart();
  const { t: translation } = useTranslation();

  const { _id: shoppingCartId } = shoppingCart || {};

  const extendedDocuments = shoppingCart?.pdfs?.map((document) => ({ ...document, shoppingCartId }));

  const documentEntries =
    extendedDocuments?.map((document) => addDocumentEntry(document, shoppingCartId, extendedDocuments)) || null;

  return (
    <SigningContextErrorHandler error={error}>
      <Title level={5}>{translation('signing.viewDocumentsAndSignStep.documentsCard.stepHeadline')}</Title>
      <Card title={translation('signing.viewDocumentsAndSignStep.documentsCard.title')} icon={<FaRegFile size="15" />}>
        {loading ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> : documentEntries}
      </Card>
    </SigningContextErrorHandler>
  );
};

export default DocumentsList;
