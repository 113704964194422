import apollo from 'graphql/apollo';
import { createPersonMutation } from 'graphql/mutations';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { adminPersonsInTenantQuery } from 'graphql/queries';

const createPerson = async ({ name, position }) =>
  apollo.mutate({
    mutation: createPersonMutation,
    variables: { person: { name, position } },
    update: (cache, { data }) => {
      const result = grabFirstGQLDataResult(data);
      const cachedData = cache.readQuery({ query: adminPersonsInTenantQuery });
      cache.writeQuery({
        query: adminPersonsInTenantQuery,
        data: {
          persons: [...cachedData.persons, result],
        },
      });
    },
  });
export default createPerson;
