import { gql } from '@apollo/client';

const superAdminDigitalSignatureMetricsQuery = gql`
  query superAdminDigitalSignatureMetricsQuery {
    getDigitalSignatureMetrics {
      openedDigitalSignatureProcessesThisMonth
      openedDigitalSignatureProcesses
      openedSigningProcessesThisMonth
      openedSigningProcesses
      finishedDigitalSignatureProcessesThisMonth
      finishedDigitalSignatureProcesses
      finishedSigningProcessesThisMonth
      finishedSigningProcesses
      numberOfTenantWithEnabledDigitalSignature
      currentlyOpenDigitalSignatureProcesses
      currentlyOpenSigningProcesses
      numberOfTotalTenants
    }
  }
`;

export default superAdminDigitalSignatureMetricsQuery;
