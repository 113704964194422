// https://github.com/formium/formik/issues/284
import equal from 'fast-deep-equal/es6/react';
import { Slider as SliderAntd } from 'antd';
import { useFormikContext } from 'formik';
import { useFunctionToRefCB } from 'memo';
import { memo } from 'react';
import './InputNumber.less';
import useNumberFormat, { useDecimalSeparator } from 'hooks/common/useNumberFormat';
import { FieldFormikWithDebounce } from './FieldFormikWithDebounce';

const I = memo(SliderAntd, equal);
const II = memo(FieldFormikWithDebounce, equal);

const Slider = ({ className, debounceTime, ...props }) => {
  const { setFieldValueAndTouched } = useFormikContext();

  const { decimalSeparator, thousandSeparator } = useDecimalSeparator();
  const { formatter } = useNumberFormat({ decimalSeparator, thousandSeparator });
  return (
    <II
      {...props}
      tipFormatter={formatter}
      onChange={useFunctionToRefCB((e) => setFieldValueAndTouched(props.name, e))}
      debounceTime={debounceTime}
      component={I}
    />
  );
};

const SliderMemo = memo(Slider, equal);
export default SliderMemo;
