import { Divider } from 'antd';
import classnames from 'classnames';
import classes from './BorderedBox.module.less';

export const BorderedBox = ({ children, label, type = 'transparent', className, margin = 0, marginTop }) => {
  return (
    <div style={{ margin, marginTop }}>
      <div className={classnames(classes.container, !!type && `${type}-color-bordered-box`, className)}>
        <Divider className={classes.title} orientation="left" plain>
          {label}
        </Divider>
        <div className={classes.containerChild}>{children}</div>
      </div>
    </div>
  );
};

export default BorderedBox;
