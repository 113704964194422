import { SIGNING_CSS_PATH_KEY } from 'hooks/common/useLoadTheme';
import { SIGNING_ACCESS_TOKEN_KEY } from './useLoginSignee';
import { SIGNING_LOGO_URL_KEY } from './useLogo';
import { SIGNING_CURRENT_STATE } from './useUpdateCurrentSigningState';

const useResetSigningContext = () => {
  const resetSigningContext = () => {
    window.localStorage.removeItem(SIGNING_ACCESS_TOKEN_KEY);
    window.localStorage.removeItem(SIGNING_CURRENT_STATE);
  };
  const resetSigningTheme = () => {
    window.localStorage.removeItem(SIGNING_LOGO_URL_KEY);
    window.localStorage.removeItem(SIGNING_CSS_PATH_KEY);
  };
  return { resetSigningContext, resetSigningTheme };
};

export default useResetSigningContext;
