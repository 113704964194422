import { Card } from 'antd';
import FormItem from 'components/common/FormItem';
import { AlertFromFormik, setGraphqlErrors } from 'components/common/ErrorComponent';
import { rolesCanBeSet } from 'constants/roles';
import equal from 'fast-deep-equal/es6/react';
import { Form, Input, Select, SubmitButton } from 'formik-antd';
import i18n from 'i18n';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FormikImageField from 'components/common/FormikImage/FormikImage';
import toast from 'utils/toast';
import * as Yup from 'yup';
import { sendInvitation } from 'graphql/methods';
import I18nFormik from 'components/common/I18nFormik';
import classes from './ManageUsers.module.less';

const { Option } = Select;
export const userInvitationSchema = () =>
  Yup.object().shape({
    firstName: Yup.string().required().label(i18n.t('admin.ManageUsers.userInvitation.inputs.firstName.label')),
    lastName: Yup.string().required().label(i18n.t('admin.ManageUsers.userInvitation.inputs.lastName.label')),
    email: Yup.string().email().required().label(i18n.t('admin.ManageUsers.userInvitation.inputs.email.label')),
    role: Yup.string().required().label(i18n.t('admin.ManageUsers.userInvitation.inputs.role.label')),
  });

export const UserForm = ({ showUploadSignature }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={classes.group}>
        <FormItem name="firstName" label={t('admin.ManageUsers.userInvitation.inputs.firstName.label')}>
          <Input name="firstName" />
        </FormItem>
        <FormItem name="lastName" label={t('admin.ManageUsers.userInvitation.inputs.lastName.label')}>
          <Input name="lastName" />
        </FormItem>
      </div>
      <FormItem name="position" label={t('admin.ManageUsers.userInvitation.inputs.position.label')}>
        <Input name="position" />
      </FormItem>
      <FormItem name="email" label={t('admin.ManageUsers.userInvitation.inputs.email.label')}>
        <Input name="email" />
      </FormItem>
      <FormItem
        name="role"
        label={t('admin.ManageUsers.userInvitation.inputs.role.label')}
        tooltip={t('admin.ManageUsers.userInvitation.inputs.role.tooltip')}
      >
        <Select name="role">
          {Object.keys(rolesCanBeSet).map((key) => (
            <Option value={key} key={key}>
              {t(`common.roles.${key}`)}
            </Option>
          ))}
        </Select>
      </FormItem>
      {showUploadSignature && (
        <FormItem
          name="customSignatureImage"
          label={t('admin.ManageUsers.userInvitation.inputs.customSignatureImage.label')}
        >
          <FormikImageField
            name="customSignatureImage"
            resetButtonLabel={t('admin.ManageUsers.userInvitation.inputs.customSignatureImage.resetButton')}
            placeholderText={t('admin.ManageUsers.userInvitation.inputs.customSignatureImage.emptyPlaceholder')}
            uploadLabel={t('admin.ManageUsers.userInvitation.inputs.customSignatureImage.uploadButton')}
            showEmptyIfNoSrc
            showResetButton
          />
        </FormItem>
      )}
    </>
  );
};
const UserInvitationForm = () => {
  const { t } = useTranslation();
  const initialValues = useMemo(
    () => ({
      firstName: '',
      lastName: '',
      position: '',
      email: '',
      role: '',
    }),
    [],
  );
  return (
    <I18nFormik
      initialValues={initialValues}
      validationSchema={userInvitationSchema}
      onSubmit={async (values, formik) => {
        const { firstName, lastName, position, email, role } = values;
        try {
          formik.setSubmitting(true);
          await sendInvitation({
            email,
            role,
            profile: {
              firstName,
              lastName,
              position,
            },
          });
          toast.success(t('common.toast.success.sendInvitation'));
          formik.resetForm();
        } catch (error) {
          const graphQlErrors = setGraphqlErrors({
            error,
            errorResolver: ({ message }) => {
              if (message.includes('email')) return ['email', message];
              return undefined;
            },
          });
          formik.setErrors(graphQlErrors);
        } finally {
          formik.setSubmitting(false);
        }
      }}
    >
      <Form layout="vertical">
        <AlertFromFormik />
        <UserForm />
        <SubmitButton> {t('admin.ManageUsers.buttons.sendInvitation')}</SubmitButton>
      </Form>
    </I18nFormik>
  );
};
function UserInvitation() {
  const { t } = useTranslation();
  return (
    <Card title={t('admin.ManageUsers.userInvitation.title')}>
      <UserInvitationForm />
    </Card>
  );
}

export default memo(UserInvitation, equal);
