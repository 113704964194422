import { useEffect, useState } from 'react';
import kanzleiPilotLogoSrc from 'assets/images/KanzleiPilotLogo.svg';

export const SIGNING_LOGO_URL_KEY = 'logo-url';

const useLogo = (logoURL) => {
  const savedLogoUrl = window.localStorage.getItem(SIGNING_LOGO_URL_KEY);
  const [requestState, setRequestState] = useState({
    loading: true,
    logoURL: savedLogoUrl || kanzleiPilotLogoSrc,
    error: null,
  });
  useEffect(() => {
    if (!logoURL) {
      setRequestState((currentState) => ({ ...currentState, loading: false }));
      return;
    }
    fetch(logoURL)
      .then((res) => {
        res.json().then((data) => {
          window.localStorage.setItem(SIGNING_LOGO_URL_KEY, data.url);
          setRequestState({
            loading: false,
            logoURL: data.url,
          });
        });
      })
      .catch((error) => {
        setRequestState((currentState) => ({
          ...currentState,
          loading: false,
          error,
        }));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoURL]);
  return requestState;
};

export default useLogo;
