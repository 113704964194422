import { gql } from '@apollo/client';
import { userCategoryListFragment } from 'graphql/fragments';

const userCategoryListQuery = gql`
  query userCategoryListQuery($shoppingCartId: ID) {
    categories(shoppingCartId: $shoppingCartId) {
      ...userCategoryListFragment
    }
  }
  ${userCategoryListFragment}
`;

export default userCategoryListQuery;
