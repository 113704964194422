import { Layout, Col, Row, Steps, Button, Space } from 'antd';
import cn from 'classnames';
import TenantLogo from 'components/common/TenantLogo';
import useWindowSize from 'hooks/common/useWindowSize';
import useModifyI18N from 'hooks/signing/useModifyI18N';
import { useTranslation } from 'react-i18next';
import classes from './SigningProcessLayout.module.less';

const { Content } = Layout;
const { Step } = Steps;

/**
 * SigningProcessLayout component is the layout component for the signing process page
 * @param {Object} inputParameters - Input parameters of the function
 * @param {JSX.Element[]} inputParameters.children - Content, which should be shown in the content area
 * @param {Object} inputParameters.theme - Theme object
 * @param {String} inputParameters.theme.locale - Language, which should be used
 * @param {String} inputParameters.theme.logoURL - Url for the tenant logo
 * @param {String} inputParameters.contentAreaClassName - Custom classname for the content area
 * @param {String[]} inputParameters.steps - Collection of step keys
 * @param {Number} inputParameters.currentStep - Index of current step
 * @param {Function} inputParameters.onContinueButton - Function, called on press of the continue button (if null or undefined, then no continue button is shown)
 * @param {Boolean} inputParameters.onContinueLoading - Should the continue button show a loading spin
 * @param {Function} inputParameters.onBackButton - Function, called on press of the back button (if null or undefined, then no back button is shown)
 * @returns {JSX.Element} layout for the signing processs
 * @component
 */
const SigningProcessLayout = ({
  children,
  theme,
  contentAreaClassName,
  steps,
  currentStep,
  onContinueButton,
  onContinueLoading,
  onBackButton,
}) => {
  useModifyI18N(theme?.locale || 'de');
  const { t: translation } = useTranslation();
  const { width } = useWindowSize();
  const stepsComponents =
    steps &&
    steps.map((step) => {
      return <Step title={translation(`signing.common.steps.${step}`)} key={step} />;
    });

  const mobileView = width < 1140;

  /**
   * Scrolls to the top of the document
   */
  const scrollToTop = () => {
    const rootElement = document.getElementById('root');
    if (rootElement) rootElement.scrollTop = 0;
  };

  /**
   * Generates on click handlers for the continue and back button, which scrolls page to top and calls the specified function
   * @param {Function} buttonSpecificFunction - Function, which should be called
   * @returns {Function} on click function for the steps continue and back button
   */
  const generateOnButtonPress = (buttonSpecificFunction) => () => {
    scrollToTop();
    if (buttonSpecificFunction) buttonSpecificFunction();
  };

  return (
    <Layout className={classes.layout}>
      <Row align="middle" justify="center" className={cn(classes.headerRow, mobileView ? classes.headerRowMobile : '')}>
        <TenantLogo getLogoUrlUrl={theme?.logoURL} className={classes.logo} />
      </Row>
      <Row className={!mobileView && classes.contentRow} style={{ flexWrap: mobileView ? 'wrap' : 'nowrap' }}>
        <Col className={mobileView ? classes.stepsColumnMobile : classes.stepsColumn}>
          <Steps
            direction={mobileView ? 'horizontal' : 'vertical'}
            size={mobileView ? 'small' : 'default'}
            current={currentStep}
          >
            {stepsComponents}
          </Steps>
        </Col>
        <Col className={cn(classes.contentColumn, mobileView ? classes.contentColumnMobile : '')} flex="auto">
          <Content className={classes.content}>
            <div className={cn(classes.contentArea, contentAreaClassName)}>{children}</div>
          </Content>
          <div className={classes.buttonBar}>
            <Space>
              {onBackButton && (
                <Button onClick={generateOnButtonPress(onBackButton)}>
                  {translation('signing.common.backButton')}
                </Button>
              )}
              {onContinueButton && (
                <Button type="primary" onClick={generateOnButtonPress(onContinueButton)} loading={onContinueLoading}>
                  {translation('signing.common.continueButton')}
                </Button>
              )}
            </Space>
          </div>
        </Col>
      </Row>
    </Layout>
  );
};

export default SigningProcessLayout;
