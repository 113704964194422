import { gql } from '@apollo/client';
import { userCompanyFragment } from 'graphql/fragments';

const userCompanyListQuery = gql`
  query userCompanyListQuery {
    companies {
      ...userCompanyFragment
    }
  }
  ${userCompanyFragment}
`;

export default userCompanyListQuery;
