import { gql } from '@apollo/client';
import { adminItemFragment } from 'graphql/fragments';

const copyItemMutation = gql`
  mutation copyItemMutation($itemId: ID!, $categoryId: ID!, $name: String!) {
    copyCategoryItem(itemId: $itemId, categoryId: $categoryId, name: $name) {
      ...adminItemFragment
    }
  }
  ${adminItemFragment}
`;

export default copyItemMutation;
