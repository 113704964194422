import i18n from 'i18n';
import { IoArrowDownCircle } from 'react-icons/io5';
import { stateLoadingPdf } from 'pages/shoppingCartManagement/ShoppingCartSuccessful/hooks';

/**
 * Formats the state of a document download for display
 * @param {Object} inputParameters - Input parameters of the component
 * @param {string} inputParameters.stateLoading - The current state of the download
 * @param {string} inputParameters.progress - The progress of the download
 * @param {string} inputParameters.inactive - The text to display when the download is inactive
 * @param {React.Component} inputParameters.InactiveIcon - The icon to display when the download is inactive
 * @returns {string|React.Component} The formatted state of the download
 */
export const formatStateDownload = ({ stateLoading, progress, inactive, InactiveIcon = IoArrowDownCircle }) => {
  if (stateLoading === stateLoadingPdf.generate) return '0%';
  if (stateLoading === stateLoadingPdf.download) return progress;
  if (stateLoading === stateLoadingPdf.save) return '100%';

  return (
    <>
      <InactiveIcon size={20} style={{ marginRight: '8px' }} />
      {inactive || i18n.t('user.PrintShoppingCartPage.download')}
    </>
  );
};
