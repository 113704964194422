import { Alert } from 'antd';
import { useFormikContext } from 'formik';
import htmr from 'htmr';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getMessageFromGraphQLError } from 'utils/helpers';

export const ErrorComponent = ({ description }) => {
  const { t } = useTranslation();
  return <Alert message={t('common.error')} description={htmr(description)} type="error" showIcon />;
};

// PLEASE USE ASYNC ONSUBMIT AND USE AWAIT IN IT
export const AlertFromFormik = () => {
  const { errors, isSubmitting } = useFormikContext();
  // console.log(JSON.stringify(useFormikContext()));
  const errorRef = useRef(); // fixes flicker when errors on isSubmitting === {}
  if (!isSubmitting) errorRef.current = Object.values(errors).find((e) => e?.alert)?.alert;
  return errorRef.current ? <ErrorComponent description={errorRef.current} /> : null;
};

export const QueryErrorAlert = ({ error }) =>
  error ? <ErrorComponent description={getMessageFromGraphQLError(error)} /> : null;

export const getAlertError = (string) => {
  const a = [];
  a.alert = string;
  return a;
};

// PLEASE USE ASYNC ONSUBMIT AND USE AWAIT IN IT
export const setGraphqlErrors = ({ errorResolver, error, formik, showErrorsInFormForValues = [] }) => {
  let e = error && getMessageFromGraphQLError(error);
  e && (e = String(e));
  let res;
  // console.log(e);
  if (typeof errorResolver === 'function') res = errorResolver({ message: e, formik, error });
  else res = errorResolver?.[e];
  res = Array.isArray(res) ? res : ['unknown', e];
  const [fieldWithError, description] = res;
  if (showErrorsInFormForValues.includes(fieldWithError)) return { [fieldWithError]: description };
  return { [fieldWithError]: getAlertError(description) };
};

// export const useError = ({ response, error = response, formatError, errors }) => {
//   let e = error && isEmpty(errors) && getMessageFromGraphQLError(error);
//   e && (e = String(e));
//   const [fieldWithError, description] = formatError(e) || [];
//   return { fieldWithError, ErrorComponent: !!description && <ErrorComponent description={description} /> };
// };

// export const useFormikValuesChangedAfterSubmit = ({ formikRef }) => {
//   const submittedValuesRef = useRef();

//   const handleSubmit = useCallback(
//     (...p) => {
//       submittedValuesRef.current = formikRef.current.values;
//       formikRef.current.handleSubmit(...p);
//     },
//     [formikRef],
//   );

//   const valuesNotChangedFromLastSubmit = ({ values }) =>
//     submittedValuesRef.current && isEqual(values, submittedValuesRef.current);
//   return { handleSubmit, valuesNotChangedFromLastSubmit };
// };
export default ErrorComponent;
