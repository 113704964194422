import gql from 'graphql-tag';

const myThemeRichEditorQuery = gql`
  query myThemeRichEditorQuery {
    myTheme {
      pdf {
        main {
          primaryColor
          fontFamily
          color
        }
      }
    }
  }
`;

export default myThemeRichEditorQuery;
