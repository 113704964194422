import SigningInfoPagesLayout from 'components/layouts/SigningInfoPagesLayout';
import useSigningContext from 'hooks/signing/useSigningContext';
import useResetSigningContext from 'hooks/signing/useResetSigningContext';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import routePaths from 'router/route-paths';
import classes from './InfoPages.module.less';

const DocumentGenerationFailed = () => {
  const { t: translation } = useTranslation();
  const { loading, signingContext } = useSigningContext();
  const { resetSigningContext, resetSigningTheme } = useResetSigningContext();
  const navigate = useNavigate();
  const [firstCheck, setFirstCheck] = useState(true);
  const [officeEmail, setOfficeEmail] = useState(signingContext?.officeEmail || '');
  useEffect(() => {
    if (!loading && signingContext) setFirstCheck(false);
    if (!loading && !signingContext && firstCheck) navigate(routePaths.signingUnvalid);
  }, [loading, signingContext, navigate, firstCheck]);

  setTimeout(() => resetSigningContext(), 2000);

  setTimeout(() => resetSigningTheme(), 5000);

  if (officeEmail === '' && signingContext?.officeEmail) setOfficeEmail(signingContext.officeEmail);

  return (
    <SigningInfoPagesLayout contentAreaClassName={classes.contentArea}>
      <div className={classes.headingWrapper}>
        <h1 className={classes.infoPageHeading}>
          {translation('signing.infoPages.waitForDocumentsFailed.heading', { officeEmail })}
        </h1>
      </div>
    </SigningInfoPagesLayout>
  );
};

export default DocumentGenerationFailed;
