import jwtDecode from 'jwt-decode';
import { useEffect, useCallback, useState } from 'react';
import { isGlobalPage } from 'utils/isGlobalPage';
import useCurrentUser from '../auth/useCurrentUser';

const DEFAULT_CSS_THEME_PATH = '/css';
export const SIGNING_CSS_PATH_KEY = 'signingCssPath';
const INDIVIDUAL_CSS_THEME_PATH = (primaryColor, color) => `/css?primaryColor=${primaryColor}&color=${color}`;

const checkLocationPathname = () => {
  return /(\/signing)\/?([^/]*)/.exec(window?.location?.pathname);
};

const extractValueOutOfColorString = (colorString) => colorString.replace('#', '');

const cssPathOfSigningPagesInLocalStorage = (cssPath) => {
  if (cssPath) {
    window.localStorage.setItem(SIGNING_CSS_PATH_KEY, cssPath);
    return cssPath;
  }
  const pathOfLocalStorage = window.localStorage.getItem(SIGNING_CSS_PATH_KEY);
  if (pathOfLocalStorage) return pathOfLocalStorage;
  return DEFAULT_CSS_THEME_PATH;
};

const generateCSSGetLink = ({ isSigningPage, token }) => {
  if (!isSigningPage) return DEFAULT_CSS_THEME_PATH;
  if (!token) return cssPathOfSigningPagesInLocalStorage();
  try {
    const decodedToken = jwtDecode(token);

    if (!decodedToken || !decodedToken.theme) return DEFAULT_CSS_THEME_PATH;

    let { background: primaryColor, color } = decodedToken.theme;

    if (!primaryColor || !color) return cssPathOfSigningPagesInLocalStorage();

    primaryColor = extractValueOutOfColorString(primaryColor);
    color = extractValueOutOfColorString(color);

    return cssPathOfSigningPagesInLocalStorage(INDIVIDUAL_CSS_THEME_PATH(primaryColor, color));
  } catch {
    return cssPathOfSigningPagesInLocalStorage();
  }
};

const useLoadTheme = () => {
  const [user, loading] = useCurrentUser(isGlobalPage());
  const [themeLoading, setThemeLoading] = useState(true);

  const loadTheme = useCallback(async () => {
    let element = document.head.querySelector('#antdStyles');
    if (!element) {
      element = document.createElement('style');
      element.id = 'antdStyles';
      document.head.appendChild(element);
    }

    const matchSigning = checkLocationPathname();
    const cssUrl = generateCSSGetLink({ isSigningPage: matchSigning, token: matchSigning && matchSigning[2] });
    const css = await fetch(cssUrl).then((resp) => resp.text());

    element.innerHTML = css;
  }, []);
  const userId = user?._id;
  useEffect(() => {
    if (loading) return;
    userId;
    setThemeLoading(true);
    loadTheme().then(() => setThemeLoading(false));
  }, [loadTheme, loading, userId]);
  return [themeLoading];
};

export default useLoadTheme;
