import { ReconciliationOutlined } from '@ant-design/icons';
import { Button, Card, Collapse } from 'antd';
import equal from 'fast-deep-equal/es6/react';
import { TableMemo } from 'memo';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { omit, pick } from 'lodash';
import getCompanyImportFieldsById from 'graphql/cache/user/company/getCompanyImportFieldsById';
import classes from './HiddenImportData.module.less';

const { Panel } = Collapse;
const columns = ({ t }) => {
  return [
    {
      title: t('user.ShoppingCart.ContactData.prefillContact.columns.fieldTitle'),
      dataIndex: 'field',
      key: 'field',
      render: (field) => t(`user.ShoppingCart.ContactData.prefillContact.columns.fields.${field}`),
    },
    {
      title: t('user.ShoppingCart.ContactData.prefillContact.columns.valueTitle'),
      dataIndex: 'value',
      key: 'value',
    },
  ];
};

function ImportFields({ companyId, isOpen }) {
  const { t } = useTranslation();
  const { dataSource, importFields } = useMemo(() => {
    // eslint-disable-next-line no-shadow
    const importFields = getCompanyImportFieldsById({ companyId });
    // eslint-disable-next-line no-shadow
    const dataSource = {
      importCompanyType: importFields?.importCompanyType,
      commercialObject: importFields?.commercialObject,
      importSalutation: importFields?.importSalutation,
      importAcademicTitle: importFields?.importAcademicTitle,
      importEmail: importFields?.importEmail,
    };
    return {
      dataSource: Object.entries(dataSource).map(([field, value]) => ({ field, value })),
      importFields: omit(importFields, ['__typename']),
    };
  }, [companyId]);
  const isHide = useMemo(
    () =>
      !Object.values(pick(importFields, ['importSalutation', 'importAcademicTitle', 'importEmail'])).filter(Boolean)
        .length,
    [importFields],
  );

  const { setValues } = useFormikContext();

  const onPrefill = useCallback(() => {
    setValues((state) => ({ ...state, email: importFields?.importEmail }));
  }, [importFields.importEmail, setValues]);

  if (isHide || !companyId) return null;

  return (
    <Card className={classes.card}>
      <Collapse defaultActiveKey={isOpen} className={classes.collapse} ghost>
        <Panel
          key="true"
          className={classes.panel}
          header={t('user.ShoppingCart.ContactData.prefillContact.contactCollapse')}
        >
          <TableMemo columns={columns({ t })} dataSource={dataSource} pagination={false} />
        </Panel>
      </Collapse>
      {importFields?.importEmail && (
        <Button className="margin-bottom-16" onClick={onPrefill} icon={<ReconciliationOutlined />}>
          {t('user.ShoppingCart.ContactData.prefillContact.button')}
        </Button>
      )}
    </Card>
  );
}
export default memo(ImportFields, equal);
