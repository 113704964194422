import { useMemo } from 'react';
import { userDocumentTemplateMultiSelectQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { useCachedQuery } from 'graphql/utils';

const useDocumentTemplateList = () => {
  const { data, loading } = useCachedQuery(userDocumentTemplateMultiSelectQuery, {
    variables: { isLibrary: false },
    fetchPolicy: 'cache-and-network',
  });
  const documentTemplates = useMemo(() => grabFirstGQLDataResult(data), [data]);
  return { data: documentTemplates, loading };
};

export default useDocumentTemplateList;
