import { gql } from '@apollo/client';
import { adminCompanyTypeFragment } from 'graphql/fragments';

const adminCompanyTypesQuery = gql`
  query adminCompanyTypesQuery {
    companyTypes {
      ...adminCompanyTypeFragment
    }
  }
  ${adminCompanyTypeFragment}
`;

export default adminCompanyTypesQuery;
