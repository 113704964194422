import { useCachedQuery } from 'graphql/utils';
import { adminPlaceholderDataForOptionQuery } from 'graphql/queries';
import apolloClient from 'graphql/apollo';
import { useLibraryContext } from 'contexts/LibraryContext';
import { getFieldsFromData } from './useAdminInputFields';

export const getAdminFieldsForOption = ({ categoryId, parentId, _id, isLibrary }) => {
  const data = apolloClient.readQuery({
    query: adminPlaceholderDataForOptionQuery,
    variables: { categoryId, parentId, isLibrary },
  });
  const fields = getFieldsFromData({ data, parentId, categoryId, _id });
  return fields;
};
const useAdminFieldsForOption = ({ categoryId, parentId, _id, fetchPolicy = 'network-only' }) => {
  const { isLibrary } = useLibraryContext();
  const { data, loading } = useCachedQuery(adminPlaceholderDataForOptionQuery, {
    variables: { categoryId, parentId, isLibrary },
    fetchPolicy,
  });
  if (loading) return [null, true];
  const fields = getFieldsFromData({ data, parentId, categoryId, _id });
  return [fields, loading];
};

export default useAdminFieldsForOption;
