import { Form, Input } from 'formik-antd';
import * as Yup from 'yup';
import { memo, useMemo, useRef } from 'react';
import { AlertFromFormik } from 'components/common/ErrorComponent';
import { useTranslation } from 'react-i18next';
import { updateCategory } from 'graphql/methods';
import FormItem from 'components/common/FormItem';
import SaveChangesButton from 'components/common/SaveChangesButton';
import { useLibraryContext } from 'contexts/LibraryContext';
import I18nFormik from 'components/common/I18nFormik';
import equal from 'fast-deep-equal/es6/react';
import { RichEditorField } from 'components/common/RichEditor';
import i18n from 'i18n';
import MoreInfoWidget from 'components/common/MoreInfoWidget';
import toast from 'utils/toast';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { RichText } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { textsSchema } from './itemModal/schema';
import { SubTitleFormikInput, GuidanceVideoFormikInput, GuidanceTextFormikRichEditor } from './itemModal/components';

export const guidanceVideoIdSchema = () => ({
  guidanceVideoId: Yup.string()
    .transform((value) => value || '')
    .url()
    .label(i18n.t('admin.categoryTexts.guidanceVideoId.label')),
});
const AddCategorySchema = () =>
  Yup.object().shape({
    ...textsSchema({}),
    ...guidanceVideoIdSchema({}),
  });
export const IntroForDocumentFormikInput = ({ props }) => (
  <RichEditorField
    rootElement="paragraph"
    name="introForDocument"
    label="admin.categoryTexts.introForDocument.label"
    tooltip={i18n.t('admin.categoryTexts.introForDocument.tooltip')}
    {...props}
  />
);

const TextsHelperWidget = () => {
  const { t } = useTranslation();
  return (
    <MoreInfoWidget
      buttonText={t('admin.CatalogueConfiguration.TextsHelperWidget.howUseButton')}
      title={t('admin.CatalogueConfiguration.TextsHelperWidget.modalInfo.title')}
      helpText={t('admin.CatalogueConfiguration.TextsHelperWidget.modalInfo.helpText')}
      videoCaption={t('admin.CatalogueConfiguration.TextsHelperWidget.modalInfo.videoCaption')}
      videoUrl={t('admin.CatalogueConfiguration.TextsHelperWidget.modalInfo.videoUrl')}
      imageUrl={t('admin.CatalogueConfiguration.TextsHelperWidget.modalInfo.imageUrl')}
    />
  );
};

const TextsHelperWidgetMemo = memo(TextsHelperWidget, equal);

const TextsTab = ({ _id, name, subTitle, guidanceText, guidanceVideoId, introForDocument }) => {
  const initialValues = useMemo(
    () => ({
      name,
      subTitle,
      guidanceText: guidanceText ?? RichText.getDefaultRichEditorValue(),
      guidanceVideoId,
      introForDocument: introForDocument ?? RichText.getDefaultRichEditorValue(),
    }),
    [guidanceText, guidanceVideoId, introForDocument, name, subTitle],
  );
  const formikRef = useRef();
  const { t } = useTranslation();
  const { isLibrary } = useLibraryContext();
  return (
    <I18nFormik
      ref={formikRef}
      initialValues={initialValues}
      onSubmit={(values, formik) =>
        updateCategory({ _id, modifier: values })
          .then((response) => {
            const { data } = response;
            const warnings = grabFirstGQLDataResult(data)?.warnings;
            warnings &&
              warnings.forEach((warning) => toast.warning(t(`BackendWarnings.${warning.key}`, warning.variables)));
            return response;
          })
          .catch(formik.setErrors)
      }
      validationSchema={AddCategorySchema}
      enableReinitialize
    >
      <Form layout="vertical">
        <TextsHelperWidgetMemo />
        <AlertFromFormik />
        <FormItem
          name="name"
          label={t('admin.categoryTexts.name.label')}
          tooltip={t('admin.categoryTexts.name.tooltip')}
        >
          <Input name="name" autoFocus placeholder={t('admin.categoryTexts.name.label')} />
        </FormItem>
        <SubTitleFormikInput tooltip={t('admin.categoryTexts.subject.tooltip')} />
        <IntroForDocumentFormikInput />
        {isLibrary ? (
          <>
            <GuidanceTextFormikRichEditor />
            <GuidanceVideoFormikInput />
          </>
        ) : null}
        <SaveChangesButton initialValues={initialValues} />
      </Form>
    </I18nFormik>
  );
};
export default memo(TextsTab, equal);
