import apollo from 'graphql/apollo';
import { createDiscountMutation } from 'graphql/mutations';
import { grabFirstGQLDataResult } from 'utils/helpers';

const createDiscount = async ({ categoryId, name, value, type, categoryListQuery }) =>
  apollo.mutate({
    mutation: createDiscountMutation,
    variables: { categoryId, discount: { name, value, type } },
    update: (cache, { data }) => {
      const result = grabFirstGQLDataResult(data);
      const cachedData = cache.readQuery({ query: categoryListQuery });
      cache.writeQuery({
        query: categoryListQuery,
        data: {
          categories: cachedData.categories.map((c) =>
            c._id === categoryId ? { ...c, discounts: [...(c.discounts || []), result] } : c,
          ),
        },
      });
    },
  });
export default createDiscount;
