import React, { useState } from 'react';
import { Button, Input } from 'antd';
import { gql } from '@apollo/client';
import ApolloClient from 'graphql/apollo';
import { downloadText } from './utils';

window.profileBackendCPU = (seconds) =>
  ApolloClient.mutate({
    mutation: gql`
      mutation profileCPU($seconds: Int) {
        profileCPU(seconds: $seconds)
      }
    `,
    variables: { seconds },
  }).then((r) => {
    downloadText(`profile_${new Date().toISOString()}.cpuprofile`, r.data.profileCPU);
    console.log(`examine the profile:
Navigate to chrome://inspect
Click Open dedicated DevTools for Node
Select the profiler tab
Load your file
`);
  });

export const ProfileBackendCPUPage = () => {
  const [seconds, setSeconds] = useState(10);
  const [isDisabled, setIsDisabled] = useState(false);
  return (
    <div className="container-fluid">
      <div>examine the profile:</div>
      <div>Navigate to chrome://inspect</div>
      <div>Click Open dedicated DevTools for Node</div>
      <div>Select the profiler tab</div>
      <div>Load your file</div>
      <br />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ whiteSpace: 'pre', marginRight: 5 }}>Number of seconds to record</span>
        {/* eslint-disable-next-line no-unsafe-optional-chaining */}
        <Input type="number" value={+seconds} onChange={(event) => setSeconds(+event?.target?.value)} />
      </div>
      <Button
        disabled={isDisabled}
        onClick={() => {
          setIsDisabled(true);
          window
            .profileBackendCPU(seconds)
            .catch(console.log)
            .then(() => {
              setIsDisabled(false);
            });
        }}
      >
        Profile and save log
      </Button>
    </div>
  );
};

export default ProfileBackendCPUPage;
