import equal from 'fast-deep-equal/es6/react';
import { TableMemo } from 'memo';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCachedQuery } from 'graphql/utils';
import { superAdminTenantsQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { fullNameFromUser } from 'components/layout/CurrentUserDropdown';
import GraphQLLoadingWrapper from 'components/common/GraphQLLoadingWrapper';
import toast from 'utils/toast';
import stateInvitation from 'constants/stateInvitation';
import { Button, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { resendInvitation } from 'graphql/methods';
import { formatDate, formatDateTime } from 'utils/date';
import { RiMailLine, RiMailSettingsLine } from 'react-icons/ri';
import { editTenantModal } from './TenantForm';
import TenantActionButton from './TenantActionList';

// const deleteUserWithConfirmation = ({ _id }) =>
//   confirmModal({
//     okText: i18n.t('admin.deleteDiscountConfirmation.ok'),
//     cancelText: i18n.t('admin.deleteDiscountConfirmation.cancel'),
//     okType: 'danger',
//     onOk: async () => {
//       deleteUser({ userId: _id }).catch(() => toast.error(i18n.t('common.toast.errors.user.delete')));
//     },
//     title: i18n.t('admin.deleteDiscountConfirmation.title'),
//   });
export const PasswordResetButton = memo(({ _id }) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('common.buttons.resendInvitation.tooltip')}>
      <Button
        className="ant-btn-default"
        type="danger"
        ghost
        icon={<RiMailSettingsLine className="anticon" />}
        onClick={() => {
          resendInvitation({ userId: _id })
            .then(() => toast.success(t('common.toast.success.passwordReset')))
            .catch(() => toast.error(t('common.toast.errors.passwordReset')));
        }}
      />
    </Tooltip>
  );
}, equal);

export const ResendInvitationButton = memo(({ _id }) => {
  const { t } = useTranslation();
  return (
    <Button
      className="ant-btn-default"
      type="danger"
      ghost
      icon={<RiMailLine className="anticon" />}
      onClick={() => {
        resendInvitation({ userId: _id })
          .then(() => toast.success(t('common.toast.success.resendInvitation')))
          .catch(() => toast.error(t('common.toast.errors.resendInvitation')));
      }}
    />
  );
}, equal);

const columns = ({ t, onEdit }) => [
  {
    title: t('superAdmin.ManageTenants.columns.tenantName'),
    key: 'name',
    dataIndex: 'name',
  },
  {
    title: t('superAdmin.ManageTenants.columns.rootAdminName'),
    key: 'rootAdminName',
    render: ({ rootAdmin }) => fullNameFromUser(rootAdmin),
  },
  {
    title: t('superAdmin.ManageTenants.columns.rootAdminEmail'),
    key: 'rootAdmin.profile.email',
    render: (tenant) => tenant.rootAdmin?.email,
  },
  {
    title: t('superAdmin.ManageTenants.columns.nrOfAllowedCompaniesByPricingPlan'),
    dataIndex: 'nrOfAllowedCompaniesByPricingPlan',
    key: 'nrOfAllowedCompaniesByPricingPlan',
  },
  {
    title: t('superAdmin.ManageTenants.columns.nrOfAllowedUsersByPricingPlan'),
    dataIndex: 'nrOfAllowedUsersByPricingPlan',
    key: 'nrOfAllowedUsersByPricingPlan',
  },
  {
    title: t('superAdmin.ManageTenants.columns.licenseStarted'),
    dataIndex: 'licenseStarted',
    key: 'licenseStarted',
    render: (date) => (date ? formatDate(date) : null),
    sorter: true,
  },
  {
    title: t('superAdmin.ManageTenants.columns.status'),
    dataIndex: 'rootAdmin',
    key: 'status',
    render: (rootAdmin) =>
      t(`superAdmin.ManageTenants.stateInvitation.${rootAdmin?.state || stateInvitation.INACTIVE}`),
  },
  {
    title: t('superAdmin.ManageTenants.columns.nrOfCompanies'),
    dataIndex: ['numberOf', 'companies'],
    key: 'numberOf.companies',
    render: (e) => e || 0,
  },
  {
    title: t('superAdmin.ManageTenants.columns.nrOfUsers'),
    dataIndex: ['numberOf', 'users'],
    key: 'nrOfUsers',
    render: (e) => e || 0,
  },
  {
    title: t('superAdmin.ManageTenants.columns.nrOfCreatedShoppingCarts'),
    dataIndex: ['numberOf', 'createdShoppingCarts'],
    key: 'nrOfCreatedShoppingCarts',
    render: (e) => e || 0,
  },
  {
    title: t('superAdmin.ManageTenants.columns.nrOfCatalogueConfigurationEdits'),
    dataIndex: ['numberOf', 'catalogueConfigurationEdits'],
    key: 'nrOfCatalogueConfigurationEdits',
    render: (e) => e || 0,
  },
  {
    title: t('superAdmin.ManageTenants.columns.nrOfCompanyTypeEdits'),
    dataIndex: ['numberOf', 'companyTypeEdits'],
    key: 'nrOfDocumentTypeEdits',
    render: (e) => e || 0,
  },
  {
    title: t('superAdmin.ManageTenants.columns.nrOfTotalActions'),
    dataIndex: ['numberOf', 'totalActions'],
    key: 'nrOfTotalActions',
    render: (e) => e || 0,
  },
  {
    title: t('superAdmin.ManageTenants.columns.nrOfLogins'),
    dataIndex: ['numberOf', 'logins'],
    key: 'nrOfLogins',
    render: (e) => e || 0,
  },
  {
    title: t('superAdmin.ManageTenants.columns.timeStampOfLastAction'),
    dataIndex: 'timeStampOfLastAction',
    key: 'timeStampOfLastAction',
    render: (date) => date && formatDateTime(new Date(date)),
  },
  {
    title: t('superAdmin.ManageTenants.columns.locale'),
    dataIndex: 'rootAdmin',
    key: 'locale',
    render: (rootAdmin) =>
      rootAdmin?.locale ? t(`superAdmin.ManageTenants.fields.locale.values.${rootAdmin?.locale}`) : null,
  },
  {
    title: t('superAdmin.ManageTenants.columns.tenantStatus'),
    dataIndex: 'disabled',
    key: 'disabled',
    render: (disabled) => t(`superAdmin.ManageTenants.fields.disabled.values.${disabled ? 'disabled' : 'enabled'}`),
  },
  {
    title: t('superAdmin.ManageTenants.columns.viewLogbook'),
    key: 'viewLogbook',
    fixed: 'right',
    render: ({ _id, name }) => (
      <div className="text-align-center">
        <TenantActionButton classNameButton="ant-btn-default" _id={_id} name={name} />
      </div>
    ),
  },
  {
    title: t('common.columns.actions'),
    fixed: 'right',
    width: 102,
    render: (tenant) => {
      const { rootAdmin } = tenant;
      const state = rootAdmin?.state;
      const userId = rootAdmin?._id;
      return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-word', textAlign: 'center' }}>
          <Button
            className="ant-btn-default"
            type="danger"
            ghost
            icon={<EditOutlined />}
            onClick={() => onEdit(tenant)}
          />{' '}
          {state === stateInvitation.ACTIVE && <PasswordResetButton _id={userId} />}
          {state === stateInvitation.INVITATION_SEND && (
            <Button
              className="ant-btn-default"
              type="danger"
              ghost
              icon={<RiMailLine className="anticon" />}
              onClick={() => {
                resendInvitation({ userId })
                  .then(() => toast.success(t('common.toast.success.resendInvitation')))
                  .catch(() => toast.error(t('common.toast.errors.resendInvitation')));
              }}
            />
          )}
        </div>
      );
    },
  },
];

const useSuperAdminTenantsQuery = () => {
  const { data, ...res } = useCachedQuery(superAdminTenantsQuery, { fetchPolicy: 'cache-and-network' });
  return useMemo(() => {
    const tenants = data?.tenants;
    return {
      data: {
        tenants: Array.isArray(tenants)
          ? tenants.map((tenant) => {
              return { ...tenant, licenseStarted: tenant.licenseStarted ? new Date(tenant.licenseStarted) : null };
            })
          : null,
      },
      ...res,
    };
  }, [data, res]);
};

function TenantList() {
  const { t } = useTranslation();
  const { data, loading, error } = useSuperAdminTenantsQuery();
  const tenants = useMemo(() => grabFirstGQLDataResult(data), [data]);
  const onEdit = useCallback((tenant) => {
    editTenantModal(tenant);
  }, []);
  return (
    <GraphQLLoadingWrapper data={data} loading={loading} error={error}>
      <TableMemo
        bordered={false}
        columns={columns({ t, onEdit })}
        dataSource={tenants}
        pagination={false}
        rowKey={({ _id }) => _id}
        scroll={{ x: 2000 }}
      />
    </GraphQLLoadingWrapper>
  );
}

export default memo(TenantList, equal);
