import { Button, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { importDefaultTemplatesForDigitalSignature } from 'graphql/methods';

/**
 * CleanImportFooter component to show the button combinatuin for the case that no default template for digital signature is imported into the tenant instance
 * @param {Object} inputParameters - Input parameters of the component
 * @param {Boolean} inputParameters.shouldSetSelectionValuesToImportedTemplates - Should the imported templates be set as selection the settings
 * @param {Function} inputParameters.onCloseModal - Function to close the modal
 * @returns {JSX.Element} button bar for import modal for the case that no default template is imported
 * @component
 */
const CleanImportFooter = ({ shouldSetSelectionValuesToImportedTemplates, onCloseModal }) => {
  const { t } = useTranslation();

  const onImportClicked = () => {
    onCloseModal();
    importDefaultTemplatesForDigitalSignature(false, shouldSetSelectionValuesToImportedTemplates);
  };

  return (
    <Space>
      <Button onClick={onCloseModal}>
        {t('admin.Setting.DigitalSignaturePreferences.importDefaultTemplatesModal.cancelButton')}
      </Button>
      <Button type="primary" onClick={onImportClicked}>
        {t('admin.Setting.DigitalSignaturePreferences.importDefaultTemplatesModal.importTemplatesButton')}
      </Button>
    </Space>
  );
};

export default CleanImportFooter;
