import DraggableBodyRow from 'components/admin/drag/DraggableBodyRow';
import equal from 'fast-deep-equal/es6/react';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Collapse } from 'antd';
import { useRowContext } from 'contexts/RowContext';
import cn from 'classnames';
import HeaderCollapse from 'components/admin/components/HeaderCollapse/HeaderCollapse';
import { find, findIndex, omit } from 'lodash';
import { clientShift } from 'utils/helpers';
import classes from './Collapse.module.less';
import { useCollapseProps, usePanelTitle, usePanelProps } from './hooks';

const { Panel } = Collapse;
const PanelCollapseContent = ({
  setCollapseProps,
  activeKeys,
  onChangeCollapse,
  recordId,
  setPanelProps,
  panelHeader,
  Component,
  componentProps,
  isNew,
  decorate,
}) => {
  return (
    <div style={{ position: 'relative ' }}>
      <Collapse
        {...setCollapseProps}
        activeKey={activeKeys}
        onChange={onChangeCollapse}
        className={cn('site-collapse-custom-collapse', setCollapseProps?.className)}
      >
        <Panel
          key={recordId}
          {...setPanelProps}
          className={cn(classes.siteCollapseCustomPanel, 'xs-mb-20', setPanelProps?.className)}
          header={panelHeader}
        >
          <Component {...componentProps} isNew={isNew} />
        </Panel>
      </Collapse>
      {decorate}
    </div>
  );
};
const PanelCollapseContentMemo = memo(PanelCollapseContent, equal);

function PanelCollapse({
  editRecord,
  isNew,
  record,
  changeActiveKey,
  activeKey,
  component: Component,
  itemTypes,
  //
  isDraggingInContainer,
  collapseProps,
  extraPanelProps,
  panelProps,
  decorate = null,
  componentProps,
}) {
  const { _id: recordId } = record;
  const setIsActive = useCallback(
    (active) => {
      changeActiveKey({ recordId, active });
    },
    [recordId, changeActiveKey],
  );
  const onOpenCollapse = useCallback(() => editRecord({ recordId }), [editRecord, recordId]);

  const panelHeaderTitle = usePanelTitle({ title: panelProps.header, record });
  const setCollapseProps = useCollapseProps({ collapseProps, record });
  const setPanelProps = usePanelProps({ panelProps, recordId, onOpenCollapse, extraPanelProps });

  const activeKeys = useMemo(() => {
    let _activeKeys;
    if (isDraggingInContainer === itemTypes) _activeKeys = undefined;
    else _activeKeys = activeKey ? recordId : undefined;
    return _activeKeys;
  }, [activeKey, isDraggingInContainer, itemTypes, recordId]);

  const onChangeCollapse = useCallback(
    (keys) => {
      setIsActive(keys.length && keys[0]);
    },
    [setIsActive],
  );
  const panelHeader = useMemo(
    () => <HeaderCollapse name={panelHeaderTitle} isActive={isDraggingInContainer !== itemTypes && !!activeKey} />,
    [activeKey, isDraggingInContainer, itemTypes, panelHeaderTitle],
  );
  return (
    <PanelCollapseContentMemo
      setCollapseProps={setCollapseProps}
      activeKeys={activeKeys}
      onChangeCollapse={onChangeCollapse}
      recordId={recordId}
      setPanelProps={setPanelProps}
      panelHeader={panelHeader}
      Component={Component}
      componentProps={componentProps}
      isNew={isNew}
      decorate={decorate}
    />
  );
}

const PanelCollapseMemo = memo(PanelCollapse, equal);

const PanelCollapseWrapper = (props) => {
  const { isDraggingInContainer } = useRowContext();
  // eslint-disable-next-line react/destructuring-assignment
  return <PanelCollapseMemo key={props.record._id} isDraggingInContainer={isDraggingInContainer} {...props} />;
};
const PanelCollapseWrapperMemo = memo(PanelCollapseWrapper, equal);

const RenderRecord = ({
  record,
  itemTypes,
  onHover,
  moveMutation,
  draggableProps,
  editRecord,
  isNew,
  changeActiveKey,
  activeKey,
  component,
  collapseProps,
  extraPanelProps,
  panelProps,
  componentProps: _componentProps,
  decorate,
  nameElementData,
}) => {
  const componentProps = useMemo(
    () => ({ ..._componentProps, [nameElementData]: record }),
    [_componentProps, nameElementData, record],
  );
  return (
    <DraggableBodyRow
      key={record._id}
      id={record._id}
      recordId={record._id}
      itemTypes={itemTypes}
      move={onHover}
      mutation={moveMutation}
      {...draggableProps}
    >
      <PanelCollapseWrapperMemo
        key={record._id}
        editRecord={editRecord}
        isNew={isNew}
        changeActiveKey={changeActiveKey}
        activeKey={activeKey}
        record={record}
        component={component}
        itemTypes={itemTypes}
        //
        collapseProps={collapseProps}
        extraPanelProps={extraPanelProps}
        panelProps={panelProps}
        componentProps={componentProps}
        decorate={decorate}
      />
    </DraggableBodyRow>
  );
};
const RenderRecordMemo = memo(RenderRecord, equal);

function DraggableCollapse({
  component,
  data: dataProp,
  moveMutation: moveMutationProp,
  componentProps,
  itemTypes,
  nameElementData,
  draggableProps = {},
  collapseProps,
  extraPanelProps,
  panelProps,
  decorate,
  newKeys = [],
  listOnlyNewestEntryOnCreate = false,
  moveOptions: { orderBy, shiftBy } = {},
}) {
  const [activeKeys, setActiveKeys] = useState({});

  const newKeysRef = useRef({});
  const [data, setData] = useState(dataProp);

  useEffect(() => setData(dataProp), [dataProp]);
  const onHover = useCallback(
    (overId, draggedId) => {
      setData((state) => {
        let draggedShift;
        let overShift;
        if (shiftBy) {
          draggedShift = find(state, { _id: draggedId })?.[shiftBy];
          overShift = find(state, { _id: overId })?.[shiftBy];
        } else {
          draggedShift = findIndex(state, { _id: draggedId });
          overShift = findIndex(state, { _id: overId });
        }
        const newItems = clientShift({
          array: state,
          _id: draggedId,
          shift: overShift - draggedShift,
          orderBy,
        });
        return newItems;
      });
    },
    [orderBy, shiftBy],
  );
  const moveMutation = useCallback(
    ({ _id }) => {
      setData((state) => {
        moveMutationProp(_id, state);
        return state;
      });
    },
    [moveMutationProp],
  );

  useEffect(() => {
    newKeys.forEach((e) => {
      if (typeof newKeysRef.current === 'object' && !(e in newKeysRef.current)) {
        newKeysRef.current[e] = true;
        if (listOnlyNewestEntryOnCreate) {
          setActiveKeys({ [e]: true });
        } else {
          setActiveKeys({ ...activeKeys, [e]: true });
        }
      }
    });
  }, [activeKeys, listOnlyNewestEntryOnCreate, newKeys]);

  const changeActiveKey = useCallback(({ recordId, active }) => {
    setActiveKeys((state) => {
      newKeysRef.current = { ...newKeysRef.current, [recordId]: false };
      return { ...state, [recordId]: active ? recordId : undefined };
    });
  }, []);

  const editRecord = useCallback(({ recordId }) => {
    setActiveKeys((state) => {
      newKeysRef.current = { ...newKeysRef.current, [recordId]: true };
      return { ...state, [recordId]: true };
    });
  }, []);

  const dataForRender = data?.map((record) => omit(record, ['order']));

  if (!dataForRender?.length) return null;
  return dataForRender.map((record) => (
    <RenderRecordMemo
      key={record._id}
      record={record}
      itemTypes={itemTypes}
      onHover={onHover}
      moveMutation={moveMutation}
      draggableProps={draggableProps}
      editRecord={editRecord}
      isNew={newKeysRef.current[record._id]}
      changeActiveKey={changeActiveKey}
      activeKey={activeKeys[record._id]}
      component={component}
      collapseProps={collapseProps}
      extraPanelProps={extraPanelProps}
      panelProps={panelProps}
      componentProps={componentProps}
      decorate={decorate}
      nameElementData={nameElementData}
    />
  ));
}

export default memo(DraggableCollapse, equal);
