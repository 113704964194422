import { Route, Routes } from 'react-router-dom';

export const HideByRoute = ({ path, children }) => {
  return (
    <Routes>
      <Route key={path} path={path} element={null} />
      <Route path="*" element={children} />
    </Routes>
  );
};

export default HideByRoute;
