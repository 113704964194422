import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import routePaths from 'router/route-paths';

const SigningContextErrorHandler = ({ error, children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const { message } = error || {};
    console.log('Message', message);
    if (!message) return;
    if (message.includes('signed')) navigate(routePaths.signingAlreadySigned);
    if (message.includes('passwordWrong')) navigate(routePaths.signingUnvalid);
    if (message.includes('Not authorized')) navigate(routePaths.signingUnvalid);
    if (message.includes('inactive')) navigate(routePaths.signingUnvalid);
    if (message.includes('revoked')) navigate(routePaths.signingUnvalid);
    if (message.includes('expired')) navigate(routePaths.signingUnvalid);
    if (message.includes('loginFailed')) navigate(routePaths.signingUnvalid);
  });

  if (!error) return children;

  return null;
};

export default SigningContextErrorHandler;
