import { gql } from '@apollo/client';

const importDefaultTemplatesForDigitalSignatureMutation = gql`
  mutation importDefaultTemplatesForDigitalSignatureMutation(
    $shouldReimportExistingOnes: Boolean
    $shouldSetSelectionValuesToImportedTemplates: Boolean
  ) {
    importDefaultTemplates(
      shouldReimportExistingOnes: $shouldReimportExistingOnes
      shouldSetSelectionValuesToImportedTemplates: $shouldSetSelectionValuesToImportedTemplates
    )
  }
`;

export default importDefaultTemplatesForDigitalSignatureMutation;
