import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { FaMagic } from 'react-icons/fa';
import { useFormikField } from 'hooks/common/useFormikField';
import Card from 'components/common/Card';
import NotifySigneesForSuccessfulFinishTemplateSelect from './AutomateSignatureProcessCard/NotifySigneesForSuccessfulFinishTemplateSelect';
import PostSuccessfulFinishStateSelect from './AutomateSignatureProcessCard/PostSuccessfulFinishStateSelect';
import NotifySigneesForRevokeTemplateSelect from './AutomateSignatureProcessCard/NotifySigneesForRevokeTemplateSelect';
import RemindSigneeAboutDigitalSignatureProcessTemplateSelect from './AutomateSignatureProcessCard/RemindSigneeAboutDigitalSignatureProcessTemplateSelect';
import AutomaticRemindSigneesAboutSignatureProcessSwitch from './AutomateSignatureProcessCard/AutomaticRemindSigneesAboutSignatureProcessSwitch';
import AutomaticRemindSigneesAboutSignatureProcessIntervalInput from './AutomateSignatureProcessCard/AutomaticRemindSigneesAboutSignatureProcessIntervalInput';

/**
 * AutomateSignatureProcessCard component is the settings card for automate the signature process (configure notification emails and status changes)
 * @returns {JSX.Element} card for the digital signature preference page for automate signature process settings
 * @component
 */
const AutomateSignatureProcessCard = () => {
  const { t } = useTranslation();
  const { value: digitalSignatureEnabled } = useFormikField('digitalSignatureEnabled');
  if (!digitalSignatureEnabled) return null;
  return (
    <Card
      icon={<FaMagic />}
      title={t('admin.Setting.DigitalSignaturePreferences.automateSignatureProcess.cardLabel')}
      style={{ padding: 0 }}
    >
      <Form layout="vertical">
        <NotifySigneesForSuccessfulFinishTemplateSelect t={t} />
        <PostSuccessfulFinishStateSelect t={t} />
        <NotifySigneesForRevokeTemplateSelect t={t} />
        <RemindSigneeAboutDigitalSignatureProcessTemplateSelect t={t} />
        <AutomaticRemindSigneesAboutSignatureProcessSwitch />
        <AutomaticRemindSigneesAboutSignatureProcessIntervalInput />
      </Form>
    </Card>
  );
};

export default AutomateSignatureProcessCard;
