import { useState } from 'react';
import { useApolloClient, useSubscription } from '@apollo/client';
import openSigningProcessesChangedSubscription from 'graphql/subscriptions/openSigningProcessesChanged';
import { userSigningProcessesClientQuery } from 'graphql/queries';

const useSubscribeToOpenSigningProcesses = (skip = false) => {
  const apolloClient = useApolloClient();
  const [hasActiveSigningProcesses, setHasActiveSigningProcesses] = useState(false);
  const [acticeSigningProcesses, setActiveSigningProcesses] = useState([]);
  useSubscription(openSigningProcessesChangedSubscription, {
    skip,
    onSubscriptionData: ({ subscriptionData }) => {
      const { signingProcesses } = subscriptionData.data.openSigningProcessesChanged;
      if (signingProcesses.length > 0) {
        setHasActiveSigningProcesses(true);
      } else {
        setHasActiveSigningProcesses(false);
      }
      setActiveSigningProcesses(signingProcesses);
      apolloClient.cache.writeQuery({ query: userSigningProcessesClientQuery, data: { signingProcesses } });
    },
  });
  return { hasActiveSigningProcesses, acticeSigningProcesses };
};

export default useSubscribeToOpenSigningProcesses;
