import { gql } from '@apollo/client';

const userHistoryItemFragment = gql`
  fragment userHistoryItemFragment on HistoryItem {
    _id
    actionType
    createdAt
    createdBy {
      fullName
    }
    variables
  }
`;

export default userHistoryItemFragment;
