import jwtDecode from 'jwt-decode';

const useSigningGraphqlContext = () => {
  return getSigneeAuthTokenContext();
};

export const getSigneeAuthTokenContext = () => {
  const signingAccessToken = localStorage.getItem('signing-access-token');
  if (!signingAccessToken) return {};
  const decoded = jwtDecode(signingAccessToken);
  if (decoded && decoded.expires && decoded.expires <= new Date(Date.now())) return {};
  const context = {
    headers: {
      authorization: `Bearer ${signingAccessToken}`,
    },
  };
  return context;
};

export default useSigningGraphqlContext;
