import { gql } from '@apollo/client';
import apollo from 'graphql/apollo';
import { documentTemplateBlockDescriptorFragment } from 'graphql/fragments';

export const documentTemplateFragment = gql`
  fragment documentTemplateFragment on DocumentTemplate {
    _id
    blocks {
      _id
      name
      props
      descriptor {
        ...documentTemplateBlockDescriptorFragment
      }
    }
  }
  ${documentTemplateBlockDescriptorFragment}
`;

function getDocumentTemplateBlocksCache({ _id } = {}) {
  const data = apollo.readFragment({
    fragment: documentTemplateFragment,
    fragmentName: 'documentTemplateFragment',
    id: `DocumentTemplate___${_id}`,
  });
  return data;
}
export default getDocumentTemplateBlocksCache;
