import { Tooltip } from 'antd';
import { memo } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import equal from 'fast-deep-equal/es6/react';

export function HelperIcon({ icon, title }) {
  return <Tooltip title={title}>{icon || <InfoCircleOutlined />}</Tooltip>;
}

export default memo(HelperIcon, equal);
