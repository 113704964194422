import { gql } from '@apollo/client';

const updateDefaultTemplatesMutation = gql`
  mutation updateDefaultTemplatesMutation($digitalSignatureDefaultTemplates: DigitalSignatureDefaultTemplatesInput!) {
    setDigitalSignatureDefaultTemplates(selectedDefaultTemplates: $digitalSignatureDefaultTemplates) {
      noticeOfCancellationTemplate
      sepaMandateTemplate
      startClientNotificationTemplate
      startTenantNotificationTemplate
      passwordEmailTemplate
      revokeEmailTemplate
      remindEmailTemplate
      signedBySigneeNotificationTemplate
      openedBySigneeNotificationTemplate
      successfulFinishedNotificationTemplate
    }
  }
`;

export default updateDefaultTemplatesMutation;
