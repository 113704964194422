import { FaCheckCircle, FaInfoCircle } from 'react-icons/fa';
import c from './ItemInCollapse.module.less';
import { Sans22px400, Sans30px400 } from './Text/Text';

const ItemInCollapse = ({ item }) => {
  1;

  return (
    <div className={c.itemInCollapse}>
      <div className={c.iconColumn}>
        <FaCheckCircle />
      </div>
      <div className={c.itemColumn}>
        <div className={c.titleRow}>
          <Sans30px400>{item.name}</Sans30px400>
          <FaInfoCircle />
        </div>
        <Sans22px400>{item.description}</Sans22px400>
      </div>
    </div>
  );
};
export default ItemInCollapse;
