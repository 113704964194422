import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Typography } from 'antd';
import { useQuery } from '@apollo/client';
import { adminHasTenantImportedDefaultTemplatesQuery } from 'graphql/queries';
import Switch from 'components/common/Switch';
import CleanImportFooter from './CleanImportFooter';
import IncompleteImportFooter from './IncompleteImportFooter';
import ReimportFooter from './ReimportFooter';

import classes from './ImportDefaultTemplatesModal.module.less';

const { Text } = Typography;

/**
 * ImportDefaultTemplatesModal component which confirms the import of default templates for digital signature and configures the import
 * @param {Object} inputParameters - Input parameters of the component
 * @param {Boolean} inputParameters.open - Should the modal be open
 * @param {Function} inputParameters.onCloseModal - Function to close the modal
 * @returns {JSX.Element} modal for digital signature preferences page to import default templates for digital signature
 * @component
 */
const ImportDefaultTemplatesModal = ({ open, onCloseModal }) => {
  const { t } = useTranslation();
  const { data = {}, loading } = useQuery(adminHasTenantImportedDefaultTemplatesQuery, {
    fetchPolicy: 'cache-and-network',
  });
  const [shouldSetSelectionValuesToImportedTemplates, setShouldSetSelectionValuesToImportedTemplates] = useState(true);

  const defaultTemplatesImportState = data?.hasTenantDefaultTemplatesImported;

  if (loading) return null;

  let modalFooter = null;
  let modalMessage = '';
  if (defaultTemplatesImportState?.hasOneImportedTemplate && defaultTemplatesImportState?.hasOneNotImportedTemplate) {
    modalFooter = (
      <IncompleteImportFooter
        onCloseModal={onCloseModal}
        shouldSetSelectionValuesToImportedTemplates={shouldSetSelectionValuesToImportedTemplates}
      />
    );
    modalMessage = t('admin.Setting.DigitalSignaturePreferences.importDefaultTemplatesModal.message.incompleteImport');
  } else if (defaultTemplatesImportState?.hasOneImportedTemplate) {
    modalFooter = (
      <ReimportFooter
        onCloseModal={onCloseModal}
        shouldSetSelectionValuesToImportedTemplates={shouldSetSelectionValuesToImportedTemplates}
      />
    );
    modalMessage = t('admin.Setting.DigitalSignaturePreferences.importDefaultTemplatesModal.message.reimportAll');
  } else {
    modalFooter = (
      <CleanImportFooter
        onCloseModal={onCloseModal}
        shouldSetSelectionValuesToImportedTemplates={shouldSetSelectionValuesToImportedTemplates}
      />
    );
    modalMessage = t('admin.Setting.DigitalSignaturePreferences.importDefaultTemplatesModal.message.cleanImport');
  }

  return (
    <Modal
      title={t('admin.Setting.DigitalSignaturePreferences.importDefaultTemplatesModal.title')}
      visible={open}
      footer={modalFooter}
      onCancel={onCloseModal}
      className={classes.modal}
    >
      <Text>{modalMessage}</Text>
      <Switch
        label={t(
          'admin.Setting.DigitalSignaturePreferences.importDefaultTemplatesModal.shouldSetSelectionValuesToImportedTemplatesSwitch.label',
        )}
        defaultChildren
        checked={shouldSetSelectionValuesToImportedTemplates}
        onChange={() => setShouldSetSelectionValuesToImportedTemplates((currentState) => !currentState)}
        className={classes.switch}
      />
    </Modal>
  );
};

export default ImportDefaultTemplatesModal;
