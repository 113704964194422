import { gql } from '@apollo/client';

const adminShoppingCartPreferencesFragment = gql`
  fragment adminShoppingCartPreferencesFragment on ShoppingCartPreferences {
    _id
    _apolloCacheKey
    allowCustomPrices
    companyTypeId
    documentTemplates {
      _id
    }
    emailTemplateId
    pricingMode
    roundPriceId
    showCalculated
    showDiscounts
    showPrices
    showDigits
    showCategoryTotal
  }
`;

export default adminShoppingCartPreferencesFragment;
