import { gql } from '@apollo/client';
import { superAdminTenantActionFragment } from 'graphql/fragments';

const superAdminTenantActionsQuery = gql`
  query superAdminTenantActionsQuery($_id: ID!) {
    tenantActions(_id: $_id) {
      ...superAdminTenantActionFragment
    }
  }
  ${superAdminTenantActionFragment}
`;

export default superAdminTenantActionsQuery;
