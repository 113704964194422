import { Editor, Transforms } from 'slate';
import { getSelectionRootBlocks } from '../utils';

export const alignFn = (to) => (editor) => {
  const nodesInSelection = Array.from(
    Editor.nodes(editor, {
      match: (node) => !Editor.isEditor(node),
      at: getSelectionRootBlocks(editor),
    }),
  );
  const rootNodes = nodesInSelection.filter((el) => el[1].length === 1);
  const nodePathsToRemoveKey = nodesInSelection.map(([, path]) => path);
  const nodePathsToAddKey = rootNodes.map(([, path]) => path);
  nodePathsToRemoveKey.forEach((path) => Transforms.setNodes(editor, { align: undefined }, { at: path }));
  nodePathsToAddKey.forEach((path) => Transforms.setNodes(editor, { align: to }, { at: path }));
};
