import { Radio } from 'formik-antd';
import FormItem from 'components/common/FormItem';
import { SEPA_MODE } from 'constants/shoppingCart';

/**
 * SepaMode is a React functional component that renders a radio button group for choosing the SEPA mode.
 *
 * @component
 * @param {Object} props - The properties passed to this component.
 * @param {function} props.t - The translation function from i18next, used for internationalization.
 * @returns {ReactElement} The rendered component.
 *
 * @example
 * <SepaMode t={translationFunction} />
 */
export const SepaMode = ({ t }) => {
  return (
    <FormItem
      tooltip={t('admin.Setting.DigitalSignaturePreferences.sepa.sepaMode.tooltip')}
      name="sepaMode"
      label={t('admin.Setting.DigitalSignaturePreferences.sepa.sepaMode.label')}
    >
      <Radio.Group name="sepaMode">
        <Radio.Button value={SEPA_MODE.optional}>
          {t('admin.Setting.DigitalSignaturePreferences.sepa.sepaMode.optional')}
        </Radio.Button>
        <Radio.Button value={SEPA_MODE.mandatory}>
          {t('admin.Setting.DigitalSignaturePreferences.sepa.sepaMode.mandatory')}
        </Radio.Button>
      </Radio.Group>
    </FormItem>
  );
};
