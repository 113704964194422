import { gql } from '@apollo/client';

const adminRoundPricesFragment = gql`
  fragment adminRoundPricesFragment on RoundPrice {
    _id
    label
    roundUpTo
    roundUpToMultiple
  }
`;

export default adminRoundPricesFragment;
