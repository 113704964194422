import Card from 'components/common/Card';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormOutlined } from '@ant-design/icons';
import { useFormikField } from 'hooks/common/useFormikField';
import { SignaturePageTitle } from './SignatureSettingsCard/SignaturePageTitle';
import { SignaturePageIntroductionText } from './SignatureSettingsCard/SignaturePageIntroductionText';
import { SignaturePageOptionYesText } from './SignatureSettingsCard/SignaturePageOptionYesText';
import { SignaturePageOptionNoText } from './SignatureSettingsCard/SignaturePageOptionNoText';

const SignatureSettingsCard = () => {
  const { t } = useTranslation();

  const { value: monthlyPaymentMode } = useFormikField('monthlyPaymentMode');
  if (monthlyPaymentMode !== 'optional') return null;

  return (
    <Card
      icon={<FormOutlined />}
      title={t('admin.Setting.MonthlyPaymentPreferences.signatureSettings.cardLabel')}
      style={{ padding: 0 }}
    >
      <Form layout="vertical">
        <SignaturePageTitle t={t} />
        <SignaturePageIntroductionText t={t} />
        <SignaturePageOptionYesText t={t} />
        <SignaturePageOptionNoText t={t} />
      </Form>
    </Card>
  );
};

export default SignatureSettingsCard;
