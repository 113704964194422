import { DIGITAL_SIGNATURE_STATE } from '@JavaScriptSuperstars/kanzleipilot-shared/lib/digitalSignature';
import { Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import useFasterPreferences from 'hooks/user/useFasterPreferences';
import Card from 'components/common/Card';
import { mergeSigneeData } from 'model/ShoppingCart';
import SigningStateRow from './DigitalSignatureCard/SigningStateRow';
import SigneeRow from './DigitalSignatureCard/SigneeRow';
import AdditionalButton from './DigitalSignatureCard/AdditionalButton';
import classes from './DigitalSignatureCard.module.less';

/**
 * Get function to generate SigneeRow components for digital signature card
 * @param {String} digitalSignatureState - Digital signature state of the shopping cart
 * @param {String} shoppingCartId - Database id of shopping cart object
 * @param {Number} numberOfSignees - Number of signees shown
 * @returns {Function} function with a signee object as input, which generates the SigneeRow component for the signee
 */
const getAddSigneeEntry = (digitalSignatureState, shoppingCartId, numberOfSignees) => (signee, index) => {
  const { lastName, firstName } = signee;
  return (
    <SigneeRow
      signee={signee}
      key={`${lastName}_${firstName}`}
      digitalSignatureState={digitalSignatureState}
      shoppingCartId={shoppingCartId}
      className={numberOfSignees - 1 === index ? classes.SigneeRowLast : null}
    />
  );
};

/**
 * CardStructure component for DigitalSignatureCard component, which renders a card with specified content
 * @param {Object} inputParameter - Input parameter of component
 * @param {JSX.Element[]} inputParameter.children - inner components to render in card
 * @returns {JSX.Element} CardStructure component for DigitalSignatureCard component
 */
const CardStructure = ({ children }) => {
  const { t: translation } = useTranslation();
  return <Card title={translation('viewer.ShoppingCartView.DigitalSignature.title')}>{children}</Card>;
};

/**
 * DigitalSignatureCard component for shopping cart view page, which shows digital signature state, signees for the cart and available options
 * @param {Object} inputParameter - Input parameter of component
 * @param {Object} inputParameter.shoppingCart - Shopping cart object of view
 * @returns {JSX.Element} DigitalSignatureCard component for shopping cart view page
 * @component
 */
const DigitalSignatureCard = ({ shoppingCart }) => {
  const { preferences: currentPreferences } = useFasterPreferences();
  const { digitalSignatureEnabled } = currentPreferences?.digitalSignaturePreferences || {};

  if (!digitalSignatureEnabled) return null;

  if (!shoppingCart)
    return (
      <CardStructure>
        <Skeleton />
      </CardStructure>
    );

  const { signatureData, digitalSignatureState } = shoppingCart || {};

  if (digitalSignatureState === DIGITAL_SIGNATURE_STATE.NO_DIGITAL_SIGNATURE || !digitalSignatureState) return null;

  const signees = signatureData ? mergeSigneeData(signatureData) : [];

  const signeesComponents = signees.map(getAddSigneeEntry(digitalSignatureState, shoppingCart?._id, signees.length));

  return (
    <CardStructure>
      <SigningStateRow digitalSignatureState={digitalSignatureState} signatureData={signatureData} />
      {signeesComponents}
      <AdditionalButton
        digitalSignatureState={digitalSignatureState}
        shoppingCartId={shoppingCart._id}
        shoppingCart={shoppingCart}
      />
    </CardStructure>
  );
};

export default DigitalSignatureCard;
