/*
  A hook to fetch current user
  Add extra props to the currentUser fragment when you need it
*/
import { useCachedQuery } from 'graphql/utils';
import { version as clientVersion } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { currentUserQuery, sharedPackageVersionQuery } from 'graphql/queries';
import { useEffect } from 'react';
import RefreshTokenService from 'utils/RefreshTokenService';
import useLogout from './useLogout';

const useRefreshUser = (me) => {
  const logout = useLogout();
  useEffect(() => {
    const setIntervalId = setInterval(() => {
      const refreshToken = RefreshTokenService.getRefreshToken();
      if (me?._id)
        try {
          const data = JSON.parse(decodeURIComponent(atob(refreshToken.split('.')[1])));
          if (data._id !== me._id) window.location.reload();
        } catch (e) {
          logout();
        }
    }, 2000);
    return () => {
      clearInterval(setIntervalId);
    };
  }, [logout, me]);
};

let loggedWarning;
export default function useCurrentUser(skip = false) {
  const { loading, error, data, refetch } = useCachedQuery(currentUserQuery, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
    skip: !skip ? undefined : skip,
  });
  const sharedPackageVersionQueryData = useCachedQuery(sharedPackageVersionQuery, { skip: !skip ? undefined : skip });
  const serverVersion = sharedPackageVersionQueryData?.data?.sharedPackageVersion;
  if (
    !loggedWarning &&
    serverVersion &&
    (serverVersion !== clientVersion || [serverVersion, clientVersion].includes('LINKED'))
  ) {
    loggedWarning = true;
    console.error(`client shared package version ${clientVersion},\n server shared package version ${serverVersion}`);
  }
  useRefreshUser(data?.me);
  return [data?.me, loading, error, refetch];
}
