import { gql } from '@apollo/client';
import {
  adminShoppingCartPreferencesFragment,
  userDigitalSignaturePreferencesFragment,
  userEmailPreferencesFragment,
  userMonthlyPaymentPreferencesFragment,
} from 'graphql/fragments';

const userPreferencesQuery = gql`
  query userPreferencesQuery($shoppingCartId: ID, $configDate: DateTime) {
    tenantSettings(shoppingCartId: $shoppingCartId, configDate: $configDate) {
      _id
      _apolloCacheKey
      shoppingCartPreferences {
        ...adminShoppingCartPreferencesFragment
      }
      digitalSignaturePreferences {
        ...userDigitalSignaturePreferencesFragment
      }
      emailPreferences {
        ...userEmailPreferencesFragment
      }
      monthlyPaymentPreferences {
        ...userMonthlyPaymentPreferencesFragment
      }
    }
  }
  ${adminShoppingCartPreferencesFragment}
  ${userDigitalSignaturePreferencesFragment}
  ${userEmailPreferencesFragment}
  ${userMonthlyPaymentPreferencesFragment}
`;

export default userPreferencesQuery;
