import { gql } from '@apollo/client';

const getSigneeSigningLinkMutation = gql`
  mutation getSigneeSigningLinkMutation($shoppingCartId: ID!, $signeeRelatedObjectId: ID!, $signeeEmail: String!) {
    getSigneeSigningLink(
      shoppingCartId: $shoppingCartId
      signeeRelatedObjectId: $signeeRelatedObjectId
      signeeEmail: $signeeEmail
    )
  }
`;

export default getSigneeSigningLinkMutation;
