import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Steps } from 'antd';
import equal from 'fast-deep-equal/es6/react';
import classes from './ResetPasswordForm.module.less';
import { ResetProvider, resetStore } from './context';
import SetNewPasswordStep from './SetNewPasswordStep';
import EnterEmailStep from './EnterEmailStep';

const { Step } = Steps;

function ResetPasswordForm() {
  const [step, setStep] = useState(0);
  const { t } = useTranslation();
  const onNext = useCallback(() => {
    setStep(step + 1);
  }, [step]);
  return (
    <div className={classes.container}>
      <Steps direction="vertical" current={step}>
        <Step title={t('ResetPassword.steps.email')} />
        <Step title={t('ResetPassword.steps.setNewPassword')} />
      </Steps>
      <div className={classes.resetPassword}>
        <ResetProvider value={resetStore}>
          {step === 0 && <EnterEmailStep onNext={onNext} />}
          {step === 1 && <SetNewPasswordStep />}
        </ResetProvider>
      </div>
    </div>
  );
}

export default memo(ResetPasswordForm, equal);
