import { Button as Btn } from 'antd';
import classNames from 'classnames';
import c from './Button.module.less';

const Button = ({ children, onClick, className, icon }) => {
  return (
    <Btn className={classNames(className, c.button)} onClick={onClick}>
      {children}
      {icon && <div className={c.rightIcon}>{icon}</div>}
    </Btn>
  );
};
export default Button;
