import { RichText } from '@JavaScriptSuperstars/kanzleipilot-shared/lib/exports';
import { Modal } from 'antd';
import { useState } from 'react';
import classes from './ServiceEntryModal.module.less';

/**
 * BenefitsBlock component to show the benefit for a service in the service entry modal
 * @param {Object} inputParameters - Input parameters of the component
 * @param {String} inputParameters.benefits - Rich text json for the benefits text
 * @param {String} inputParameters.title - Title for the block
 * @returns {JSX.Element} shows benefits of a service as block
 * @component
 */
const BenefitsBlock = ({ benefits }) => {
  if (!benefits) return null;
  return <span className={classes.benefitsContent}>{RichText.renderRichText(benefits)}</span>;
};

/**
 * ServiceEntryModal component is a modal for details on a selected service for the service overview step
 * @param {Object} inputParameters - Input parameters of the component
 * @param {Object} inputParameters.item - Selected service object
 * @param {React.Ref} inputParameters.modalRef - Reference, which gets the open function to open the modal
 * @returns {JSX.Element} modal to show details on the selected service
 */
const ServiceEntryModal = ({ item, modalRef }) => {
  const [visible, setVisible] = useState(false);

  const closeModal = () => {
    setVisible(false);
  };

  // eslint-disable-next-line no-param-reassign
  modalRef.current = {
    openModal: () => {
      setVisible(true);
    },
  };

  const { texts } = item;

  return (
    <Modal visible={visible} title={texts?.name} footer={null} onCancel={closeModal} className={classes.modal}>
      <BenefitsBlock benefits={texts?.benefits} />
    </Modal>
  );
};

export default ServiceEntryModal;
