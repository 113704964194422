import apollo from 'graphql/apollo';
import { importDocumentTemplatesFromLibraryMutation } from 'graphql/mutations';
import { adminDocumentTemplateListForPageQuery } from 'graphql/queries';

const importDocumentTemplatesFromLibrary = async (documentTemplates) =>
  apollo.mutate({
    mutation: importDocumentTemplatesFromLibraryMutation,
    variables: { input: documentTemplates },
    refetchQueries: [{ query: adminDocumentTemplateListForPageQuery, variables: { isLibrary: false } }],
  });

export default importDocumentTemplatesFromLibrary;
