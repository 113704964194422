import { gql } from '@apollo/client';
import { adminContactFragment } from 'graphql/fragments';

const createContactMutation = gql`
  mutation createContactMutation($contact: ContactInput!) {
    createContact(contact: $contact) {
      ...adminContactFragment
    }
  }
  ${adminContactFragment}
`;

export default createContactMutation;
