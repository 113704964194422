import EmailTemplateSelect from 'components/common/EmailTemplateSelect';

const NotifyTenantSigneesForDigitalSignatureStartTemplateSelect = ({ t }) => {
  return (
    <EmailTemplateSelect
      fieldName="notifyTenantSigneesForDigitalSignatureStartTemplate"
      label={t(
        'admin.Setting.DigitalSignaturePreferences.generalSettings.notifyTenantSigneesForDigitalSignatureStartTemplate.label',
      )}
      tooltip={t(
        'admin.Setting.DigitalSignaturePreferences.generalSettings.notifyTenantSigneesForDigitalSignatureStartTemplate.tooltip',
      )}
      placeholder={t(
        'admin.Setting.DigitalSignaturePreferences.generalSettings.notifyTenantSigneesForDigitalSignatureStartTemplate.placeholder',
      )}
    />
  );
};

export default NotifyTenantSigneesForDigitalSignatureStartTemplateSelect;
