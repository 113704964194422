import { useRowContext } from 'contexts/RowContext';
import equal from 'fast-deep-equal/es6/react';
import { memo } from 'react';
import './DraggableDecoration.less';

function DraggableDecoration({ height = '100%' }) {
  const { isDragging } = useRowContext();
  if (isDragging)
    return (
      <div
        className="draggable-decorator"
        style={{
          height,
        }}
      />
    );
  return null;
}

export default memo(DraggableDecoration, equal);
