import { gql } from '@apollo/client';
import { superAdminTenantFragment } from 'graphql/fragments';

const updateTenantMutation = gql`
  mutation updateTenantMutation($_id: ID!, $modifier: UpdateTenantInput) {
    updateTenant(_id: $_id, modifier: $modifier) {
      ...superAdminTenantFragment
    }
  }
  ${superAdminTenantFragment}
`;

export default updateTenantMutation;
