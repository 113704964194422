import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { EyeOutlined } from '@ant-design/icons';
import { createHtmlPreviewById } from 'graphql/methods';
import { documentModal } from 'components/user/document';
import classes from '../DocumentsCard.module.less';

/**
 * Renders a button that opens a document preview modal when clicked
 * @param {Object} inputParameters - Input parameters of the component
 * @param {string} inputParameters.shoppingCartId - The ID of the shopping cart
 * @param {Object} inputParameters.documentTemplate - The document template object
 * @returns {JSX.Element} The rendered OpenDocumentPreview component
 */
export const OpenDocumentPreview = ({ shoppingCartId, documentTemplate }) => {
  const { t } = useTranslation();
  return (
    <Button
      icon={<EyeOutlined />}
      onClick={() => {
        documentModal({
          value: createHtmlPreviewById({ shoppingCartId, documentTemplate }),
          type: 'html',
        });
      }}
      className={classes.downloadButton}
    >
      {t('viewer.ShoppingCartView.CreatedDocuments.viewPDF')}
    </Button>
  );
};
