import { Layout } from 'antd';
import Logo from 'components/layout/Logo';
import classNames from 'classnames';
import { ThemeProvider } from 'styled-components';
import Progress from 'pages/signDocuments/Progress';
import { useSignDocumentsContext } from 'pages/signDocuments/SignDocumentsContext';
import { useThemeRichEditor } from 'graphql/hooks';
import s from './SignDocumentsLayout.module.less';

const { Content } = Layout;

export default function SignDocumentsLayout({ children, hideProgress, className }) {
  const { page } = useSignDocumentsContext();
  const { theme } = useThemeRichEditor();
  return (
    <ThemeProvider theme={theme}>
      <Layout className={classNames(s.layout, className)}>
        <div className={s.header}>
          <Logo onClick={() => {}} />
          {!hideProgress && <Progress page={page} />}
        </div>
        <Content className={s.content}>{children}</Content>
      </Layout>
    </ThemeProvider>
  );
}
