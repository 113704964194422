import PageContainer from 'components/layout/PageContainer';
import React from 'react';
import ProfileBackendCPUPage from './Page';

export const ProfileBackendCPU = () => {
  return (
    <PageContainer title="Profile Backend CPU" style={{ height: '100%' }}>
      <ProfileBackendCPUPage />
    </PageContainer>
  );
};

export default ProfileBackendCPU;
