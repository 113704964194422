import { gql } from '@apollo/client';
import { adminVariableListFragment } from 'graphql/fragments';

const createVariableMutation = gql`
  mutation createVariableMutation($modifier: VariableInput) {
    createVariable(modifier: $modifier) {
      ...adminVariableListFragment
    }
  }
  ${adminVariableListFragment}
`;

export default createVariableMutation;
