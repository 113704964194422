import { useCachedQuery } from 'graphql/utils';
import { useMutation } from '@apollo/client';
import { Alert, Skeleton, Spin, Card } from 'antd';
import PageContainer from 'components/layout/PageContainer';
import equal from 'fast-deep-equal/es6/react';
import { createShoppingCartMutation, createShoppingCartRevisionMutation, sendEmailMutation } from 'graphql/mutations';
import { memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IoCheckmarkCircle } from 'react-icons/io5';
import { useNavigate, useLocation } from 'react-router-dom';
import { getMessageFromGraphQLError, grabFirstGQLDataResult } from 'utils/helpers';
import cn from 'classnames';
import { getPlaceholderObjectByIdQuery } from 'graphql/queries';
import { RichText } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { pick } from 'lodash';
import { PreviewEmailTemplate } from 'pages/shoppingCartManagement/ShoppingCart/components/SendEmail';
import GraphQLLoadingWrapper from 'components/common/GraphQLLoadingWrapper';
import { FaFile } from 'react-icons/fa';
import DocumentsCard from 'components/common/DocumentsCard';
import NextActions from './NextActions';
import classes from './SendEmailShoppingCartPage.module.less';
import { useEmailStatus } from './hooks';

const skeletons = [...new Array(2)].map((_, i) => i + 1);

const EmailPreview = ({ shoppingCartId, subject, body, signature, bcc, recipients, shouldSendAttachments }) => {
  const { t } = useTranslation();
  const { data, loading, error } = useCachedQuery(getPlaceholderObjectByIdQuery, {
    variables: {
      _id: shoppingCartId,
    },
  });

  const placeholderObject = useMemo(
    () =>
      data && {
        ...JSON.parse(grabFirstGQLDataResult(data)),
      },
    [data],
  );
  return (
    <>
      {error ? <Alert type="error" message={getMessageFromGraphQLError(error)} /> : null}
      {skeletons.map((k) => (
        <Skeleton title loading={!data && loading} active key={k} />
      ))}
      {data && !loading ? (
        <RichText.PlaceholderContextProvider value={placeholderObject}>
          <Card title={t('user.ShoppingCart.SendEmail.preview')}>
            <PreviewEmailTemplate
              subject={subject}
              body={body}
              signature={signature}
              bcc={bcc}
              recipients={recipients}
            />
            {shouldSendAttachments && (
              <DocumentsCard
                shoppingCartId={shoppingCartId}
                icon={<FaFile />}
                title={t('user.ShoppingCart.SendEmail.attachments')}
              />
            )}
          </Card>
        </RichText.PlaceholderContextProvider>
      ) : null}
    </>
  );
};
const useCreateShoppingCart = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location?.state;
  const [createShoppingCart, { loading, error, called }] = useMutation(
    state?.data?.prevCartId ? createShoppingCartRevisionMutation : createShoppingCartMutation,
  );
  useEffect(() => {
    if (state && !state.shoppingCartId && !state.data?.calledShoppingCart && !called) {
      createShoppingCart({ variables: state.data }).then(({ data }) => {
        const shoppingCartId = grabFirstGQLDataResult(data)._id;
        navigate(location.pathname, {
          replace: true,
          state: {
            ...state,
            shoppingCartId,
          },
        });
      });
      navigate(location.pathname, {
        replace: true,
        state: {
          ...state,
          calledShoppingCart: true,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [state?.shoppingCartId, loading, error];
};
const EmailPreviewMemo = memo(EmailPreview, equal);
function SendEmailShoppingCartPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location?.state;
  const [shoppingCartId, shoppingCartLoading, shoppingCartError] = useCreateShoppingCart();
  const [sendEmail, { loading: emailLoading, error: emailError, called: emailCalled }] = useMutation(sendEmailMutation);
  const {
    status,
    loading: loadingStatus,
    error: statusError,
  } = useEmailStatus({
    _id: state?.sendEmailStatusId,
  });
  useEffect(
    () => {
      if (state && !state.called && !emailCalled && shoppingCartId) {
        sendEmail({
          variables: {
            shoppingCartId,
            emailProps: {
              subject: state?.subject,
              body: state?.body,
              signature: state?.signature,
              shouldSendAttachments: state?.shouldSendAttachments,
            },
            emailRecipients: state?.recipients,
          },
        }).then(({ data }) => {
          const sendEmailStatusId = data?.sendEmail;
          navigate(location.pathname, {
            replace: true,
            state: {
              ...state,
              sendEmailStatusId,
            },
          });
        });
        navigate(location.pathname, {
          replace: true,
          state: {
            ...state,
            called: true,
          },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shoppingCartId],
  );
  const isLoadingEmail = shoppingCartLoading || emailLoading || loadingStatus || status === 'generating';
  const error = [shoppingCartError, emailError, emailCalled && statusError].filter(Boolean)[0];
  return (
    <PageContainer>
      {error ? (
        <Alert type="error" message={t(`${getMessageFromGraphQLError(error)}`)} />
      ) : (
        (emailCalled || state?.called || state?.calledShoppingCart) && (
          <Spin spinning={isLoadingEmail}>
            <Alert
              message={t(`user.SendShoppingCartPage.${isLoadingEmail ? 'loadingMessage' : 'successfulMessage'}`)}
              type={isLoadingEmail ? 'info' : 'success'}
              showIcon
              className={cn(classes.alert, isLoadingEmail && 'alert-info')}
              icon={isLoadingEmail || <IoCheckmarkCircle type="success" size={40} />}
              closable
            />
          </Spin>
        )
      )}
      <div className="xs-mt-20">
        <GraphQLLoadingWrapper
          data={shoppingCartId}
          loading={shoppingCartLoading}
          error={shoppingCartError}
          countSkeletons={4}
        >
          <NextActions shoppingCartId={shoppingCartId} />
          {state ? (
            <EmailPreviewMemo
              shoppingCartId={shoppingCartId}
              {...pick(state, ['body', 'subject', 'signature', 'bcc', 'recipients', 'shouldSendAttachments'])}
            />
          ) : null}
        </GraphQLLoadingWrapper>
      </div>
    </PageContainer>
  );
}
export default memo(SendEmailShoppingCartPage, equal);
