import { gql } from '@apollo/client';
import apollo from 'graphql/apollo';
import { adminInputFieldFragment } from 'graphql/fragments';

const itemInputFieldsFragment = gql`
  fragment itemInputFieldsFragment on CategoryItem {
    inputFields {
      ...adminInputFieldFragment
    }
  }
  ${adminInputFieldFragment}
`;

function moveItemInputFieldsCache({ _id, inputFields }) {
  apollo.writeFragment({
    id: `CategoryItem___${_id}`,
    fragment: itemInputFieldsFragment,
    fragmentName: 'itemInputFieldsFragment',
    data: { inputFields },
  });
}

export default moveItemInputFieldsCache;
