// import * as Sentry from '@sentry/react';
import settings from 'config/settings';
// import initGA from './init-ga';
import initSentry from '../sentry';
import './yup';

const signingPageRegex = /(\/signing)\/?([^/]*)/;

window.settings = settings; // dev
const insertScript = (html, { src, isAsync } = {}) => {
  const script = document.createElement('script');
  script.innerHTML = html;
  if (isAsync) script.async = true;
  if (src) script.src = src;
  document.body.appendChild(script);
};

const addGoogleAnalytics = () => {
  insertScript('', { isAsync: true, src: 'https://www.googletagmanager.com/gtag/js?id=G-LXPN06Z63P' });
  insertScript(`
  window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-LXPN06Z63P');`);
};

const addHotjarAnalytics = () => {
  // <!-- Hotjar Tracking Code for https://tour.kanzleipilot.de -->
  const html = `
      (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:2358340,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
  insertScript(html);
};

/**
 * Script initialization
 */
export default function startup() {
  const isSigningPage = signingPageRegex.test(window.location.href);
  if (settings.isProduction && !isSigningPage) {
    initSentry();
    addGoogleAnalytics();
    addHotjarAnalytics();
  }
}
