import { gql } from '@apollo/client';

const adminCompanyTypeFragment = gql`
  fragment adminCompanyTypeFragment on CompanyType {
    _id
    isSelfBooker
    label
  }
`;

export default adminCompanyTypeFragment;
