import { gql } from '@apollo/client';
import { adminTenantSettingsFragment } from 'graphql/fragments';

const adminGlobalTenantSettingsQuery = gql`
  query adminTenantSettingsQuery {
    tenantSettings {
      ...adminTenantSettingsFragment
    }
  }
  ${adminTenantSettingsFragment}
`;

export default adminGlobalTenantSettingsQuery;
