import apollo from 'graphql/apollo';
import { updateEmailMutation } from 'graphql/mutations';

const updateEmail = async (email) =>
  apollo.mutate({
    mutation: updateEmailMutation,
    variables: { email },
  });

export default updateEmail;
