import { gql } from '@apollo/client';

const adminPersonFragment = gql`
  fragment adminPersonFragment on Person {
    _id
    name
    position
    signature
  }
`;

export default adminPersonFragment;
