import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card, Skeleton } from 'antd';
import equal from 'fast-deep-equal/es6/react';
import i18n from 'i18n';
import { TableMemo } from 'memo';
import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import inputConfirmModal from 'utils/inputConfirmModal';
import { find } from 'lodash';
import { activateUser, resetCustomSignatureImage, updateUser, uploadFile } from 'graphql/methods';
import toast from 'utils/toast';
import deleteUser from 'graphql/methods/admin/user/deleteUser';
import { useCachedQuery } from 'graphql/utils';
import { adminUsersInTenantQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';
import confirmModal from 'utils/confirmModal';
import stateInvitation from 'constants/stateInvitation';
import { BsFillPersonPlusFill } from 'react-icons/bs';
import { fullNameFromUser } from 'components/layout/CurrentUserDropdown';
import roles from 'constants/roles';
import Switch from 'components/common/Switch';
import { PasswordResetButton, ResendInvitationButton } from 'pages/superAdmin/ManageTenants/TenantList';
import { UserForm, userInvitationSchema } from './UserInvitation';
import classes from './UserList.module.less';

const skeletons = [...new Array(2)].map((_, i) => i + 1);
const deleteUserWithConfirmation = ({ _id }) =>
  confirmModal({
    okText: i18n.t('admin.deleteUserConfirmation.ok'),
    cancelText: i18n.t('admin.deleteUserConfirmation.cancel'),
    okType: 'danger',
    onOk: async () => {
      deleteUser({ userId: _id }).catch(() => toast.error(i18n.t('common.toast.errors.user.delete')));
    },
    title: i18n.t('admin.deleteUserConfirmation.title'),
  });
const editUser = (user) =>
  inputConfirmModal({
    formContent: () => <UserForm showUploadSignature />,
    fields: [],
    onSubmit: async ({ firstName, lastName, position, email, role, customSignatureImage }) => {
      if (customSignatureImage && typeof customSignatureImage !== 'string' && customSignatureImage !== null) {
        await uploadFile({
          name: `User_CustomSignature_${new Date().toISOString()}`,
          parentId: user._id,
          parentType: 'user_custom_signature_image',
          size: customSignatureImage.size,
          file: customSignatureImage,
        });
      } else if (
        customSignatureImage &&
        typeof customSignatureImage === 'string' &&
        customSignatureImage === 'reset' &&
        user?.customSignatureImage
      ) {
        await resetCustomSignatureImage(user._id);
      }
      await updateUser({
        email,
        role,
        profile: {
          firstName,
          lastName,
          position,
        },
        userId: user._id,
      });
    },
    value: {
      firstName: user?.profile?.firstName || '',
      lastName: user?.profile?.lastName || '',
      position: user?.profile?.position || '',
      email: user?.email || '',
      role: user?.role || '',
      customSignatureImage: user?.customSignatureImage || null,
    },
    headerText: i18n.t('admin.ManageUsers.modal.editUser'),
    okText: i18n.t('common.ok'),
    cancelText: i18n.t('common.cancel'),
    validationSchema: userInvitationSchema,
    forceMultiField: true,
    width: '600px',
    errorResolver: {
      Duplicated: ['email', i18n.t('admin.ManageUsers.duplicatedErrorMessage')],
    },
  });

export const userTableColumns = ({ t, onEdit }) =>
  [
    {
      title: t('admin.ManageUsers.userList.fields.name'),
      render: fullNameFromUser,
    },
    {
      title: t('admin.ManageUsers.userList.fields.position'),
      render: (u) => u?.profile?.position || null,
      key: 'profile.position',
    },
    {
      title: t('admin.ManageUsers.userList.fields.email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('admin.ManageUsers.userList.fields.role'),
      dataIndex: 'role',
      key: 'role',
      render: (role) => t(`common.roles.${role}`),
    },
    {
      title: t('admin.ManageUsers.userList.fields.status'),
      dataIndex: 'state',
      key: 'state',
      render: (state) => t(`common.stateInvitation.${state}`),
    },
    {
      key: 'actions',
      width: 64,
      fixed: 'right',
      render: (user) => {
        const { _id, state, role } = user;
        return (
          <>
            {state !== stateInvitation.INACTIVE && (
              <>
                <Button
                  className="ant-btn-default"
                  type="danger"
                  ghost
                  icon={<EditOutlined />}
                  onClick={() => {
                    onEdit(_id);
                  }}
                />{' '}
                {role !== roles.ROOT_ADMIN ? (
                  <Button
                    className="ant-btn-default"
                    type="danger"
                    ghost
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      deleteUserWithConfirmation({ _id });
                    }}
                  />
                ) : null}{' '}
              </>
            )}
            {state === stateInvitation.ACTIVE && <PasswordResetButton _id={_id} />}
            {state === stateInvitation.INVITATION_SEND && <ResendInvitationButton _id={_id} />}
            {state === stateInvitation.INACTIVE && (
              <Button
                className="ant-btn-default"
                type="danger"
                ghost
                icon={
                  <span className="anticon">
                    <BsFillPersonPlusFill size={20} />
                  </span>
                }
                onClick={() => {
                  activateUser({ userId: `${_id}` })
                    .then(() =>
                      toast.success(t('common.toast.success.activateInvitation', { name: fullNameFromUser(user) })),
                    )
                    .catch(() => toast.error(t('common.toast.errors.activateInvitation')));
                }}
              />
            )}
          </>
        );
      },
    },
  ].filter(Boolean);

function UserList({ query = adminUsersInTenantQuery, getColumns = userTableColumns }) {
  const [showInactiveUsers, setShowInactiveUsers] = useState(false);
  const { data, loading } = useCachedQuery(query);
  const users = useMemo(() => {
    return grabFirstGQLDataResult(data) || [];
  }, [data]);
  const filterUsers = useMemo(() => {
    if (!users || !users.length) return users;
    if (showInactiveUsers) return users;
    return users.filter((user) => user.state !== 'INACTIVE');
  }, [users, showInactiveUsers]);
  const { t } = useTranslation();
  const onEdit = useCallback(
    (_id) => {
      editUser(find(users, { _id }));
    },
    [users],
  );
  return (
    <Card title={t('admin.ManageUsers.userList.title')}>
      <Switch
        label="Zeige inaktive Benutzer an"
        onChange={() => setShowInactiveUsers((currentVal) => !currentVal)}
        checked={showInactiveUsers}
        className={classes.inactiveUserFilterSwitch}
      />
      {!data && loading ? (
        skeletons.map((k) => <Skeleton title loading active key={k} />)
      ) : (
        <TableMemo
          rowKey={({ _id }) => _id}
          bordered
          dataSource={filterUsers}
          columns={getColumns({ t, onEdit })}
          pagination={false}
          scroll={{ x: 700 }}
        />
      )}
    </Card>
  );
}

export default memo(UserList, equal);
