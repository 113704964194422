import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { PrinterOutlined } from '@ant-design/icons';
import { usePrintPdf } from 'pages/shoppingCartManagement/ShoppingCartSuccessful/hooks';
import classes from '../DocumentsCard.module.less';
import { formatStateDownload } from './formatStateDownload';

/**
 * Component that renders a button to print a document
 * @param {Object} inputParameters - Input parameters of the component
 * @param {Object} inputParameters.document - The document to print
 * @param {Array} inputParameters.generatedFiles - The generated files to print
 * @param {Function} inputParameters.addGeneratedPdf - The function to add the generated PDF
 * @returns {JSX.Element}
 */
export const PrintDocument = ({ document, generatedFiles, addGeneratedPdf }) => {
  const { t } = useTranslation();
  const [openPrintPdfView, { loading, progress, stateLoading }] = usePrintPdf({
    document,
    generatedFiles,
    addGeneratedPdf,
  });
  return (
    <Button
      type="primary"
      ghost
      loading={loading || document.isGenerating}
      disabled={loading || document.isGenerating}
      onClick={openPrintPdfView}
      className={classes.downloadButton}
    >
      {formatStateDownload({
        stateLoading,
        progress,
        inactive: t('user.PrintShoppingCartPage.print'),
        InactiveIcon: PrinterOutlined,
      })}
    </Button>
  );
};
