import { Button, Modal } from 'antd';

const OptionButtonBar = (options) => {
  return options.map((option) => {
    return (
      <Button
        onClick={option.handleClick}
        key={option.text}
        className={option.className}
        icon={option.icon}
        disabled={option.disabled}
      >
        {option.text}
      </Button>
    );
  });
};

/*
  SelectionModal: Modal to give user the option to select on option out of multiple to go further in the users flow
  @handleCancel: func: Handles behaviour for clicking outside of the modal
  @title: string: Add title for modal
  @subtitle: string: Add subtitle for modal
  @visible: boolean: Switch visibility of the modal on and off
  @className: string: Name for a css class to set for example the width of the complete modal
  @classNameContent: string: Name for a css class to set for example the min-height of the content area
  @children: Array[ReactNode]: Represents inner area of the modal (Will be put to content area of modal) 
  @options: Array[Object]:
    @handleClick: func: Handles the behaviour after clicking on the option
    @text: string: Button title for this option
    @className: string: Name for a css class for this option
    @icon: ReactNode: Adds an icon to the option button
    @disabled: boolean: Disables option
*/

const SelectionModal = ({ handleCancel, options, title, subtitle, children, visible, className, classNameContent }) => {
  return (
    <Modal
      visible={visible}
      title={
        <>
          {title}
          {subtitle || null}
        </>
      }
      onCancel={handleCancel}
      footer={OptionButtonBar(options)}
      className={className}
    >
      <div className={classNameContent}>{children}</div>
    </Modal>
  );
};

export default SelectionModal;
