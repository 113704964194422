import c from './Note.module.less';

const Note = ({ title, children }) => {
  1;

  return (
    <div className={c.note}>
      {title && <div className={c.title}>{title}</div>}
      <div>{children}</div>
    </div>
  );
};

export default Note;
