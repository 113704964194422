import React, { useMemo } from 'react';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { useCachedQuery } from 'graphql/utils';
import { QueryErrorAlert } from 'components/common/ErrorComponent';
import { adminCanImportCategoriesQuery, adminCanImportDocumentTemplatesQuery, onboardingQuery } from 'graphql/queries';
import { grabFirstGQLDataResult, toastGraphQLError } from 'utils/helpers';
import { completeOnboarding } from 'graphql/methods';
import { useNavigate } from 'react-router-dom';
import routePaths from 'router/route-paths';
import { onboardingDataTransformer, steps } from './Onboarding.utils';
import { OnboardingContextProvider, useOnboardingContext } from './OnboardingContext';
import OnboardingLayout from './OnboardingLayout';

const Onboarding = () => {
  const { currentIndex, currentStep } = useOnboardingContext();
  return (
    <OnboardingLayout currentIndex={currentIndex}>
      <currentStep.Content />
    </OnboardingLayout>
  );
};
export default function OnboardingWithContext() {
  const { data, loading, error } = useCachedQuery(onboardingQuery);

  const {
    data: canImportCategories,
    loading: loadingCanImportCategories,
    error: errorCanImportCategories,
  } = useCachedQuery(adminCanImportCategoriesQuery);
  const {
    data: canImportDocumentTemplates,
    loading: loadingCanImportDocumentTemplates,
    error: errorCanImportDocumentTemplates,
  } = useCachedQuery(adminCanImportDocumentTemplatesQuery);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const navigate = useNavigate();

  const stepsForOnboarding = useMemo(() => {
    let stepsToUse = [...steps];
    if (canImportCategories && !grabFirstGQLDataResult(canImportCategories))
      stepsToUse = stepsToUse.filter((step) => step.titleI18nKey !== 'onboarding.pickCategories.stepTitle');
    if (canImportDocumentTemplates && !grabFirstGQLDataResult(canImportDocumentTemplates))
      stepsToUse = stepsToUse.filter((step) => step.titleI18nKey !== 'onboarding.pickDocuments.stepTitle');
    return stepsToUse;
  }, [canImportCategories, canImportDocumentTemplates]);

  const onComplete = React.useCallback(
    async (values) => {
      const transformed = onboardingDataTransformer.transformFormValuesToGraphQLParams(values);
      setIsSubmitting(true);
      try {
        localStorage.setItem('onboardingSteps', JSON.stringify(stepsForOnboarding));
        await completeOnboarding(transformed);
        localStorage.setItem('onboardingSteps', JSON.stringify(stepsForOnboarding));
        navigate(routePaths.tenantOnboardingCompleted);
        window.location.reload();
      } catch (err) {
        toastGraphQLError(err);
      } finally {
        setIsSubmitting(false);
      }
    },
    [navigate, stepsForOnboarding],
  );

  if (loading || loadingCanImportCategories || loadingCanImportDocumentTemplates) return <LoadingOverlay />;
  if (
    (error && !data) ||
    (errorCanImportCategories && !canImportCategories) ||
    (errorCanImportDocumentTemplates && !canImportDocumentTemplates)
  )
    return <QueryErrorAlert error={error || errorCanImportCategories || errorCanImportDocumentTemplates} />;
  const formValues = onboardingDataTransformer.transformGraphQLParamsToFormValues(grabFirstGQLDataResult(data));

  return (
    <OnboardingContextProvider
      formValues={formValues}
      steps={stepsForOnboarding}
      onComplete={onComplete}
      isSubmitting={isSubmitting}
      setIsSubmitting={setIsSubmitting}
    >
      <Onboarding />
    </OnboardingContextProvider>
  );
}
