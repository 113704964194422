import { FaEye } from 'react-icons/fa';
import SignDocumentsLayout from '../../../components/layouts/SignDocumentsLayout';
import { Sans30px400, Sans35px600 } from '../Text/Text';
import { Br100, Br150, Br200 } from '../Text/Br';
import NextButton from '../NextButton';
import PricingOverview from '../PricingOverview';
import AdditionalService from '../AdditionalService';

const benefits = [
  { _id: '1', text: 'benefit 1' },
  { _id: '2', text: 'benefit 2' },
  { _id: '3', text: 'benefit 3' },
];
const items = [
  {
    _id: '1',
    benefits,
    name: 'Verfahrensdokumentation',
    pricing: '300',
  },
  {
    _id: '2',
    benefits,
    name: 'Monatliches Strategie-Gespräch',
    pricing: '300',
  },
];

const ReviewServices = ({ setPage }) => {
  return (
    <SignDocumentsLayout>
      <Sans35px600 icon={<FaEye />}>Additional services</Sans35px600>
      <Br100 />
      <Sans30px400>Do you want to aditionally book Bookmarked Items?</Sans30px400>
      <Br150 />
      {items.map((item, index) => (
        <>
          {index === 0 ? null : <Br100 />}
          <AdditionalService key={item._id} item={item} />
        </>
      ))}
      <Br150 />
      <PricingOverview />
      <Br200 />
      <NextButton
        onClick={() => {
          setPage('viewAndSignDocuments');
        }}
      >
        Next
      </NextButton>
    </SignDocumentsLayout>
  );
};
export default ReviewServices;
