import DocumentTemplateConfiguration from './DocumentTemplateConfiguration';

export default function DocumentTemplate(props) {
  return <DocumentTemplateConfiguration {...props} />;
}

// for (let i = 0; i < 9; i++) {
//   const waitForNextRender = () => new Promise(res => window.setTimeout(res, 500))
//   const extra = document.querySelector('.ant-card-extra button').click()
//   await waitForNextRender();
//   const button = [...document.querySelectorAll('.ant-modal-body button:nth-child(2)')][i].click();
//   await waitForNextRender();
// }
