import apollo from 'graphql/apollo';
import { deleteStaticItemMutation } from 'graphql/mutations';
import i18n from 'i18n';
import { find } from 'lodash';
import confirmModal from 'utils/confirmModal';
import { clientDelete, toastGraphQLError } from 'utils/helpers';
import toast from 'utils/toast';

const deleteStaticItem = async ({ _id, categoryListQuery }) =>
  apollo.mutate({
    mutation: deleteStaticItemMutation,
    variables: { _id },
    update: (cache) => {
      const cachedData = cache.readQuery({ query: categoryListQuery });
      const { categories } = cachedData;
      const category = categories.find(({ staticItems }) => find(staticItems, { _id }));
      if (!category) return;
      const newStaticItems = clientDelete({ array: category.staticItems, _id });
      cache.writeQuery({
        query: categoryListQuery,
        data: {
          categories: cachedData.categories.map((c) =>
            find(c.staticItems, { _id }) ? { ...c, staticItems: newStaticItems } : c,
          ),
        },
      });
    },
  });
export const deleteStaticItemWithConfirmation = ({ _id, categoryListQuery }) =>
  confirmModal({
    okText: i18n.t('admin.deleteStaticItemConfirmation.ok'),
    cancelText: i18n.t('admin.deleteStaticItemConfirmation.cancel'),
    okType: 'danger',
    onOk: async () => deleteStaticItem({ _id, categoryListQuery }).then(toast.successDefault).catch(toastGraphQLError),
    title: i18n.t('admin.deleteStaticItemConfirmation.title'),
  });
export default deleteStaticItem;
