import { Button } from 'antd';
import { UndoOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { resetTenantSettings } from 'graphql/methods';
import toast from 'utils/toast';
import confirmModal from 'utils/confirmModal';
import i18n from 'i18n';

/**
 * ResetPreferencesButton component is the reset button for the digital signature preferences
 * @returns {JSX.Element} page button for the digital signature preferences page
 * @component
 */
const ResetPreferencesButton = () => {
  const { t } = useTranslation();
  const onResetSettings = () => {
    const onConfirm = () =>
      resetTenantSettings({ type: 'digitalSignaturePreferences' })
        .then(() => toast.successDefault())
        .catch((e) => console.error(e) || toast.error(i18n.t('admin.Setting.ShoppingCartPreferences.errors.reset')));

    confirmModal({
      cancelText: i18n.t('common.cancel'),
      okText: i18n.t('common.ok'),
      okType: 'danger',
      onOk: onConfirm,
      title: i18n.t('admin.Setting.DigitalSignaturePreferences.resetConfirm'),
    });
  };
  return (
    <Button icon={<UndoOutlined />} onClick={onResetSettings}>
      {t('admin.Setting.DigitalSignaturePreferences.resetButton')}
    </Button>
  );
};

export default ResetPreferencesButton;
