import useSigningGraphqlContext from 'hooks/signing/useSigningGraphqlContext';
import signingShoppingCartQuery from 'graphql/queries/signing/signingShoppingCartQuery';
import useCurrentlyCachedQuery from 'hooks/common/useCurrentlyCachedQuery';

const useSigningShoppingCart = () => {
  const signingGraphlQlContext = useSigningGraphqlContext();
  const { loading, error, data, refetch } = useCurrentlyCachedQuery(
    signingShoppingCartQuery,
    {
      context: signingGraphlQlContext,
      skip: !signingGraphlQlContext?.headers,
    },
    120,
  );
  const noContextError = !signingGraphlQlContext?.headers ? { message: 'Not authorized' } : undefined;
  return { error: error || noContextError, loading, shoppingCart: data?.shoppingCartOfSigningContext, refetch };
};

export default useSigningShoppingCart;
