import { gql } from '@apollo/client';

const adminStatusFragment = gql`
  fragment adminStatusFragment on Status {
    _id
    name
    type
    isDefault
    order
  }
`;

export default adminStatusFragment;
