import apollo from 'graphql/apollo';
import { resetTenantSettingsMutation } from 'graphql/mutations';
import { adminTenantSettingsQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';

const resetTenantSettings = async ({ type }) =>
  apollo.mutate({
    mutation: resetTenantSettingsMutation,
    variables: { type },
    update: (cache, { data }) => {
      const settings = grabFirstGQLDataResult(data);
      cache.writeQuery({
        query: adminTenantSettingsQuery,
        data: {
          tenantSettings: settings,
        },
      });
    },
  });

export default resetTenantSettings;
