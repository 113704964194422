import apollo from 'graphql/apollo';
import { createItemMutation } from 'graphql/mutations';
import { pick } from 'lodash';
import { grabFirstGQLDataResult } from 'utils/helpers';

const createCategoryItem = async ({ categoryId, categoryListQuery, modifier }) =>
  apollo.mutate({
    mutation: createItemMutation,
    variables: {
      categoryId,
      item: pick(modifier, [
        'benefits',
        'calculationMode',
        'highlightBindingness',
        'infoText',
        'minPrice',
        'name',
        'notesForFeeAgreement',
        'notesToEmployee',
        'paymentInterval',
        'pleaseNote',
        'pricingFormula',
        'scalePricingFormulaTitle',
        'scaleTitle',
        'scales',
        'scalesEnabled',
      ]),
    },
    update: (cache, { data }) => {
      const result = grabFirstGQLDataResult(data);
      const cachedData = cache.readQuery({ query: categoryListQuery });
      cache.writeQuery({
        query: categoryListQuery,
        data: {
          categories: cachedData.categories.map((c) =>
            c._id === categoryId ? { ...c, items: [...(c.items || []), result] } : c,
          ),
        },
      });
    },
  });
export default createCategoryItem;
