import { gql } from '@apollo/client';
import { adminDiscountFragment } from 'graphql/fragments';

const createDiscountMutation = gql`
  mutation createDiscountMutation($categoryId: ID!, $discount: DiscountInput!) {
    createCategoryDiscount(categoryId: $categoryId, discount: $discount) {
      ...adminDiscountFragment
    }
  }
  ${adminDiscountFragment}
`;

export default createDiscountMutation;
