import { FaSignature } from 'react-icons/fa';
import SignDocumentsLayout from '../../../components/layouts/SignDocumentsLayout';
import c from './Hello.module.less';
import { Sans43px600 } from '../Text/Text';
import WhiteButton from '../WhiteButton';

const Hello = ({ setPage }) => {
  return (
    <SignDocumentsLayout hideProgress className={c.layout}>
      <div className={c.helloContainer}>
        <FaSignature />
        <Sans43px600 textStyle={{ color: 'unset' }}>
          View and Sign your
          <br />
          documents
        </Sans43px600>
        <WhiteButton
          onClick={() => {
            setPage('confirmIdentity');
          }}
        />
      </div>
    </SignDocumentsLayout>
  );
};
export default Hello;
