import { gql } from '@apollo/client';
import { adminEmailPreferencesFragment } from 'graphql/fragments';

const adminEmailPreferencesQuery = gql`
  query adminEmailPreferencesQuery {
    tenantSettings {
      _id
      emailPreferences {
        ...adminEmailPreferencesFragment
      }
    }
  }
  ${adminEmailPreferencesFragment}
`;

export default adminEmailPreferencesQuery;
