import { useTranslation } from 'react-i18next';
import EmailTemplateSelect from 'components/common/EmailTemplateSelect';

/**
 * NotifyTenantSigneesAboutOpenBySigneeTemplateSelect component is the email template select for the notify tenant signees about that a signee opened its signing process function
 * @returns {JSX.Element} email template select for the additional notification settings card in the digital signature preferences page
 * @component
 */
const NotifyTenantSigneesAboutOpenBySigneeTemplateSelect = () => {
  const { t } = useTranslation();
  return (
    <EmailTemplateSelect
      fieldName="notifyTenantSigneesAboutOpenBySigneeTemplate"
      label={t(
        'admin.Setting.DigitalSignaturePreferences.additionalNotificationSettings.notifyTenantSigneesAboutOpenBySigneeTemplate.label',
      )}
      tooltip={t(
        'admin.Setting.DigitalSignaturePreferences.additionalNotificationSettings.notifyTenantSigneesAboutOpenBySigneeTemplate.tooltip',
      )}
      placeholder={t(
        'admin.Setting.DigitalSignaturePreferences.additionalNotificationSettings.notifyTenantSigneesAboutOpenBySigneeTemplate.placeholder',
      )}
    />
  );
};

export default NotifyTenantSigneesAboutOpenBySigneeTemplateSelect;
