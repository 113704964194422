import { useMemo } from 'react';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import Avatar from 'react-avatar';
import { Dropdown, Menu } from 'antd';
import useLogout from 'hooks/auth/useLogout';
import { AuditOutlined, LogoutOutlined } from '@ant-design/icons';
import { useTranslation, Trans } from 'react-i18next';
import i18n from 'i18n';
import { Link } from 'react-router-dom';
import routePaths from 'router/route-paths';

export const fullNameFromUser = (user) => {
  if (!user) return i18n.t('CurrentUserDropdown.noUserName');
  if (user.fullName) return user.fullName;
  const { profile } = user;
  if (!profile?.firstName && !profile?.lastName) return i18n.t('CurrentUserDropdown.noUserName');
  if (profile?.firstName && profile?.lastName) return `${profile.firstName} ${profile.lastName}`;
  return profile?.firstName || profile?.lastName;
};
export const UserAvatar = ({ user }) => {
  return (
    <Avatar className="cursor-pointer" name={fullNameFromUser(user)} size={32} maxInitials={2} round fgColor="white" />
  );
};
export default function CurrentUserDropdown({ handleMenuClick = () => {} }) {
  const [me] = useCurrentUser();
  const logout = useLogout();
  const { t } = useTranslation();

  const menu = useMemo(
    () => (
      <Menu onClick={handleMenuClick}>
        <Menu.Item>
          <Trans i18nKey="CurrentUserDropdown.loggedInAs" name={fullNameFromUser(me)}>
            Logged in as <b>{{ name: fullNameFromUser(me) }}</b>
          </Trans>
        </Menu.Item>
        <Menu.Item>
          <Trans i18nKey="CurrentUserDropdown.role" role={me?.role}>
            Role: <b>{{ role: t(`common.roles.${me?.role}`) }}</b>
          </Trans>
        </Menu.Item>
        {me?.locale !== 'de' ? (
          <Menu.Item
            onClick={() => {
              i18n.changeLanguage(i18n.language === 'en' ? 'de' : 'en');
            }}
          >
            <Trans i18nKey="CurrentUserDropdown.locale">
              Locale: <b>{i18n.language}</b>
            </Trans>
          </Menu.Item>
        ) : null}
        <Menu.Divider />
        <Menu.Item key={routePaths.accountSetting} title="">
          <Link to={routePaths.accountSetting}>
            <AuditOutlined />
            {t('NavBar.accountSetting.title')}
          </Link>
        </Menu.Item>
        <Menu.Item onClick={logout}>
          <LogoutOutlined />
          {t('CurrentUserDropdown.logout')}
        </Menu.Item>
      </Menu>
    ),
    [me, t, logout, handleMenuClick],
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight" trigger="click">
      <div>
        <UserAvatar user={me} />
      </div>
    </Dropdown>
  );
}
