import { useMemo } from 'react';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { ComparisonTypes } from 'constants/shoppingCart';
import useComparingData from './useComparingData';
import { applyGlobalToIndependent } from './utils';

const variableComparedProps = ['value'];

const useComparingVariables = ({ shoppingCartId, query }) => {
  const { global, independent, loading, error } = useComparingData({
    queries: [query],
    shoppingCartId,
  });

  const variables = useMemo(() => {
    // region TODO: enable comparison feature
    //  if (!shoppingCartId && global) return grabFirstGQLDataResult(global).variables;
    // endregion
    if (global)
      return grabFirstGQLDataResult(global).variables.map((variable) => {
        if (variable.type !== 'text') {
          return { ...variable, value: parseFloat(variable.value) };
        }
        return variable;
      });

    if (!global || !independent) return null;
    const globalVariables = grabFirstGQLDataResult(global);
    const independentVariables = grabFirstGQLDataResult(independent);
    return applyGlobalToIndependent({
      disableComparisonTypes: [ComparisonTypes.NEW],
      independent: independentVariables.variables,
      global: globalVariables.variables,
      comparedProps: variableComparedProps,
    });
  }, [global, independent]);

  return { loading, error, data: variables };
};

export default useComparingVariables;
