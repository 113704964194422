import LoadingOverlay from 'components/common/LoadingOverlay';
import { useCachedQuery } from 'graphql/utils';
import SignDocuments from 'pages/signDocuments/SignDocuments';
import { gql } from '@apollo/client';

const getFrontendRouteQuery = gql`
  query getFrontendRoute($_id: ID!) {
    getFrontendRoute(_id: $_id) {
      _id
      props
      type
    }
  }
`;
const componentMap = {
  signDocumentsRoute: SignDocuments,
};
const CustomFrontendRouter = ({ match }) => {
  const { loading, data } = useCachedQuery(getFrontendRouteQuery, { variables: { _id: match.params.id } });
  const { props, type } = data?.getFrontendRoute || {};
  if (loading) return <LoadingOverlay />;

  const Component = componentMap[type];
  if (!Component) return null;
  return <Component {...props} />;
};

export default CustomFrontendRouter;
