import apollo from 'graphql/apollo';
import { updateDefaultTemplatesMutation } from 'graphql/mutations';
import { adminDefaultTemplatesQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';

const updateDefaultTemplates = async (digitalSignatureDefaultTemplates) => {
  const mutationProperties = {
    mutation: updateDefaultTemplatesMutation,
    variables: { digitalSignatureDefaultTemplates },
    refetchQueries: [{ query: adminDefaultTemplatesQuery }],
    update: (cache, { data }) => {
      const settings = grabFirstGQLDataResult(data);
      cache.writeQuery({
        query: adminDefaultTemplatesQuery,
        data: {
          getDigitalSignatureDefaultTemplates: settings,
        },
      });
    },
  };
  return apollo.mutate(mutationProperties);
};

export default updateDefaultTemplates;
