import { gql } from '@apollo/client';
import { warningFragment } from 'graphql/fragments';

const getWarningsQuery = gql`
  query getWarningsQuery($types: [WarningType]) {
    getWarnings(types: $types) {
      ...warningFragment
    }
  }
  ${warningFragment}
`;

export default getWarningsQuery;
