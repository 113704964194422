import formattedInputValue from 'pages/shoppingCartManagement/ShoppingCartView/Categories/components/InputFieldsValuePreparation';
import { useTranslation } from 'react-i18next';
import CollapsableCard from '../CollapsableCard';
import ServiceEntry from './ServiceEntry/ServiceEntry';
import classes from './CategoryOverview.module.less';
import KeyValueBlock from './KeyValueBlock';

/**
 * CategoryOverview component to show selected category infos on the service overview step
 * @param {Object} inputParameters - Input parameters of the component
 * @param {Object} inputParameters.category - Category object
 * @param {Object} inputParameters.category.texts - Texts of the category
 * @param {String} inputParameters.category.texts.name - Name of the category
 * @param {Object[]} inputParameters.category.inputFields - Inputfields of the category
 * @param {Object[]} inputParameters.category.items - Selected services of the category
 * @param {Date} inputParameters.category.startOfService - Date of start of service
 * @param {Object} inputParameters.category.discount - Informations about selected discount
 * @param {Boolean} inputParameters.showDigits - Show digits on prices
 * @param {Boolean} inputParameters.isFixedMonthlyFeeType - Is the shopping cart in fixed monthly fee mode
 * @returns {JSX.Element} collapsable card to show informations about a selected category
 * @component
 */
const CategoryOverview = ({ category, showDigits, isFixedMonthlyFeeType }) => {
  const { t: translation } = useTranslation();
  const { texts, inputFields, items, startOfService, discount } = category;

  const { name } = texts;

  const mapInputFieldToKeyValueBlockEntries = (inputField) => {
    console.log(inputField);
    let value = formattedInputValue({
      inputField: {
        name: inputField.orginalInputFieldData.name,
        unitName: inputField.orginalInputFieldData.unitName,
        options: inputField.orginalInputFieldData.options,
        ...inputField,
      },
      showDigits: true,
      t: translation,
    });
    if (Array.isArray(value)) value = value.map((entry, index) => ({ ...entry, key: index }));
    return {
      key: inputField.orginalInputFieldData.name,
      value,
    };
  };

  let inputFieldDataAsKeyValueStore = inputFields
    .filter(
      (inputField) =>
        inputField?.orginalInputFieldData?.name &&
        inputField?.value &&
        inputField?.orginalInputFieldData?.type !== 'internal',
    )
    .map(mapInputFieldToKeyValueBlockEntries);

  /**
   * Filters key value pairs with duplicated keys
   * @param {Object[]} keyValuePairs - Collection of key value pairs
   * @returns {Object[]} filtered key value pairs collection
   */
  const filterForDuplicatedKeys = (keyValuePairs) => {
    const foundKeys = new Set();
    return keyValuePairs.filter((keyValuePair) => {
      const found = foundKeys.has(keyValuePair.key);
      if (found) return false;
      foundKeys.add(keyValuePair.key);
      return true;
    });
  };

  inputFieldDataAsKeyValueStore = filterForDuplicatedKeys(inputFieldDataAsKeyValueStore);

  if (startOfService)
    inputFieldDataAsKeyValueStore.unshift({
      key: translation('user.ShoppingCart.Category.ValidationLabels.startOfService'),
      value: <span className="info-text">{startOfService}</span>,
    });
  inputFieldDataAsKeyValueStore = inputFieldDataAsKeyValueStore.map((keyValue) => ({
    key: `${keyValue.key}:`,
    value: keyValue.value,
  }));

  const itemsComponents = items.map((item) => (
    <ServiceEntry
      item={item}
      key={item.texts.name}
      showDigits={showDigits}
      isFixedMonthlyFeeType={isFixedMonthlyFeeType}
      activeDiscount={discount}
    />
  ));

  return (
    <CollapsableCard title={name} openByDefault>
      <KeyValueBlock keyValuePairs={inputFieldDataAsKeyValueStore} className={classes.keyValueBlock} />
      {itemsComponents}
    </CollapsableCard>
  );
};

export default CategoryOverview;
