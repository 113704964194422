import OutOfServiceCardContent from 'components/global/OutOfServiceCardContent';
import SigningInfoPagesLayout from 'components/layouts/SigningInfoPagesLayout';
import useTriggerOutOfServiceWebhook from 'hooks/useTriggerOutOfServiceWebhook';
import kanzleiPilotLogoSrc from 'assets/images/KanzleiPilotLogo.svg';
import classes from './SigningOutOfService.module.less';

/**
 * Out of service page for signing pages
 * @returns {JSX.Element} out of service page component
 * @component
 */
const SigningOutOfService = () => {
  useTriggerOutOfServiceWebhook('signing');
  return (
    <SigningInfoPagesLayout contentAreaClassName={classes.contentArea} altLogo={kanzleiPilotLogoSrc}>
      <OutOfServiceCardContent />
    </SigningInfoPagesLayout>
  );
};

export default SigningOutOfService;
