import { gql } from '@apollo/client';
import { currentUserFragment } from 'graphql/fragments';

const updateProfileMutation = gql`
  mutation updateProfileMutation($profile: UserProfileInput) {
    updateProfile(profile: $profile) {
      ...currentUserFragment
    }
  }
  ${currentUserFragment}
`;

export default updateProfileMutation;
