import { gql } from '@apollo/client';

const adminYourLicenseFragment = gql`
  fragment adminYourLicenseFragment on License {
    nrOfCompaniesLimit
    nrOfCompaniesCreated
    nrOfUsersLimit
    nrOfUsersCreated
    licenseStarted
  }
`;

export default adminYourLicenseFragment;
