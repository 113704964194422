import { gql } from '@apollo/client';

const userViewShoppingCartDiscountFragment = gql`
  fragment userViewShoppingCartDiscountFragment on Discount {
    _id
    _apolloCacheKey
    name
    value
    type
  }
`;

export default userViewShoppingCartDiscountFragment;
