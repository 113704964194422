import { gql } from '@apollo/client';

const adminPdfTableThemeFragment = gql`
  fragment adminPdfTableThemeFragment on PdfTableTheme {
    fontSize
    marginBottom
    marginTop
  }
`;

export default adminPdfTableThemeFragment;
