import { Button } from 'antd';
import { FullscreenExitOutlined } from '@ant-design/icons';
import classes from './FullscreenModeHeader.module.less';

/**
 * FullscreenModeHeader component for the fullscreen mode
 * @param {Object} inputParameters - Input parameters of component
 * @param {Function} inputParameters.onClose - Funcion, which is called, when the close button for fullscreen mode is pressed
 * @param {Object} inputParameters.documentInfo - Informations about the document
 * @param {String} inputParameters.documentInfo.documentTemplateName - Name of the document template related to document
 * @returns {JSX.Element} header for fullscreen mode with document title (document template name) and a close button
 * @component
 */
const FullscreenModeHeader = ({ onClose, documentInfo }) => {
  return (
    <div className={classes.Header}>
      <Button icon={<FullscreenExitOutlined />} onClick={onClose} className={classes.CloseButton} type="text" />
      <span>{documentInfo.documentTemplateName}</span>
    </div>
  );
};

export default FullscreenModeHeader;
