import { Trans } from 'react-i18next';
import DiscountedInformation from './DiscountedInformation';

/**
 * DiscountedCustomPriceInformation component to show discounted custom price info text for service overview step of the signing process
 * @param {Object} inputParameters - Input parameters of the component
 * @param {ServicePriceInformation} inputParameters.priceInformation - Information about the price of the service
 * @param {ActiveDiscount} inputParameters.activeDiscount - Active discount on category level
 * @returns {JSX.Element} info text about discounted custom price
 * @component
 */
const DiscountedCustomPriceInformation = ({ priceInformations, activeDiscount }) => {
  if (priceInformations.isFixedMonthlyFee) return null;
  if (!priceInformations.showCalculatedPrice)
    return <DiscountedInformation priceInformations={priceInformations} activeDiscount={activeDiscount} />;

  const { value, type } = activeDiscount;

  const discountValueWithUnit = type === 'percent' ? `${value} %` : `${value} €`;

  const components = { s: <s /> };
  const values = {
    discountValueWithUnit,
    customPriceWithoutDiscount: priceInformations.formattedCustomPrice,
    calculatedPrice: priceInformations.formattedCalculatedPrice,
  };

  return (
    <Trans
      i18nKey="signing.serviceOverviewStep.service.discountedCustomPriceInformation"
      components={components}
      values={values}
    />
  );
};

export default DiscountedCustomPriceInformation;
