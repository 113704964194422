import MultilineInput from 'components/common/MultilineInput';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Row, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import classes from './DnsEntry.module.less';

const { Title } = Typography;

const DNSEntry = ({
  value,
  status,
  name,
  label,
  recordHostname,
  recordHostnameLabel,
  recordType,
  recordTypeLabel,
  title,
  noCheck,
}) => {
  const EntryType = (
    <MultilineInput
      name="type"
      label={`${recordTypeLabel}`}
      value={recordType}
      isCopyAndReadOnly
      className={classes.entryTypeInput}
    />
  );

  const PreFixSection = (
    <MultilineInput
      name="prefix"
      label={`${recordHostnameLabel}`}
      value={recordHostname}
      isCopyAndReadOnly
      className={classes.hostnameInput}
    />
  );

  return (
    <>
      {title ? (
        <>
          <Title level={4} className={classes.h4Subheading}>
            {title}
          </Title>{' '}
          {!noCheck ? <EntryStatusIcon status={status} /> : null}
        </>
      ) : null}
      <Row className={classes.firstRow}>
        {recordType ? EntryType : null}
        {recordHostname && PreFixSection}
      </Row>
      <MultilineInput name={name} label={`${label}`} value={value} isCopyAndReadOnly />
    </>
  );
};

/**
 * This component displays a green checkmark if the status is 'connected', otherwise a red cross
 * @param status - the status of the DKIM verification
 * @returns {JSX.Element}
 * @component
 */
const EntryStatusIcon = ({ status }) => {
  const { t } = useTranslation();

  return status ? (
    <Tooltip title={t('admin.Setting.Email.inputs.dkimStatusCheck.tooltip.connected')}>
      <CheckCircleOutlined className={classes.checkCircleConnected} />
    </Tooltip>
  ) : (
    <Tooltip title={t('admin.Setting.Email.inputs.dkimStatusCheck.tooltip.notConnected')}>
      <CloseCircleOutlined className={classes.checkCircleNotConnected} />
    </Tooltip>
  );
};

export default DNSEntry;
