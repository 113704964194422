import { gql } from '@apollo/client';

const userTotalValueFragment = gql`
  fragment userTotalValueFragment on TotalValue {
    _id
    _apolloCacheKey
    discountedValue
    discountedVAT
  }
`;

export default userTotalValueFragment;
