import apollo from 'graphql/apollo';
import { userGetStatusesQuery } from 'graphql/queries';

export default function moveInputField({ statuses }) {
  if (!statuses.length) return;
  const { type } = statuses[0];
  apollo.writeQuery({
    query: userGetStatusesQuery,
    variables: {
      type,
    },
    data: { getStatuses: statuses },
  });
}
