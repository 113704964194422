import { gql } from '@apollo/client';

const changeSigningLinkExpirationDateMutation = gql`
  mutation changeSigningLinkExpirationDateMutation(
    $shoppingCartId: ID!
    $signeeRelatedObjectId: ID!
    $signeeEmail: String!
    $newExpirationDate: DateTime!
  ) {
    changeSigningLinkExpirationDate(
      shoppingCartId: $shoppingCartId
      signeeRelatedObjectId: $signeeRelatedObjectId
      signeeEmail: $signeeEmail
      newExpirationDate: $newExpirationDate
    )
  }
`;

export default changeSigningLinkExpirationDateMutation;
