import { Card } from 'antd';
import classes from './Card.module.less';

const CollapsableCard = ({ title, children, icon, className }) => {
  return (
    <div className={classes.cardWrapper}>
      <Card className={classes.card}>
        <div className={classes.header}>
          <div className={classes.iconWrapper}>
            <span>{icon}</span>
          </div>
          <div className={classes.headingWrapper}>
            <h2>{title}</h2>
          </div>
        </div>
        <div className={className}>{children}</div>
      </Card>
    </div>
  );
};

export default CollapsableCard;
