import { gql } from '@apollo/client';
import { adminTenantSettingsFragment } from 'graphql/fragments';

const updateTenantSettingsMutation = gql`
  mutation updateTenantSettingsMutation($settings: SettingsInput) {
    updateSettings(settings: $settings) {
      ...adminTenantSettingsFragment
    }
  }
  ${adminTenantSettingsFragment}
`;

export default updateTenantSettingsMutation;
