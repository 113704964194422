import SigningInfoPagesLayout from 'components/layouts/SigningInfoPagesLayout';
import useResetSigningContext from 'hooks/signing/useResetSigningContext';
import { useTranslation } from 'react-i18next';
import classes from './InfoPages.module.less';

const SigningUnvalid = () => {
  const { t: translation } = useTranslation();
  const { resetSigningContext, resetSigningTheme } = useResetSigningContext();

  resetSigningContext();
  setTimeout(() => resetSigningTheme(), 5000);

  return (
    <SigningInfoPagesLayout contentAreaClassName={classes.contentArea}>
      <div className={classes.headingWrapper}>
        <h1 className={classes.infoPageHeading}>{translation('signing.infoPages.signingNotValid.heading')}</h1>
      </div>
    </SigningInfoPagesLayout>
  );
};

export default SigningUnvalid;
