import { useMemo } from 'react';
import { Select } from 'formik-antd';
import { useCachedQuery } from 'graphql/utils';
import { adminDocumentTemplateListQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';
import FormItem from 'components/common/FormItem';

const { Option } = Select;

const DocumentTemplateSelect = ({ label, fieldName, placeholder, tooltip, isLibrary = false }) => {
  const { data, loading } = useCachedQuery(adminDocumentTemplateListQuery, {
    variables: { isLibrary },
  });
  const documentTemplates = useMemo(() => grabFirstGQLDataResult(data), [data]);
  return (
    <FormItem name={fieldName} label={label} tooltip={tooltip}>
      <Select name={fieldName} loading={loading} disabled={loading} placeholder={placeholder} allowClear>
        {loading ||
          documentTemplates?.map(({ _id, name }) => (
            <Option key={_id} value={_id}>
              {name}
            </Option>
          ))}
      </Select>
    </FormItem>
  );
};

export default DocumentTemplateSelect;
