import { union } from 'lodash';
import { Calc, DIGITAL_SIGNATURE_STATE } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { CalculationMode, HighlightBindingness } from 'constants/item';

const buildEmptyBindingnessCounts = () => ({
  fixPriced: 0,
  dynamic: 0,
  estimated: 0,
  onActualCostItems: 0,
});

export const calculateItemCountForBindingnessOption = (categories) => {
  if (!categories) return null;

  const intervalAndBindingnessCountMap = {
    oneOff: buildEmptyBindingnessCounts(),
    monthly: buildEmptyBindingnessCounts(),
    yearly: buildEmptyBindingnessCounts(),
  };

  categories.forEach((category) => {
    category.items.forEach((item) => {
      if (item.highlightBindingness)
        intervalAndBindingnessCountMap[item.paymentInterval][item.highlightBindingness] += 1;
      if (item.calculationMode === CalculationMode.ON_ACTUAL_COST)
        intervalAndBindingnessCountMap[item.paymentInterval].onActualCostItems += 1;
    });
  });

  intervalAndBindingnessCountMap.monthlyAdvance = {
    fixPriced: intervalAndBindingnessCountMap.yearly.fixPriced + intervalAndBindingnessCountMap.monthly.fixPriced,
    dynamic: intervalAndBindingnessCountMap.yearly.dynamic + intervalAndBindingnessCountMap.monthly.dynamic,
    estimated: intervalAndBindingnessCountMap.yearly.estimated + intervalAndBindingnessCountMap.monthly.estimated,
  };

  return intervalAndBindingnessCountMap;
};

const HighlightBindingnessNames = Object.values(HighlightBindingness).filter(
  (highlightBindingness) => highlightBindingness !== HighlightBindingness.NO_HIGHLIGHT,
);

export const formatHighlightBindingnessInfo = (bindingnessCounts, translation, bindingnessValues, showDigits) => {
  return HighlightBindingnessNames.map(
    (highlightBindingness) =>
      bindingnessCounts[highlightBindingness] > 0 &&
      translation('sharedPackage.numberOfHighlightBindingness', {
        highlightBindingness,
        count: bindingnessCounts[highlightBindingness],
        value: Calc.formatCurrency(bindingnessValues?.[highlightBindingness]?.discountedValue, { showDigits }),
      }),
  ).filter(Boolean);
};

/**
 * Generates a function, which maps signee information from signature data of shopping cart by merging infos from the signee data and the signed signee data
 * @param {Object[]} signedSignees - Array with data of signed signees
 * @returns {Function} map function to map signees and enrich the data
 */
const getMapSignee = (signedSignees) => {
  return (signee) => {
    const { firstName, lastName, email, id, lastRemindedDate } = signee;
    const signInfo = signedSignees.find((signingInfo) => signingInfo.signeeEmail === email);
    const hasSigned = signInfo !== undefined;
    const { signDate } = signInfo || {};
    return { firstName, lastName, signDate, hasSigned, email, id, lastRemindedDate };
  };
};

export const mergeSigneeData = (signatureData) => {
  const tenantSignees = signatureData
    ? signatureData.tenantSignees?.map(getMapSignee(signatureData.signedTenantSignees))
    : [];
  const companySignees = signatureData
    ? signatureData.companySignees?.map(getMapSignee(signatureData.signedCompanySignees))
    : [];
  const signees = signatureData ? union(tenantSignees, companySignees) : [];
  return signees;
};

export const doUserNeedToSign = (digitalSignatureState, signatureData, user) => {
  if (digitalSignatureState !== DIGITAL_SIGNATURE_STATE.STARTED) return false;
  const isUserPartOfSigningProcess = signatureData.tenantSignees.find((signee) => signee.email === user?.email);
  const hasUserSigneedYet = signatureData.signedTenantSignees.find((signee) => signee.signeeEmail === user?.email);
  return isUserPartOfSigningProcess && !hasUserSigneedYet;
};
