import LogQueue from './sentryLogQueue';

const consoleLogsQueue = new LogQueue(20);
// window.consoleLogsQueue = consoleLogsQueue;
const fill = (source, name, replacementFactory) => {
  if (!(name in source)) {
    return;
  }
  const original = source[name];
  const wrapped = replacementFactory(original);
  // Make sure it's a function first, as we need to attach an empty prototype for `defineProperties` to work
  // otherwise it'll throw "TypeError: Object.defineProperties called on non-object"
  if (typeof wrapped === 'function') {
    try {
      wrapped.prototype = wrapped.prototype || {};
      Object.defineProperties(wrapped, {
        __sentry_original__: {
          enumerable: false,
          value: original,
        },
      });
    } catch (_Oo) {
      // This can throw if multiple fill happens on a global object like XMLHttpRequest
      // Fixes https://github.com/getsentry/sentry-javascript/issues/2043
    }
  }
  // eslint-disable-next-line no-param-reassign
  source[name] = wrapped;
};
// function safeJoin(input, delimiter) {
//   if (!Array.isArray(input)) {
//     return '';
//   }
//   const output = [];
//   // eslint-disable-next-line no-plusplus
//   for (let i = 0; i < input.length; i++) {
//     const value = input[i];
//     try {
//       output.push(String(value));
//     } catch (e) {
//       output.push('[value cannot be serialized]');
//     }
//   }
//   return output.join(delimiter);
// }
const levels = ['log', 'info', 'warn', 'error', 'debug', 'assert'];
const storeConsoleLogs = () => {
  if (!('console' in global)) {
    return;
  }
  levels.forEach((level) => {
    if (!(level in global.console)) {
      return;
    }
    // eslint-disable-next-line func-names
    fill(global.console, level, function (originalConsoleLevel) {
      // eslint-disable-next-line func-names
      return function () {
        const args = [];
        // eslint-disable-next-line no-plusplus
        for (let _i = 0; _i < arguments.length; _i++) {
          // eslint-disable-next-line prefer-rest-params
          args[_i] = arguments[_i];
        }
        // const message = safeJoin(args, ' ');
        // consoleLogsQueue.enqueue({ message, args });
        consoleLogsQueue.enqueue(args);

        // this fails for some browsers. :(
        if (originalConsoleLevel) {
          Function.prototype.apply.call(originalConsoleLevel, global.console, args);
        }
      };
    });
  });
};

storeConsoleLogs();
export { consoleLogsQueue };
