import { gql } from '@apollo/client';

const richEditorSchemaQuery = gql`
  query getPlaceholdersSchema($isLibrary: Boolean, $initializationConfigDate: DateTime) {
    getPlaceholdersSchema(isLibrary: $isLibrary, initializationConfigDate: $initializationConfigDate) {
      name
      use
      fields {
        name
        hiddenByDefault
        label
        use
        type
        fields {
          name
          hiddenByDefault
          label
        }
      }
    }
  }
`;

export default richEditorSchemaQuery;
