import SelectInputField from 'components/common/SelectInputField';
import { filterKanzleipilotTeamUsers, isKanzleipilotTeamUserSelected } from 'components/user/shoppingCart/utils';
import useUsersInTenantList from 'hooks/user/useUsersInTenantList';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import { useFormikContext } from 'formik';

/**
 * Renders a multi-select input for users in a tenant list, filtered by certain criteria.
 * @param {Object} inputParameters - Props passed to the component.
 * @param {string} inputParameters.fieldName - Name attribute for the select input field.
 * @param {string} inputParameters.label - Label text for the select input field.
 * @param {string} inputParameters.tooltip - Tooltip text to provide additional information about the select input.
 * @param {Array} inputParameters.specialOptions - Additional options that can be added to the list of users.
 * @param {string} inputParameters.className - Optional additional CSS classes to apply to the component.
 * @param {string} inputParameters.placeholder - Placeholder text for the select input field when no option is selected.
 * @returns {JSX.Element} showing a multi-select of tenant users
 * @component
 */
const UserMultiSelect = ({ fieldName, label, tooltip, specialOptions, className, placeholder }) => {
  const { data: users } = useUsersInTenantList();
  const [currentUser] = useCurrentUser();
  const { values } = useFormikContext();
  const tenantSignees =
    users && !isKanzleipilotTeamUserSelected(users, values.defaultTenantSignees)
      ? users?.filter((user) => filterKanzleipilotTeamUsers(user, currentUser))
      : users || [];
  const tenantSigneesOptions = tenantSignees.map((user) => ({
    label: `${user.profile.firstName} ${user.profile.lastName}`,
    value: user._id,
  }));
  if (specialOptions) tenantSigneesOptions?.push(...specialOptions);
  return (
    <SelectInputField
      name={fieldName}
      options={tenantSigneesOptions}
      label={label}
      tooltip={tooltip}
      placeholder={placeholder}
      className={className}
    />
  );
};

export default UserMultiSelect;
