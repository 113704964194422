import { Input, Select } from 'formik-antd';
import * as Yup from 'yup';
import i18n from 'i18n';
import variableTypes from 'constants/variableTypes';
import VariableValueInput from 'components/common/VariableValueInput';
import { getOriginalMessageFromGraphQLError } from 'utils/helpers';

const { Option } = Select;

/**
 * This is the Select component for creating variables.
 * @returns Variable type - Select component
 */
const TypeVariableFormikSelect = () => {
  return (
    <Select name="type">
      {Object.values(variableTypes).map((type) => (
        <Option key={type}>{i18n.t(`common.Variables.types.${type}`)}</Option>
      ))}
    </Select>
  );
};

/**
 * This is the Select component for editing variables. The type of a variable shall not be changable to type 'text'.
 * Therefore, the option 'text' is disabled.
 * @returns Variable type - Select component with disabled text option
 */
const TypeVariableFormikSelect_EditMode = () => {
  return (
    <Select name="type">
      {Object.values(variableTypes).map((type) => {
        if (type === 'text') {
          return (
            <Option disabled key={type}>
              {i18n.t(`common.Variables.types.${type}`)}
            </Option>
          );
        }
        return <Option key={type}>{i18n.t(`common.Variables.types.${type}`)}</Option>;
      })}
    </Select>
  );
};

/// //////////////////// schema ////////////////////////////

export const variableSchema = ({ isInternal }) => ({
  ...(!isInternal && {
    label: Yup.string().nullable().required().label(i18n.t('admin.ConstantsAndPlaceholders.columns.name')),
  }),
  // value: Yup.number().nullable().moreThan(0).required().label(i18n.t('admin.ConstantsAndPlaceholders.columns.value')),
  value: Yup.mixed().required().label(i18n.t('admin.ConstantsAndPlaceholders.columns.value')),
  ...(!isInternal && {
    type: Yup.string().required().label(i18n.t('admin.ConstantsAndPlaceholders.columns.type')),
  }),
});

export const variableValidationSchema = ({ isInternal } = {}) => Yup.object().shape(variableSchema({ isInternal }));

/// ////////////  variable fields for modal ////////////////

export const getVariableFields = ({ isInternal, isEditMode } = {}) =>
  [
    {
      label: i18n.t('admin.ConstantsAndPlaceholders.columns.name'),
      name: 'label',
      component: Input,
    },
    {
      label: i18n.t('admin.ConstantsAndPlaceholders.columns.description'),
      placeholder: 'placeholder',
      name: 'description',
      component: Input,
    },
    !isInternal && {
      label: i18n.t('admin.ConstantsAndPlaceholders.columns.type'),
      name: 'type',
      component: isEditMode ? TypeVariableFormikSelect_EditMode : TypeVariableFormikSelect,
    },
    {
      label: i18n.t('admin.ConstantsAndPlaceholders.columns.value'),
      name: 'value',
      component: VariableValueInput,
    },
  ].filter(Boolean);

export const errorResolver = ({ error }) => {
  if (getOriginalMessageFromGraphQLError(error).includes('variable.duplicated')) return ['label', error.message];
  return undefined;
};
