import { gql } from '@apollo/client';

const adminPdfBodyPrimaryFooterFragment = gql`
  fragment adminPdfBodyPrimaryFooterFragment on PdfBodyPrimaryFooter {
    fontFamily
    color
  }
`;

export default adminPdfBodyPrimaryFooterFragment;
