import { useShoppingCartRevisionContext } from 'components/user/shoppingCart/context';
import { userDocumentTemplateMultiSelectQuery } from 'graphql/queries';
import { useCachedQuery } from 'graphql/utils';
import { createContext, useContext, useMemo } from 'react';
import { formatDateTime } from 'utils/date';
import { grabFirstGQLDataResult } from 'utils/helpers';

const DocumentTemplatesForShoppingCartContext = createContext(null);

/**
 * Fetches current document templates for selection fields
 * @returns {Object[]} current document templates
 */
const useDocumentTemplateList = () => {
  const { data, loading } = useCachedQuery(userDocumentTemplateMultiSelectQuery, {
    variables: { isLibrary: false },
    fetchPolicy: 'cache-and-network',
  });
  const documentTemplates = useMemo(() => grabFirstGQLDataResult(data), [data]);
  return { data: documentTemplates, loading };
};

/**
 * Addes option fields (name and value (contains json string with id and init config date)) for historical document templates
 * @param {Object[]} historicalDocumentTemplates - Historical document templates
 * @returns {Object[]} historical document templates with option fields
 */
const prepareHistoricalDocumentTemplatesForDocumentTemplateSelect = (historicalDocumentTemplates) => {
  return historicalDocumentTemplates?.map((documentTemplate) => ({
    ...documentTemplate,
    name: `${documentTemplate.name} ${formatDateTime(documentTemplate.initializationConfigDate)}`,
    value: JSON.stringify({
      _id: documentTemplate._id,
      initializationConfigDate: documentTemplate.initializationConfigDate,
    }),
  }));
};

/**
 * Addes option fields (name and value (contains json string with id)) for current document templates
 * @param {Object[]} currentDocumentTemplates - Current document templates
 * @returns {Object[]} current document templates with option fields
 */
const prepareCurrentDocumentTemplatesForDocumentTemplateSelect = (currentDocumentTemplates) => {
  return currentDocumentTemplates?.map((documentTemplate) => ({
    ...documentTemplate,
    name: documentTemplate.name,
    value: JSON.stringify({ _id: documentTemplate._id }),
  }));
};

/**
 * Fetches document templates and provide them with option fields (name and value (contains json string with id and init config date))
 * @param {Boolean} isAllowHistoricalDocumentTemplates - Should historical document templates be fetched and provided
 * @returns {Object} loading state and fetched document templates (historical and current) with option fields (name and value)
 */
const useDocumentTemplateOptions = (isAllowHistoricalDocumentTemplates) => {
  const { documentTemplates: historicalDocumentTemplates } = useShoppingCartRevisionContext();

  const historicalDocumentTemplatesOptions = useMemo(
    () =>
      isAllowHistoricalDocumentTemplates
        ? prepareHistoricalDocumentTemplatesForDocumentTemplateSelect(historicalDocumentTemplates)
        : [],
    [historicalDocumentTemplates, isAllowHistoricalDocumentTemplates],
  );

  const { data: currentDocumentTemplates, loading } = useDocumentTemplateList();

  const currentDocumentTemplatesOptions = useMemo(
    () => prepareCurrentDocumentTemplatesForDocumentTemplateSelect(currentDocumentTemplates),
    [currentDocumentTemplates],
  );
  return {
    historicalDocumentTemplatesOptions,
    currentDocumentTemplatesOptions,
    loading,
  };
};
/**
 * Provider component for the document templates for shopping cart context, which provides document templates options for the shopping cart (create/edit new shopping cart)(document templates with name and value field for option components for a select field) (Can add historical document templates to the current document templates for the shopping cart)
 * @param {Object} inputParameters - Input parameters of the component
 * @param {JSX.Element} inputParameters.children - Children components of the component which should be rendered in place of this component
 * @param {Boolean} inputParameters.isAllowHistoricalDocumentTemplates - Should historical document templates be fetched and provided
 * @returns {JSX.Element} provider for the document templates for shopping cart context
 * @component
 */
export const DocumentTemplatesForShoppingCartContextProvider = ({ children, isAllowHistoricalDocumentTemplates }) => {
  const { historicalDocumentTemplatesOptions, currentDocumentTemplatesOptions, loading } = useDocumentTemplateOptions(
    isAllowHistoricalDocumentTemplates,
  );
  const allDocumentTemplateOptions = useMemo(() => {
    if (historicalDocumentTemplatesOptions && currentDocumentTemplatesOptions)
      return [...historicalDocumentTemplatesOptions, ...currentDocumentTemplatesOptions];
    if (historicalDocumentTemplatesOptions) return historicalDocumentTemplatesOptions;
    if (currentDocumentTemplatesOptions) return currentDocumentTemplatesOptions;
    return [];
  }, [historicalDocumentTemplatesOptions, currentDocumentTemplatesOptions]);
  const contextContent = useMemo(
    () => ({
      loading,
      historicalDocumentTemplatesOptions,
      currentDocumentTemplatesOptions,
      allDocumentTemplateOptions,
    }),
    [loading, currentDocumentTemplatesOptions, historicalDocumentTemplatesOptions, allDocumentTemplateOptions],
  );
  return (
    <DocumentTemplatesForShoppingCartContext.Provider value={contextContent}>
      {children}
    </DocumentTemplatesForShoppingCartContext.Provider>
  );
};

/**
 * @typedef {Object} DocumentTemplatesForShoppingCartContext
 * @property {Boolean} loading - Does data get loaded currently
 * @property {Object[]} historicalDocumentTemplatesOptions - Historical document templates options (document template object with a name field for option labels and a value field with stringified json information)
 * @property {Object[]} currentDocumentTemplatesOptions - Current document templates options (document template object with a name field for option labels and a value field with stringified json information)
 * @property {Object[]} allDocumentTemplateOptions - Historical and current document templates options (document template object with a name field for option labels and a value field with stringified json information)
 */

/**
 * Hook to get access to the document templates for shopping cart context (Must be used in the document templates for shopping cart context)
 * @returns {React.Context<DocumentTemplatesForShoppingCartContext>} use context hook for document templates for shopping cart context
 */
export const useDocumentTemplatesForShoppingCartContext = () => useContext(DocumentTemplatesForShoppingCartContext);
