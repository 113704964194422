import apollo from 'graphql/apollo';
import { userIsDiscountUsedInCartQuery } from 'graphql/queries';

const isDiscountUsedInCart = async ({ _id }) =>
  (
    await apollo.query({
      query: userIsDiscountUsedInCartQuery,
      variables: { _id },
      fetchPolicy: 'network-only',
    })
  ).data?.discountById?.usedInShoppingCart;
export default isDiscountUsedInCart;
