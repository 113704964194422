import { Collapse } from 'antd';
import HeaderCollapse from 'components/admin/components/HeaderCollapse/HeaderCollapse';
import equal from 'fast-deep-equal/es6/react';
import { memo, useCallback } from 'react';
import { AiOutlineRight } from 'react-icons/ai';
import { GrDrag } from 'react-icons/gr';
import { colors } from 'styles/colors';
import classes from './CollapseDrag.module.less';

const { Panel } = Collapse;

const CollapseDrag = ({ label }) => {
  const expandIcon = useCallback(
    () => (
      <div className={classes.expandIcon}>
        <GrDrag size={14} className={classes.drag} />
        <AiOutlineRight size={14} color={colors.expandArrow} />
      </div>
    ),
    [],
  );

  return (
    <div className={classes.layer}>
      <Collapse defaultActiveKey={undefined} expandIcon={expandIcon} expandIconPosition="left">
        <Panel header={<HeaderCollapse name={label} isActive={false} />} />
      </Collapse>
    </div>
  );
};

export default memo(CollapseDrag, equal);
