import { gql } from '@apollo/client';
import { adminTenantSettingsFragment } from 'graphql/fragments';

const resetTenantSettingsMutation = gql`
  mutation resetTenantSettingsMutation($type: String!) {
    resetSettings(type: $type) {
      ...adminTenantSettingsFragment
    }
  }
  ${adminTenantSettingsFragment}
`;

export default resetTenantSettingsMutation;
