import { gql } from '@apollo/client';

const documentTemplateBlockDescriptorFragment = gql`
  fragment documentTemplateBlockDescriptorFragment on DocumentTemplateBlockDescriptor {
    _id
    name
    displayName
    form
    infoText
    previewImageThumbnailSrc
    previewImageSrc
  }
`;

export default documentTemplateBlockDescriptorFragment;
