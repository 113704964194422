import { gql } from '@apollo/client';

const userEmailServiceQuery = gql`
  query userEmailServiceQuery {
    getEmailService {
      mailServiceProvider
    }
  }
`;

export default userEmailServiceQuery;
