import { useCachedQuery, useCachedLazyQuery } from 'graphql/utils';
import { userDeletedIdsQuery, userShoppingCartQuery } from 'graphql/queries';
import { CreationMode } from 'constants/shoppingCart';
import { useParams } from 'react-router';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { useEffect, useMemo } from 'react';

const useUserShoppingCart = ({ _id, fetchPolicy = 'network-only', errorPolicy = 'all' }) => {
  const { mode } = useParams();
  const isGlobal = useMemo(() => mode === CreationMode.GLOBAL, [mode]);
  const { data, loading } = useCachedQuery(userShoppingCartQuery, { variables: { _id }, fetchPolicy, errorPolicy });
  const [getDeletedIds, { data: deletedIdsData, loading: deletedIdsLoading, called }] = useCachedLazyQuery(
    userDeletedIdsQuery,
    {
      variables: { configDate: data?.userShoppingCart?.initializationConfigDate },
      fetchPolicy,
      errorPolicy,
    },
  );
  useEffect(() => isGlobal && !loading && !called && getDeletedIds(), [called, getDeletedIds, isGlobal, loading]);
  const shoppingCart = useMemo(() => {
    if (data && (!isGlobal || deletedIdsData)) {
      // eslint-disable-next-line no-shadow
      const shoppingCart = grabFirstGQLDataResult(data);
      return {
        ...shoppingCart,
        initializationConfigDateForCompany: shoppingCart.isCompanyOutdated
          ? shoppingCart.initializationConfigDateForCompany
          : null,
        deletedIds: grabFirstGQLDataResult(deletedIdsData),
      };
    }
    return undefined;
  }, [data, deletedIdsData, isGlobal]);
  const isLoading = useMemo(
    () => loading || (isGlobal && (!called || deletedIdsLoading)),
    [called, deletedIdsLoading, isGlobal, loading],
  );
  if (isLoading) return [null, true];
  return [shoppingCart, isLoading];
};

export default useUserShoppingCart;
