import apollo from 'graphql/apollo';
import { duplicateDocumentTemplateMutation } from 'graphql/mutations';
import { adminDocumentTemplateListForPageQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';

const duplicateDocumentTemplate = async ({ documentTemplateId, name, isLibrary }) =>
  apollo.mutate({
    mutation: duplicateDocumentTemplateMutation,
    variables: {
      documentTemplateId,
      name,
      isLibrary,
    },
    refetchQueries: [{ query: adminDocumentTemplateListForPageQuery, variables: { isLibrary: false } }],
    update: (cache, { data }) => {
      const newDocument = grabFirstGQLDataResult(data);
      const cachedData = cache.readQuery({
        query: adminDocumentTemplateListForPageQuery,
        variables: {
          isLibrary,
        },
      });
      const documentTemplates = grabFirstGQLDataResult(cachedData);
      if (documentTemplates && documentTemplates.length) {
        apollo.writeQuery({
          query: adminDocumentTemplateListForPageQuery,
          variables: { isLibrary },
          data: {
            documentTemplates: [newDocument, ...documentTemplates],
          },
        });
      }
    },
  });
export default duplicateDocumentTemplate;
