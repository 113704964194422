/* eslint-disable react-hooks/rules-of-hooks */
import { useCachedQuery } from 'graphql/utils';
import { tenantLogoQuery } from 'graphql/queries';
import { useEffect } from 'react';
import { grabFirstGQLDataResult } from 'utils/helpers';

function usePreloadLogo({ onLoad } = {}) {
  const { data } = useCachedQuery(tenantLogoQuery);
  useEffect(() => {
    if (!data) return;
    const logoUrl = grabFirstGQLDataResult(data);
    const image = new Image();
    image.src = logoUrl;
    image.onload = onLoad;
  }, [data, onLoad]);
  return null;
}

export default usePreloadLogo;
