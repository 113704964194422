import { useApolloClient } from '@apollo/client';
import { signingContextQuery } from 'graphql/queries';

export const SIGNING_CURRENT_STATE = 'signing-current-state';

const useUpdateCurrentSigningState = () => {
  const client = useApolloClient();
  const updateCurrentSigningState = (newState) => {
    window.localStorage.setItem(SIGNING_CURRENT_STATE, JSON.stringify(newState));
    client.refetchQueries({ include: [signingContextQuery] });
  };
  return updateCurrentSigningState;
};

export default useUpdateCurrentSigningState;
