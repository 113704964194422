import { gql } from '@apollo/client';
import { adminCompanyFragment } from 'graphql/fragments';

const updateCompanyMutation = gql`
  mutation updateCompanyMutation($_id: ID!, $modifier: UpdateCompanyInput) {
    updateCompany(_id: $_id, modifier: $modifier) {
      ...adminCompanyFragment
    }
  }
  ${adminCompanyFragment}
`;

export default updateCompanyMutation;
