import { gql } from '@apollo/client';

const adminPdfTableColorsFragment = gql`
  fragment adminPdfTableColorsFragment on PdfTableColors {
    color
    background
  }
`;

export default adminPdfTableColorsFragment;
