import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import DnsEntry from './DnsEntry';

export function SPFRecord() {
  const { values } = useFormikContext();
  const { t } = useTranslation();

  return (
    <DnsEntry
      name="dkimSettings.spfRecord"
      label={t('admin.Setting.Email.inputs.dnsSettings.spfRecord.label')}
      value={values.dkimSettings.spfRecord}
      recordHostnameLabel={t('admin.Setting.Email.inputs.dnsSettings.spfRecord.prefixLabel')}
      recordHostname={values.dkimSettings.spfRecordHostname}
      recordType={t('admin.Setting.Email.inputs.dnsSettings.spfRecord.type')}
      recordTypeLabel={t('admin.Setting.Email.inputs.dnsSettings.spfRecord.typeLabel')}
      status={values.dkimSettings.isSpfRecordVerified}
      title={t('admin.Setting.Email.inputs.dnsSettings.dkimRecord.heading')}
      key="spfRecord"
    />
  );
}
