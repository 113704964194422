import './polyfill';
import { pdfjs } from 'react-pdf';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './memo';

// Pdf worker script for react-pdf copied by webpack config at build time to serve file by our cdn
pdfjs.GlobalWorkerOptions.workerSrc = `//${window.location.host}/pdfWorker/pdf.worker.min.js`;

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
