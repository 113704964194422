import OutOfServiceCardContent from 'components/global/OutOfServiceCardContent';
import AuthLayout from 'components/layouts/AuthLayout';
import useTriggerOutOfServiceWebhook from 'hooks/useTriggerOutOfServiceWebhook';

/**
 * Out of service page for app
 * @returns {JSX.Element} out of service page component
 * @component
 */
const OutOfServicePage = () => {
  useTriggerOutOfServiceWebhook('global');
  return (
    <AuthLayout>
      <OutOfServiceCardContent />
    </AuthLayout>
  );
};

export default OutOfServicePage;
