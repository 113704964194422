import routePaths from 'router/route-paths';

/**
 * Determines if current path is a global page like out of service page
 * @returns {Boolean}
 */
export const isGlobalPage = () => {
  const currentPagepath = window.location.pathname;
  if (currentPagepath === routePaths.outOfService) return true;
  return false;
};
