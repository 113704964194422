import { Card as CardAnt, Collapse } from 'antd';
import classes from './CollapsableCard.module.less';

const { Panel } = Collapse;

/**
 * CollapsableCard component for signing process
 * @param {Object} inputParameters - Input parameters of component
 * @param {JSX.Element} inputParameters.title - Title for card
 * @param {JSX.Element[]} inputParameters.children - Children of component
 * @param {Boolean} inputParameters.openByDefault - Should the card be open by default
 * @returns {JSX.Element} card component for signing processes, which is collapsable
 * @component
 */
const CollapsableCard = ({ title, children, openByDefault = false }) => {
  let defaultActiveKey;
  if (openByDefault) defaultActiveKey = ['1'];
  return (
    <div className={classes.cardWrapper}>
      <CardAnt className={classes.card}>
        <Collapse className={classes.collapse} defaultActiveKey={defaultActiveKey}>
          <Panel header={title} className={classes.collapsePanel} key={1}>
            <div>{children}</div>
          </Panel>
        </Collapse>
      </CardAnt>
    </div>
  );
};

export default CollapsableCard;
