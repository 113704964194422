import { Badge } from 'antd';
import { numberToLocaleString } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { find } from 'lodash';

/**
 * Formattes the value of an input field
 * @param {Object} inputParameters - Input parameters of the function
 * @param {Object} inputParameters.inputField - Input field object
 * @param {Boolean} inputParameters.showDigits - Should prices show digits
 * @param {Function} inputParameters.t - Translation function
 * @param {Boolean} inputParameters.isCustomPrice - Has the selected service, where the input field is part of, a custom price
 * @returns {JSX.Element} formatted value of input field as element
 */
const formattedInputValue = ({ inputField, showDigits, t, isCustomPrice }) => {
  if (typeof inputField.value === 'string')
    return (
      <span className="info-text">
        {[find(inputField.options, { _id: inputField.value })?.name].filter(Boolean).join(' ')}
      </span>
    );
  let { value } = inputField;
  let postfix = null;

  let varValue = inputField.variable?.value;
  if (typeof varValue === 'string') varValue = Number.parseFloat(inputField.variable.value);

  if (Number.isFinite(varValue ?? inputField.defaultValue)) {
    const defaultValue = varValue ?? inputField.defaultValue;
    value = isCustomPrice ? defaultValue : inputField.value ?? defaultValue;
    postfix = value !== defaultValue && (
      <span>
        <Badge
          className="ant-badge-background-primary-color"
          count={t('viewer.ShoppingCartView.customInternalValue')}
        />
      </span>
    );
  }

  return [
    [numberToLocaleString(value, t('localeCode'), { isRestrictMaximumDigits: false, showDigits }), inputField.unitName]
      .filter(Boolean)
      .map((e) => <span className="info-text">{e}</span>),
    postfix,
  ]
    .flat()
    .filter(Boolean)
    .map((e) => <>{e} </>);
};

export default formattedInputValue;
