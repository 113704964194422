import { gql } from '@apollo/client';
import { userViewShoppingCartCategoriesFragment } from 'graphql/fragments';

const userViewShoppingCartCategoriesQuery = gql`
  query userViewShoppingCartCategoriesQuery($isLibrary: Boolean, $shoppingCartId: ID) {
    categories(isLibrary: $isLibrary, shoppingCartId: $shoppingCartId) {
      ...userViewShoppingCartCategoriesFragment
    }
  }
  ${userViewShoppingCartCategoriesFragment}
`;

export default userViewShoppingCartCategoriesQuery;
