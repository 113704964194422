import apollo from 'graphql/apollo';
import { activateUserMutation } from 'graphql/mutations';
import { grabFirstGQLDataResult } from 'utils/helpers';
import stateInvitation from 'constants/stateInvitation';
import { gql } from '@apollo/client';

const adminUserFragment = gql`
  fragment adminUserFragment on User {
    state
  }
`;

const activateUser = async ({ userId }) =>
  apollo.mutate({
    mutation: activateUserMutation,
    variables: { userId },
    update: (cache, { data }) => {
      const isActivate = grabFirstGQLDataResult(data);
      if (isActivate) {
        cache.writeFragment({
          id: `User___${userId}`,
          fragmentName: 'adminUserFragment',
          fragment: adminUserFragment,
          data: { state: stateInvitation.ACTIVE },
        });
      }
    },
  });

export default activateUser;
