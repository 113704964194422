import i18n from 'i18n';
import apollo from 'graphql/apollo';
import { importDefaultTemplatesForDigitalSignatureMutation } from 'graphql/mutations';
import {
  adminDocumentTemplateListQuery,
  adminEmailTemplatesQuery,
  adminHasTenantImportedDefaultTemplatesQuery,
  adminTenantSettingsQuery,
} from 'graphql/queries';
import toast from 'utils/toast';

/**
 * Importes default templates for digital signature
 * @param {Boolean} shouldReimportExistingOnes - Should imported templates be reimported
 * @param {Boolean} shouldSetSelectionValuesToImportedTemplates - Should the new imported templates set as selection in settings
 */
const importDefaultTemplatesForDigitalSignature = async (
  shouldReimportExistingOnes,
  shouldSetSelectionValuesToImportedTemplates,
) => {
  apollo
    .mutate({
      mutation: importDefaultTemplatesForDigitalSignatureMutation,
      variables: { shouldReimportExistingOnes, shouldSetSelectionValuesToImportedTemplates },
      refetchQueries: [
        { query: adminTenantSettingsQuery },
        { query: adminDocumentTemplateListQuery, variables: { isLibrary: false } },
        { query: adminEmailTemplatesQuery, variables: { isLibrary: false } },
        { query: adminHasTenantImportedDefaultTemplatesQuery },
      ],
    })
    .then(() => toast.successDefault())
    .catch(() =>
      toast.error(i18n.t('admin.Setting.DigitalSignaturePreferences.importDefaultTemplatesModal.importError')),
    );
};

export default importDefaultTemplatesForDigitalSignature;
