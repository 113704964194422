import { Button, Skeleton } from 'antd';
import FormItem from 'components/common/FormItem';
import { Form, Input, SubmitButton } from 'formik-antd';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { updateEmail } from 'graphql/methods';
import { useFormikContext } from 'formik';
import toast from 'utils/toast';
import i18n from 'i18n';
import * as Yup from 'yup';
import { isEqual } from 'lodash';
import useVerifyEmail from 'hooks/auth/useVerifyEmail';
import { AlertFromFormik, setGraphqlErrors } from 'components/common/ErrorComponent';
import I18nFormik from 'components/common/I18nFormik';

const skeletons = [...new Array(1)].map((_, i) => i + 1);

const changeEmail = (isOtp) => ({
  newEmail: Yup.string().required().email().label(i18n.t('user.Setting.AccountSetting.tabs.main.fields.newEmail')),
  ...(isOtp && {
    otp: Yup.string().required().label(i18n.t('user.Setting.AccountSetting.tabs.main.fields.otp')),
  }),
});

export const changeEmailSchema = () => Yup.object().shape(changeEmail());

const ChangeEmailButton = ({ initialValues, isOtp }) => {
  const { values, handleSubmit } = useFormikContext();
  const { t } = useTranslation();
  return (
    !isEqual(isOtp ? { otp: values.otp } : values, isOtp ? { otp: initialValues.otp } : initialValues) && (
      <SubmitButton style={{ marginRight: '16px' }} onClick={handleSubmit} type="primary">
        {t(`user.Setting.AccountSetting.tabs.main.${isOtp ? 'saveEmail' : 'changeEmail'}`)}
      </SubmitButton>
    )
  );
};

// const ResendVerificationEmail = () => {
//   const { t } = useTranslation();
//   const [disabled, setDisabled] = useState(false);
//   const { value } = useFormikField('newEmail');

//   const sendVerificationEmail = useCallback(async () => {
//     setDisabled(true);
//     await resendVerificationEmail({ email: value });
//     toast.success(t('common.toast.success.sendChangeEmailVerificationEmail', { email: value }));

//     window.setTimeout(() => setDisabled(false), 5000);
//   }, [t, value]);

//   return (
//     <Button disabled={disabled} onClick={sendVerificationEmail}>
//       {t('common.buttons.resendVerificationEmail')}
//     </Button>
//   );
// };
function ChangeEmail() {
  const verifyEmail = useVerifyEmail();
  const { t } = useTranslation();
  const [me, loading] = useCurrentUser();
  const [isChange, setIsChange] = useState(false);
  const [isOtp, setIsOtp] = useState(false);
  const emailName = useMemo(() => (isChange ? 'newEmail' : 'email'), [isChange]);
  const email = me?.email;

  const initialValues = useMemo(
    () => ({
      email: email || '',
      newEmail: '',
      otp: '',
    }),
    [email],
  );

  const onCancel = useCallback(
    (resetForm) => {
      setIsChange(!isChange);
      setIsOtp(false);
      resetForm();
    },
    [isChange],
  );

  if (!me && loading) return skeletons.map((k) => <Skeleton title loading active key={k} />);

  return (
    <I18nFormik
      reInitOnSubmit={false}
      initialValues={initialValues}
      enableReinitialize
      validationSchema={() => changeEmailSchema(isOtp)}
      onSubmit={async ({ newEmail, otp }, formik) => {
        try {
          formik.setSubmitting(true);
          if (isOtp) {
            const success = await verifyEmail({ email: newEmail, otp });
            if (success) {
              setIsOtp(false);
              onCancel(formik.resetForm);
              toast.successDefault();
            }
          } else {
            const success = await updateEmail(newEmail);
            if (success) {
              formik.setFieldTouched(emailName, false, false);
              setIsOtp(true);
              toast.success(t('common.toast.success.sendChangeEmailVerificationEmail', { email: newEmail }));
            }
          }
        } catch (error) {
          console.error(error);
          formik.setErrors(
            setGraphqlErrors({
              error,
              errorResolver: ({ message }) => {
                if (message.includes('One-time')) return ['otp', message];
                return undefined;
              },
            }),
          );
        } finally {
          formik.setSubmitting(false);
        }
      }}
    >
      {({ resetForm }) => (
        <Form layout="vertical">
          <AlertFromFormik />

          <FormItem name={emailName} label={t(`user.Setting.AccountSetting.tabs.main.fields.${emailName}`)}>
            <Input name={emailName} readOnly={!isChange} />
          </FormItem>

          {isOtp && (
            <FormItem name="otp" label={t(`user.Setting.AccountSetting.tabs.main.fields.otp`)}>
              <div className="otp-input">
                <Input name="otp" />
              </div>
            </FormItem>
          )}
          <ChangeEmailButton initialValues={initialValues} isOtp={isOtp} />
          <Button onClick={() => onCancel(resetForm)}>
            {t(
              isChange
                ? 'user.Setting.AccountSetting.tabs.main.cancelChangeEmail'
                : 'user.Setting.AccountSetting.tabs.main.changeEmail',
            )}
          </Button>
        </Form>
      )}
    </I18nFormik>
  );
}

export default ChangeEmail;
