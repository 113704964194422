import { Layout, Col, Row } from 'antd';
import cn from 'classnames';
import Card from 'components/common/Card';
import TenantLogo from 'components/common/TenantLogo';
import useModifyI18N from 'hooks/signing/useModifyI18N';
import classes from './SigningInfoPagesLayout.module.less';

const { Content } = Layout;

const StartSigning = ({ children, theme, contentAreaClassName, altLogo }) => {
  useModifyI18N(theme?.locale || 'de');
  return (
    <Layout className={classes.layout}>
      <Row align="middle" justify="center" className={classes.row}>
        <Col xs={22} sm={20} md={16} lg={12} xl={10} xxl={9} className={classes.column}>
          <Content>
            <Card className={classes.card}>
              <TenantLogo getLogoUrlUrl={theme?.logoURL} className={classes.logo} altLogo={altLogo} />
              <div className={cn(classes.content, contentAreaClassName)}>{children}</div>
            </Card>
          </Content>
        </Col>
      </Row>
    </Layout>
  );
};

export default StartSigning;
