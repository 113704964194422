import { message as Message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useApolloClient, useSubscription } from '@apollo/client';
import notificationSubscription from 'graphql/subscriptions/notificationsSubscription';
import { userShoppingCartViewQuery } from 'graphql/queries';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import { isSigningPage } from 'hooks/signing/useIsSigningPage';
import { CloseOutlined } from '@ant-design/icons';
import { isGlobalPage } from 'utils/isGlobalPage';
import classes from './NotificationHandler.module.less';

let progressKeyReference;

/**
 * Processes input arguments and returns the messages to be rendered.
 *
 * @param {String[]} messages - A collection of messages to be processed.
 * @param {Object} dataValues - Any values to be passed to the translation function.
 * @param {Function} translations - A translation function for the messages.
 * @param {String} key - A unique key to reference the message.
 * @returns {JSX.Element} A component to be rendered within a message pop-up.
 */
const processMessage = (message, dataValues, translations, key) => {
  return (
    <div className={classes.contentContainer}>
      <CloseOutlined className={classes.closeButton} onClick={() => Message.destroy(key)} />
      {message.map((singleMessage) => (
        <p>{translations(`BackendErrors.${singleMessage}`, dataValues)}</p>
      ))}
    </div>
  );
};

/**
 * Evaluates, if the message is a start digital signature success or error message
 * @param {String[]} message - Collection of messages
 * @returns {Boolean} is message a digital signature start or error message
 */
const isDigitalSignatureStartNotification = (message) => {
  const digitalSignatureRegexp = /digitalSignature/i;
  const startRegexp = /start/i;
  const startMessage = message.find(
    (singleMessage) => digitalSignatureRegexp.test(singleMessage) && startRegexp.test(singleMessage),
  );
  return startMessage && startMessage !== null;
};

/**
 * NotificationHandler component is a top level component for page layouts to subscribe to backend notifications and print them as messages
 * @param {Object} inputParameters - Input parameters of component
 * @param {JSX.Element} inputParameters.children - Components to render
 * @returns {JSX.Element} handler for backend notifications
 * @component
 */
const NotificationHandler = () => {
  const { t: translation } = useTranslation();
  const apolloClient = useApolloClient();
  const [user] = useCurrentUser(isGlobalPage());

  useSubscription(notificationSubscription, {
    skip: !user || isSigningPage() || isGlobalPage(),
    onSubscriptionData: ({ subscriptionData }) => {
      const { message, type, dataValues } = subscriptionData.data.notification;
      if (isDigitalSignatureStartNotification(message)) {
        const { shoppingCartId } = dataValues;
        if (shoppingCartId) {
          apolloClient.refetchQueries({
            include: [{ query: userShoppingCartViewQuery, variables: { _id: shoppingCartId } }],
          });
        }
      }
      const duration = 15;
      const key = `${Date.now()}-${Math.floor(Math.random() * 1000)}`;
      const proccessedMessage = {
        content: processMessage(message, dataValues, translation, key),
        className: classes.message,
        duration,
        key,
      };
      switch (type) {
        case 'SUCCESS':
          Message.success(proccessedMessage);
          break;
        case 'WARNING':
          Message.warning(proccessedMessage);
          break;
        case 'ERROR':
          Message.error(proccessedMessage);
          progressKeyReference && Message.destroy(progressKeyReference);
          break;
        case 'PROGRESS':
          progressKeyReference = key;
          Message.loading({ ...proccessedMessage, duration: 0 });
          break;
        case 'PROGRESS_END':
          progressKeyReference && Message.destroy(progressKeyReference);
          break;
        default:
          Message.info(proccessedMessage);
          break;
      }
    },
  });
  return null;
};

export default NotificationHandler;
