import MoreInfoWidget from 'components/common/MoreInfoWidget';
import equal from 'fast-deep-equal/es6/react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

const EditDocumentTemplateHelperWidget = ({ buttonClassName, block }) => {
  const { name, displayName } = block;
  const { t } = useTranslation();
  return (
    <MoreInfoWidget
      buttonClassName={buttonClassName}
      buttonText={t(`admin.DocumentTemplateConfigurationPage.BlockHelperWidget.howUseButton`, {
        displayName,
      })}
      title={t(`admin.DocumentTemplateConfigurationPage.BlockHelperWidget.modalInfo.${name}.title`)}
      videoCaption={t(`admin.DocumentTemplateConfigurationPage.BlockHelperWidget.modalInfo.${name}.videoCaption`, {
        displayName,
      })}
      videoUrl={t(`admin.DocumentTemplateConfigurationPage.BlockHelperWidget.modalInfo.${name}.videoUrl`)}
    />
  );
};

export default memo(EditDocumentTemplateHelperWidget, equal);
