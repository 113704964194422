import { memo } from 'react';
import equal from 'fast-deep-equal/es6/react';
import { useTranslation } from 'react-i18next';
import { Alert } from 'antd';
import { CreationMode } from 'constants/shoppingCart';
import { useParams } from 'react-router';
import MoreInfoWidget from 'components/common/MoreInfoWidget';
import { formatDate } from 'utils/date';
import { useShoppingCartRevisionContext } from 'components/user/shoppingCart/context';

const GlobalModeHelperWidget = memo(({ buttonClassName }) => {
  const { t } = useTranslation();
  return (
    <MoreInfoWidget
      buttonClassName={buttonClassName}
      buttonText={t('user.ShoppingCart.globalModeAlert.GlobalModeHelperWidget.howUseButton')}
      title={t('user.ShoppingCart.globalModeAlert.GlobalModeHelperWidget.modalInfo.title')}
      helpText={t('user.ShoppingCart.globalModeAlert.GlobalModeHelperWidget.modalInfo.helpText')}
      videoCaption={t('user.ShoppingCart.globalModeAlert.GlobalModeHelperWidget.modalInfo.videoCaption')}
      videoUrl={t('user.ShoppingCart.globalModeAlert.GlobalModeHelperWidget.modalInfo.videoUrl')}
      imageUrl={t('user.ShoppingCart.globalModeAlert.GlobalModeHelperWidget.modalInfo.imageUrl')}
    />
  );
}, equal);

const GlobalModeAlertMessage = memo(() => {
  const { t } = useTranslation();
  const { createdAt } = useShoppingCartRevisionContext();
  return (
    <>
      <div>{t('user.ShoppingCart.globalModeAlert.title', { date: formatDate(createdAt) })}</div>
      <GlobalModeHelperWidget />
    </>
  );
}, equal);
const GlobalModeAlert = () => {
  const params = useParams();
  if (params.mode !== CreationMode.GLOBAL) return null;
  return <Alert className="alert-info" type="info" showIcon description={<GlobalModeAlertMessage />} />;
};

export default memo(GlobalModeAlert, equal);
