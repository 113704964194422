import apollo from 'graphql/apollo';
import { userCategoryListQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';

function getCategoriesCache() {
  const data = apollo.readQuery({
    query: userCategoryListQuery,
  });
  return grabFirstGQLDataResult(data) || [];
}
export default getCategoriesCache;
