import { gql } from '@apollo/client';

const adminEmailPreferencesFragment = gql`
  fragment adminEmailPreferencesFragment on EmailPreferences {
    _id
    attachmentsSizeLimit
    signature
    bcc
    activeUserToBCC
  }
`;

export default adminEmailPreferencesFragment;
