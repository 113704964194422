import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import { Form } from 'formik-antd';
import FormItem from 'components/common/FormItem';
import equal from 'fast-deep-equal/es6/react';
import { memo, useMemo } from 'react';
import { useFormikContext } from 'formik';
import SaveChangesButton from 'components/common/SaveChangesButton';
import { updateTheme } from 'graphql/methods';
import I18nFormik from 'components/common/I18nFormik';
import corporateSettingsDefaults from 'constants/corporate-settings';
import toast from 'utils/toast';
import confirmModal from 'utils/confirmModal';
import i18n from 'i18n';
import { ColorPickerFormikInput, FontFormikInput } from './components/Inputs';
import classes from './CorporateIdentitySetting.module.less';
import ButtonColorPreview from './components/ButtonColorPreview';

const CorporatePrimaryColorPreview = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  const { primaryColor, fontFamily, color } = values;
  return (
    <ButtonColorPreview
      {...{ primaryColor, color, fontFamily }}
      text={t('admin.Setting.CorporateIdentity.CorporatePrimaryColor.preview')}
    />
  );
};
const confirmChangesModal = () => {
  return new Promise((resolve) => {
    confirmModal({
      title: i18n.t('admin.Setting.CorporateIdentity.confirmSubmit'),
      maskClosable: true,
      width: 800,
      onOk: () => resolve(true),
      onCancel: () => resolve(false),
    });
  });
};

function CorporatePrimaryColour({ primaryColor, color, fontFamily }) {
  const { t } = useTranslation();
  const initialValues = useMemo(
    () => ({
      primaryColor: primaryColor || corporateSettingsDefaults.primaryColor,
      color: color || corporateSettingsDefaults.color,
      fontFamily: fontFamily || corporateSettingsDefaults.fontFamily,
    }),
    [color, primaryColor, fontFamily],
  );
  return (
    <Card title={t('admin.Setting.CorporateIdentity.title')}>
      <I18nFormik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(main) => {
          updateTheme({ pdf: { main } })
            .then(() => toast.successDefault())
            .then(() => window.location.reload());
        }}
      >
        <div>
          <Form layout="vertical" className={classes.corporatePrimaryColorForm}>
            <div>
              <FormItem
                name="primaryColor"
                label={t('admin.Setting.CorporateIdentity.CorporatePrimaryColor.inputs.primaryColor')}
              >
                <ColorPickerFormikInput name="primaryColor" fast={false} />
              </FormItem>
              <FormItem
                name="fontFamily"
                label={t('admin.Setting.CorporateIdentity.CorporatePrimaryColor.inputs.fontFamily')}
              >
                <FontFormikInput name="fontFamily" />
              </FormItem>
              <FormItem name="color" label={t('admin.Setting.CorporateIdentity.CorporatePrimaryColor.inputs.color')}>
                <ColorPickerFormikInput fast={false} name="color" />
              </FormItem>
            </div>
            <CorporatePrimaryColorPreview />
          </Form>
          <SaveChangesButton
            style={{ marginRight: '16px', marginTop: 'unset' }}
            initialValues={initialValues}
            onClick={confirmChangesModal}
          />
        </div>
      </I18nFormik>
    </Card>
  );
}

export default memo(CorporatePrimaryColour, equal);
