/**
 * Validates an IBAN (International Bank Account Number).
 *
 * @function validateIban
 * @param {string} iban - The IBAN to validate.
 * @returns {boolean} - Returns true if the IBAN is valid, false otherwise.
 * @description
 * The function checks if the IBAN:
 * 1. Is not empty.
 * 2. Consists only of uppercase letters and numbers.
 * 3. Has a length between 15 and 35 characters.
 * 4. Starts with two uppercase letters.
 */
export const validateIban = (iban) => {
  if (!iban) {
    return false;
  }
  const rawIban = iban.replace(/[ ]/g, '');

  if (!rawIban.match(/^[A-Z0-9]+$/)) {
    return false; // Invalid characters
  }

  if (rawIban.length < 15 || rawIban.length > 35) {
    return false;
  }

  if (!/^[A-Z]{2}/.test(rawIban)) {
    return false;
  }

  return true;
};

/**
 * Validates a BIC (Bank Identifier Code).
 *
 * @function validateBic
 * @param {string} bic - The BIC to validate.
 * @returns {boolean} - Returns true if the BIC is valid, false otherwise.
 * @description
 * The function checks if the BIC:
 * 1. Is not empty.
 * 2. Is either 8 or 11 characters long.
 * 3. Starts with four uppercase letters (bank code).
 * 4. Has two uppercase letters at positions 5 and 6 (country code).
 * 5. Has two alphanumeric characters at positions 7 and 8 (location code).
 * 6. If it is 11 characters long, has three alphanumeric characters at positions 9 to 11 (optional branch code).
 */
export const validateBic = (bic) => {
  if (!bic) {
    return false;
  }

  // Check if the length is 8 or 11
  if (bic.length !== 8 && bic.length !== 11) {
    return false;
  }

  // Check if the first 4 characters are letters
  if (!/^[A-Z]{4}/.test(bic)) {
    return false;
  }
  // Check if the next 2 characters are letters (country code)
  if (!/^[A-Z]{2}/.test(bic.substring(4, 6))) {
    return false;
  }

  // Check if the next 2 characters are letters or digits (location code)
  if (!/^[A-Z0-9]{2}/i.test(bic.substring(6, 8))) {
    return false;
  }

  // If there's an optional branch code, check if it's 3 letters or digits
  if (bic.length === 11 && !/^[A-Z0-9]{3}/i.test(bic.substring(8, 11))) {
    return false;
  }

  return true;
};
