import { gql } from '@apollo/client';

const adminTenantCompanyFragment = gql`
  fragment adminTenantCompanyFragment on TenantCompany {
    companyName
    street
    streetNr
    cityCode
    city
    phoneNr
    country
    websiteUrl
    officeEmail
    creditorIdentNr
  }
`;

export default adminTenantCompanyFragment;
