import { gql } from '@apollo/client';

const userSigningProcessesClientQuery = gql`
  query signingProcessesQuery {
    signingProcesses {
      link @client
      projectName @client
      nameOfClient @client
      shoppingCartId @client
    }
  }
`;

export default userSigningProcessesClientQuery;
