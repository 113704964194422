import { Calc } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { FeeType } from 'constants/shoppingCart';
import { CalculationMode, HighlightBindingness, PaymentInterval } from 'constants/item';

/**
 * @typedef {Object} ActiveDiscount
 * @property {String} type - Type of discount ('percent' or 'absolute')
 * @property {Float} value - Value of discount (If discount is 10%, then the value is 10)
 * @property {String} name - Name of discount
 */

/**
 * @typedef {Object} ServicePriceInformation
 * @property {Boolean} isCustomPrice - Is the total price a custom price or a calulated price
 * @property {Boolean} isDiscounted - Is the total price discounted
 * @property {Boolean} isFixedMonthlyFee - Is fixed monthly fee type and is not payment interval one off (Prices of payment interval are always normally shown; Monthly and yearly prices are shown as included in combined company price)
 * @property {Boolean} isActualCost - Is service a service paid by actual cost
 * @property {Boolean} showCalculatedPrice - Should the calculated price be shown, if price is a custom price
 * @property {Boolean} shouldHighlightBindingness - Should price bindingness be highlighted
 * @property {String} highlightBindingness - Bingingness highlight
 * @property {String} paymentInterval - Payment interval of the service
 * @property {String} formattedTotalPriceOfService - Total price of service formatted
 * @property {String} formattedCalculatedPrice - Calculated price before discount formatted
 * @property {String} formattedCustomPrice - Custom price before discount formatted
 * @property {JSX.Element} formattedPriceComponent - Component to show total price (COntains price component or actual cost component or fixed fee component)
 */

/**
 * Calculates discounted custom price of a service
 * @param {Float} customPrice - Custom price of the service
 * @param {ActiveDiscount} activeDiscount - Active discount for the service
 * @returns {Float} discounted custom price of service
 */
const getDiscountedCustomPrice = (customPrice, activeDiscount) => {
  if (!activeDiscount || !activeDiscount.value || activeDiscount.value === 0) return customPrice;
  const { value, type } = activeDiscount;

  if (type === 'percent') return customPrice * (1 - value / 100);
  return customPrice - value;
};

/**
 * Summarieses the price informations of a service, formatted prices and component for the total price
 * @param {Object} item - Service object
 * @param {Object} shoppingCart - Shopping cart object
 * @param {ActiveDiscount} activeDiscount - Active discount on category level for this service
 * @param {Object} components - Components, which should be used for the different price modes for the total price
 * @param {JSX.ElementType} components.actualCostComponent - Component constructor used, if service is paid by actual cost
 * @param {JSX.ElementType} components.fixedFeeComponent - Component constructor used, if total price is a fixed fee price
 * @param {JSX.ElementType} components.priceComponent - Component constructor used, if total price is a normal price
 * @returns {ServicePriceInformation}
 */
export const getPriceInformationOfService = (
  item,
  shoppingCart,
  activeDiscount,
  { actualCostComponent, fixedFeeComponent, priceComponent },
) => {
  const { feeType, showDigits } = shoppingCart;
  const {
    discountedValue,
    value,
    customPrice,
    calculationMode,
    paymentInterval,
    highlightBindingness,
    showCalculatedPrice,
  } = item;

  const isFixedMonthlyFeeType = feeType === FeeType.FIXED_MONTHLY;
  const isActualCost = calculationMode === CalculationMode.ON_ACTUAL_COST;
  const isFixedMonthlyFee = paymentInterval !== PaymentInterval.ONE_OFF && isFixedMonthlyFeeType;
  const isCustomPrice = typeof customPrice === 'number';
  const isDiscounted = value !== discountedValue;
  const shouldHighlightBindingness = highlightBindingness !== HighlightBindingness.NO_HIGHLIGHT;

  const ActualCostComponent = actualCostComponent;
  const FixedFeeComponent = fixedFeeComponent;
  const PriceComponent = priceComponent;

  const formattedTotalPriceOfService = Calc.formatCurrency(
    isCustomPrice ? getDiscountedCustomPrice(customPrice, activeDiscount) : discountedValue,
    {
      showDigits,
    },
  );

  const formattedCalculatedPrice = Calc.formatCurrency(value, {
    showDigits,
  });

  const formattedCustomPrice = Calc.formatCurrency(isCustomPrice ? customPrice : 0, { showDigits });

  const priceInformations = {
    isCustomPrice,
    isDiscounted,
    isFixedMonthlyFee,
    isActualCost,
    showCalculatedPrice,
    shouldHighlightBindingness,
    highlightBindingness,
    paymentInterval,
    formattedTotalPriceOfService,
    formattedCalculatedPrice,
    formattedCustomPrice,
  };

  let formattedPriceComponent;
  if (isActualCost && !isFixedMonthlyFee) {
    formattedPriceComponent = <ActualCostComponent priceInformations={priceInformations} />;
  } else if (!isFixedMonthlyFee) {
    formattedPriceComponent = <PriceComponent priceInformations={priceInformations} />;
  } else {
    formattedPriceComponent = <FixedFeeComponent priceInformations={priceInformations} />;
  }
  priceInformations.formattedPriceComponent = formattedPriceComponent;
  return priceInformations;
};
