import { useVariablesContext, VATContext } from 'components/user/shoppingCart/context';
import equal from 'fast-deep-equal';
import { memo, useMemo } from 'react';
import { PaymentInterval } from 'constants/item';
import { TotalPricingCard } from 'pages/shoppingCartManagement/ShoppingCart/TotalPricing';
import { ShowVATProvider } from 'contexts/ShowVATContext';
import CompareToGlobalCard from 'components/user/compareToGlobal/CompareToGlobalCard';
import useFasterPreferences from 'hooks/user/useFasterPreferences';
import CategoryInView from './CategoryInView';
import TotalPricing from './TotalPricing';
import { useFormatActiveCategories } from '../hooks';

const Categories = ({ shoppingCart, categories }) => {
  const variables = useVariablesContext();
  const activeCategories = useFormatActiveCategories(shoppingCart.categories, categories);
  const { preferences } = useFasterPreferences({ shoppingCartId: shoppingCart._id });
  const items = useMemo(
    () =>
      activeCategories
        .map((category) => category.items)
        .flat()
        .flat(),
    [activeCategories],
  );
  const total = useMemo(
    () =>
      Object.values(PaymentInterval).reduce(
        (acc, paymentInterval) => ({ ...acc, [paymentInterval]: shoppingCart[paymentInterval] }),
        {},
      ),
    [shoppingCart],
  );
  if (!activeCategories.length) return null;
  return (
    <ShowVATProvider defaultValue={shoppingCart.showVat}>
      <VATContext.Provider value={shoppingCart.vat}>
        <CompareToGlobalCard variables={variables} isCategories={!!activeCategories.length}>
          <CategoryInView
            shoppingCartId={shoppingCart._id}
            feeType={shoppingCart.feeType}
            categories={activeCategories}
            showDigits={shoppingCart.showDigits}
            showPrices={shoppingCart.showPrices}
            vatType={shoppingCart.vatType}
            migrated={shoppingCart.migrated}
            showVat={shoppingCart.showVat}
          />
        </CompareToGlobalCard>
        <TotalPricingCard>
          <TotalPricing
            feeType={shoppingCart.feeType}
            isCategoryLevel={false}
            items={items}
            migrated={shoppingCart.migrated}
            showVat={shoppingCart.showVat}
            parent={activeCategories}
            showDigits={shoppingCart.showDigits}
            total={total}
            vatType={shoppingCart.vatType}
            monthlyPaymentPreferences={preferences?.monthlyPaymentPreferences}
          />
        </TotalPricingCard>
      </VATContext.Provider>
    </ShowVATProvider>
  );
};
export default memo(Categories, equal);
