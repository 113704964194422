import { Row, Col } from 'antd';
import classes from './KeyValueBlock.module.less';

/**
 * KeyValuePair component for the KeyValueBlock
 * @param {Object} inputParameters - Input parameters of the component
 * @param {Object} inputParameters.keyValuePair - Key value pair to show
 * @param {String} inputParameters.keyValuePair.key - Content for the key column
 * @param {String} inputParameters.keyValuePair.value - Content for the value column
 * @returns {JSX.Element} shows a row with two columns
 * @component
 */
const KeyValuePair = ({ keyValuePair }) => {
  const { key, value } = keyValuePair;
  return (
    <Row className="KeyValueBlockRow">
      <Col span={11} className={classes.columns}>
        <b>{key}</b>
      </Col>
      <Col span={2} />
      <Col span={11} className={classes.columns}>
        {value}
      </Col>
    </Row>
  );
};

/**
 * KeyValueBlock component for the service overview step of the signing process
 * @param {Object} inputParameters - Input parameters for the component
 * @param {Object[]} inputParameters.keyValuePairs - Collection of key value pairs
 * @param {String} inputParameters.keyValuePairs.key - Content for the key column
 * @param {String} inputParameters.keyValuePairs.value - Content for the value column
 * @param {String} inputParameters.className - Classname for the container of the key value block
 * @returns {JSX.Element} shows rows with two columns, one column is the key column, the other the value column
 * @component
 */
const KeyValueBlock = ({ keyValuePairs, className }) => {
  const rows = keyValuePairs.map((keyValuePair) => <KeyValuePair keyValuePair={keyValuePair} />);
  return <div className={className}>{rows}</div>;
};

export default KeyValueBlock;
