import apollo from 'graphql/apollo';
import { moveCategoryMutation } from 'graphql/mutations';

const moveCategory = async ({ _id, offset, order }, categoryListQuery) =>
  apollo.mutate({
    mutation: moveCategoryMutation,
    variables: { _id, offset, order },
    refetchQueries: [{ query: categoryListQuery }],
  });
export default moveCategory;
