import { grabFirstGQLDataResult } from 'utils/helpers';
import getUploadFileLink from './getUploadFileLink';
import setFileUploaded from './setFileUploaded';

const uploadFile = async ({ name, parentId, parentType, file, size }) => {
  const { data } = await getUploadFileLink({
    name,
    parentId,
    parentType,
    size,
  });
  const { _id: linkId, url } = grabFirstGQLDataResult(data);
  const options = {
    method: 'PUT',
    body: file,
  };
  await fetch(url, options);
  const result = await setFileUploaded({ _id: linkId });

  return { url: grabFirstGQLDataResult(result.data) };
};

export default uploadFile;
