import { TableMemo } from 'memo';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { columns } from './columns';

/**
 * DocumentTemplateTable component shows a table with all documents of a shopping cart with print and download functions
 * @param {Object} inputParameters - Input parameters of the component
 * @param {string} inputParameters.shoppingCartId - Id of shopping cart from which the documents should be showed
 * @param {Array} inputParameters.documents - An array of document templates to display in the table
 * @param {boolean} inputParameters.isAllDocumentsGenerated - A boolean indicating whether all documents have been generated
 * @param {React.Component} inputParameters.customBottomCell - A custom component to render in the bottom cell of the table
 * @param {boolean} inputParameters.displayIfEmptyCard - A boolean indicating whether to display the table if there are no documents to show
 * @param {string} inputParameters.companyName - The name of the company associated with the document templates
 * @param {Boolean} inputParameters.showBottomCell - Should the bottom cell be shown (default: true)
 * @returns {JSX.Element} - A table component that displays the document templates and allows the user to generate PDFs
 * @component
 */
export const DocumentTemplateTable = ({
  shoppingCartId,
  documents,
  isAllDocumentsGenerated,
  customBottomCell,
  displayIfEmptyCard,
  companyName,
  showBottomCell = true,
}) => {
  const [generatedFiles, setGeneratedPdfs] = useState({});
  const { t } = useTranslation();
  const addGeneratedPdf = useCallback((key, pdf) => setGeneratedPdfs((state) => ({ ...state, [key]: pdf })), []);

  const selectDatasource = () => {
    const CustomBottomCell = customBottomCell;

    if (documents || displayIfEmptyCard) {
      return [
        ...documents,
        showBottomCell
          ? { name: customBottomCell ? <CustomBottomCell /> : t('user.PrintShoppingCartPage.downloadAll'), _id: 'all' }
          : null,
      ].filter(Boolean);
    }

    return [];
  };

  return (
    <TableMemo
      columns={columns({
        documents,
        shoppingCartId,
        generatedFiles,
        addGeneratedPdf,
        isAllDocumentsGenerated,
        companyName,
      })}
      dataSource={selectDatasource()}
      scroll={{
        x: 390,
      }}
      pagination={false}
    />
  );
};
