import cn from 'classnames';
import classes from './ShortKeyValueBlock.module.less';

const ShortKeyValueBlockRow = ({ keyForValue, value }) => {
  return (
    <div className={cn(classes.ShortKeyValueBlockRow, 'ShortKeyValueBlockRow')}>
      <div className={classes.ShortKeyValueBlockKeyColumn}>
        <b>{keyForValue}</b>
      </div>
      <div className={classes.ShortKeyValueBlockValueColumn}>{value}</div>
    </div>
  );
};

const ShortKeyValueBlock = ({ keyValuePairs, className }) => {
  if (!keyValuePairs?.length) return null;

  const rows = keyValuePairs.map((keyValuePair) => {
    return <ShortKeyValueBlockRow key={keyValuePair.key} keyForValue={keyValuePair.key} value={keyValuePair.value} />;
  });

  return <div className={className}> {rows} </div>;
};

export default ShortKeyValueBlock;
