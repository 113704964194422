import { Collapse } from 'antd';
import concatClassNames from 'utils/classNames';
import classes from './InlineCollapsable.module.less';

const { Panel } = Collapse;

/**
 * Inline collapsable, which consists by a header with arrow icon and the content area, which is shown if the collapsable is open (can be opened by click on the header)
 * @param {Object} inputParameters - Input parameters of the component
 * @param {String|JSX.Element} inputParameters.header - Text or component for the collapsable header
 * @param {Boolean} inputParameters.open - Should the collapsable initial be open
 * @param {JSX.Element[]} inputParameters.children - Content of the collapsable
 * @returns {JSX.Element} collapsable component without a box
 * @component
 */
const Collapsable = ({ header, open, children }) => {
  return (
    <Collapse className={classes.collapsable} defaultActiveKey={open && 0} ghost>
      <Panel className={concatClassNames(classes.collapsable, classes.collapsablePanel)} header={header}>
        {children}
      </Panel>
    </Collapse>
  );
};

export default Collapsable;
