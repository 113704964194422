import { Alert } from 'antd';
import Switch from 'components/common/Switch';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const DigitalSignatureEnabled = ({ t, disabled = false }) => {
  const { t: translation } = useTranslation();
  const { values, initialValues } = useFormikContext();
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    if (!values.digitalSignatureEnabled && initialValues.digitalSignatureEnabled) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  }, [values, initialValues, setShowWarning]);

  return (
    <>
      <Switch
        name="digitalSignatureEnabled"
        label={t('admin.Setting.DigitalSignaturePreferences.generalSettings.digitalSignatureEnabled.label')}
        tooltip={t('admin.Setting.DigitalSignaturePreferences.generalSettings.digitalSignatureEnabled.tooltip')}
        disabled={disabled}
        defaultChildren
      />
      {showWarning && (
        <Alert
          showIcon
          type="warning"
          message={translation(
            'admin.Setting.DigitalSignaturePreferences.generalSettings.digitalSignatureEnabled.warning.title',
          )}
          description={translation(
            'admin.Setting.DigitalSignaturePreferences.generalSettings.digitalSignatureEnabled.warning.description',
          )}
        />
      )}
      {disabled ? (
        <Alert
          type="warning"
          message={translation(
            'admin.Setting.DigitalSignaturePreferences.generalSettings.digitalSignatureEnabled.disabledInfo',
          )}
        />
      ) : null}
    </>
  );
};

export default DigitalSignatureEnabled;
