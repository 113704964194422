import { Image, Row } from 'antd';
import fallback from 'assets/images/fallback.jpeg';

export default function BlockPreviewContent({ block, hideBlockName, title }) {
  if ((hideBlockName || !block.displayName) && !block.infoText && !block.previewImageSrc) return null;
  return (
    <>
      {title ? <h4>{title}</h4> : null}
      {!hideBlockName ? <h5>{block.displayName}</h5> : null}
      {block.infoText ? <p>{block.infoText}</p> : null}
      {block.previewImageSrc && (
        <Row>
          <Image
            width="100%"
            src={block.previewImageSrc} // display fallback when src is missing
            fallback={fallback} // display fallback when src is bad
            preview={block.previewImageSrc ? undefined : false} // hide preview if src = fallback
          />
        </Row>
      )}
    </>
  );
}
