import { gql } from '@apollo/client';

const revokeDigitalSignatureMutation = gql`
  mutation revokeDigitalSignatureMutation($shoppingCartId: ID!, $shouldNotifySignees: Boolean!, $internalNote: String) {
    revokeShoppingCart(
      shoppingCartId: $shoppingCartId
      shouldNotifySignees: $shouldNotifySignees
      internalNote: $internalNote
    ) {
      _id
    }
  }
`;

export default revokeDigitalSignatureMutation;
