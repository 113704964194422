import { Card as CardAntd } from 'antd';
import equal from 'fast-deep-equal/es6/react';
import { memo } from 'react';
import classes from './Card.module.less';

export const formatCartTitle = ({ icon, title, subtitle } = {}) => {
  if (title || icon)
    return (
      <>
        <div className={classes.cartTitle}>
          {icon || null}
          <div className={classes.title}>{title}</div>
        </div>
        {subtitle}
      </>
    );
  return null;
};

const Card = ({ icon, title, subtitle, children, ...props }) => {
  return (
    <CardAntd className={classes.card} title={formatCartTitle({ title, icon, subtitle })} {...props}>
      {children}
    </CardAntd>
  );
};

export default memo(Card, equal);
