import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { VariableSizeGrid as Grid } from 'react-window';
import ResizeObserver from 'rc-resize-observer';
import classNames from 'classnames';
import { Table, Checkbox } from 'antd';
import { without } from 'lodash';
import classes from './VirtualTable.module.less';

function VirtualTable(props) {
  const { columns, scroll, isChecked } = props;
  const [tableWidth, setTableWidth] = useState(0);
  const widthColumnCount = columns.filter(({ width }) => !width).length;
  const sumWidthColumn = columns.reduce((accumulator, currentValue) => accumulator + (currentValue?.width || 0), 0);
  const mergedColumns = columns.map((column) => {
    if (column.width) {
      return column;
    }
    return { ...column, width: Math.floor((tableWidth - sumWidthColumn) / widthColumnCount) };
  });
  const gridRef = useRef();
  const [connectObject] = useState(() => {
    const obj = {};
    Object.defineProperty(obj, 'scrollLeft', {
      get: () => null,
      set: (scrollLeft) => {
        if (gridRef.current) {
          gridRef.current.scrollTo({
            scrollLeft,
          });
        }
      },
    });
    return obj;
  });

  const resetVirtualGrid = () => {
    gridRef.current?.resetAfterIndices({
      columnIndex: 0,
      shouldForceUpdate: true,
    });
  };

  useEffect(() => resetVirtualGrid, [tableWidth]);

  const renderVirtualList = (rawData, { scrollbarSize, ref, onScroll }) => {
    // eslint-disable-next-line no-param-reassign
    ref.current = connectObject;
    const totalHeight = rawData.length * 400;
    return (
      <Grid
        ref={gridRef}
        className="virtual-grid"
        columnCount={mergedColumns.length}
        columnWidth={(index) => {
          const { width } = mergedColumns[index];
          return totalHeight > scroll.y && index === mergedColumns.length - 1 ? width - scrollbarSize - 1 : width;
        }}
        height={scroll.y}
        rowCount={rawData.length}
        rowHeight={() => 400}
        width={tableWidth}
        onScroll={({ scrollLeft }) => {
          onScroll({
            scrollLeft,
          });
        }}
      >
        {({ columnIndex, rowIndex, style }) => {
          const currentColumn = mergedColumns[columnIndex];
          const currentData = rawData[rowIndex];
          let content = currentData[currentColumn.dataIndex];
          if (currentColumn?.render) {
            content = currentColumn.render(content, currentData);
          }
          return (
            <div
              style={{ padding: '16px 16px', overflowWrap: 'break-word', overflowY: 'auto', ...style }}
              className={classNames('virtual-table-cell', {
                'virtual-table-cell-last': columnIndex === mergedColumns.length - 1,
                [classes.checkedRow]: isChecked(currentData),
              })}
            >
              {content}
            </div>
          );
        }}
      </Grid>
    );
  };

  return (
    <ResizeObserver
      onResize={({ width }) => {
        setTableWidth(width);
      }}
    >
      <Table
        {...props}
        className="virtual-table"
        columns={mergedColumns}
        pagination={false}
        components={{
          body: renderVirtualList,
        }}
      />
    </ResizeObserver>
  );
} // Usage

export default ({ columns: _columns, dataSource, rowSelection, height = 500 }) => {
  const isChecked = useCallback(
    ({ key }) => rowSelection?.selectedRowKeys.includes(key),
    [rowSelection?.selectedRowKeys],
  );
  const columns = useMemo(() => {
    if (rowSelection) {
      const { selectedRowKeys, getCheckboxProps, onChange } = rowSelection;
      const plainOptions = dataSource
        .filter((data) => {
          const checkboxProps = getCheckboxProps(data);
          if (checkboxProps?.disabled) return false;
          return true;
        })
        .map((data) => {
          const checkboxProps = getCheckboxProps(data);
          return checkboxProps?.key;
        });
      const indeterminate = selectedRowKeys.length && selectedRowKeys.length < plainOptions.length;
      const checkAll = selectedRowKeys.length === plainOptions.length;
      const onChangeSelectedRow = (key) => {
        const list = selectedRowKeys.includes(key) ? without(selectedRowKeys, key) : [...selectedRowKeys, key];
        onChange(list);
      };
      const onCheckAllChange = () => {
        onChange(checkAll ? [] : plainOptions);
      };
      return [
        {
          title: <Checkbox onChange={onCheckAllChange} indeterminate={indeterminate} checked={checkAll} />,
          render: (rowValue, data) => {
            const { key, disabled } = getCheckboxProps(data);
            return (
              <Checkbox onChange={() => onChangeSelectedRow(key)} checked={isChecked({ key })} disabled={disabled} />
            );
          },
          key: 'status',
          width: 50,
        },
        ..._columns,
      ];
    }
    return _columns;
  }, [_columns, dataSource, isChecked, rowSelection]);
  return (
    <VirtualTable
      columns={columns}
      isChecked={isChecked}
      dataSource={dataSource}
      scroll={{
        y: height,
        x: '100vw',
      }}
    />
  );
};
