import { Button } from 'antd';
import { FullscreenOutlined } from '@ant-design/icons';
import concatClassNames from 'utils/classNames';
import StickyBarOnScrollPane from './StickyBarOnScrollPane';
import ScaleControl from './ScaleControl';
import classes from './ScaleButtonBar.module.less';

/**
 * ScaleButtonBar with scale control elements and the full screen button
 * @param {Object} inputParameters - Input parameters for component
 * @param {Function} inputParameters.onUpdateScale - Function, which is called, when the scale will be updated with the new scale value
 * @param {Number} inputParameters.currentScale - Current scale value
 * @param {JSX.Element} inputParameters.scrollPane - Container on which the bar should stick on
 * @param {Function} inputParameters.onOpenFullscreenViewer - (Optional) Function, which us called on the press on the full screen button (Button only rendered, if function is provided)
 * @param {String} inputParameters.className - Classname for the sticky bar
 * @returns {JSX.Element} ScaleButtonBar component for the pdf viewer as sticky bar
 * @component
 */
const ScaleButtonBar = ({ onUpdateScale, currentScale, scrollPane, onOpenFullscreenViewer, className }) => {
  return (
    <StickyBarOnScrollPane
      scrollPane={scrollPane}
      className={concatClassNames(classes.ScaleButtonBarStickyBar, className)}
    >
      <div className={concatClassNames(classes.ScaleButtonBar, 'ScaleButtonBar_InnerScaleButtonBar')}>
        <ScaleControl
          onUpdateScale={onUpdateScale}
          currentScale={currentScale}
          className="ScaleButtonBar_ScaleControl"
        />
        {onOpenFullscreenViewer ? (
          <Button icon={<FullscreenOutlined />} type="text" onClick={onOpenFullscreenViewer} />
        ) : null}
      </div>
    </StickyBarOnScrollPane>
  );
};

export default ScaleButtonBar;
