import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useFunctionToRefCB } from 'memo';
import i18n from 'i18n';

const RefreshI18nErrorsFormik = () => {
  const { errors, setFieldTouched } = useFormikContext();
  const refreshFnRef = useFunctionToRefCB(() =>
    window.setTimeout(() => Object.keys(errors).forEach((fieldName) => setFieldTouched(fieldName)), 0),
  );
  useEffect(() => {
    i18n.on('languageChanged', refreshFnRef);
    return () => {
      i18n.off('languageChanged', refreshFnRef);
    };
  }, [refreshFnRef]);
  return null;
};

export default RefreshI18nErrorsFormik;
