import { gql } from '@apollo/client';
import { userStatusFragment } from 'graphql/fragments';

const userGetStatuses = gql`
  query userGetStatuses($type: String) {
    getStatuses(type: $type) {
      ...userStatusFragment
    }
  }
  ${userStatusFragment}
`;

export default userGetStatuses;
