import { FaCreditCard } from 'react-icons/fa';
import SignDocumentsLayout from '../../../components/layouts/SignDocumentsLayout';
import { Sans30px400, Sans30px600, Sans35px600 } from '../Text/Text';
import { Br050, Br100, Br150, Br200 } from '../Text/Br';
import NextButton from '../NextButton';
import Checkbox from '../Checkbox';
import Input from '../Input';

const SEPA = ({ setPage }) => {
  return (
    <SignDocumentsLayout>
      <Sans35px600 icon={<FaCreditCard />}>SEPA</Sans35px600>
      <Br100 />
      <Sans30px600 mobileTextStyle={{ fontSize: 14 }}>Please give us your SEPA mandate</Sans30px600>
      <Br150 />
      <Sans30px400 mobileTextStyle={{ fontSize: 14 }}>Please enter your Bank Account Owner</Sans30px400>
      <Br050 />
      <Input />
      <Br100 />
      <Sans30px400 mobileTextStyle={{ fontSize: 14 }}>Please enter your IBAN</Sans30px400>
      <Br050 />
      <Input />
      <Br100 />
      <Sans30px400 mobileTextStyle={{ fontSize: 14 }}>Please enter your BIC</Sans30px400>
      <Br050 />
      <Input />
      <Br100 />
      <Checkbox>
        <Sans30px400>Yes, I give you permission for SEPA</Sans30px400>
      </Checkbox>
      <Br200 />
      <NextButton
        onClick={() => {
          setPage('done');
        }}
      >
        Next
      </NextButton>
    </SignDocumentsLayout>
  );
};
export default SEPA;
