import React from 'react';
import classNames from 'classnames';
import classes from './classes.module.less';

export const Menu = React.forwardRef(({ className, ...props }, ref) => (
  <div {...props} ref={ref} className={classNames(classes.menu, className)} />
));

export const Toolbar = React.forwardRef(({ className, ...props }, ref) => (
  <Menu {...props} ref={ref} className={classes.toolbar} />
));
