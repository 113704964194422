import { gql } from '@apollo/client';
import adminVariableFragment from './adminVariableFragment';

const adminVariableListFragment = gql`
  fragment adminVariableListFragment on Variables {
    _id
    variables {
      ...adminVariableFragment
    }
  }
  ${adminVariableFragment}
`;

export default adminVariableListFragment;
