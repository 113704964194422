import PageContainer from 'components/layout/PageContainer';
import { useTranslation } from 'react-i18next';
import EmptyBox from 'components/common/EmptyBox';
import { useYourLicense } from 'graphql/hooks';
import { TableMemo } from 'memo';
import { Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import apollo from 'graphql/apollo';
import { isTenantOnboardingCompletedQuery } from 'graphql/queries';
import moment from 'moment';

const scaleTableColumns = () => [
  {
    title: '',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '',
    dataIndex: 'value',
    key: 'value',
  },
];

function LicenseOverviewSetting() {
  const { t } = useTranslation();
  const { data } = useYourLicense();
  const tableData = [
    {
      name: t(`admin.Setting.LicenseOverview.columns.nrOfCompaniesLimit`),
      value: data?.nrOfCompaniesLimit,
    },
    {
      name: t(`admin.Setting.LicenseOverview.columns.nrOfCompaniesCreated`),
      value: data?.nrOfCompaniesCreated,
    },
    {
      name: t(`admin.Setting.LicenseOverview.columns.nrOfUsersLimit`),
      value: data?.nrOfUsersLimit,
    },
    {
      name: t(`admin.Setting.LicenseOverview.columns.nrOfUsersCreated`),
      value: data?.nrOfUsersCreated,
    },
    {
      name: t(`admin.Setting.LicenseOverview.columns.licenseStarted`),
      value: data?.licenseStarted ? moment(data?.licenseStarted).format('DD.MM.YYYY') : null,
    },
  ].filter(({ value }) => value);

  return (
    <PageContainer
      title={t('admin.Setting.LicenseOverview.title')}
      right={
        <div className="ant-modal-confirm-btns">
          <Button
            icon={<ReloadOutlined />}
            onClick={() => {
              apollo.writeQuery({
                query: isTenantOnboardingCompletedQuery,
                data: { isTenantOnboardingCompleted: false },
              });
            }}
            type="primary"
          >
            {t('admin.Setting.LicenseOverview.restartOnboarding')}
          </Button>
        </div>
      }
    >
      <TableMemo
        locale={{ emptyText: <EmptyBox label={t('admin.itemModal.inputs.scales.emptyScaleList')} /> }}
        bordered
        pagination={false}
        dataSource={tableData}
        columns={scaleTableColumns({
          t,
          tableData,
        })}
      />
    </PageContainer>
  );
}
export default LicenseOverviewSetting;
