import Card from 'components/common/Card';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { ControlOutlined } from '@ant-design/icons';
import { useFormikField } from 'hooks/common/useFormikField';
import { MonthlyPaymentMode } from './GeneralSettingsCard/MonthlyPaymentMode';
import { TitleMonthlyPayment } from './GeneralSettingsCard/TitleMonthlyPayment';
import { RoundingOptions } from './GeneralSettingsCard/RoundingOptions';

const MONTHLY_ADVANCED_ROUNDING_ENABLED = false;

const GeneralSettingsCard = () => {
  const { t } = useTranslation();
  const { value: monthlyPaymentMode } = useFormikField('monthlyPaymentMode');
  return (
    <Card
      icon={<ControlOutlined />}
      title={t('admin.Setting.MonthlyPaymentPreferences.generalSettings.cardLabel')}
      style={{ padding: 0 }}
    >
      <Form layout="vertical">
        <MonthlyPaymentMode t={t} />
        {monthlyPaymentMode !== 'disabled' ? <TitleMonthlyPayment t={t} /> : null}
        {monthlyPaymentMode !== 'disabled' && MONTHLY_ADVANCED_ROUNDING_ENABLED ? <RoundingOptions t={t} /> : null}
      </Form>
    </Card>
  );
};

export default GeneralSettingsCard;
