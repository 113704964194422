/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
import * as Sentry from '@sentry/react';
import LZString from 'lz-string';
import { CaptureConsole } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import apollo from 'graphql/apollo';
import { groupBy, pick } from 'lodash';
import { serializeError } from 'serialize-error';
import { consoleLogsQueue } from './sentryConsoleLogs';

const attachmentUrlFromDsn = (dsn, eventId) => {
  const { host, path, projectId, port, protocol, user } = dsn;
  return `${protocol}://${host}${port !== '' ? `:${port}` : ''}${
    path !== '' ? `/${path}` : ''
  }/api/${projectId}/events/${eventId}/attachments/?sentry_key=${user}&sentry_version=7&sentry_client=custom-javascript`;
};

function chunkSubstr(str, size) {
  const numChunks = Math.ceil(str.length / size);
  const chunks = new Array(numChunks);

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size);
  }

  return chunks;
}
export const JSONStringifyCircular = (objects) => JSON.stringify(serializeError(objects));
let errorInBeforeSendLogged = false;
const logErrorInBeforeSend = (...params) => {
  errorInBeforeSendLogged || (errorInBeforeSendLogged = console.error(...params) || 1);
};

const maxCharsInOneContext = 8192 - 42; // 8150

// example request size is 170kb
// 10=>94kb
// 21 => 188kb

const maxContexts = 20;

export default () =>
  console.log('Sentry is enabled') ||
  Sentry.init({
    dsn: 'https://df50adc75c0f4dd5b05b59f852f1ef2f@o1166963.ingest.sentry.io/6257607',
    autoSessionTracking: true,
    ignoreErrors: ['ResizeObserver'],
    integrations: [
      new Integrations.BrowserTracing(),
      new CaptureConsole({
        levels: ['warn', 'error'],
      }),
    ],
    beforeSend: (data) => {
      try {
        if (
          false ||
          data.message.includes('hared package version diffe') ||
          data.message.includes('is deprecated') ||
          data.message.includes('If you intentionally want it to appear in the DOM') ||
          data.message.includes('has been renamed, and is not recommended for use') ||
          data.message.includes('%c next state color:') ||
          data.message.includes('$reactTemp1') ||
          data.message.includes('Could not find Fiber with id') ||
          data.message.includes('Each child in a list should have a unique') ||
          data.message.includes("Can't perform a React state update on an unmounted component") ||
          data.message.includes('The message port closed before a response was received') ||
          data.message.includes('cannot appear as a child of') ||
          data.message.includes('User not found') ||
          data.message.includes('Duplicated') ||
          data.message.includes('Password or email are incorrect')
        )
          return null;
      } catch (e) {
        0;
      }

      // sentry max request size is 200kb
      try {
        const getChunks = (obj, fullData) => {
          const compressed = LZString.compressToEncodedURIComponent(JSONStringifyCircular(obj));
          return fullData ? compressed : chunkSubstr(compressed, maxCharsInOneContext);
        };

        const data0 = {};
        try {
          data0.body = document.querySelector('body').outerHTML;
          data0.consoleLogsQueue = JSONStringifyCircular(consoleLogsQueue.queue);
        } catch (e) {
          logErrorInBeforeSend(e);
        }

        const data1 = { ...data0 };
        try {
          data1.activeQueries = [...apollo.cache.watches].map((e) => [
            e?.query?.definitions?.[0]?.name?.value || 'unknown query',
            e.variables,
          ]);
        } catch (e) {
          logErrorInBeforeSend(e);
        }

        const data2 = { ...data1 };
        try {
          data2.formikRef = JSON.stringify(window?.formikRef?.current, (k, v) => (v === undefined ? 'undefined' : v));
        } catch (e) {
          logErrorInBeforeSend(e);
        }

        const data3 = { ...data2 };
        try {
          const maxEntriesPerTypename = 15;
          const allowedKeys = Object.values(groupBy(Object.keys(apollo.cache.data.data), (k) => k.split('___')[0]))
            .filter((ids) => ids.length < maxEntriesPerTypename)
            .map((ids) => ids)
            .flat();
          data3.smallApolloCache = pick(apollo.cache.data.data, allowedKeys);
        } catch (e) {
          logErrorInBeforeSend(e);
        }

        const data4 = { ...data3 };
        try {
          data4.apolloCache = apollo.cache.data.data;
          delete data4.smallApolloCache;
        } catch (e) {
          logErrorInBeforeSend(e);
        }

        let sendMaxDataAsAttachment;

        let chunks = null;
        [data4, data3, data2, data1, data0].find((currentData, index) => {
          chunks = getChunks(currentData);
          if (chunks.length < maxContexts) return true;
          if (index === 0) sendMaxDataAsAttachment = true;
          return false;
        });

        if (!data.contexts) data.contexts = {};
        chunks.forEach((c, index) => {
          data.contexts[`JSON_LZString_${index}`] = { data: c };
        });
        if (sendMaxDataAsAttachment) {
          try {
            const client = Sentry.getCurrentHub().getClient();
            const endpoint = attachmentUrlFromDsn(client.getDsn(), data.event_id);
            const formData = new FormData();
            formData.append(
              'my-attachment',
              new Blob([getChunks(data4, true)], {
                type: 'application/json',
              }),
              'logs.json',
            );
            fetch(endpoint, {
              method: 'POST',
              body: formData,
            }).catch((e) => {
              // we have to catch this otherwise it throws an infinite loop in Sentry
              logErrorInBeforeSend(e);
            });
          } catch (e) {
            logErrorInBeforeSend(e);
          }
        }
      } catch (e) {
        logErrorInBeforeSend(e);
      }
      return data;
    },

    tracesSampler: (samplingContext) => {
      if (samplingContext.transactionContext.op === 'navigation') return 0;
      return 1;
    },
  });
