import { Transforms } from 'slate';

export const allModifiers = ['bold', 'italic', 'underline'];

export const withNoMarks = (editor) => {
  const { normalizeNode } = editor;
  // eslint-disable-next-line no-param-reassign
  editor.insertData = (data) => {
    if (data instanceof window.DataTransfer) {
      const text = data.getData('text/plain');
      Transforms.insertText(editor, text);
    } else Transforms.insertData(editor, data);
  };
  // eslint-disable-next-line no-param-reassign
  editor.normalizeNode = ([node, path]) => {
    const marks = allModifiers.filter((key) => key in node);
    if (marks.length) {
      Transforms.unsetNodes(editor, marks, {
        at: path,
      });
    }

    return normalizeNode([node, path]);
  };
  return editor;
};
