import { Skeleton } from 'antd';
import equal from 'fast-deep-equal/es6/react';
import { memo, useMemo } from 'react';
import { useCachedQuery } from 'graphql/utils';
import { adminPersonsInTenantQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';
import Person from './Person';

function PersonList() {
  const { data, loading } = useCachedQuery(adminPersonsInTenantQuery);
  const persons = useMemo(() => {
    return grabFirstGQLDataResult(data);
  }, [data]);
  return !data && loading ? (
    <Skeleton title loading active />
  ) : (
    persons.map((person) => <Person key={person._id} {...person} />)
  );
}

export default memo(PersonList, equal);
