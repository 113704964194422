import { Button } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { DIGITAL_SIGNATURE_STATE } from '@JavaScriptSuperstars/kanzleipilot-shared/lib/digitalSignature';
import EditShoppingCartModal from 'components/user/shoppingCart/modals/EditShoppingCartModal';
import { useCachedQuery } from 'graphql/utils';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { userEditShoppingCartQuery } from 'graphql/queries';
import RevokeDigitalSignatureProcessModal from '../RevokeDigitalSignatureProcessModal/RevokeDigitalSignatureProcessModal';

/**
 * EditShoppingCartButton component for start the edit process for a shopping cart on shopping cart view page
 * @param {Object} inputParameter
 * @param {string} inputParameter._id - Database id of shopping cart object, which should be edit
 * @param {string} inputParameter.className - Css classname for button style
 * @param {boolean} inputParameter.disabled - sets disabled state of the button
 * @returns {JSX.Element} EditShoppingCartButton component for shopping cart view page
 */

const EditShoppingCartButton = ({ _id, className, disabled }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isShowEditModal, setIsShowEditModal] = useState(false);
  const [showRevokeModal, setShowRevokeModal] = useState(false);

  const { data, loading } = useCachedQuery(userEditShoppingCartQuery, {
    variables: { _id },
    fetchPolicy: 'cache-and-network',
  });

  const { isLatest, migrated, digitalSignatureState } = useMemo(() => grabFirstGQLDataResult(data) ?? {}, [data]);

  const openEditShoppingCartRevisionModal = () => {
    if (digitalSignatureState === DIGITAL_SIGNATURE_STATE.STARTED) setShowRevokeModal(true);
    else setIsShowEditModal(true);
  };

  const hideEditModal = () => {
    setIsShowEditModal(false);
  };

  const closeRevokeModal = () => {
    setShowRevokeModal(false);
  };

  if (data && !isLatest) return null;
  return (
    <>
      <Button
        icon={<EditFilled />}
        disabled={(!data && loading) || disabled}
        loading={!data && loading}
        onClick={openEditShoppingCartRevisionModal}
        className={className}
      >
        {t('viewer.ShoppingCartView.editShoppingCart')}
      </Button>
      {showRevokeModal && (
        <RevokeDigitalSignatureProcessModal
          shoppingCartId={_id}
          onCloseModal={closeRevokeModal}
          onRevokeStarted={() => setIsShowEditModal(true)}
          isForEditShoppingCart
        />
      )}
      <EditShoppingCartModal
        _id={_id}
        navigate={navigate}
        isShowEditModal={isShowEditModal}
        hideModal={hideEditModal}
        migrated={migrated}
      />
    </>
  );
};

export default EditShoppingCartButton;
