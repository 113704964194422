export default [
  {
    columnTarget: 'identifier',
    required: true,
    possibleNames: ['identifier', 'Mandantennummer', 'Zentrale Mandantennummer'],
  },
  { columnTarget: 'name', required: true, possibleNames: ['name'] },
  { columnTarget: 'importCompanyType', possibleNames: ['importCompanyType', 'Unternehmensform'] },
  { columnTarget: 'commercialObject', possibleNames: ['commercialObject', 'Unternehmensgegenstand'] },
  { columnTarget: 'street', required: true, possibleNames: ['street', 'Straße'] },
  { columnTarget: 'houseNumber', possibleNames: ['houseNumber', 'streetNr', 'Straße-Nr'] },
  { columnTarget: 'city', required: true, possibleNames: ['city', 'Ort'] },
  { columnTarget: 'cityCode', required: true, possibleNames: ['cityCode', 'PLZ'] },
  { columnTarget: 'country', required: true, possibleNames: ['country', 'Land'] },
  { columnTarget: 'importSalutation', possibleNames: ['importSalutation', 'Anrede'] },
  { columnTarget: 'importAcademicTitle', possibleNames: ['importAcademicTitle', 'Akademischer Grad'] },
  { columnTarget: 'importEmail', possibleNames: ['importEmail', 'E-Mail'] },
];
