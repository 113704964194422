import { gql } from '@apollo/client';
import { userCompanyFragment } from 'graphql/fragments';

const userCompanyQuery = gql`
  query userCompanyQuery($_id: ID!, $initializationConfigDate: DateTime) {
    companyById(_id: $_id, initializationConfigDate: $initializationConfigDate) {
      ...userCompanyFragment
    }
  }
  ${userCompanyFragment}
`;

export default userCompanyQuery;
