import FormItem from 'antd/lib/form/FormItem';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button, Image, Row, Upload, Empty, Space } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import fallback from 'assets/images/fallback.jpeg';
import cn from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import toast from 'utils/toast';
import Resizer from 'react-image-file-resizer';
import styles from './FormikImage.module.less';

const getLink = (value) => {
  if (!value) return '';
  if (typeof value === 'string') {
    return value;
  }
  const src = URL.createObjectURL(value);
  return src;
};

const ImageUpload = ({ uploadClassName, uploadProps, fileProps }) => {
  const { form, name, control, uploadLabel, showImage } = uploadProps;
  const { allowSVG, maxWidthImage, maxHeightImage, maxSizeSVG } = fileProps;
  const { t } = useTranslation();
  const setFieldValueAndTouched_ = form.setFieldValueAndTouched;
  const setFieldValueAndTouched = useCallback(
    (v) => setFieldValueAndTouched_(name, v),
    [name, setFieldValueAndTouched_],
  );
  return (
    <Upload
      className={uploadClassName}
      maxCount={1}
      accept={`.jpeg,.png,.jpg${allowSVG ? ',.svg' : ''}`}
      beforeUpload={(file) => {
        const fileType = file.type;
        const img = new window.Image();
        img.onload = function onload() {
          if (fileType === 'image/svg+xml') {
            if (file.size > maxSizeSVG) {
              toast.error(t('admin.Setting.CorporateIdentity.TenantLogo.bigImage'));
            } else setFieldValueAndTouched(file);
          }
          // eslint-disable-next-line react/no-this-in-sfc
          else if (this.naturalWidth > maxWidthImage || this.naturalHeight > maxHeightImage) {
            let type = 'JPEG';
            if (file.name.toLowerCase().endsWith('.png')) type = 'PNG';
            Resizer.imageFileResizer(
              file, // Is the file of the image which will resized.
              1000, // Is the maxWidth of the resized new image.
              1000, // Is the maxHeight of the resized new image.
              type, // Is the compressFormat of the resized new image.
              95, // Is the quality of the resized new image.
              0, // Is the degree of clockwise rotation to apply to uploaded image.
              (newFile) => setFieldValueAndTouched(newFile), // Is the callBack function of the resized new image URI.
              'file', // Is the output type of the resized new image.
              null, // Is the minWidth of the resized new image.
              null, // Is the minHeight of the resized new image.
            );
          } else setFieldValueAndTouched(file);
        };
        img.src = getLink(file);

        return true;
      }}
      listType="picture"
      showUploadList={
        showImage
          ? false
          : {
              showDownloadIcon: false,
              showRemoveIcon: false,
            }
      }
    >
      {control || <Button icon={<UploadOutlined />}>{uploadLabel}</Button>}
    </Upload>
  );
};

const FormikImage = ({
  showResetButton,
  resetButtonLabel,
  placeholderText,
  field,
  form,
  containerClassName,
  showImage,
  showEmptyIfNoSrc,
  uploadClassName,
  name,
  control,
  uploadLabel,
  hideFallback,
  allowSVG = false,
  maxSizeSVG = 5242880, // 5 mb
  maxWidthImage = 1000,
  maxHeightImage = 1000,
}) => {
  const { value } = field;
  const [src, setSrc] = useState(getLink(value));
  const setFieldValueAndTouched_ = form.setFieldValueAndTouched;
  const setFieldValueAndTouched = useCallback(
    (v) => setFieldValueAndTouched_(name, v),
    [name, setFieldValueAndTouched_],
  );

  useEffect(() => {
    setSrc(getLink(value));
  }, [value]);

  const uploadProps = { form, name, control, uploadLabel, showImage };
  const fileProps = { allowSVG, maxWidthImage, maxHeightImage, maxSizeSVG };

  const onResetCustomSignatureImage = () => {
    setFieldValueAndTouched('reset');
  };

  return (
    <div className={cn(styles.formikImageContainer, containerClassName)}>
      {showImage ? (
        <Row>
          {showEmptyIfNoSrc && (src === '' || src === 'reset') ? (
            <Empty description={placeholderText} style={{ width: '100%', height: '200px' }}>
              <ImageUpload uploadClassName={uploadClassName} uploadProps={uploadProps} fileProps={fileProps} />
            </Empty>
          ) : (
            <Image
              className={styles.formikImage}
              src={src || (hideFallback ? null : fallback)} // display fallback when src is missing
              fallback={fallback} // display fallback when src is bad
              preview={src ? undefined : false} // hide preview if src = fallback
            />
          )}
        </Row>
      ) : null}
      <Row>
        {showEmptyIfNoSrc && (src === '' || src === 'reset') ? null : (
          <Space>
            <ImageUpload uploadClassName={uploadClassName} uploadProps={uploadProps} fileProps={fileProps} />
            {showResetButton && <Button onClick={onResetCustomSignatureImage}>{resetButtonLabel}</Button>}
          </Space>
        )}
      </Row>
    </div>
  );
};

const FormikImageField = ({
  showResetButton,
  resetButtonLabel,
  placeholderText,
  name,
  label,
  uploadLabel,
  showImage = true,
  showEmptyIfNoSrc,
  control,
  uploadClassName,
  containerClassName,
  hideFallback,
  tooltip,
  allowSVG,
  maxSizeSVG,
  maxWidthImage,
  maxHeightImage,
}) => {
  const { t } = useTranslation();
  return (
    <FormItem name={name} label={t(label)} tooltip={tooltip}>
      <Field name={name}>
        {({ field, form }) => (
          <FormikImage
            showResetButton={showResetButton}
            resetButtonLabel={resetButtonLabel}
            placeholderText={placeholderText}
            containerClassName={containerClassName}
            showImage={showImage}
            showEmptyIfNoSrc={showEmptyIfNoSrc}
            uploadClassName={uploadClassName}
            name={name}
            control={control}
            uploadLabel={uploadLabel}
            field={field}
            form={form}
            hideFallback={hideFallback}
            allowSVG={allowSVG}
            maxSizeSVG={maxSizeSVG}
            maxWidthImage={maxWidthImage}
            maxHeightImage={maxHeightImage}
          />
        )}
      </Field>
    </FormItem>
  );
};
export default FormikImageField;
