import { useCallback } from 'react';
import { useMutation, useApolloClient } from '@apollo/client';
import RefreshTokenService from 'utils/RefreshTokenService';
import { currentUserQuery } from 'graphql/queries';
import { logoutMutation } from 'graphql/mutations';

export const afterLogout = (client) => {
  RefreshTokenService.clearRefreshToken();
  client.writeQuery({ query: currentUserQuery, data: { me: null } });
  client.resetStore().catch(() => {});
};

const useLogout = () => {
  const client = useApolloClient();
  const [logout] = useMutation(logoutMutation);
  const handleLogout = useCallback(async () => {
    await logout();
    afterLogout(client);
  }, [client, logout]);

  return handleLogout;
};

export default useLogout;
