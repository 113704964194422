import { gql } from '@apollo/client';

const sendDigitalSignatureReminderMailMutation = gql`
  mutation sendDigitalSignatureReminderMailMutation(
    $shoppingCartId: ID!
    $signeeRelatedObjectId: ID!
    $signeeEmail: String!
  ) {
    remindSignee(
      shoppingCartId: $shoppingCartId
      signeeRelatedObjectId: $signeeRelatedObjectId
      signeeEmail: $signeeEmail
    )
  }
`;

export default sendDigitalSignatureReminderMailMutation;
