import { Alert } from 'antd';
import { useGetWarnings } from 'graphql/hooks';
import { Trans, useTranslation } from 'react-i18next';

const WarningsComponent = ({ warningsTypes }) => {
  const { t } = useTranslation();
  const { data: warnings } = useGetWarnings(warningsTypes);

  return (
    <>
      {warnings.map((warning) => {
        // eslint-disable-next-line
        const components = { a: <a href={t('common.urls.sendEmailUrl')}/> };
        const message = <Trans i18nKey={warning.message} components={components} />;
        return <Alert message={message} type={warning.level} />;
      })}
    </>
  );
};

export default WarningsComponent;
