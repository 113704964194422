import { gql } from '@apollo/client';
import apollo from 'graphql/apollo';
import { adminDiscountFragment } from 'graphql/fragments';

export const categoryDiscountsFragment = gql`
  fragment categoryDiscountsFragment on Category {
    discounts {
      ...adminDiscountFragment
    }
  }
  ${adminDiscountFragment}
`;

export default function updateCategoryDiscount({ _id, discounts }) {
  apollo.writeFragment({
    id: `Category___${_id}`,
    fragment: categoryDiscountsFragment,
    fragmentName: 'categoryDiscountsFragment',
    data: { discounts },
  });
}
