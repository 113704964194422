import equal from 'fast-deep-equal/es6/react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import MoreInfoWidget from 'components/common/MoreInfoWidget';

/**
 * This is a general guide on how to setup the email settings
 * This widget can be found on the email settings page
 * @returns {JSX.Element}
 * @component
 */
export const EmailHelperWidget = memo(() => {
  const { t } = useTranslation();
  return (
    <MoreInfoWidget
      buttonText={t('admin.CatalogueConfiguration.EmailHelperWidget.howUseButton')}
      title={t('admin.CatalogueConfiguration.EmailHelperWidget.modalInfo.title')}
      helpText={t('admin.CatalogueConfiguration.EmailHelperWidget.modalInfo.helpText')}
      videoCaption={t('admin.CatalogueConfiguration.EmailHelperWidget.modalInfo.videoCaption')}
      videoUrl={t('admin.CatalogueConfiguration.EmailHelperWidget.modalInfo.videoUrl')}
      imageUrl={t('admin.CatalogueConfiguration.EmailHelperWidget.modalInfo.imageUrl')}
    />
  );
}, equal);
