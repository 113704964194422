import { gql } from '@apollo/client';
import { adminVariableListFragment } from 'graphql/fragments';

const adminVariableListQuery = gql`
  query adminVariableListQuery($isLibrary: Boolean) {
    variables(isLibrary: $isLibrary) {
      ...adminVariableListFragment
    }
  }
  ${adminVariableListFragment}
`;

export default adminVariableListQuery;
