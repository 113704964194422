import FormikImageField from 'components/common/FormikImage';

const ConfigurationImage = ({ fieldDescriptor, label }) => {
  const { uploadButtonLabel } = fieldDescriptor;
  return (
    <FormikImageField
      name={fieldDescriptor.name}
      label={label}
      uploadLabel={uploadButtonLabel}
      tooltip={fieldDescriptor.tooltip}
      allowSVG
      maxWidthImage={2480}
      maxHeightImage={3508}
    />
  );
};

export default ConfigurationImage;
