import { gql } from '@apollo/client';
import { adminInputFieldFragment } from 'graphql/fragments';

const createInputFieldMutation = gql`
  mutation createInputFieldMutation(
    $categoryId: ID!
    $inputField: InputFieldInput!
    $parentId: ID!
    $parentType: String!
  ) {
    createInput(categoryId: $categoryId, inputField: $inputField, parentId: $parentId, parentType: $parentType) {
      ...adminInputFieldFragment
    }
  }
  ${adminInputFieldFragment}
`;

export default createInputFieldMutation;
