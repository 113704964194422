import { Transforms, Element as SlateElement, Node, Editor } from 'slate';
import { Plugins } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { LIST_TYPES } from '../BlockButton';

const {
  withMultiLevelList: { getNextListType },
} = Plugins;

const getPathBefore = (editor, itemListPath) => {
  const beforePath = [...itemListPath];
  const position = beforePath.length - 1;
  if (LIST_TYPES.includes(Node.parent(editor, itemListPath).children[beforePath[position] - 1]?.type)) {
    beforePath[position] -= 1;
    return beforePath;
  }
  return null;
};

const getPathAfter = (editor, itemListPath) => {
  const afterPath = [...itemListPath];
  const position = afterPath.length - 1;
  if (LIST_TYPES.includes(Node.parent(editor, itemListPath).children[afterPath[position] + 1]?.type)) {
    afterPath[position] += 1;
    return afterPath;
  }
  return null;
};

export const decreaseListItemDepth = (editor, _, { rootElement }) => {
  const [, itemListPath] =
    Editor.above(editor, {
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'list-item',
    }) || [];

  if (!itemListPath?.length) return;

  Transforms.unwrapNodes(editor, {
    match: (n) => LIST_TYPES.includes(!Editor.isEditor(n) && SlateElement.isElement(n) && n.type),
    split: true,
  });

  const list = Editor.above(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && LIST_TYPES.includes(n.type),
  });
  const newProperties = {
    type: list ? 'list-item' : rootElement,
  };
  Transforms.setNodes(editor, newProperties);
};

export const increaseListItemDepth = (editor) => {
  const [, itemListPath] =
    Editor.above(editor, {
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'list-item',
    }) || [];
  if (!itemListPath?.length) return;
  const list = Node.parent(editor, itemListPath);

  const nextListType = getNextListType(list);

  const afterPath = getPathAfter(editor, itemListPath);
  const beforePath = getPathBefore(editor, itemListPath);
  const block = {
    type: list.type,
    listType: nextListType,
    level: (list.level ?? 1) + 1,
  };
  Transforms.wrapNodes(editor, block, {
    at: {
      anchor: {
        ...editor.selection.anchor,
        path: beforePath || editor.selection.anchor.path,
      },
      focus: {
        ...editor.selection.focus,
        path: afterPath || editor.selection.focus.path,
      },
    },
  });
  if (afterPath) {
    const afterOffset = 1 + (beforePath ? 1 : 0);
    afterPath[afterPath.length - 1] -= afterOffset;
    afterPath.push(afterOffset);
    Transforms.unwrapNodes(editor, { at: afterPath });
  }
  if (beforePath) {
    beforePath.push(0);
    Transforms.unwrapNodes(editor, { at: beforePath });
  }
};
