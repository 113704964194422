import equal from 'fast-deep-equal/es6/react';
import { memo } from 'react';
import classes from './CategoryInCart.module.less';

const CategoryPanelHeader = ({ title, subTitle }) => {
  return (
    <div className={classes.categoryPanelHeader}>
      <h3>{title}</h3>
      <div className="subtitle">{subTitle}</div>
    </div>
  );
};

export default memo(CategoryPanelHeader, equal);
