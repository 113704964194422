import apollo from 'graphql/apollo';
import { deletePersonMutation } from 'graphql/mutations';
import { adminPersonsInTenantQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';

const deletePerson = async ({ _id }) =>
  apollo.mutate({
    mutation: deletePersonMutation,
    variables: { _id },
    update: (cache, { data }) => {
      const isDeletePerson = grabFirstGQLDataResult(data);
      if (isDeletePerson) {
        const cacheData = cache.readQuery({ query: adminPersonsInTenantQuery });
        const persons = grabFirstGQLDataResult(cacheData);
        const newPersons = persons.filter((person) => person._id !== _id);
        cache.writeQuery({
          query: adminPersonsInTenantQuery,
          data: {
            persons: newPersons,
          },
        });
      }
    },
  });

export default deletePerson;
