import { gql } from '@apollo/client';

const adminCheckAllItemPricingFormulasQuery = gql`
  query adminCheckAllItemPricingFormulasQuery($isLibrary: Boolean!) {
    validateAllItemPricingFormulas(isLibrary: $isLibrary) {
      isValid
      errors {
        categoryName
        itemName
      }
    }
  }
`;

export default adminCheckAllItemPricingFormulasQuery;
