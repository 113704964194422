import { useMemo, useState } from 'react';
import FormItem from 'components/common/FormItem';
import { Input } from 'formik-antd';
import { useFormikField } from 'hooks/common/useFormikField';
import useSigningGraphqlContext from 'hooks/signing/useSigningGraphqlContext';
import { validateIban } from 'utils/banking';
import debounce from 'lodash/debounce';
import { useMutation } from '@apollo/client';
import validateIbanMutation from 'graphql/mutations/signing/validateIbanMutation';
import { CheckCircleFilled } from '@ant-design/icons';

/**
 * Removes all spaces from the given value.
 *
 * @param {string} value - The string from which spaces are to be removed.
 * @returns {string} The string with all spaces removed.
 */
const raw = (value) => value.replace(/[ ]/g, '');

/**
 * Formats the given string by inserting spaces at regular intervals.
 *
 * @param {string} value - The string to be formatted.
 * @param {number} [divide=4] - Interval at which spaces should be inserted.
 * @returns {string} The formatted string with spaces.
 */
const formatter = (value, divide = 4) =>
  raw(value)
    .split('')
    .reduce((o, i, j) => {
      const addSpace = j > 0 && (j + 1) % divide === 0;
      const newStr = `${o}${i}`.toUpperCase();
      return addSpace ? `${newStr} ` : newStr;
    }, '')
    .trim();

/**
 * Component for IBAN input with validation and BIC update functionality.
 * Utilizes useFormikField for IBAN field state management.
 * Validates the IBAN on change and updates the BIC if IBAN is valid.
 *
 * @param {Object} props - Component props.
 * @param {Function} props.t - Translation function for localizing labels and placeholders.
 * @param {Function} props.onBankDataUpdated - Callback function to update the bank data based on validated IBAN.
 * @param {boolean} [props.disabled=false] - Flag to indicate if the input should be disabled.
 * @returns {ReactElement} A form item with an IBAN input field.
 */
const IbanInput = ({ t, onBankDataUpdated, disabled, prefilledIban }) => {
  const { onChange } = useFormikField('iban');
  const signingGraphlQlContext = useSigningGraphqlContext();
  const [getBic] = useMutation(validateIbanMutation, {
    context: signingGraphlQlContext,
  });
  const [ibanVerified, setIbanVerified] = useState(false);

  const debouncedIbanValidation = useMemo(
    () =>
      debounce((newValue) => {
        const ibanValidation = async () => {
          const isValid = validateIban(raw(newValue));

          if (isValid) {
            const result = await getBic({
              variables: {
                iban: newValue,
              },
            });
            if (result.data?.validateIban?.valid) {
              onBankDataUpdated({
                bic: result.data?.validateIban?.bic,
                bankName: result.data?.validateIban?.bankName,
                bankCity: result.data?.validateIban?.bankCity,
              });
              setIbanVerified(true);
            } else {
              setIbanVerified(false);
            }
          }
        };
        ibanValidation(newValue);
      }, 500),
    [getBic, onBankDataUpdated],
  );

  const handleIbanChange = (event) => {
    const input = event.target;
    const oldValue = input.value;
    const { selectionStart } = input;
    const newValue = event.target.value;
    const formattedValue = formatter(newValue);
    onChange(formattedValue);

    // Calculate the new cursor position.
    let newCursorPos = selectionStart;
    if (formattedValue.length !== oldValue.length) {
      const diff = formattedValue.length - oldValue.length;
      newCursorPos = selectionStart + diff;
    }

    // Set the cursor to the new position after the state has been updated.
    setTimeout(() => {
      input.setSelectionRange(newCursorPos, newCursorPos);
    }, 0);

    debouncedIbanValidation(newValue);
  };

  return (
    <FormItem name="iban" label={t('iban.label')}>
      <Input
        disabled={disabled}
        addonAfter={ibanVerified ? <CheckCircleFilled style={{ color: '#16b513' }} /> : null}
        onChange={handleIbanChange}
        name="iban"
        placeholder={prefilledIban || t('iban.placeholder')}
      />
    </FormItem>
  );
};

export default IbanInput;
