import { gql } from '@apollo/client';

const userBCCQuery = gql`
  query userBCCQuery {
    tenantSettings {
      _id
      emailPreferences {
        _id
        bcc
        bccWithActiveUserApplied
      }
    }
  }
`;

export default userBCCQuery;
