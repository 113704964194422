import { useTranslation } from 'react-i18next';
import Switch from 'components/common/Switch';

/**
 * AutomaticRemindSigneesAboutSignatureProcessSwitch component is the switch for the automate signature process card to enable or disable the auto remind function for signees (Needs formik context)
 * @returns {JSX.Element} switch component for automate signature process card in digital signature preferences page
 * @component
 */
const AutomaticRemindSigneesAboutSignatureProcessSwitch = () => {
  const { t } = useTranslation();
  return (
    <Switch
      name="automaticRemindSigneesAboutSignatureProcess"
      label={t(
        'admin.Setting.DigitalSignaturePreferences.automateSignatureProcess.automaticRemindSigneesAboutSignatureProcess.label',
      )}
      tooltip={t(
        'admin.Setting.DigitalSignaturePreferences.automateSignatureProcess.automaticRemindSigneesAboutSignatureProcess.tooltip',
      )}
      defaultChildren
    />
  );
};

export default AutomaticRemindSigneesAboutSignatureProcessSwitch;
