import { Fragment, memo } from 'react';
import LoginForm from 'components/auth/LoginForm';
import { useTranslation } from 'react-i18next';
import equal from 'fast-deep-equal/es6/react';
import classes from './Login.module.less';

const Login = () => {
  const { t } = useTranslation();
  return (
    <>
      <h2 className={classes.title}>{t('Login.title')}</h2>
      <LoginForm />
    </>
  );
};

export default memo(Login, equal);
