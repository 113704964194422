import { CheckOutlined, CloseOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import PageContainer from 'components/layout/PageContainer';
import equal from 'fast-deep-equal/es6/react';
import { loginAsUserMutation } from 'graphql/mutations';
import { superAdminAllUsersQuery } from 'graphql/queries';
import useLogin from 'hooks/auth/useLogin';
import i18n from 'i18n';
import UserList, { userTableColumns } from 'pages/admin/ManageUsers/UserList';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

const actionsColumn = ({ loginAs }) => ({
  key: 'actions',
  width: 64,
  fixed: 'right',
  render: (user) => {
    const { _id } = user;
    return (
      <Button
        className="ant-btn-default"
        type="danger"
        ghost
        icon={<UserSwitchOutlined />}
        onClick={async () => {
          await loginAs({ userId: _id }).then(() => {
            window.location.href = '/';
          });
        }}
      />
    );
  },
});

const onboardingColumn = () => ({
  key: 'onboarding',
  width: 140,
  title: i18n.t('admin.ManageUsers.userList.fields.onboarding'),
  render: ({ isTenantOnboardingCompleted }) => {
    return isTenantOnboardingCompleted ? <CheckOutlined /> : <CloseOutlined />;
  },
});

function ManageTenants() {
  const { t } = useTranslation();
  const loginAs = useLogin({ mutation: loginAsUserMutation });
  return (
    <PageContainer title={t('superAdmin.ManageAllUsers.title')}>
      <UserList
        query={superAdminAllUsersQuery}
        getColumns={(props) => [
          onboardingColumn(),
          ...userTableColumns(props)
            .map((column) => (column.key === 'actions' ? actionsColumn({ loginAs }) : column))
            .filter(Boolean),
        ]}
      />
    </PageContainer>
  );
}

export default memo(ManageTenants, equal);
