import PageContainer from 'components/layout/PageContainer';
import React from 'react';
import DBCallsPage from './Page';

export const DBCalls = () => {
  return (
    <PageContainer title="DB Calls" style={{ height: '100%' }}>
      <DBCallsPage />
    </PageContainer>
  );
};

export default DBCalls;
