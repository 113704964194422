import { gql } from '@apollo/client';

const userUsersInTenantQuery = gql`
  query userUsersInTenantQuery {
    usersInTenant {
      _id
      profile {
        firstName
        lastName
      }
      email
    }
  }
`;

export default userUsersInTenantQuery;
