import { useCachedQuery } from 'graphql/utils';
import { userEmailServiceQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';

const useMailServiceProvider = ({ fetchPolicy = 'cache-and-network' } = {}) => {
  const { data, loading } = useCachedQuery(userEmailServiceQuery, { fetchPolicy });
  const mailServiceProvider = grabFirstGQLDataResult(data)?.mailServiceProvider;
  return { mailServiceProvider, loading };
};

export default useMailServiceProvider;
