import { useCachedQuery } from 'graphql/utils';
import { forgotPassword } from 'graphql/methods';
import { getMyEmailQuery } from 'graphql/queries';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Form, Input, SubmitButton } from 'formik-antd';
import FormItem from 'components/common/FormItem';
import i18n from 'i18n';
import { AlertFromFormik, setGraphqlErrors } from 'components/common/ErrorComponent';
import toast from 'utils/toast';
import I18nFormik from 'components/common/I18nFormik';
import { getOriginalMessageFromGraphQLError } from 'utils/helpers';
import { useResetContext } from './context';
import LoginButton from './LoginButton';

const emailSchema = () =>
  Yup.object().shape({
    email: Yup.string().email().required().label(i18n.t('ResetPassword.fields.email')),
  });

function EnterEmailStep({ onNext }) {
  const resetStore = useResetContext();
  const { data } = useCachedQuery(getMyEmailQuery);

  const { t } = useTranslation();

  return (
    <I18nFormik
      initialValues={{
        email: '',
      }}
      validationSchema={emailSchema}
      onSubmit={async ({ email }, formik) => {
        try {
          formik.setSubmitting(true);
          await forgotPassword({ email });
          resetStore.setEmail(email);
          toast.success(t('common.toast.success.sendChangePasswordVerificationEmail', { email }));
          onNext();
        } catch (error) {
          console.error(error);
          formik.setErrors(
            setGraphqlErrors({
              error,
              errorResolver: ({ message }) => {
                if (getOriginalMessageFromGraphQLError(error).includes('userNotFound')) return ['email', message];
                return undefined;
              },
            }),
          );
        } finally {
          formik.setSubmitting(false);
        }
      }}
    >
      <Form layout="vertical">
        <AlertFromFormik />
        <p>{t('ResetPassword.enterEmail', { email: data?.me?.email })}</p>
        <FormItem name="email" label={t('ResetPassword.fields.email')}>
          <Input name="email" />
        </FormItem>
        <SubmitButton className="margin-right-16">{t('ResetPassword.buttons.verify')}</SubmitButton>
        <LoginButton />
      </Form>
    </I18nFormik>
  );
}

export default EnterEmailStep;
