import { EyeOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import Card from 'components/common/Card';
import roles from 'constants/roles';
import equal from 'fast-deep-equal/es6/react';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import { find, differenceBy } from 'lodash';
import { TableMemo } from 'memo';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';
import routePaths from 'router/route-paths';
import { fullNameFromUser } from 'components/layout/CurrentUserDropdown';
import { formatDateTime } from 'utils/date';
import { totalValueToString } from 'utils/formatValues';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { userHistoricRevisionsViewQuery } from 'graphql/queries';
import { useCachedQuery } from 'graphql/utils';
import { useParams } from 'react-router';
import GraphQLLoadingWrapper from 'components/common/GraphQLLoadingWrapper';

const historicRevisionsColumns = ({ t, categories, items }) => [
  {
    title: t('viewer.ShoppingCartView.columns.createdAt'),
    key: 'createdAt',
    dataIndex: 'createdAt',
    render: (createdAt) => formatDateTime(createdAt),
  },
  {
    title: t('viewer.ShoppingCartView.columns.selectedCategories'),
    key: 'selectedCategories',
    dataIndex: 'categories',
    render: (categoriesShoppingCart) => {
      return categoriesShoppingCart
        ?.filter((category) => category.itemIds.length)
        .map(({ _id }) => find(categories, { _id })?.name)
        .filter(Boolean)
        .join(', ');
    },
  },
  {
    title: t('viewer.ShoppingCartView.columns.selectedItems'),
    key: 'selectedItems',
    dataIndex: 'categories',
    render: (categoriesShoppingCart) => {
      return categoriesShoppingCart
        .map(({ itemIds }) => itemIds)
        .flat()
        .map((itemId) => find(items, { _id: itemId })?.name)
        .filter(Boolean)
        .join(', ');
    },
  },
  {
    title: t('viewer.ShoppingCartView.columns.valueOneOff'),
    key: 'oneOff',
    render: ({ oneOff, showDigits }) => totalValueToString({ paymentIntervalValues: oneOff, showDigits }),
  },
  {
    title: t('viewer.ShoppingCartView.columns.valueMonthly'),
    key: 'monthly',
    render: ({ monthly, showDigits }) => totalValueToString({ paymentIntervalValues: monthly, showDigits }),
  },
  {
    title: t('viewer.ShoppingCartView.columns.valueYearly'),
    key: 'yearly',
    render: ({ yearly, showDigits }) => totalValueToString({ paymentIntervalValues: yearly, showDigits }),
  },
  {
    title: t('viewer.ShoppingCartView.columns.createdBy'),
    key: 'createdBy',
    dataIndex: 'createdBy',
    render: (createdBy) => fullNameFromUser(createdBy),
  },
  {
    render: ({ _id }) => (
      <Link to={generatePath(routePaths.shoppingCartView, { id: _id })}>
        <Button className="ant-btn-default" type="primary" ghost icon={<EyeOutlined />} />
      </Link>
    ),
  },
];

const HistoricRevisions = ({ categories, items }) => {
  const params = useParams();
  const { t } = useTranslation();
  const [me] = useCurrentUser();
  const columns = useMemo(() => {
    const _columns = historicRevisionsColumns({ t, categories, items });
    return me?.role === roles.VIEWER
      ? differenceBy(_columns, ['actions'], (e) => (typeof e === 'object' ? e.key : e))
      : _columns;
  }, [categories, items, me?.role, t]);

  const { data, ...rest } = useCachedQuery(userHistoricRevisionsViewQuery, {
    variables: { _id: params.id },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });
  const shoppingCartHistory = useMemo(() => grabFirstGQLDataResult(data)?.history, [data]);

  return (
    <Card title={t('viewer.ShoppingCartView.HistoricRevisions.title')}>
      <GraphQLLoadingWrapper data={data} {...rest}>
        <TableMemo
          rowKey={(record) => record?._id}
          dataSource={shoppingCartHistory}
          columns={columns}
          pagination={false}
          scroll={{
            x: 902,
          }}
        />
      </GraphQLLoadingWrapper>
    </Card>
  );
};
export default memo(HistoricRevisions, equal);
