import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import Card from 'components/common/Card';
import { useFormikField } from 'hooks/common/useFormikField';
import { SepaEnable } from './SEPASettings/SepaEnable';
import { SepaMode } from './SEPASettings/SepaMode';
import { SepaIntroText } from './SEPASettings/SepaIntroText';
import { SepaMandateTextPrivate } from './SEPASettings/SepaMandateTextPrivate';
import { SepaMandateTextCompany } from './SEPASettings/SepaMandateTextCompany';
import { SepaDocumentTemplateSelect } from './SEPASettings/SepaDocumentTemplateSelect';

/**
 * SEPASettings is a React functional component that provides a user interface for configuring SEPA (Single Euro Payments Area) settings.
 * It renders a form within a card, allowing the user to enable/disable SEPA and configure related settings if SEPA is enabled.
 *
 * @component
 * @returns {ReactElement} The rendered component.
 *
 * @example
 * <SEPASettings />
 */
const SEPASettings = () => {
  const { t } = useTranslation();
  const { value: sepaEnabled } = useFormikField('sepaEnabled');
  const { value: digitalSignatureEnabled } = useFormikField('digitalSignatureEnabled');
  if (!digitalSignatureEnabled) return null;

  return (
    <Card title={t('admin.Setting.DigitalSignaturePreferences.sepa.cardLabel')}>
      <Form layout="vertical">
        <SepaEnable t={t} />
        {sepaEnabled ? (
          <>
            <SepaMode t={t} />
            <SepaIntroText t={t} />
            <SepaMandateTextPrivate t={t} />
            <SepaMandateTextCompany t={t} />
            <SepaDocumentTemplateSelect t={t} />
          </>
        ) : (
          ''
        )}
      </Form>
    </Card>
  );
};

export default SEPASettings;
