import { Button, Image, Modal } from 'antd';
import { memo, useCallback } from 'react';
import ReactPlayer from 'react-player';
import cn from 'classnames';
import { CalendarOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import classes from './MoreInfoWidget.module.less';

export function MoreInfoWidgetContent({
  videoUrl,
  helpText,
  videoCaption,
  imageUrl,
  children,
  playerProps = {},
  hideBook,
  className,
}) {
  const { t } = useTranslation();
  return (
    <div className={cn(classes.modalHelper, className)}>
      {videoUrl ? (
        <div className={classes.videoWrapper}>
          <ReactPlayer controls url={videoUrl} {...playerProps} />
        </div>
      ) : null}
      {videoCaption ? <p>{videoCaption}</p> : null}
      {imageUrl && (
        <div className={classes.imageContainer}>
          <Image
            wrapperClassName={classes.imageWrapper}
            src={imageUrl}
            preview={
              imageUrl
                ? {
                    zIndex: 10001,
                  }
                : false
            }
          />
        </div>
      )}
      {helpText ? <p>{helpText}</p> : null}

      {children}
      {hideBook ? null : (
        <div className="book-calendly-in-more-info">
          <h4>{t('common.MoreInfoWidget.book.title')}</h4>
          <a href={t('common.MoreInfoWidget.book.calendlyUrl')} target="_blank" rel="noreferrer">
            <Button type="primary" icon={<CalendarOutlined />}>
              {t('common.MoreInfoWidget.book.calendly')}
            </Button>
          </a>
        </div>
      )}
    </div>
  );
}

function MoreInfoWidget({ buttonClassName, buttonText, title, helpText, videoCaption, videoUrl, imageUrl, children }) {
  const [modal, contextHolder] = Modal.useModal();
  const onOpenInfoModal = useCallback(() => {
    modal.info({
      maskClosable: true,
      title,
      content: (
        <MoreInfoWidgetContent
          videoUrl={videoUrl}
          helpText={helpText}
          videoCaption={videoCaption}
          imageUrl={imageUrl}
          // eslint-disable-next-line react/no-children-prop
          children={children}
        />
      ),
      width: 800,
    });
  }, [modal, title, videoUrl, helpText, videoCaption, imageUrl, children]);
  let isVideoUrl = true;
  try {
    // eslint-disable-next-line no-new
    new URL(videoUrl);
  } catch {
    isVideoUrl = false;
  }
  return (
    <>
      {contextHolder}
      <Button
        type="link"
        className={cn(classes.button, buttonClassName)}
        icon={isVideoUrl && <PlayCircleOutlined />}
        onClick={onOpenInfoModal}
      >
        {buttonText}
      </Button>
    </>
  );
}

export default memo(MoreInfoWidget);
