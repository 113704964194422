import { gql } from '@apollo/client';

const userEditShoppingCartQuery = gql`
  query userEditShoppingCartQuery($_id: ID!) {
    userShoppingCart(_id: $_id) {
      _id
      isLatest
      migrated
      digitalSignatureState
    }
  }
`;

export default userEditShoppingCartQuery;
