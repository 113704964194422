import { gql } from '@apollo/client';
import { adminThemeFragment } from 'graphql/fragments';

const adminMyThemeQuery = gql`
  query adminMyThemeQuery {
    myTheme {
      ...adminThemeFragment
    }
  }
  ${adminThemeFragment}
`;

export default adminMyThemeQuery;
