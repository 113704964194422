import { gql } from '@apollo/client';
import { adminInputFieldFragment, adminVariableListFragment } from 'graphql/fragments';

const adminPlaceholderDataForOptionQuery = gql`
  query adminPlaceholderDataForOptionQuery($categoryId: ID!, $isLibrary: Boolean, $parentId: ID) {
    inputs(categoryId: $categoryId, parentId: $parentId) {
      ...adminInputFieldFragment
    }
    variables(isLibrary: $isLibrary) {
      ...adminVariableListFragment
    }
  }
  ${adminVariableListFragment}
  ${adminInputFieldFragment}
`;

export default adminPlaceholderDataForOptionQuery;
