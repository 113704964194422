import { adminMyThemeQuery } from 'graphql/queries';
import { useCachedQuery } from 'graphql/utils';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { useRef } from 'react';

export const useTheme = () => {
  const { data, loading } = useCachedQuery(adminMyThemeQuery);
  const themeRef = useRef();
  const theme = grabFirstGQLDataResult(data)?.pdf;
  if (!loading) themeRef.current = theme;
  return { loading, theme: themeRef.current };
};

export default useTheme;
