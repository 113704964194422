import InputDate from 'components/common/InputDate';
import equal from 'fast-deep-equal/es6/react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import ShoppingCartFormItem from '../components/ShoppingCartFormItem';

/**
 * InputDate for date of meeting
 * @returns {JSX.Element} let's the user enter the date of the meeting
 * @component
 */
const MeetingAtFormikInput = () => {
  const { t } = useTranslation();
  return (
    <ShoppingCartFormItem name="meetingAt" label={t('user.ShoppingCart.inputs.meetingAt.label')}>
      <InputDate name="meetingAt" format={t('dateFormats.dateMoment')} formatTo="YYYY-MM-DD" />
    </ShoppingCartFormItem>
  );
};
export default memo(MeetingAtFormikInput, equal);
