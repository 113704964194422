import { gql } from '@apollo/client';
import { adminCategoryListFragment } from 'graphql/fragments';

const createCommonCategoryMutation = gql`
  mutation createCommonCategoryMutation($category: Input!) {
    createCategory(category: $category, isLibrary: true) {
      ...adminCategoryListFragment
    }
  }
  ${adminCategoryListFragment}
`;

export default createCommonCategoryMutation;
