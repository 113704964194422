import { PlusOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import { addDocumentsToShoppingCart } from 'graphql/methods';
import inputConfirmModal from 'utils/inputConfirmModal';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import roles from 'constants/roles';
import { DIGITAL_SIGNATURE_STATE } from '@JavaScriptSuperstars/kanzleipilot-shared/';
import AddDocumentForm from './AddDocumentForm';

/**
 * Open add document modal for the shopping cart view
 * @param {Object} inputParameters - Input parameters of the function
 * @param {Object[]} inputParameters.availableDocumentTemplates - Collection of available document templates to select from
 * @param {String} inputParameters.shoppingCartId - Id of shopping cart object
 * @returns {*} modal controller
 */
const openAddDocument = ({ availableDocumentTemplates, shoppingCartId }) =>
  inputConfirmModal({
    formContent: () => <AddDocumentForm availableDocumentTemplates={availableDocumentTemplates} />,
    fields: [],
    onSubmit: ({ documents }) => addDocumentsToShoppingCart({ shoppingCartId, documentIds: documents }),
    value: { document: '' },
    headerText: i18n.t('viewer.ShoppingCartView.CreatedDocuments.addDocumentsModal.title'),
    okText: i18n.t('common.ok'),
    cancelText: i18n.t('common.cancel'),
    forceMultiField: true,
    width: '600px',
  });

/**
 * AddDocumentsCell component is a table cell for modify the documents card to add the add document button to the documents card for shopping cart view
 * @param {Object} inputParameters - Input parameters for the component
 * @param {String} inputParameters.shoppingCartId - Id of the shopping cart object
 * @param {Boolean} inputParameters.disableAdding - Should the add button be disabled
 * @param {Object[]} inputParameters.availableDocuments - Collection of available document templates to select from
 * @param {String} inputParameters.digitalSignatureState - Digital signature state of the shopping cart
 * @returns {JSX.Element} table cell with add document button
 * @component
 */
const AddDocumentsCell = ({ shoppingCartId, disabledAdding, availableDocuments, digitalSignatureState }) => {
  const { t } = useTranslation();

  const [me] = useCurrentUser();
  const isViewer = me?.role === roles.VIEWER;

  const disableByDigitalSignature =
    digitalSignatureState &&
    (digitalSignatureState === DIGITAL_SIGNATURE_STATE.STARTED ||
      digitalSignatureState === DIGITAL_SIGNATURE_STATE.REVOKED ||
      digitalSignatureState === DIGITAL_SIGNATURE_STATE.SIGNED);

  const tooltip = useMemo(() => {
    if (!availableDocuments?.length) return t('viewer.ShoppingCartView.CreatedDocuments.noAvailableDocumentsTooltip');
    if (disabledAdding) return t('viewer.ShoppingCartView.CreatedDocuments.disabledAddingTooltip');
    if (disableByDigitalSignature)
      return t('viewer.ShoppingCartView.CreatedDocuments.disabledByDigitalSignatureAddingTooltip');
    return null;
  }, [t, disabledAdding, disableByDigitalSignature, availableDocuments?.length]);

  if (isViewer) return '';

  return (
    <Tooltip title={tooltip}>
      <Button
        disabled={!availableDocuments?.length || disabledAdding || disableByDigitalSignature}
        icon={<PlusOutlined />}
        onClick={() =>
          openAddDocument({
            availableDocumentTemplates: availableDocuments,
            shoppingCartId,
          })
        }
      >
        {t('viewer.ShoppingCartView.CreatedDocuments.addDocument')}
      </Button>
    </Tooltip>
  );
};

export default AddDocumentsCell;
