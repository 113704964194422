import { Collapse } from 'antd';
import HeaderCollapse from 'components/admin/components/HeaderCollapse/HeaderCollapse';
import equal from 'fast-deep-equal/es6/react';
import { useGetCategoryByIdCache } from 'graphql/cache';
import { memo, useCallback } from 'react';
import { AiOutlineRight } from 'react-icons/ai';
import { GrDrag } from 'react-icons/gr';
import { colors } from 'styles/colors';
import classes from './CategoryDrag.module.less';

const { Panel } = Collapse;

const CategoryDrag = ({ _id }) => {
  const category = useGetCategoryByIdCache(_id);
  const expandIcon = useCallback(
    () => (
      <div className={classes.expandIcon}>
        <GrDrag size={14} className={classes.drag} />
        <AiOutlineRight size={14} color={colors.expandArrow} />
      </div>
    ),
    [],
  );
  return (
    <div className={classes.layer}>
      <Collapse defaultActiveKey={undefined} expandIcon={expandIcon} expandIconPosition="left">
        <Panel forceRender header={<HeaderCollapse name={category?.name} isActive={false} />} />
      </Collapse>
    </div>
  );
};

export default memo(CategoryDrag, equal);
