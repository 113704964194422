import equal from 'fast-deep-equal/es6/react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import InputDate, { ThisMonthButton } from 'components/common/InputDate';
import ShoppingCartFormItem from '../components/ShoppingCartFormItem';

/**
 * InputDate for start of contract
 * @returns {JSX.Element} let's the user enter the start date of the project
 * @component
 */
const ValidityContractFormikInput = () => {
  const { t } = useTranslation();
  return (
    <ShoppingCartFormItem name="startOfContract" label={t('user.ShoppingCart.validityContract.title')}>
      <InputDate
        name="startOfContract"
        format={t('dateFormats.dateMoment')}
        formatTo="YYYY-MM-DD"
        renderExtraFooter={(props) => <ThisMonthButton {...props} name="startOfContract" />}
      />
    </ShoppingCartFormItem>
  );
};
export default memo(ValidityContractFormikInput, equal);
