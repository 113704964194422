import { Collapse, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { moveInputField } from 'graphql/methods';
import moveInputFieldCache from 'graphql/cache/moveInputField';
import { memo, useCallback, useEffect, useState } from 'react';
import { adminGetCategoryListQuery } from 'graphql/queries';
import equal from 'fast-deep-equal/es6/react';
import { useLibraryContext } from 'contexts/LibraryContext';
import TextsTab from './TextsTab';
import InputFieldsTab from './InputFieldsTab';
import DiscountsTab from './DiscountsTab';
import classes from './CollapsibleCategoryConfiguration.module.less';
import ItemsContainer from './ItemsContainer';
import StaticItemsTab from './StaticItemsTab';
import ConditionalVisibilityTab from './ConditionalVisibilityTab';
import { ImportedAlert } from './itemModal/components';
import EditCategoryHelperWidget from './EditCategoryHelperWidget';
import { useSortEnd } from './drag/DragTable';
import CommonLibraryTab from './CommonLibraryTab';

const { TabPane } = Tabs;
const { Panel } = Collapse;

/**
 * Inner collapse component for category configuration
 * @param {Object} inputParameters - Input properties of the component
 * @param {boolean} inputParameters.isNew - Flag if the category is new
 * @param {string} inputParameters._id - ID of the category
 * @param {string} inputParameters.name - Name of the category
 * @param {string} inputParameters.subTitle - Subtitle of the category
 * @param {string} inputParameters.guidanceText - Guidance text of the category in common library for edit
 * @param {string} inputParameters.guidanceVideoId - Guidance video ID of the category in common library for edit
 * @param {string} inputParameters.introForDocument - Text for intro for documents of the category
 * @param {Object[]} inputParameters.inputFields - List of direct input fields of the category
 * @param {Object[]} inputParameters.discounts - List of discounts of the category
 * @param {string} inputParameters.introForStaticItem - Text for intro for static items of the category
 * @param {string} inputParameters.outroForStaticItem - Text for outro for static items of the category
 * @param {Object[]} inputParameters.staticItems - List of static items of the category
 * @param {string[]} inputParameters.companyTypeIds - List of company types, which are assigned to the category
 * @param {string[]} inputParameters.commonLibraryAccessGroups - List of access groups of the category in common library for edit
 * @returns {JSX.Element} collapse component with tabs for category configuration
 * @component
 */
function CollapseCategoryProperties({
  isNew,
  _id,
  name,
  subTitle,
  guidanceText,
  guidanceVideoId,
  introForDocument,
  inputFields,
  discounts,
  introForStaticItem,
  outroForStaticItem,
  staticItems,
  companyTypeIds,
  commonLibraryAccessGroups,
}) {
  const { t } = useTranslation();

  const { isLibrary } = useLibraryContext();
  const categoryListQuery = adminGetCategoryListQuery(isLibrary);
  const [activePanel, setActivePanel] = useState([]);
  useEffect(() => {
    if (isNew) {
      setActivePanel(['0']);
    }
  }, [isNew]);

  const moveInCache = useCallback((newData) => moveInputFieldCache({ _id, inputFields: newData }), [_id]);

  const { onSortEnd } = useSortEnd({
    dataSource: inputFields,
    moveInCache,
    moveMutation: moveInputField,
    refetchQuery: categoryListQuery,
  });

  return (
    <Collapse
      className={classes.collapseCategoryProperties}
      activeKey={activePanel}
      expandIconPosition="left"
      onChange={setActivePanel}
    >
      <Panel header={t('admin.CatalogueConfiguration.ConfigureCategoryProperties.title')}>
        <EditCategoryHelperWidget />
        <Tabs type="card" className={classes.tabs}>
          <TabPane tab={t('admin.CatalogueConfiguration.ConfigureCategoryProperties.properties.texts.title')} key="1">
            <TextsTab
              _id={_id}
              name={name}
              subTitle={subTitle}
              guidanceText={guidanceText}
              guidanceVideoId={guidanceVideoId}
              introForDocument={introForDocument}
            />
          </TabPane>
          <TabPane
            tab={t('admin.CatalogueConfiguration.ConfigureCategoryProperties.properties.inputFields.title')}
            key="2"
          >
            <InputFieldsTab
              categoryId={_id}
              onSortEnd={onSortEnd}
              parentId={_id}
              parentType="category"
              inputFields={inputFields?.filter((f) => f.parentId === _id)}
            />
          </TabPane>
          <TabPane
            tab={t('admin.CatalogueConfiguration.ConfigureCategoryProperties.properties.discounts.title')}
            key="3"
          >
            <DiscountsTab discounts={discounts} _id={_id} />
          </TabPane>
          <TabPane
            tab={t('admin.CatalogueConfiguration.ConfigureCategoryProperties.properties.staticItems.title')}
            key="4"
          >
            <StaticItemsTab
              introForStaticItem={introForStaticItem}
              outroForStaticItem={outroForStaticItem}
              staticItems={staticItems}
              _id={_id}
            />
          </TabPane>
          <TabPane
            tab={t('admin.CatalogueConfiguration.ConfigureCategoryProperties.properties.conditionalVisibility.title')}
            key="5"
          >
            <ConditionalVisibilityTab companyTypeIds={companyTypeIds} parentId={_id} />
          </TabPane>
          {isLibrary ? (
            <TabPane
              tab={t('admin.CatalogueConfiguration.ConfigureCategoryProperties.properties.commonLibrary.title')}
              key="6"
            >
              <CommonLibraryTab _id={_id} commonLibraryAccessGroups={commonLibraryAccessGroups} />
            </TabPane>
          ) : null}
        </Tabs>
      </Panel>
    </Collapse>
  );
}
const CollapseCategoryPropertiesMemo = memo(CollapseCategoryProperties, equal);

function CollapsibleCategoryContent({ category, isNew }) {
  const {
    _id,
    companyTypeIds,
    discounts,
    guidanceText,
    guidanceVideoId,
    inputFields,
    introForDocument,
    introForStaticItem,
    items,
    name,
    outroForStaticItem,
    staticItems,
    subTitle,
    commonLibraryAccessGroups,
  } = category;

  return (
    <>
      <ImportedAlert type="category" guidanceText={guidanceText} guidanceVideoId={guidanceVideoId} uuid={_id} />
      <CollapseCategoryPropertiesMemo
        isNew={isNew}
        _id={_id}
        companyTypeIds={companyTypeIds}
        discounts={discounts}
        guidanceText={guidanceText}
        guidanceVideoId={guidanceVideoId}
        inputFields={inputFields}
        introForDocument={introForDocument}
        introForStaticItem={introForStaticItem}
        name={name}
        outroForStaticItem={outroForStaticItem}
        staticItems={staticItems}
        subTitle={subTitle}
        commonLibraryAccessGroups={commonLibraryAccessGroups}
      />
      <ItemsContainer _id={_id} items={items} />
    </>
  );
}

export default memo(CollapsibleCategoryContent, equal);
