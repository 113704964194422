import { Button, Skeleton } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import inputConfirmModal from 'utils/inputConfirmModal';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import { TableMemo, useFunctionToRefCB } from 'memo';
import BorderedBox from 'components/common/BorderedBox';
import { memo, useCallback, useMemo } from 'react';
import { clientShift, ObjectId } from 'utils/helpers';
import toast from 'utils/toast';
import confirmModal from 'utils/confirmModal';
import EmptyBox from 'components/common/EmptyBox';
import * as Yup from 'yup';
import { find } from 'lodash';
import { useLibraryContext } from 'contexts/LibraryContext';
import { Mentions } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { useFormikContext } from 'formik';
import classNames from 'classnames';
import { getAdminFieldsForOption, useAdminFieldsForOption } from 'graphql/hooks';
import equal from 'fast-deep-equal';
import { pricingFormulaValidationSchema, textsSchema } from './itemModal/schema';
import { DragHandle, useDragTableComponents } from './drag/DragTable';
import PricingFormulaInput from './itemModal/PricingFormulaInput';

const InputFieldOptionValue = ({ value, categoryId, parentId, inputFieldId }) => {
  const [fields, loading] = useAdminFieldsForOption({
    categoryId,
    parentId,
    _id: inputFieldId,
    fetchPolicy: 'cache-first',
  });
  const formulaFields = useMemo(
    () => fields?.map((field) => ({ ...field, formulaName: field.originalName })),
    [fields],
  );
  const formatted = useMemo(
    () =>
      Mentions.formatFormula({
        formula: Mentions.richTextToFormula(value),
        getInputFields: () => formulaFields,
      }),
    [formulaFields, value],
  );
  if (loading && !fields) return <Skeleton.Input active style={{ width: '100%' }} />;
  return formatted;
};
const inputFieldOptionSchema = ({ categoryId, parentId, inputFieldId }) => [
  {
    label: i18n.t('admin.inputFieldModal.comboOptions.modal.nameFieldLabel'),
    name: 'name',
    description: i18n.t('admin.inputFieldModal.comboOptions.modal.nameFieldDescription'),
  },
  {
    label: i18n.t('admin.inputFieldModal.comboOptions.modal.valueFieldLabel'),
    name: 'value',
    description: i18n.t('admin.inputFieldModal.comboOptions.modal.valueFieldDescription'),
    render: () => (
      <PricingFormulaInput
        name="value"
        label="admin.inputFieldModal.comboOptions.modal.valueFieldLabel"
        fieldsHook={useAdminFieldsForOption}
        hookProps={{ categoryId, parentId, _id: inputFieldId }}
        disabledFunctions={['scale']}
        toolbarProps={{ isAlwaysShow: true }}
      />
    ),
  },
];
const optionValidationSchema = ({ formikRef, categoryId, isLibrary, parentId, inputFieldId }) =>
  Yup.object().shape({
    ...textsSchema(),
    value: pricingFormulaValidationSchema({
      categoryId,
      formikRef,
      getFields: getAdminFieldsForOption,
      isLibrary,
      label: i18n.t('admin.inputFieldModal.comboOptions.modal.valueFieldLabel'),
      parentId,
      _id: inputFieldId,
    }),
  });
const addNewOption = ({ onSubmit, categoryId, parentId, inputFieldId, isLibrary }) =>
  inputConfirmModal({
    fields: inputFieldOptionSchema({ categoryId, parentId, inputFieldId }),
    headerText: i18n.t('admin.inputFieldModal.comboOptions.modal.addTitle'),
    onSubmit,
    okText: i18n.t('admin.inputFieldModal.comboOptions.modal.ok'),
    cancelText: i18n.t('admin.inputFieldModal.comboOptions.modal.cancel'),
    value: { name: '', value: undefined },
    validationSchema: (props) => optionValidationSchema({ ...props, categoryId, parentId, inputFieldId, isLibrary }),
    width: '800px',
    errorResolver: { Duplicated: ['name', i18n.t('admin.inputFieldModal.comboOptions.modal.duplicatedErrorMessage')] },
  });
const editOptionModal = ({ currentValue, currentName, onSubmit, categoryId, parentId, inputFieldId, isLibrary }) =>
  inputConfirmModal({
    fields: inputFieldOptionSchema({ categoryId, parentId, inputFieldId }),
    headerText: i18n.t('admin.inputFieldModal.comboOptions.modal.editTitle'),
    onSubmit,
    okText: i18n.t('admin.inputFieldModal.comboOptions.modal.ok'),
    cancelText: i18n.t('admin.inputFieldModal.comboOptions.modal.cancel'),
    value: { name: currentName, value: currentValue },
    validationSchema: (props) => optionValidationSchema({ ...props, categoryId, parentId, inputFieldId, isLibrary }),
    width: '800px',
    errorResolver: { Duplicated: ['name', i18n.t('admin.inputFieldModal.comboOptions.modal.duplicatedErrorMessage')] },
  });
/// ///////////////////////////////
const inputFieldOptionsColumns = ({ deleteOption, editOption, isLibrary, t, categoryId, parentId, inputFieldId }) => [
  {
    className: 'drag-visible',
    width: 10,
    render: () => <DragHandle />,
    key: 'sort',
  },
  {
    className: classNames('col-8', 'table-text'),
    title: t('admin.inputFieldModal.comboOptions.table.nameColumnLabel'),
    dataIndex: 'name',
    key: 'name',
  },
  {
    className: classNames('col-4', 'table-text'),
    title: t('admin.inputFieldModal.comboOptions.table.valueColumnLabel'),
    dataIndex: 'value',
    key: 'value',
    render: (value) => (
      <InputFieldOptionValue value={value} categoryId={categoryId} parentId={parentId} inputFieldId={inputFieldId} />
    ),
  },
  {
    className: 'action-column-2',
    title: t('admin.inputFieldModal.comboOptions.table.actionsColumnLabel'),
    key: 'actions',
    render: ({ _id, name, value }) => {
      return (
        <>
          <Button
            className="ant-btn-default"
            ghost
            type="primary"
            icon={<EditOutlined />}
            onClick={() =>
              editOptionModal({
                isLibrary,
                categoryId,
                parentId,
                inputFieldId,
                currentName: name,
                currentValue: value,
                onSubmit: (modifier) => {
                  editOption({ _id, modifier });
                },
              })
            }
          />{' '}
          <Button
            className="ant-btn-default"
            type="danger"
            ghost
            icon={<DeleteOutlined />}
            onClick={() => deleteOption({ _id })}
          />
        </>
      );
    },
  },
];
const InputFieldOptions = ({ onChange, value: fieldValue, categoryId, parentId, inputFieldId }) => {
  const { t } = useTranslation();
  const { isLibrary } = useLibraryContext();
  const addOption = useCallback(
    () =>
      addNewOption({
        categoryId,
        parentId,
        inputFieldId,
        isLibrary,
        onSubmit: ({ name, value: optionValue }) => {
          if (find(fieldValue, { name })) throw new Error('Duplicated');
          onChange([
            ...(fieldValue || []),
            {
              name,
              value: optionValue,
              _id: ObjectId(),
              order: (fieldValue?.length ? fieldValue[fieldValue.length - 1].order : 0) + 1,
            },
          ]);
        },
      }),
    [categoryId, fieldValue, inputFieldId, isLibrary, onChange, parentId],
  );
  const editOption = useCallback(
    ({ _id, modifier }) => {
      if (find(fieldValue, (v) => v.name === modifier.name && v._id !== _id)) throw new Error('Duplicated');
      onChange((fieldValue || []).map((e) => (e._id === _id ? { _id, ...modifier } : e)));
    },
    [fieldValue, onChange],
  );
  const deleteOption = useCallback(
    ({ _id }) => {
      confirmModal({
        cancelText: i18n.t('admin.inputFieldModal.comboOptions.table.deleteOptionConfirmation.cancel'),
        okText: i18n.t('admin.inputFieldModal.comboOptions.table.deleteOptionConfirmation.ok'),
        okType: 'danger',
        onOk: () =>
          Promise.resolve()
            .then(() => {
              const newFieldValue = (fieldValue || [])
                .filter((v) => v._id !== _id)
                .map((v, index) => {
                  return { ...v, order: index + 1 };
                });
              onChange(newFieldValue);
            })

            .then(toast.successDefault),
        title: i18n.t('admin.inputFieldModal.comboOptions.table.deleteOptionConfirmation.title'),
      });
    },
    [fieldValue, onChange],
  );

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex !== newIndex) {
        const fieldValueId = fieldValue[oldIndex]?._id;
        const newFieldValue = clientShift({
          array: fieldValue,
          _id: fieldValueId,
          shift: newIndex - oldIndex,
        });
        onChange(newFieldValue);
      }
    },
    [fieldValue, onChange],
  );

  const { DraggableContainer, DraggableBodyRow } = useDragTableComponents({ dataSource: fieldValue, onSortEnd });

  return (
    <BorderedBox type="transparent" label={t('admin.inputFieldModal.comboOptions.boxLabel')}>
      <Button icon={<PlusOutlined />} onClick={addOption} type="primary">
        {t('admin.inputFieldModal.comboOptions.addOption')}
      </Button>
      <br />
      <br />
      <div className="table-wrapper">
        <TableMemo
          locale={{
            emptyText: <EmptyBox label={t('admin.CatalogueConfiguration.emptyInputFieldOptionListMessage')} />,
          }}
          rowKey={(record) => {
            return record?._id;
          }}
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
          pagination={false}
          dataSource={fieldValue}
          columns={inputFieldOptionsColumns({
            deleteOption,
            editOption,
            isLibrary,
            t,
            categoryId,
            parentId,
            inputFieldId,
          })}
        />
      </div>
    </BorderedBox>
  );
};

const InputFieldOptionsMemo = memo(InputFieldOptions, equal);

const InputFieldOptionsField = ({ fast, name, onChange: onChangeProps, validate, ...restProps }) => {
  const { values, setFieldValueAndTouched } = useFormikContext();
  return (
    <InputFieldOptionsMemo
      value={values[name]}
      onChange={useFunctionToRefCB((newValue) => {
        setFieldValueAndTouched(name, newValue);
        onChangeProps && onChangeProps(newValue);
      })}
      {...restProps}
    />
  );
};
export default memo(InputFieldOptionsField, equal);
