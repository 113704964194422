import { useTranslation } from 'react-i18next';
import classes from './ConfigurationParagraph.module.less';

const ConfigurationParagraph = ({ fieldDescriptor }) => {
  const { t } = useTranslation();

  const headingText = fieldDescriptor.translated
    ? fieldDescriptor.displayName
    : t(`admin.DocumentTemplateConfigurationPage.blockParagraphs.${fieldDescriptor.name}`);

  return <p className={classes.paragraph}>{headingText}</p>;
};

export default ConfigurationParagraph;
