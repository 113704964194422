import { gql } from '@apollo/client';
import { adminInputFieldFragment } from 'graphql/fragments';

const updateInputFieldMutation = gql`
  mutation updateInputFieldMutation($_id: ID!, $modifier: InputFieldInput!) {
    updateInput(_id: $_id, modifier: $modifier) {
      ...adminInputFieldFragment
    }
  }
  ${adminInputFieldFragment}
`;

export default updateInputFieldMutation;
