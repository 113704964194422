import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getDecimalSeparator, getNumberFormat } from '@JavaScriptSuperstars/kanzleipilot-shared';

export const useDecimalSeparator = () => {
  const { t } = useTranslation();
  return getDecimalSeparator(t);
};

export const useNumberFormat = ({ decimalSeparator, thousandSeparator }) => {
  return useMemo(() => getNumberFormat({ decimalSeparator, thousandSeparator }), [decimalSeparator, thousandSeparator]);
};

export default useNumberFormat;
