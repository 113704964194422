import { gql } from '@apollo/client';
import { adminStaticItemFragment } from 'graphql/fragments';

const createStaticItemMutation = gql`
  mutation createStaticItemMutation($categoryId: ID!, $modifier: StaticItemInput!) {
    createCategoryStaticItem(categoryId: $categoryId, modifier: $modifier) {
      ...adminStaticItemFragment
    }
  }
  ${adminStaticItemFragment}
`;

export default createStaticItemMutation;
