import { gql } from '@apollo/client';

const userMonthlyPaymentPreferencesFragment = gql`
  fragment userMonthlyPaymentPreferencesFragment on MonthlyPaymentPreferences {
    monthlyPaymentMode
    monthlyPaymentTitle
    rounding
    shoppingCartSettings {
      questionText
      helpText
      defaultOption
    }
    signatureSettings {
      title
      introductionText
      optionPayMonthlyPaymentText
      optionPaySeparatelyText
    }
  }
`;

export default userMonthlyPaymentPreferencesFragment;
