import I18nFormik from 'components/common/I18nFormik';
import equal from 'fast-deep-equal/es6/react';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'formik-antd';
import { AlertFromFormik } from 'components/common/ErrorComponent';
import SaveChangesButton from 'components/common/SaveChangesButton';
import { adminGlobalTenantSettingsQuery } from 'graphql/queries';
import { useCachedQuery } from 'graphql/utils';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { GlobalOutlined } from '@ant-design/icons';
import Card from 'components/common/Card';
import GraphQLLoadingWrapper from 'components/common/GraphQLLoadingWrapper';
import * as Yup from 'yup';
import i18n from 'i18n';
import FormItem from 'components/common/FormItem';
import PageContainer from 'components/layout/PageContainer';
import Switch from 'components/common/Switch';
import { updateTenantSettings } from 'graphql/methods';

const schema = () => ({
  hideCountryInAddresses: Yup.boolean()
    .nullable()
    .label(i18n.t('admin.OtherSettings.globalSetting.inputs.hideCountryInAddresses.label')),
});
const globalSettingsSchema = () => Yup.object().shape(schema());

const HideCountryInAddressesFormikSwitch = (props) => {
  const { t } = useTranslation();
  return (
    <FormItem name="hideCountryInAddresses">
      <Switch
        name="hideCountryInAddresses"
        label={t('admin.OtherSettings.globalSettings.inputs.hideCountryInAddresses.label')}
        checkedChildren={t('common.on')}
        unCheckedChildren={t('common.off')}
        className="form-item-no-margin"
        {...props}
      />
    </FormItem>
  );
};
const GlobalSettings = () => {
  const { data, ...rest } = useCachedQuery(adminGlobalTenantSettingsQuery);
  const globalSettings = useMemo(() => grabFirstGQLDataResult(data), [data]);
  const initialValues = useMemo(
    () => ({
      hideCountryInAddresses: globalSettings?.hideCountryInAddresses ?? true,
    }),
    [globalSettings?.hideCountryInAddresses],
  );
  const onSubmit = useCallback(async ({ hideCountryInAddresses }) => {
    await updateTenantSettings({
      settings: {
        hideCountryInAddresses,
      },
    });
  }, []);
  return (
    <GraphQLLoadingWrapper data={data} {...rest}>
      <I18nFormik initialValues={initialValues} onSubmit={onSubmit} validationSchema={globalSettingsSchema}>
        <Form layout="vertical">
          <AlertFromFormik />
          <HideCountryInAddressesFormikSwitch />
          <SaveChangesButton initialValues={initialValues} />
        </Form>
      </I18nFormik>
    </GraphQLLoadingWrapper>
  );
};
const OtherSettings = () => {
  const { t } = useTranslation();
  return (
    <PageContainer title={t('admin.OtherSettings.title')}>
      <Card icon={<GlobalOutlined />} title={t('admin.OtherSettings.globalSettings.title')}>
        <GlobalSettings />
      </Card>
    </PageContainer>
  );
};
export default memo(OtherSettings, equal);
