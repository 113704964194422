import { Typography, Button, Space } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { CheckCircleOutlined, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { useFormikContext } from 'formik';
import { adminEmailServiceQuery } from 'graphql/queries';
import { verifyCustomDomainMutation } from 'graphql/mutations/admin/verifyCustomDomainMutation';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from 'utils/formatDateTime';
import toast from 'utils/toast';
import classes from './DKIMVerificationStatus.module.less';

/**
 * This component displays the current DKIM verification status for a custom domain on the email settings page
 * @param domain - the custom domain
 * @returns {JSX.Element}
 * @component
 */
export const DKIMVerificationStatus = ({ domain }) => {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  const [isDomainVerified, setIsDomainVerified] = useState(values.dkimSettings.isCustomDomainVerified);

  useEffect(() => {
    setIsDomainVerified(values.dkimSettings.isCustomDomainVerified);
  }, [values.dkimSettings.isCustomDomainVerified]);

  const [verifyCustomDomain, { loading, error }] = useMutation(verifyCustomDomainMutation, {
    variables: {
      domain,
    },
    refetchQueries: [{ query: adminEmailServiceQuery }],
  });

  const checkVerificationStatus = () => {
    verifyCustomDomain()
      .then((res) => {
        setIsDomainVerified(res.data?.verifyCustomDomain.verified);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  let formattedDate = '';

  if (values.dkimSettings.lastVerificationCheck) {
    formattedDate = formatDateTime(values.dkimSettings.lastVerificationCheck);
  }

  if (loading)
    return (
      <Space direction="vertical" size={0} className={classes.confirmationContainer}>
        <Space size="small">
          <SyncOutlined spin />
          <Typography.Text>{t('admin.Setting.Email.inputs.dkimStatusCheck.status.loading')}</Typography.Text>
        </Space>
        <CheckVerificationButton checkVerificationStatus={checkVerificationStatus} />
      </Space>
    );

  if (error)
    return (
      <Space direction="vertical" size={0} className={classes.confirmationContainer}>
        <Typography.Text>{t('admin.Setting.Email.inputs.dkimStatusCheck.status.error')}</Typography.Text>
        <CheckVerificationButton checkVerificationStatus={checkVerificationStatus} />
      </Space>
    );

  return (
    <Space direction="vertical" size={0} className={classes.confirmationContainer}>
      <Space size="small" className={classes.confirmationStatusContainer}>
        {isDomainVerified ? (
          <>
            <CheckCircleOutlined className={classes.verifiedIcon} />
            <Typography.Text>
              {' '}
              {t('admin.Setting.Email.inputs.dkimStatusCheck.status.success_connected')}
              {formattedDate
                ? t('admin.Setting.Email.inputs.dkimStatusCheck.status.last_checked', { date: formattedDate })
                : ''}
            </Typography.Text>
          </>
        ) : (
          <>
            <CloseCircleOutlined className={classes.notVerifiedIcon} />
            <Typography.Text>
              {t('admin.Setting.Email.inputs.dkimStatusCheck.status.success_notConnected')}
              {formattedDate
                ? t('admin.Setting.Email.inputs.dkimStatusCheck.status.last_checked', { date: formattedDate })
                : ''}
            </Typography.Text>
          </>
        )}
      </Space>
      <CheckVerificationButton checkVerificationStatus={checkVerificationStatus} />
    </Space>
  );
};

/**
 * This component is the button to check the DKIM verification status (Uses formik context to submit form before validation)
 * @param checkVerificationStatus - the function to check the DKIM verification status
 * @returns {JSX.Element}
 * @component
 */
const CheckVerificationButton = ({ checkVerificationStatus }) => {
  const { t } = useTranslation();
  const { submitForm, dirty } = useFormikContext();

  const onClick = useCallback(async () => {
    if (dirty) await submitForm();
    checkVerificationStatus();
  }, [dirty, submitForm, checkVerificationStatus]);

  return (
    <Button type="link" onClick={onClick} icon={<SyncOutlined />} className={classes.confirmationButton}>
      {t('admin.Setting.Email.inputs.dkimStatusCheck.checkStatus')}
    </Button>
  );
};

export default DKIMVerificationStatus;
