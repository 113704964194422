import React from 'react';
import { useTranslation } from 'react-i18next';
import { ImportDocumentFromLibrary } from 'components/admin/DocumentTemplate/modals/ImportDocumentFromLibrary';
import confirmModal from 'utils/confirmModal';
import OnboardingStepTemplate from '../components/OnboardingStepTemplate';
import { useOnboardingContext } from '../OnboardingContext';

export default function PickDocuments() {
  const { t } = useTranslation();
  const importDocumentFromLibraryRef = React.useRef();
  const { onSubmit, formValuesRef } = useOnboardingContext();
  return (
    <OnboardingStepTemplate
      title={t('onboarding.pickDocuments.title')}
      description={t('onboarding.pickDocuments.textAboveVideo')}
      videoUrl={t('onboarding.pickDocuments.videoUrl')}
      videoCaption={t('onboarding.pickDocuments.videoCaption')}
      onNext={() =>
        confirmModal({
          maskClosable: true,
          okType: 'danger',
          onOk: () => {
            const preSubmitSuccessful = importDocumentFromLibraryRef.current.preSubmit();
            if (preSubmitSuccessful) {
              const selectedDocuments = importDocumentFromLibraryRef.current.getSelection();
              onSubmit({ importDocuments: selectedDocuments });
            }
          },
          title: t('onboarding.pickDocuments.infoConfirmationMessage'),
        })
      }
      onPrev={() => {
        const selectedDocuments = importDocumentFromLibraryRef.current.getSelection();
        formValuesRef.current = { ...formValuesRef.current, importDocuments: selectedDocuments };
      }}
      showAlert
      alertProp={{ message: t('onboarding.pickDocuments.infoMessage'), type: 'info', className: 'alert-info' }}
    >
      <ImportDocumentFromLibrary
        checkAll
        initialSelection={formValuesRef.current.importDocuments}
        ref={importDocumentFromLibraryRef}
      />
    </OnboardingStepTemplate>
  );
}
