import { gql } from '@apollo/client';
import { adminItemFragment } from 'graphql/fragments';

const createItemMutation = gql`
  mutation createItemMutation($categoryId: ID!, $item: CategoryItemInput!) {
    createCategoryItem(categoryId: $categoryId, item: $item) {
      ...adminItemFragment
    }
  }
  ${adminItemFragment}
`;

export default createItemMutation;
