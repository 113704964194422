import { useFormikContext } from 'formik';
import { useMemo } from 'react';

const useIsOldContactData = () => {
  const {
    values: { initializationConfigDateForCompany },
  } = useFormikContext();
  const isOldContactData = useMemo(() => !!initializationConfigDateForCompany, [initializationConfigDateForCompany]);
  return isOldContactData;
};

export default useIsOldContactData;
