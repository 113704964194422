import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import DnsEntry from './DnsEntry';

export function DKIMRecord() {
  const { values } = useFormikContext();
  const { t } = useTranslation();

  return (
    <DnsEntry
      name="dkimSettings.dkimRecord"
      label={t('admin.Setting.Email.inputs.dnsSettings.dkimRecord.label')}
      value={values.dkimSettings.dkimRecord}
      recordHostnameLabel={t('admin.Setting.Email.inputs.dnsSettings.dkimRecord.prefixLabel')}
      recordHostname={values.dkimSettings.dkimRecordHostname}
      recordType={t('admin.Setting.Email.inputs.dnsSettings.dkimRecord.type')}
      recordTypeLabel={t('admin.Setting.Email.inputs.dnsSettings.dkimRecord.typeLabel')}
      status={values.dkimSettings.isDkimRecordVerified}
      title={t('admin.Setting.Email.inputs.dnsSettings.dkimRecord.heading')}
      key="dkimRecord"
    />
  );
}
