import apollo from 'graphql/apollo';
import { updateTenantMutation } from 'graphql/mutations';
import { superAdminAvailableAccessGroupsQuery, superAdminTenantsQuery } from 'graphql/queries';

const updateTenant = async ({
  _id,
  name,
  nrOfAllowedCompaniesByPricingPlan,
  nrOfAllowedUsersByPricingPlan,
  licenseStarted,
  locale,
  disabled,
  commonLibraryAccessGroups,
}) =>
  apollo.mutate({
    mutation: updateTenantMutation,
    variables: {
      _id,
      modifier: {
        name,
        nrOfAllowedCompaniesByPricingPlan,
        nrOfAllowedUsersByPricingPlan,
        licenseStarted,
        locale,
        disabled,
        commonLibraryAccessGroups,
      },
    },
    refetchQueries: [{ query: superAdminTenantsQuery }, superAdminAvailableAccessGroupsQuery],
  });
export default updateTenant;
