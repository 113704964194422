// 'application/pdf'

const useDownloadFile = (url, fileType, fileName) => {
  const downloadFile = (fileArrayBuffer) => {
    const getDataPromise = new Promise((resolve) => {
      if (!fileArrayBuffer) {
        fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': fileType,
          },
        })
          .then((response) => {
            return response.blob();
          })
          .then((blob) => {
            resolve([blob]);
          })
          .catch((error) => {
            console.log('Fetching file to download failed: ', error);
          });
      } else {
        resolve([fileArrayBuffer]);
      }
    });
    getDataPromise.then((binaryData) => {
      const convertedBlob = new Blob(binaryData, { type: fileType });
      const blobUrl = window.URL.createObjectURL(convertedBlob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', fileName);
      // link.setAttribute('type', fileType);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
  };
  return { downloadFile };
};

export default useDownloadFile;
