import { Button } from 'antd';
import FormItem from 'components/common/FormItem';
import { useFormikField } from 'hooks/common/useFormikField';
import classes from './Switcher.module.less';

export default TypeFormikSwitcher;

function TypeFormikSwitcher({ allKeys, activeKey, name = 'type' }) {
  return (
    <FormItem name={name}>
      <TypeSwitcher name={name} allKeys={allKeys} activeKey={activeKey} />
    </FormItem>
  );
}

function TypeSwitcher({ name, allKeys }) {
  const { value, onChange } = useFormikField(name);

  return (
    <div className={classes.tabContainer}>
      {allKeys.map(({ label, _id }) => (
        <Button onClick={() => onChange(_id)} type={value === _id ? 'primary' : ''}>
          {label}
        </Button>
      ))}
    </div>
  );
}
