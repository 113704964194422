import { gql } from '@apollo/client';

const userEmailPreferencesFragment = gql`
  fragment userEmailPreferencesFragment on EmailPreferences {
    _id
    _apolloCacheKey
    signature
  }
`;

export default userEmailPreferencesFragment;
