import { gql } from '@apollo/client';
import { adminCategoryListFragment } from 'graphql/fragments';

const importCategoriesFromLibraryMutation = gql`
  mutation importCategoriesFromLibraryMutation($input: [CategoryImportInput]) {
    importCategoriesFromLibrary(input: $input) {
      ...adminCategoryListFragment
    }
  }
  ${adminCategoryListFragment}
`;

export default importCategoriesFromLibraryMutation;
