import { Alert } from 'antd';
import PageContainer from 'components/layout/PageContainer';
import equal from 'fast-deep-equal/es6/react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { IoCheckmarkCircle } from 'react-icons/io5';
import { useLocation, useParams } from 'react-router-dom';
import DocumentsCard from 'components/common/DocumentsCard';
import classes from './PrintShoppingCartPage.module.less';
import NextActions from './NextActions';

function PrintShoppingCartPage() {
  const state = useLocation();
  const { id: shoppingCartId } = useParams();
  const { t } = useTranslation();
  return (
    <PageContainer>
      <div className={`xs-mt-20 ${classes.container}`}>
        {state && (
          <Alert
            message={t('user.PrintShoppingCartPage.successfulMessage')}
            type="success"
            showIcon
            icon={<IoCheckmarkCircle type="success" size={40} />}
            closable
          />
        )}
        <DocumentsCard fetchPolicy="cache-first" shoppingCartId={shoppingCartId} />
        <NextActions />
      </div>
    </PageContainer>
  );
}
export default memo(PrintShoppingCartPage, equal);
