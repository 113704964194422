import { find, groupBy, isEqual, pick } from 'lodash';
import { ComparisonTypes } from 'constants/shoppingCart';
import { useMemo } from 'react';

/**
 * Concatted _ids of the object arrays and removes doubles
 * @param {Object[]} current - Current objects collection
 * @param {Object[]} historical - Historical objects collection
 * @returns {String[]} array with ids of the provided object arrays
 */
export const uniqueConcattedIds = (current, historical) => [...new Set([...historical, ...current].map((e) => e._id))];

/**
 * Merges historical and current resources together (unique by id); adds historical and current version into one object and adds comparison type
 * @param {Object} inputParameters - Input parameters of the function
 * @param {Object[]} inputParameters.independent - Historical resources
 * @param {Object[]} inputParameters.global - Current resources
 * @param {String[]} inputParameters.disableComparisonTypes - Remove resources with this comparison types
 * @param {String[]} inputParameters.comparedProps - Properties of the objects which should be used to decide if a historical version is equal to the current version of a resource
 * @param {Fucntion} inputParameters.formatDocument - Function to format historical and current object version for equal check
 * @returns {Object[]} merged array of historical and current resources
 */
export const applyGlobalToIndependent = ({
  independent: historical = [],
  global: current = [],
  disableComparisonTypes = [],
  comparedProps,
  formatDocument = (e) => e,
}) => {
  const ids = uniqueConcattedIds(current, historical);
  const newData = ids
    .map((_id) => {
      const global = find(current, { _id });
      const independent = find(historical, { _id });

      let comparisonType;
      if (
        global &&
        independent &&
        !isEqual(formatDocument(pick(global, comparedProps)), formatDocument(pick(independent, comparedProps))) // Check init date
      )
        comparisonType = ComparisonTypes.CHANGED;
      else if (!global) comparisonType = ComparisonTypes.DELETED;
      else if (!independent) comparisonType = ComparisonTypes.NEW;
      else comparisonType = ComparisonTypes.NO_CHANGE;
      if (disableComparisonTypes.includes(comparisonType)) return null;
      return {
        comparisonType,
        ...(independent ?? global),
        ...(comparisonType === ComparisonTypes.CHANGED && { global }),
      };
    })
    .filter(Boolean);
  return newData;
};

export const useGroupComparingData = (data) => {
  const groupedStaticItems = useMemo(
    () => groupBy(data, (e) => (ComparisonTypes.NEW === e.comparisonType ? e.comparisonType : ComparisonTypes.CHANGED)),
    [data],
  );
  return [groupedStaticItems[ComparisonTypes.CHANGED] ?? [], groupedStaticItems[ComparisonTypes.NEW] ?? []];
};
