import apollo from 'graphql/apollo';
import { deleteShoppingCartMutation } from 'graphql/mutations';
import i18n from 'i18n';
import confirmModal from 'utils/confirmModal';
import { toastGraphQLError } from 'utils/helpers';
import toast from 'utils/toast';

export const deleteShoppingCart = async ({ _id }) =>
  apollo.mutate({
    mutation: deleteShoppingCartMutation,
    variables: { _id },
  });
export default deleteShoppingCart;

export const deleteShoppingCartWithConfirmation = ({ _id, onOk }) =>
  confirmModal({
    cancelText: i18n.t('deleteShoppingCartConfirmation.cancel'),
    maskClosable: true,
    okText: i18n.t('deleteShoppingCartConfirmation.ok'),
    okType: 'danger',
    onOk: () =>
      deleteShoppingCart({ _id })
        .then((props) => {
          onOk(props);
          toast.successDefault();
        })
        .catch(toastGraphQLError),
    title: i18n.t('deleteShoppingCartConfirmation.title'),
  });
