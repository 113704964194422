import { Formik as DefaultFormik } from 'formik';
import { forwardRef, useState } from 'react';
import RefreshI18nErrorsFormik from './RefreshI18nErrorsFormik';

export const I18nFormik = ({ children, onSubmit, reInitOnSubmit = true, ...props }, ref) => {
  const isFunction = typeof children === 'function';
  const [key, setKey] = useState(1);
  return (
    <DefaultFormik
      key={key}
      innerRef={ref}
      onSubmit={async (...props1) => {
        const response = await Promise.resolve(onSubmit(...props1));
        if (response && !response.error) reInitOnSubmit && setKey((p) => p + 1);
        return response;
      }}
      {...props}
    >
      {(...formikChildrenProps) => (
        <>
          <RefreshI18nErrorsFormik />
          {isFunction ? children(...formikChildrenProps) : children}
        </>
      )}
    </DefaultFormik>
  );
};
export default forwardRef(I18nFormik);
