export const PaymentInterval = {
  ONE_OFF: 'oneOff',
  MONTHLY: 'monthly',
  YEARLY: 'yearly',
  MONTHLY_ADVANCE: 'monthlyAdvance',
};

export const CalculationMode = {
  VALUE: 'value',
  ON_ACTUAL_COST: 'onActualCost',
};

export const HighlightBindingness = {
  NO_HIGHLIGHT: 'noHighlight',
  DYNAMIC: 'dynamic',
  FIX_PRICED: 'fixPriced',
  ESTIMATED: 'estimated',
};
