import { gql } from '@apollo/client';

const moveCategoryMutation = gql`
  mutation moveCategoryMutation($_id: ID!, $offset: Int, $order: Int) {
    moveCategory(_id: $_id, offset: $offset, order: $order) {
      _id
      order
    }
  }
`;

export default moveCategoryMutation;
