import { gql } from '@apollo/client';
import { adminCategoryListFragment } from 'graphql/fragments';

const adminCategoriesQuery = gql`
  query adminCategoriesQuery($isLibrary: Boolean) {
    categories(isLibrary: $isLibrary) {
      ...adminCategoryListFragment
    }
  }
  ${adminCategoryListFragment}
`;

export default adminCategoriesQuery;
