import equal from 'fast-deep-equal';
import { createContext, useContext, useMemo, useState } from 'react';

const SigningProcessShoppingCartContext = createContext(null);

/**
 * Provider component for the signing process shopping cart context, which handles the state of the shopping cart for the signing process
 * @param {Object} inputParameters - Input parameters of the component
 * @param {JSX.Element} inputParameters.children - Children components of the component which should be rendered in place of this component
 * @param {Object} inputParameters.value - Initial shopping cart object
 * @returns {JSX.Element} provider for the signing process shopping cart context
 */
export const SigningProcessShoppingCartContextProvider = ({ children, value }) => {
  const [shoppingCart, setShoppingCart] = useState(value || { categories: [] });
  const contextContent = useMemo(
    () => ({
      shoppingCart,
      updateShoppingCart: (updateFunction) =>
        setShoppingCart((currentShoppingCart) => updateFunction(currentShoppingCart)),
    }),
    [shoppingCart, setShoppingCart],
  );
  if (!equal(value, shoppingCart)) setShoppingCart(value);
  return (
    <SigningProcessShoppingCartContext.Provider value={contextContent}>
      {children}
    </SigningProcessShoppingCartContext.Provider>
  );
};

/**
 * @typedef {Object} SigningProcessShoppingCartContext
 * @property {Object} shoppingCart - Current shopping cart stored in the context
 * @property {Function} updateShoppingCart - Function to update shopping cart stored in the context (Gets a function as property, which gets the current shopping cart as parameter and returns the updated shopping cart)
 */

/**
 * Hook to get access to the signing process shopping cart context (Must be used in the signing process shopping cart context)
 * @returns {React.Context<SigningProcessShoppingCartContext>} use context hook for signing process shopping cart context
 */
export const useSigningProcessShoppingCartContext = () => useContext(SigningProcessShoppingCartContext);
