import { Calc } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { PricingFormula } from 'components/admin/ItemsContainer';
import EmptyBox from 'components/common/EmptyBox';
import { CalculationMode } from 'constants/item';
import { useCompareToGlobalContext } from 'contexts/CompareToGlobalContext';
import equal from 'fast-deep-equal';
import { TableMemo } from 'memo';
import { scaleTableColumns, useScales } from 'pages/admin/ItemCatalogueConfiguration/Scales';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import DifferencesPopover from './DifferencesPopover';

const Scales = ({ scales }) => {
  const { sortedValue, valueRef } = useScales(scales);
  const { t } = useTranslation();
  return (
    <TableMemo
      locale={{ emptyText: <EmptyBox label={t('admin.itemModal.inputs.scales.emptyScaleList')} /> }}
      bordered
      pagination={false}
      dataSource={sortedValue}
      columns={scaleTableColumns({
        t,
        valueRef,
      })}
    />
  );
};

const rows = ({ t, showDigits }) => {
  const shouldRenderFn = ({ values: { calculationMode, global } }) =>
    calculationMode === CalculationMode.VALUE && global.calculationMode === CalculationMode.VALUE;
  return [
    {
      label: t('viewer.ShoppingCartView.rows.name'),
      dataIndex: 'name',
    },
    {
      label: t('viewer.ShoppingCartView.rows.calculationMode'),
      dataIndex: 'calculationMode',
      render: (calculationMode) => t(`common.Item.calculationMode.${calculationMode}`),
    },
    {
      label: t('viewer.ShoppingCartView.rows.paymentInterval'),
      dataIndex: 'paymentInterval',
      render: (paymentInterval) => t(`common.Item.paymentIntervalValue.${paymentInterval}`),
    },
    {
      label: t('viewer.ShoppingCartView.rows.highlightBindingness'),
      dataIndex: 'highlightBindingness',
      render: (highlightBindingness) => t(`common.Item.highlightBindingness.${highlightBindingness}`),
      shouldRenderFn,
    },
    {
      label: t('viewer.ShoppingCartView.rows.pricingFormula'),
      render: (e) => (
        <PricingFormula
          calculationMode={e.calculationMode}
          pricingFormula={e.pricingFormulaExtended.formatted}
          inputFields={e.inputFields}
        />
      ),
      shouldRenderFn,
    },
    {
      label: t('viewer.ShoppingCartView.rows.minPrice'),
      dataIndex: 'minPrice',
      render: (minPrice) => (Number.isFinite(minPrice) ? Calc.formatCurrency(minPrice, { showDigits }) : undefined),
      shouldRenderFn,
    },
    {
      name: 'scales',
      label: t('viewer.ShoppingCartView.rows.scales'),
      render: (item) => <Scales scales={item.scalesEnabled ? item.scales : []} />,
      shouldRenderFn,
    },
  ];
};
const ItemDifferences = ({ item, className }) => {
  const { t } = useTranslation();
  const { isCompare } = useCompareToGlobalContext();
  if (!isCompare) return null;
  return <DifferencesPopover className={className} data={item} rows={rows({ t })} />;
};

export default memo(ItemDifferences, equal);
