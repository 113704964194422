import { gql } from '@apollo/client';
import { currentUserFragment } from 'graphql/fragments';

const verifyEmailMutation = gql`
  mutation verifyEmailMutation($email: String!, $otp: String!) {
    verifyEmail(email: $email, otp: $otp) {
      token
      refreshToken
      user {
        ...currentUserFragment
      }
    }
  }
  ${currentUserFragment}
`;

export default verifyEmailMutation;
