import { useState } from 'react';
import { Typography } from 'antd';
import { useFormikField } from 'hooks/common/useFormikField';

import BicInput from './BicInput';
import IbanInput from './IbanInput';

const { Text } = Typography;

const BankData = ({ t }) => {
  const { value: bankName } = useFormikField('bankData.bankName');
  const { value: bankCity } = useFormikField('bankData.bankCity');
  if (!bankName) return null;
  return (
    <div>
      <Text strong>{t('bankData.label')}: </Text>
      <Text>{bankName}</Text>
      {bankCity ? <Text>; {bankCity}</Text> : null}
    </div>
  );
};

/**
 * Renders a grouped input component for IBAN and BIC fields.
 * It consists of two subcomponents: `IbanInput` and `BicInput`.
 * The `IbanInput` component may update the BIC value, which is then passed to the `BicInput` component.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.t - A translation function for localizing labels and placeholders.
 * @param {boolean} [props.disabled=false] - Indicates whether the input fields should be disabled.
 * @returns {ReactElement} A compound component containing both IBAN and BIC input fields.
 */
const IbanAndBicInputGroup = ({ t, disabled, prefilledBankAccount }) => {
  const [serverBankData, setServerBankData] = useState({ bic: '', bankName: '', bankCity: '' });

  const handleBankDataUpdated = (bankData) => {
    setServerBankData(bankData);
  };
  return (
    <>
      <IbanInput
        disabled={disabled}
        t={t}
        onBankDataUpdated={handleBankDataUpdated}
        prefilledIban={prefilledBankAccount.iban}
      />
      <div>
        <BicInput disabled={disabled} t={t} serverBankData={serverBankData} prefilledBic={prefilledBankAccount.bic} />
        <BankData t={t} />
      </div>
    </>
  );
};

export default IbanAndBicInputGroup;
