import PageContainer from 'components/layout/PageContainer';
import equal from 'fast-deep-equal/es6/react';
import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import toast from 'utils/toast';
import { Alert, Tooltip, Collapse, Spin } from 'antd';
import { updateEmailService } from 'graphql/methods';
import { BulbOutlined, CloudServerOutlined, InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';
import Card from 'components/common/Card';
import apolloClient from 'graphql/apollo';
import { TableMemo } from 'memo';
import { adminGetAllowedEmailConfigurationsQuery } from 'graphql/queries/admin/adminGetAllowedEmailConfigurationsQuery';
import SMTPHelperButton from './SMTPHelperButton';
import OtherEmailSettings from './OtherEmailSettings';
import { EmailSettingsFormFields } from './EmailSettingsFormFields';
import { EmailHelperWidget } from './EmailHelperWidget';
import { SignatureCardMemo } from './SignatureCard';

const { Panel } = Collapse;

const getAllowedConfigurations = () =>
  apolloClient
    .query({ query: adminGetAllowedEmailConfigurationsQuery, fetchPolicy: 'network-only' })
    .then((e) => JSON.parse(e.data.getAllowedEmailConfigurations))
    .catch(() => toast.error(i18n.t('admin.Setting.Email.mailServiceIsNotSmtp')));

const AllowedConfigurations = ({ emailServiceData }) => {
  const { t } = useTranslation();
  if (!emailServiceData) return null;
  return (
    <Card icon={<SearchOutlined />} title={t('admin.Setting.Email.smtpSetting.button')}>
      {(() => {
        if (emailServiceData === 'loading')
          return (
            <>
              <Spin style={{ marginRight: 15 }} />
              {t('admin.Setting.Email.smtpSetting.loading')}
            </>
          );
        const bestOption = emailServiceData[0];
        const columns = (isSuccess) =>
          [
            isSuccess && {
              title: t('common.success').replace('!', ''),
              render: (row) => {
                const style = { width: '100%', height: '100%', margin: -16, position: 'absolute', display: 'flex' };
                return !row.success ? (
                  <Tooltip title={row.error || ''} autoAdjustOverflow>
                    <div style={{ ...style, background: 'red' }}>
                      <InfoCircleOutlined style={{ margin: 'auto' }} />
                    </div>
                  </Tooltip>
                ) : (
                  <div style={{ ...style, background: 'green' }} />
                );
              },
              key: 'success',
            },
            {
              title: t('admin.Setting.Email.inputs.smtpPort'),
              render: (row) => row.smtpPort,
              key: 'port',
            },
            {
              title: t('admin.Setting.Email.inputs.useTLS'),
              render: (row) => t(row.useTLS ? 'common.yes' : 'common.no'),
              key: 'useTLS',
            },
            {
              title: t('admin.Setting.Email.inputs.requireTLS'),
              render: (row) => t(row.requireTLS ? 'common.yes' : 'common.no'),
              key: 'requireTLS',
            },
            {
              title: t('admin.Setting.Email.inputs.useSSLv3'),
              render: (row) => t(row.useSSLv3 ? 'common.yes' : 'common.no'),
              key: 'useSSLv3',
            },
          ].filter(Boolean);
        const successColumns = columns(true);
        const optionColumns = columns();
        return (
          <>
            {bestOption.success && (
              <>
                <Alert
                  showIcon
                  type="success"
                  className="margin-bottom-16"
                  message={t('common.success')}
                  description={t('admin.Setting.Email.smtpSetting.found')}
                />
                <TableMemo rowKey="_id" dataSource={[emailServiceData[0]]} pagination={false} columns={optionColumns} />
              </>
            )}
            {!bestOption.success && (
              <Alert
                showIcon
                type="error"
                className="margin-bottom-16"
                message={t('common.error')}
                description={t('admin.Setting.Email.smtpSetting.notFound')}
              />
            )}
            <Collapse bordered={false} defaultActiveKey={null} expandIconPosition="left">
              <Panel forceRender header={t('admin.Setting.Email.smtpSetting.showDetails')}>
                <TableMemo
                  rowKey="_id"
                  dataSource={emailServiceData.map((e) => ({ ...e, _id: JSON.stringify(e) }))}
                  pagination={false}
                  columns={successColumns}
                />
              </Panel>
            </Collapse>
          </>
        );
      })()}
    </Card>
  );
};

function EmailSettingWrapper() {
  const { t } = useTranslation();
  const [emailServiceData, setEmailServiceData] = useState();
  const [mailServiceProvider, setMailServiceProvider] = useState('');

  const setBestSMTPSettings = useCallback(async () => {
    setEmailServiceData('loading');
    await getAllowedConfigurations()
      .then(async (response) => {
        setEmailServiceData(response);
        if (response[0].success) {
          const options = ['requireTLS', 'smtpPort', 'useSSLv3', 'useTLS'];

          const extractedValues = options.reduce((result, key) => {
            return {
              ...result,
              [key]: response[0][key],
            };
          }, {});

          const serviceUpdate = {
            mailServiceProvider: 'smtp',
            ...extractedValues,
          };
          await updateEmailService(serviceUpdate);
        }
      })
      .catch(() => {
        setEmailServiceData(null);
      });
  }, []);

  return (
    <PageContainer
      title={t('admin.Setting.Email.title')}
      right={<SMTPHelperButton mailServiceProvider={mailServiceProvider} setBestSMTPSettings={setBestSMTPSettings} />}
    >
      <Alert
        icon={<BulbOutlined />}
        showIcon
        closable
        className="alert-info"
        message={t('admin.Setting.Email.saveAndTestEmailAlert.message')}
        description={t('admin.Setting.Email.saveAndTestEmailAlert.description')}
        type="info"
      />
      <AllowedConfigurations emailServiceData={emailServiceData} />
      <EmailHelperWidget />
      <Card icon={<CloudServerOutlined />} title={t('admin.Setting.Email.settingTitle')}>
        <EmailSettingsFormFields
          mailServiceProvider={mailServiceProvider}
          setMailServiceProvider={setMailServiceProvider}
          setBestSMTPSettings={setBestSMTPSettings}
        />
      </Card>
      <OtherEmailSettings mailServiceProvider={mailServiceProvider} />
      <SignatureCardMemo />
    </PageContainer>
  );
}

export default memo(EmailSettingWrapper, equal);
