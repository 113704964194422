import { Button, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { importDefaultTemplatesForDigitalSignature } from 'graphql/methods';

/**
 * IncompleteImportFooter component to show the button combination for the case that one or multiple default templates are missing in the tenant instance, but min one is imported
 * @param {Object} inputParameters - Input parameters of the component
 * @param {Boolean} inputParameters.shouldSetSelectionValuesToImportedTemplates - Should the imported templates be set as selection the settings
 * @param {Function} inputParameters.onCloseModal - Function to close the modal
 * @returns {JSX.Element} button bar for import modal for the case if one or multiple templates are imported to tenant instance
 * @component
 */
const IncompleteImportFooter = ({ shouldSetSelectionValuesToImportedTemplates, onCloseModal }) => {
  const { t } = useTranslation();

  const onImportWithReimport = () => {
    onCloseModal();
    importDefaultTemplatesForDigitalSignature(true, shouldSetSelectionValuesToImportedTemplates);
  };

  const onImportWithoutReimport = () => {
    onCloseModal();
    importDefaultTemplatesForDigitalSignature(false, shouldSetSelectionValuesToImportedTemplates);
  };

  return (
    <Space>
      <Button onClick={onCloseModal}>
        {t('admin.Setting.DigitalSignaturePreferences.importDefaultTemplatesModal.cancelButton')}
      </Button>
      <Button onClick={onImportWithReimport}>
        {t('admin.Setting.DigitalSignaturePreferences.importDefaultTemplatesModal.importAllTemplatesButton')}
      </Button>
      <Button type="primary" onClick={onImportWithoutReimport}>
        {t('admin.Setting.DigitalSignaturePreferences.importDefaultTemplatesModal.importMissingTemplatesButton')}
      </Button>
    </Space>
  );
};

export default IncompleteImportFooter;
