import { gql } from '@apollo/client';

const adminHasTenantImportedDefaultTemplatesQuery = gql`
  query adminHasTenantImportedDefaultTemplatesQuery {
    hasTenantDefaultTemplatesImported {
      hasOneImportedTemplate
      hasOneNotImportedTemplate
    }
  }
`;

export default adminHasTenantImportedDefaultTemplatesQuery;
