export const FeeType = {
  STANDARD: 'standard',
  FIXED_MONTHLY: 'fixedMonthly',
};

export const VATType = {
  PERSONAL: 'personal',
  COMPANY: 'company',
};

export const ShowDigits = {
  always: 'always',
  avoid: 'avoid',
};

export const CreationMode = {
  INDEPENDENT: 'independent',
  GLOBAL: 'global',
};

export const ComparisonTypes = {
  NO_CHANGE: 'noChange',
  NEW: 'new',
  DELETED: 'deleted',
  CHANGED: 'changed',
};

export const ShoppingCartChanges = {
  CRITICAL: 'critical',
  MINOR: 'minor',
  NOTHING: 'nothing',
};

export const KANZLEIPILOT_TEAM_USER = {
  firstName: 'KanzleiPilot',
  lastName: 'Team',
};

export const SEPA_MODE = {
  optional: 'optional',
  mandatory: 'mandatory',
};

export const ActiveComparisonTypes = [ComparisonTypes.NEW, ComparisonTypes.DELETED, ComparisonTypes.CHANGED];
