import { gql } from '@apollo/client';
import { adminShoppingCartPreferencesFragment } from 'graphql/fragments';

const userShoppingCartPreferencesQuery = gql`
  query userShoppingCartPreferencesQuery {
    tenantSettings {
      _id
      shoppingCartPreferences {
        ...adminShoppingCartPreferencesFragment
      }
    }
  }
  ${adminShoppingCartPreferencesFragment}
`;

export default userShoppingCartPreferencesQuery;
