import { gql } from '@apollo/client';
import { userDiscountFragment } from 'graphql/fragments';

const isDiscountUsedInCartQuery = gql`
  query isDiscountUsedInCartQuery($_id: ID!) {
    discountById(_id: $_id) {
      ...userDiscountFragment
      usedInShoppingCart
    }
  }
  ${userDiscountFragment}
`;

export default isDiscountUsedInCartQuery;
