import { gql } from '@apollo/client';

const userInputFieldFragment = gql`
  fragment userInputFieldFragment on InputField {
    _id
    _apolloCacheKey
    allowedRangeMaxValue
    allowedRangeMinValue
    categoryId
    defaultType
    defaultValue
    name
    parentId
    parentType
    type
    unitName
    variable {
      _id
      _apolloCacheKey
      value
      label
    }
    options {
      _id
      _apolloCacheKey
      order
      value
      name
    }
  }
`;

export default userInputFieldFragment;
