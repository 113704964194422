import { gql } from '@apollo/client';
import { adminDiscountFragment } from 'graphql/fragments';

const updateDiscountMutation = gql`
  mutation updateDiscountMutation($_id: ID!, $modifier: DiscountInput!) {
    updateCategoryDiscount(_id: $_id, modifier: $modifier) {
      ...adminDiscountFragment
    }
  }
  ${adminDiscountFragment}
`;

export default updateDiscountMutation;
