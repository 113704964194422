import { useRef } from 'react';
import { Form, Modal, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormItem, Radio } from 'formik-antd';
import { useMutation } from '@apollo/client';
import * as Yup from 'yup';
import I18nFormik from 'components/common/I18nFormik';
import EmailTemplateSelect from 'components/common/EmailTemplateSelect';
import Switch from 'components/common/Switch';
import { remindAllSigneesMutation } from 'graphql/mutations';
import useFasterPreferences from 'hooks/user/useFasterPreferences';
import openRemindAllMailDrawer from './OpenRemindAllEmailDrawer';
import classes from './RemindAllSigneesModal.module.less';

/**
 * Component, which represents button bar for digital signature remind multiple modal
 * @param {Object} inputParameters - Input parameters for the component
 * @param {Function} inputParameters.onSend - Function for on yes clicked event
 * @param {Function} inputParameters.onOpenEmailEditorDrawer - Function for on open email editor clicked event
 * @param {Function} inputParameters.onCancel - Function for on no clicked event
 * @returns {JSX.Element} ButtonBar component for digital signature revoke modal
 * @component
 */
const ButtonBar = ({ onSend, onCancel, onOpenEmailEditorDrawer }) => {
  const { t: translation } = useTranslation();
  return (
    <div>
      <Button type="default" onClick={onCancel}>
        {translation('viewer.ShoppingCartView.RemindAllSigneesModal.buttons.cancel')}
      </Button>
      <Button type="default" onClick={onOpenEmailEditorDrawer}>
        {translation('viewer.ShoppingCartView.RemindAllSigneesModal.buttons.openEmailEditor')}
      </Button>
      <Button type="primary" onClick={onSend}>
        {translation('viewer.ShoppingCartView.RemindAllSigneesModal.buttons.send')}
      </Button>
    </div>
  );
};

/**
 * RemindAllSigneesModal component to send digital signature reminder emails to multiple signees with filter and template options
 * @param {Object} inputParameters - Input parameter
 * @param {String} inputParameters.shoppingCartId - Database id of related shopping cart object
 * @param {Function} inputParameters.onCloseModal - Function, which handles the close of the modal
 * @param {String} inputParameters.shoppingCart - Current shopping cart object
 * @returns {JSX.Element} modal to configure digital signature remind all signees function
 * @component
 */
const RemindAllSigneesModal = ({ shoppingCartId, onCloseModal, shoppingCart }) => {
  const { t: translation } = useTranslation();
  const { preferences: currentPreferences } = useFasterPreferences();
  const formReference = useRef();

  const initialValues = {
    emailTemplateId: null,
    onlyUnsignedSignees: true,
    signeeType: 'ALL',
  };

  const validationSchema = Yup.object().shape({
    emailTemplateId: Yup.string()
      .required(translation('viewer.ShoppingCartView.RemindAllSigneesModal.emailTemplateSelect.error'))
      .typeError(translation('viewer.ShoppingCartView.RemindAllSigneesModal.emailTemplateSelect.error')),
    onlyUnsignedSignees: Yup.boolean(),
    signeeType: Yup.string().required(),
  });

  const [remindAllSignees] = useMutation(remindAllSigneesMutation);

  const onOkClicked = async () => {
    formReference.current.isDirty = true;
    if (Object.keys(await formReference.current.validateForm()).length !== 0) return;
    await remindAllSignees({
      variables: {
        shoppingCartId,
        emailTemplateId: formReference.current.values.emailTemplateId,
        onlyRemindUnsigned: formReference.current.values.onlyUnsignedSignees,
        signeeType: formReference.current.values.signeeType,
      },
    });
    onCloseModal();
  };

  const onOpenEmailEditorDrawer = async () => {
    const { onlyUnsignedSignees, signeeType, emailTemplateId } = formReference.current.values;
    const filterOptions = { onlyUnsignedSignees, signeeType };
    openRemindAllMailDrawer({
      shoppingCart,
      filterOptions,
      emailTemplateId,
      preferences: currentPreferences,
      translation,
    });
    onCloseModal();
  };

  const onCancelClicked = () => {
    onCloseModal();
  };

  return (
    <Modal
      title={translation('viewer.ShoppingCartView.RemindAllSigneesModal.title')}
      visible
      footer={
        <ButtonBar onSend={onOkClicked} onCancel={onCancelClicked} onOpenEmailEditorDrawer={onOpenEmailEditorDrawer} />
      }
      onCancel={onCloseModal}
      className={classes.modal}
    >
      <div>
        <I18nFormik ref={formReference} initialValues={initialValues} validationSchema={validationSchema}>
          <Form layout="vertical">
            <EmailTemplateSelect
              label={translation('viewer.ShoppingCartView.RemindAllSigneesModal.emailTemplateSelect.label')}
              placeholder={translation('viewer.ShoppingCartView.RemindAllSigneesModal.emailTemplateSelect.placeholder')}
              fieldName="emailTemplateId"
              errorMessageEnabled
            />
            <Switch
              label={translation('viewer.ShoppingCartView.RemindAllSigneesModal.onlyUnsignedSigneesSwitch.label')}
              name="onlyUnsignedSignees"
              defaultChildren
            />
            <FormItem
              name="signeeType"
              label={translation('viewer.ShoppingCartView.RemindAllSigneesModal.signeeTypeSelect.label')}
            >
              <Radio.Group name="signeeType">
                <Radio.Button name="signeeType" value="ALL">
                  {translation('viewer.ShoppingCartView.RemindAllSigneesModal.signeeTypeSelect.all')}
                </Radio.Button>
                <Radio.Button name="signeeType" value="TENANT">
                  {translation('viewer.ShoppingCartView.RemindAllSigneesModal.signeeTypeSelect.tenant')}
                </Radio.Button>
                <Radio.Button name="signeeType" value="COMPANY">
                  {translation('viewer.ShoppingCartView.RemindAllSigneesModal.signeeTypeSelect.company')}
                </Radio.Button>
              </Radio.Group>
            </FormItem>
          </Form>
        </I18nFormik>
      </div>
    </Modal>
  );
};

export default RemindAllSigneesModal;
