import { useState, useCallback } from 'react';
import FullViewPdfViewer from './FullViewPdfViewer';

/**
 * FullscreenModeWrapper component for pdf viewer, which manages the fullscreen mode
 * @param {Object} inputParameters - Input parameters for component
 * @param {JSX.Element} inputParameters.children - Child component, which gets the property openFullscreenMode:Function to open the fullscreen mode
 * @param {Uint8Array} inputParameters.data - Binary data of document as Uint8Array or null
 * @param {Object} inputParameters.documentInfo - Informations about the document
 * @param {String} inputParameters.documentInfo.documentTemplateName - Name of document template
 * @returns {JSX.Element} wrapper component for fullscreen mode visible state
 * @component
 */
const FullscreenModeWrapper = ({ children, data, documentInfo }) => {
  const [showFullScreenMode, setShowFullScreenMode] = useState(false);
  const openFullscreenMode = useCallback(() => setShowFullScreenMode(true), []);
  return (
    <>
      {children({ openFullscreenMode })}
      {showFullScreenMode ? (
        <FullViewPdfViewer onClose={() => setShowFullScreenMode(false)} data={data} documentInfo={documentInfo} />
      ) : null}
    </>
  );
};

export default FullscreenModeWrapper;
