import { createContext, useContext, useState, useMemo } from 'react';

export const AllApolloCategoriesContext = createContext({});
export const useAllApolloCategoriesContext = () => useContext(AllApolloCategoriesContext);

export const CategoriesContext = createContext({});
export const useCategoriesContext = () => useContext(CategoriesContext);

export const CategoryContext = createContext({});
export const useCategoryContext = () => useContext(CategoryContext);

export const ShoppingCartRevisionContext = createContext({});
export const useShoppingCartRevisionContext = () => useContext(ShoppingCartRevisionContext);

export const VariablesContext = createContext({});
export const useVariablesContext = () => useContext(VariablesContext);

export const VATContext = createContext({});
export const useVATContext = () => useContext(VATContext);

export const CustomPriceContext = createContext({});
export const useCustomPriceContext = () => useContext(CustomPriceContext);

export const ShowCategoryTotalContext = createContext({});
export const useShowCategoryTotalContext = () => useContext(ShowCategoryTotalContext);

export const AffectedInputFieldIdsContext = createContext([]);
export const useAffectedInputFieldIdsContext = () => useContext(AffectedInputFieldIdsContext);

export const RecipientsContext = createContext([]);
export const useRecipientsContext = () => useContext(RecipientsContext);

export const DigitalSignatureEmailContext = createContext({});
export const useDigitalSignatureEmailContext = () => useContext(DigitalSignatureEmailContext);
export const DigitalSignatureEmailProvider = ({ children }) => {
  const [initialValues, setInitialValues] = useState({});
  const value = useMemo(() => ({ initialValues, setInitialValues }), [initialValues]);
  return <DigitalSignatureEmailContext.Provider value={value}>{children}</DigitalSignatureEmailContext.Provider>;
};
