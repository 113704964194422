import classNames from 'classnames';
import { FaCheck, FaCreditCard, FaEye, FaFileSignature, FaPlus, FaUserShield } from 'react-icons/fa';
import c from './Progress.module.less';

const Circle = ({ children, isActive }) => {
  1;

  return <div className={classNames(c.circle, { 'active-circle': isActive })}>{children}</div>;
};
const Dash = ({ isActive }) => <div className={classNames(c.dash, { 'active-dash': isActive })} />;
const Progress = ({
  options = [
    { key: 'confirmIdentity', component: <FaUserShield style={{ marginLeft: 2 }} /> },
    { key: 'reviewServices', component: <FaEye style={{ fontSize: 20 }} /> },
    { key: 'additionalServices', component: <FaPlus style={{ fontSize: 18 }} /> },
    { key: 'viewAndSignDocuments', component: <FaFileSignature style={{ marginLeft: 6 }} /> },
    { key: 'sepa', component: <FaCreditCard style={{ fontSize: 20 }} /> },
    { key: '', component: <FaCheck style={{ fontSize: 18 }} /> },
  ],
  page,
}) => {
  const activeIndex = options.findIndex((option) => option.key === page);
  return (
    <div className={classNames(c.progress, 'progress')}>
      {options.map((e, index) => (
        <>
          <Circle isActive={activeIndex >= index}>{e.component}</Circle>
          {index === options.length - 1 ? null : <Dash isActive={activeIndex > index} />}
        </>
      ))}
    </div>
  );
};
export default Progress;
