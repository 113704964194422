/* eslint-disable no-param-reassign */
import React, { useCallback, memo, useMemo, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { CheckOutlined } from '@ant-design/icons';
import { adminCategoriesQuery } from 'graphql/queries';
import { importCategoriesFromLibrary } from 'graphql/methods';
import { pick } from 'lodash';
import ImportFromCommonLibrary, {
  ImportFromLibrary,
} from 'components/common/CommonLibraryImport/ImportFromCommonLibrary';
import equal from 'fast-deep-equal/es6/react';
import CollapsibleCommonCategory from './CollapsibleCommonCategory';
import ImportCategoriesFromLibraryHelperWidget from './ImportCategoriesFromLibraryHelperWidget';
import '../common/ImportFromCommonLibrary.less';

const RenderItem = ({ data: category, isSelected, isOnboarding, isDuplicated, handleSelect }) => {
  return (
    <div key={category._id} id={category._id} className={cn('import-items', isDuplicated ? 'import-duplicated' : null)}>
      <CollapsibleCommonCategory key={category._id} category={category} isOnboarding={isOnboarding} />
      <button className="import-add-btn" type="button" onClick={() => handleSelect(category, !isSelected)}>
        {isSelected ? <CheckOutlined /> : null}
      </button>
    </div>
  );
};
const RenderItemMemo = memo(RenderItem, equal);

const ImportCategoryPresetsModal = ({ visible, handleClose }) => {
  const { t } = useTranslation();

  const errorMessage = useCallback(
    (duplicatedCategories) => {
      return t('admin.importCategoryPresetsModal.errorMessage', {
        duplicatedCategories,
      });
    },
    [t],
  );

  const onOk = useCallback(async (values) => {
    await importCategoriesFromLibrary(
      values.map((category) => pick(category, ['_id', 'name'])),
      adminCategoriesQuery,
    );
  }, []);

  const title = useMemo(
    () => (
      <>
        <span>{t('admin.importCategoryPresetsModal.title')}</span>
        <div>
          <ImportCategoriesFromLibraryHelperWidget />
        </div>
      </>
    ),
    [t],
  );

  if (!visible) return null;
  return (
    <ImportFromCommonLibrary
      emptyMessage={t('user.ShoppingCart.emptyCategoryListMessage')}
      errorMessage={errorMessage}
      handleClose={handleClose}
      modalProps={{ visible: true, width: 1000 }}
      onOk={onOk}
      query={adminCategoriesQuery}
      renderItem={RenderItemMemo}
      title={title}
      showClAdvertisement
    />
  );
};

export const ImportCategoryPresets = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const errorMessage = useCallback(
    (duplicatedCategories) => {
      return t('admin.importCategoryPresetsModal.errorMessage', {
        duplicatedCategories,
      });
    },
    [t],
  );
  return (
    <ImportFromLibrary
      ref={ref}
      {...props}
      query={adminCategoriesQuery}
      renderItem={RenderItemMemo}
      errorMessage={errorMessage}
      emptyMessage={t('user.ShoppingCart.emptyCategoryListMessage')}
    />
  );
});

export default memo(ImportCategoryPresetsModal, equal);
