import Card from 'components/common/Card';
import { useTranslation } from 'react-i18next';
import { FileFilled } from '@ant-design/icons';
import { Alert } from 'antd';
import { useFormikField } from 'hooks/common/useFormikField';

const DocumentsSettingsCard = () => {
  const { t } = useTranslation();

  const { value: monthlyPaymentMode } = useFormikField('monthlyPaymentMode');
  if (monthlyPaymentMode !== 'optional') return null;

  return (
    <Card
      icon={<FileFilled />}
      title={t('admin.Setting.MonthlyPaymentPreferences.documentsSettings.cardLabel')}
      style={{ padding: 0 }}
    >
      <Alert
        type="info"
        className="alert-info"
        description={t('admin.Setting.MonthlyPaymentPreferences.documentsSettings.infoAlertText')}
        showIcon
      />
    </Card>
  );
};

export default DocumentsSettingsCard;
