import { gql } from '@apollo/client';
import apollo from 'graphql/apollo';
import { adminInputFieldFragment } from 'graphql/fragments';

const setInputFieldFragment = gql`
  fragment setInputFieldFragment on Category {
    inputFields {
      ...adminInputFieldFragment
    }
  }
  ${adminInputFieldFragment}
`;

export default function moveInputField({ _id, inputFields }) {
  apollo.writeFragment({
    id: `Category___${_id}`,
    fragment: setInputFieldFragment,
    fragmentName: 'setInputFieldFragment',
    data: { inputFields },
  });
}
