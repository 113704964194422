/*
  A hook to fetch current user
  Add extra props to the currentUser fragment when you need it
*/
import { verifyEmailMutation } from 'graphql/mutations';
import useAuth from './useAuth';

const useVerifyEmail = () => {
  const [verifyEmail] = useAuth(verifyEmailMutation);
  return verifyEmail;
};

export default useVerifyEmail;
