import { Calc, RichText } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { Button } from 'antd';
import Card from 'components/common/Card';
import equal from 'fast-deep-equal/es6/react';
import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import { useFormikContext } from 'formik';
import cn from 'classnames';
import { throttle } from 'lodash';
import { CalculationMode } from 'constants/item';
import TranslationPrice from 'components/common/TranslationPrice';
import { useCompareToGlobalContext } from 'contexts/CompareToGlobalContext';
import classes from './ItemPriceInfo.module.less';
import classesItemCard from './ItemCard.module.less';
import { useCustomPriceContext } from '../context';
import { customPriceDrawer } from './CustomPriceDrawer';

const ItemPriceInfo = ({
  _id,
  calculationMode,
  currentFeeTypeMessage,
  currentFeeTypeMonthly,
  customPrice,
  discount,
  highlightBindingness,
  internalInputFields,
  internalNoteToTeam,
  isBindingnessActualCost,
  officialReasonText,
  paymentInterval,
  price,
  showCalculatedPrice,
  showDigits,
  showPrices,
}) => {
  const { isCompare } = useCompareToGlobalContext();
  const { allowCustomPrices, showCalculatedPrice: showCalculatedPriceDefault } = useCustomPriceContext();
  const { t } = useTranslation();
  const [showEditCustom, setShowEditCustom] = useState(false);
  const { values, setValues } = useFormikContext();

  const onSubmit = useCallback(
    (newValues) => {
      setValues((state) => ({
        ...state,
        ...newValues,
      }));
    },
    [setValues],
  );
  const onShowEditButton = useMemo(
    () => throttle(() => allowCustomPrices && !showEditCustom && !isMobile && setShowEditCustom(true), 100),
    [allowCustomPrices, showEditCustom],
  );
  const isPrice = useMemo(() => price.isPrice || isBindingnessActualCost, [isBindingnessActualCost, price.isPrice]);
  const isAllowCustomPrice = useMemo(
    () => calculationMode === CalculationMode.VALUE && allowCustomPrices && isPrice,
    [allowCustomPrices, calculationMode, isPrice],
  );

  const translationSharedData = useMemo(
    () => ({ paymentInterval, showDigits, highlightBindingness }),
    [highlightBindingness, paymentInterval, showDigits],
  );

  const onOpen = useCallback(() => {
    if (isAllowCustomPrice)
      customPriceDrawer({
        _id,
        calcData: price.calcData,
        currentFeeTypeMessage,
        customPrice,
        internalInputFields,
        internalNoteToTeam,
        isCompare,
        officialReasonText,
        onSubmit,
        showCalculatedPrice: showCalculatedPrice ?? showCalculatedPriceDefault,
        translationSharedData,
        values,
      });
  }, [
    _id,
    currentFeeTypeMessage,
    customPrice,
    internalInputFields,
    internalNoteToTeam,
    isAllowCustomPrice,
    isCompare,
    officialReasonText,
    onSubmit,
    price.calcData,
    showCalculatedPrice,
    showCalculatedPriceDefault,
    translationSharedData,
    values,
  ]);

  const priceTranslation = useMemo(
    () =>
      (currentFeeTypeMessage || isPrice) &&
      Calc.priceInfoAsText({
        ...translationSharedData,
        discount,
        calculationMode,
        currentFeeTypeMonthly,
        value: {
          ...price.value,
          customPrice: price.customPrice,
          discountedCustomPrice: price.discountedCustomPrice,
        },
        showCalculatedPrice,
      }),
    [
      calculationMode,
      currentFeeTypeMessage,
      currentFeeTypeMonthly,
      discount,
      isPrice,
      price.customPrice,
      price.discountedCustomPrice,
      price.value,
      showCalculatedPrice,
      translationSharedData,
    ],
  );

  if ((isPrice && showPrices) || currentFeeTypeMessage)
    return (
      <Card
        size="small"
        className={classesItemCard.itemPricePreview}
        onMouseOver={onShowEditButton}
        onMouseLeave={() => allowCustomPrices && setShowEditCustom(false)}
        onClick={() => isMobile && onOpen()}
      >
        <span
          className={isAllowCustomPrice ? classes.infoText : ''}
          tabIndex={0}
          role="button"
          onKeyDown={(e) => e.stopPropagation() || onOpen()}
          onClick={(e) => e.stopPropagation() || onOpen()}
        >
          <TranslationPrice
            currentFeeTypeMessage={currentFeeTypeMessage}
            priceTranslation={priceTranslation}
            customPrice={price.customPrice}
            showCalculatedPrice={price.showCalculatedPrice}
            discountedCustomPrice={price.discountedCustomPrice}
          />
          {isAllowCustomPrice && !RichText.isRichTextEmpty(officialReasonText) ? (
            <>
              <br />
              <i>
                {t('user.ShoppingCart.Category.Item.displayOfficialReasonText')}{' '}
                <span className={classes.officialReasonText}>{RichText.renderRichText(officialReasonText)}</span>
              </i>
            </>
          ) : null}
          {isAllowCustomPrice && (isTablet || (!isMobile && showEditCustom)) ? (
            <Button
              className={cn(classes.editButton, 'ant-btn-default')}
              type="text"
              icon={<EditOutlined />}
              onClick={(e) => e.stopPropagation() || (!isMobileOnly && onOpen())}
            />
          ) : null}
        </span>
      </Card>
    );
  return null;
};

export default memo(ItemPriceInfo, equal);
