import apollo from 'graphql/apollo';
import { deleteTenantLogoMutation } from 'graphql/mutations';
import { tenantLogoQuery } from 'graphql/queries';

const deleteTenantLogo = async () =>
  apollo.mutate({
    mutation: deleteTenantLogoMutation,
    refetchQueries: [{ query: tenantLogoQuery }],
  });
export default deleteTenantLogo;
