import { useTranslation } from 'react-i18next';
import { Alert } from 'antd';

export default function AlphaWarningAlert() {
  const { t } = useTranslation();
  const warningCardTranslation = (key) => `admin.Setting.DigitalSignaturePreferences.warningCard.${key}`;

  return (
    <Alert
      showIcon
      type="warning"
      className="alert-warning"
      message={t(warningCardTranslation('message'))}
      description={t(warningCardTranslation('description'))}
    />
  );
}
