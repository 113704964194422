import { useTranslation } from 'react-i18next';
import useSigningContext from 'hooks/signing/useSigningContext';
import IdentityQuestionForm from './IdentityStep/IdentityQuestionForm';
import CustomWarningAlert from './CustomWarningAlert';
import classes from './IdentityStep.module.less';

/**
 * IdentityStep component is the step component for the identity check in the signing process
 * @param {Object} inputParameters - Input parameters of the component
 * @param {React.Ref} inputParameters.submitReference - Reference, which gets the onContinue function
 * @param {Object} inputParameters.stepState - Saved state of the step
 * @returns {JSX.Element} step for identity check in the signing process
 */
const IdentityStep = ({ submitReference, stepState }) => {
  const { signingContext } = useSigningContext();
  const { t: translation } = useTranslation('translation', { keyPrefix: 'signing.identityStep' });

  const privateOrCompany = signingContext?.isPrivatePerson ? 'private' : 'company';
  const name = `${signingContext?.firstName} ${signingContext?.lastName}`;
  const company = signingContext?.nameOfCompany || '';
  return (
    <>
      <CustomWarningAlert
        message={translation('warning.message')}
        description={translation('warning.description', { name, company })}
        className={classes.warningAlert}
      />
      <div className={classes.question}>
        <p>{translation(`question.${privateOrCompany}`, { name, company })}</p>
        <IdentityQuestionForm
          name={name}
          company={company}
          privateOrCompany={privateOrCompany}
          submitReference={submitReference}
          stepState={stepState}
        />
      </div>
    </>
  );
};

export default IdentityStep;
