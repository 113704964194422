import { Layout, Row, Col } from 'antd';
import cn from 'classnames';
import kanzleiPilotLogoSrc from 'assets/images/KanzleiPilotLogo.svg';
import Footer from 'components/layout/Footer';
import Card from 'components/common/Card';
import classes from './AuthLayout.module.less';
import Quotes from './Quotes';

const CardHeader = () => <img alt="logo" src={kanzleiPilotLogoSrc} className={classes.logo} />;

const { Content } = Layout;

export default function AuthLayout({ children }) {
  return (
    <Layout className={classes.layout}>
      <Row justify="center" align="middle" style={{ flex: 1 }}>
        <Col
          xs={{ order: 2, span: 24 }}
          md={{ order: 1, span: 12 }}
          lg={{ span: 10 }}
          style={{ padding: 20 }}
          className={cn('text-align-center', classes.leftText)}
        >
          <Quotes />
        </Col>
        <Col xs={{ order: 1, span: 24 }} md={{ order: 2, span: 12 }} lg={{ span: 10 }} style={{ padding: 20 }}>
          <Card className={cn(classes.card, 'ant-card-head-borderless')} title={<CardHeader />}>
            <Content className={classes.content}>{children}</Content>
            <Footer className={cn('margin-top-16', classes.footer)} />
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}
