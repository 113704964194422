import { gql } from '@apollo/client';

const completeSepaMandateStep = gql`
  mutation completeSepaMandateStep(
    $hasGranted: Boolean!
    $iban: String
    $bic: String
    $accountOwnerName: String
    $bankData: BankDataInput
  ) {
    completeSepaMandateStep(
      hasGranted: $hasGranted
      iban: $iban
      bic: $bic
      accountOwnerName: $accountOwnerName
      bankData: $bankData
    ) {
      success
    }
  }
`;

export default completeSepaMandateStep;
