import { Radio, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { DIGITAL_SIGNATURE_STATE } from '@JavaScriptSuperstars/kanzleipilot-shared';
import useFasterPreferences from 'hooks/user/useFasterPreferences';

const { Text } = Typography;

/**
 * Additional shopping cart filters collapsable with currently only the digital signature state filter
 * @param {Object} inputParameter - Input parameters of the component
 * @param {String} inputParameter.digitalSignatureState - Current selected filter value for digital signature state filter
 * @param {Function} inputParameter.updateQuery - Function to update query with new filter options, if an other filter option is selected
 * @returns {JSX.Element} ShoppingCartAdditionalFilters component for shopping cart entries page
 * @component
 */
const ShoppingCartAdditionalFilters = ({ digitalSignatureState, updateQuery }) => {
  const { t } = useTranslation();
  const { preferences } = useFasterPreferences({ fetchPolicy: 'cache-only' });

  const isDigitalSignatureEnabled = preferences?.digitalSignaturePreferences?.digitalSignatureEnabled || false;

  if (!isDigitalSignatureEnabled) return null;

  return (
    <>
      <div style={{ marginTop: '16px', marginBottom: '5px' }}>
        <Text>{t('ShoppingCartEntriesPage.digitalSignatureFilterLabel')}</Text>
      </div>
      <Radio.Group
        defaultValue={digitalSignatureState}
        onChange={(e) => updateQuery({ field: 'digitalSignatureState', value: e.target.value })}
        size="small"
      >
        <Radio.Button value={undefined}>{t('ShoppingCartEntriesPage.statusAll')}</Radio.Button>
        <Radio.Button value={DIGITAL_SIGNATURE_STATE.NO_DIGITAL_SIGNATURE}>
          {t('ShoppingCartEntriesPage.additionalFilter.digitalSignatureState.NO_DIGITAL_SIGNATURE')}
        </Radio.Button>
        <Radio.Button value={DIGITAL_SIGNATURE_STATE.DRAFT}>
          {t('ShoppingCartEntriesPage.additionalFilter.digitalSignatureState.DRAFT')}
        </Radio.Button>
        <Radio.Button value={DIGITAL_SIGNATURE_STATE.STARTED}>
          {t('ShoppingCartEntriesPage.additionalFilter.digitalSignatureState.STARTED')}
        </Radio.Button>
        <Radio.Button value={DIGITAL_SIGNATURE_STATE.REVOKED}>
          {t('ShoppingCartEntriesPage.additionalFilter.digitalSignatureState.REVOKED')}
        </Radio.Button>
        <Radio.Button value={DIGITAL_SIGNATURE_STATE.SIGNED}>
          {t('ShoppingCartEntriesPage.additionalFilter.digitalSignatureState.SIGNED')}
        </Radio.Button>
      </Radio.Group>
    </>
  );
};

export default ShoppingCartAdditionalFilters;
