import { FaCalculator, FaCheckCircle } from 'react-icons/fa';
import classNames from 'classnames';
import c from './PricingOverview.module.less';
import Panel from './Panel';
import { Sans30px400, Sans30px600 } from './Text/Text';
import Note from './Note';
import { Br100 } from './Text/Br';

const PricingOverview = ({ note }) => {
  1;

  return (
    <div className={c.pricingOverview}>
      <Panel
        title={
          <Sans30px600
            iconStyle={{ fontSize: 16, marginTop: 6 }}
            mobileIconStyle={{ fontSize: 15, marginTop: 2, marginRight: 8 }}
            icon={<FaCalculator />}
            mobileTextStyle={{ fontSize: 14 }}
          >
            Pricing overview
          </Sans30px600>
        }
      >
        {note && (
          <>
            <Note title={<Sans30px600>Aktive Nachlässe</Sans30px600>}>
              <Sans30px400
                iconStyle={{ fontSize: 17, marginTop: 4, marginRight: 7 }}
                mobileIconStyle={{ fontSize: 15, marginTop: 2 }}
                icon={<FaCheckCircle />}
              >
                {note}
              </Sans30px400>
            </Note>
            <Br100 />
          </>
        )}
        {[
          ['Interval', 'Price (without vat)'],
          ['One-off', 'Free service'],
          ['Monthly', '100 Euro'],
          ['Yearly', '200 Euro'],
        ].map(([firstColumn, secondColumn], index) => {
          const isHeader = index === 0;
          const Text = isHeader ? Sans30px600 : Sans30px400;
          return (
            <div className={classNames(c.rowContainer, isHeader && c.header)}>
              <div>
                <Text mobileTextStyle={!isHeader && { fontSize: 14 }}>{firstColumn}</Text>
              </div>
              <div>
                <Text mobileTextStyle={!isHeader && { fontSize: 14 }}>{secondColumn}</Text>
              </div>
            </div>
          );
        })}
      </Panel>
    </div>
  );
};
export default PricingOverview;
