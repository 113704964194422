import { gql } from '@apollo/client';
import { adminPersonFragment } from 'graphql/fragments';

const updatePersonMutation = gql`
  mutation updatePersonMutation($_id: ID!, $modifier: UpdatePersonInput!) {
    updatePerson(_id: $_id, modifier: $modifier) {
      ...adminPersonFragment
    }
  }
  ${adminPersonFragment}
`;

export default updatePersonMutation;
