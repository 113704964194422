import equal from 'fast-deep-equal/es6/react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import routePaths from 'router/route-paths';

const LoginButton = () => {
  const { t } = useTranslation();

  return (
    <Link className="grey-link" to={routePaths.login}>
      {t('ResetPassword.buttons.goToLogin')}
    </Link>
  );
};

export default memo(LoginButton, equal);
