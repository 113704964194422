import { gql } from '@apollo/client';

const moveDocumentTemplateMutation = gql`
  mutation moveDocumentTemplateMutation($_id: ID!, $offset: Int, $order: Int) {
    moveDocumentTemplate(_id: $_id, offset: $offset, order: $order) {
      _id
      order
    }
  }
`;

export default moveDocumentTemplateMutation;
