import { gql } from '@apollo/client';
import { adminCategoryListFragment } from 'graphql/fragments';

const createCategoryMutation = gql`
  mutation createCategoryMutation($category: Input!) {
    createCategory(category: $category) {
      ...adminCategoryListFragment
    }
  }
  ${adminCategoryListFragment}
`;

export default createCategoryMutation;
