import { gql } from '@apollo/client';

const createHtmlPreviewMutation = gql`
  mutation createHtmlPreviewMutation(
    $shoppingCart: ShoppingCartInput!
    $documentTemplate: DocumentTemplatePreviewInput!
  ) {
    generateDocumentHTMLPreview(shoppingCart: $shoppingCart, documentTemplate: $documentTemplate)
  }
`;

export default createHtmlPreviewMutation;
