import apollo from 'graphql/apollo';
import { sendInvitationMutation } from 'graphql/mutations';
import { adminUsersInTenantQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';

const sendInvitation = async ({ profile, role, email }) =>
  apollo.mutate({
    mutation: sendInvitationMutation,
    variables: { profile, role, email },
    update: (cache, { data }) => {
      const cacheData = cache.readQuery({ query: adminUsersInTenantQuery });
      const newUser = grabFirstGQLDataResult(data);

      cache.writeQuery({
        query: adminUsersInTenantQuery,
        data: {
          usersInTenant: [...cacheData.usersInTenant, newUser],
        },
      });
    },
  });
export default sendInvitation;
