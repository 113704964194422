import 'components/layouts/SignDocuments.variables.less';
import { useMediaQuery } from 'react-responsive';

const text = ({ textStyle, iconStyle, mobileTextStyle = {}, mobileIconStyle = {} }) => {
  const textMobile = { ...textStyle, ...mobileTextStyle };
  const iconMobile = { ...iconStyle, ...mobileIconStyle };
  return function Text({
    children,
    icon,
    style,
    textStyle: t,
    iconStyle: i,
    mobileIconStyle: mi,
    mobileTextStyle: mt,
  }) {
    const isSmallWidthDevice = useMediaQuery({ maxWidth: 500 });
    return (
      <span style={{ display: 'flex', ...style }}>
        {icon ? (
          <span
            className="sd-primary-100-color"
            style={{ ...(isSmallWidthDevice ? { ...iconMobile, ...i, ...mi } : { ...iconStyle, ...i }) }}
          >
            {icon}
          </span>
        ) : null}
        <span style={{ ...(isSmallWidthDevice ? { ...textMobile, ...t, ...mt } : { ...textStyle, ...t }) }}>
          {children}
        </span>
      </span>
    );
  };
};

const size43px = 26;
const size35px = 22;
const size21pxM = 21;
const size30px = 18;
const size26px = 16;
// const size27px = 16.5;
const size12pxM = 12;
const size22px = 13;
const size11pxM = 10.5;
const size13pxM = 13;
const fontWeight600 = 600;
const fontWeight400 = 400;
const common = {
  fontFamily: 'Open Sans',
  color: '#323232',
  display: /* https://css-tricks.com/forums/topic/line-height-not-working-inside-span/ */ 'inline-block',
};

export const Sans43px600 = text({
  textStyle: { ...common, fontSize: size43px, fontWeight: fontWeight600 },
  mobileTextStyle: { fontSize: size21pxM },
});
export const Sans35px600 = text({
  textStyle: { ...common, fontSize: size35px, fontWeight: fontWeight600 },
  iconStyle: { display: 'inline-flex', marginRight: 13, fontSize: 28, marginTop: 4 },
  mobileTextStyle: { fontSize: size21pxM },
  mobileIconStyle: { fontSize: 23, marginTop: 5 },
});
export const Sans30px400 = text({
  textStyle: { ...common, fontSize: size30px, fontWeight: fontWeight400 },
  mobileTextStyle: { fontSize: size13pxM, lineHeight: 1.5 },
  iconStyle: { display: 'inline-flex', marginRight: 11, fontSize: 23, marginTop: 2 },
  mobileIconStyle: { fontSize: 20, marginTop: 0 },
});
export const Sans30px600 = text({
  textStyle: { ...common, fontSize: size30px, fontWeight: fontWeight600 },
  mobileTextStyle: { fontSize: size13pxM },
  iconStyle: { display: 'inline-flex', marginRight: 11, fontSize: 23, marginTop: 2 },
  mobileIconStyle: { fontSize: 20, marginTop: 0 },
});
export const Sans26px600 = text({
  textStyle: { ...common, fontSize: size26px, fontWeight: fontWeight600 },
  mobileTextStyle: { fontSize: size12pxM },
});
export const Sans26px400 = text({
  textStyle: { ...common, fontSize: size26px, fontWeight: fontWeight400 },
  mobileTextStyle: { fontSize: size12pxM },
  iconStyle: { fontSize: 20, marginRight: 15 },
});
export const Sans22px400 = text({
  textStyle: { ...common, fontSize: size22px, fontWeight: fontWeight400, color: '#a3a3a3' },
  mobileTextStyle: { fontSize: size11pxM },
});
