import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';

const useCompareToGlobalState = (defaultValue) => {
  const location = useLocation();
  const [isCompare, setIsCompare] = useState(() => defaultValue ?? useCompareToGlobalState.defaults.isCompare);
  useEffect(() => setIsCompare(defaultValue ?? useCompareToGlobalState.defaults.isCompare), [defaultValue, location]);
  const toggleCompare = useCallback((v) => setIsCompare(typeof v === 'boolean' ? v : (s) => !s), []);
  return { isCompare, toggleCompare };
};
useCompareToGlobalState.defaults = {
  isCompare: false,
  toggleCompare: () => {},
};

export const CompareToGlobalContext = createContext({
  ...useCompareToGlobalState.defaults,
});

export const CompareToGlobalProvider = ({ defaultValue, children }) => {
  const compareToGlobalState = useCompareToGlobalState(defaultValue);
  return <CompareToGlobalContext.Provider value={compareToGlobalState}>{children}</CompareToGlobalContext.Provider>;
};

export const useCompareToGlobalContext = () => useContext(CompareToGlobalContext);
