import { gql } from '@apollo/client';
import { adminThemeFragment } from 'graphql/fragments';

const updateThemeMutation = gql`
  mutation updateThemeMutation($theme: ThemeInput) {
    updateTheme(theme: $theme) {
      ...adminThemeFragment
    }
  }
  ${adminThemeFragment}
`;

export default updateThemeMutation;
