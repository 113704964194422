import { gql } from '@apollo/client';
import { adminEmailServiceFragment } from 'graphql/fragments';

const updateEmailServiceMutation = gql`
  mutation updateEmailServiceMutation($emailService: EmailServiceInput!) {
    updateEmailService(emailService: $emailService) {
      ...adminEmailServiceFragment
    }
  }
  ${adminEmailServiceFragment}
`;

export default updateEmailServiceMutation;
