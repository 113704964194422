import { gql } from '@apollo/client';

const warningFragment = gql`
  fragment warningFragment on Warning {
    message
    level
  }
`;

export default warningFragment;
