import { gql } from '@apollo/client';
import apollo from 'graphql/apollo';
import { adminStaticItemFragment } from 'graphql/fragments';

export const categoryStaticItems = gql`
  fragment categoryStaticItems on Category {
    staticItems {
      ...adminStaticItemFragment
    }
  }
  ${adminStaticItemFragment}
`;

export default function updateCategoryStaticItemCache({ _id, staticItems }) {
  apollo.writeFragment({
    id: `Category___${_id}`,
    fragment: categoryStaticItems,
    fragmentName: 'categoryStaticItems',
    data: { staticItems },
  });
}
