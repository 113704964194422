import { gql } from '@apollo/client';
import { adminItemFragment } from 'graphql/fragments';

export const adminCategoryItemQuery = gql`
  query adminCategoryItemQuery($_id: ID!) {
    categoryItem(_id: $_id) {
      ...adminItemFragment
    }
  }
  ${adminItemFragment}
`;

export default adminCategoryItemQuery;
