import FormItem from 'components/common/FormItem';
import equal from 'fast-deep-equal/es6/react';
import { Input } from 'formik-antd';
import { memo } from 'react';

import * as Yup from 'yup';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';

const schema = () => ({
  name: Yup.string()
    .transform((value) => value || '')
    .label(i18n.t('admin.ManageShoppingCartStatus.fields.name.label'))
    .required()
    .min(1),
});

export const statusValidationSchema = () => Yup.object().shape(schema());

function StatusForm() {
  const { t } = useTranslation();
  return (
    <FormItem name="name" label={t('admin.ManageShoppingCartStatus.fields.name.label')}>
      <Input name="name" placeholder={t('admin.ManageShoppingCartStatus.fields.name.placeholder')} />
    </FormItem>
  );
}

export default memo(StatusForm, equal);
